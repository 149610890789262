import { FC } from "react";
import { Form, Tag } from "antd";
import { FORM_ITEM_OFFSET } from "@app/features/inventory/InventoryHealthDetailsPages/constants";
import { useInheritedLabels } from "@app/features/inventory/InventoryHealthDetailsPages/SharedSections/Labels/useInheritedLabels";

interface InheritedLabelsProps {
    id: number;
    labelType: "seat" | "internal";
}

const { Item } = Form;

export const InheritedLabels: FC<InheritedLabelsProps> = ({ id, labelType }) => {
    const { inheritedLabels } = useInheritedLabels({ id, labelType });

    const typeLabel = labelType === "seat" ? "Seat" : "Global";

    if (!inheritedLabels?.length) return null;

    return (
        <Item
            className={FORM_ITEM_OFFSET}
            label={`Inherited ${typeLabel} Labels`}
            data-sdet={`inherited-${labelType}-lables`}
        >
            {inheritedLabels.map((label) => {
                return (
                    <Tag key={`${label?.label?.key}-${label?.value}`}>
                        {label?.label?.key}: {label?.value}
                    </Tag>
                );
            })}
        </Item>
    );
};
