import { useHistory, useLocation } from "react-router-dom";
import { useCallback } from "react";
import { getQueryParam } from "./utils";

type UseQueryParamListStateReturnType = [string[], (values: string[]) => void];

export const useQueryParamListState = (param: string): UseQueryParamListStateReturnType => {
    const { search, pathname } = useLocation();
    const history = useHistory();

    const stringValue = getQueryParam(search, param);
    const value = stringValue ? stringValue.split(",") : [];

    const setValue = useCallback(
        (values: string[]) => {
            const params = new URLSearchParams(search);
            if (values.length === 0) {
                params.delete(param);
            } else {
                params.set(param, values.join(","));
            }
            history.replace({ pathname, search: params.toString() });
        },
        [search, history, pathname, param]
    );

    return [value, setValue];
};
