import { DealListSource, GetSeatAllDealsParams, LiveStatsTableAdSource } from "@app/core/services";

interface StatusFilter {
    type: "status";
    status: number[];
}

interface DspFilter {
    type: "dsps";
    dsps: number[];
}

interface PricingTypeFilter {
    type: "pricingType";
    pricingType: number;
}

interface TransactionTypeFilter {
    type: "transactionType";
    transactionType: number;
}

interface PriorityFilter {
    type: "priority";
    priority: number[];
}

interface DealOriginFilter {
    type: "dealOrigin";
    dealOrigin: number[];
}

interface DealTypeFilter {
    type: "dealType";
    dealType: number[];
}

interface ExternalStatusFilter {
    type: "externalStatus";
    externalStatus: number[];
}

interface SearchKeywordFilter {
    type: "searchKeyword";
    searchKeyword: string;
}

export interface GetSeatAllDealsLiveStatsRequestBody {
    pagination: {
        page: number;
        pageSize: number;
    };
    sort: {
        column: string; // TODO: limit?
        order: "ASC" | "DESC";
    };
    queryParams: {
        tz: {
            code: string;
        };
        granularity: "5min" | "1hour" | "summary";
        currency: {
            id: number;
        };
        timeRange: "today" | "yesterday" | "currentHour" | "lastHour" | "todayCompare";
    };
    filters: (
        | StatusFilter
        | DspFilter
        | PricingTypeFilter
        | TransactionTypeFilter
        | PriorityFilter
        | DealOriginFilter
        | DealTypeFilter
        | ExternalStatusFilter
        | SearchKeywordFilter
    )[];
}

export const getSeatAllDealsLiveStatsRequestBody = (params: Omit<GetSeatAllDealsParams, "seatId">) => {
    const search = params.searchKeyword ? { type: "searchKeyword", searchKeyword: params.searchKeyword } : undefined;
    const dealType = hasValues(params.filterDealType)
        ? { type: "dealType", dealType: params.filterDealType }
        : undefined;
    const dsps = hasValues(params.filterDsp) ? { type: "dsps", dsps: params.filterDsp } : undefined;
    const status = hasValues(params.filterStatus) ? { type: "status", status: params.filterStatus } : undefined;
    const buyerStatus = hasValues(params.filterBuyerStatus)
        ? { type: "externalStatus", externalStatus: params.filterBuyerStatus }
        : undefined;
    const priority = hasValues(params.filterPriorities)
        ? { type: "priority", priority: params.filterPriorities }
        : undefined;
    return {
        pagination: {
            page: params.page,
            pageSize: params.maxResults,
        },
        sort: params.orderBy
            ? {
                  column: params.orderBy,
                  order: params.isAscending ? "ASC" : "DESC",
              }
            : undefined,
        queryParams: {
            tz: {
                code: params.timeZoneCode || "UTC",
            },
            granularity: "summary",
            currency: params.currencyId
                ? {
                      id: params.currencyId,
                  }
                : null,
            timeRange: "today",
        },
        filters: [search, dealType, dsps, status, buyerStatus, priority].filter(Boolean),
    };
};

export const dealListSourceToLiveStatsAdSourcePreview = (ls: DealListSource): LiveStatsTableAdSource => ({
    adSourceId: ls.id,
    name: ls.name,
    priority: ls.priority.name,
    bookPrice: ls.bookingPrice,
});

const hasValues = (item: unknown): boolean => {
    return Array.isArray(item) && item.length > 0;
};
