import { Form, FormInstance } from "antd";
import { useParams } from "react-router-dom";
import { createContext, FC, useContext } from "react";
import { HierarchyFormsMode } from "@app/features/inventory/HierarchyForms/constants";
import { BrandChannelForm } from "@app/features/inventory/HierarchyForms/BrandChannelForm/useBrandChannelForm";
import { BrandChannel, Seat, useGetSeatSelfQuery } from "@app/core/services";
import { Publisher, useGetPublisherByIdQuery } from "@magnite/client-streaming-platform";

export interface BrandChannelFormInstanceProps {
    brandChannel?: BrandChannel;
    mode: Exclude<HierarchyFormsMode, "copy">;
}

type BrandChannelFormInstanceContextType = BrandChannelFormInstanceProps & {
    seatId: string;
    seat: Seat | null;
    isLoading: boolean;
    publisherId: number;
    useWatch: typeof Form.useWatch;
    publisher: Publisher | undefined;
    form: FormInstance<BrandChannelForm>;
};

const BrandChannelFormInstanceContext = createContext<BrandChannelFormInstanceContextType | null>(null);

export const BrandChannelFormInstance: FC<BrandChannelFormInstanceProps> = ({ children, ...rest }) => {
    const { seatId, publisherId } = useParams<{ seatId: string; publisherId: string }>();
    const [form] = Form.useForm<BrandChannelForm>();
    const { data: seatSelf, isFetching: isLoadingSeat } = useGetSeatSelfQuery(seatId);
    const { data: publisher, isFetching } = useGetPublisherByIdQuery(Number(publisherId));
    const isLoading: boolean = isFetching || isLoadingSeat;

    return (
        <BrandChannelFormInstanceContext.Provider
            value={{
                ...rest,
                form,
                publisher,
                isLoading,
                seatId,
                useWatch: Form.useWatch,
                seat: seatSelf?.seat || null,
                publisherId: Number(publisherId),
            }}
        >
            {children}
        </BrandChannelFormInstanceContext.Provider>
    );
};
export const useBrandChannelFormInstance = (): BrandChannelFormInstanceContextType => {
    const context: BrandChannelFormInstanceContextType | null = useContext(BrandChannelFormInstanceContext);
    if (!context) throw new Error("useBrandChannelFormInstance must be used within a BrandChannelFormInstance");
    return context;
};
