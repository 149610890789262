import moment from "moment-timezone";
import { formatArray, formatNumber } from "@rubicon/utils";
import {
    Currency,
    CurrencyConversion,
    DealStatus,
    AdSourceKey,
    SeatAdSourcesStatus,
    TimeZone,
} from "@app/core/services";
import { isSameDate, isGreaterThanOrNullDate } from "@app/core/utils";
import { AdSourceFloorTypeIds, AdSourceTypeIds, PricingModels } from "../seatAdSources/constants";
import { isAdSourceFieldReadOnlyByKeys } from "../seatAdSources/utils";
import { EligibleSyncedFieldKeysByType, SyncedFieldLabelsByKey } from "./constants";
import { SyncedFieldKey, SyncedFields } from "./types";

type _TypeId = number | null | undefined;
type _Currency = (Partial<Currency> & { id: number }) | null | undefined;
type _Date = moment.Moment | null | undefined;
type _FormNumber = number | string | null | undefined;
type _FormString = string | null | undefined;
type _Status = (Partial<SeatAdSourcesStatus> & { id: number }) | DealStatus | null | undefined;
type _TimeZone = (Partial<TimeZone> & { id: number }) | null | undefined;
type _IdType =
    | {
          id?: number;
      }
    | null
    | undefined;
type _FormNumberArray = (number | string | null | undefined)[] | null | undefined;

export const getEligibleSyncedFieldsKeySet = (
    adSourceTypeId: _TypeId,
    floorTypeId: _TypeId
): Readonly<Set<SyncedFieldKey>> | null => {
    if (isAuctionPrice(adSourceTypeId)) {
        if (isOverrideOrFallbackFloor(adSourceTypeId, floorTypeId)) {
            return EligibleSyncedFieldKeysByType.auctionOverrideOrFallback;
        }
        return EligibleSyncedFieldKeysByType.auction;
    }

    if (isFixedPrice(adSourceTypeId)) {
        return EligibleSyncedFieldKeysByType.fixed;
    }

    if (isProgrammaticGuaranteed(adSourceTypeId) || isConditionalProgrammaticGuaranteed(adSourceTypeId)) {
        return EligibleSyncedFieldKeysByType.programmatic;
    }

    return null;
};

export const getEligibleSyncedFieldLabelsAsList = (adSourceTypeId: _TypeId, floorTypeId: _TypeId): string | null => {
    const eligibleKeys = getEligibleSyncedFieldsKeySet(adSourceTypeId, floorTypeId);
    if (!eligibleKeys) {
        return null;
    }

    return formatArray.asAndList(Array.from(eligibleKeys).map((key) => SyncedFieldLabelsByKey[key as SyncedFieldKey]));
};

export const getSyncedFieldLabelsAsList = (
    syncedFields: SyncedFields,
    adSourceTypeId: _TypeId,
    floorTypeId: _TypeId
): string | null => {
    const eligibleKeys = getEligibleSyncedFieldsKeySet(adSourceTypeId, floorTypeId);
    if (!eligibleKeys) {
        return null;
    }

    const syncedFieldLabels = Object.entries(syncedFields)
        .filter(([key, value]) => value !== undefined && eligibleKeys?.has(key as SyncedFieldKey))
        .map(([key]) => SyncedFieldLabelsByKey[key as SyncedFieldKey]);
    if (!syncedFieldLabels.length) {
        return null;
    }

    return formatArray.asAndList(syncedFieldLabels);
};

export const filterUndefinedSyncedFields = (syncedFields: SyncedFields): SyncedFields =>
    Object.entries(syncedFields)
        .filter((validSyncedFieldEntry) => validSyncedFieldEntry[1] !== undefined)
        .reduce(
            (validSyncedFields, syncedFieldEntry) => ({
                ...validSyncedFields,
                [syncedFieldEntry[0]]: syncedFieldEntry[1],
            }),
            {}
        );

export const hasDefinedSyncedFields = (syncedFields: SyncedFields): boolean =>
    Object.values(filterUndefinedSyncedFields(syncedFields)).some((value) => value !== undefined);

export const isAuctionPrice = (adSourceTypeId: _TypeId): boolean => adSourceTypeId === AdSourceTypeIds.AUCTION_PRICE;

export const isFixedPrice = (adSourceTypeId: _TypeId): boolean => adSourceTypeId === AdSourceTypeIds.FIXED_PRICE;

export const isProgrammaticGuaranteed = (adSourceTypeId: _TypeId): boolean =>
    adSourceTypeId === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED;

export const isConditionalProgrammaticGuaranteed = (adSourceTypeId: _TypeId): boolean =>
    adSourceTypeId === AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED;

export const isFixedPriceModel = (adSourceTypeId: _TypeId, priceModelId: _TypeId): boolean =>
    isFixedPrice(adSourceTypeId) && priceModelId === PricingModels.FIXED_PRICE;

export const isOverrideOrFallbackFloor = (adSourceTypeId: _TypeId, floorTypeId: _TypeId): boolean =>
    isAuctionPrice(adSourceTypeId) &&
    [AdSourceFloorTypeIds.OVERRIDE, AdSourceFloorTypeIds.FALLBACK].includes(Number(floorTypeId));

export const getCurrencyConversionRate = (
    sourceCurrencyId: _FormNumber,
    targetCurrencyId: _FormNumber,
    currencyConversions: CurrencyConversion[] | undefined
): number => {
    const _sourceCurrencyId = isValidPositiveNumber(sourceCurrencyId) ? convertStringToNumber(sourceCurrencyId) : null;
    const _targetCurrencyId = isValidPositiveNumber(targetCurrencyId) ? convertStringToNumber(targetCurrencyId) : null;

    if (
        !_sourceCurrencyId ||
        !_targetCurrencyId ||
        !currencyConversions?.length ||
        sourceCurrencyId === targetCurrencyId
    ) {
        return 1;
    }
    const conversion = currencyConversions.find(
        (conversion) =>
            conversion.sourceCurrencyId === _sourceCurrencyId && conversion.targetCurrencyId === _targetCurrencyId
    );
    return conversion?.rate || 1;
};

export const convertCurrency = (
    value: _FormNumber,
    sourceCurrencyId: _FormNumber,
    targetCurrencyId: _FormNumber,
    currencyConversions: CurrencyConversion[] | undefined
): number | null => {
    if (!value || !isValidPositiveNumber(sourceCurrencyId) || !isValidPositiveNumber(targetCurrencyId)) {
        return null;
    }
    const conversionRate = getCurrencyConversionRate(sourceCurrencyId, targetCurrencyId, currencyConversions);
    return Number(value) * conversionRate;
};

export const convertCurrencyRoundedDownToNearestCent = (
    value: _FormNumber,
    sourceCurrencyId: _FormNumber,
    targetCurrencyId: _FormNumber,
    currencyConversions: CurrencyConversion[] | undefined
): string | null => {
    const convertedCurrency = convertCurrency(value, sourceCurrencyId, targetCurrencyId, currencyConversions);
    if (!convertedCurrency) {
        return null;
    }

    const numericConvertedCurrency = Number(convertedCurrency);
    if (numericConvertedCurrency === 0) {
        return formatNumber.asFixed(numericConvertedCurrency);
    }

    const convertedCurrencyRoundedDownToNearestCent = Math.floor(numericConvertedCurrency * 100) / 100;
    return formatNumber.asFixed(convertedCurrencyRoundedDownToNearestCent);
};

const isEqualFormNumber = (a: _FormNumber, b: _FormNumber): boolean =>
    convertStringToNumber(a) === convertStringToNumber(b);

const isEqualById = (a: _IdType, b: _IdType): boolean => isEqualFormNumber(a?.id, b?.id);

const convertStringToNumber = (n: _FormNumber): number | null | undefined => (typeof n === "string" ? Number(n) : n);

const isValidPositiveNumber = (value: _FormNumber): boolean => {
    const numberValue = Number(value);
    return typeof numberValue === "number" && !isNaN(numberValue) && isFinite(numberValue) && numberValue > 0;
};

const isValidPositiveNumberArray = (values: _FormNumberArray): boolean =>
    Array.isArray(values) && Boolean(values.length) && values.every(isValidPositiveNumber);

export const getMinFormNumber = (values: _FormNumberArray): number | null => {
    if (values === null || values === undefined || !isValidPositiveNumberArray(values)) {
        return null;
    }

    return Math.min(...values.map((value) => Number(value)));
};

const isStartDateReadOnly = (isEdit: boolean, readOnlyAdSourceFields: AdSourceKey[] | null | undefined): boolean =>
    isEdit && isAdSourceFieldReadOnlyByKeys(readOnlyAdSourceFields, "bookingStartDate");

export const shouldSyncStartDate = (
    isOneToOne: boolean,
    isEdit: boolean,
    startDate: _Date,
    adSourceTypeId: _TypeId,
    readOnlyAdSourceFields: AdSourceKey[] | null | undefined,
    compareStartDate?: _Date
): boolean =>
    !isSameDate(startDate, compareStartDate) &&
    isOneToOne &&
    Boolean(startDate) &&
    (isAuctionPrice(adSourceTypeId) ||
        isFixedPrice(adSourceTypeId) ||
        isProgrammaticGuaranteed(adSourceTypeId) ||
        isConditionalProgrammaticGuaranteed(adSourceTypeId)) &&
    !isStartDateReadOnly(isEdit, readOnlyAdSourceFields);

export const shouldSyncEndDate = (
    isOneToOne: boolean,
    endDate: _Date,
    adSourceTypeId: _TypeId,
    compareEndDate?: _Date
): boolean =>
    !isSameDate(endDate, compareEndDate) &&
    isOneToOne &&
    endDate !== undefined &&
    (isAuctionPrice(adSourceTypeId) ||
        isFixedPrice(adSourceTypeId) ||
        isProgrammaticGuaranteed(adSourceTypeId) ||
        isConditionalProgrammaticGuaranteed(adSourceTypeId));

export const shouldSyncTimeZone = (
    isOneToOne: boolean,
    isEdit: boolean,
    timeZone: _TimeZone,
    adSourceTypeId: _TypeId,
    readOnlyAdSourceFields: AdSourceKey[] | null | undefined,
    compareTimeZone?: _TimeZone
): boolean =>
    !isEqualById(timeZone, compareTimeZone) &&
    isOneToOne &&
    Boolean(timeZone) &&
    (isAuctionPrice(adSourceTypeId) ||
        isFixedPrice(adSourceTypeId) ||
        isProgrammaticGuaranteed(adSourceTypeId) ||
        isConditionalProgrammaticGuaranteed(adSourceTypeId)) &&
    !isStartDateReadOnly(isEdit, readOnlyAdSourceFields);

export const shouldSyncCpmRate = (
    isOneToOne: boolean,
    cpmRate: _FormNumber,
    adSourceTypeId: _TypeId,
    floorTypeId: _TypeId,
    compareCpmRate?: _FormNumber
): boolean => {
    cpmRate = convertStringToNumber(cpmRate);
    if (isEqualFormNumber(cpmRate, compareCpmRate) || !isOneToOne || typeof cpmRate !== "number" || cpmRate <= 0) {
        return false;
    }
    return (
        isFixedPrice(adSourceTypeId) ||
        isProgrammaticGuaranteed(adSourceTypeId) ||
        isConditionalProgrammaticGuaranteed(adSourceTypeId) ||
        isOverrideOrFallbackFloor(adSourceTypeId, floorTypeId)
    );
};

export const shouldSyncStatus = (isOneToOne: boolean, status: _Status, compareStatus?: _Status): boolean =>
    !isEqualById(status, compareStatus) && isOneToOne && Boolean(status);

export const shouldSyncCurrency = (
    isOneToOne: boolean,
    currency: _Currency,
    adSourceTypeId: _TypeId,
    compareCurrency?: _Currency
): boolean =>
    !isEqualById(currency, compareCurrency) &&
    isOneToOne &&
    Boolean(currency) &&
    (isProgrammaticGuaranteed(adSourceTypeId) ||
        isConditionalProgrammaticGuaranteed(adSourceTypeId) ||
        isFixedPrice(adSourceTypeId) ||
        isAuctionPrice(adSourceTypeId));

export const shouldSyncImpressions = (
    isOneToOne: boolean,
    impressions: _FormNumber,
    adSourceTypeId: _TypeId,
    compareImpressions?: _FormNumber
): boolean => {
    impressions = convertStringToNumber(impressions);
    return (
        !isEqualFormNumber(impressions, compareImpressions) &&
        isOneToOne &&
        typeof impressions === "number" &&
        impressions > 0 &&
        (isProgrammaticGuaranteed(adSourceTypeId) || isConditionalProgrammaticGuaranteed(adSourceTypeId))
    );
};

export const shouldSyncGroupCpmRate = (
    isOneToMany: boolean,
    adSourceTypeId: _TypeId,
    priceModelId: _TypeId,
    updatedCpmRate: _FormNumber,
    updatedCurrencyId: _FormNumber,
    dealCpmRate: _FormNumber,
    dealCurrencyId: _FormNumber,
    adSourceCpmRate: _FormNumber,
    adSourceCurrencyId: _FormNumber,
    currencyConversions: CurrencyConversion[] | undefined
): boolean => {
    const hasUpdatedCpmRate = isValidPositiveNumber(updatedCpmRate);
    const hasUpdatedCurrency = isValidPositiveNumber(updatedCurrencyId);

    if (
        !isOneToMany ||
        !isFixedPriceModel(adSourceTypeId, priceModelId) ||
        (!hasUpdatedCpmRate && !hasUpdatedCurrency)
    ) {
        return false;
    }

    const cpmRate = Number(updatedCpmRate || dealCpmRate);
    const currencyId = updatedCurrencyId || dealCurrencyId;

    if (isValidPositiveNumber(cpmRate) && isValidPositiveNumber(adSourceCpmRate)) {
        const conversionRate = getCurrencyConversionRate(currencyId, adSourceCurrencyId, currencyConversions);
        return cpmRate * conversionRate < Number(adSourceCpmRate);
    }

    return isValidPositiveNumber(cpmRate);
};

// temporarily disable this feature due to autoscale issues
export const shouldSyncGroupStartDate = (
    _isOneToMany: boolean,
    _adSourceTypeId: _TypeId,
    _updatedStartDate: _Date,
    _updatedTimeZoneCode: _FormString,
    _dealStartDate: _Date,
    _dealTimeZoneCode: _FormString,
    _adSourceStartDate: _Date,
    _adSourceTimeZoneCode: _FormString
): boolean => false;

// TODO: Uncomment and implement this function when product requirements are finalized
// export const shouldSyncGroupStartDate = (
//     isOneToMany: boolean,
//     adSourceTypeId: _TypeId,
//     updatedStartDate: _Date,
//     updatedTimeZoneCode: _FormString,
//     dealStartDate: _Date,
//     dealTimeZoneCode: _FormString,
//     adSourceStartDate: _Date,
//     adSourceTimeZoneCode: _FormString
// ): boolean => {
//     const hasUpdatedStartDate = Boolean(updatedStartDate);
//     const hasUpdatedTimeZone = Boolean(updatedTimeZoneCode);

//     if (
//         !isOneToMany ||
//         !(isAuctionPrice(adSourceTypeId) || isFixedPrice(adSourceTypeId)) ||
//         (!hasUpdatedStartDate && !hasUpdatedTimeZone)
//     ) {
//         return false;
//     }

//     const startDate = updatedStartDate || dealStartDate;
//     const timeZoneCode = updatedTimeZoneCode || dealTimeZoneCode;

//     return isLessThanOrOnlyDate(startDate, timeZoneCode, adSourceStartDate, adSourceTimeZoneCode);
// };

export const shouldSyncGroupEndDate = (
    isOneToMany: boolean,
    adSourceTypeId: _TypeId,
    updatedEndDate: _Date,
    updatedTimeZoneCode: _FormString,
    dealEndDate: _Date,
    dealTimeZoneCode: _FormString,
    adSourceEndDate: _Date,
    adSourceTimeZoneCode: _FormString
): boolean => {
    const hasUpdatedEndDate = Boolean(updatedEndDate) || updatedEndDate === null;
    const hasUpdatedTimeZone = Boolean(updatedTimeZoneCode);

    if (
        !isOneToMany ||
        !(isAuctionPrice(adSourceTypeId) || isFixedPrice(adSourceTypeId)) ||
        !adSourceEndDate ||
        (!hasUpdatedEndDate && !hasUpdatedTimeZone)
    ) {
        return false;
    }

    const endDate = updatedEndDate !== undefined ? updatedEndDate : dealEndDate;
    const timeZoneCode = updatedTimeZoneCode || dealTimeZoneCode;

    return isGreaterThanOrNullDate(endDate, timeZoneCode, adSourceEndDate, adSourceTimeZoneCode);
};
