import { FC, PropsWithChildren } from "react";
import { css } from "@emotion/css";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Row, Space, Typography } from "antd";
import { gray7, gray8, ResizableDrawer } from "@rubicon/antd-components";
import { FORM_ITEMS } from "../../constants";
import {
    formatEstAdBreakCount,
    formatEstAvgConcurrentViewers,
    formatEstPeakConcurrentViewers,
    formatEstImpOpportunities,
    formatEstTotalAvailAdMins,
    formatStartDate,
    formatTimeZone,
    formatDuration,
} from "../../utils";
import { useGetTimeZonesQuery } from "@app/core/services";
import { LiveEvent } from "@app/core/services/console/liveEvents";
import { mapLiveEventStartEndDatesToDuration } from "../../InventoryLiveEventsForm";

const drawerClass = css`
    .ant-drawer-body {
        padding: 0;
    }
`;

interface Props {
    title: string;
    liveEvent: LiveEvent;
    handleEdit: (eventId: number | string) => void;
    handleClose: () => void;
}

export const InventoryLiveEventDetailsDrawer: FC<Props> = ({ title, liveEvent, handleEdit, handleClose }) => {
    const { data: timezones } = useGetTimeZonesQuery();

    return (
        <ResizableDrawer
            width="25%"
            open={true}
            closable={false}
            onClose={handleClose}
            placement="right"
            className={drawerClass}
        >
            <DrawerHeader title={title} closeHandler={handleClose}>
                <Space>
                    <Button type="primary" onClick={() => handleEdit(liveEvent.id)}>
                        Edit
                    </Button>
                    <Button>Change history</Button>
                </Space>
            </DrawerHeader>
            <Space direction="vertical" style={{ padding: "16px 24px" }}>
                <Row>
                    <Col span={24}>
                        <Typography.Text strong>{FORM_ITEMS.START_DATE.label}</Typography.Text>
                        <Typography.Paragraph>{formatStartDate(liveEvent.startDate)}</Typography.Paragraph>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Typography.Text strong>{FORM_ITEMS.DURATION.label}</Typography.Text>
                        <Typography.Paragraph>
                            {formatDuration(mapLiveEventStartEndDatesToDuration(liveEvent))}
                        </Typography.Paragraph>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Typography.Text strong>{FORM_ITEMS.TIMEZONE.label}</Typography.Text>
                        <Typography.Paragraph>{formatTimeZone(liveEvent.timeZone?.id, timezones)}</Typography.Paragraph>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Typography.Text strong>{FORM_ITEMS.EST_IMP_OPPORTUNITIES.label}</Typography.Text>
                        <Typography.Paragraph>
                            {formatEstImpOpportunities(liveEvent.estimatedImpressionOpportunities)}
                        </Typography.Paragraph>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Typography.Text strong>{FORM_ITEMS.EST_AVG_CONCURRENT_VIEWERS.label}</Typography.Text>
                        <Typography.Paragraph>
                            {formatEstAvgConcurrentViewers(liveEvent.estimatedAverageConcurrentUsers)}
                        </Typography.Paragraph>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Typography.Text strong>{FORM_ITEMS.EST_PEAK_CONCURRENT_VIEWERS.label}</Typography.Text>
                        <Typography.Paragraph>
                            {formatEstPeakConcurrentViewers(liveEvent.estimatedPeakConcurrentUsers)}
                        </Typography.Paragraph>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Typography.Text strong>{FORM_ITEMS.EST_AD_BREAK_COUNT.label}</Typography.Text>
                        <Typography.Paragraph>
                            {formatEstAdBreakCount(liveEvent.estimatedAdBreakCount)}
                        </Typography.Paragraph>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Typography.Text strong>{FORM_ITEMS.EST_TOTAL_AVAIL_AD_MINS.label}</Typography.Text>
                        <Typography.Paragraph>
                            {formatEstTotalAvailAdMins(liveEvent.estimatedTotalAvailableAdMinutes)}
                        </Typography.Paragraph>
                    </Col>
                </Row>
            </Space>
        </ResizableDrawer>
    );
};

const drawerHeaderClass = css`
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
`;

const DrawerHeader: FC<PropsWithChildren<{ title: string; closeHandler: () => void }>> = ({
    title,
    closeHandler,
    children,
}) => {
    return (
        <Flex vertical className={drawerHeaderClass}>
            <Flex align="baseline" justify="space-between">
                <Typography.Title level={4} style={{ margin: 0, padding: "16px 24px" }}>
                    {title}
                </Typography.Title>
                <Button
                    type="text"
                    onClick={() => closeHandler()}
                    className={css`
                        color: ${gray7};

                        &:hover {
                            color: ${gray8};
                            background-color: transparent;
                        }
                    `}
                >
                    <CloseOutlined
                        className={css`
                            svg {
                                width: 1.15em;
                                height: 1.15em;
                            }
                        `}
                    />
                </Button>
            </Flex>
            <div style={{ padding: "0px 24px 16px 24px" }}>{children}</div>
        </Flex>
    );
};
