import { FC } from "react";
import { Layout, Space } from "antd";
import { Footer } from "@app/core/components";
import { useGetCurrenciesPrefetch } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { DealHealthTable } from "./DealHealthTable";
import { DealHealthTableFilters } from "./DealHealthTableFilters";
import { DealHealthToolbar } from "./DealHealthToolbar";

// deprecated
import DealHealthDeprecatedTable from "./deprecated/DealHealthTable";
import DealHealthDeprecatedTableFilters from "./deprecated/DealHealthTableFilters";
import { selectIsBeta } from "./deprecated/reducer";

const DealHealthPage: FC = () => {
    useGetCurrenciesPrefetch();
    const isBeta = useAppSelector(selectIsBeta); // TODO: remove after beta is complete

    return (
        <>
            <DealHealthToolbar />
            <Layout.Content>
                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                    {isBeta ? (
                        <>
                            <DealHealthTableFilters />
                            <DealHealthTable />
                        </>
                    ) : (
                        <>
                            <DealHealthDeprecatedTableFilters />
                            <DealHealthDeprecatedTable />
                        </>
                    )}
                </Space>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
export default DealHealthPage;
