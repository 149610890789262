import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useGetNetworkEndpointsAwsRegionsQuery } from "@app/core/services";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { AdSourceQpsLimitOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/useExchangeProtectionsForm";

const { useWatch } = Form;

interface UseAwsRegions {
    options: LabeledValue[];
    isFetching: boolean;
}

export const useAwsRegions = (): UseAwsRegions => {
    const { data, isFetching } = useGetNetworkEndpointsAwsRegionsQuery();

    const adSourceQpsLimitOptions: AdSourceQpsLimitOption[] = useWatch(INVENTORY_FORM_FIELDS.QPS_LIMITS.name) ?? [];
    const selectedRegions = adSourceQpsLimitOptions.map((option) => option?.qpsLimit?.awsRegion?.id);
    const options = useMemo(
        () =>
            (data || [])
                .filter((region) => region.active)
                .map((region) => ({
                    value: region.code,
                    label: region.name,
                    id: region.id,
                    disabled: selectedRegions.includes(region.id),
                })),
        [data, selectedRegions]
    );

    return {
        isFetching,
        options,
    };
};
