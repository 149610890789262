import { DealListItem } from "@app/core/services";
import { PRICING_TYPES } from "@app/features/deals/DealListPage/constants";
import {
    SORT_ORDER,
    TRANSPARENCY_HIDDEN_VALUE,
    TRANSPARENCY_MASKED_VALUE,
    TRANSPARENCY_NONE_VALUE,
    TRANSPARENCY_OVERRIDE_VALUE,
    TRANSPARENCY_SHARED_VALUE,
    TRANSPARENCY_SPECIFIC_VALUE,
} from "@app/features/deals/constants";
import { DEAL_TYPE_PROGRAMMATIC_GUARANTEE, DEAL_TYPE_FIXED_PRICE_DEAL } from "@app/features/deals/constants";
import {
    DealAdSourceTransparencey,
    DealAdSourceTransparenceyMasked,
    DealAdSourceTransparenceyOverride,
    DealAdSourceTransparencySpecific,
    FreqCapping,
} from "./DealForm/types";

export const getPricingTypeLabel = (deal: DealListItem) => {
    if (deal?.dealPricingType?.id == PRICING_TYPES.FIXED.id) {
        return PRICING_TYPES.FIXED.label;
    }
    return PRICING_TYPES.AUCTION.label;
};
export const getDealFreqCappingsItems = (freqCappings: FreqCapping[]) => {
    return freqCappings.map(({ id, ...rest }) => {
        return rest;
    });
};

export const getDealTableSort = (sortBy: string, sortOrder: string) => {
    if (sortBy && sortOrder === SORT_ORDER.ASCENDING) {
        return { orderBy: sortBy, asc: true };
    }
    if (sortBy && sortOrder === SORT_ORDER.DESCENDING) {
        return { orderBy: sortBy, asc: false };
    }
    return { orderBy: "", asc: null };
};

export const getCPMRateLabel = (dealType: { id: number; name: string }) => {
    if (dealType?.id == DEAL_TYPE_PROGRAMMATIC_GUARANTEE.id || dealType?.id == DEAL_TYPE_FIXED_PRICE_DEAL.id) {
        return PRICING_TYPES.FIXED.label;
    }
    return PRICING_TYPES.AUCTION.label;
};
export const getSharedMarketplacesSeats = (items: number[]): Array<{ id: number }> =>
    items.map((item) => ({ id: item }));

export const getTransparencyToBoolean = (
    radioValue:
        | DealAdSourceTransparencey
        | DealAdSourceTransparenceyOverride
        | DealAdSourceTransparenceyMasked
        | DealAdSourceTransparencySpecific
        | string
        | null
) => {
    switch (radioValue) {
        case TRANSPARENCY_SHARED_VALUE:
        case TRANSPARENCY_MASKED_VALUE:
        case TRANSPARENCY_OVERRIDE_VALUE:
            return true;
        case TRANSPARENCY_HIDDEN_VALUE:
        case TRANSPARENCY_NONE_VALUE:
        case TRANSPARENCY_SPECIFIC_VALUE:
            return false;

        default:
            //TODO because transparency allowContent cane have not only shared/hidden value
            return true;
    }
};
