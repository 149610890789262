import { useForm, useWatch } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Modal, notification, Typography } from "antd";
import {
    AdvancedFloor,
    Floor,
    FloorCreatePayload,
    TargetingMode,
    useCreateAdvancedFloorMutation,
    useUpdateAdvancedFloorMutation,
} from "@app/core/services/console";
import { CREATE_FLOOR_FORM_ITEMS_NAME } from "@app/features/inventory/constants";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useTargetingForm } from "@app/features/targeting/useTargetingForm";
import { TargetingFormKeys } from "@app/features/targeting/constants";
import { Store } from "rc-field-form/lib/interface";

interface FloorAdvancedFormValues {
    [CREATE_FLOOR_FORM_ITEMS_NAME.NAME]: string;
    [CREATE_FLOOR_FORM_ITEMS_NAME.PRICE]: number;
}

const getInitialValues = (
    mode: "create" | "edit",
    floor: Floor | AdvancedFloor | null | undefined
): Store | undefined => {
    if (mode === "create") {
        return {
            [CREATE_FLOOR_FORM_ITEMS_NAME.NAME]: "",
            [CREATE_FLOOR_FORM_ITEMS_NAME.PRICE]: 0,
        };
    }

    if (!floor) {
        return undefined;
    }

    return {
        [CREATE_FLOOR_FORM_ITEMS_NAME.NAME]: floor.name,
        [CREATE_FLOOR_FORM_ITEMS_NAME.PRICE]: floor.price / 1000,
    };
};

export const useFloorAdvancedForm = (mode: "create" | "edit", floor?: Floor | AdvancedFloor | null) => {
    const [createFloor, { isLoading: isCreating }] = useCreateAdvancedFloorMutation();
    const [updateFloor, { isLoading: isUpdating }] = useUpdateAdvancedFloorMutation();

    const [form] = useForm<FloorAdvancedFormValues>();
    const floorPrice = useWatch(CREATE_FLOOR_FORM_ITEMS_NAME.PRICE, form);

    const { seatId, id } = useParams<{ seatId?: string; id?: string }>();
    const history = useHistory();

    const { targetingPayloadsByFormKey, targetingModePayloadsByFormKey, loadTargeting } = useTargetingForm(
        TargetingFormKeys.InventoryAdvancedFloors
    );

    const handleCancel = () => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_ADVANCED(Number(seatId)));
    };

    const handleCreate = async () => {
        const { name, price } = form.getFieldsValue([
            CREATE_FLOOR_FORM_ITEMS_NAME.NAME,
            CREATE_FLOOR_FORM_ITEMS_NAME.PRICE,
        ]);
        const targetingPayload = targetingPayloadsByFormKey[TargetingFormKeys.InventoryAdvancedFloors];
        const body: FloorCreatePayload = {
            id: null,
            name: name.trim(),
            price: price * 1000,
            seat: { id: Number(seatId) },
        };

        if (targetingPayload.length) {
            Object.assign(body, {
                targeting: targetingPayload,
                targetingMode: targetingModePayloadsByFormKey[TargetingFormKeys.InventoryAdvancedFloors],
            });
        }

        try {
            await createFloor({ seatId: Number(seatId), body }).unwrap();
            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_ADVANCED(Number(seatId)));
            // setTimeout to prevent flicker after route change
            setTimeout(() => notification.success({ message: "Floor created successfully" }), 0);
        } catch (err) {
            notification.error({ message: err.data.errorDescription });
        }
    };

    const handleEdit = async () => {
        const { name, price } = form.getFieldsValue([
            CREATE_FLOOR_FORM_ITEMS_NAME.NAME,
            CREATE_FLOOR_FORM_ITEMS_NAME.PRICE,
        ]);
        const body: FloorCreatePayload = {
            id: Number(id),
            name,
            price: price * 1000,
            seat: { id: Number(seatId) },
        };

        const targetingPayload = targetingPayloadsByFormKey[TargetingFormKeys.InventoryAdvancedFloors];

        Object.assign(body, {
            targeting: targetingPayload,
            targetingMode: targetingModePayloadsByFormKey[TargetingFormKeys.InventoryAdvancedFloors],
        });

        try {
            await updateFloor({ seatId: Number(id), body }).unwrap();
            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_ADVANCED(Number(seatId)));
            // setTimeout to prevent flicker after route change
            setTimeout(() => notification.success({ message: "Floor updated successfully" }), 0);
        } catch (err) {
            notification.error({ message: err.data.errorDescription });
        }
    };

    const handleSubmit = async () => {
        try {
            await form.validateFields();

            const targetingPayload = targetingPayloadsByFormKey[TargetingFormKeys.InventoryAdvancedFloors];
            if (!targetingPayload.length) {
                Modal.confirm({
                    title: "Warning",
                    content: (
                        <div>
                            You are about to create a floor with no targeting. Are you sure you want to apply this floor
                            price to <Typography.Text strong>ALL</Typography.Text> inventory?
                        </div>
                    ),
                    onOk: handleCreateOrEdit,
                    okText: "Yes",
                    cancelText: "No",
                });
                return;
            }
            handleCreateOrEdit();
        } catch (error) {
            form.scrollToField(error.errorFields[0].name, { behavior: "smooth", block: "center" });
        }
    };

    const handleCreateOrEdit = async () => {
        if (mode === "edit") {
            handleEdit();
            return;
        }
        handleCreate();
    };

    useEffect(() => {
        if (mode === "edit" && floor) {
            loadTargeting(
                TargetingFormKeys.InventoryAdvancedFloors,
                floor.targeting,
                floor.targetingMode as TargetingMode
            );
        }
    }, [mode, floor, loadTargeting]);

    useEffect(() => {
        if (floor) {
            form.resetFields();
        }
    }, [form, floor, mode]);

    return {
        form,
        floorPrice,
        initialValues: getInitialValues(mode, floor),

        handleSubmit,
        handleCancel,

        isLoading: isCreating || isUpdating,
    };
};
