import { FC } from "react";
import { Card } from "antd";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { BRAND_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { BrandLabels } from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormSections/LabelsSection/Fields/BrandLabels";

export const LabelsInternalOnly: FC = () => (
    <Card title="Internal Only" type="inner" styles={{ body: INVENTORY_CARD_BODY_STYLE }}>
        <BrandLabels labelType="internal" {...BRAND_FORM_FIELDS.INTERNAL_LABELS} />
    </Card>
);
