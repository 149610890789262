import { AdSourcePriceModel, SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceTypeIds, PricingModels } from "@app/features/seatAdSources/constants";
import { Form } from "antd";

const { useWatch } = Form;

export const useIsFieldVisible = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeId = adSourceType?.id;

    const priceModel = useWatch<AdSourcePriceModel>(AD_SOURCE_FIELDS.PRICE_MODEL.name);
    switch (adSourceTypeId) {
        case AdSourceTypeIds.PROGRAMMATIC_GUARANTEED:
            return priceModel?.id === PricingModels.FIXED_PRICE;
        case AdSourceTypeIds.FIXED_PRICE:
            return false;
        case AdSourceTypeIds.AUCTION_PRICE:
            return false;
        case AdSourceTypeIds.MARKETPLACE:
            return false;
        case AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED:
        case AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED:
            return true;
        case AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE:
        case AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED:
        default:
            return false;
    }
};
