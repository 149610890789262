import { Card } from "antd";
import { FC } from "react";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { SUPPLY_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { SupplyLabels } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormSections/LabelsSection/Fields/SupplyLabels";

export const LabelsInternalOnly: FC = () => {
    return (
        <Card title="Internal Only" type="inner" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
            <SupplyLabels labelType="internal" {...SUPPLY_FORM_FIELDS.INTERNAL_LABELS} />
        </Card>
    );
};
