import { Form } from "antd";
import { MarketplaceInfo, SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceTypeIds, DealTypeIds } from "@app/features/seatAdSources/constants";
import { useEffect, useState } from "react";

export const useValidateAttachedDeals = () => {
    const [validationError, setValidationError] = useState<string | null>(null);

    const dealsList = Form.useWatch(AD_SOURCE_FIELDS.DEMAND.name);
    const adSourceType = Form.useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeId = adSourceType?.id;
    const isRequired =
        adSourceTypeId !== AdSourceTypeIds.OPEN_AUCTION && adSourceTypeId !== AdSourceTypeIds.PRE_BID_DEALS;

    useEffect(() => {
        if (dealsList?.length) {
            setValidationError(null);
        }
    }, [dealsList]);

    const attachedDealsValidator = (_: unknown, value: MarketplaceInfo[]) => {
        if (isRequired && !value?.length) {
            setValidationError("At least One deal must be specified");
            return Promise.reject();
        }
        if (!isRequired && !value?.length) {
            setValidationError(null);
            return Promise.resolve();
        }

        const isLegacyDealAttached = value.some(({ deal }) => {
            return deal.dealType.id === DealTypeIds.LEGACY;
        });

        if (isLegacyDealAttached) {
            setValidationError(null);
            return Promise.resolve();
        }

        if (
            adSourceTypeId === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED ||
            adSourceTypeId === AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED
        ) {
            const isDealsHavePgType = value.every(
                ({ deal }) => deal.dealType.id === DealTypeIds.PROGRAMMATIC_GUARANTEED
            );
            if (!isDealsHavePgType) {
                setValidationError("Deal Type need to match the parent Ad Source Type.");
                return Promise.reject();
            }
        }

        if (adSourceTypeId === AdSourceTypeIds.FIXED_PRICE) {
            const isDealsHaveFpType = value.every(({ deal }) => deal.dealType.id === DealTypeIds.FIXED_PRICE);

            if (!isDealsHaveFpType) {
                setValidationError("Deal Type need to match the parent Ad Source Type.");
                return Promise.reject();
            }
        }
        if (adSourceTypeId === AdSourceTypeIds.AUCTION_PRICE) {
            const isDealsHaveApType = value.every(({ deal }) => deal.dealType.id === DealTypeIds.AUCTION_PRICE);

            if (!isDealsHaveApType) {
                setValidationError("Deal Type need to match the parent Ad Source Type.");
                return Promise.reject();
            }
        }
        setValidationError(null);
        return Promise.resolve();
    };

    return { attachedDealsValidator, validationError };
};
