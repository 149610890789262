import { useState } from "react";
import { notification } from "antd";
import { IdNameEntity } from "@app/core/services";
import { DEFAULT_FALLBACK_MESSAGE } from "@app/features/inventory/constants";

const initialState = {
    isOpen: false,
    id: null,
};

interface UseBrandSafetyDeleteModal {
    isOpen: boolean;
    handleOpen: (id: IdNameEntity) => void;
    handleCancel: () => void;
    handleConfirm: () => Promise<void>;
    name: string;
}

export const useBrandSafetyDeleteModal = (
    deleteTrigger: (id: number) => { unwrap: () => Promise<void> },
    postDeleteEvent?: () => void
): UseBrandSafetyDeleteModal => {
    const [state, setState] = useState<{ isOpen: boolean; id: null | IdNameEntity }>(initialState);

    const handleOpen = (id: IdNameEntity) => setState({ isOpen: true, id });

    const handleCancel = () => setState(initialState);

    const handleConfirm = async (): Promise<void> => {
        if (!state.id) return;
        try {
            await deleteTrigger(state.id.id).unwrap();
            setState(initialState);
            notification.success({ message: `${state.id?.name} deleted successfully` });
            postDeleteEvent?.();
        } catch (err) {
            setState({ ...state, isOpen: false });
            notification.error({ message: err.message || err.data.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };

    return {
        isOpen: state.isOpen,
        name: state.id?.name || "",
        handleOpen,
        handleCancel,
        handleConfirm,
    };
};
