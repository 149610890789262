import { LiveEvent } from "@app/core/services/console/liveEvents";
import moment from "moment-timezone";

export const currentlyLiveEvents = (data: LiveEvent[]) => {
    return data?.filter((liveEvent) => moment(liveEvent.endDate).isAfter(moment()));
};

export const completedLiveEvents = (data: LiveEvent[]) => {
    return data?.filter((liveEvent) => moment(liveEvent.endDate).isBefore(moment()));
};
