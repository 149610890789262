import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetRegionsQuery } from "@magnite/client-streaming-platform";

interface UseRegion {
    options: LabeledValue[];
    isFetching: boolean;
}

export const useRegion = (): UseRegion => {
    const { data = [], isFetching } = useGetRegionsQuery();
    const options = useMemo(() => (data || []).map((region) => ({ value: region.id, label: region.name })), [data]);

    return {
        isFetching,
        options,
    };
};
