import { FC } from "react";
import { LiveStatsTableAdSource } from "@app/core/services";
import { Popover, Col, Row, Typography } from "antd";
import { formatNumber } from "@rubicon/utils";
import { Dashes } from "@app/core/components";
import { brandCobalt } from "@rubicon/antd-components";
import { SeatAdSourcesDetailsDrawerSecondaryView } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/types";

interface DealFloorPricesProps {
    dealAdSources: LiveStatsTableAdSource[];
    currencyType: {
        id: number;
        code: string;
    };
    openDrawer: (adSourceId: number, secondaryDetailsView?: SeatAdSourcesDetailsDrawerSecondaryView) => void;
}

interface PopoverProps {
    adSources: LiveStatsTableAdSource[];
    currencyCode: string;
    handleOpenAdSourceDrawer: (number) => void;
}

export const getDisplayValue = (rate: number, currencyCode: string, isBookingPrice?: boolean): string => {
    const value = isBookingPrice ? rate / 1000 : rate;
    return formatNumber.asMoneyVerbose(value, currencyCode);
};
export const PopoverContent: FC<PopoverProps> = ({ adSources, handleOpenAdSourceDrawer, currencyCode }) => {
    return (
        <>
            <Typography.Paragraph>
                The deal is configured to multiple ad sources with <br />
                different floor prices <br />
            </Typography.Paragraph>
            <Row>
                <Col span={16}>Name</Col>
                <Col span={8}>Floor Price</Col>
            </Row>
            {adSources?.map((adSource) => (
                <Row key={adSource?.adSourceId}>
                    <Col span={16}>
                        <a
                            data-sdet="deal-floor-multiple-prices-item"
                            style={{ textTransform: "capitalize", color: brandCobalt }}
                            onClick={() => handleOpenAdSourceDrawer(adSource?.adSourceId)}
                        >
                            {adSource?.name}
                        </a>
                    </Col>

                    <Col span={8}>{getDisplayValue(adSource?.bookPrice, currencyCode, true)}</Col>
                </Row>
            ))}
        </>
    );
};

export const DealFloorPrices: FC<DealFloorPricesProps> = ({ dealAdSources, currencyType, openDrawer }) => {
    const adSources: LiveStatsTableAdSource[] = [...(dealAdSources || [])].sort((a, b) => a?.bookPrice - b?.bookPrice);
    if (!adSources.length) {
        return <Dashes />;
    }

    const prices = dealAdSources?.map(({ bookPrice }) => bookPrice);

    return prices.length > 1 ? (
        <Popover
            title="Multiple Floor Prices"
            content={
                <PopoverContent
                    adSources={adSources}
                    handleOpenAdSourceDrawer={openDrawer}
                    currencyCode={currencyType.code}
                />
            }
        >
            <span data-sdet="deal-floor-multiple-prices" style={{ color: "#1890FF" }}>
                {getDisplayValue(prices[0], currencyType.code, true)} -{" "}
                {getDisplayValue(prices[prices.length - 1], currencyType.code, true)} (Floor)
            </span>
        </Popover>
    ) : (
        <span data-sdet="deal-floor-single-price" style={{ color: "#1890FF" }}>
            {getDisplayValue(prices[0], currencyType.code, true)} (Floor)
        </span>
    );
};
