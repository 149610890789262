import { FC } from "react";
import { Col, Form, Row, Select } from "antd";
import { useChartMetricsFilters } from "./useChartMetricsFilters";

export const ChartMetricsFilters: FC = () => {
    const {
        metricOneOptions,
        metricTwoOptions,
        metricOneValue,
        metricTwoValue,
        handleChangeMetricOneValue,
        handleChangeMetricTwoValue,
    } = useChartMetricsFilters();

    return (
        <Form key="form" data-sdet="chart-metrics-filters">
            <Row gutter={[16, 16]} align="middle">
                <Col xs={24} sm={11} md={11} lg={8} xl={6} xxl={4}>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Select
                            onChange={handleChangeMetricOneValue}
                            optionFilterProp="label"
                            options={metricOneOptions}
                            showSearch
                            style={{ width: "100%" }}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            value={metricOneValue?.value}
                            data-sdet="chart-metric-one-value"
                        />
                    </Form.Item>
                </Col>
                <Col xs={2} sm={2} md={2} lg={1} xl={1} xxl={1} style={{ textAlign: "center" }}>
                    v.s.
                </Col>
                <Col xs={24} sm={11} md={11} lg={8} xl={6} xxl={4}>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Select
                            onChange={handleChangeMetricTwoValue}
                            optionFilterProp="label"
                            options={metricTwoOptions}
                            showSearch
                            style={{ width: "100%" }}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            value={metricTwoValue?.value}
                            data-sdet="chart-metric-two-value"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
