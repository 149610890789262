import { LabeledValue } from "antd/lib/select";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME, DEAL_TYPES } from "@app/features/deals/constants";
import { useGetGlobalNetworksQuery } from "@magnite/client-streaming-platform";
import { dealFormDspFieldChange } from "@app/features/deals/DealForm/reducer";
import { DealType } from "@app/features/deals/DealForm/types";
import {
    AUCTION_PRICE_AD_SOURCE_TYPE_ID,
    FIXED_PRICE_AD_SOURCE_TYPE_ID,
    PROGRAMMATIC_GUARANTEED_AD_SOURCE_TYPE_ID,
} from "@app/features/deals/DealForm/useDealForm";

interface Options extends LabeledValue {
    hasSync: boolean;
}

interface UseDealDspField {
    isLoading: boolean;
    options: Options[];
    handleChange: (value: number, option) => void;
    value: number | null;
    onSearch: (value: string) => void;
    searchValue: string | undefined;
}

const getAdSourceTypeId = (dealType: DealType): number => {
    if (dealType === DEAL_TYPES.FIXED) {
        return FIXED_PRICE_AD_SOURCE_TYPE_ID;
    }
    if (dealType === DEAL_TYPES.AUCTION) {
        return AUCTION_PRICE_AD_SOURCE_TYPE_ID;
    }
    return PROGRAMMATIC_GUARANTEED_AD_SOURCE_TYPE_ID;
};

export const useDealDspField = (changeSyncDsp: (hasSync: boolean) => void, mode: string): UseDealDspField => {
    const dispatch = useAppDispatch();
    const [searchValue, setSearchValue] = useState<string>("");

    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.DSP]);
    const dealType = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);
    const adSourceTypeId = getAdSourceTypeId(dealType);
    const { data, isFetching } = useGetGlobalNetworksQuery({ adSourceTypeId });

    const options = useMemo(
        () =>
            (data || [])
                .map((item) => ({
                    label: item.name,
                    value: item.id,
                    seatId: item.seat.id,
                    hasSync: Boolean(item.externalDealSyncIntegrations?.length),
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        [data]
    );

    const handleChange = (value: number, { hasSync }: { hasSync: boolean }): void => {
        changeSyncDsp(hasSync);
        dispatch(dealFormDspFieldChange(value));
    };
    const onSearch = (value: string): void => {
        setSearchValue(value);
    };

    useEffect(() => {
        if (mode === "copy" || mode === "edit") {
            const hasSync = options.some((item) => item.value === value && item.hasSync);
            hasSync && changeSyncDsp(hasSync);
        }
    }, [mode, options, value, changeSyncDsp]);

    return {
        isLoading: isFetching,
        onSearch,
        searchValue,
        handleChange,
        options,
        value,
    };
};
