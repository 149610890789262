import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useSearchQuery } from "@magnite/client-streaming-platform";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useMemo, useState } from "react";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { LabeledValue } from "antd/lib/select";

interface UseDealSharedSeatsField {
    isFetching: boolean;
    options: LabeledValue[];
    value: number[] | null;
    searchValue: string;
    handleSearch: (value: string) => void;
    handleChange: (values: number[]) => void;
}

export const useDealSharedSeatsField = (): UseDealSharedSeatsField => {
    const dispatch = useAppDispatch();
    const [searchValue, setSearchValue] = useState<string>("");

    const { data, isFetching } = useSearchQuery({
        type: "supplyseat",
        keyword: searchValue,
        page: 1,
        max: 100,
    });
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.SHARED_SEATS]);
    const options = useMemo(() => (data || []).map((item) => ({ label: item.name, value: item.id })), [data]);

    const handleChange = (value: number[]): void => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.SHARED_SEATS, value }));
    };
    const handleSearch = (value: string): void => setSearchValue(value);

    return {
        isFetching,
        options,
        value,
        searchValue,
        handleSearch,
        handleChange,
    };
};
