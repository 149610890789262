import { Col, Form, Input, Row } from "antd";
import { FC } from "react";
import { Brand, useGetSeatBrandsQuery } from "@app/core/services";
import {
    SearchItemSeat,
    useSearchQuery,
    SeatPublisher,
    useGetSeatPublishersQuery,
} from "@magnite/client-streaming-platform";
import { BULK_OPERATION_FORM_ITEMS } from "../../../constants";
import { GenericLoadOnScrollField } from "../../../shared/GenericLoadOnScrollField";
import { CopyInventoryChildrenField } from "./CopyInventoryChildrenField";
import { useDestinationSeat } from "./useDestinationSeat";

export const BulkOperationCopyBrandForm: FC = () => {
    const form = Form.useFormInstance();
    const { seatId, destinationSeat } = useDestinationSeat(form);

    return (
        <>
            <Row>
                <Col xs={24} md={12}>
                    <Form.Item
                        {...BULK_OPERATION_FORM_ITEMS.BRAND_TO_COPY}
                        rules={[{ required: true, message: "Please select a Brand to Copy" }]}
                        wrapperCol={{ xs: 24 }}
                    >
                        <GenericLoadOnScrollField<Brand>
                            labelInValue
                            sdet="brand-field"
                            useGetOptionsQuery={useGetSeatBrandsQuery}
                            extraParams={{ seatId, channels: false }}
                            optionMapper={({ name, id, publisher: { name: pubName, seat } }: Brand) => ({
                                label: `${seat.name} > ${pubName} > ${name}`,
                                value: id,
                            })}
                            onChange={() => form.setFieldsValue({ recursiveCopy: false, copyScope: null })}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={{ span: 10, offset: 2 }}>
                    <CopyInventoryChildrenField />
                </Col>
            </Row>
            <Form.Item
                {...BULK_OPERATION_FORM_ITEMS.NAME_FOR_NEW_BRAND}
                rules={[{ required: true, whitespace: true, message: "Please put a Name for New Brand" }]}
                wrapperCol={{ xs: 24, md: 12 }}
            >
                <Input data-sdet="name-for-new-brand-field" />
            </Form.Item>
            <Form.Item
                {...BULK_OPERATION_FORM_ITEMS.DESTINATION_SEAT}
                rules={[{ required: true, message: "Please select a Destination Seat Filter" }]}
                wrapperCol={{ xs: 24, md: 12 }}
            >
                <GenericLoadOnScrollField<SearchItemSeat>
                    labelInValue
                    sdet="destination-seat-filter-field"
                    useGetOptionsQuery={useSearchQuery}
                    extraParams={{ type: "seat" }}
                    optionMapper={({ name, id }: SearchItemSeat) => ({ label: name, value: id })}
                    onChange={() =>
                        form.setFieldValue(BULK_OPERATION_FORM_ITEMS.SELECT_PUBLISHER_PARENT.name, undefined)
                    }
                />
            </Form.Item>
            <Form.Item
                {...BULK_OPERATION_FORM_ITEMS.SELECT_PUBLISHER_PARENT}
                rules={[{ required: true, message: "Please select a Publisher Parent" }]}
                wrapperCol={{ xs: 24, md: 12 }}
            >
                <GenericLoadOnScrollField<SeatPublisher>
                    labelInValue
                    sdet="publisher-parent-field"
                    useGetOptionsQuery={useGetSeatPublishersQuery}
                    extraParams={{ seatId: destinationSeat?.value || Number(seatId), channels: false }}
                    optionMapper={({ name, id, seat }: SeatPublisher) => ({
                        label: `${seat.name} > ${name}`,
                        value: id,
                    })}
                />
            </Form.Item>
        </>
    );
};
