import { FC } from "react";
import { Col, Form, InputNumber, Row, Select, Space } from "antd";
import { css } from "@emotion/css";
import { CurrencyInput } from "@app/core/components";
import { useDemandAcquisitionCostFormItem } from "./useDemandAcquisitionCostFormItem";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import {
    AD_SOURCE_FIELDS,
    DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
    DemandAcquisitionCostModelId,
} from "@app/features/seatAdSources/constants";
import {
    getFixedDemandAcquisitionCostFormValue,
    getPercentDemandAcquisitionCostFormValue,
    getDemandAcquisitionCostHelpText,
    getDemandAcquisitionCostLabel,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/utils";
import { useDemandAcquisitionCostUtils } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/useDemandAcquisitionCostUtils";

const dacLabelCss = css`
    .ant-form-item {
        margin-bottom: 8px;
    }
    .ant-form-item-control-input {
        display: none;
    }
`;

export const DemandAcquisitionCostFormItem: FC = () => {
    const { isDemandAcquisitionEnabled, isDemandAcquisitionReadonly } = useDemandAcquisitionCostUtils();
    const { dac, dacModelId, dacModelOptions, fixedDacOnBlur, percentDacOnBlur, dacModelOnSelect } =
        useDemandAcquisitionCostFormItem();

    if (!isDemandAcquisitionEnabled) {
        return null;
    }

    const dacLabel = getDemandAcquisitionCostLabel(dacModelId);
    const dacHelpText = getDemandAcquisitionCostHelpText(dac, dacModelId);
    const isDacModelPercent = dacModelId === DemandAcquisitionCostModelId.PERCENTAGE;

    return (
        <div data-sdet="demand-acquisition-cost-fields">
            <Row>
                <Col className={dacLabelCss}>
                    <Form.Item label={AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.label} colon={false} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Space.Compact>
                        <Form.Item name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST_MODEL}>
                            <Select
                                style={{
                                    width: "120px",
                                }}
                                data-sdet="ad-source-demand-acquisition-cost-model"
                                disabled={isDemandAcquisitionReadonly}
                                options={dacModelOptions}
                                placeholder="Select..."
                                onSelect={dacModelOnSelect}
                                value={dacModelId}
                            />
                        </Form.Item>
                        {isDacModelPercent ? (
                            <Form.Item
                                name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST}
                                data-sdet="ad-source-demand-acquisition-cost-field"
                                help={dacHelpText}
                            >
                                <InputNumber
                                    style={{
                                        width: "280px",
                                    }}
                                    data-sdet="ad-source-demand-acquisition-cost-input"
                                    placeholder={`Enter ${dacLabel}`}
                                    precision={0}
                                    suffix="%"
                                    disabled={isDemandAcquisitionReadonly}
                                    value={getPercentDemandAcquisitionCostFormValue(dac)}
                                    onBlur={(event) => fixedDacOnBlur(event.target.value)}
                                />
                            </Form.Item>
                        ) : (
                            <CurrencyInput
                                style={{
                                    width: "320px",
                                }}
                                name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST}
                                dataSdetPrefix="ad-source-demand-acquisition-cost"
                                label={dacLabel}
                                hideLabel
                                disabled={isDemandAcquisitionReadonly}
                                currencyCode={DEFAULT_ENRICHMENT_COST_CURRENCY_CODE}
                                help={dacHelpText}
                                value={getFixedDemandAcquisitionCostFormValue(dac) ?? undefined}
                                onBlur={percentDacOnBlur}
                            />
                        )}
                    </Space.Compact>
                </Col>
            </Row>
        </div>
    );
};
