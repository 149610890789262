import { FC } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { InventoryLiveEventsForm } from "./InventoryLiveEventsForm";
import { notification } from "antd";
import { useAppSelector } from "@app/core/store";
import { selectGetTimeZonesResult } from "@app/core/services";
import { useAuthClient } from "@app/core/authClient";
import { Loading } from "@app/core/components";
import { LiveEventSubmitPayload, useCreateLiveEventMutation } from "@app/core/services/console/liveEvents";

export const InventoryLiveEventsCreatePage: FC = () => {
    const { session } = useAuthClient();
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();
    const [createLiveEvent, { isLoading }] = useCreateLiveEventMutation();

    const timeZones = useAppSelector(selectGetTimeZonesResult);
    const timeZone = session?.user?.userPreferenceMap.timezone as string;

    const preferredTimezone = timeZones?.data?.find((tz) => tz.code === timeZone);

    if (!preferredTimezone) {
        return <Loading />;
    }

    const initialValues = {
        timeZone: preferredTimezone,
    };

    const handleSubmit = (liveEventSubmitPayload: Omit<LiveEventSubmitPayload, "seat">) => {
        createLiveEvent({
            seatId: Number(seatId),
            body: {
                ...liveEventSubmitPayload,
                seat: {
                    id: Number(seatId),
                },
            },
        })
            .unwrap()
            .then(() => {
                history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_LIVE_EVENTS(seatId));
            })
            .catch((err) => {
                notification.error({
                    message:
                        err.data?.errorDescription ||
                        err.data?.errorCode ||
                        "Something went wrong when trying to create this live event",
                });
            });
    };

    const handleCancel = () => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_LIVE_EVENTS(seatId));
    };

    return (
        <>
            <PageHeader title="Create Live Event" onBack={handleCancel} />
            <InventoryLiveEventsForm
                isSubmitting={isLoading}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                liveEvent={initialValues}
            />
        </>
    );
};
