import { Button, Form, Space } from "antd";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { getLabelFormInitialValues, onFinishUpdateLabel } from "../helpers";
import { CLASSIFICATIONS_LABEL_EDIT_FORM_SDET } from "./ClassificationsLabelEditForm";
import { ClassificationsLabelFormBaseFormItems } from "../ClassificationsLabelFormBaseFormItems";
import { useNotificationError } from "@app/core/components/hooks";
import { ClassificationsLabelEditKeyReadOnly } from "./ClassificationsLabelEditKeyReadOnly";
import { useParams } from "react-router-dom";
import { InfoCircleFilled } from "@ant-design/icons";
import { infoColor } from "@rubicon/antd-components";
import { Label, useUpdateSeatLabelMutation } from "@magnite/client-streaming-platform";

export const CLASSIFICATIONS_LABEL_EDIT_KEY_FORM_UNSAVED_CHANGES_COPY_SDET =
    "classifications-label-edit-key-form-unsaved-changes-copy";
export const CLASSIFICATIONS_LABEL_EDIT_KEY_FORM_SAVE_BUTTON_SDET = "classifications-label-edit-key-form-edit-button";
export const CLASSIFICATIONS_LABEL_EDIT_KEY_FORM_CANCEL_BUTTON_SDET =
    "classifications-label-edit-key-form-cancel-button";

interface Props {
    isEditingKey: boolean;
    setIsEditingKey: Dispatch<SetStateAction<boolean>>;
    label: Label;
}

export const ClassificationsLabelEditKeyForm: FC<Props> = ({ isEditingKey, setIsEditingKey, label }) => {
    const [form] = Form.useForm();
    const [updateClassificationsLabel, { isLoading, error }] = useUpdateSeatLabelMutation();
    const initialValues = getLabelFormInitialValues(label);
    const [isPristine, setIsPristine] = useState(true);
    const isDistributionGroup: boolean = !!label?.isDistributionGroup;
    useNotificationError(error);

    const { seatId } = useParams<{ seatId: string }>();

    const handelCancel = (): void => {
        setIsEditingKey(false);
        setIsPristine(true);
        form.resetFields();
    };

    const handleSave = (): void => form.submit();

    const handleFinish = async (values): Promise<void> => {
        await onFinishUpdateLabel(label.id, values, seatId, updateClassificationsLabel);
        setIsPristine(true);
        setIsEditingKey(false);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            initialValues={initialValues}
            wrapperCol={{ sm: 24, lg: 12 }}
            data-sdet={CLASSIFICATIONS_LABEL_EDIT_FORM_SDET}
            onValuesChange={() => setIsPristine(false)}
        >
            {isEditingKey ? (
                <>
                    <ClassificationsLabelFormBaseFormItems label={label} isDistributionGroup={isDistributionGroup} />
                    {!isDistributionGroup && (
                        <Space>
                            <Button
                                data-sdet={CLASSIFICATIONS_LABEL_EDIT_KEY_FORM_SAVE_BUTTON_SDET}
                                type="primary"
                                onClick={handleSave}
                                loading={isLoading}
                            >
                                Save
                            </Button>
                            <Button
                                data-sdet={CLASSIFICATIONS_LABEL_EDIT_KEY_FORM_CANCEL_BUTTON_SDET}
                                onClick={handelCancel}
                            >
                                Cancel
                            </Button>
                            {!isPristine && (
                                <span data-sdet={CLASSIFICATIONS_LABEL_EDIT_KEY_FORM_UNSAVED_CHANGES_COPY_SDET}>
                                    <InfoCircleFilled style={{ color: infoColor }} /> You have unsaved changes
                                </span>
                            )}
                        </Space>
                    )}
                </>
            ) : (
                <ClassificationsLabelEditKeyReadOnly label={label} />
            )}
        </Form>
    );
};
