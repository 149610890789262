import { EditOutlined } from "@ant-design/icons";
import { AllowedExtendedIdTransparency } from "@app/core/services";
import { Button, Col, Form, Row, Select, Space } from "antd";
import { FC, useState } from "react";
import { AllowedExtendedIdTransparenciesDrawer } from "./AllowedExtendedIdTransparenciesDrawer";
import { AllowedExtendedIdTransparencyTags } from "./AllowedExtendedIdTransparencyTags";
import { LabeledValue } from "antd/es/select";

interface Props {
    allowAllExtendedId: boolean;
    allowedExtendedIdTransparencies: AllowedExtendedIdTransparency[];
    index: number;
    isEditing: boolean;
}

export const EXTENDED_USER_ID_OPTIONS = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Specific",
        value: "specific",
    },
    {
        label: "None",
        value: "none",
    },
];

export const mapExtendedUserIdApiToForm = (allowAllExtendedId: boolean, allowedExtendedIdTransparencies: unknown[]) => {
    if (allowAllExtendedId && !allowedExtendedIdTransparencies.length) {
        return EXTENDED_USER_ID_OPTIONS[0].value; // All
    }
    if (!allowAllExtendedId && Boolean(allowedExtendedIdTransparencies.length)) {
        return EXTENDED_USER_ID_OPTIONS[1].value; // Specific
    }
    return EXTENDED_USER_ID_OPTIONS[2].value; // None
};

export const mapExtendedUserIdFormToApi = (
    allowAllExtendedId: "all" | "specific" | "none",
    allowedExtendedIdTransparenciesForm: LabeledValue[]
) => {
    if (allowAllExtendedId === "all") {
        return {
            allowedExtendedIdTransparencies: [],
            allowAllExtendedId: true,
        };
    }
    if (allowAllExtendedId === "specific") {
        if (!allowedExtendedIdTransparenciesForm.length) {
            return {
                allowedExtendedIdTransparencies: [],
                allowAllExtendedId: false,
            };
        }

        return {
            allowedExtendedIdTransparencies: mapAllowedExtendedIdTransparenciesFormToApi(
                allowedExtendedIdTransparenciesForm
            ),
            allowAllExtendedId: false,
        };
    }
    return {
        allowedExtendedIdTransparencies: [],
        allowAllExtendedId: false,
    };
};

export const mapAllowedExtendedIdTransparenciesApiToForm = (
    allowedExtendedIdTransparencies: AllowedExtendedIdTransparency[]
) => {
    return allowedExtendedIdTransparencies.map(({ extendedId, network }) => ({
        value: `${extendedId.id}:${network.id}`,
        label: `${extendedId.name}:${network.name}`,
    }));
};

export const mapAllowedExtendedIdTransparenciesFormToApi = (allowedExtendedIdTransparencies: LabeledValue[]) => {
    return allowedExtendedIdTransparencies.map((labeledValue) => {
        const [extendedIdName, networkName] = (labeledValue.label as string).split(":");
        const [extendedIdId, networkId] = (labeledValue.value as string).split(":");
        return {
            extendedId: {
                id: Number(extendedIdId),
                name: extendedIdName,
            },
            network: {
                id: Number(networkId),
                name: networkName,
            },
        };
    });
};

export const AllowAllExtendedIdCell: FC<Props> = ({
    index,
    allowAllExtendedId,
    allowedExtendedIdTransparencies,
    isEditing,
}) => {
    const form = Form.useFormInstance();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const formValue = Form.useWatch(["transparency", index, "allowAllExtendedId"]);
    const formValueAllowedExtendedIdTransparencies = Form.useWatch([
        "transparency",
        index,
        "allowedExtendedIdTransparencies",
    ]);

    if (isEditing) {
        return (
            <Row>
                <Col xs={24}>
                    <Row style={{ marginBottom: allowedExtendedIdTransparencies.length ? "8px" : 0 }}>
                        <Col xs={24}>
                            <Space>
                                <Form.Item
                                    name={[index, "allowAllExtendedId"]}
                                    initialValue={mapExtendedUserIdApiToForm(
                                        allowAllExtendedId,
                                        allowedExtendedIdTransparencies
                                    )}
                                    style={{ marginBottom: 0 }}
                                >
                                    <Select
                                        style={{ width: "100px" }}
                                        options={EXTENDED_USER_ID_OPTIONS}
                                        onChange={(v) => {
                                            if (v === "specific") {
                                                setIsDrawerOpen(true);
                                            }
                                            if (v === "all" || v === "none") {
                                                form.setFieldValue(
                                                    ["transparency", index, "allowedExtendedIdTransparencies"],
                                                    []
                                                );
                                            }
                                        }}
                                        data-sdet="extented-user-id-select"
                                    />
                                </Form.Item>
                                {formValue === "specific" && (
                                    <Button
                                        data-sdet="extented-user-id-edit-button"
                                        icon={<EditOutlined />}
                                        onClick={() => setIsDrawerOpen(true)}
                                    />
                                )}
                            </Space>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Item
                                name={[index, "allowedExtendedIdTransparencies"]}
                                initialValue={mapAllowedExtendedIdTransparenciesApiToForm(
                                    allowedExtendedIdTransparencies
                                )}
                                style={{ marginBottom: 0 }}
                                noStyle={!formValueAllowedExtendedIdTransparencies?.length}
                                wrapperCol={{ xs: 24 }}
                            >
                                <AllowedExtendedIdTransparencyTags />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <AllowedExtendedIdTransparenciesDrawer
                    index={index}
                    isOpen={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                />
            </Row>
        );
    }

    if (allowAllExtendedId) {
        return <span>All</span>;
    }

    if (!allowedExtendedIdTransparencies.length) {
        return <span>None</span>;
    }

    return (
        <AllowedExtendedIdTransparencyTags
            value={mapAllowedExtendedIdTransparenciesApiToForm(allowedExtendedIdTransparencies)}
        />
    );
};
