// TODO: Declare global instead of accessing window directly
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const windowConf = window.__config || {};

const conf = Object.freeze({
    ...windowConf,
});

export default conf;

export const isDevOrQaEnv = (() => {
    const appsMagniteRoot = conf.appsMagniteRoot;
    if (!appsMagniteRoot || typeof appsMagniteRoot !== "string") {
        return false;
    }
    return ["apps-dev", "apps-qa"].some((devOrQaEnv) => appsMagniteRoot.includes(`${devOrQaEnv}.magnite.com`));
})();
