import { useAppSelector } from "@app/core/store";
import { EVENT_NAMES, useCommunicatorSession } from "@rubicon/iframe-communicator/react";
import { copy } from "@rubicon/utils/dist/clipboard";
import { notification } from "antd";
import { selectIframed } from "../Main/reducer";

interface UseClipBoardButton {
    handleCopy: (text: string, type?: string) => void;
}

export const useClipBoardButton = (): UseClipBoardButton => {
    const session = useCommunicatorSession();
    const inIframe = useAppSelector(selectIframed);

    const handleCopy = async (text: string, type?: string) => {
        if (inIframe) {
            session?.emitEvent(EVENT_NAMES.COPY_TO_CLIPBOARD, { text, type });
        } else {
            if ("clipboard" in navigator) {
                try {
                    await navigator.clipboard.writeText(text);
                    notification.success({ message: `${type} is copied` });
                } catch (err) {
                    notification.error({ message: `${type} is not copied because of ${err}` });
                }
            } else {
                copy(text);
            }
        }
    };

    return { handleCopy };
};
