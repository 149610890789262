import { FC } from "react";
import { Form, Select } from "antd";
import { TargetingDimensionTypeLabels } from "@app/features/targeting/constants";
import { BulkUploadDrawerButton } from "../../BulkUploadDrawerButton";
import { useBulkPostalCodeDrawerButton } from "./useBulkPostalCodeDrawer";
import { GeoTarget } from "@magnite/client-streaming-platform";

interface Props {
    onChange: (value: GeoTarget[]) => void;
    values: GeoTarget[];
}

const COUNTRY_CODES = [
    { label: "Australia", value: "AU" },
    { label: "Canada", value: "CA" },
    { label: "Denmark", value: "DK" },
    { label: "Finland", value: "FI" },
    { label: "France", value: "FR" },
    { label: "Germany", value: "DE" },
    { label: "Great Britain", value: "GB" },
    { label: "Ireland", value: "IE" },
    { label: "Italy", value: "IT" },
    { label: "New Zealand", value: "NZ" },
    { label: "Norway", value: "NO" },
    { label: "Poland", value: "PL" },
    { label: "Portugal", value: "PT" },
    { label: "Spain", value: "ES" },
    { label: "Sweden", value: "SE" },
    { label: "United States", value: "US" },
];

interface CountryCodeSelectProps {
    value: string;
    onChange: (value: string) => void;
}

const CountryCodeSelect: FC<CountryCodeSelectProps> = ({ value, onChange }) => (
    <Form.Item label="Country">
        <Select options={COUNTRY_CODES} value={value} onChange={onChange} />
    </Form.Item>
);

export const BulkPostalCodeDrawerButton: FC<Props> = ({ onChange, values }) => {
    const {
        countryCode,
        handleAdd,
        handleChangeCountryCode,
        handleChangeValue,
        handleClose,
        handleOpen,
        handleReplace,
        isLoadingAdd,
        isLoadingReplace,
        isOpen,
        value,
        invalidValues,
        isEmpty,
    } = useBulkPostalCodeDrawerButton(onChange, values);

    return (
        <BulkUploadDrawerButton
            extra={<CountryCodeSelect value={countryCode} onChange={handleChangeCountryCode} />}
            handleAdd={handleAdd}
            handleChangeValue={handleChangeValue}
            handleClose={handleClose}
            handleOpen={handleOpen}
            handleReplace={handleReplace}
            isLoadingAdd={isLoadingAdd}
            isLoadingReplace={isLoadingReplace}
            isOpen={isOpen}
            value={value}
            title={`${TargetingDimensionTypeLabels.GeoTargets} Targeting - Bulk Postal Code`}
            placeholder="Enter postal codes one per row"
            invalidValues={invalidValues}
            isEmpty={isEmpty}
        />
    );
};
