import { PricingModels, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { AdSource } from "@app/core/services";

export const useIsFieldVisible = (adSource: AdSource) => {
    const adSourceTypeId = adSource.type.id;
    const priceModel = adSource?.priceModel?.id;

    switch (adSourceTypeId) {
        case AdSourceTypeIds.PROGRAMMATIC_GUARANTEED:
        case AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED:
        case AdSourceTypeIds.FIXED_PRICE:
            return priceModel === PricingModels.FIXED_PRICE;
        case AdSourceTypeIds.AUCTION_PRICE:
        case AdSourceTypeIds.MARKETPLACE:
        case AdSourceTypeIds.LINEAR_AUCTION_PRICE:
        case AdSourceTypeIds.OPEN_AUCTION:
        case AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE:
        case AdSourceTypeIds.TREMOR_NETWORK:
        case AdSourceTypeIds.CURATOR_MARKETPLACE:
            return false;

        default:
            return true;
    }
};
