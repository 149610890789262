import { useGetSeatLabelsQuery, useGetLabelValuesQuery, LabelValue } from "@magnite/client-streaming-platform";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { Form } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";

export const useAddLabel = () => {
    const currentLabels = Form.useWatch(AD_SOURCE_FIELDS.LABEL.name) || [];
    const { setFieldValue } = Form.useFormInstance();
    const { seatId } = useParams<{ seatId: string }>();
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [selectedLabelValue, setSelectedLabelValue] = useState<LabelValue | null>(null);
    const { data: allLabels = [], isFetching: isLabelsOptionsLoading } = useGetSeatLabelsQuery({
        seatId: Number(seatId),
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { data: labelValues = [], isFetching: isLabelValuesLoading } = useGetLabelValuesQuery(selectedLabel?.id, {
        skip: !selectedLabel,
    });
    const labels = allLabels.filter(({ isDistributionGroup }) => !isDistributionGroup);
    const handleAddLabel = () => {
        const isInCurrentLabels = currentLabels.some(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (labelValue) => selectedLabelValue.label.id === labelValue.label.id
        );
        if (!selectedLabelValue?.label.multiValue && isInCurrentLabels) {
            setFieldValue(
                AD_SOURCE_FIELDS.LABEL.name,
                currentLabels.map((labelValue) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (labelValue.label.id === selectedLabelValue.label.id) {
                        return selectedLabelValue;
                    }
                    return labelValue;
                })
            );
        } else {
            setFieldValue(AD_SOURCE_FIELDS.LABEL.name, [...currentLabels, selectedLabelValue]);
        }
        setSelectedLabel(null);
        setSelectedLabelValue(null);
    };
    return {
        selectedLabelValue,
        selectedLabel,
        setSelectedLabel,
        setSelectedLabelValue,
        isLabelValuesLoading,
        isLabelsOptionsLoading,
        labels,
        labelValues,
        handleAddLabel,
    };
};
