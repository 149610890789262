import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FORM_FIELDS } from "./constants";
import { LabeledValue } from "antd/lib/select";
import { Store } from "rc-field-form/lib/interface";
import { FormInstance, notification } from "antd";
import { DEFAULT_FALLBACK_MESSAGE } from "@app/features/inventory/constants";
import {
    Brand,
    useUpdateBrandBrandSafetyMutation,
    useUpdateBrandChannelBrandSafetyMutation,
} from "@app/core/services/console";
import { useSeatTree } from "@app/features/inventory/InventoryBrandSafety/SeatTree/useSeatTree";
import { setEditActiveKey, setIsActionsDisabled } from "@app/features/inventory/InventoryBrandSafety/reducer";
import { parseBlockedIndustriesFromApi, parseBlockedIndustriesToApi } from "@app/core/components";

export interface BrandDetailsFormValues {
    [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAIN_LISTS.name]: LabeledValue[];
    [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: string;
    [FORM_FIELDS.BLOCKED_BUYER_SEAT_LIST.name]: LabeledValue | null;
}

interface UseBrandBrandSafetyEditForm {
    brandForm: FormInstance<BrandDetailsFormValues>;
    handleSubmit: () => void;
    handleDelete: () => void;
    resetFormsFields: () => void;
    initialValues: Store | undefined;
    isDeleting: boolean;
    isUpdating: boolean;
}

export const getInitialValuesFromBrand = (brand: Brand | null): Store | undefined => {
    if (!brand) return undefined;

    return {
        [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAIN_LISTS.name]: brand.seatAdvertiserDomainFilterListDefs.map((list) => ({
            value: list.id,
            label: list.name,
        })),
        [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: brand.advertiserBlockDomains.join("\n"),
        [FORM_FIELDS.BLOCKED_BUYER_SEAT_LIST.name]: brand.buyerSeatList
            ? { value: brand.buyerSeatList.id, label: brand.buyerSeatList.name }
            : null,
        [FORM_FIELDS.BLOCKED_IAB_CATEGORIES.name]: brand.blockedIabCategories.map((list) => ({
            value: list.id,
            label: list.name,
        })),
        [FORM_FIELDS.BLOCKED_INDUSTRIES.name]: parseBlockedIndustriesFromApi(brand.blockedIndustries),
    };
};

export const useBrandBrandSafetyEditForm = (brand: Brand): UseBrandBrandSafetyEditForm => {
    const dispatch = useDispatch();
    const [updateBrandBrandSafety] = useUpdateBrandBrandSafetyMutation();
    const [updateBrandChannelBrandSafety] = useUpdateBrandChannelBrandSafetyMutation();
    const { brandForm, resetFormsFields } = useSeatTree();
    const initialValues = getInitialValuesFromBrand(brand);

    const isChannel: boolean = brand.type.name === "Channel";

    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const handleSubmit = async (): Promise<void> => {
        dispatch(setIsActionsDisabled(true));
        setIsUpdating(true);
        const {
            blockedAdvertiserDomainLists,
            blockedAdvertiserDomains,
            blockedBuyerSeatList,
            blockedIabCategories,
            blockedIndustries,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
        } = brandForm.getFieldsValue(Object.values(FORM_FIELDS).map((field) => field.name));
        try {
            if (isChannel) {
                await updateBrandChannelBrandSafety({
                    id: brand.id,
                    brand: {
                        advertiserBlockDomains: blockedAdvertiserDomains.length
                            ? blockedAdvertiserDomains?.split("\n")
                            : null,
                        buyerSeatList: blockedBuyerSeatList ? { id: blockedBuyerSeatList.value as number } : null,
                        seatAdvertiserDomainFilterListDefs: blockedAdvertiserDomainLists.length
                            ? blockedAdvertiserDomainLists.map(({ value }) => ({ id: value as number }))
                            : null,
                        blockedIabCategories: blockedIabCategories.length
                            ? blockedIabCategories.map(({ value }) => ({ id: value as number }))
                            : null,
                        blockedIndustries: parseBlockedIndustriesToApi(blockedIndustries),
                    },
                }).unwrap();
            } else {
                await updateBrandBrandSafety({
                    id: brand.id,
                    advertiserBlockDomains: blockedAdvertiserDomains.length
                        ? blockedAdvertiserDomains?.split("\n")
                        : null,
                    buyerSeatList: blockedBuyerSeatList ? { id: blockedBuyerSeatList.value as number } : null,
                    seatAdvertiserDomainFilterListDefs: blockedAdvertiserDomainLists.length
                        ? blockedAdvertiserDomainLists.map(({ value }) => ({ id: value as number }))
                        : null,
                    blockedIabCategories: blockedIabCategories.length
                        ? blockedIabCategories.map(({ value }) => ({ id: value as number }))
                        : null,
                    blockedIndustries: parseBlockedIndustriesToApi(blockedIndustries),
                }).unwrap();
            }
            dispatch(setEditActiveKey(null));
            notification.success({ message: "Brand updated successfully" });
        } catch (error) {
            notification.error({ message: error.data.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        } finally {
            resetFormsFields();
            dispatch(setIsActionsDisabled(false));
            setIsUpdating(false);
        }
    };
    const handleDelete = async (): Promise<void> => {
        dispatch(setIsActionsDisabled(true));
        setIsDeleting(true);
        try {
            if (isChannel) {
                await updateBrandChannelBrandSafety({
                    id: brand.id,
                    brand: {
                        advertiserBlockDomains: [],
                        buyerSeatList: null,
                        seatAdvertiserDomainFilterListDefs: [],
                        blockedIabCategories: [],
                        blockedIndustries: null,
                    },
                }).unwrap();
            } else {
                await updateBrandBrandSafety({
                    id: brand.id,
                    advertiserBlockDomains: [],
                    buyerSeatList: null,
                    seatAdvertiserDomainFilterListDefs: [],
                    blockedIabCategories: [],
                    blockedIndustries: null,
                }).unwrap();
            }
            notification.success({ message: "Brand updated successfully" });
        } catch (error) {
            notification.error({ message: error.data.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        } finally {
            dispatch(setIsActionsDisabled(false));
            setIsDeleting(false);
        }
    };

    useEffect(() => {
        if (initialValues && !isUpdating) {
            brandForm.setFieldsValue(initialValues);
        }
    }, [brandForm, initialValues, isUpdating]);

    return {
        brandForm,
        isUpdating,
        isDeleting,
        handleDelete,
        handleSubmit,
        resetFormsFields,
        initialValues,
    };
};
