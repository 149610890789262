export const COLUMN_KEY = {
    NAME: "name",
    DEAL_PRIORITY: "priority",
    CODE: "code",
    DEAL_ENTITY_TYPE: "entityType",
    DEAL_ORIGIN_NAME: "dealOriginName",
    NETWORK_NAME: "networkName",
    DEAL_START_DATE: "startDate",
    DEAL_END_DATE: "endDate",
    PACING: "sortPacing",
    CPM_RATE: "cpmRate",
    TRIES: "tries",
    IMPRESSIONS: "impressions",
    USE_RATE: "useRate",
    NET_REVENUE: "netRevenue",
    DEAL_STATUS_NAME: "statusName",
    DEAL_EXTERNAL_STATUS: "externalStatus",
    DEAL_ACTIONS: "actions",
} as const;
