import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { InventoryLiveEventsTablePage } from "../InventoryLiveEventsTablePage";
import { InventoryLiveEventsCreatePage } from "../InventoryLiveEventsCreatePage";
import { InventoryLiveEventsEditPage } from "../InventoryLiveEventsEditPage";

export const InventoryLiveEventsSwitch: FC = () => {
    return (
        <Switch>
            <Route exact path={[ROUTES.SEAT_INVENTORY_LIVE_EVENTS, ROUTES.SEAT_INVENTORY_LIVE_EVENTS_DETAILS_DRAWER]}>
                <InventoryLiveEventsTablePage />
            </Route>
            <Route exact path={ROUTES.SEAT_INVENTORY_LIVE_EVENTS_CREATE}>
                <InventoryLiveEventsCreatePage />
            </Route>
            <Route exact path={ROUTES.SEAT_INVENTORY_LIVE_EVENTS_EDIT}>
                <InventoryLiveEventsEditPage />
            </Route>
        </Switch>
    );
};
