import { AdSourceListItem } from "@app/core/services";
import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";

type AdSourceCpmRateType = "Cpm" | "CpmUp" | "CpmDown" | "PercentUp" | "PercentDown" | "--";

export const getRateTypeToBeDisplayed = (adSource: AdSourceListItem): AdSourceCpmRateType => {
    const adSourceTypeId = adSource.type.id;

    if (
        adSourceTypeId === AdSourceTypeIds.FIXED_PRICE ||
        adSourceTypeId === AdSourceTypeIds.LINEAR_UNRESERVED_FIXED_RATE
    ) {
        if (adSource.adSourcePriceModel?.name === "Tiered price") {
            return "--";
        }
    }

    if (
        adSourceTypeId === AdSourceTypeIds.PRE_BID_DEALS ||
        adSourceTypeId === AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE ||
        adSourceTypeId === AdSourceTypeIds.FALLBACK_TAG
    ) {
        return "--";
    }

    if (
        adSourceTypeId === AdSourceTypeIds.AUCTION_PRICE ||
        adSourceTypeId === AdSourceTypeIds.OPEN_AUCTION ||
        adSourceTypeId === AdSourceTypeIds.MARKETPLACE ||
        adSourceTypeId === AdSourceTypeIds.PRE_BID_AD_SOURCE ||
        adSourceTypeId === AdSourceTypeIds.LINEAR_AUCTION_PRICE
    ) {
        if (adSource.adSourceFloorType?.name === "IncreaseCPM") {
            return "CpmUp";
        }
        if (adSource.adSourceFloorType?.name === "DecreaseCPM") {
            return "CpmDown";
        }
        if (adSource.adSourceFloorType?.name === "IncreasePercent") {
            return "PercentUp";
        }
        if (adSource.adSourceFloorType?.name === "DecreasePercent") {
            return "PercentDown";
        }
    }

    return "Cpm";
};
