import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { LabeledValue } from "antd/lib/select";
import { useGetLabelValuesQuery, useGetSeatLabelsQuery } from "@magnite/client-streaming-platform";
import { Row, Col, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { blue6 } from "@rubicon/antd-components";

export const useInventoryLabelSelect = (
    existingInventoryLabels,
    onChangeDimensionValues: (value: LabeledValue[]) => void
) => {
    const { seatId } = useParams<{ seatId: string }>();
    const [selectedInventoryLabelId, setSelectedInventoryLabelId] = useState<number | null>(null);
    const [selectedInventoryLabelValue, setSelectedInventoryLabelValue] = useState<string | null>(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

    const { data: inventoryLabels = [], isFetching: isInventoryLabelOptionsLoading } = useGetSeatLabelsQuery({
        seatId: Number(seatId),
    });

    const inventoryLabelOptions = useMemo(
        () =>
            inventoryLabels.map((label) => ({
                label: label.enablePrefilter ? (
                    <Row justify={"space-between"}>
                        <Col>{label.key}</Col>
                        <Col>
                            <Tooltip title="Waterfall Prefilter">
                                <InfoCircleOutlined style={{ color: blue6 }} />
                            </Tooltip>
                        </Col>
                    </Row>
                ) : (
                    label.key
                ),
                value: label.id,
            })),
        [inventoryLabels]
    );

    const { data: inventoryLabelValues = [], isFetching: isInventoryLabelValuesLoading } = useGetLabelValuesQuery(
        Number(selectedInventoryLabelId),
        { skip: !selectedInventoryLabelId }
    );

    const inventoryLabelValuesOptions = useMemo(
        () => inventoryLabelValues.map((label) => ({ label: label.value, value: label.id })),
        [inventoryLabelValues]
    );

    const addInventoryLabelValuePair = () => {
        const selectedLabelValuePair = inventoryLabelValues.find(
            (labelObj) => labelObj.id === Number(selectedInventoryLabelValue)
        );
        const labelArray = [selectedLabelValuePair];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let labelValuePair = labelArray.map((target) => ({
            value: JSON.stringify(target),
            label: `${target?.label.key}: ${target?.value}`,
        }));

        if (existingInventoryLabels?.length) {
            labelValuePair = [...existingInventoryLabels, ...labelValuePair];
        }
        onChangeDimensionValues(labelValuePair);
        setIsDropdownVisible(false);
        setSelectedInventoryLabelId(null);
        setSelectedInventoryLabelValue(null);
    };

    return {
        addInventoryLabelValuePair,
        inventoryLabelOptions,
        inventoryLabelValuesOptions,
        isDropdownVisible,
        isInventoryLabelOptionsLoading,
        isInventoryLabelValuesLoading,
        selectedInventoryLabelId,
        selectedInventoryLabelValue,
        setIsDropdownVisible,
        setSelectedInventoryLabelId,
        setSelectedInventoryLabelValue,
    };
};
