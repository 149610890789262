import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";

export const isDemandSectionVisible = (adSourceTypeId: number) => {
    return (
        adSourceTypeId === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED ||
        adSourceTypeId === AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED ||
        adSourceTypeId === AdSourceTypeIds.FIXED_PRICE ||
        adSourceTypeId === AdSourceTypeIds.AUCTION_PRICE ||
        adSourceTypeId === AdSourceTypeIds.OPEN_AUCTION ||
        adSourceTypeId === AdSourceTypeIds.LINEAR_UNRESERVED_FIXED_RATE ||
        adSourceTypeId === AdSourceTypeIds.LINEAR_AUCTION_PRICE ||
        adSourceTypeId === AdSourceTypeIds.MARKETPLACE ||
        adSourceTypeId === AdSourceTypeIds.PRE_BID_DEALS
    );
};
