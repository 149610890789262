import { useState } from "react";
import { useParams } from "react-router-dom";
import { message } from "antd";
import { LabeledValue } from "antd/lib/select";
import {
    useValidateAdUnitsMutation,
    useValidateBrandsMutation,
    useValidateSupplyMutation,
} from "@app/core/services/console";
import { useValidateChannelsMutation, useValidatePublishersMutation } from "@magnite/client-streaming-platform";
import { InventoryCategoryTypes } from "@app/features/targeting/constants";
import { inventoryToLabelValue } from "@app/features/targeting/helpers";

export const useBulkInventoryDrawerButton = (onChange: (value: LabeledValue[]) => void, values: LabeledValue[]) => {
    const { seatId } = useParams<{ seatId: string }>();
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState("");
    const [category, setCategory] = useState(InventoryCategoryTypes.Channels);
    const [isLoadingAdd, setIsLoadingAdd] = useState(false);
    const [isLoadingReplace, setIsLoadingReplace] = useState(false);

    const [validateAdUnits] = useValidateAdUnitsMutation();
    const [validateBrands] = useValidateBrandsMutation();
    const [validateChannels] = useValidateChannelsMutation();
    const [validatePublishers] = useValidatePublishersMutation();
    const [validateSupply] = useValidateSupplyMutation();

    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    const handleChangeValue = (v) => setValue(v);
    const handleChangeCategory = (c) => setCategory(c.value as InventoryCategoryTypes);

    const validateInventory = async () => {
        try {
            switch (category) {
                case InventoryCategoryTypes.AdUnits:
                    return await validateAdUnits({ seatId: Number(seatId), body: value.split("\n") }).unwrap();
                case InventoryCategoryTypes.Brands:
                    return await validateBrands({ seatId: Number(seatId), body: value.split("\n") }).unwrap();
                case InventoryCategoryTypes.Channels:
                    return await validateChannels({ seatId: Number(seatId), body: value.split("\n") }).unwrap();
                case InventoryCategoryTypes.Publishers:
                    return await validatePublishers({ seatId: Number(seatId), body: value.split("\n") }).unwrap();
                case InventoryCategoryTypes.Supply:
                    return await validateSupply({ seatId: Number(seatId), body: value.split("\n") }).unwrap();
            }
        } catch (_) {
            // TODO: We can probably do better with error handling here instead of just a catchall, but this should
            // at least surface the failure for now
            message.error("Validation failed");
        }
    };

    const handleReplace = async () => {
        setIsLoadingReplace(true);
        const inventory = await validateInventory();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange(inventoryToLabelValue(inventory));
        setValue("");
        setCategory(InventoryCategoryTypes.Channels);
        setIsOpen(false);
        setIsLoadingReplace(false);
    };

    const handleAdd = async () => {
        setIsLoadingAdd(true);
        const inventory = await validateInventory();
        const existingValuesByValue = values.reduce<{
            [value: string | number]: LabeledValue;
        }>((byValue, value) => {
            byValue[value.value] = value;
            return byValue;
        }, {});
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const combinedValueByValue = inventoryToLabelValue(inventory).reduce((byValue, value) => {
            byValue[value.value] = value;
            return byValue;
        }, existingValuesByValue);

        const combinedValues = Object.values<LabeledValue>(combinedValueByValue);

        onChange(combinedValues);
        setValue("");
        setCategory(InventoryCategoryTypes.Channels);
        setIsOpen(false);
        setIsLoadingAdd(false);
    };

    return {
        handleAdd,
        handleChangeCategory,
        handleChangeValue,
        handleClose,
        handleOpen,
        handleReplace,
        isLoadingAdd,
        isLoadingReplace,
        isOpen,
        value,
        category,
    };
};
