import {
    AdUnit,
    Brand,
    BrandChannel,
    BrandSupply,
    useGetAdUnitQuery,
    useGetBrandByIdQuery,
    useGetBrandChannelQuery,
    useGetSupplyQuery,
} from "@app/core/services";
import { Publisher, useGetChannelQuery, useGetPublisherByIdQuery } from "@magnite/client-streaming-platform";
import { useMemo } from "react";
import { InventoryDetailsDrawerType } from "../reducer";

interface UseUnitData {
    isLoading: boolean;
    publisherData?: Publisher;
    brandData?: Brand;
    supplyData?: BrandSupply;
    adUnitData?: AdUnit;
    channelData?: BrandChannel;
}
export const useUnitData = (id: string | number, drawerType: InventoryDetailsDrawerType): UseUnitData => {
    const parsedId = Number(id);
    const isNaNId = isNaN(parsedId);

    const { data: publisherData, isFetching: isFetchingPublisher } = useGetPublisherByIdQuery(parsedId, {
        skip: drawerType !== InventoryDetailsDrawerType.PUBLISHER || isNaNId,
    });

    const { data: brandData, isFetching: isFetchingBrand } = useGetBrandByIdQuery(parsedId, {
        skip: drawerType !== InventoryDetailsDrawerType.BRAND || isNaNId,
    });

    const { data: supplyData, isFetching: isFetchingSupply } = useGetSupplyQuery(parsedId, {
        skip: drawerType !== InventoryDetailsDrawerType.SUPPLY || isNaNId,
    });

    const { data: adUnitData, isFetching: isFetchingAdUnit } = useGetAdUnitQuery(parsedId, {
        skip: drawerType !== InventoryDetailsDrawerType.AD_UNIT || isNaNId,
    });

    const { data: channelData, isFetching: isFetchingChannel } = useGetChannelQuery(parsedId, {
        skip: drawerType !== InventoryDetailsDrawerType.CHANNEL || isNaNId,
    });

    const { data: brandChannelData, isFetching: isFetchingBrandChannel } = useGetBrandChannelQuery(parsedId, {
        skip: drawerType !== InventoryDetailsDrawerType.BRAND_CHANNEL || isNaNId,
    });

    const isLoading = useMemo<boolean>(
        () =>
            isFetchingPublisher ||
            isFetchingBrand ||
            isFetchingSupply ||
            isFetchingAdUnit ||
            isFetchingChannel ||
            isFetchingBrandChannel,
        [
            isFetchingPublisher,
            isFetchingBrand,
            isFetchingSupply,
            isFetchingAdUnit,
            isFetchingChannel,
            isFetchingBrandChannel,
        ]
    );

    return {
        isLoading,
        publisherData,
        brandData,
        supplyData,
        adUnitData,
        channelData: channelData || brandChannelData,
    };
};
