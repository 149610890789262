import { FC, ReactElement, ReactNode } from "react";
import { Form, Select } from "antd";
import { LabeledValue } from "antd/lib/select";
import { TagRender } from "../TagRender";

interface Props {
    label: ReactNode;
    onChange: (value: string | number | number[] | undefined, option: LabeledValue | LabeledValue[]) => void;
    isLoading?: boolean;
    options: LabeledValue[];
    value: number | number[] | string | null | LabeledValue[] | LabeledValue;
    dataSdet?: string;
    mode?: "multiple" | "tags";
    disabled?: boolean;
    placeholder?: string;
    dropdownRender?: (menu: ReactElement) => ReactElement;
    onSearch?: (value: string) => void;
}

export const FilterSelect: FC<Props> = ({
    label,
    onChange,
    isLoading = false,
    options,
    value,
    dataSdet,
    mode,
    disabled,
    placeholder,
    dropdownRender,
    onSearch,
}) => {
    return (
        <Form.Item data-sdet={dataSdet} label={label} style={{ marginBottom: 0 }}>
            <Select
                allowClear={Boolean(value)}
                loading={isLoading}
                onChange={onChange}
                onSearch={onSearch}
                optionFilterProp="label"
                options={options}
                showSearch
                style={{ width: "100%" }}
                value={value}
                tagRender={TagRender}
                placeholder={placeholder ? placeholder : "Select…"}
                mode={mode}
                disabled={disabled}
                dropdownRender={dropdownRender}
            />
        </Form.Item>
    );
};
