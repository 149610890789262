import { useSeatAuthContext } from "@app/core/auth";
import { SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { useWatch } from "antd/lib/form/Form";

export const useIsFieldVisible = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const { context } = useSeatAuthContext();

    const adSourceTypeId = adSourceType?.id ?? null;

    if (adSourceTypeId !== AdSourceTypeIds.FALLBACK_TAG && context?.udeEnabled) {
        return true;
    }
    return false;
};
