import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { LabeledValue } from "antd/lib/select";
import { RootState } from "@app/core/store";
import { DEFAULT_PAGE_SIZE } from "@app/core/components/constants";

export enum SeatAdSourcesListDisplayMode {
    TableView = "tableView",
    GridView = "gridView",
}

export interface SeatAdSourcesListTableSort {
    orderBy: string;
    asc: boolean | null;
}

export const DefaultSeatAdSourcesListDisplayMode = SeatAdSourcesListDisplayMode.TableView;

interface SeatAdSourcesTableFilters {
    seatAdSourcesAssignee: LabeledValue[] | null;
    seatAdSourcesLabelKey: LabeledValue[] | null;
    seatAdSourcesLabelValue: LabeledValue[] | null;
    seatAdSourcesPriority: LabeledValue[] | null;
    seatAdSourcesRegion: LabeledValue[] | null;
    seatAdSourcesStatus: LabeledValue[] | null;
    seatAdSourcesTargeting: LabeledValue[] | null;
    seatAdSourcesType: LabeledValue[] | null;
}

const DefaultSort = () => ({
    orderBy: "priority",
    asc: true,
});

const DefaultPagination = () => ({
    page: 1,
    max: DEFAULT_PAGE_SIZE,
});

export interface SeatAdSourcesListPagination {
    page: number;
    max: number;
}

interface SeatAdSourcesListSearchFilter {
    keyword: string;
}

interface SeatAdSourcesListTableView {
    sort: SeatAdSourcesListTableSort;
    pagination: SeatAdSourcesListPagination;
}

interface SeatAdSourcesListGridPriorities {
    [key: number]: {
        isLoaded: boolean;
        hasAdSources: boolean;
        pagination: SeatAdSourcesListPagination;
        isUpdatingPagination: boolean;
    };
}

interface SeatAdSourcesListGridView {
    priorityCandidates: SeatAdSourcesListGridPriorities;
}

export interface SeatAdSourcesListState {
    filters: SeatAdSourcesTableFilters;
    searchFilter: SeatAdSourcesListSearchFilter;
    displayMode: SeatAdSourcesListDisplayMode;
    tableView: SeatAdSourcesListTableView;
    gridView: SeatAdSourcesListGridView;
    isBeta: boolean;
}

export const initialState: SeatAdSourcesListState = {
    filters: {
        seatAdSourcesAssignee: [],
        seatAdSourcesLabelKey: [],
        seatAdSourcesLabelValue: [],
        seatAdSourcesPriority: [],
        seatAdSourcesRegion: [],
        seatAdSourcesStatus: [{ label: "Running", value: 1 }],
        seatAdSourcesTargeting: [],
        seatAdSourcesType: [],
    },
    searchFilter: {
        keyword: "",
    },
    displayMode: DefaultSeatAdSourcesListDisplayMode,
    tableView: {
        sort: DefaultSort(),
        pagination: DefaultPagination(),
    },
    gridView: {
        priorityCandidates: {},
    },
    isBeta: false,
};

const seatAdSourcesListSlice = createSlice({
    name: "seatAdSourcesList",
    initialState,
    reducers: {
        onPageExit: (state) => {
            state.tableView.pagination = DefaultPagination();
        },
        clearFilters: (state) => {
            state.filters = {
                ...initialState.filters,
                seatAdSourcesStatus: [],
            };
        },
        setFiltersSeatAdSourcesType: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.seatAdSourcesType = action.payload;
            state.tableView.pagination = { ...state.tableView.pagination, page: 1 };
        },
        setFiltersSeatAdSourcesPriority: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.seatAdSourcesPriority = action.payload;
            state.tableView.pagination = { ...state.tableView.pagination, page: 1 };
        },
        setFiltersSeatAdSourcesRegion: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.seatAdSourcesRegion = action.payload;
            state.tableView.pagination = { ...state.tableView.pagination, page: 1 };
        },
        setFiltersSeatAdSourcesStatus: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.seatAdSourcesStatus = action.payload;
            state.tableView.pagination = { ...state.tableView.pagination, page: 1 };
        },
        setFiltersSeatAdSourcesTargeting: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.seatAdSourcesTargeting = action.payload;
            state.tableView.pagination = { ...state.tableView.pagination, page: 1 };
        },
        setFiltersSeatAdSourcesAssignee: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.seatAdSourcesAssignee = action.payload;
            state.tableView.pagination = { ...state.tableView.pagination, page: 1 };
        },
        setFiltersSeatAdSourcesLabelKey: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.seatAdSourcesLabelKey = action.payload;
            state.tableView.pagination = { ...state.tableView.pagination, page: 1 };
        },
        setFiltersSeatAdSourcesLabelValue: (state, action: PayloadAction<LabeledValue[] | null>) => {
            state.filters.seatAdSourcesLabelValue = action.payload;
            state.tableView.pagination = { ...state.tableView.pagination, page: 1 };
        },
        setSearchFilter: (state, action: PayloadAction<SeatAdSourcesListSearchFilter>) => {
            state.searchFilter = action.payload;
            state.tableView.pagination = { ...state.tableView.pagination, page: 1 };
        },
        setTableViewPagination: (state, action: PayloadAction<SeatAdSourcesListPagination>) => {
            state.tableView.pagination = action.payload;
        },
        setGridViewPriorityPagination: (
            state,
            action: PayloadAction<{ priority: number; page: number; max: number }>
        ) => {
            const { priority, page, max } = action.payload;
            state.gridView.priorityCandidates[priority].pagination = { page, max };
            state.gridView.priorityCandidates[priority].isUpdatingPagination = true;
        },
        resetGridViewPriorityPagination: (state) => {
            Object.keys(state.gridView.priorityCandidates).forEach((priority) => {
                state.gridView.priorityCandidates[priority].pagination = DefaultPagination();
            });
        },
        setGridViewPriorityCandidates: (state, action: PayloadAction<{ availablePriorities: LabeledValue[] }>) => {
            const priorityCandidateMap: SeatAdSourcesListGridPriorities = {};
            const { availablePriorities } = action.payload;

            const priorities = availablePriorities.map((priorityOption) => priorityOption.value);
            priorities.forEach((priority) => {
                const priorityCandidate = state.gridView.priorityCandidates[priority];
                priorityCandidateMap[priority] = {
                    isLoaded: priorityCandidate ? priorityCandidate.isLoaded : false,
                    hasAdSources: priorityCandidate ? priorityCandidate.hasAdSources : false,
                    pagination: priorityCandidate ? priorityCandidate.pagination : DefaultPagination(),
                };
            });

            state.gridView.priorityCandidates = priorityCandidateMap;
        },
        updateGridViewPriorityFetchStatus: (
            state,
            action: PayloadAction<{ priority: number; isFetching: boolean; hasAdSources: boolean }>
        ) => {
            const { priority, isFetching, hasAdSources } = action.payload;
            state.gridView.priorityCandidates[priority].isLoaded = !isFetching;
            state.gridView.priorityCandidates[priority].hasAdSources = hasAdSources;
        },
        gridViewPriorityAdSourcesReceived: (state, action: PayloadAction<{ priority: number }>) => {
            state.gridView.priorityCandidates[action.payload.priority].isUpdatingPagination = false;
        },
        switchSeatAdSourcesListDisplayMode: (state) => {
            state.displayMode =
                current(state).displayMode === SeatAdSourcesListDisplayMode.TableView
                    ? SeatAdSourcesListDisplayMode.GridView
                    : SeatAdSourcesListDisplayMode.TableView;
        },
        setTableViewSort: (state, action: PayloadAction<SeatAdSourcesListTableSort>) => {
            state.tableView.sort = action.payload;
        },
        setIsBeta: (state, action: PayloadAction<boolean>) => {
            state.isBeta = action.payload;
        },
    },
});

export const selectSeatAdSourcesFilterCount = (state: RootState): number =>
    Object.values(state.seatAdSourcesList.filters).filter(
        (filter) => filter && (!Array.isArray(filter) || filter.length > 0)
    ).length;
export const selectSeatAdSourcesFilters = (state: RootState) => state.seatAdSourcesList.filters;
export const selectSeatAdSourcesSearchFilter = (state: RootState) => state.seatAdSourcesList.searchFilter;
export const selectSeatAdSourcesSelectedPriorityFilters = (state: RootState) =>
    state.seatAdSourcesList.filters.seatAdSourcesPriority?.map((priorityFilter) => priorityFilter.value);
export const selectSeatAdSourcesListDisplayMode = (state: RootState) => state.seatAdSourcesList.displayMode;
export const selectSeatAdSourcesListTableViewPagination = (state: RootState) =>
    state.seatAdSourcesList.tableView.pagination;
export const selectSeatAdSourcesListGridViewFilteredPriorityCandidates = (state: RootState) => {
    const filteredPriorities: SeatAdSourcesListGridPriorities = {};
    const selectedPriorityFilters = selectSeatAdSourcesSelectedPriorityFilters(state);
    Object.entries(state.seatAdSourcesList.gridView.priorityCandidates).forEach((entry) => {
        if (selectedPriorityFilters?.length === 0 || selectedPriorityFilters?.includes(Number(entry[0]))) {
            filteredPriorities[entry[0]] = entry[1];
        }
    });
    return filteredPriorities;
};
export const selectSeatAdSourcesListGridViewPriorities = (state: RootState) => {
    const filteredPriorityCandidates = selectSeatAdSourcesListGridViewFilteredPriorityCandidates(state);
    return Object.keys(filteredPriorityCandidates).map((priority) => Number(priority));
};
export const selectSeatAdSourcesListGridViewAllFilteredPrioritiesLoaded = (state: RootState) => {
    const filteredPriorityCandidates = selectSeatAdSourcesListGridViewFilteredPriorityCandidates(state);
    return Object.values(filteredPriorityCandidates).reduce(
        (allPrioritiesLoaded, priorityMapValue) =>
            (allPrioritiesLoaded && priorityMapValue.isLoaded) || priorityMapValue.isUpdatingPagination,
        true
    );
};
export const selectSeatAdSourcesListGridViewPrimaryPriority = (state: RootState) => {
    const allFilteredPrioritiesLoaded = selectSeatAdSourcesListGridViewAllFilteredPrioritiesLoaded(state);
    if (allFilteredPrioritiesLoaded) {
        const filteredPriorityCandidatesWithAdSources = selectSeatAdSourcesListGridViewPriorities(state).filter(
            (priority) => state.seatAdSourcesList.gridView.priorityCandidates[priority].hasAdSources
        );
        return Math.min(...filteredPriorityCandidatesWithAdSources);
    } else {
        return 1;
    }
};
export const selectSeatAdSourcesListGridHasAdSources = (state: RootState) => {
    const allFilteredPrioritiesLoaded = selectSeatAdSourcesListGridViewAllFilteredPrioritiesLoaded(state);
    const filteredPriorityCandidates = selectSeatAdSourcesListGridViewFilteredPriorityCandidates(state);
    return (
        allFilteredPrioritiesLoaded &&
        Object.values(filteredPriorityCandidates).reduce(
            (hasAdSources, priorityCandidate) => hasAdSources || priorityCandidate.hasAdSources,
            false
        )
    );
};
export const selectSeatAdSourcesTableViewSort = (state: RootState) => state.seatAdSourcesList.tableView.sort;
export const selectIsBeta = (state: RootState) => state.seatAdSourcesList.isBeta;

export const {
    onPageExit,
    clearFilters,
    setFiltersSeatAdSourcesAssignee,
    setFiltersSeatAdSourcesLabelKey,
    setFiltersSeatAdSourcesLabelValue,
    setFiltersSeatAdSourcesType,
    setFiltersSeatAdSourcesPriority,
    setFiltersSeatAdSourcesStatus,
    setFiltersSeatAdSourcesRegion,
    setFiltersSeatAdSourcesTargeting,
    setTableViewPagination,
    setSearchFilter,
    setGridViewPriorityPagination,
    resetGridViewPriorityPagination,
    setGridViewPriorityCandidates,
    updateGridViewPriorityFetchStatus,
    gridViewPriorityAdSourcesReceived,
    switchSeatAdSourcesListDisplayMode,
    setTableViewSort,
    setIsBeta,
} = seatAdSourcesListSlice.actions;

export const seatAdSourcesListReducer = seatAdSourcesListSlice.reducer;
