import { useEffect, FocusEvent } from "react";
import { Form } from "antd";
import { Rule } from "antd/es/form";
import { NamePath, StoreValue } from "antd/es/form/interface";
import { LabeledValue } from "antd/es/select";
import { CUSTOM_OPTION } from "./constants";
import { DurationType } from "./types";
import { getInputName, getLabel, getOptions, getSelectName } from "./utils";
import { maxValidator, minValidator } from "./validators";

interface UseMinMaxDurationSelect {
    isCustom: boolean;
    label: string;
    selectRules: Rule[] | undefined;
    inputRules: Rule[] | undefined;
    options: LabeledValue[];
    handleSelectChange: (option: number | string | undefined) => void;
    handleInputBlur: (event: FocusEvent<HTMLInputElement>) => void;
}

export const useMinMaxDurationSelect = (
    name: string,
    selectName: string,
    mode: DurationType,
    value: number | undefined,
    oppositeName: string,
    required: boolean | undefined,
    min: number | undefined,
    onChange: (v: number | undefined) => void
): UseMinMaxDurationSelect => {
    const form = Form.useFormInstance();
    const { setFieldValue, validateFields } = form;
    const selectValue = Form.useWatch<number | string | undefined>(selectName, form);
    const inputValue = Form.useWatch<number | undefined>(name, form);
    const oppositeInputName = getInputName(oppositeName);
    const oppositeSelectName = getSelectName(oppositeName);
    const options = getOptions()[mode];
    const label = getLabel(mode);
    const isCustom =
        selectValue === CUSTOM_OPTION.value ||
        (typeof inputValue === "number" && !options.some((option) => option.value === inputValue));
    const rules = [
        ({ getFieldValue }: { getFieldValue: (name: NamePath) => StoreValue }) => ({
            validator: async () => {
                const isMin = mode === "min";
                const duration = getFieldValue(name);
                const oppositeDuration = getFieldValue(oppositeInputName);

                return isMin
                    ? minValidator(duration, oppositeDuration, required, min)
                    : maxValidator(duration, oppositeDuration, required);
            },
        }),
    ];
    const selectRules = isCustom ? undefined : rules;
    const inputRules = isCustom ? rules : undefined;

    const handleSelectChange = (option: number | string | undefined) => {
        if (option === CUSTOM_OPTION.value) {
            onChange(undefined);
            setFieldValue(selectName, option);
            // setFieldValue(name, undefined);
        } else {
            onChange(option as number);
            setFieldValue(name, option);
            // setFieldValue(selectName, option);
        }
        // push validation into the event queue so that validation will run after updating this select value
        // this handles the case where min custom with value > max is changed to a dropdown option > max
        setTimeout(() => validateFields([selectName, oppositeInputName, oppositeSelectName]));
    };

    const handleInputBlur = ({ target: { value } }: FocusEvent<HTMLInputElement>) => {
        const duration = value ? Number(value) : undefined;
        if (duration && !isNaN(duration) && options.some((option) => option.value === duration)) {
            setFieldValue(selectName, duration);
        }
        onChange(duration);
        validateFields([oppositeInputName, oppositeSelectName]);
    };

    useEffect(() => {
        const shouldInitializeSelectValue = !selectValue && inputValue;
        if (shouldInitializeSelectValue) {
            const initialSelectValue = isCustom ? CUSTOM_OPTION.value : inputValue;
            setFieldValue(selectName, initialSelectValue);
        }

        const shouldInitializeInputValue =
            selectValue && typeof selectValue === "number" && !isNaN(selectValue) && !inputValue;
        if (shouldInitializeInputValue) {
            setFieldValue(name, selectValue);
        }
    }, [selectValue, inputValue, isCustom, name, selectName, setFieldValue]);

    useEffect(() => {
        if (value) {
            setFieldValue(selectName, isCustom ? CUSTOM_OPTION.value : value);
            setFieldValue(name, isCustom ? value : null);
        }
    }, [value, isCustom, setFieldValue, selectName, name]);

    return {
        isCustom,
        label,
        selectRules,
        inputRules,
        options,
        handleSelectChange,
        handleInputBlur,
    };
};
