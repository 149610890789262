import { useNotificationError } from "@app/core/components/hooks";
import { uri } from "@rubicon/utils";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ClassificationsLabelDetailsContent } from "./ClassificationsLabelDetailsContent";
import { Loading } from "@app/core/components";
import { ClassificationsLabelDetailsTitle } from "./ClassificationsLabelDetailsTitle";
import { ClassificationsLabelDefinitionsContent } from "./ClassificationsLabelDefinitions";
import { useGetLabelValuesQuery, useGetSeatLabelDetailsQuery } from "@magnite/client-streaming-platform";
import { HelperKeysDrawerBreadcrumbHeader } from "@app/core/components/HelperKeysDrawerBreadcrumbHeader";
import { ResizableDrawer } from "@rubicon/antd-components";
import { drawerRightClosePosition } from "@app/features/controls/constants";

export const removeLabelDrawerParamsFromSearch = (queryString: string) => {
    const removeDrawerParam = uri.deleteSearchParam(queryString, "drawer");
    const nextQueryString = uri.deleteSearchParam(removeDrawerParam, "labelId");
    return nextQueryString;
};

export const ClassificationsLabelsTabDrawer = () => {
    const { search: queryString } = useLocation();

    const drawerParam = useMemo(() => uri.getSearchParam(queryString, "drawer"), [queryString]);
    const labelId = useMemo(() => uri.getSearchParam(queryString, "labelId"), [queryString]);

    const history = useHistory();
    const onClose = () => {
        const nextQueryString = removeLabelDrawerParamsFromSearch(queryString);
        history.push({ search: nextQueryString });
    };

    const {
        data: label,
        isLoading: isLoadingLabel,
        error: labelError,
    } = useGetSeatLabelDetailsQuery(Number(labelId), { skip: !drawerParam || !labelId });
    const {
        data: labelValues,
        isLoading: isLoadingLabelValues,
        error: labelValuesError,
    } = useGetLabelValuesQuery(Number(labelId), { skip: !drawerParam || !labelId });

    useNotificationError(labelError || labelValuesError);

    return (
        <ResizableDrawer
            title={
                <>
                    {drawerParam === "details" && <ClassificationsLabelDetailsTitle label={label} />}
                    {drawerParam === "definitions" && (
                        <HelperKeysDrawerBreadcrumbHeader parentLabel={label?.key} childLabel="Label Definitions" />
                    )}
                </>
            }
            open={drawerParam === "details" || drawerParam === "definitions"}
            onClose={onClose}
            placement="right"
            width={"45%"}
            className={drawerRightClosePosition}
        >
            {(isLoadingLabel || isLoadingLabelValues) && <Loading />}
            {drawerParam === "details" && (
                <ClassificationsLabelDetailsContent label={label} labelValues={labelValues} wrapperCol={{ xs: 24 }} />
            )}
            {drawerParam === "definitions" && <ClassificationsLabelDefinitionsContent />}
        </ResizableDrawer>
    );
};
