import { FC } from "react";
import { Flex, Form } from "antd";
import { css } from "@emotion/css";
import { AdSourceFloorType, Audience, useGetCurrenciesConversionsByCodeQuery } from "@app/core/services";
import { ResizableDrawer } from "@rubicon/antd-components";
import { AdSourcesForm } from "../../..";
import { getAudienceEnrichmentCost, getIsRangedCost } from "../utils";
import { EcDetailsSection } from "./EcDetailsSection";
import { SectionTitle } from "./SectionTitle";
import { getCurrencyConversionRate } from "@app/features/syncedFields";
import { DEFAULT_ENRICHMENT_COST_CURRENCY_CODE } from "@app/features/seatAdSources/constants";
import { EnrichmentCostTable } from "../EnrichmentCostTable";
import { DealLevelValue } from "../useAdSourceEnrichmentCost";
import { SeatSegment } from "@magnite/client-streaming-platform";

interface Props {
    adSourceCpm: AdSourcesForm["bookingPrice"];
    adSourceCurrencyCode: string;
    adSourceDemandAcquisitionCost: number | null;
    adSourceDemandAcquisitionCostModelId: number | null;
    adSourceFloorType: Partial<AdSourceFloorType> | undefined;
    adSourceLevelAudiences: Audience[];
    adSourceLevelSegments: SeatSegment[];
    adSourceType: AdSourcesForm["type"];
    dealCurrencyCode: string;
    dealLevelAudiences: Audience[];
    dealLevelSegments: SeatSegment[];
    dealLevelValues: DealLevelValue[];
    dealName: string;
    isDemandAcquisitionCostEnabled: boolean;
    onClose: () => void;
    open: boolean;
    shouldUseAdSourceValues: boolean;
}

export const EnrichmentCostDetailsDrawer: FC<Props> = ({
    adSourceCpm,
    adSourceCurrencyCode,
    adSourceDemandAcquisitionCost,
    adSourceDemandAcquisitionCostModelId,
    adSourceFloorType,
    adSourceLevelAudiences,
    adSourceLevelSegments,
    adSourceType,
    dealCurrencyCode,
    dealLevelAudiences,
    dealLevelSegments,
    dealLevelValues,
    dealName,
    isDemandAcquisitionCostEnabled,
    onClose,
    open,
    shouldUseAdSourceValues,
}) => {
    const { data: conversions } = useGetCurrenciesConversionsByCodeQuery(DEFAULT_ENRICHMENT_COST_CURRENCY_CODE);
    const currencyCode = shouldUseAdSourceValues ? adSourceCurrencyCode : dealCurrencyCode;
    const conversionRate = getCurrencyConversionRate(DEFAULT_ENRICHMENT_COST_CURRENCY_CODE, currencyCode, conversions);
    const isRangedCost = getIsRangedCost(adSourceType);
    const adSourceEc = getAudienceEnrichmentCost(
        adSourceLevelAudiences,
        adSourceLevelSegments,
        isRangedCost,
        conversionRate
    );
    const dealEc = getAudienceEnrichmentCost(dealLevelAudiences, dealLevelSegments, isRangedCost, conversionRate);

    return (
        <ResizableDrawer
            open={open}
            onClose={onClose}
            title={`Enrichment Cost: ${dealName}`}
            placement="right"
            width={1024}
            className={css`
                .ant-drawer-body {
                    padding: 0;
                }
            `}
        >
            <Form layout="vertical">
                <Flex vertical style={{ padding: "16px" }}>
                    <EnrichmentCostTable
                        adSourceType={adSourceType}
                        adSourceCpm={adSourceCpm}
                        adSourceFloorType={adSourceFloorType}
                        adSourceDemandAcquisitionCost={adSourceDemandAcquisitionCost}
                        adSourceDemandAcquisitionCostModelId={adSourceDemandAcquisitionCostModelId}
                        adSourceEc={adSourceEc}
                        dealLevelValues={dealLevelValues}
                        isDemandAcquisitionCostEnabled={isDemandAcquisitionCostEnabled}
                        shouldUseAdSourceValues={shouldUseAdSourceValues}
                    />
                </Flex>
                <SectionTitle title="Ad Source EC Details" />
                <EcDetailsSection
                    mode="adSource"
                    audiences={adSourceLevelAudiences}
                    segments={adSourceLevelSegments}
                    ecValue={adSourceEc}
                    adSourceCurrencyCode={dealCurrencyCode}
                    conversionRate={conversionRate}
                />
                <SectionTitle title="Deal EC Details" />
                <EcDetailsSection
                    mode="deal"
                    audiences={dealLevelAudiences}
                    segments={dealLevelSegments}
                    ecValue={dealEc}
                    dealName={dealName}
                    adSourceCurrencyCode={dealCurrencyCode}
                    conversionRate={conversionRate}
                />
            </Form>
        </ResizableDrawer>
    );
};
