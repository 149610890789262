import { useAppSelector } from "@app/core/store";
import { skipToken } from "@reduxjs/toolkit/query";
import { useSeatAuthContext } from "@app/core/auth";
import { Seat } from "@app/core/services";
import { GetSeatPublishersParams } from "@magnite/client-streaming-platform";
import {
    InventoryHealthState,
    selectInventoryHealthSearchFilter,
} from "@app/features/inventory/InventoryHealth/InventoryHealthPage/reducer";

interface UseInventoryHealthInventoryTableFilters {
    seatAllInventoryHealthParams: typeof skipToken | GetSeatPublishersParams;
}

const getSeatAllInventoryHealthParams = (context: Seat | null, searchFilter: InventoryHealthState["searchFilter"]) => {
    if (!context) {
        return skipToken;
    }

    return {
        keyword: searchFilter?.keyword,
        seatId: context.id,
    };
};

export const useInventoryHealthInventoryTableFilters = (): UseInventoryHealthInventoryTableFilters => {
    const { context } = useSeatAuthContext();

    const inventoryHealthSearchFilter = useAppSelector(selectInventoryHealthSearchFilter);

    return {
        seatAllInventoryHealthParams: getSeatAllInventoryHealthParams(context, inventoryHealthSearchFilter),
    };
};
