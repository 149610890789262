import { Form } from "antd";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { SeatAdSourcesType } from "@app/core/services";

const { useWatch } = Form;
export const useIsRequiredValidation = () => {
    // ToDo add generic type
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);

    const adSourceTypeId = adSourceType?.id;
    if (
        adSourceTypeId === AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED ||
        adSourceTypeId === AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED ||
        adSourceTypeId === AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED
    ) {
        return () => ({ required: true, message: `${AD_SOURCE_FIELDS.FIXED_CPM.label} is required` });
    }

    return null;
};
