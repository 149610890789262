import { SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { getPricingSectionName } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/getPricingSectionName";
import { Form } from "antd";

const { useWatch } = Form;

export const usePricingSectionName = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeId = adSourceType?.id;

    return getPricingSectionName(adSourceTypeId);
};
