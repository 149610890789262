import { FC } from "react";
import { Affix, Button, Card, Form, Modal, Space, Typography, Collapse } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { css, cx } from "@emotion/css";
import { ItemType } from "rc-collapse/es/interface";
import { gold6, gray2, gray5, colorLink } from "@rubicon/antd-components";
import { Title } from "@app/core/components";
import { DealList, Targeting } from "@app/core/services";
import {
    DealObjectSidePanel,
    PricingDefinitionSidePanel,
    DemandConfigurationSidePanel,
    AdSourceConfigurationSidePanel,
} from "@app/features/deals/DealForm/DefinitionSidePanels";
import {
    DealTermsSection,
    DealPricingSection,
    ConfigureAdSourceShownSection,
    DealDemandConfigurationSection,
} from "@app/features/deals/DealForm/DealDemandFormSections";
import { DealFormCreateAdSource } from "@app/features/deals/DealForm/DealFormCreateAdSource";
import { DealDetailsPageTableAdSource } from "../DealDetailsPage/DealDetailsPageAdSourceConfiguration/DealDetailsPageTableAdSource";
import { DEAL_SECTIONS_NAME } from "../constants";
import { DealFormMode } from "./types";
import { useDealForm } from "./useDealForm";

export const mainStepSectionClassList = css`
    .ant-collapse-expand-icon {
        align-self: center;
    }

    .ant-collapse-item > .ant-collapse-header {
        align-items: center;
    }

    .ant-collapse-header {
        background-color: ${gray2};
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }

    .ant-typography {
        margin-top: 0;
    }
`;

export const expandIconStyle = {
    marginLeft: "0.25rem",
    fontSize: "1rem",
};
export const adminExpandIconStyle = {
    fontSize: "0.75rem",
    color: colorLink,
    marginLeft: "0",
    left: "6.25rem",
    width: "fit-content",
};
export const internalExpandIconStyle = {
    ...adminExpandIconStyle,
    left: "3.25rem",
};

export const secondaryStepsSectionClassList = css`
    .ant-collapse-item > .ant-collapse-header {
        background: none;
        padding: 0.75rem 1rem;
    }
`;
export const adminInternalSectionClassList = css`
    .ant-collapse-item > .ant-collapse-header {
        padding: 0 !important;
        margin-bottom: 1.5rem;
    }

    .ant-collapse-header-text h4 {
        color: ${colorLink};
        font-size: 0.875rem;
        font-weight: normal;
    }
`;

export const cardBodyStyle = {
    padding: "0.75rem 3rem 0 3rem",
};
const adSourceCardBodyStyle = {
    padding: "0.75rem 2rem 0 1rem",
};

export const rowContentStyle = {
    marginTop: "0.75rem",
};

export const collapseHeaderStyleWithInfo = css`
    border-top: 1px solid ${gray5};

    &.ant-collapse-item > .ant-collapse-header {
        align-items: center;
    }
`;

export const sectionHeaderCollapseStyle = {
    borderBottom: "none",
};

const adSourceNameStyle = {
    background: "#fdfae7",
};

const classList = css`
    border-bottom: 0;
`;

export interface DealCreateFormDemandProps {
    mode: DealFormMode;
    dealAdSource?: DealList;
    targeting?: Targeting[];
}

export const DealCreateFormDemand: FC<DealCreateFormDemandProps> = ({ mode, dealAdSource }) => {
    const {
        adSourceConfigurationShown,
        configureAdSourceShown,
        createdWithBuyerSeats,
        dealAdSourceCopyData,
        dealAdSourceData,
        dealImpressionsShown,
        dealOriginShown,
        dealRateShown,
        demandConfigurationShown,
        enforcementShown,
        form,
        frequencyCappingItemShown,
        handleCancel,
        hideSubmitConfirmationModal,
        handleDelete,
        handleSubmit,
        hasMultipleAdSources,
        hasMultipleDeals,
        hideDeleteModal,
        internalFieldsShowm,
        isAwaitingSubmitCooldown,
        isCopyingAdSource,
        isDeleting,
        isSubmitting,
        isSubmitConfirmationEligible,
        submitConfirmationText,
        isSubmitConfirmationModalOpen,
        isDeleteModalOpen,
        priceModelShown,
        publisherCredentialShown,
        showDeleteModal,
        showSubmitConfirmationModal,
        adSourceAdResponsePriceOverrideShown,
        showDeleteButton,
        deleteModalTitle,
        deleteModalConfirmationText,
    } = useDealForm(mode, dealAdSource);

    const formSubmitText = mode === "create" ? "Submit" : "Save";

    return (
        <Form data-sdet="deal-create-form-demand" layout="vertical" form={form}>
            <>
                {configureAdSourceShown && <ConfigureAdSourceShownSection />}
                <Collapse
                    bordered
                    ghost={false}
                    defaultActiveKey={[1, 2, 3]}
                    className={mainStepSectionClassList}
                    collapsible="header"
                    items={
                        [
                            {
                                key: "1",
                                label: (
                                    <Title
                                        level={5}
                                        title={`Step 1 of ${hasMultipleAdSources ? "2" : "3"}: Deal Object`}
                                    />
                                ),
                                extra: <DealObjectSidePanel />,
                                children: (
                                    <Collapse
                                        className={cx(secondaryStepsSectionClassList, classList)}
                                        style={{ borderTop: "none" }}
                                        defaultActiveKey={["0", "1"]}
                                        collapsible="header"
                                        items={[
                                            {
                                                key: "0",
                                                label: <Title level={5} title={DEAL_SECTIONS_NAME.DEAL_TERMS} />,
                                                children: (
                                                    <DealTermsSection
                                                        mode={mode}
                                                        internalFieldsShowm={internalFieldsShowm}
                                                        dealOriginShown={dealOriginShown}
                                                        publisherCredentialShown={publisherCredentialShown}
                                                        createdWithBuyerSeats={createdWithBuyerSeats}
                                                    />
                                                ),
                                            },
                                            {
                                                key: "1",
                                                style: sectionHeaderCollapseStyle,
                                                label: <Title level={5} title={DEAL_SECTIONS_NAME.PRICING} />,
                                                extra: <PricingDefinitionSidePanel />,
                                                children: (
                                                    <DealPricingSection
                                                        mode={mode}
                                                        priceModelShown={priceModelShown}
                                                        dealRateShown={dealRateShown}
                                                        dealImpressionsShown={dealImpressionsShown}
                                                        adSourceAdResponsePriceOverrideShown={
                                                            adSourceAdResponsePriceOverrideShown
                                                        }
                                                    />
                                                ),
                                            },
                                        ]}
                                    />
                                ),
                            },
                            demandConfigurationShown &&
                                !hasMultipleAdSources && {
                                    key: "2",
                                    children: (
                                        <DealDemandConfigurationSection
                                            mode={mode}
                                            enforcementShown={enforcementShown}
                                            frequencyCappingItemShown={frequencyCappingItemShown}
                                        />
                                    ),
                                    label: (
                                        <Space>
                                            <Title
                                                level={5}
                                                title={`Step 2 of 3: ${DEAL_SECTIONS_NAME.DEMAND_CONFIGURATION}`}
                                            />
                                            <Typography.Text style={{ fontWeight: "normal" }}>
                                                (Additional configuration of this deal to Ad source)
                                            </Typography.Text>
                                        </Space>
                                    ),
                                    extra: <DemandConfigurationSidePanel />,
                                },
                            (hasMultipleAdSources || adSourceConfigurationShown) && {
                                key: "3",
                                style: hasMultipleAdSources ? adSourceNameStyle : undefined,
                                extra: <AdSourceConfigurationSidePanel />,
                                children: (
                                    <>
                                        {hasMultipleAdSources && dealAdSource && (
                                            <Card bordered={false} styles={{ body: adSourceCardBodyStyle }}>
                                                <DealDetailsPageTableAdSource adSources={dealAdSource?.dealListSources}>
                                                    <Typography.Paragraph style={{ marginBottom: "1.25rem" }}>
                                                        <Space size="small">
                                                            <WarningOutlined
                                                                data-sdet="multiple-ad-sources-warning-icon"
                                                                style={{ color: gold6 }}
                                                            />
                                                            The above deal object is configured to multiple ad sources.
                                                            If you want to make any changes to any of the ad sources,
                                                            please go to the detail of the ad source to edit.
                                                        </Space>
                                                    </Typography.Paragraph>
                                                </DealDetailsPageTableAdSource>
                                            </Card>
                                        )}
                                        {!hasMultipleAdSources && adSourceConfigurationShown && (
                                            <DealFormCreateAdSource
                                                mode={mode}
                                                hasMultipleDeals={hasMultipleDeals}
                                                dealAdSourceData={
                                                    isCopyingAdSource ? dealAdSourceCopyData : dealAdSourceData
                                                }
                                            />
                                        )}
                                    </>
                                ),
                                label: (
                                    <Space>
                                        <Title
                                            level={5}
                                            title={`Step ${hasMultipleAdSources ? `2 of 2:` : `3 of 3:`} ${
                                                DEAL_SECTIONS_NAME.AD_SOURCE_CONFIGURATION
                                            }`}
                                        />
                                        {(hasMultipleAdSources || (hasMultipleDeals && mode === "edit")) && (
                                            <WarningOutlined
                                                data-sdet="multiple-ad-sources-deals-warning-icon"
                                                style={{ color: gold6, fontSize: "16px" }}
                                            />
                                        )}
                                    </Space>
                                ),
                            },
                        ].filter(Boolean) as ItemType[]
                    }
                />
            </>
            <Affix offsetBottom={46}>
                <Card styles={{ body: { padding: "0.75rem 1rem" } }} style={{ marginTop: "3rem" }}>
                    <Space>
                        {isSubmitConfirmationEligible ? (
                            <Button
                                type="primary"
                                data-sdet="confirmation-submit"
                                onClick={showSubmitConfirmationModal}
                            >
                                {formSubmitText}
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                loading={isSubmitting}
                                data-sdet="submit"
                                disabled={isAwaitingSubmitCooldown}
                                onClick={handleSubmit}
                            >
                                {formSubmitText}
                            </Button>
                        )}
                        <Button onClick={handleCancel} disabled={isSubmitting} data-sdet="cancel">
                            Cancel
                        </Button>
                        {showDeleteButton && (
                            <Button
                                type="primary"
                                data-sdet="delete-button"
                                disabled={isDeleting}
                                danger
                                loading={isDeleting}
                                onClick={showDeleteModal}
                            >
                                Delete
                            </Button>
                        )}
                    </Space>
                </Card>
            </Affix>
            <Modal
                data-sdet="delete-modal"
                open={isDeleteModalOpen}
                title={deleteModalTitle}
                onOk={handleDelete}
                onCancel={hideDeleteModal}
                confirmLoading={isDeleting}
                styles={{ body: { height: "200px" } }}
                footer={[
                    <Button key="no" onClick={hideDeleteModal}>
                        Cancel
                    </Button>,
                    <Button
                        key="yes"
                        type="primary"
                        danger
                        loading={isDeleting}
                        disabled={isDeleting}
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>,
                ]}
            >
                <Typography.Paragraph>{deleteModalConfirmationText}</Typography.Paragraph>
            </Modal>
            <Modal
                open={isSubmitConfirmationModalOpen}
                title="Confirmation"
                onCancel={hideSubmitConfirmationModal}
                confirmLoading={isSubmitting}
                footer={[
                    <Button key="no" onClick={hideSubmitConfirmationModal} disabled={isSubmitting}>
                        No
                    </Button>,
                    <Button
                        key="yes"
                        type="primary"
                        loading={isSubmitting}
                        disabled={isAwaitingSubmitCooldown}
                        onClick={handleSubmit}
                    >
                        Yes
                    </Button>,
                ]}
            >
                <p>{submitConfirmationText}</p>
            </Modal>
        </Form>
    );
};
