import { Seat, SeatAdSourcesType, useGetAdSourceAuctionTypesQuery, useGetSeatSelfQuery } from "@app/core/services";
import { useIsFieldVisible } from "./useIsFieldVisible";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { AdSourceFormsContext, AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { Form } from "antd";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

const { useWatch, useFormInstance } = Form;

export const useAuctionTypeSelect = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const { setFieldsValue } = useFormInstance<AdSourcesForm>();
    const {
        forms: { adSourceFormMode },
    } = useContext(AdSourceFormsContext);
    const isCreating = adSourceFormMode === "create";
    const isVisible = useIsFieldVisible();

    const { data: seatSelf, isFetching: isSeatFetching } = useGetSeatSelfQuery(seatId, { skip: !isVisible || !seatId });

    const { data: auctionTypes, isFetching: isAuctionTypesFetching } = useGetAdSourceAuctionTypesQuery(undefined, {
        skip: !isVisible,
    });
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);

    const adSourceTypeId = adSourceType?.id;

    const seat: Seat | null = seatSelf?.seat || null;

    useEffect((): void => {
        if (isCreating && seat?.adSourceAuctionTypeDefault) {
            setFieldsValue({
                [AD_SOURCE_FIELDS.AUCTION_TYPE.name]: {
                    id: seat.adSourceAuctionTypeDefault.id,
                    name: seat.adSourceAuctionTypeDefault.name,
                },
            });
        }
    }, [seat, setFieldsValue, isCreating]);

    useEffect((): void => {
        if (isCreating && adSourceTypeId === AdSourceTypeIds.OPEN_AUCTION) {
            setFieldsValue({
                [AD_SOURCE_FIELDS.AUCTION_TYPE.name]: {
                    id: 2,
                    name: "Second Price Auction",
                },
            });
        }
        if (isCreating && adSourceTypeId === AdSourceTypeIds.LINEAR_AUCTION_PRICE) {
            setFieldsValue({
                [AD_SOURCE_FIELDS.AUCTION_TYPE.name]: {
                    id: 1,
                    name: "1st Price Auction",
                },
            });
        }
    }, [isCreating, adSourceTypeId, setFieldsValue]);

    return { auctionTypes, isLoading: isAuctionTypesFetching || isSeatFetching, isVisible };
};
