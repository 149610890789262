import { useSeatAuthContext, useUserAccess } from "@app/core/auth";
import { PAGE_SIZE_OPTIONS } from "@app/core/components/constants";
import { Floor } from "@app/core/services";
import { Button, Col, Row, Space, Table, TableColumnsType } from "antd";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { ADVANCED_FLOORS_TABLE_COLUMN_KEY } from "../InventoryFloorsPage/constants";
import { SeatFloorsAdvancedTableActions } from "./SeatFloorsAdvancedTableActions";
import SeatFloorsTableFilterSearch from "./SeatFloorsAdvancedTableFilterSearch";
import { useSeatFloorsAdvancedTable } from "./useSeatFloorsAdvancedTable";
import { getFloorPriceLabel } from "@app/features/inventory/InventoryFloors/helpers";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { SeatFloorsAdvancedSwitch } from "../SeatFloorsAdvancedSwtich";
import { InventoryAdvancedFloorHelpDrawer } from "../InventoryAdvancedFloorHelpDrawer";
import { StyledLink } from "@app/core/components";

interface DataType extends Floor {
    key: React.Key;
}

const SeatFloorsAdvancedTable: FC = () => {
    const { hasSeatWriteAccess } = useUserAccess();
    const history = useHistory();
    const { context } = useSeatAuthContext();

    const columns: TableColumnsType<DataType> = [
        {
            dataIndex: ADVANCED_FLOORS_TABLE_COLUMN_KEY.FLOOR,
            title: "Floor",
            width: 300,
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (_, record) => <StyledLink onClick={() => handleOpenDrawer(record.id)}>{record.name}</StyledLink>,
        },
        {
            dataIndex: ADVANCED_FLOORS_TABLE_COLUMN_KEY.PRICE,
            title: "Price",
            width: 150,
            sorter: (a, b) => a.price - b.price,
            render: (_, record) => getFloorPriceLabel(record, context?.floorCurrency?.code),
        },
        {
            dataIndex: ADVANCED_FLOORS_TABLE_COLUMN_KEY.ACTIONS,
            title: "Action",
            width: 50,
            render: (_, record) => <SeatFloorsAdvancedTableActions floor={record} />,
        },
    ];

    const handleAddFloor = () => {
        if (!context) {
            return;
        }
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_ADVANCED_CREATE(context.id));
    };
    const { floors, isFetching } = useSeatFloorsAdvancedTable();

    const handleOpenDrawer = (id: number) => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_ADVANCED_DETAILS(Number(context?.id), id));
    };

    const handleBack = () => {
        history.goBack();
    };

    const dataSource: DataType[] = floors.map((floor) => ({
        key: floor.id,
        ...floor,
    }));

    return (
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Row justify="space-between">
                <Col xs={24} sm={10} md={9} lg={7} xl={6} xxl={5}>
                    <SeatFloorsTableFilterSearch />
                </Col>
                <Col>
                    <Space size="large">
                        <InventoryAdvancedFloorHelpDrawer />
                        {hasSeatWriteAccess && (
                            <Button type="primary" onClick={handleAddFloor}>
                                Add Floor
                            </Button>
                        )}
                    </Space>
                </Col>
            </Row>
            <Table
                size="small"
                columns={columns}
                showSorterTooltip={false}
                loading={isFetching}
                dataSource={dataSource}
                pagination={{
                    total: floors.length,
                    showSizeChanger: true,
                    showLessItems: true,
                    size: "default",
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                }}
            />
            <SeatFloorsAdvancedSwitch handleBack={handleBack} />
        </Space>
    );
};

export default SeatFloorsAdvancedTable;
