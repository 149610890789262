import { FC } from "react";
import { Form, Input } from "antd";
import { FORM_ITEMS } from "../../../constants";
import { formatEstAvgConcurrentViewers } from "../../../utils";

export const EstimatedAverageConcurrentViewers: FC = () => {
    return (
        <Form.Item
            name={FORM_ITEMS.EST_AVG_CONCURRENT_VIEWERS.name}
            label={FORM_ITEMS.EST_AVG_CONCURRENT_VIEWERS.label}
        >
            <Input placeholder={`e.g. ${formatEstAvgConcurrentViewers(565000)}`} />
        </Form.Item>
    );
};
