import { LabeledValue } from "antd/lib/select";
import { setFiltersSeatAdSourcesLabelKey } from "../../reducer";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useGetSeatLabelsQuery } from "@magnite/client-streaming-platform";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

interface UseSeatAdSourcesLabelKeyFilter {
    handleChangeValue: (value: string | number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue[] | string | null;
    isLoading: boolean;
}

export const useSeatAdSourcesLabelKeyFilter = (): UseSeatAdSourcesLabelKeyFilter => {
    const dispatch = useAppDispatch();
    const { seatId } = useParams<{ seatId: string }>();
    const seatAdSourcesLabelKey = useAppSelector((state) => state.seatAdSourcesList.filters.seatAdSourcesLabelKey);
    const { data, isFetching } = useGetSeatLabelsQuery({ seatId: Number(seatId) });
    const options = useMemo(() => (data || []).map((filter) => ({ label: filter.key, value: filter.id })), [data]);

    const handleChangeValue = (value: string | number | undefined, option: LabeledValue[]) => {
        dispatch(setFiltersSeatAdSourcesLabelKey(option));
    };

    return {
        handleChangeValue,
        options: options,
        value: seatAdSourcesLabelKey,
        isLoading: isFetching,
    };
};
