import { FC } from "react";
import { Form, Select } from "antd";
import { useRevenueTypeField } from "./useRevenueTypeField";

export const RevenueTypeField: FC = () => {
    const { options, handleChangeValue, value } = useRevenueTypeField();

    return (
        <Form.Item label="Revenue Type" data-sdet="revenue-type-field" style={{ marginBottom: "0px" }}>
            <Select
                style={{ width: "100%" }}
                onChange={handleChangeValue}
                value={value}
                optionFilterProp="label"
                options={options}
            />
        </Form.Item>
    );
};
