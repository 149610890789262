import { Col, Row, Space, Table } from "antd";
import { getColumns, getTableRows } from "./helpers";
import { usePriceOverridesTable } from "./usePriceOverridesTable";
import { DebounceUriSearch } from "@app/core/components";

export const PRICE_OVERRIDES_SEARCH_SDET = "price-overrides-table-search";

export const PriceOverridesTable = () => {
    const { isLoading, data, onClickName } = usePriceOverridesTable();

    return (
        <>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                <Row>
                    <Col>
                        <DebounceUriSearch sdet={PRICE_OVERRIDES_SEARCH_SDET} placeholder="e.g. Site Name or 123456" />
                    </Col>
                </Row>
                <Table
                    size="small"
                    columns={getColumns()}
                    dataSource={getTableRows(data, onClickName)}
                    loading={isLoading}
                    pagination={false}
                    scroll={{ x: 1000, y: 500 }}
                    showSorterTooltip={false}
                />
            </Space>
        </>
    );
};

export default PriceOverridesTable;
