import { useMemo } from "react";
import { useGetRegionsQuery } from "@magnite/client-streaming-platform";
import { UNSPECIFIED_REGION_OPTION } from "@app/features/seatAdSources/constants";

export const useRegionSelect = () => {
    const { data, isLoading } = useGetRegionsQuery();

    const regions = useMemo(() => {
        if (!data) {
            return [];
        }
        return [UNSPECIFIED_REGION_OPTION, ...data];
    }, [data]);

    return { regions, isLoading };
};
