import { FC } from "react";
import { css } from "@emotion/css";
import { Button, Col, Row, Space, Tooltip } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { Loading } from "@app/core/components";
import { useSeatAdSourcesPageNavigator } from "@app/features/seatAdSources/utils/index";
import { useUserAccess } from "@app/core/auth";
import { useFetchSeatAdSourceByUrlId } from "../data";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/types";
import {
    isAdSourceAdServingEligible,
    isAdSourceCopyEligible,
    isAdSourceEditEligible,
} from "@app/features/seatAdSources/utils";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useSeatAdSourcesDetailsDrawer } from "../SeatAdSourcesDetailsDrawer/useSeatAdSourcesDetailsDrawer";
import { SeatAdSourcesDetails } from "../SeatAdSourcesDetails/SeatAdSourcesDetails";
import { AdSourceDetailsDrawerWrapper } from "./AdSourceDetailsDrawerWrapper";

export const SeatAdSourcesDetailsPage: FC = () => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { adSource, isFetching, isError } = useFetchSeatAdSourceByUrlId();
    const { openSeatAdSourceDetailsPageDrawer } = useSeatAdSourcesDetailsDrawer();

    const {
        goToSeatAdSourcesEditPage,
        goToSeatAdSourcesCopyPage,
        goToSeatAdSourcesListPage,
        isExpandAll,
        handleChangeCollapse,
    } = useSeatAdSourcesPageNavigator();
    const { canEditSeat, isTremorUser } = useUserAccess();

    if (isFetching) {
        return <Loading />;
    }

    if (isError || !adSource) {
        history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES(seatId));
        return null;
    }

    const showAdServing = adSource && isAdSourceAdServingEligible(adSource.type.id);
    const showCopyButton = adSource && isAdSourceCopyEligible(canEditSeat);
    const showEditButton = adSource && isAdSourceEditEligible(canEditSeat);

    return (
        <div
            data-sdet="seat-ad-sources-details-page"
            className={css`
                .ant-collapse {
                    border: 0;

                    &:first-child {
                        border-top: 1px solid #d9d9d9;
                    }
                }

                .ant-collapse > .ant-collapse-item > .ant-collapse-header {
                    padding: 0 16px;
                    line-height: 42px;

                    .ant-collapse-header-text {
                        font-size: 20px;
                        font-weight: 500;

                        .ant-typography {
                            line-height: 42px;
                        }
                    }

                    .ant-collapse-arrow {
                        font-size: 16px !important;
                        vertical-align: -3px;
                    }
                }
            `}
        >
            <PageHeader
                title={
                    <Tooltip placement="bottomRight" title={adSource.name}>
                        <span>{adSource.name}</span>
                    </Tooltip>
                }
                onBack={goToSeatAdSourcesListPage}
            >
                <Row justify="space-between" gutter={[16, 8]} style={{ width: "100%" }}>
                    <Col>
                        <Space>
                            {showEditButton && (
                                <Button type="primary" onClick={() => goToSeatAdSourcesEditPage(adSource.id)}>
                                    Edit
                                </Button>
                            )}
                            {showCopyButton && (
                                <Button onClick={() => goToSeatAdSourcesCopyPage(adSource.id)}>Copy</Button>
                            )}
                            <Button
                                onClick={() =>
                                    openSeatAdSourceDetailsPageDrawer(adSource.id, SecondaryDetailsView.Ladle)
                                }
                            >
                                Ladle
                            </Button>
                            <Button type="link" onClick={handleChangeCollapse}>
                                {isExpandAll ? "Expand All" : "Collapse All"}
                            </Button>
                        </Space>
                    </Col>
                    <Col>
                        <Space wrap>
                            {showAdServing && (
                                <Button
                                    onClick={() =>
                                        openSeatAdSourceDetailsPageDrawer(adSource.id, SecondaryDetailsView.AdServing)
                                    }
                                >
                                    Ad Serving
                                </Button>
                            )}
                            <Button
                                onClick={() =>
                                    openSeatAdSourceDetailsPageDrawer(adSource.id, SecondaryDetailsView.VastErrors)
                                }
                            >
                                VAST Errors
                            </Button>
                            <Button
                                onClick={() =>
                                    openSeatAdSourceDetailsPageDrawer(adSource.id, SecondaryDetailsView.CreativeReview)
                                }
                            >
                                Creative Review
                            </Button>
                            {isTremorUser && (
                                <Button
                                    onClick={() =>
                                        openSeatAdSourceDetailsPageDrawer(adSource.id, SecondaryDetailsView.TimingStats)
                                    }
                                >
                                    Timing Stats
                                </Button>
                            )}
                            <Button
                                onClick={() =>
                                    openSeatAdSourceDetailsPageDrawer(adSource.id, SecondaryDetailsView.ChangeHistory)
                                }
                            >
                                Change History
                            </Button>
                            <Button
                                onClick={() =>
                                    openSeatAdSourceDetailsPageDrawer(adSource.id, SecondaryDetailsView.Permissions)
                                }
                            >
                                Permissions
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </PageHeader>
            <AdSourceDetailsDrawerWrapper />
            <SeatAdSourcesDetails adSource={adSource} />
        </div>
    );
};
