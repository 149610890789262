import { useUserAccess } from "@app/core/auth";
import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";

export const useIsAdResponsePriceOverrideVisible = (adSourceTypeId: number | undefined): boolean => {
    const { showAdResponsePriceOverride } = useUserAccess();

    return (
        showAdResponsePriceOverride &&
        (adSourceTypeId
            ? [
                  AdSourceTypeIds.PROGRAMMATIC_GUARANTEED,
                  AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED,
                  AdSourceTypeIds.FIXED_PRICE,
                  AdSourceTypeIds.AUCTION_PRICE,
                  AdSourceTypeIds.OPEN_AUCTION,
              ].includes(adSourceTypeId)
            : false)
    );
};
