import { useWatch } from "antd/lib/form/Form";
import { MarketplaceInfo, SeatAdSourcesStatus, TimeZone } from "@app/core/services";
import { TargetingFormKeys } from "@app/features/targeting/constants";
import { useTargetingForm } from "@app/features/targeting/useTargetingForm";
import { useContext } from "react";
import { AD_SOURCE_FIELDS, AdSourceTypeIds, DealTypeIds } from "../constants";
import { DEAL_FORM_FIELDS, LEGACY_DEAL_ENTITY_TYPE } from "./DealFormDrawer/DealFormContent/constants";
import { AdSourceFormsContext, AdSourcesForm, MarketplaceInfoContext } from "./SeatAdSourcesForm";
import { useAppDispatch } from "@app/core/store";
import { resetTargetingBlocksByFormKey } from "@app/features/targeting/reducer";
import { useAdSourceSyncDealToAdSource } from "./sync/useAdSourceSyncDealToAdSource";
import { SyncedFields, useSyncedFieldsProducer } from "@app/features/syncedFields";
import { parseDateStringFromApi } from "@app/core/utils";

export type MarketplaceInfoDrawerModeName = "closed" | "view" | "edit" | "create";

export interface MarketplaceInfoDrawerBaseMode {
    name: MarketplaceInfoDrawerModeName;
}

export interface ClosedMode extends MarketplaceInfoDrawerBaseMode {
    name: "closed";
}

export interface ViewMode extends MarketplaceInfoDrawerBaseMode {
    name: "view";
    selectedId: number;
}

export type DealAttachType = "newDeal" | "existingDeal";

export interface CreateMode extends MarketplaceInfoDrawerBaseMode {
    name: "create";
    attachType: DealAttachType;
}

export interface EditMode extends MarketplaceInfoDrawerBaseMode {
    name: "edit";
    selectedId: number;
}

export type MarketplaceInfoDrawerMode = ClosedMode | ViewMode | CreateMode | EditMode;

export const useMarketplaceInfoWidget = () => {
    const { forms } = useContext(AdSourceFormsContext);
    const { loadValidationTargeting } = useTargetingForm();
    const { marketplaceInfoModeState, dealObjectModeState, dealFetchingState } = useContext(MarketplaceInfoContext);
    const [marketplaceInfoMode, setMarketplaceInfoMode] = marketplaceInfoModeState;
    const [isDealFetching, setIsDealFetching] = dealFetchingState;
    const [dealObjectMode, setDealObjectMode] = dealObjectModeState;
    const dispatch = useAppDispatch();
    const dealOrigin = useWatch<MarketplaceInfo["deal"]>("dealOrigin", forms.dealObjectForm);
    const { syncDealFieldsToAdSource, onSubmitUpdateMarketplaceInfo, consumeAllSyncedFields } =
        useAdSourceSyncDealToAdSource(dealOrigin?.id);
    const { updateSyncedFields } = useSyncedFieldsProducer();

    const deleteByIndex = (index: number) => {
        consumeAllSyncedFields();
        const dealsList: MarketplaceInfo[] = forms.adSourceForm.getFieldValue(AD_SOURCE_FIELDS.DEMAND.name);
        const newDealsList = [...dealsList];
        newDealsList.splice(index, 1);
        forms.adSourceForm.setFieldValue(AD_SOURCE_FIELDS.DEMAND.name, newDealsList);
        const adSourceTypeId = forms.adSourceForm.getFieldValue(AD_SOURCE_FIELDS.TYPE.name)?.id;
        const adSourceTimeZone = forms.adSourceForm.getFieldValue(AD_SOURCE_FIELDS.TIME_ZONE.name);

        if (
            dealsList.length === 2 &&
            newDealsList.length === 1 &&
            [AdSourceTypeIds.AUCTION_PRICE, AdSourceTypeIds.FIXED_PRICE].includes(adSourceTypeId)
        ) {
            const newDeal = newDealsList[0];
            const isLegacyDeal = newDeal.deal.entityType === LEGACY_DEAL_ENTITY_TYPE;
            const postDeleteSyncedFields: SyncedFields = isLegacyDeal
                ? {
                      startDate: parseDateStringFromApi(newDeal.startDate, adSourceTimeZone?.code),
                      endDate: parseDateStringFromApi(newDeal.endDate, adSourceTimeZone?.code),
                  }
                : {
                      startDate: parseDateStringFromApi(newDeal.deal.startTime, newDeal.deal.timeZone?.code),
                      endDate: parseDateStringFromApi(newDeal.deal.endTime, newDeal.deal.timeZone?.code),
                      timeZone: newDeal.deal.timeZone as TimeZone,
                      cpmRate: newDeal.deal.rate,
                      status: newDeal.deal.status as SeatAdSourcesStatus,
                      currency: newDeal.deal.currencyType,
                  };
            syncDealFieldsToAdSource(null, marketplaceInfoMode, postDeleteSyncedFields);
        }

        loadValidationTargeting(TargetingFormKeys.AdSource, newDealsList.map((mpInfo) => mpInfo.targeting).flat());
    };

    const getAdSourceFormData = () => {
        const adSourceFormData: AdSourcesForm = forms.adSourceForm.getFieldsValue();
        return adSourceFormData;
    };

    const deleteMarketplaceInfo = () => {
        if (marketplaceInfoMode.name === "edit" || marketplaceInfoMode.name === "view") {
            deleteByIndex(marketplaceInfoMode.selectedId);
            close();
        }
    };

    const setCreateMode = (attachType: DealAttachType) => {
        setMarketplaceInfoMode({ name: "create", attachType });
    };

    const getSelectedMarketplaceInfo = (index: number) => {
        const dealsList: MarketplaceInfo[] = forms.adSourceForm.getFieldValue(AD_SOURCE_FIELDS.DEMAND.name);
        const selectedDeal = dealsList?.[index];
        if (!selectedDeal) {
            return null;
        }
        return selectedDeal;
    };

    const setSelectedMarketplace = (index: number) => {
        const dealsList: MarketplaceInfo[] = forms.adSourceForm.getFieldValue(AD_SOURCE_FIELDS.DEMAND.name);
        const selectedDeal = dealsList?.[index];
        if (!selectedDeal) {
            return;
        }

        const formData = getAdSourceFormData();

        forms.dealObjectForm.setFieldsValue({
            dealOrigin: {
                ...selectedDeal.deal,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                startTime: selectedDeal.deal.startTime
                    ? parseDateStringFromApi(selectedDeal.deal.startTime, selectedDeal.deal.timeZone?.code)
                    : null,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                endTime: selectedDeal.deal.endTime
                    ? parseDateStringFromApi(selectedDeal.deal.endTime, selectedDeal.deal.timeZone?.code)
                    : null,
            },
        });
        forms.demandConfigurationForm.setFieldsValue({
            currencyType: selectedDeal.currencyType,
            enforcement: selectedDeal.enforcement,
            freqCappingType: selectedDeal.freqCappingType,
            [DEAL_FORM_FIELDS.DEAL_FREQ_CAPPINGS.name]: selectedDeal.dealFreqCappings,
            buyerSeats: selectedDeal.buyerSeats,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            endDate: selectedDeal.endDate
                ? parseDateStringFromApi(selectedDeal.endDate, formData.timeZone?.code)
                : null,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            startDate: selectedDeal.startDate
                ? parseDateStringFromApi(selectedDeal.startDate, formData.timeZone?.code)
                : null,
            network: selectedDeal.network,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            targeting: selectedDeal.targeting,
            runDateMode: !selectedDeal.endDate ? "evergreen" : "fixed",
        });
    };

    const setEditMode = (index: number) => {
        setMarketplaceInfoMode({ name: "edit", selectedId: index });
        setSelectedMarketplace(index);
    };

    const close = () => {
        consumeAllSyncedFields();
        setMarketplaceInfoMode({ name: "closed" });
        setDealObjectMode("view");
        forms.dealObjectForm.resetFields();
        forms.demandConfigurationForm.resetFields();
        dispatch(resetTargetingBlocksByFormKey({ formKey: TargetingFormKeys.AdSourceAdditionalTargeting }));
    };

    const setViewMode = (index: number) => {
        setMarketplaceInfoMode({ name: "view", selectedId: index });
        setSelectedMarketplace(index);
    };

    const submitMarketplaceInfo = (name: string, { values }) => {
        if (name === "demandConfigurationForm") {
            const dealObject = forms.dealObjectForm.getFieldsValue();
            const isAttachingDeal = marketplaceInfoMode.name === "create";
            const isLegacyDeal = dealObject.dealOrigin.entityType === LEGACY_DEAL_ENTITY_TYPE;

            if (isAttachingDeal) {
                if (
                    marketplaceInfoMode.attachType === "existingDeal" &&
                    [DealTypeIds.AUCTION_PRICE, DealTypeIds.FIXED_PRICE, DealTypeIds.PROGRAMMATIC_GUARANTEED].includes(
                        dealObject.dealOrigin.dealType.id
                    )
                ) {
                    // When attaching an existing fixed price deal, we need to produce all syncable values because there is no guarantee that the individual field
                    // onChange / onBlur events will have been triggered to produce the syncable values (as they normally would be during create and edit).
                    updateSyncedFields({
                        startDate: parseDateStringFromApi(
                            dealObject.dealOrigin.startTime,
                            dealObject.dealOrigin.timeZone?.code
                        ),
                        endDate: dealObject.dealOrigin.endTime
                            ? parseDateStringFromApi(
                                  dealObject.dealOrigin.endTime,
                                  dealObject.dealOrigin.timeZone?.code
                              )
                            : null,
                        timeZone: dealObject.dealOrigin.timeZone as TimeZone,
                        cpmRate: dealObject.dealOrigin.rate,
                        status: dealObject.dealOrigin.status as SeatAdSourcesStatus,
                        currency: dealObject.dealOrigin.currencyType,
                        impressions: dealObject.dealOrigin.bookingVolume,
                    });
                }
                syncDealFieldsToAdSource(dealObject.dealOrigin, marketplaceInfoMode);
            } else if (isLegacyDeal) {
                syncDealFieldsToAdSource(dealObject.dealOrigin, marketplaceInfoMode);
            }

            onSubmitUpdateMarketplaceInfo(dealObject.dealOrigin, values);

            close();
        }
    };

    return {
        marketplaceInfoMode,
        setCreateMode,
        setEditMode,
        close,
        setViewMode,
        submitMarketplaceInfo,
        deleteByIndex,
        deleteMarketplaceInfo,
        isDealFetching,
        setIsDealFetching,
        dealObjectMode,
        setDealObjectMode,
        getSelectedMarketplaceInfo,
        getAdSourceFormData,
    };
};
