import { LabeledValue } from "antd/lib/select";
import { useGetRegionsQuery } from "@magnite/client-streaming-platform";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";

interface UseAdSourceRegionField {
    options: LabeledValue[];
    isFetching: boolean;
    onChange: (value: number) => void;
    value: number | null;
}

export const useAdSourceRegionField = (): UseAdSourceRegionField => {
    const dispatch = useAppDispatch();
    const { data, isFetching } = useGetRegionsQuery();

    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_REGION]);
    const options = useMemo(() => (data || []).map((item) => ({ label: item.name, value: item.id })), [data]);

    const onChange = (value: number): void => {
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_REGION, value }));
    };
    return {
        options,
        isFetching,
        value,
        onChange,
    };
};
