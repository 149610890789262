import { FC } from "react";
import { useDspFilter } from "./useDealHealthDspFilter";
import { LabeledValue } from "antd/es/select";
import { useAppSelector } from "@app/core/store";
import { Form, Select } from "antd";
import { TagRender } from "@app/core/components/TagRender";
import { HelpTooltip } from "@app/core/components";
import { DealsHelpKeys } from "@app/features/deals/constants";
import { SelectAllDropdownRender } from "@app/core/components";

export const DealHealthDspFilter: FC = () => {
    const { options, value, handleChange, isFetching, areAllOptionsSelected, toggleSelectDeselectAll } = useDspFilter();
    const dealType = useAppSelector((state) => state.dealHealthDeprecated.filters.filterDealType);
    // value === 4 is the legacy deal type
    const isLegacy = dealType?.some(({ value }) => value === 4);

    return (
        <Form.Item
            label={
                <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealDSP} popover={true}>
                    DSP
                </HelpTooltip>
            }
            data-sdet="deal-health-dsp-filter"
            style={{ marginBottom: 0 }}
        >
            <Select
                allowClear={Boolean(value)}
                optionFilterProp="label"
                value={value}
                loading={isFetching}
                placeholder={"All"}
                onChange={(_a, option) => handleChange(option as LabeledValue[])}
                mode="multiple"
                options={options}
                disabled={isLegacy}
                showSearch
                style={{ width: "100%" }}
                tagRender={TagRender}
                dropdownRender={(menu) => (
                    <SelectAllDropdownRender
                        menu={menu}
                        onClick={toggleSelectDeselectAll}
                        areAllOptionsSelected={areAllOptionsSelected}
                    />
                )}
            />
        </Form.Item>
    );
};
