import { AdSource } from "@app/core/services";
import { AdSourceTypeIds, DefaultDemandAcquisitionCostModel, DemandAcquisitionCostModelId } from "../../constants";
import { useDemandAcquisitionCostUtils } from "../AdSourcesSections/EnrichmentCostSection/useDemandAcquisitionCostUtils";

interface UseParseEnrichmentCostFields {
    parseEnrichmentCostFieldsToApi: (
        adSourceTypeId: number,
        demandAcquisitionCost: number | string | null,
        demandAcquisitionCostModelId: number | null
    ) => EnrichmentCostApiFields;
}

interface EnrichmentCostFields {
    demandAcquisitionCost: number | null;
    operatorCost: number | null;
}

export interface EnrichmentCostFormFields extends EnrichmentCostFields {
    demandAcquisitionCostModel: { id: number; name: string } | null;
    operatorCostModel: { id: number; name: string } | null;
}

export interface EnrichmentCostApiFields extends EnrichmentCostFields {
    demandAcquisitionCostModel: { id: number } | null;
    operatorCostModel: { id: number } | null;
}

export const parseDemandAcquistionCostFromApi = (
    apiDac: number | null | undefined,
    apiDacModelId: number | undefined
): number | null => {
    if (!apiDac) {
        return null;
    }

    if (apiDacModelId === DemandAcquisitionCostModelId.PERCENTAGE) {
        // percent is returned as is (1 -> 1)
        return apiDac;
    }

    // fixed is returned in micros (1000 -> 1)
    return apiDac / 1000;
};

export const parseEnrichmentCostFieldsFromApi = (adSource: AdSource | undefined): EnrichmentCostFormFields => {
    const dacCostModel = adSource?.demandAcquisitionCostModel ?? null;
    return {
        demandAcquisitionCost: parseDemandAcquistionCostFromApi(adSource?.demandAcquisitionCost, dacCostModel?.id),
        demandAcquisitionCostModel: dacCostModel,
        operatorCost: adSource?.operatorCost || null,
        operatorCostModel: { id: 1, name: "Fixed" },
    };
};

const isAdSourceTypeDemandAcquisitionCostIneligible = (adSourceTypeId: AdSourceTypeIds): boolean =>
    adSourceTypeId === AdSourceTypeIds.MARKETPLACE || adSourceTypeId === AdSourceTypeIds.CURATOR_MARKETPLACE;

export const useParseEnrichmentCostFields = (): UseParseEnrichmentCostFields => {
    const { isDemandAcquisitionEnabled } = useDemandAcquisitionCostUtils();

    const parseEnrichmentCostFieldsToApi = (
        adSourceTypeId: number,
        demandAcquisitionCost: number | string | null,
        demandAcquisitionCostModelId: number | null
    ) => {
        const isEligible = !isAdSourceTypeDemandAcquisitionCostIneligible(adSourceTypeId);
        const isEnabled = isEligible && isDemandAcquisitionEnabled;

        const dacCostModel = isEnabled
            ? { id: demandAcquisitionCostModelId || DefaultDemandAcquisitionCostModel.id }
            : null;

        const dacFormValue = Number(demandAcquisitionCost);
        const dac =
            isEnabled && !isNaN(dacFormValue) && demandAcquisitionCost !== null
                ? dacFormValue * 1000 // both percent and fixed dacs are sent as micros (1 -> 1000)
                : null;

        return {
            demandAcquisitionCost: dac,
            demandAcquisitionCostModel: dacCostModel,
            operatorCost: null,
            operatorCostModel: { id: 1, name: "Fixed" },
        };
    };

    return {
        parseEnrichmentCostFieldsToApi,
    };
};
