import { Form, Table } from "antd";
import { getColumns, getTableRows } from "./helpers";
import { useTagParamsTable } from "./useTagParamsTable";
import { useEditModeControls } from "../EditModeControls/useEditModeControls";
import { useAppSelector } from "@app/core/store";
import { selectMacroFields } from "../reducer";
import { tableCellFormItemStyle } from "../../constants";

export const DATA_SDET_FORM = "tag-params-list-form";
export const TAG_PARAMS_TABLE_SDET = "tag-params-list-table";
export const TAG_PARAM_TABLE_NAME = "macro";

export const TagParamsTable = () => {
    const { isLoading, data, handleChange, handleChangeCheckbox } = useTagParamsTable();
    const { isEdit } = useEditModeControls();
    const macroValues = useAppSelector(selectMacroFields);

    return (
        <Form data-sdet={DATA_SDET_FORM}>
            <Table
                size="small"
                className={tableCellFormItemStyle}
                data-sdet={TAG_PARAMS_TABLE_SDET}
                columns={getColumns()}
                dataSource={getTableRows(data, macroValues, {
                    handleChange,
                    handleChangeCheckbox,
                    isEdit: Boolean(isEdit),
                })}
                loading={isLoading}
                pagination={false}
                scroll={{ x: 1000 }}
                showSorterTooltip={false}
            />
        </Form>
    );
};

export default TagParamsTable;
