import { FC } from "react";
import { AdUnit, Brand, BrandSupply, Seat } from "@app/core/services";
import { Publisher } from "@magnite/client-streaming-platform";
import { AffiliateCostValue } from "@app/features/inventory/components/FormItems";
import { REVSHARE_PERCENTAGE_COST_MODEL } from "@app/features/inventory/InventorySeat/constants";
import { useAdUnitFormInstance } from "@app/features/inventory/HierarchyForms/AdUnitForm/AdUnitFormInstance";

const getExtra = (
    seat: Seat | null,
    publisher: Publisher | undefined,
    brand: Brand | undefined,
    supply: BrandSupply | undefined,
    adUnit: AdUnit | undefined
): string | null => {
    if (brand?.affiliateCostModel?.id === REVSHARE_PERCENTAGE_COST_MODEL.value) {
        return `Inherited value: ${
            adUnit?.affiliateCostValuePercent ??
            supply?.affiliateCostValuePercent ??
            brand?.affiliateCostValuePercent ??
            publisher?.affiliateCostValuePercent ??
            seat?.affiliateCostValuePercent
        } %`;
    }
    const affiliateCostValueFixed: number | null | undefined =
        adUnit?.affiliateCostValueFixed ??
        supply?.affiliateCostValueFixed ??
        brand?.affiliateCostValueFixed ??
        publisher?.affiliateCostValueFixed ??
        seat?.affiliateCostValueFixed;

    if (typeof affiliateCostValueFixed === "number") return `Inherited value: ${affiliateCostValueFixed / 1000} CPM`;

    return null;
};

export const CostValue: FC = () => {
    const { seat, publisher, brand, supply, adUnit } = useAdUnitFormInstance();
    const extra: string | null = getExtra(seat, publisher, brand, supply, adUnit);
    return <AffiliateCostValue extra={extra} wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }} />;
};
