import { isDevOrQaEnv } from "@app/core/conf";
import { useUserAccess } from "./useUserAccess";

interface UseAdStatsBetaAccess {
    hasDealHealthBetaAccess: boolean;
    hasDealPerformanceOverviewBetaAccess: boolean;
    hasAdSourcesListBetaAccess: boolean;
    hasAdSourcesPerformanceOverviewBetaAccess: boolean;
}

export const useAdStatsBetaAccess = (): UseAdStatsBetaAccess => {
    const { hasInternalAccess } = useUserAccess();

    return {
        hasDealHealthBetaAccess: isDevOrQaEnv && hasInternalAccess,
        hasDealPerformanceOverviewBetaAccess: isDevOrQaEnv && hasInternalAccess,
        hasAdSourcesListBetaAccess: isDevOrQaEnv && hasInternalAccess,
        hasAdSourcesPerformanceOverviewBetaAccess: isDevOrQaEnv && hasInternalAccess,
    };
};
