import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { useGetCostModelsQuery } from "@magnite/client-streaming-platform";
import { AFFILIATE_COST_VALUE_FIXED, AFFILIATE_COST_VALUE_PERCENT } from "@app/features/inventory/constants";

const { useWatch } = Form;

interface UseAffiliateCostModel {
    isFetching: boolean;
    options: LabeledValue[];
    costValue: number | undefined;
}

export const useAffiliateCostModel = (): UseAffiliateCostModel => {
    const costValueFixed = useWatch(AFFILIATE_COST_VALUE_FIXED);
    const costValuePercent = useWatch(AFFILIATE_COST_VALUE_PERCENT);
    const { data, isFetching } = useGetCostModelsQuery();
    const options = useMemo(() => (data || []).map((model) => ({ value: model.id, label: model.name })), [data]);
    return {
        options,
        isFetching,
        costValue: costValuePercent || costValueFixed,
    };
};
