import { FC } from "react";
import { Row, Col, Typography, Button, Space, Tooltip } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { GoToEntityLink } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ContentTransparencyRule, useDeleteContentTransparencyRuleMutation } from "@app/core/services";
import { HelpKeysButton } from "@app/core/components/HelpKeysDrawer";
import { uri } from "@rubicon/utils";
import { useBrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";
import { BrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal";
import { CONTENT_TRANSPARENCY_DELETE_BTN_SDET } from "./ContentTransparencyRuleDetailsContent";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";
import { useGoToEntityLink } from "@app/core/components/GoToEntityLink/useGotoEntityLink";

interface Props {
    entity?: ContentTransparencyRule;
}

export const CONTENT_TRANSPARENCY_RULES_TITLE_SDET = "content-transparency-rules-details-drawer-title";
export const CONTENT_TRANSPARENCY_RULES_TITLE_NAME_SDET = "content-transparency-rules-details-drawer-title-name";
export const CONTENT_TRANSPARENCY_RULES_DETAILS_ACTIONS_SDET = "content-transparency-rules-details-action";
export const CONTENT_TRANSPARENCY_RULES_ACTIONS_EDIT_BUTTON_SDET = "content-transparency-rules-details-action-edit-btn";

export const ContentTransparencyRulesDrawerTitle: FC<Props> = ({ entity }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { search } = useLocation();
    const copyUrl = useGoToEntityLink();
    const history = useHistory();
    const { canEditSeat } = useUserAccess();

    const handlePostDelete = () => {
        history.replace(
            ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, [
                CONTROLS_ROUTES.BRAND_SAFETY,
                CONTROLS_ROUTES.CONTENT_TRANSPARENCY_RULES,
            ])
        );
    };
    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteContentTransparencyRuleMutation();
    const { handleOpen: handleDeleteModal, ...restModalProps } = useBrandSafetyDeleteModal(
        deleteTrigger,
        handlePostDelete
    );

    return entity ? (
        <Row data-sdet={CONTENT_TRANSPARENCY_RULES_TITLE_SDET} gutter={[0, 16]} style={{ marginRight: "1rem" }}>
            <Col span={24}>
                <Row justify="space-between">
                    <Col>
                        <Row gutter={[8, 4]} style={{ alignItems: "baseline" }}>
                            <Col>
                                <Typography.Title
                                    data-sdet={CONTENT_TRANSPARENCY_RULES_TITLE_NAME_SDET}
                                    ellipsis={{
                                        tooltip: {
                                            title: `${entity.name}`,
                                            placement: "bottom",
                                        },
                                    }}
                                    level={5}
                                    style={{
                                        margin: 0,
                                        maxWidth: "18rem",
                                    }}
                                >
                                    {entity.name}
                                </Typography.Title>
                            </Col>
                            <Col>
                                {entity.id && (
                                    <GoToEntityLink
                                        entity="Content Transparency"
                                        linkTo={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_RULES_EDIT(
                                            seatId,
                                            entity.id
                                        )}
                                        copyUrl={copyUrl}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <HelpKeysButton
                            onClick={() => {
                                const nextQueryString = uri.setSearchParam(search, "drawer", "definitions");
                                history.push({ search: nextQueryString });
                            }}
                            buttonName="Content Transparency"
                        />
                    </Col>
                </Row>
            </Col>
            {canEditSeat && (
                <Col span={24}>
                    <Row data-sdet={CONTENT_TRANSPARENCY_RULES_DETAILS_ACTIONS_SDET}>
                        <Col>
                            <Space>
                                <Button
                                    onClick={() =>
                                        entity.id &&
                                        history.push(
                                            ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_RULES_EDIT(
                                                seatId,
                                                entity.id
                                            )
                                        )
                                    }
                                    data-sdet={CONTENT_TRANSPARENCY_RULES_ACTIONS_EDIT_BUTTON_SDET}
                                    type="primary"
                                >
                                    Edit
                                </Button>
                                <Tooltip title="Delete">
                                    <Button
                                        type="primary"
                                        data-sdet={CONTENT_TRANSPARENCY_DELETE_BTN_SDET}
                                        danger
                                        onClick={() => handleDeleteModal({ id: entity.id, name: entity.name })}
                                    >
                                        Delete
                                    </Button>
                                </Tooltip>
                                <BrandSafetyDeleteModal isDeleting={isDeleting} {...restModalProps} />
                            </Space>
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    ) : null;
};
