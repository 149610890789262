import { Col, Typography } from "antd";
import { AdSource } from "@app/core/services";
import { useIsFieldVisible } from "./useIsFieldVisible";

const { Text, Paragraph } = Typography;

export const FloorTypeSelect = ({ adSource }: { adSource: AdSource }) => {
    const isVisible = useIsFieldVisible(adSource);

    if (!isVisible) {
        return null;
    }
    return (
        <Col xs={12} xl={8}>
            <Text strong>Floor Type</Text>
            <Paragraph>{adSource?.adSourceFloorType.name || "--"}</Paragraph>
        </Col>
    );
};
