import { FC, useState } from "react";
import { Col, Form, Row, Tag } from "antd";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { TagRender } from "@app/core/components";
import { useLiveEventsSelect } from "./useLiveEventsSelect";
import { currentlyLiveEvents, completedLiveEvents } from "../utils";
import { LiveEvent } from "@app/core/services/console/liveEvents";
import { NONE } from "@app/core/components/constants";

type Props = {
    value?: LiveEvent[];
    onChange?: (liveEvents: LiveEvent[]) => void;
};

const LiveEventSelect: FC<Props> = ({ value, onChange }) => {
    const { isFetching, onSearch, loadMore, hasMore, options } = useLiveEventsSelect();
    const initialLiveEvents = value ? currentlyLiveEvents(value) : [];
    const initialCompletedEvents = value ? completedLiveEvents(value) : [];
    const [currentLiveEvents, setCurrentLiveEvents] = useState(initialLiveEvents);

    const hasCompletedEvents = initialCompletedEvents.length > 0;

    const handleChange = (value: LiveEvent[]) => {
        if (onChange) {
            setCurrentLiveEvents(value);
            onChange([...value, ...initialCompletedEvents]);
        }
    };

    return (
        <Col>
            <Row>
                <SelectWithValue
                    loading={isFetching}
                    data-sdet="live-event-select"
                    showSearch
                    mode="multiple"
                    value={currentLiveEvents}
                    tagRender={TagRender}
                    onChange={handleChange}
                    options={options}
                    optionFilterProp="label"
                    placeholder={"Select Live Event.."}
                    fieldAsLabel={["name"]}
                    fieldAsValue={["id"]}
                    onSearch={onSearch}
                    hasMore={hasMore}
                    loadMore={loadMore}
                />
            </Row>
            {hasCompletedEvents && (
                <>
                    <h4 style={{ marginTop: 0, marginBottom: 10 }}>Completed Events</h4>
                    {initialCompletedEvents && initialCompletedEvents.length > 0
                        ? initialCompletedEvents?.map((event) => <Tag key={event.id}>{event.name}</Tag>)
                        : NONE}
                </>
            )}
        </Col>
    );
};
export const LiveEventFormItem: FC = () => {
    return (
        <Form.Item
            label={AD_SOURCE_FIELDS.LIVE_EVENTS.label}
            name={AD_SOURCE_FIELDS.LIVE_EVENTS.name}
            {...VERTICAL_LAYOUT_FIX}
        >
            <LiveEventSelect />
        </Form.Item>
    );
};
