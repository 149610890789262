import { useContext } from "react";
import { useParams } from "react-router-dom";
import { Form } from "antd";
import { MarketplaceInfo, SeatAdSourcesType, useGetSeatAdSourcesTypeQuery } from "@app/core/services";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm";
import {
    AD_SOURCE_FIELDS,
    AdSourceTypeIds,
    DefaultDemandAcquisitionCostModel,
} from "@app/features/seatAdSources/constants";
import { PRICE_MODEL_OPTIONS } from "@app/features/seatAdSources/SeatAdSourcesForm/DealFormDrawer/DealFormContent/DealFormSections/DealObjectSection/PricingSection/Fields";
import { format } from "@rubicon/utils";

const { SHARED } = format.constants;

interface UseAdSourceTypeSelect {
    types: SeatAdSourcesType[];
    isLoading: boolean;
    isReadonly: boolean;
    onChange: (adSourceType: { id: number }) => void;
}

export const useAdSourceTypeSelect = (): UseAdSourceTypeSelect => {
    const { seatId } = useParams<{ seatId: string }>();
    const { isLoading, data: types } = useGetSeatAdSourcesTypeQuery(Number(seatId));
    const { adSourceFormMode } = useContext(AdSourceFormsContext).forms;
    const hasAttachedDeals = Boolean(Form.useWatch<MarketplaceInfo[]>(AD_SOURCE_FIELDS.DEMAND.name)?.length);
    const isReadonly = adSourceFormMode !== "create" || hasAttachedDeals;
    const adSourceForm = Form.useFormInstance();
    const adSourceType = Form.useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);

    const isEndDateRequired =
        adSourceType &&
        adSourceType.id !== AdSourceTypeIds.PROGRAMMATIC_GUARANTEED &&
        adSourceType.id !== AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED &&
        adSourceType.id !== AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED &&
        adSourceType.id !== AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED;

    const onChange = ({ id: adSourceTypeId }) => {
        //reset transparency section to initial values
        if (
            adSourceTypeId === AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED ||
            adSourceTypeId === AdSourceTypeIds.MARKETPLACE ||
            adSourceTypeId === AdSourceTypeIds.LINEAR_UNRESERVED_FIXED_RATE ||
            adSourceTypeId === AdSourceTypeIds.LINEAR_AUCTION_PRICE
        ) {
            adSourceForm.setFieldsValue({
                [AD_SOURCE_FIELDS.SUPPLY_NAME.name]: true,
                [AD_SOURCE_FIELDS.DOMAIN_NAME.name]: true,
                [AD_SOURCE_FIELDS.DOMAIN_NAME_OVERRIDE.name]: null,
                [AD_SOURCE_FIELDS.SUPPLY_PAGE.name]: true,
                [AD_SOURCE_FIELDS.REFER_URL.name]: true,
                [AD_SOURCE_FIELDS.FULL_IP_ADDRESS.name]: true,
                [AD_SOURCE_FIELDS.BUNDLE_ID.name]: true,
                [AD_SOURCE_FIELDS.BUNDLE_ID_OVERRIDE.name]: null,
                [AD_SOURCE_FIELDS.STORE_URL.name]: true,
                [AD_SOURCE_FIELDS.USER_ID.name]: true,
                [AD_SOURCE_FIELDS.CONTENT.name]: { name: SHARED, value: true },
                [AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.name]: true,
                [AD_SOURCE_FIELDS.EXTENDED_USER_ID.name]: [],
                [AD_SOURCE_FIELDS.OZTAM_CO_VIEWING.name]: false,
            });
        }

        if (adSourceTypeId === AdSourceTypeIds.FIXED_PRICE) {
            adSourceForm.setFieldValue(
                AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST_MODEL.name,
                DefaultDemandAcquisitionCostModel
            );
        }

        const isFirstSelection = !adSourceType;
        if (isFirstSelection) {
            return;
        }

        if (!isEndDateRequired) {
            adSourceForm.validateFields([AD_SOURCE_FIELDS.END_DATE.name]);
        }

        if (adSourceTypeId !== AdSourceTypeIds.FIXED_PRICE) {
            adSourceForm.setFieldsValue({
                [AD_SOURCE_FIELDS.PRICE_MODEL.name]: PRICE_MODEL_OPTIONS.FIXED_PRICE,
                [AD_SOURCE_FIELDS.FIXED_CPM.name]: null,
            });
        }

        if (adSourceTypeId !== AdSourceTypeIds.AUCTION_PRICE) {
            adSourceForm.setFieldsValue({
                [AD_SOURCE_FIELDS.FLOOR_TYPE.name]: null,
                [AD_SOURCE_FIELDS.OVERRIDE_FLOOR_CPM.name]: null,
                [AD_SOURCE_FIELDS.AUCTION_TYPE.name]: null,
                [AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.name]: null,
            });
        }

        if (adSourceTypeId !== AdSourceTypeIds.MARKETPLACE) {
            adSourceForm.setFieldsValue({
                [AD_SOURCE_FIELDS.ASSOCIATED_MARKETPLACE.name]: null,
                [AD_SOURCE_FIELDS.ASSOCIATED_GLOBAL_IDG.name]: null,
                [AD_SOURCE_FIELDS.ASSOCIATED_GLOBAL_IDG.selectName]: null,
            });
        }

        if (adSourceTypeId !== AdSourceTypeIds.CURATOR_MARKETPLACE) {
            adSourceForm.setFieldsValue({
                [AD_SOURCE_FIELDS.ASSOCIATED_CURATOR_MARKETPLACES.name]: [],
            });
        }
    };

    return {
        types: types || [],
        isLoading,
        isReadonly,
        onChange,
    };
};
