import { useEffect, useState } from "react";
import { FORM_FIELDS } from "./constants";
import { useDispatch } from "react-redux";
import { LabeledValue } from "antd/lib/select";
import { Store } from "rc-field-form/lib/interface";
import { FormInstance, notification } from "antd";
import { Seat, UpdateSeatPayload } from "@app/core/services/console";
import { useUpdateSeatInContext, useUserAccess } from "@app/core/auth";
import { DEFAULT_FALLBACK_MESSAGE } from "@app/features/inventory/constants";
import { useSeatTree } from "@app/features/inventory/InventoryBrandSafety/SeatTree/useSeatTree";
import { setEditActiveKey, setIsActionsDisabled } from "@app/features/inventory/InventoryBrandSafety/reducer";

export interface SeatDetailsFormValues {
    [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: string;
    [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAIN_LISTS.name]: LabeledValue[];
    [FORM_FIELDS.BLOCKED_BUYER_SEAT_LIST.name]: LabeledValue | null;
}

interface UseSeatBrandSafetyEditForm {
    isUpdating: boolean;
    isDeleting: boolean;
    handleSubmit: () => void;
    handleDelete: () => void;
    resetFormsFields: () => void;
    initialValues: Store | undefined;
    seatForm: FormInstance<SeatDetailsFormValues>;
}

export const getInitialValuesFromSeat = (seat: Seat | null): Store | undefined => {
    if (!seat) return undefined;

    return {
        [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAIN_LISTS.name]: seat.seatAdvertiserDomainFilterListDefs.map((list) => ({
            value: list.id,
            label: list.name,
        })),
        [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: seat.advertiserBlockDomains.join("\n"),
        [FORM_FIELDS.BLOCKED_BUYER_SEAT_LIST.name]: seat.buyerSeatList
            ? { value: seat.buyerSeatList.id, label: seat.buyerSeatList.name }
            : null,
    };
};

export const useSeatBrandSafetyEditForm = (seat: Seat): UseSeatBrandSafetyEditForm => {
    const dispatch = useDispatch();
    const { seatForm, resetFormsFields } = useSeatTree();
    const initialValues = getInitialValuesFromSeat(seat);
    const { updateSeatSelf } = useUpdateSeatInContext();
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const isInternalOnly: boolean = isSysAdmin || isPubAcctMgr;

    const handleSubmit = async (): Promise<void> => {
        dispatch(setIsActionsDisabled(true));
        setIsUpdating(true);
        const { blockedAdvertiserDomainLists, blockedAdvertiserDomains, blockedBuyerSeatList } =
            seatForm.getFieldsValue([
                FORM_FIELDS.BLOCKED_ADVERTISER_DOMAIN_LISTS.name,
                FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name,
                FORM_FIELDS.BLOCKED_BUYER_SEAT_LIST.name,
            ]);

        const externalPayload: UpdateSeatPayload = {
            id: seat.id,
            audienceLockAllowedDomain:
                seat.audienceLockAllowedDomain && !seat.audienceLockAllowedDomain.length
                    ? null
                    : seat.audienceLockAllowedDomain,
            advertiserBlockDomains: blockedAdvertiserDomains.length ? blockedAdvertiserDomains?.split("\n") : null,
            buyerSeatList: blockedBuyerSeatList ? { id: blockedBuyerSeatList.value as number } : null,
            seatAdvertiserDomainFilterListDefs: blockedAdvertiserDomainLists.length
                ? blockedAdvertiserDomainLists.map(({ value }) => ({ id: value as number }))
                : null,
        };
        const internalOnlyPayload: UpdateSeatPayload = { ...(seat as UpdateSeatPayload), ...externalPayload };
        const payload: UpdateSeatPayload = isInternalOnly ? internalOnlyPayload : externalPayload;

        try {
            await updateSeatSelf(payload);
            dispatch(setEditActiveKey(null));
            notification.success({ message: `Seat updated successfully` });
        } catch (error) {
            notification.error({ message: error.data.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        } finally {
            resetFormsFields();
            setIsUpdating(false);
            dispatch(setIsActionsDisabled(false));
        }
    };

    const handleDelete = async (): Promise<void> => {
        dispatch(setIsActionsDisabled(true));
        setIsDeleting(true);
        const externalPayload: UpdateSeatPayload = {
            id: seat.id,
            audienceLockAllowedDomain:
                seat.audienceLockAllowedDomain && !seat.audienceLockAllowedDomain.length
                    ? null
                    : seat.audienceLockAllowedDomain,
            advertiserBlockDomains: null,
            buyerSeatList: null,
            seatAdvertiserDomainFilterListDefs: null,
        };
        const internalOnlyPayload: UpdateSeatPayload = { ...(seat as UpdateSeatPayload), ...externalPayload };
        const payload: UpdateSeatPayload = isInternalOnly ? internalOnlyPayload : externalPayload;

        try {
            await updateSeatSelf(payload);
            notification.success({ message: "Seat updated successfully" });
        } catch (error) {
            notification.error({ message: error.data.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        } finally {
            dispatch(setIsActionsDisabled(false));
            setIsDeleting(false);
        }
    };

    useEffect(() => {
        if (initialValues && !isUpdating) {
            seatForm.setFieldsValue(initialValues);
        }
    }, [initialValues, isUpdating, seatForm]);

    return {
        seatForm,
        isUpdating,
        isDeleting,
        handleSubmit,
        handleDelete,
        resetFormsFields,
        initialValues,
    };
};
