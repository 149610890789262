import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_NO_COMMA } from "@app/core/components/constants";
import { formatNumber } from "@rubicon/utils";
import { Moment } from "moment-timezone";
import { DurationValue } from "./InventoryLiveEventsForm";

export const formatStartDate = (date: Moment) => {
    return date.format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_NO_COMMA);
};

export const formatDuration = (duration: DurationValue) => {
    return `${duration.hours} hrs ${duration.minutes} mins`;
};

export const formatEstImpOpportunities = (value: number) => {
    return formatNumber.asWhole(value);
};

export const formatEstAvgConcurrentViewers = (value: number) => {
    return formatNumber.asWhole(value);
};

export const formatTimeZone = (value: number, timezones) => {
    const timeZone = timezones?.find((timezone) => timezone.id === value);
    return timeZone ? timeZone.name : "- -";
};

export const formatEstPeakConcurrentViewers = (value: number) => {
    return formatNumber.asWhole(value);
};

export const formatEstAdBreakCount = (value: number) => {
    return formatNumber.asWhole(value);
};

export const formatEstTotalAvailAdMins = (value: number) => {
    return value ? `${value} mins` : "- -";
};

export const calculatedEndDate = (startDate: Moment, duration: DurationValue) => {
    const endDate = startDate?.clone().add({ hours: Number(duration.hours), minutes: Number(duration.minutes) });
    return endDate;
};
