import { FC } from "react";
import { Form, Input } from "antd";
import { FORM_ITEMS } from "../../../constants";
import { useLiveEventsFormItemName } from "./useLiveEventsFormItemName";

export const LiveEventsFormItemName: FC = () => {
    const { rules } = useLiveEventsFormItemName();

    return (
        <Form.Item required name={FORM_ITEMS.NAME.name} label={FORM_ITEMS.NAME.label} rules={rules}>
            <Input placeholder="Enter Name..." />
        </Form.Item>
    );
};
