import { PageHeader } from "@ant-design/pro-layout";
import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Affix, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";

export const PRICE_OVERRIDE_HEADER_SDET = "price-overrides-header";

export const PriceOverridesHeader = () => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { canEditSeat } = useUserAccess();

    return (
        <Affix>
            <PageHeader
                ghost={false}
                title="Ad Response: Price Overrides"
                data-sdet={PRICE_OVERRIDE_HEADER_SDET}
                extra={
                    canEditSeat && (
                        <Button
                            data-sdet="add-price-override-config-button"
                            type="primary"
                            onClick={() =>
                                history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_PRICE_OVERRIDE_CREATE_PAGE(seatId))
                            }
                        >
                            Add Price Override Config
                        </Button>
                    )
                }
            />
        </Affix>
    );
};

export default PriceOverridesHeader;
