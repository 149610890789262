import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";

export const isExtendTimeoutValueVisible = (adSourceTypeId: number) => {
    switch (adSourceTypeId) {
        case AdSourceTypeIds.PROGRAMMATIC_GUARANTEED:
        case AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED:
        case AdSourceTypeIds.FIXED_PRICE:
        case AdSourceTypeIds.AUCTION_PRICE:
        case AdSourceTypeIds.OPEN_AUCTION:
        case AdSourceTypeIds.LINEAR_AUCTION_PRICE:
        case AdSourceTypeIds.LINEAR_UNRESERVED_FIXED_RATE:
        case AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED:
        case AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE:
        case AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED:
            return true;
        default:
            return false;
    }
};
