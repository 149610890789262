import { useGetFutureLiveEventsQuery } from "@app/core/services/console/liveEvents";
import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useLoadOnScroll } from "@app/core/components";
import { DEFAULT_PAGE_SIZE } from "@app/core/components/constants";

export const useLiveEventsSelect = () => {
    const { seatId } = useParams<{ seatId: string }>();

    const [search, setSearch] = useState("");
    const [keyword, setKeyword] = useState("");
    const [pageByKeyword, setPageByKeyword] = useState({ "": 1 });
    const page = pageByKeyword[keyword] || 1;

    const { data, isFetching, originalArgs } = useGetFutureLiveEventsQuery({
        seatId: Number(seatId),
        page,
        max: DEFAULT_PAGE_SIZE,
        keyword,
    });

    const liveEvents = useMemo(() => data?.liveEvents, [data]);

    const handleLoadMore = useMemo(
        () => () => {
            setPageByKeyword((prev) => ({
                ...prev,
                [keyword]: (prev[keyword] || 1) + 1,
            }));
        },
        [keyword]
    );

    const { options, loadMore, hasMore } = useLoadOnScroll(
        liveEvents,
        isFetching,
        keyword,
        originalArgs?.page || 1,
        liveEvents?.length === DEFAULT_PAGE_SIZE,
        handleLoadMore
    );

    const debouncedHandleSearch = useMemo(
        () =>
            debounce((value: string) => {
                setKeyword(value);
            }, FILTER_INPUT_DEBOUNCE_TIME),
        []
    );

    const onSearch = (searchTerm: string) => {
        setSearch(searchTerm);
        debouncedHandleSearch(searchTerm);
    };

    return {
        isFetching,
        onSearch,
        search,
        hasMore,
        loadMore,
        options,
    };
};
