import { FC } from "react";
import { Seat } from "@app/core/services";
import { Publisher } from "@magnite/client-streaming-platform";
import { AffiliateCostValue } from "@app/features/inventory/components/FormItems";
import { REVSHARE_PERCENTAGE_COST_MODEL } from "@app/features/inventory/InventorySeat/constants";
import { usePublisherFormInstance } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormInstance";

const getExtra = (seat: Seat | null, publisher: Publisher | undefined): string | null => {
    if (publisher?.affiliateCostModel?.id === REVSHARE_PERCENTAGE_COST_MODEL.value) {
        return `Inherited value: ${publisher.affiliateCostValuePercent ?? seat?.affiliateCostValuePercent} %`;
    }
    const affiliateCostValueFixed: number | null | undefined =
        publisher?.affiliateCostValueFixed ?? seat?.affiliateCostValueFixed;

    if (typeof affiliateCostValueFixed === "number") return `Inherited value: ${affiliateCostValueFixed / 1000} CPM`;

    return null;
};
export const CostValue: FC = () => {
    const { seat, publisher } = usePublisherFormInstance();
    const extra: string | null = getExtra(seat, publisher);
    return <AffiliateCostValue extra={extra} wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }} />;
};
