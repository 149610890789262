import {
    createConnection,
    deleteConnection,
    EVENT_NAMES,
    useCommunicatorSession,
} from "@rubicon/iframe-communicator/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useIframeCommunicator = () => {
    const session = useCommunicatorSession();
    const location = useLocation();

    useEffect(() => {
        session?.emitEvent(EVENT_NAMES.HASH_CHANGE, { hash: window.location.hash });
    }, [location, session]);

    useEffect(() => {
        if (!window.document.referrer) {
            return;
        }

        const referrer = new URL(window.document.referrer);
        const session = createConnection(referrer.origin);

        if (session instanceof Error) {
            console.error("iframe-communicator error", session);
            return;
        }

        return () => {
            deleteConnection();
        };
    }, []);
};
