import { FC } from "react";
import { Select, Space } from "antd";
import { FilterFilled, GlobalOutlined } from "@ant-design/icons";
import { Label } from "@magnite/client-streaming-platform";

const { Option } = Select;

interface LabelsSelectProps {
    loading: boolean;
    value: number | undefined;
    labels: Label[] | undefined;
    onChange: (value: number | undefined) => void;
}

export const LabelsSelect: FC<LabelsSelectProps> = ({ onChange, value, labels, loading }) => {
    return (
        <Select
            allowClear
            showSearch
            value={value}
            loading={loading}
            onChange={onChange}
            optionFilterProp="label"
            style={{ width: "100%" }}
            autoClearSearchValue={false}
            placeholder="Select Label..."
        >
            {(labels || []).map((label) => {
                if (label.enablePrefilter && !label.isPublic) {
                    return (
                        <Option key={label.id} value={label.id} label={label.key}>
                            <Space size="small">
                                <GlobalOutlined />
                                <FilterFilled />
                                {label.key}
                            </Space>
                        </Option>
                    );
                }
                if (label.enablePrefilter) {
                    return (
                        <Option key={label.id} value={label.id} label={label.key}>
                            <Space size="small">
                                <FilterFilled />
                                {label.key}
                            </Space>
                        </Option>
                    );
                }
                if (!label.isPublic) {
                    return (
                        <Option key={label.id} value={label.id} label={label.key}>
                            <Space size="small">
                                <GlobalOutlined />
                                {label.key}
                            </Space>
                        </Option>
                    );
                }
                return (
                    <Option key={label.id} value={label.id} label={label.key}>
                        {label.key}
                    </Option>
                );
            })}
        </Select>
    );
};
