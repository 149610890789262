import { Card } from "antd";
import { FC } from "react";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { BrandChannelLabels } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormSections/AdvancedFeaturesSection/Sections/LabelsSection/Fields/BrandChannelLabels";

export const LabelsInternalOnly: FC = () => (
    <Card title="Internal Only" type="inner" styles={{ body: INVENTORY_CARD_BODY_STYLE }}>
        <BrandChannelLabels labelType="internal" {...CHANNEL_FORM_FIELDS.INTERNAL_LABELS} />
    </Card>
);
