import { FC } from "react";
import { css } from "@emotion/css";
import { Typography, Button, Col, Row, Table, Flex } from "antd";
import {
    EnrichmentCost,
    calcFinalCpmWithEcAndDac,
    displayEnrichmentCost,
    formatFinalCpmWithEcAndDac,
    formatCpm,
    getRateColumnTitle,
    formatDac,
    formatEcCurrency,
} from "./utils";
import { format } from "@rubicon/utils";
import { DealLevelValue } from "./useAdSourceEnrichmentCost";
import { AdSourceFloorType } from "@app/core/services";
import { AdSourcesForm } from "../..";
import { DEFAULT_ENRICHMENT_COST_CURRENCY_CODE } from "@app/features/seatAdSources/constants";

const tableStyles = css`
    .ant-table tfoot > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
        padding: 8px 8px;
    }
    .ant-table-tbody > tr > td {
        padding: 0px 8px;
    }
`;

interface EcTableItem {
    adSourceEc: string;
    cpm: string;
    currencyCode: string;
    dealEc: string;
    dealName: string;
    demandAcquisitionCost: string;
    demandAcquisitionCostValue: number | null;
    finalCpm: string;
    finalEc: string;
    finalEcValue: EnrichmentCost;
    finalEcPlusDac: EnrichmentCost;
    key: string;
    finalCpmWithEcAndDac: EnrichmentCost;
}

interface Props {
    adSourceCpm: number | null;
    adSourceType: AdSourcesForm["type"];
    adSourceFloorType: Partial<AdSourceFloorType> | undefined;
    adSourceDemandAcquisitionCost: number | null;
    adSourceDemandAcquisitionCostModelId: number | null | undefined;
    adSourceEc: EnrichmentCost;
    dealLevelValues: DealLevelValue[];
    isDemandAcquisitionCostEnabled: boolean;
    setSelectedDeal?: (deal: string) => void;
    shouldUseAdSourceValues: boolean;
}

const noWrapSpan = (value) => <span style={{ whiteSpace: "nowrap" }}>{value}</span>;

const displayInheritedIndicator = (value: string | number, isInherited: boolean): string =>
    isInherited ? `${value} *` : String(value);

export const EnrichmentCostTable: FC<Props> = ({
    adSourceType,
    adSourceCpm,
    adSourceFloorType,
    adSourceDemandAcquisitionCost,
    adSourceDemandAcquisitionCostModelId = null,
    adSourceEc,
    dealLevelValues,
    isDemandAcquisitionCostEnabled,
    setSelectedDeal,
    shouldUseAdSourceValues,
}) => {
    const ecTableItems: EcTableItem[] = dealLevelValues?.length
        ? dealLevelValues.map((dealLevelValue) => {
              return {
                  key: String(dealLevelValue.dealId || dealLevelValue.dealName),
                  adSourceEc: formatEcCurrency(dealLevelValue.adSourceEc, dealLevelValue.currencyCode),
                  cpm: formatCpm(
                      dealLevelValue.cpm,
                      dealLevelValue.currencyCode,
                      adSourceCpm,
                      adSourceType.id,
                      adSourceFloorType?.id
                  ),
                  currencyCode: dealLevelValue.currencyCode,
                  dealEc: formatEcCurrency(dealLevelValue.dealEc, dealLevelValue.currencyCode),
                  dealName: dealLevelValue.dealName,
                  demandAcquisitionCost: formatDac(
                      dealLevelValue.adSourceDemandAcquisitionCost,
                      adSourceDemandAcquisitionCostModelId,
                      dealLevelValue.currencyCode
                  ),
                  demandAcquisitionCostValue: dealLevelValue.adSourceDemandAcquisitionCost,
                  finalCpm: displayInheritedIndicator(
                      displayEnrichmentCost(dealLevelValue.finalCpm, dealLevelValue.currencyCode),
                      shouldUseAdSourceValues
                  ),

                  finalEc: formatEcCurrency(dealLevelValue.finalEc, dealLevelValue.currencyCode),
                  finalEcPlusDac: dealLevelValue.finalEcPlusDac,
                  finalEcValue: dealLevelValue.finalEc,
                  isInheritedFromAdSource: shouldUseAdSourceValues,
                  finalCpmWithEcAndDac: calcFinalCpmWithEcAndDac(
                      dealLevelValue.cpm,
                      dealLevelValue.finalEc,
                      dealLevelValue.adSourceDemandAcquisitionCost,
                      adSourceDemandAcquisitionCostModelId
                  ),
              };
          })
        : [
              {
                  key: "no_deals",
                  adSourceEc: displayEnrichmentCost(
                      adSourceEc,
                      DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
                      adSourceDemandAcquisitionCostModelId
                  ),
                  cpm: format.constants.DEFAULT_DASHES,
                  currencyCode: DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
                  dealEc: format.constants.DEFAULT_DASHES,
                  dealName: "No Deals Selected",
                  demandAcquisitionCost: displayEnrichmentCost(
                      adSourceDemandAcquisitionCost,
                      DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
                      adSourceDemandAcquisitionCostModelId
                  ),
                  demandAcquisitionCostValue: 0,
                  finalCpm: format.constants.DEFAULT_DASHES,
                  finalEcPlusDac: 0,
                  finalEc: format.constants.DEFAULT_DASHES,
                  finalEcValue: 0,
                  finalCpmWithEcAndDac: null,
              },
          ];
    return (
        <Flex vertical>
            <Table
                bordered
                columns={[
                    { title: "Deal", key: "dealName", dataIndex: "dealName", width: "45%" },
                    {
                        title: getRateColumnTitle(adSourceType),
                        key: "cpm",
                        dataIndex: "",
                        render: (record: EcTableItem) => {
                            return noWrapSpan(record.cpm);
                        },
                    },
                    {
                        title: "Ad Source EC",
                        key: "adSourceEc",
                        dataIndex: "adSourceEc",
                        render: noWrapSpan,
                    },
                    {
                        title: "Deal EC",
                        key: "dealEc",
                        dataIndex: "dealEc",
                        render: noWrapSpan,
                    },
                    {
                        title: "Final EC",
                        key: "finalEc",
                        dataIndex: "finalEc",
                        render: noWrapSpan,
                    },
                    {
                        title: "DAC",
                        key: "demandAcquisitionCost",
                        dataIndex: "demandAcquisitionCost",
                        render: noWrapSpan,
                    },
                    {
                        title: `Final ${getRateColumnTitle(adSourceType)} with EC${
                            isDemandAcquisitionCostEnabled ? " & DAC" : ""
                        }`,
                        key: "finalCpm",
                        dataIndex: "",
                        render: (record: EcTableItem) => {
                            const formattedFinalCpmWithEcAndDac = formatFinalCpmWithEcAndDac(
                                record.finalCpmWithEcAndDac,
                                record.currencyCode,
                                adSourceType.id,
                                adSourceFloorType?.id
                            );

                            if (setSelectedDeal) {
                                return (
                                    <Row wrap={false} align="middle" justify="space-between">
                                        <Col style={{ whiteSpace: "nowrap" }}>{formattedFinalCpmWithEcAndDac}</Col>
                                        <Col>
                                            <Button type="link" onClick={() => setSelectedDeal(record.key)}>
                                                View Details
                                            </Button>
                                        </Col>
                                    </Row>
                                );
                            }

                            return noWrapSpan(formattedFinalCpmWithEcAndDac);
                        },
                    },
                ].filter((col) => {
                    return isDemandAcquisitionCostEnabled || col.key !== "demandAcquisitionCost";
                })}
                dataSource={ecTableItems}
                pagination={false}
                className={tableStyles}
            />
            {shouldUseAdSourceValues && (
                <Row style={{ marginBottom: "0.5rem" }}>
                    <Col span={24}>
                        <Typography.Text type="secondary">* Inherited from Ad Source</Typography.Text>
                    </Col>
                </Row>
            )}
        </Flex>
    );
};
