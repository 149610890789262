import { AdServerLadle } from "@magnite/client-streaming-platform";
import { AdSource, useGetNetworkEndpointsAwsRegionsQuery, useGetSeatAvailableNetworksQuery } from "@app/core/services";
import { useUserAccess } from "@app/core/auth";
import { useSearchQuery } from "@magnite/client-streaming-platform";
import { Form, FormInstance, notification } from "antd";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { LabeledValue } from "antd/lib/select";
import {
    useActivateAdServerLadlesForSeatAdSourceMutation,
    useActivateAdServerLadleMutation,
    useCreateAdServerLadleMutation,
    AdServerLadleResponse,
} from "@magnite/client-streaming-platform";
import { useSeatAdSourcesDetailsDrawerContent } from "../../../../useSeatAdSourcesDetailsDrawerContent";

enum LADLE_FORM_FIELDS {
    Region = "awsRegionCode",
    LadleName = "ladleName",
    SupplyDomain = "sourceDomain",
    BundleId = "bundleId",
    LineItem = "lineItem",
    Order = "order",
    AdvertiserDomain = "adomain",
    CreativeId = "creativeId",
    Creative = "creative",
    CreativeUnfilled = "creativeUnfilled",
    Demand = "networkCode",
    NurlDomain = "winNurlDomain",
}

const initialFormData = {
    [LADLE_FORM_FIELDS.Region]: null,
    [LADLE_FORM_FIELDS.LadleName]: null,
    [LADLE_FORM_FIELDS.SupplyDomain]: null,
    [LADLE_FORM_FIELDS.BundleId]: null,
    [LADLE_FORM_FIELDS.LineItem]: null,
    [LADLE_FORM_FIELDS.Order]: null,
    [LADLE_FORM_FIELDS.AdvertiserDomain]: null,
    [LADLE_FORM_FIELDS.CreativeId]: null,
    [LADLE_FORM_FIELDS.Creative]: null,
    [LADLE_FORM_FIELDS.Demand]: null,
    [LADLE_FORM_FIELDS.NurlDomain]: null,
};

export type LadleFormDataType = typeof initialFormData;

export type LadleFormFieldsValueType = {
    [key in LADLE_FORM_FIELDS]: string | number | boolean;
};

interface UseSeatAdSourcesDetailsLadleForm {
    initialFormData: LadleFormDataType;
    awsRegionsOptions: LabeledValue[];
    defaultRegion: LabeledValue;
    form: FormInstance;
    handleChangeLineItemSearch: (value: string) => void;
    handleChangeOrderSearch: (value: string) => void;
    handleFormChange: (values: LadleFormFieldsValueType) => void;
    handleActivate: () => void;
    handleSaveAndActivate: () => void;
    isFetchingAwsRegions: boolean;
    isFetchingLineItems: boolean;
    isFetchingNetworks: boolean;
    isFetchingOrders: boolean;
    LADLE_FORM_FIELDS: typeof LADLE_FORM_FIELDS;
    lineItemsOptions: LabeledValue[];
    lineItemsSearch: string;
    networksOptions: LabeledValue[];
    onClose: () => void;
    ordersOptions: LabeledValue[];
    ordersSearch: string;
    visible: boolean;
    isSubmitting: boolean;
    hasAdminAccess: boolean;
    formData: LadleFormDataType;
}

export const useSeatAdSourcesDetailsLadleForm = (adSource: AdSource): UseSeatAdSourcesDetailsLadleForm => {
    const [form] = Form.useForm();
    const [formData, setFormData] = useState<LadleFormDataType>(initialFormData);
    const [visible, setVisible] = useState<boolean>(true);
    const [lineItemsSearch, setLineItemsSearch] = useState("");
    const [lineItemKeyword, setLineItemKeyword] = useState("");
    const [ordersSearch, setOrdersSearch] = useState("");
    const [orderKeyword, setOrderKeyword] = useState("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { seatId } = useParams<{ seatId: string }>();
    const { switchToDefaultDetailsView } = useSeatAdSourcesDetailsDrawerContent();
    const [activateAdServerLadleForSeatAdSource] = useActivateAdServerLadlesForSeatAdSourceMutation();
    const [activateAdServerLadle] = useActivateAdServerLadleMutation();
    const [createAdServerLadle] = useCreateAdServerLadleMutation();
    const { hasAdminAccess } = useUserAccess();
    const { data: networks, isFetching: isFetchingNetworks } = useGetSeatAvailableNetworksQuery(Number(seatId));
    const { data: awsRegions, isFetching: isFetchingAwsRegions } = useGetNetworkEndpointsAwsRegionsQuery();
    const { data: lineItems, isFetching: isFetchingLineItems } = useSearchQuery({
        type: "lineitem",
        keyword: lineItemKeyword,
        page: 1,
        max: 100,
    });
    const { data: orders, isFetching: isFetchingOrders } = useSearchQuery({
        type: "order",
        keyword: orderKeyword,
        page: 1,
        max: 50,
    });
    const debouncedSetLineItemKeyword = useMemo(
        () => debounce((value: string) => setLineItemKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setLineItemKeyword]
    );
    const handleChangeLineItemSearch = (value: string) => {
        setLineItemsSearch(value);
        debouncedSetLineItemKeyword(value);
    };
    const debouncedSetOrderKeyword = useMemo(
        () => debounce((value: string) => setOrderKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setOrderKeyword]
    );
    const handleChangeOrderSearch = (value: string) => {
        setOrdersSearch(value);
        debouncedSetOrderKeyword(value);
    };
    const handleFormChange = (values: LadleFormFieldsValueType): void => {
        for (const [key, value] of Object.entries(values)) {
            setFormData((prevFormData) => ({ ...prevFormData, [key]: value }));
        }
    };
    const onClose = () => {
        switchToDefaultDetailsView();
    };
    const networksOptions = useMemo(
        () =>
            (networks || []).map((network) => ({
                value: network.code,
                label: network.name,
            })),
        [networks]
    );
    const defaultRegion: LabeledValue = {
        value: "all",
        label: "All Regions",
    };
    const awsRegionsOptions = [defaultRegion].concat(
        useMemo(
            () =>
                (awsRegions || [])
                    .filter((region) => region.active)
                    .map((region) => ({
                        value: region.code,
                        label: region.name,
                    })),
            [awsRegions]
        )
    );
    const lineItemsOptions = useMemo(
        () =>
            (lineItems || []).map((lineItem) => ({
                value: lineItem.id,
                label: lineItem.name,
            })),
        [lineItems]
    );
    const ordersOptions = useMemo(
        () =>
            (orders || []).map((order) => ({
                value: order.id,
                label: order.name,
            })),
        [orders]
    );
    const generatePayload = () => {
        const payload: AdServerLadle = {
            adSourceId: adSource.id,
            hasCreative: formData[LADLE_FORM_FIELDS.Creative],
        };

        if (formData[LADLE_FORM_FIELDS.AdvertiserDomain]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            payload.adomain = formData[LADLE_FORM_FIELDS.AdvertiserDomain];
        }
        if (formData[LADLE_FORM_FIELDS.Region]) {
            if (formData[LADLE_FORM_FIELDS.Region] == "all") {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                payload.awsRegionCode = null;
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                payload.awsRegionCode = formData[LADLE_FORM_FIELDS.Region];
            }
        }
        if (formData[LADLE_FORM_FIELDS.CreativeId]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            payload.creativeId = formData[LADLE_FORM_FIELDS.CreativeId];
        }
        if (formData[LADLE_FORM_FIELDS.LadleName]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            payload.ladleName = formData[LADLE_FORM_FIELDS.LadleName];
        }
        if (formData[LADLE_FORM_FIELDS.Demand]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            payload.networkCode = formData[LADLE_FORM_FIELDS.Demand];
        }
        if (formData[LADLE_FORM_FIELDS.SupplyDomain]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            payload.sourceDomain = formData[LADLE_FORM_FIELDS.SupplyDomain];
        }
        if (formData[LADLE_FORM_FIELDS.Creative] && formData[LADLE_FORM_FIELDS.NurlDomain]) {
            payload.winNurlDomain = formData[LADLE_FORM_FIELDS.NurlDomain];
        }
        if (formData[LADLE_FORM_FIELDS.BundleId]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            payload.bundleId = formData[LADLE_FORM_FIELDS.BundleId];
        }
        if (formData[LADLE_FORM_FIELDS.LineItem]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            payload.lineItemId = formData[LADLE_FORM_FIELDS.LineItem];
        }
        if (formData[LADLE_FORM_FIELDS.Order]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            payload.orderId = formData[LADLE_FORM_FIELDS.Order];
        }

        return payload;
    };
    const generateSuccessLink = () => {
        if (hasAdminAccess) {
            return `#/seats/${adSource.seat.id}/ladle-diagnostics`;
        }
        return `#/seats/${adSource.seat.id}/seat-diagnostics`;
    };
    const handleSubmit = async (saveAndActivate: boolean) => {
        setIsSubmitting(true);
        setFormData(initialFormData);

        const payload: AdServerLadle = generatePayload();

        try {
            let activatedAdServerLadle: AdServerLadleResponse;
            if (saveAndActivate) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const createdAdServerLadle = await createAdServerLadle({ id: null, body: payload }).unwrap();
                activatedAdServerLadle = await activateAdServerLadle({
                    id: Number(createdAdServerLadle.id),
                    body: {},
                }).unwrap();
            } else {
                activatedAdServerLadle = await activateAdServerLadleForSeatAdSource({
                    id: Number(adSource.id),
                    body: payload,
                }).unwrap();
            }

            notification.success({
                message: "Ladle Processing...",
                description: (
                    <>
                        <div>
                            {activatedAdServerLadle.ladleName} is currently processing. Once finished you can view the
                            results under
                            <a href={generateSuccessLink()}> Ladle Results</a>
                        </div>
                    </>
                ),
                duration: 10,
            });

            form.resetFields();
            setVisible(false);
        } catch (err) {
            notification.error({
                message: "Ladle Error",
                description: err.data.errorDescription,
                duration: 10,
            });
        } finally {
            setIsSubmitting(false);
            switchToDefaultDetailsView();
        }
    };
    const handleActivate = () => {
        handleSubmit(false);
    };
    const handleSaveAndActivate = () => {
        handleSubmit(true);
    };

    return {
        initialFormData,
        form,
        handleFormChange,
        LADLE_FORM_FIELDS,
        handleActivate,
        handleSaveAndActivate,
        handleChangeLineItemSearch,
        handleChangeOrderSearch,
        networksOptions,
        awsRegionsOptions,
        lineItemsOptions,
        lineItemsSearch,
        ordersOptions,
        ordersSearch,
        isFetchingNetworks,
        isFetchingAwsRegions,
        isFetchingLineItems,
        isFetchingOrders,
        onClose,
        visible,
        isSubmitting,
        formData,
        hasAdminAccess,
        defaultRegion,
    };
};
