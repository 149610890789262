import { AdSource } from "@app/core/services";
import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";

export const useIsFieldVisible = (adSource: AdSource) => {
    const adSourceTypeId = adSource.type.id;

    switch (adSourceTypeId) {
        case AdSourceTypeIds.PROGRAMMATIC_GUARANTEED:
        case AdSourceTypeIds.FIXED_PRICE:
        case AdSourceTypeIds.MARKETPLACE:
        case AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED:
        case AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED:
        case AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED:
        case AdSourceTypeIds.AUCTION_PRICE:
        case AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE:
            true;
        default:
            return true;
    }
};
