import { AdSource } from "@app/core/services";
import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";

export const useIsFieldVisible = (adSource: AdSource) => {
    const adSourceTypeId = adSource.type.id;

    switch (adSourceTypeId) {
        case AdSourceTypeIds.PROGRAMMATIC_GUARANTEED:
        case AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED:
        case AdSourceTypeIds.FIXED_PRICE:
        case AdSourceTypeIds.LINEAR_UNRESERVED_FIXED_RATE:
            return true;
        default:
            return false;
    }
};
