import { Button, Card, Col, Form, Row, Table, Typography, Collapse } from "antd";
import { ChangeEvent, FC, useMemo, useState } from "react";
import {
    AD_SOURCE_FIELDS,
    AD_SOURCE_SECTIONS_NAME,
    cardBodyStyle,
    GUTTER,
    mainStepSectionClassList,
    AD_SOURCE_SECTIONS,
    PricingModels,
    AdSourceTypeIds,
} from "../../../constants";
import { PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import {
    getDataSource,
    getTableColumns,
    isDemandSectionVisible,
    isDemandTableVisible,
    isExcludeDemandVisible,
} from "./utils";
import { SeatAdSourcesType } from "@app/core/services";
import { AdSourcesForm } from "../../SeatAdSourcesForm";
import { useMarketplaceInfoWidget } from "../../useMarketplaceInfoWidget";
import { useValidateAttachedDeals } from "./useValidateAttachedDeals";
import { ExcludeDemandMultiselect } from "./Fields";
import { DefinitionSidePanel } from "../../DefinitionSidePanel";
import { useTargetingForm } from "@app/features/targeting/useTargetingForm";
import { TargetingFormKeys } from "@app/features/targeting/constants";
import { TableSearchInput } from "@app/core/components";
import {
    useSearchDemandDealsTable,
    SortField,
    sortDeals,
    compareFunctionsByColumnKey,
} from "@app/features/seatAdSources/utils/useDemandDealsTable";
import { brandRuthlessEmpress } from "@rubicon/antd-components";
import { DEFAULT_PAGE_SIZE_DEAL_DEMAND_TABLE, PAGE_SIZE_OPTIONS } from "@app/core/components/constants";
import { DEAL_FORM_FIELDS } from "@app/features/seatAdSources/SeatAdSourcesForm/DealFormDrawer/DealFormContent/constants";

export const tableStyles = css`
    .ant-table tfoot > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
        padding: 8px 8px;
    }

    .ant-table-tbody > tr > td {
        padding: 0px 8px;
    }
`;

export const DemandSection: FC = () => {
    const dealsList = Form.useWatch<AdSourcesForm["marketplaceInfoList"]>(AD_SOURCE_FIELDS.DEMAND.name) || [];
    const [search, setSearch] = useState<string>("");
    const [sortField, setSortField] = useState<SortField>({ columnKey: DEAL_FORM_FIELDS.DEMAND.name, order: "ascend" });
    const [{ max, page }, setPagination] = useState<{ page: number; max: number }>({
        page: 1,
        max: DEFAULT_PAGE_SIZE_DEAL_DEMAND_TABLE,
    });
    const { setCreateMode, deleteByIndex, setEditMode, setViewMode } = useMarketplaceInfoWidget();
    const { loadValidationTargeting } = useTargetingForm();

    const adSourceType = Form.useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const currency = Form.useWatch<AdSourcesForm["currencyType"]>(AD_SOURCE_FIELDS.CURRENCY.name);
    const rate = Form.useWatch<AdSourcesForm["bookingPrice"]>(AD_SOURCE_FIELDS.FIXED_CPM.name);
    const startDate = Form.useWatch<AdSourcesForm["bookingStartDate"]>(AD_SOURCE_FIELDS.START_DATE.name);
    const endDate = Form.useWatch<AdSourcesForm["bookingEndDate"]>(AD_SOURCE_FIELDS.END_DATE.name);
    const timeZone = Form.useWatch<AdSourcesForm["timeZone"]>(AD_SOURCE_FIELDS.TIME_ZONE.name);
    const priceModel = Form.useWatch<AdSourcesForm["priceModel"]>(AD_SOURCE_FIELDS.PRICE_MODEL.name);
    const adSourceFloorType = Form.useWatch<AdSourcesForm["adSourceFloorType"]>(AD_SOURCE_FIELDS.FLOOR_TYPE.name);
    const adSourceForm = Form.useFormInstance<AdSourcesForm>();

    const adSourceTypeId = adSourceType?.id;

    const dealsDataSource = getDataSource(dealsList, {
        adSourceFloorType,
        currency,
        endDate,
        rate,
        startDate,
        timeZone,
        type: adSourceType,
    });

    const columns = getTableColumns({
        adSourceTypeId,
        deleteByIndex,
        setEditMode,
        setViewMode,
    });

    const isSingleInstanceOnly =
        adSourceTypeId === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED ||
        adSourceTypeId === AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED ||
        (adSourceTypeId === AdSourceTypeIds.FIXED_PRICE && priceModel?.id === PricingModels.TIERED_PRICE);

    const handleDeleteAll = () => {
        adSourceForm.setFieldValue(AD_SOURCE_FIELDS.DEMAND.name, []);
        loadValidationTargeting(TargetingFormKeys.AdSource, []);
    };

    const { attachedDealsValidator, validationError } = useValidateAttachedDeals();
    const searchFilteredDeals = useSearchDemandDealsTable(dealsDataSource, search);
    const sortedDeals = useMemo(() => {
        return sortDeals(searchFilteredDeals, sortField, compareFunctionsByColumnKey);
    }, [searchFilteredDeals, sortField]);

    if (!isDemandSectionVisible(adSourceType?.id)) {
        return null;
    }

    const onSearchChange = (searchString: string) => {
        setSearch(searchString);
    };

    const onSortChange = (_a: unknown, _b: unknown, sortField: SortField) => {
        setSortField(sortField);
    };

    const onPaginationChange = (page: number, pMax: number) => {
        const updatedCurrentPage = pMax === max ? page : 1;
        setPagination({ max: pMax, page: updatedCurrentPage });
    };

    const totalPaginationProps = {
        current: page,
        pageSize: max,
        total: sortedDeals.length,
        onChange: onPaginationChange,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        showSizeChanger: true,
    };

    return (
        <Collapse
            bordered
            ghost={false}
            collapsible="header"
            className={mainStepSectionClassList}
            defaultActiveKey={["1"]}
            data-sdet={AD_SOURCE_SECTIONS.BRAND_SAFETY.toLowerCase().replace(/_/g, "-")}
            items={[
                {
                    key: "1",
                    forceRender: true,
                    id: "demandSection",
                    label: AD_SOURCE_SECTIONS_NAME.DEMAND,
                    extra: (
                        <DefinitionSidePanel
                            panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.DEMAND]}
                            section={AD_SOURCE_SECTIONS.DEMAND}
                            sectionSelector="#demandSection"
                            elementType={["th", "label"]}
                        />
                    ),
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            <Row gutter={GUTTER}>
                                {isExcludeDemandVisible(adSourceType?.id) && (
                                    <Col span={16}>
                                        <ExcludeDemandMultiselect />
                                    </Col>
                                )}
                                {isDemandTableVisible(adSourceType?.id) && (
                                    <Col span={24}>
                                        {validationError && (
                                            <Col span={24}>
                                                <Typography.Text type="danger">{validationError}</Typography.Text>
                                            </Col>
                                        )}
                                        <Form.Item
                                            rules={[
                                                {
                                                    validator: attachedDealsValidator,
                                                },
                                            ]}
                                            name={AD_SOURCE_FIELDS.DEMAND.name}
                                            noStyle
                                        >
                                            <div></div>
                                        </Form.Item>
                                        <Row gutter={[8, 8]}>
                                            {dealsList.length ? (
                                                <Col span={24}>
                                                    {!isSingleInstanceOnly && (
                                                        <Row justify="space-between" style={{ marginBottom: "16px" }}>
                                                            <Col>
                                                                <TableSearchInput
                                                                    entityType="Deal"
                                                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                        onSearchChange(e.target.value)
                                                                    }
                                                                    style={{ width: "300px" }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <Row>
                                                        <Col span={24}>
                                                            <Table
                                                                size="small"
                                                                columns={columns}
                                                                dataSource={sortedDeals}
                                                                bordered
                                                                scroll={{ x: 1300 }}
                                                                className={tableStyles}
                                                                onChange={onSortChange}
                                                                pagination={
                                                                    isSingleInstanceOnly ? false : totalPaginationProps
                                                                }
                                                            />
                                                        </Col>
                                                        <Col span={24}>
                                                            <Typography.Text type="secondary">
                                                                * Inherited from Ad Source
                                                            </Typography.Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ) : null}
                                            <Col span={24}>
                                                <Button
                                                    data-sdet="add-deal-button"
                                                    disabled={isSingleInstanceOnly && dealsList?.length >= 1}
                                                    onClick={() => setCreateMode("newDeal")}
                                                    type="dashed"
                                                    icon={<PlusOutlined />}
                                                    style={{ paddingLeft: 0 }}
                                                    className={css`
                                                        color: ${brandRuthlessEmpress};
                                                        border-color: ${brandRuthlessEmpress};
                                                        padding-left: 16px !important;
                                                        padding-right: 16px !important;
                                                    `}
                                                >
                                                    Add Deal
                                                </Button>
                                                {dealsList?.length ? (
                                                    <Button onClick={handleDeleteAll} type="link">
                                                        Remove All Deals
                                                    </Button>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                            </Row>
                        </Card>
                    ),
                },
            ]}
        />
    );
};
