import { useGetPublisherByIdQuery } from "@magnite/client-streaming-platform";
import { useParams } from "react-router-dom";

interface PublisherFloorsPageParams {
    publisherId: string;
}

export const usePublisherById = () => {
    const { publisherId } = useParams<PublisherFloorsPageParams>();
    const { data: publisher, isFetching } = useGetPublisherByIdQuery(Number(publisherId));

    return {
        publisher,
        isFetching,
    };
};
