import { useHistory, useLocation } from "react-router-dom";
import { getQueryParam } from "./utils";
import { useCallback } from "react";

type UseQueryParamStateReturnType = [string | undefined, (value: string) => void];

export const useQueryParamState = (param: string): UseQueryParamStateReturnType => {
    const { search, pathname } = useLocation();
    const history = useHistory();

    const value = getQueryParam(search, param);
    const searchWithoutParam = getSearchWithoutParam(search, param);

    // only rerender setValue if the value of the search string excluding the param has changed
    const setValue = useCallback(
        (value: string) => {
            const params = new URLSearchParams(searchWithoutParam);
            if (value && value.length > 0) {
                params.set(param, value);
            }
            history.replace({ pathname, search: params.toString() });
        },
        [searchWithoutParam, pathname, history, param]
    );

    return [value, setValue];
};

const getSearchWithoutParam = (search: string, param: string) => {
    const searchWithoutParam = new URLSearchParams(search);
    searchWithoutParam.delete(param);
    return searchWithoutParam.toString();
};
