import { ROUTE_FORMATTERS } from "@app/core/routing";
import { AdUnit, Brand, BrandChannel, BrandSupply } from "@app/core/services";
import { Publisher } from "@magnite/client-streaming-platform";
import {
    AdUnitSections,
    BrandSections,
    ChannelSections,
    PublisherSections,
    SupplySections,
} from "../../InventoryHealthDetailsPages";
import { useAppDispatch } from "@app/core/store";
import { ReactNode, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useUnitData } from "../../DetailsDrawer/DetailsDrawerContent/useUnitData";
import { closeDrawer, InventoryDetailsDrawerType, pickDrawerType, setPage } from "../../DetailsDrawer/reducer";
import { UnitUrlIds } from "../../DetailsDrawer/useInventoryDetailsDrawerUrlId";

interface UseInventoryHealthUnitPage {
    title: string;
    unitSections: ReactNode;
    isLoading: boolean;
    onBack: () => void;
}

export const useInventoryHealthUnitPage = (type: InventoryDetailsDrawerType): UseInventoryHealthUnitPage => {
    const { seatId, publisherId, brandId, supplyId, adUnitId } = useParams<UnitUrlIds>();

    const unitId = useMemo(
        () => Number(adUnitId || supplyId || brandId || publisherId),
        [adUnitId, supplyId, brandId, publisherId]
    );
    const { publisherData, channelData, brandData, supplyData, adUnitData, isLoading } = useUnitData(unitId, type);
    const dispatch = useAppDispatch();

    const history = useHistory();
    const onBack = (): void => {
        dispatch(setPage({ isPage: false, pageUrl: undefined }));
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH(seatId));
    };

    const closeUrl = useMemo(() => {
        return ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
            type,
            Number(seatId),
            Number(publisherId),
            Number(brandId),
            Number(supplyId),
            Number(adUnitId)
        );
    }, [type, seatId, publisherId, brandId, supplyId, adUnitId]);

    const { unitSections, title } = useMemo<{ title: string; unitSections: ReactNode }>(() => {
        let title = "";
        let unitSections: ReactNode;

        switch (type) {
            case InventoryDetailsDrawerType.PUBLISHER:
                unitSections = <PublisherSections publisher={publisherData as Publisher} />;
                title = `Publisher Details: ${publisherData?.name}`;
                break;
            case InventoryDetailsDrawerType.CHANNEL:
                unitSections = <ChannelSections channel={channelData as BrandChannel} />;
                title = `Channel Details: ${channelData?.name}`;
                break;
            case InventoryDetailsDrawerType.BRAND:
                unitSections = <BrandSections brand={brandData as Brand} />;
                title = `Brand Details: ${brandData?.name}`;
                break;
            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                unitSections = <ChannelSections channel={channelData as BrandChannel} />;
                title = `Channel Details: ${channelData?.name}`;
                break;
            case InventoryDetailsDrawerType.SUPPLY:
                unitSections = <SupplySections supply={supplyData as BrandSupply} />;
                title = `Supply Details: ${supplyData?.name}`;
                break;
            case InventoryDetailsDrawerType.AD_UNIT:
                unitSections = <AdUnitSections adUnit={adUnitData as AdUnit} />;
                title = `Ad Unit Details: ${adUnitData?.name}`;
                break;
        }

        return { title, unitSections };
    }, [type, publisherData, channelData, brandData, supplyData, adUnitData]);

    useEffect(() => {
        dispatch(setPage({ isPage: true, pageUrl: closeUrl }));
    }, [dispatch, closeUrl]);

    useEffect(() => {
        dispatch(pickDrawerType(type));
    }, [dispatch, type]);

    useEffect(() => {
        return () => {
            dispatch(setPage({ isPage: false, pageUrl: undefined }));
            dispatch(closeDrawer());
        };
    }, [dispatch]);

    return {
        unitSections,
        title,
        onBack,
        isLoading,
    };
};
