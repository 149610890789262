import { FC, useMemo } from "react";
import { useAdBidStats } from "../useAdBidStats";
import { Card } from "antd";
import { formatNumber } from "@rubicon/utils";
import styled from "@emotion/styled";
import { MetricsItem } from "@rubicon/antd-components";
import { StatsItem } from "../StatsItem";
import { Loading } from "@app/core/components";
import { useCurrencyConversion } from "@app/features/inventory/useCurrencyConversion";

interface Props {
    adSourceId: number;
}

const StyledCard = styled(Card)`
    .ant-card-body {
        padding: 0px 10px !important;
        height: 100%;
        transition: height 2s;
        transition-timing-function: ease-in-out;
    }
    .ant-card-head {
        font-size: 16px;
        background-color: #f0f2f5;
        min-height: auto;
        padding: 8px;
    }
    .ant-card-bordered {
        height: 100% !important;
    }
`;

const formatMoneyOrDefault = (value: number | undefined | null, code: string): string =>
    formatNumber.asMoney(typeof value === "number" ? value / Math.pow(10, 6) : undefined, code);

export const AdStatsCard: FC<Props> = ({ adSourceId }) => {
    const { adStatsTableData, isFetching } = useAdBidStats(adSourceId);
    const { preferredCurrency } = useCurrencyConversion();
    const currencyCode = preferredCurrency?.code;

    const adStatsTableDataItem = adStatsTableData?.[0];
    const data: MetricsItem[] = useMemo((): MetricsItem[] => {
        return adStatsTableDataItem
            ? [
                  {
                      title: "Requests Filled",
                      value: formatNumber.asWhole(adStatsTableDataItem?.requestsFilled),
                  },
                  {
                      title: "Impressions",
                      value: formatNumber.asWhole(adStatsTableDataItem?.impressions),
                  },
                  {
                      title: "aDomain Count",
                      value: formatNumber.asWhole(adStatsTableDataItem?.aDomainCount),
                  },
                  {
                      title: "Auction Count",
                      value: formatNumber.asWhole(adStatsTableDataItem?.auctionCount),
                  },
                  {
                      title: "Revenue",
                      value: formatMoneyOrDefault(adStatsTableDataItem?.revenue, currencyCode || "USD"),
                  },
                  {
                      title: "Cost",
                      value: formatMoneyOrDefault(adStatsTableDataItem?.cost, currencyCode || "USD"),
                  },
              ]
            : [];
    }, [adStatsTableDataItem, currencyCode]);

    if (!adStatsTableDataItem) {
        return <Loading position="relative" />;
    }

    return (
        <StyledCard title="Ad Stats" loading={isFetching}>
            {!isFetching && data.map((item) => <StatsItem key={`${item?.title}${item?.value}`} item={item} />)}
        </StyledCard>
    );
};
