import { EVENT_NAMES, useCommunicatorSession } from "@rubicon/iframe-communicator/react";

export const useIframeChangeApp = () => {
    const session = useCommunicatorSession();

    const handleChangeApp = (appRoute: string, data: unknown) => {
        session?.emitEvent(EVENT_NAMES.APP_CHANGE, { appRoute, data });
    };
    return { handleChangeApp };
};
