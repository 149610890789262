import { ROUTE_FORMATTERS } from "@app/core/routing";
import { LiveEvent, useDeleteLiveEventMutation, useGetLiveEventsQuery } from "@app/core/services/console/liveEvents";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { notification, TablePaginationConfig } from "antd";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    selectInventoryLiveEventsIsAscending,
    selectInventoryLiveEventsKeyword,
    selectInventoryLiveEventsMax,
    selectInventoryLiveEventsOrderBy,
    selectInventoryLiveEventsPage,
    setInventoryLiveEventsKeyword,
    setInventoryLiveEventsPagination,
    setInventoryLiveEventsSort,
} from "../reducer";
import debounce from "lodash.debounce";
import { DEFAULT_PAGE_SIZE, FILTER_INPUT_DEBOUNCE_TIME, PAGE_SIZE_OPTIONS } from "@app/core/components/constants";

// Note: these are the names used and recognised by the API as values for the "orderBy" param when retrieving live events
export const COLUMN_KEY = {
    EVENT_NAME: "name",
    START_DATE: "startDate",
    DURATION: "duration",
    EST_IMP_OPPORTUNITIES: "estimatedImpressionOpportunities",
    EST_AVG_CONCURRENT_VIEWERS: "estimatedAverageConcurrentUsers",
    EST_PEAK_CONCURRENT_VIEWERS: "estimatedPeakConcurrentUsers",
    EST_AD_BREAK_COUNT: "estimatedAdBreakCount",
    EST_TOTAL_AVAIL_AD_MINS: "estimatedTotalAvailableAdMinutes",
    ACTIONS: "actions",
} as const;

const columns = [
    {
        title: "Event Name",
        dataIndex: COLUMN_KEY.EVENT_NAME,
        sorter: true,
    },
    {
        title: "Start Date",
        dataIndex: COLUMN_KEY.START_DATE,
        sorter: true,
    },
    {
        title: "Duration",
        dataIndex: COLUMN_KEY.DURATION,
        sorter: true,
    },
    {
        title: "Est. Imp Opportunities",
        dataIndex: COLUMN_KEY.EST_IMP_OPPORTUNITIES,
        sorter: true,
    },
    {
        title: "Est. Avg Concurrent Viewers",
        dataIndex: COLUMN_KEY.EST_AVG_CONCURRENT_VIEWERS,
        sorter: true,
    },
    {
        title: "Est. Peak Concurrent Viewers",
        dataIndex: COLUMN_KEY.EST_PEAK_CONCURRENT_VIEWERS,
        sorter: true,
    },
    {
        title: "Est. Ad Break Count",
        dataIndex: COLUMN_KEY.EST_AD_BREAK_COUNT,
        sorter: true,
    },
    {
        title: "Est. Total Avail Ad Mins",
        dataIndex: COLUMN_KEY.EST_TOTAL_AVAIL_AD_MINS,
        sorter: true,
    },
    {
        title: "Actions",
        dataIndex: COLUMN_KEY.ACTIONS,
    },
];

export const useInventoryLiveEventsTable = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { seatId, eventId } = useParams<{ seatId: string; eventId: string }>();
    const keyword = useAppSelector(selectInventoryLiveEventsKeyword);
    const orderBy = useAppSelector(selectInventoryLiveEventsOrderBy);
    const isAscending = useAppSelector(selectInventoryLiveEventsIsAscending);
    const page = useAppSelector(selectInventoryLiveEventsPage);
    const max = useAppSelector(selectInventoryLiveEventsMax);
    const [search, setSearch] = useState(keyword);
    const { data: response, isFetching } = useGetLiveEventsQuery({
        seatId: Number(seatId),
        page,
        max,
        keyword,
        orderBy,
        isAscending,
    });
    const [openLiveEventDetails, setOpenLiveEventDetails] = useState<LiveEvent | null>(
        response?.liveEvents.find((event) => event.id === Number(eventId)) || null
    );

    const [deleteLiveEvent, { isLoading: isDeleting }] = useDeleteLiveEventMutation();
    const [liveEventToDelete, setLiveEventToDelete] = useState<LiveEvent | null>(null);

    const openLiveEventDetailsDrawer = (liveEvent: LiveEvent) => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_LIVE_EVENTS_DETAILS_DRAWER(seatId, liveEvent.id));
        setOpenLiveEventDetails(liveEvent);
    };
    const closeLiveEventDetailsDrawer = () => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_LIVE_EVENTS(seatId));
        setOpenLiveEventDetails(null);
    };

    const handleClickNewLiveEvent = () => history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_LIVE_EVENTS_CREATE(seatId));
    const handleClickEditLiveEvent = (eventId: number | string) =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_LIVE_EVENTS_EDIT(seatId, eventId));

    const onProposeDeleteLiveEvent = (liveEvent: LiveEvent) => {
        setLiveEventToDelete(liveEvent);
    };
    const onConfirmDeleteLiveEvent = () => {
        return deleteLiveEvent(liveEventToDelete?.id as number)
            .unwrap()
            .then(() => {
                setLiveEventToDelete(null);
            })
            .catch((err) => {
                notification.error({
                    message:
                        err.data?.errorDescription ||
                        err.data?.errorCode ||
                        "Something went wrong when trying to delete this live event",
                });
            });
    };
    const onCancelDeleteLiveEvent = () => {
        setLiveEventToDelete(null);
    };

    const debouncedKeyword = useMemo(
        () =>
            debounce(
                (keyword: string) => dispatch(setInventoryLiveEventsKeyword({ keyword })),
                FILTER_INPUT_DEBOUNCE_TIME
            ),
        [dispatch]
    );

    const onSearch = (searchTerm: string) => {
        setSearch(searchTerm);
        debouncedKeyword(searchTerm);
    };

    const onTableChange = (pagination, _filters, sorter, _extra) => {
        const { current, pageSize } = pagination;
        const { field, order } = sorter;
        dispatch(setInventoryLiveEventsSort({ orderBy: field, isAscending: order !== "descend" }));
        if (current && pageSize) {
            dispatch(setInventoryLiveEventsPagination({ page: current, max: pageSize }));
        }
    };

    return {
        liveEvents: response?.liveEvents,
        isFetching,
        columns,
        openLiveEventDetails,
        liveEventToDelete,
        isDeleting,
        search,
        paginationConfig: {
            current: page,
            pageSize: max,
            position: ["bottomRight"],
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            defaultPageSize: DEFAULT_PAGE_SIZE,
            showTotal: (total) => `${total} Total`,
            showSizeChanger: Boolean(response?.totalResults),
            total: response?.totalResults,
        } as TablePaginationConfig,
        openLiveEventDetailsDrawer,
        closeLiveEventDetailsDrawer,
        handleClickNewLiveEvent,
        handleClickEditLiveEvent,
        onProposeDeleteLiveEvent,
        onConfirmDeleteLiveEvent,
        onCancelDeleteLiveEvent,
        onSearch,
        onTableChange,
    };
};
