import { FC, useState } from "react";
import { AdSource } from "@app/core/services";
import { Tabs } from "antd";
import { SeatAdSourcesPerformanceHistorical } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/SeatAdSourcesDetailsDrawerContent/SeatAdSourcesDetailsDrawerView/secondaryViews";
import { isAdSourceDealPerformanceEligible, isAdSourcePrebid } from "@app/features/seatAdSources/utils";
import { Overview as DeprecatedOverview } from "./deprecated/OverviewTab/Overview";
import { AdBidStats } from "./AdBidStats/AdBidStats";
import { useAdStatsBetaAccess } from "@app/core/auth/useAdStatsBetaAccess";
import { Overview } from "./Overview";

interface Props {
    adSource: AdSource;
}

type AdSourcePeformanceTab = "overviewBeta" | "overview" | "historical" | "adBid";

interface AdSourceChartDrawerTabItem {
    label: string;
    key: AdSourcePeformanceTab;
}

const TAB_ITEMS: Readonly<AdSourceChartDrawerTabItem[]> = [
    { label: "Overview (BETA)", key: "overviewBeta" },
    { label: "Overview", key: "overview" },
    { label: "Historical Charts", key: "historical" },
    { label: "Ad/Bidder Stats", key: "adBid" },
];

export const SeatAdSourcesPerformance: FC<Props> = ({ adSource }) => {
    const [activeKey, setActiveKey] = useState<AdSourcePeformanceTab>("overview");
    const { hasAdSourcesPerformanceOverviewBetaAccess } = useAdStatsBetaAccess();

    const accessibleTabItems = TAB_ITEMS.filter(({ key }) => {
        const isPerformanceEligible = isAdSourceDealPerformanceEligible(adSource.type.id);
        if (key === "overviewBeta") {
            return hasAdSourcesPerformanceOverviewBetaAccess;
        }
        if (key === "historical") {
            return isPerformanceEligible;
        }
        if (key === "adBid") {
            return isPerformanceEligible || isAdSourcePrebid(adSource.type.id);
        }
        return true;
    });

    const activeTab = (() => {
        switch (activeKey) {
            case "overviewBeta":
                return <Overview />;
            case "overview":
                return (
                    <DeprecatedOverview
                        adSouceName={adSource.name}
                        adSourceId={adSource.id}
                        adSourceTypeId={adSource.type.id}
                    />
                );
            case "historical":
                return <SeatAdSourcesPerformanceHistorical adSourceId={adSource.id} />;
            case "adBid":
                return <AdBidStats adSourceId={adSource.id} />;
        }
    })();

    return (
        <>
            <Tabs
                items={accessibleTabItems}
                activeKey={activeKey}
                onChange={(key: AdSourcePeformanceTab) => setActiveKey(key)}
            />
            {activeTab}
        </>
    );
};
