import { RootState } from "@app/core/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const uiSlice = createSlice({
    name: "ui",
    initialState: {
        iframed: false,
    },
    reducers: {
        setIframed: (state, action: PayloadAction<boolean>) => {
            state.iframed = action.payload;
        },
    },
});

export const selectIframed = (state: RootState) => state.ui.iframed;

export const { setIframed } = uiSlice.actions;

export default uiSlice.reducer;
