import { FC } from "react";
import { Col, Row, Typography } from "antd";
import { LiveStatsTableAdSource } from "@app/core/services";
import { getCPMRateLabel } from "@app/features/deals/helpers";
import { DEAL_TYPE_AUCTION_PRICE_DEAL } from "@app/features/deals/constants";
import DealFloorPrices from "@app/features/deals/DealFloorPrices";
import { Dashes } from "@app/core/components";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/types";
import { getDisplayValue } from "@app/features/deals/DealFloorPrices/DealFloorPrices";
import { useAppSelector } from "@app/core/store";
import { CurrencyConversionModes } from "@app/core/clients/console";
import { useCurrency } from "../../useCurrency";
import { convert } from "@app/features/seatAdSources/helpers";

interface DealHealthTableCpmRateCellProps {
    dealType: {
        id: number;
        name: string;
    };
    rate: number;
    currencyType: {
        id: number;
        code: string;
    };
    adSources: LiveStatsTableAdSource[];
    openDrawer: (adSourceId: number, secondaryDetailsView?: SecondaryDetailsView) => void;
}

const DealTableCpmRateCell: FC<DealHealthTableCpmRateCellProps> = ({
    dealType,
    rate,
    currencyType,
    adSources,
    openDrawer,
}) => {
    const { dealCurrencyConversionMode, dealPreferredCurrency } = useAppSelector(
        (state) => state.deals.settings.values
    );

    const { currencyConversions, getCurrencyById } = useCurrency();

    const auctionPriceDeal: boolean = dealType?.id == DEAL_TYPE_AUCTION_PRICE_DEAL.id;

    if (!rate) {
        return <Dashes />;
    }

    const dealCpmRate =
        dealCurrencyConversionMode === CurrencyConversionModes.ORIGINATING_CURRENCY
            ? rate
            : convert(rate, currencyType.id, currencyConversions, dealPreferredCurrency as number);

    const currencyCode =
        dealCurrencyConversionMode === CurrencyConversionModes.ORIGINATING_CURRENCY
            ? currencyType.code
            : (getCurrencyById(dealPreferredCurrency as number)?.code as string);

    return (
        <Row>
            <Col span={24}>
                <Typography.Text>
                    {auctionPriceDeal ? (
                        <DealFloorPrices
                            dealAdSources={adSources}
                            currencyType={currencyType}
                            openDrawer={openDrawer}
                        />
                    ) : (
                        <span style={{ color: "#1890FF" }}>{getDisplayValue(dealCpmRate, currencyCode)}</span>
                    )}
                </Typography.Text>
            </Col>
            <Col span={24}>
                {rate ? <Typography.Text type="secondary">{getCPMRateLabel(dealType)}</Typography.Text> : null}
            </Col>
        </Row>
    );
};

export default DealTableCpmRateCell;
