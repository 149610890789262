import { FC } from "react";
import { brandSlate } from "@rubicon/antd-components";
import { Button, Col, Input, Row, Space, Table } from "antd";
import { DeleteOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { LiveEvent } from "@app/core/services/console/liveEvents";
import { InventoryLiveEventsDeleteModal } from "../InventoryLiveEventsDeleteModal";
import { InventoryLiveEventDetailsDrawer } from "./InventoryLiveEventDetailsDrawer";
import { COLUMN_KEY, useInventoryLiveEventsTable } from "./useInventoryLiveEventsTable";
import {
    formatDuration,
    formatEstAdBreakCount,
    formatEstAvgConcurrentViewers,
    formatEstImpOpportunities,
    formatEstPeakConcurrentViewers,
    formatEstTotalAvailAdMins,
    formatStartDate,
} from "../utils";
import { mapLiveEventStartEndDatesToDuration } from "../InventoryLiveEventsForm";

export const InventoryLiveEventsTable: FC = () => {
    const {
        liveEvents,
        isFetching,
        columns,
        paginationConfig,
        openLiveEventDetails,
        liveEventToDelete,
        isDeleting,
        search,
        openLiveEventDetailsDrawer,
        closeLiveEventDetailsDrawer,
        handleClickNewLiveEvent,
        handleClickEditLiveEvent,
        onProposeDeleteLiveEvent,
        onConfirmDeleteLiveEvent,
        onCancelDeleteLiveEvent,
        onSearch,
        onTableChange,
    } = useInventoryLiveEventsTable();

    const getTableItems = (liveEvents: LiveEvent[] = []) => {
        return liveEvents.map((liveEvent) => ({
            key: liveEvent.id,
            [COLUMN_KEY.EVENT_NAME]: <a onClick={() => openLiveEventDetailsDrawer(liveEvent)}>{liveEvent.name}</a>,
            [COLUMN_KEY.START_DATE]: formatStartDate(liveEvent.startDate),
            [COLUMN_KEY.DURATION]: formatDuration(mapLiveEventStartEndDatesToDuration(liveEvent)),
            [COLUMN_KEY.EST_IMP_OPPORTUNITIES]: formatEstImpOpportunities(liveEvent.estimatedImpressionOpportunities),
            [COLUMN_KEY.EST_AVG_CONCURRENT_VIEWERS]: formatEstAvgConcurrentViewers(
                liveEvent.estimatedAverageConcurrentUsers
            ),
            [COLUMN_KEY.EST_PEAK_CONCURRENT_VIEWERS]: formatEstPeakConcurrentViewers(
                liveEvent.estimatedPeakConcurrentUsers
            ),
            [COLUMN_KEY.EST_AD_BREAK_COUNT]: formatEstAdBreakCount(liveEvent.estimatedAdBreakCount),
            [COLUMN_KEY.EST_TOTAL_AVAIL_AD_MINS]: formatEstTotalAvailAdMins(liveEvent.estimatedTotalAvailableAdMinutes),
            [COLUMN_KEY.ACTIONS]: (
                <Button.Group>
                    <Button icon={<EditOutlined />} onClick={() => handleClickEditLiveEvent(liveEvent.id)} />
                    <Button icon={<DeleteOutlined />} onClick={() => onProposeDeleteLiveEvent(liveEvent)} />
                </Button.Group>
            ),
        }));
    };

    return (
        <Space size="large" direction="vertical" style={{ width: "100%" }}>
            <Row justify="space-between">
                <Col>
                    <Input
                        allowClear
                        data-sdet="live-events-table-search"
                        value={search}
                        onChange={(e) => onSearch(e.target.value)}
                        placeholder="Search"
                        suffix={<SearchOutlined style={{ color: brandSlate }} />}
                    />
                </Col>
                <Col>
                    <Button type="primary" data-sdet="new-live-event-button" onClick={handleClickNewLiveEvent}>
                        New Live Event
                    </Button>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={getTableItems(liveEvents)}
                loading={isFetching}
                onChange={onTableChange}
                pagination={paginationConfig}
                size="small"
            />
            {openLiveEventDetails && (
                <InventoryLiveEventDetailsDrawer
                    title={openLiveEventDetails?.name || "Live Event"}
                    liveEvent={openLiveEventDetails}
                    handleEdit={handleClickEditLiveEvent}
                    handleClose={() => closeLiveEventDetailsDrawer()}
                />
            )}
            {liveEventToDelete && (
                <InventoryLiveEventsDeleteModal
                    isOpen={Boolean(liveEventToDelete)}
                    name={liveEventToDelete?.name}
                    isDeleting={isDeleting}
                    handleConfirm={onConfirmDeleteLiveEvent}
                    handleCancel={onCancelDeleteLiveEvent}
                />
            )}
        </Space>
    );
};
