import { useMemo } from "react";
import { useGetCostModelsQuery } from "@magnite/client-streaming-platform";

interface UsePricingModelSelectProps {
    filterOption: (input: string, option: { label: string; value: number }) => boolean;
    options: { label: string; value: number }[];
    isFetching: boolean;
}

export const usePricingModelSelect = (): UsePricingModelSelectProps => {
    const { data = [], isFetching } = useGetCostModelsQuery();
    const options = useMemo(() => (data || []).map((model) => ({ value: model?.id, label: model?.name })), [data]);
    const filterOption = (input: string, option: { label: string; value: number }): boolean =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    return {
        isFetching,
        options,
        filterOption,
    };
};
