import { InventoryDistributionGroup, SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { Form } from "antd";
import { AdSourceFormMode, AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { FC, useContext, useEffect } from "react";
import { InventoryDistributionGroupsField } from "./InventoryDistributionGroupsField";
import { useSeatAuthContext } from "@app/core/auth";

interface Props {
    mode: AdSourceFormMode | undefined;
}

export const InventoryDistributionGroupsList: FC<Props> = ({ mode }) => {
    const currentGroups =
        Form.useWatch<InventoryDistributionGroup[]>(AD_SOURCE_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.name) || [];
    const adSourceType = Form.useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const { context } = useSeatAuthContext();
    const isFixedAuctionPrice = [AdSourceTypeIds.AUCTION_PRICE, AdSourceTypeIds.FIXED_PRICE].includes(adSourceType?.id);
    const { forms } = useContext(AdSourceFormsContext);
    const { adSourceForm } = forms;
    const idgValues = adSourceForm.getFieldValue(AD_SOURCE_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.name);

    const isInventoryDistributionGroupShown =
        ((adSourceType?.id === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED && mode === "create") ||
            (mode === "edit" && idgValues?.length > 0) ||
            isFixedAuctionPrice) &&
        Boolean(context?.distributionGroupsEnabled);

    //sets the values of InventoryDistributionGroup to blank if the adSourceType is updated
    useEffect(() => {
        if (mode !== "edit") {
            adSourceForm.setFieldsValue({ [AD_SOURCE_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.name]: [] });
        }
    }, [adSourceType, mode, adSourceForm]);

    return (
        <InventoryDistributionGroupsField
            isEditing={mode === "edit"}
            isHidden={!isInventoryDistributionGroupShown}
            name={AD_SOURCE_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.name}
            label={AD_SOURCE_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.label}
            value={currentGroups}
            isFixedAuctionPrice={isFixedAuctionPrice}
        />
    );
};
