import { InfoCircleFilled } from "@ant-design/icons";
import {
    PAGE_SIZE_OPTIONS,
    YEAR_MONTH_DAY_HOUR_MINUTE_AMPM,
    YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE,
} from "@app/core/components/constants";
import { useNotificationError } from "@app/core/components/hooks";
import {
    AdSourceListItem,
    AdUnit,
    BulkOperationEntityType,
    BuyerDeal,
    DemandDeal,
    getTimeZoneLabel,
    useLazyGetSeatAdSourcesQuery,
    useLazyGetSeatCtvNextAdUnitsQuery,
    useLazyGetSeatCtvNextBuyerDealsQuery,
    useLazyGetSeatCtvNextDemandDealsQuery,
} from "@app/core/services";
import { getEntityLineage } from "@app/core/services/console/changeLogs/legacyUtils";
import { formatDateInTimeZone, formatDateInUtc } from "@app/core/utils";
import {
    ADSOURCE_MAX_DURAITON,
    ADSOURCE_MIN_DURAITON,
    BULK_OPERATION_FORM_ITEMS,
    MAX_DURATION,
    MIN_DURATION,
} from "@app/features/controls/bulkOperations/constants";
import {
    selectAdSourcePageInfo,
    selectAdSourceResponse,
    selectAdSourceSelectedRowKeys,
    selectAdUnitPageInfo,
    selectAdUnitResponse,
    selectAdUnitSelectedRowKeys,
    selectBuyerDealPageInfo,
    selectBuyerDealResponse,
    selectBuyerDealSelectedRowKeys,
    selectDemandDealPageInfo,
    selectDemandDealResponse,
    selectDemandDealSelectedRowKeys,
    setAdSourceBulkOperationPageInfo,
    setAdSourceBulkOperationSelections,
    setAdSourceBulkOperationResponse,
    setAdUnitBulkOperationPageInfo,
    setAdUnitBulkOperationSelections,
    setAdUnitBulkOperationResponse,
    setBuyerDealBulkOperationPageInfo,
    setBuyerDealBulkOperationSelections,
    setBuyerDealBulkOperationResponse,
    setDemandDealBulkOperationPageInfo,
    setDemandDealBulkOperationSelections,
    setDemandDealBulkOperationResponse,
} from "@app/features/controls/bulkOperations/reducer";
import { DEFAULT_LABEL_BOTH_ALLOWED, DEFAULT_LABEL_OFF } from "@app/features/controls/constants";
import { blue6 } from "@rubicon/antd-components";
import { format } from "@rubicon/utils";
import { Button, Form, Space, Table, TableColumnsType, TablePaginationConfig, Typography } from "antd";
import { FC, Key } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const AD_SOURCES_COLUMNS_BASE: TableColumnsType = [
    {
        key: "name",
        dataIndex: "name",
        title: "Name",
        fixed: "left" as const,
        width: "250px",
        // use Typography to make sure name string break into lines when it's too long
        render: (name) => <Typography.Text>{name}</Typography.Text>,
    },
    {
        key: "type",
        dataIndex: ["type", "name"],
        title: "Type",
    },
    {
        key: "creationDate",
        dataIndex: "creationDate",
        title: "Date Created",
        render: (creationDate) => formatDateInUtc(creationDate, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE),
    },
    {
        key: "updateDate",
        dataIndex: "updateDate",
        title: "Last Updated",
        render: (updateDate) => formatDateInUtc(updateDate, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE),
    },
];

export const AD_SOURCES_EXTRA_COLUMNS: TableColumnsType = [
    {
        key: "priority",
        dataIndex: ["priority", "name"],
        title: "Priority",
    },
    // isSysAdmin || isPubAcctMgr
    {
        key: "region",
        dataIndex: "region",
        title: "Region",
        render: (region) => region?.name || format.constants.NOT_SPECIFIED,
    },
    // isSysAdmin || isPubAcctMgr
    {
        key: "salesContact",
        dataIndex: "salesContact",
        title: "Sales Contact",
        render: (salesContact) => salesContact?.name || format.constants.NOT_SPECIFIED,
    },
    {
        key: "status",
        dataIndex: ["status", "name"],
        title: "Status",
    },
    {
        key: "bookingStartDate",
        dataIndex: "bookingStartDate",
        title: "Start Date",
        render: (bookingStartDate, { timeZone }) =>
            formatDateInTimeZone(bookingStartDate, timeZone, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM),
    },
    {
        key: "bookingEndDate",
        dataIndex: "bookingEndDate",
        title: "End Date",
        render: (bookingEndDate, { timeZone }) =>
            formatDateInTimeZone(bookingEndDate, timeZone, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM),
    },
    {
        key: "timeZone",
        dataIndex: "timeZone",
        title: "Time Zone",
        render: (timeZone) => getTimeZoneLabel(timeZone),
    },
    {
        key: "currencyType",
        dataIndex: ["currencyType", "code"],
        title: "Currency",
    },
    // isSysAdmin || isPubAcctMgr
    {
        key: "costModel",
        dataIndex: "costModel",
        title: "Cost Type",
        render: (costModel) => costModel?.name || format.constants.DEFAULT_DASHES,
    },
    // isSysAdmin || isPubAcctMgr
    {
        key: "costValueFixed",
        dataIndex: "costValueFixed",
        title: "Cost Value Fixed",
        render: (costValueFixed) => (costValueFixed != null ? costValueFixed / 1000 : format.constants.DEFAULT_DASHES),
    },
    // isSysAdmin || isPubAcctMgr
    {
        key: "costValuePercent",
        dataIndex: "costValuePercent",
        title: "Cost Value Percent",
        render: (costValuePercent) => format.asSelf(costValuePercent),
    },
    {
        key: "adSourceFloorType",
        dataIndex: ["adSourceFloorType", "name"],
        title: "Floor Type",
    },
    {
        key: "bookingPrice",
        dataIndex: "bookingPrice",
        title: "Booking Price",
        render: (bookingPrice) => (bookingPrice != null ? bookingPrice / 1000 : format.constants.DEFAULT_DASHES),
    },
    {
        key: "bookingVolume",
        dataIndex: "bookingVolume",
        title: "Impression Goal",
        render: (bookingVolume) => format.asSelf(bookingVolume),
    },
    {
        key: "adSourceMinDuration",
        dataIndex: "adSourceMinDuration",
        title: "Min Duration",
        render: (adSourceMinDuration) => format.asSelf(adSourceMinDuration),
    },
    {
        key: "adSourceMaxDuration",
        dataIndex: "adSourceMaxDuration",
        title: "Max Duration",
        render: (adSourceMaxDuration) => format.asSelf(adSourceMaxDuration),
    },
    {
        key: "allowAutoscale",
        dataIndex: "allowAutoscale",
        title: "Allow Autoscale",
        render: (allowAutoscale) => format.asYesOrNo(allowAutoscale),
    },
    {
        key: "alwaysSendFloor",
        dataIndex: "alwaysSendFloor",
        title: "Always Send Floor",
        render: (alwaysSendFloor) => format.asYesOrNo(alwaysSendFloor),
    },
    {
        key: "allowSiteName",
        dataIndex: "allowSiteName",
        title: "Allow Site Name",
        render: (allowSiteName) => format.asYesOrNo(allowSiteName),
    },
    {
        key: "allowDomainName",
        dataIndex: "allowDomainName",
        title: "Allow Domain Name",
        render: (allowDomainName) => format.asYesOrNo(allowDomainName),
    },
    {
        key: "allowUserId",
        dataIndex: "allowUserId",
        title: "Allow User ID",
        render: (allowUserId, { maskUserId }) => (maskUserId ? "Masked" : format.asSharedOrHidden(allowUserId)),
    },
    {
        key: "allowSitePage",
        dataIndex: "allowSitePage",
        title: "Allow Site Page",
        render: (allowSitePage) => format.asYesOrNo(allowSitePage),
    },
    {
        key: "allowRef",
        dataIndex: "allowRef",
        title: "Allow Referrer",
        render: (allowRef) => format.asYesOrNo(allowRef),
    },
    {
        key: "allowContent",
        dataIndex: "allowContent",
        title: "Allow Content",
        render: (allowContent, { contentTransparencyRule }) =>
            contentTransparencyRule?.name || format.asSharedOrHidden(allowContent),
    },
    {
        key: "allowIp",
        dataIndex: "allowIp",
        title: "Allow IP",
        render: (allowIp) => format.asYesOrNo(allowIp),
    },
    {
        key: "auctionType",
        dataIndex: "auctionType",
        title: "Default Auction Type",
        render: (auctionType) => auctionType?.name || DEFAULT_LABEL_OFF,
    },
    {
        key: "allowAllExtendedId",
        dataIndex: "allowAllExtendedId",
        title: "Allow Extended ID",
        render: (allowAllExtendedId) => format.asYesOrNo(allowAllExtendedId),
    },
    {
        key: "allowBundleId",
        dataIndex: "allowBundleId",
        title: "Allow Bundle ID",
        render: (allowBundleId) => format.asYesOrNo(allowBundleId),
    },
    {
        key: "labelValues",
        dataIndex: "labelValues",
        title: "Labels",
        render: (labelValues) =>
            labelValues?.map(({ label, value }) => `${label.key}: ${value}`).join(", ") ||
            format.constants.DEFAULT_DASHES,
    },
    {
        key: "overrideDynamicFloor",
        dataIndex: "overrideDynamicFloor",
        title: "Run Under Inbound Floor",
        render: (overrideDynamicFloor) => format.asYesOrNo(overrideDynamicFloor),
    },
];

export const AD_UNITS_COLUMNS_BASE: TableColumnsType = [
    {
        key: "name",
        dataIndex: "name",
        title: "Name",
        fixed: "left" as const,
        width: "250px",
        // use Typography to make sure name string break into lines when it's too long
        render: (name) => <Typography.Text>{name}</Typography.Text>,
    },
    {
        key: "adUnitLineage",
        dataIndex: "supply",
        title: "Lineage",
        width: 300,
        render: (_, entity) => getEntityLineage(entity),
    },
    {
        key: "creationDate",
        dataIndex: "creationDate",
        title: "Date Created",
        render: (creationDate) => formatDateInUtc(creationDate, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE),
    },
    {
        key: "updateDate",
        dataIndex: "updateDate",
        title: "Last Updated",
        render: (updateDate) => formatDateInUtc(updateDate, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE),
    },
];

export const AD_UNITS_EXTRA_COLUMNS: TableColumnsType = [
    {
        key: "status",
        dataIndex: ["status", "name"],
        title: "Status",
    },
    {
        key: "placement",
        dataIndex: ["placement", "name"],
        title: "Placement",
    },
    {
        key: "supportedProtocols",
        dataIndex: "supportedProtocols",
        title: "Supported Protocols",
        render: (supportedProtocols) =>
            supportedProtocols.map(({ name }) => name).join(", ") || format.constants.DEFAULT_DASHES,
    },
    {
        key: "mimes",
        dataIndex: "mimes",
        title: "Mimes",
        render: (mimes) => mimes.map(({ name }) => name).join(", ") || format.constants.DEFAULT_DASHES,
    },
    {
        key: "supportedApis",
        dataIndex: "supportedApis",
        title: "Supported APIs",
        render: (supportedApis) => supportedApis.map(({ name }) => name).join(", ") || format.constants.DEFAULT_DASHES,
    },
    {
        key: "publisherReauction",
        dataIndex: "publisherReauction",
        title: "Publisher Re-Auction",
        render: (publisherReauction, { calculatedPublisherReauction }) => {
            const value =
                typeof calculatedPublisherReauction === "boolean" ? calculatedPublisherReauction : publisherReauction;

            return typeof value === "boolean" ? format.asYesOrNo(value) : format.constants.NOT_SPECIFIED;
        },
    },
    {
        key: "skip",
        dataIndex: "skip",
        title: "Skippable",
        render: (skip) => format.asYesOrNo(skip),
    },
    {
        key: "skipmin",
        dataIndex: "skipmin",
        title: "Skip Minimum",
        render: (skipmin) => format.asSelf(skipmin),
    },
    {
        key: "skipafter",
        dataIndex: "skipafter",
        title: "Skip After",
        render: (skipafter) => format.asSelf(skipafter),
    },
    {
        key: "minBitrate",
        dataIndex: "minBitrate",
        title: "Min Bitrate",
        render: (minBitrate) => format.asSelf(minBitrate),
    },
    {
        key: "maxBitrate",
        dataIndex: "maxBitrate",
        title: "Max Bitrate",
        render: (maxBitrate) => format.asSelf(maxBitrate),
    },
    {
        key: "minDuration",
        dataIndex: "minDuration",
        title: "Min Duration",
        render: (minDuration) => format.asSelf(minDuration),
    },
    {
        key: "maxDuration",
        dataIndex: "maxDuration",
        title: "Max Duration",
        render: (maxDuration) => format.asSelf(maxDuration),
    },
    {
        key: "ssaiType",
        dataIndex: "ssaiType",
        title: "SSAI Type",
        render: (ssaiType) => ssaiType?.name || DEFAULT_LABEL_OFF,
    },
    {
        key: "impressionWaitTime",
        dataIndex: "impressionWaitTime",
        title: "Impression Wait Time",
        render: (impressionWaitTime) => impressionWaitTime?.name || DEFAULT_LABEL_OFF,
    },
    {
        key: "blockedCreativeAttributes",
        dataIndex: "blockedCreativeAttributes",
        title: "Blocked Attributes",
        width: 300,
        render: (blockedCreativeAttributes) =>
            blockedCreativeAttributes?.map(({ name }) => name).join(", ") || format.constants.DEFAULT_DASHES,
    },
    {
        key: "videoDeliveries",
        dataIndex: "videoDeliveries",
        title: "Delivery Methods",
        render: (videoDeliveries) =>
            videoDeliveries?.map(({ name }) => name).join(", ") || format.constants.DEFAULT_DASHES,
    },
    {
        key: "linearity",
        dataIndex: "linearity",
        title: "Linearity",
        render: (linearity) => linearity?.name || DEFAULT_LABEL_BOTH_ALLOWED,
    },
    {
        key: "type",
        dataIndex: ["type", "name"],
        title: "Type",
        render: (name) => name || format.constants.DEFAULT_DASHES,
    },
    {
        key: "contentNetwork",
        dataIndex: "contentNetwork",
        title: "Content Network",
        render: (contentNetwork) => format.asSelf(contentNetwork),
    },
    {
        key: "contentChannel",
        dataIndex: "contentChannel",
        title: "Content Channel",
        render: (contentChannel) => format.asSelf(contentChannel),
    },
    {
        key: "unwrapVast",
        dataIndex: "unwrapVast",
        title: "Vast Unwrapping",
        render: (unwrapVast) => format.asYesOrNo(unwrapVast),
    },
    {
        key: "isLiveStream",
        dataIndex: "isLiveStream",
        title: "Live Stream",
        render: (isLiveStream) => format.asYesOrNo(isLiveStream),
    },
    {
        key: "labelValues",
        dataIndex: "labelValues",
        title: "Labels",
        render: (labelValues) =>
            labelValues?.map(({ label, value }) => `${label.key}: ${value}`).join(", ") ||
            format.constants.DEFAULT_DASHES,
    },
    {
        key: "overrideDynamicFloor",
        dataIndex: "overrideDynamicFloor",
        title: "Allow Demand Under Inbound Floor",
        render: (overrideDynamicFloor) => format.asYesOrNo(overrideDynamicFloor),
    },
];

export const BUYER_DEALS_COLUMNS_BASE: TableColumnsType = [
    {
        key: "externalName",
        dataIndex: "externalName",
        title: "Name",
        fixed: "left" as const,
        width: "250px",
        // use Typography to make sure name string break into lines when it's too long
        render: (externalName) => <Typography.Text>{format.asSelf(externalName)}</Typography.Text>,
    },
    {
        key: "networkName",
        dataIndex: ["network", "name"],
        title: "Demand",
    },
    {
        key: "buyerSeats",
        dataIndex: "buyerSeats",
        title: "Buyer",
        width: 200,
        render: (buyerSeats) => (
            <Typography.Text>
                {buyerSeats
                    .map(({ name, code }) => (name && code ? `${name} - ${code}` : null))
                    .filter(Boolean)
                    .join(", ") || format.constants.DEFAULT_DASHES}
            </Typography.Text>
        ),
    },
    {
        key: "code",
        dataIndex: "code",
        title: "Deal ID",
        render: (code) => format.asSelf(code),
    },
    {
        key: "externalStatus",
        dataIndex: "externalStatus",
        title: "Status",
        render: (externalStatus) => format.asSelf(externalStatus),
    },
    {
        key: "dealTransactionType",
        dataIndex: ["dealTransactionType", "name"],
        title: "Transaction Type",
        render: (dealTransactionType) => format.asSelf(dealTransactionType),
    },
    {
        key: "dealPricingType",
        dataIndex: ["dealPricingType", "name"],
        title: "Pricing Type",
        render: (dealPricingType) => format.asSelf(dealPricingType),
    },
    {
        key: "creationDate",
        dataIndex: "creationDate",
        title: "Date Created",
        render: (creationDate) => formatDateInUtc(creationDate, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE),
    },
    {
        key: "updateDate",
        dataIndex: "updateDate",
        title: "Last Updated",
        render: (updateDate) => formatDateInUtc(updateDate, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE),
    },
];

export const BUYER_DEALS_EXTRA_COLUMNS: TableColumnsType = [
    {
        key: "startTime",
        dataIndex: "startTime",
        title: "Start Date",
        render: (startTime, { timeZone }) => formatDateInTimeZone(startTime, timeZone, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM),
    },
    {
        key: "endTime",
        dataIndex: "endTime",
        title: "End Date",
        render: (endTime, { timeZone }) => formatDateInTimeZone(endTime, timeZone, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM),
    },
    {
        key: "timeZone",
        dataIndex: "timeZone",
        title: "Time Zone",
        render: (timeZone) => getTimeZoneLabel(timeZone),
    },
    {
        key: "currencyType",
        dataIndex: ["currencyType", "code"],
        title: "Currency",
    },
    {
        key: "rate",
        dataIndex: "rate",
        title: "CPM Rate",
        render: (rate) => format.asSelf(rate),
    },
    {
        key: "bookingVolume",
        dataIndex: "bookingVolume",
        title: "Impressions",
        render: (bookingVolume) => format.asSelf(bookingVolume),
    },
    {
        key: "sharedSeats",
        dataIndex: "sharedSeats",
        title: "Shared Seats",
        render: (sharedSeats) => sharedSeats.map(({ name }) => name).join(", ") || format.constants.DEFAULT_DASHES,
    },
    {
        key: "sharedMarketplaces",
        dataIndex: "sharedMarketplaces",
        title: "Shared Marketplaces",
        render: (sharedMarketplaces) =>
            sharedMarketplaces.map(({ name }) => name).join(", ") || format.constants.DEFAULT_DASHES,
    },
];

export const DEMAND_DEALS_COLUMNS_BASE: TableColumnsType = BUYER_DEALS_COLUMNS_BASE.filter(
    ({ key }) => key !== "buyerSeats" && key !== "externalStatus"
);
const DEMAND_DEALS_EXTRA_COLUMNS: TableColumnsType = BUYER_DEALS_EXTRA_COLUMNS;

export const getColumns = (entityType: BulkOperationEntityType, fieldsToEdit?: Key[]) => {
    switch (entityType) {
        case BulkOperationEntityType.AdSource:
            return AD_SOURCES_COLUMNS_BASE.concat(
                fieldsToEdit
                    ? AD_SOURCES_EXTRA_COLUMNS.reduce((acc: TableColumnsType, column) => {
                          const { key } = column;
                          if (
                              fieldsToEdit.includes("adsourceMinMaxDuration") &&
                              (key === ADSOURCE_MIN_DURAITON || key === ADSOURCE_MAX_DURAITON)
                          ) {
                              acc.push(column);
                              return acc;
                          }
                          if (key && fieldsToEdit.includes(key)) {
                              acc.push(column);
                          }
                          return acc;
                      }, [])
                    : []
            );
        case BulkOperationEntityType.AdUnit:
            return AD_UNITS_COLUMNS_BASE.concat(
                fieldsToEdit
                    ? AD_UNITS_EXTRA_COLUMNS.reduce((acc: TableColumnsType, column) => {
                          const { key } = column;
                          // "skipmin" and "skipafter" are not in fieldsToEdit options, they are only added to table when "skip" option is selected
                          const shouldAddSkipMinAndAfter =
                              (key === "skipmin" || key === "skipafter") && fieldsToEdit.includes("skip");
                          if (key && (shouldAddSkipMinAndAfter || fieldsToEdit.includes(key))) {
                              acc.push(column);
                          }
                          // minMaxDuration is not in fieldsToEdit options then we shouldn't show min and max duration on table
                          if (
                              fieldsToEdit.includes("minMaxDuration") &&
                              (key === MIN_DURATION || key === MAX_DURATION)
                          ) {
                              acc.push(column);
                          }
                          return acc;
                      }, [])
                    : []
            );
        case BulkOperationEntityType.BuyerDeal:
            return BUYER_DEALS_COLUMNS_BASE.concat(
                fieldsToEdit ? BUYER_DEALS_EXTRA_COLUMNS.filter(({ key }) => key && fieldsToEdit.includes(key)) : []
            );
        case BulkOperationEntityType.DemandDeal:
            return DEMAND_DEALS_COLUMNS_BASE.concat(
                fieldsToEdit ? DEMAND_DEALS_EXTRA_COLUMNS.filter(({ key }) => key && fieldsToEdit.includes(key)) : []
            );
        default:
            return [];
    }
};

const PREFER_CACHE_VALUE = true;

const RunFiltersButton = ({ onClick, entityTypeName, hasRun }) => {
    return (
        <Space size="large">
            <Button type="primary" data-sdet="run-filters-button" onClick={() => onClick()}>
                Run Filters
            </Button>
            <Space>
                <InfoCircleFilled style={{ color: blue6 }} />
                <Typography.Text>
                    {hasRun
                        ? `Run to show matched ${entityTypeName} and select any matched ${entityTypeName} to apply bulk edit`
                        : `Run to show matched ${entityTypeName} to select to edit`}
                </Typography.Text>
            </Space>
        </Space>
    );
};
export const AdSourceOperationEntitiesTable: FC = () => {
    const dispatch = useDispatch();
    const form = Form.useFormInstance();

    const data = useSelector(selectAdSourceResponse);
    const selectedRowKeys = useSelector(selectAdSourceSelectedRowKeys);
    const { current, pageSize } = useSelector(selectAdSourcePageInfo);
    const fieldsToEdit = Form.useWatch(BULK_OPERATION_FORM_ITEMS.FIELDS_TO_EDIT.name);
    const { seatId } = useParams<{ seatId: string }>();

    const [triggerGetAdSources, { error, isFetching }] = useLazyGetSeatAdSourcesQuery();

    useNotificationError(error);

    const handleApiCall = (pageInfo: { current?: number; pageSize?: number }) => {
        dispatch(setAdSourceBulkOperationPageInfo(pageInfo));
        const { adSourceStatus, adSourceKeyword, priorityIds, region, salesContactIds, ...rest } =
            form.getFieldValue("filters");

        triggerGetAdSources(
            {
                seatId: Number(seatId),
                page: pageInfo.current,
                max: pageInfo.pageSize,
                statusIds: adSourceStatus,
                keyword: adSourceKeyword,
                priority: priorityIds,
                salesContactIds: salesContactIds?.includes(-1) ? null : salesContactIds,
                region: region?.includes(-1) ? null : region,
                ...rest,
            },
            PREFER_CACHE_VALUE
        )
            .unwrap()
            .then((response) => dispatch(setAdSourceBulkOperationResponse({ response })));
    };

    const onClickRunFilters = () => {
        handleApiCall({ current: 1, pageSize });
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        handleApiCall({ current: pagination.current, pageSize: pagination.pageSize });
    };

    return (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <RunFiltersButton onClick={onClickRunFilters} entityTypeName="Ad Sources" hasRun={Boolean(data)} />
            <Table
                size="small"
                dataSource={data?.adSources || []}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                columns={getColumns(BulkOperationEntityType.AdSource, fieldsToEdit)}
                loading={isFetching}
                onChange={handleTableChange}
                rowKey="id"
                rowSelection={{
                    selectedRowKeys,
                    preserveSelectedRowKeys: true,
                    // when selectedRowKeys contains id(s) from upload, but may not in the table loaded items, it will show "undefined" element(s) in selectedRows
                    onChange: (selectedRowKeys: number[], selectedRows: (AdSourceListItem | undefined)[]) => {
                        const presentSelectedRows = selectedRows.filter(Boolean) as AdSourceListItem[];
                        dispatch(
                            setAdSourceBulkOperationSelections({ selectedRowKeys, selectedRows: presentSelectedRows })
                        );
                    },
                }}
                showSorterTooltip={false}
                scroll={{ x: "max-content" }}
                pagination={{
                    current: current,
                    pageSize: pageSize,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    showTotal: (total) => `${total} Total`,
                    showSizeChanger: Boolean(data?.totalResults),
                    total: data?.totalResults || 0,
                }}
            />
        </Space>
    );
};

export const AdUnitOperationEntitiesTable: FC = () => {
    const dispatch = useDispatch();
    const form = Form.useFormInstance();

    const data = useSelector(selectAdUnitResponse);
    const { current, pageSize } = useSelector(selectAdUnitPageInfo);
    const selectedRowKeys = useSelector(selectAdUnitSelectedRowKeys);
    const fieldsToEdit = Form.useWatch(BULK_OPERATION_FORM_ITEMS.FIELDS_TO_EDIT.name);
    const [triggerGetAdUnits, { isFetching, error }] = useLazyGetSeatCtvNextAdUnitsQuery();

    useNotificationError(error);

    const handleApiCall = (pageInfo: { current?: number; pageSize?: number }) => {
        dispatch(setAdUnitBulkOperationPageInfo(pageInfo));
        const { adUnitKeyword, adUnitStatus, impressionWaitTime, supportedApiIds, ...rest } =
            form.getFieldValue("filters");

        triggerGetAdUnits(
            {
                seatId: Number(seatId),
                page: pageInfo.current,
                max: pageInfo.pageSize,
                statusIds: adUnitStatus,
                keyword: adUnitKeyword,
                impressionWaitTime: impressionWaitTime?.includes(-1) ? null : impressionWaitTime,
                supportedApiIds: supportedApiIds?.includes(-1) ? null : supportedApiIds,
                ...rest,
            },
            PREFER_CACHE_VALUE
        )
            .unwrap()
            .then((response) => dispatch(setAdUnitBulkOperationResponse({ response })));
    };

    const { seatId } = useParams<{ seatId: string }>();

    const onClickRunFilters = () => {
        handleApiCall({ current: 1, pageSize });
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        handleApiCall({ current: pagination.current, pageSize: pagination.pageSize });
    };

    return (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <RunFiltersButton onClick={onClickRunFilters} entityTypeName="Ad Units" hasRun={Boolean(data)} />
            <Table
                size="small"
                dataSource={data?.adUnits || []}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                columns={getColumns(BulkOperationEntityType.AdUnit, fieldsToEdit)}
                loading={isFetching}
                onChange={handleTableChange}
                rowKey="id"
                rowSelection={{
                    selectedRowKeys,
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys, selectedRows: (AdUnit | undefined)[]) => {
                        const presentSelectedRows = selectedRows.filter(Boolean) as AdUnit[];
                        dispatch(
                            setAdUnitBulkOperationSelections({ selectedRowKeys, selectedRows: presentSelectedRows })
                        );
                    },
                }}
                showSorterTooltip={false}
                scroll={{ x: "max-content" }}
                pagination={{
                    current: current,
                    pageSize: pageSize,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    showTotal: (total) => `${total} Total`,
                    showSizeChanger: Boolean(data?.totalResults),
                    total: data?.totalResults || 0,
                }}
            />
        </Space>
    );
};

export const BuyerDealOperationEntitiesTable: FC = () => {
    const dispatch = useDispatch();
    const form = Form.useFormInstance();

    const data = useSelector(selectBuyerDealResponse);
    const selectedRowKeys = useSelector(selectBuyerDealSelectedRowKeys);
    const { current, pageSize } = useSelector(selectBuyerDealPageInfo);
    const fieldsToEdit = Form.useWatch(BULK_OPERATION_FORM_ITEMS.FIELDS_TO_EDIT.name);

    const [triggerGetBuyerDeals, { isFetching, error }] = useLazyGetSeatCtvNextBuyerDealsQuery();

    useNotificationError(error);

    const { seatId } = useParams<{ seatId: string }>();

    const handleApiCall = (pageInfo: { current?: number; pageSize?: number }) => {
        dispatch(setBuyerDealBulkOperationPageInfo(pageInfo));
        const { buyerDealType, buyerDealKeyword, ...rest } = form.getFieldValue("filters") || {};
        triggerGetBuyerDeals(
            {
                seatId: Number(seatId),
                page: pageInfo.current,
                max: pageInfo.pageSize,
                type: buyerDealType,
                keyword: buyerDealKeyword,
                ...rest,
            },
            PREFER_CACHE_VALUE
        )
            .unwrap()
            .then((response) => dispatch(setBuyerDealBulkOperationResponse({ response })));
    };

    const onClickRunFilters = () => {
        handleApiCall({ current: 1, pageSize });
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        dispatch(setBuyerDealBulkOperationPageInfo({ current: pagination.current, pageSize: pagination.pageSize }));
        handleApiCall({ current: pagination.current, pageSize: pagination.pageSize });
    };

    return (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <RunFiltersButton onClick={onClickRunFilters} entityTypeName="Buyer Deals" hasRun={Boolean(data)} />
            <Table
                dataSource={data?.buyerDeals || []}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                columns={getColumns(BulkOperationEntityType.BuyerDeal, fieldsToEdit)}
                loading={isFetching}
                onChange={handleTableChange}
                rowKey="id"
                rowSelection={{
                    selectedRowKeys,
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys, selectedRows: (BuyerDeal | undefined)[]) => {
                        const presentSelectedRows = selectedRows.filter(Boolean) as BuyerDeal[];
                        dispatch(
                            setBuyerDealBulkOperationSelections({ selectedRowKeys, selectedRows: presentSelectedRows })
                        );
                    },
                }}
                showSorterTooltip={false}
                scroll={{ x: "max-content" }}
                pagination={{
                    current: current,
                    pageSize: pageSize,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    showTotal: (total) => `${total} Total`,
                    showSizeChanger: Boolean(data?.totalResults),
                    total: data?.totalResults || 0,
                }}
            />
        </Space>
    );
};

export const DemandDealOperationEntitiesTable: FC = () => {
    const dispatch = useDispatch();
    const form = Form.useFormInstance();

    const { current, pageSize } = useSelector(selectDemandDealPageInfo);

    const data = useSelector(selectDemandDealResponse);
    const selectedRowKeys = useSelector(selectDemandDealSelectedRowKeys);
    const fieldsToEdit = Form.useWatch(BULK_OPERATION_FORM_ITEMS.FIELDS_TO_EDIT.name);

    const [triggerGetDemandDeals, { isFetching, error }] = useLazyGetSeatCtvNextDemandDealsQuery();

    useNotificationError(error);

    const { seatId } = useParams<{ seatId: string }>();

    const handleApiCall = (pageInfo: { current?: number; pageSize?: number }) => {
        dispatch(setDemandDealBulkOperationPageInfo(pageInfo));
        const { demandDealType, demandDealKeyword } = form.getFieldValue("filters") || {};

        triggerGetDemandDeals(
            {
                seatId: Number(seatId),
                page: pageInfo.current,
                max: pageInfo.pageSize,
                keyword: demandDealKeyword,
                type: demandDealType,
            },
            PREFER_CACHE_VALUE
        )
            .unwrap()
            .then((response) => dispatch(setDemandDealBulkOperationResponse({ response })));
    };

    const onClickRunFilters = () => {
        handleApiCall({ current: 1, pageSize });
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        handleApiCall({ current: pagination.current, pageSize: pagination.pageSize });
    };

    return (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <RunFiltersButton onClick={onClickRunFilters} entityTypeName="Demand Deals" hasRun={Boolean(data)} />
            <Table
                dataSource={data?.demandDeals || []}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                columns={getColumns(BulkOperationEntityType.DemandDeal, fieldsToEdit)}
                loading={isFetching}
                onChange={handleTableChange}
                rowKey="id"
                rowSelection={{
                    selectedRowKeys,
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys, selectedRows: (DemandDeal | undefined)[]) => {
                        const presentSelectedRows = selectedRows.filter(Boolean) as DemandDeal[];
                        dispatch(
                            setDemandDealBulkOperationSelections({ selectedRowKeys, selectedRows: presentSelectedRows })
                        );
                    },
                }}
                showSorterTooltip={false}
                scroll={{ x: "max-content" }}
                pagination={{
                    current: current,
                    pageSize: pageSize,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    showTotal: (total) => `${total} Total`,
                    showSizeChanger: Boolean(data?.totalResults),
                    total: data?.totalResults || 0,
                }}
            />
        </Space>
    );
};

export const OperationEntitiesTable: FC = () => {
    const entityType = Form.useWatch(BULK_OPERATION_FORM_ITEMS.ENTITY_TYPE.name);

    switch (entityType) {
        case BulkOperationEntityType.AdSource:
            return <AdSourceOperationEntitiesTable />;
        case BulkOperationEntityType.AdUnit:
            return <AdUnitOperationEntitiesTable />;
        case BulkOperationEntityType.BuyerDeal:
            return <BuyerDealOperationEntitiesTable />;
        case BulkOperationEntityType.DemandDeal:
            return <DemandDealOperationEntitiesTable />;
        default:
            return null;
    }
};
