import { FC, useEffect } from "react";
import { Form, Input } from "antd";
import { NA } from "@app/core/components/constants";
import { PUBLISHER_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { usePublisherFormInstance } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormInstance";

const MAX_CHARS = 36;

export const BillingCode: FC = () => {
    const { seat, form, mode } = usePublisherFormInstance();
    const extra = `Inherited value: ${seat?.defaultBillingCode || NA}`;

    useEffect((): void => {
        if (mode === "create") {
            form.setFieldValue(PUBLISHER_FORM_FIELDS.BILLING_CODE.name, seat?.defaultBillingCode);
        }
    }, [mode, seat, form]);

    return (
        <Form.Item
            extra={extra}
            name={PUBLISHER_FORM_FIELDS.BILLING_CODE.name}
            label={PUBLISHER_FORM_FIELDS.BILLING_CODE.label}
            rules={[
                { required: true, message: PUBLISHER_FORM_FIELDS.BILLING_CODE.rulesMessage },
                { max: MAX_CHARS, message: PUBLISHER_FORM_FIELDS.BILLING_CODE.rulesMaxCharsMessage },
            ]}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
            normalize={(value) => value?.trim()}
        >
            <Input placeholder={PUBLISHER_FORM_FIELDS.BILLING_CODE.placeholder} allowClear />
        </Form.Item>
    );
};
