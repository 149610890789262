import { AppSwitch } from "@app/core/routing";
import { AppVersionRefreshModal } from "@app/features/appVersionRefresh";
import { css } from "@emotion/css";
import { Layout } from "antd";
import { Pendo } from "../Pendo";
import { useWatchSeatPermissions } from "@app/core/auth/useWatchSeatPermissions";
import { useBuyerAuthContext, useSeatAuthContext } from "@app/core/auth";
import { useIframeCommunicator } from "@app/core/services/useIframeCommunicator";

export const IframeMain = () => {
    useIframeCommunicator();
    //INFO: Figure out Do we have buyer route here or only seat?
    useWatchSeatPermissions();
    const { context: seatContext, session: seatSession } = useSeatAuthContext();
    const { context: buyerContext, session: buyerSession } = useBuyerAuthContext();

    return (
        <Layout
            data-sdet="main"
            className={css`
                min-height: 100vh;
                overflow: hidden;
                width: 100%;
            `}
        >
            <Layout
                className={css`
                    position: relative;
                    min-height: 100vh;
                `}
            >
                <AppVersionRefreshModal />
                <AppSwitch />
            </Layout>
            <Pendo context={seatContext || buyerContext} session={seatContext ? seatSession : buyerSession} />
        </Layout>
    );
};
