import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    SeatAdSourcesListDisplayMode,
    selectIsBeta,
    selectSeatAdSourcesListDisplayMode,
    switchSeatAdSourcesListDisplayMode,
} from "./reducer";

interface UseSeatAdSourcesListViewRadio {
    activeDisplayMode: SeatAdSourcesListDisplayMode;
    isBeta: boolean;
    toggleDisplayMode: () => void;
}

export const useSeatAdSourcesListPage = (): UseSeatAdSourcesListViewRadio => {
    const dispatch = useAppDispatch();
    const activeDisplayMode = useAppSelector(selectSeatAdSourcesListDisplayMode);
    const isBeta = useAppSelector(selectIsBeta);

    const toggleDisplayMode = () => {
        dispatch(switchSeatAdSourcesListDisplayMode());
    };

    return {
        activeDisplayMode,
        isBeta,
        toggleDisplayMode,
    };
};
