import { FC } from "react";
import { css } from "@emotion/css";
import { Badge, Button, Col, Collapse, CollapseProps, Form, Row, Space, Typography } from "antd";
import { brandText2 } from "@rubicon/antd-components";
import { SeatAdSourcesAssigneeFilter } from "./SeatAdSourcesAssigneeFilter";
import { SeatAdSourcesLabelKeyFilter } from "./SeatAdSourcesLabelKeyFilter";
import { SeatAdSourcesLabelValueFilter } from "./SeatAdSourcesLabelValueFilter";
import { SeatAdSourcesPriorityFilter } from "./SeatAdSourcesPriorityFilter";
import { SeatAdSourcesRegionFilter } from "./SeatAdSourcesRegionFilter";
import { SeatAdSourcesStatusFilter } from "./SeatAdSourcesStatusFilter";
import { SeatAdSourcesTargetingFilter } from "./SeatAdSourcesTargetingFilter";
import { SeatAdSourcesTypeFilter } from "./SeatAdSourcesTypeFilter";
import { useSeatAdSourcesListFilters } from "./useSeatAdSourcesListFilters";

export const SeatAdSourcesListFilters: FC = () => {
    const { filterCount, handleClearFilters } = useSeatAdSourcesListFilters();

    const items: CollapseProps["items"] = [
        {
            key: "filters",
            label: (
                <Space>
                    <Typography.Text>Filters</Typography.Text>
                    <Badge count={filterCount} style={{ backgroundColor: brandText2 }} />
                </Space>
            ),
            extra: (
                <Button size="small" onClick={handleClearFilters} data-sdet="clear">
                    Clear
                </Button>
            ),
            children: (
                <Form layout="vertical">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12} xl={8}>
                            <SeatAdSourcesPriorityFilter />
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <SeatAdSourcesTypeFilter />
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <SeatAdSourcesRegionFilter />
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <SeatAdSourcesStatusFilter />
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <SeatAdSourcesTargetingFilter />
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <SeatAdSourcesAssigneeFilter />
                        </Col>
                        <Col xs={24} lg={24} sm={24}>
                            <Space.Compact block direction="horizontal">
                                <SeatAdSourcesLabelKeyFilter />
                                <SeatAdSourcesLabelValueFilter />
                            </Space.Compact>
                        </Col>
                    </Row>
                </Form>
            ),
        },
    ];

    return (
        <div
            data-sdet="seat-ad-sources-table-filters"
            className={css`
                .ant-space-compact {
                    > :first-child {
                        width: 30%;
                    }
                    > :last-child {
                        width: 70%;
                    }
                }
            `}
        >
            <Collapse collapsible="header" defaultActiveKey="filters" items={items}></Collapse>
        </div>
    );
};
