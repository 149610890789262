import { useParams } from "react-router-dom";
import { LabelValue, useGetPublisherInheritedLabelsQuery } from "@magnite/client-streaming-platform";
import { usePublisherFormInstance } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormInstance";

export interface UsePublisherInheritedLabels {
    isInheritedLabelsShown: boolean;
    labels: LabelValue[];
}

export interface UsePublisherInheritedLabelsProps {
    labelType: "seat" | "internal";
}

export const usePublisherInheritedLabels = ({
    labelType,
}: UsePublisherInheritedLabelsProps): UsePublisherInheritedLabels => {
    const { publisherId } = useParams<{ publisherId: string }>();
    const { mode } = usePublisherFormInstance();
    const isEditMode = mode === "edit";

    const { data = [] } = useGetPublisherInheritedLabelsQuery(
        { publisherId: publisherId, includeMine: false, labelType: labelType },
        { skip: !isEditMode || !publisherId }
    );
    return {
        labels: data,
        isInheritedLabelsShown: isEditMode,
    };
};
