import { AdSourceListItem } from "@app/core/services";
import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";

export const getPriceTypeToBeDisplayed = (adSource: AdSourceListItem) => {
    const adSourceTypeId = adSource.type.id;

    if (
        adSourceTypeId === AdSourceTypeIds.AUCTION_PRICE ||
        adSourceTypeId === AdSourceTypeIds.OPEN_AUCTION ||
        adSourceTypeId === AdSourceTypeIds.MARKETPLACE ||
        adSourceTypeId === AdSourceTypeIds.PRE_BID_AD_SOURCE ||
        adSourceTypeId === AdSourceTypeIds.LINEAR_AUCTION_PRICE
    ) {
        return "Auction";
    }

    if (adSourceTypeId === AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE) {
        return "Dynamic";
    }

    if (adSourceTypeId === AdSourceTypeIds.PRE_BID_DEALS || adSourceTypeId === AdSourceTypeIds.FALLBACK_TAG) {
        return "--";
    }

    return "Fixed";
};
