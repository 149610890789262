import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";

export const isAssignedAdvertiserVisible = (adSourceTypeId: number) => {
    switch (adSourceTypeId) {
        case AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED:
        case AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED:
        case AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE:
        case AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED:
        case AdSourceTypeIds.FALLBACK_TAG:
            return true;
        default:
            return false;
    }
};
