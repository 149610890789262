import { FC } from "react";
import { HelpTooltip, SelectAllDropdownRender } from "@app/core/components";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { DealsHelpKeys } from "@app/features/deals/constants";
import { useInternalStatusFilter } from "./useInternalStatusFilter";

export const InternalStatusFilter: FC = () => {
    const { options, value, isFetching, handleChange, areAllOptionsSelected, toggleSelectDeselectAll } =
        useInternalStatusFilter();

    return (
        <FilterSelect
            label={
                <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealStatus} popover={true}>
                    Internal Status
                </HelpTooltip>
            }
            isLoading={isFetching}
            onChange={handleChange}
            options={options}
            value={value}
            dataSdet="status-filter"
            mode="multiple"
            placeholder="All"
            dropdownRender={(menu) => (
                <SelectAllDropdownRender
                    menu={menu}
                    onClick={toggleSelectDeselectAll}
                    areAllOptionsSelected={areAllOptionsSelected}
                />
            )}
        />
    );
};
