import { RuleObject } from "antd/lib/form";

export const useLiveEventsFormItemName = () => {
    const rules = [
        { required: true, message: "Name is required" },
        { validator: minLengthValidator, message: "Name cannot be only spaces" },
        { validator: maxLengthValidator, message: "Name cannot be longer than 70 characters" },
    ];

    return { rules };
};

const minLengthValidator = (_: RuleObject, name: string) => {
    if (name && !name.trim()) {
        return Promise.reject();
    }
    return Promise.resolve();
};

const maxLengthValidator = (_: RuleObject, name: string) => {
    if (name.length > 70) {
        return Promise.reject();
    }
    return Promise.resolve();
};
