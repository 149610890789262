import { useState } from "react";
import { RuleObject } from "antd/lib/form";
import { MIN_RUN_TIME_IN_HOURS, MAX_RUN_TIME_IN_HOURS } from "../../../constants";
import { DurationValue } from "../../InventoryLiveEventsForm";

export const useLiveEventsFormItemDuration = () => {
    // state to indicate whether the info circle should be red instead of blue
    const [isDurationError, setIsDurationError] = useState<boolean>(false);

    const rules = [
        { required: true, message: "Duration is required" },
        { validator: getMaxDurationValidator(setIsDurationError) },
        { validator: minHoursValidator, message: `This event can't be shorter than ${MIN_RUN_TIME_IN_HOURS} hour` },
        { validator: maxMinutesValidator, message: "Minutes must be less than 60" },
        { validator: minMinutesValidator, message: "Minutes must be between 0 and 59" },
    ];

    return { rules, isDurationError };
};

const getMaxDurationValidator =
    (setIsDurationError: React.Dispatch<React.SetStateAction<boolean>>) => (_: RuleObject, duration: DurationValue) => {
        if (
            (duration && Number(duration.hours) > MAX_RUN_TIME_IN_HOURS) ||
            (Number(duration.hours) === MAX_RUN_TIME_IN_HOURS && Number(duration.minutes) > 0)
        ) {
            setIsDurationError(true);
            return Promise.reject();
        }

        setIsDurationError(false);
        return Promise.resolve();
    };

const minHoursValidator = (_: RuleObject, duration: DurationValue) => {
    if (duration && Number(duration.hours) < MIN_RUN_TIME_IN_HOURS) {
        return Promise.reject();
    }
    return Promise.resolve();
};

const maxMinutesValidator = (_: RuleObject, duration: DurationValue) => {
    if (duration && Number(duration.minutes) > 59) {
        return Promise.reject();
    }
    return Promise.resolve();
};

const minMinutesValidator = (_: RuleObject, duration: DurationValue) => {
    if (duration && Number(duration.minutes) < 0) {
        return Promise.reject();
    }
    return Promise.resolve();
};
