import { Col, Typography } from "antd";
import { useCpmInput } from "./useCpmInput";
import { AdSource } from "@app/core/services";
import { OverrideFloorCpmEligibleAdSourceTypeIds } from "@app/features/seatAdSources/constants";

export const OverrideFloorCpmInput = ({ adSource }: { adSource: AdSource }) => {
    const { fieldLabel, value } = useCpmInput(adSource);

    const isVisible = OverrideFloorCpmEligibleAdSourceTypeIds.has(adSource.type.id);
    if (!isVisible) {
        return null;
    }

    return (
        <Col xs={12} xl={8}>
            <Typography.Text strong>{fieldLabel}</Typography.Text>
            <Typography.Paragraph>{value}</Typography.Paragraph>
        </Col>
    );
};
