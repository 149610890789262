import { FC } from "react";
import { Button } from "antd";
import { useUserAccess } from "@app/core/auth";
import { PageHeader } from "@ant-design/pro-layout";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";

const TargetingPageHeader: FC = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();
    const { canEditSeat } = useUserAccess();

    const handleCreateTargeting = (): void => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING_CREATE(seatId));
    };

    return (
        <PageHeader
            ghost={false}
            title={<span data-sdet="targeting-header-title">Targeting</span>}
            extra={
                canEditSeat && (
                    <Button type="primary" onClick={handleCreateTargeting} data-sdet="add-targeting-button">
                        Add
                    </Button>
                )
            }
        />
    );
};
export default TargetingPageHeader;
