import { FC } from "react";
import { Row, Col } from "antd";
import { EstimatedAdBreakCount } from "./EstimatedAdBreakCount";
import { EstimatedTotalAvailableAdMinutes } from "./EstimatedTotalAvailableAdMinutes/EstimatedTotalAvailableAdMinutes";
import { EstimatedImpressionOpportunities } from "./EstimatedImpressionOpportunities";
import { EstimatedAverageConcurrentViewers } from "./EstimatedAverageConcurrentViewers";
import { EstimatedPeakConcurrentViewers } from "./EstimatedPeakConcurrentViewers";

export const LiveEventsFormEstimatesSection: FC = () => {
    return (
        <>
            <Row gutter={24}>
                <Col span={8}>
                    <EstimatedImpressionOpportunities />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <EstimatedAverageConcurrentViewers />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <EstimatedPeakConcurrentViewers />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <EstimatedAdBreakCount />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <EstimatedTotalAvailableAdMinutes />
                </Col>
            </Row>
        </>
    );
};
