import { GoToEntityLink } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Col, Row, Typography } from "antd";
import { FC } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { HelpKeysButton } from "@app/core/components/HelpKeysDrawer";
import { uri } from "@rubicon/utils";
import { IndustryDetails } from "@app/core/services";
import { ClassificationsIndustryDetailsActions } from "./ClassificationsIndustryDetailsActions";
import { getUrlSegment } from "@app/core/utils/urlSegment";

export const CLASSIFICATIONS_INDUSTRY_DETAILS_DRAWER_TITLE_SDET = "classifications-industry-details-drawer-title";
export const CLASSIFICATIONS_INDUSTRY_DETAILS_DRAWER_TITLE_NAME_SDET =
    "classifications-industry-details-drawer-title-name";
interface Props {
    industry?: IndustryDetails;
}

export const ClassificationsIndustryDetailsDrawerTitle: FC<Props> = ({ industry }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const detailPagePathname = ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_DETAILS_PAGE(
        seatId,
        industry?.id as number
    );
    const inIframe = window.self !== window.top;
    const { origin, pathname } = window.location;
    const url = new URL(`${pathname}#${detailPagePathname}`, origin);
    const copyUrl = inIframe ? getUrlSegment(url.hash) : url.href;
    const { search: queryString } = useLocation();
    const history = useHistory();

    return industry ? (
        <>
            <Row
                data-sdet={CLASSIFICATIONS_INDUSTRY_DETAILS_DRAWER_TITLE_SDET}
                gutter={[0, 16]}
                style={{ marginRight: "1rem" }}
            >
                <Col span={24}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Row gutter={[8, 4]} align="middle">
                                <Col>
                                    <Typography.Title
                                        data-sdet={CLASSIFICATIONS_INDUSTRY_DETAILS_DRAWER_TITLE_NAME_SDET}
                                        ellipsis={{
                                            tooltip: { title: `Industry: ${industry.name}`, placement: "bottom" },
                                        }}
                                        level={5}
                                        style={{
                                            margin: 0,
                                            maxWidth: "18rem",
                                        }}
                                    >
                                        Industry: {industry.name}
                                    </Typography.Title>
                                </Col>
                                <Col>
                                    <GoToEntityLink
                                        entity="Industry"
                                        linkTo={ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_DETAILS_PAGE(
                                            seatId,
                                            industry.id
                                        )}
                                        copyUrl={copyUrl}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <HelpKeysButton
                                onClick={() => {
                                    const nextQueryString = uri.setSearchParam(queryString, "drawer", "definitions");
                                    history.push({ search: nextQueryString });
                                }}
                                buttonName="Industries"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <ClassificationsIndustryDetailsActions
                        isDrawer
                        industry={industry}
                        onClickChangeHistory={() => {
                            const nextQueryString = uri.setSearchParam(queryString, "drawer", "history");
                            history.push({ search: nextQueryString });
                        }}
                    />
                </Col>
            </Row>
        </>
    ) : null;
};
