import { FC } from "react";
import { format } from "@rubicon/utils";
import { Col, Form, Row } from "antd";
import { useUserAccess } from "@app/core/auth";
import { AdSourceProperties, BaseOption, PacingDeliverySchedule } from "@app/core/services";
import { AD_SOURCE_FIELDS, TARGET_DATE_AND_DELIVERY_PERCENTAGE_LABEL } from "@app/features/seatAdSources/constants";
import {
    isDailyImpressionCapEnabledEligible,
    isDailyImpressionCapEligible,
    isDeliveryPercentageEligible,
    isFallbackOpportunityEstimateEligible,
    isPacingRedistributionEligible,
    isPacingPeriodEligible,
    isTargetDateEligible,
    isPacingTypeEligible,
    formatTargetDateAndDeliveryPercentage,
    getPacingPeriodText,
    getIsDailyImpressionCapEnabled,
    isPacingDeliveryScheduleEnabled,
} from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { ReadOnlyPacingDeliverySchedule } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections";

const {
    DAILY_IMPRESSION_CAP,
    DAILY_IMPRESSION_CAP_ENABLED,
    FALLBACK_OPPORTUNITY,
    PACING_PERIOD,
    PACING_REDISTRIBUTION,
    PACING_TYPE,
} = AD_SOURCE_FIELDS;

interface Props {
    type: BaseOption;
    pacingType: AdSourceProperties | null;
    redistributePacing: boolean | null;
    dailyImpression: number | null;
    dailyRequestOpportunityFallback: number | null;
    customCurveImps: number | null;
    customCurveStartDate: string | null;
    bookingVolume: number | null;
    showFallbackOpportunity: boolean;
    pacingDeliveryScheduleEnabled: boolean;
    pacingDeliverySchedules: PacingDeliverySchedule[];
    timeZoneCode: string;
}

export const AdSourcePacing: FC<Props> = ({
    dailyImpression,
    dailyRequestOpportunityFallback,
    redistributePacing,
    pacingType,
    customCurveStartDate,
    customCurveImps,
    bookingVolume,
    type,
    showFallbackOpportunity,
    pacingDeliveryScheduleEnabled,
    pacingDeliverySchedules,
    timeZoneCode,
}) => {
    const { seatAccessFlags } = useUserAccess();
    const adSourceTypeId = type?.id;
    const pacingTypeId = pacingType?.id;
    const isDailyImpressionCapEnabled =
        isDailyImpressionCapEnabledEligible(adSourceTypeId, pacingTypeId) &&
        getIsDailyImpressionCapEnabled(dailyImpression);
    const showPacingType = isPacingTypeEligible(adSourceTypeId);
    const showPacingPeriod = isPacingPeriodEligible(adSourceTypeId, pacingTypeId);
    const showPacingRedistribution = isPacingRedistributionEligible(adSourceTypeId, pacingTypeId);
    const showDailyImpressionCapEnabled = isDailyImpressionCapEnabledEligible(adSourceTypeId, pacingTypeId);
    const showDailyImpressionCap = isDailyImpressionCapEligible(
        adSourceTypeId,
        pacingTypeId,
        isDailyImpressionCapEnabled
    );
    const showFallbackOpportunityEstimate = isFallbackOpportunityEstimateEligible(
        adSourceTypeId,
        pacingTypeId,
        showFallbackOpportunity
    );
    const showTargetDateAndDeliveryPercentage =
        isTargetDateEligible(adSourceTypeId, pacingTypeId) &&
        isDeliveryPercentageEligible(adSourceTypeId, pacingTypeId);
    const showPacingDeliverySchedule = isPacingDeliveryScheduleEnabled(
        adSourceTypeId,
        pacingTypeId,
        pacingDeliveryScheduleEnabled,
        seatAccessFlags.hasPacingDeliverySchedulesAccess
    );

    return (
        <div data-sdet="ad-source-pacing">
            {(showPacingType || showPacingPeriod) && (
                <Row>
                    {showPacingType && (
                        <Col xs={12} xl={8}>
                            <Form.Item label={PACING_TYPE.label}>{pacingType?.name}</Form.Item>
                        </Col>
                    )}
                    {showPacingPeriod && (
                        <Col xs={12} xl={8}>
                            <Form.Item label={PACING_PERIOD.label}>{getPacingPeriodText(pacingTypeId)}</Form.Item>
                        </Col>
                    )}
                </Row>
            )}
            {(showPacingRedistribution ||
                showDailyImpressionCapEnabled ||
                showDailyImpressionCap ||
                showFallbackOpportunityEstimate) && (
                <Row>
                    {showPacingRedistribution && (
                        <Col xs={12} xl={8}>
                            <Form.Item label={PACING_REDISTRIBUTION.label}>
                                {format.asYesOrNo(redistributePacing)}
                            </Form.Item>
                        </Col>
                    )}
                    {showDailyImpressionCapEnabled && (
                        <Col xs={12} xl={8}>
                            <Form.Item label={DAILY_IMPRESSION_CAP_ENABLED.label}>
                                {format.asYesOrNo(isDailyImpressionCapEnabled)}
                            </Form.Item>
                        </Col>
                    )}
                    {showDailyImpressionCap && (
                        <Col xs={12} xl={8}>
                            <Form.Item label={DAILY_IMPRESSION_CAP.label}>{dailyImpression}</Form.Item>
                        </Col>
                    )}
                    {showFallbackOpportunityEstimate && (
                        <Col xs={12} xl={8}>
                            <Form.Item label={FALLBACK_OPPORTUNITY.label}>
                                {format.asSelf(dailyRequestOpportunityFallback)}
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            )}
            {showTargetDateAndDeliveryPercentage && (
                <Row>
                    <Col xs={12} xl={8}>
                        <Form.Item label={TARGET_DATE_AND_DELIVERY_PERCENTAGE_LABEL}>
                            {formatTargetDateAndDeliveryPercentage(
                                customCurveImps,
                                bookingVolume,
                                customCurveStartDate
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            )}
            {showPacingDeliverySchedule && (
                <Row>
                    <Col sm={24}>
                        <ReadOnlyPacingDeliverySchedule
                            pacingDeliverySchedules={pacingDeliverySchedules}
                            timeZoneCode={timeZoneCode}
                        />
                    </Col>
                </Row>
            )}
        </div>
    );
};
