import { NONE } from "@app/core/components/constants";
import { Label, LabelValue } from "@magnite/client-streaming-platform";
import { formatDateInUtc } from "@app/core/utils";
import { Form, Typography } from "antd";
import { ColProps } from "antd/es/grid";
import { FC } from "react";

export const CLASSIFICATIONS_LABEL_DETAILS_CONTENT_SDET = "classifications-label-details-content";

interface Props {
    label?: Label;
    labelValues?: LabelValue[];
    wrapperCol?: ColProps;
}

export const ClassificationsLabelDetailsContent: FC<Props> = ({
    label,
    labelValues,
    wrapperCol = { xs: 24, md: 12 },
}) => {
    const isDistributionGroup: boolean = !!label?.isDistributionGroup;
    return label && labelValues ? (
        <Form layout="vertical" data-sdet={CLASSIFICATIONS_LABEL_DETAILS_CONTENT_SDET} wrapperCol={wrapperCol}>
            <Form.Item label="Label Key">
                <Typography.Text>{label.key}</Typography.Text>
            </Form.Item>
            <Form.Item label="Multi-Instance" hidden={isDistributionGroup}>
                <Typography.Text>{label.multiValue ? "Yes" : "No"}</Typography.Text>
            </Form.Item>
            <Form.Item label="Waterfall Pre-Filter" hidden={isDistributionGroup}>
                <Typography.Text>{label.enablePrefilter ? "Yes" : "No"}</Typography.Text>
            </Form.Item>
            <Form.Item label="Label Visibility" hidden={isDistributionGroup}>
                <Typography.Text>{label.isPublic ? "Public" : "Internal"}</Typography.Text>
            </Form.Item>
            <Form.Item label="Inventory Distribution Group">
                <Typography.Text>{label.isDistributionGroup ? "Yes" : "No"}</Typography.Text>
            </Form.Item>
            <Form.Item label="Values">
                <Typography.Text>
                    {labelValues.length ? labelValues.map(({ value }) => value).join(", ") : NONE}
                </Typography.Text>
            </Form.Item>
            <Form.Item label="Date Created">
                <Typography.Text>{formatDateInUtc(label.creationDate)}</Typography.Text>
            </Form.Item>
            <Form.Item label="Last Updated">
                <Typography.Text>{formatDateInUtc(label.updateDate)}</Typography.Text>
            </Form.Item>
        </Form>
    ) : null;
};
