import { FC, Fragment } from "react";
import { Row, Col, Button, Tooltip } from "antd";
import { ITargetingIncludeExclude, Targeting, TargetingIncludeExclude } from "@app/core/services/console";
import { ReadOnlyOperation } from "./ReadOnlyOperation";
import { TargetingBlockCollapse } from "../TargetingBlockCollapse";
import { Conditions, Operations, TargetingFormKeys } from "../constants";
import { ReadOnlyIncludeTargetingCollapse } from "./ReadOnlyTargetingCollapseInclude";
import { ReadOnlyExcludeTargetingCollapse } from "../ReadOnlyTargeting/ReadOnlyTargetingCollapseExclude";
import { DeleteOutlined } from "@ant-design/icons";
import { OperationSelect } from "../CreateTargeting/OperationSelect";
import { ReadOnlyTargetingBlockHeader } from "./ReadOnlyTargetingBlockHeader";
import { getConditionColor } from "./ReadOnlyCategoryHeader";
import Typography from "antd/lib/typography";

const { Text } = Typography;

export const IncludeConditionBlockHeader: FC<{ operation: Operations }> = ({ operation }) => {
    return (
        <>
            <Text style={{ color: getConditionColor(Conditions.Includes), fontWeight: 500 }}>INCLUDE </Text>
            <Text>Requests that match </Text>
            <Text style={{ fontWeight: 400 }}>{operation === Operations.All ? "ALL OF" : "ANY OF"}</Text>
            <Text> the following criteria...</Text>
        </>
    );
};

export const ExcludeConditionBlockHeader: FC<{ operation: Operations }> = ({ operation }) => {
    return (
        <>
            <Text style={{ color: getConditionColor(Conditions.Excludes) }}>EXCLUDE </Text>
            <Text>Requests that match </Text>
            <Text style={{ fontWeight: 400 }}>{operation === Operations.All ? "ALL OF" : "ANY OF"}</Text>
            <Text> the following criteria...</Text>
        </>
    );
};

export const getSelectedTargetingDimensionsCount = (targetingDimensions: TargetingIncludeExclude | null) => {
    if (!targetingDimensions) {
        return 0;
    }
    const res = Object.entries(targetingDimensions).reduce(
        (newObject: ITargetingIncludeExclude, [key, value]: [key: string, value: unknown[]]) =>
            value && Object.values(value).length > 0 ? ((newObject[key] = value), newObject) : newObject,
        {}
    );

    return Object.values(res).length;
};

export const getSelectedTargetingDimensionsCountExcludes = (targetingDimensions: TargetingIncludeExclude | null) => {
    const count = getSelectedTargetingDimensionsCount(targetingDimensions);

    if (targetingDimensions?.pmpDealIdTargets?.includes("*")) {
        return count - 1;
    }

    return count;
};

interface Props {
    operation: Operations;
    targetingBlocks: Targeting[];
    formKey: TargetingFormKeys;
    wrappedCollapse?: boolean;
    onClickDelete?: (targetingBlockId: number) => void;
    onOperationChange?: (operation: Operations) => void;
}

export const ReadOnlyTargetingBlocks: FC<Props> = ({
    targetingBlocks,
    operation,
    formKey,
    wrappedCollapse = true,
    onClickDelete,
    onOperationChange,
}) => {
    if (!targetingBlocks.length) {
        return null;
    }

    const ReadOnlyTargetingBlock = ({ targetingBlock }: { targetingBlock: Targeting }) => {
        const targetingIncludes = targetingBlock.include;
        const includesDimensionCount = getSelectedTargetingDimensionsCount(targetingIncludes);

        const targetingExcludes = targetingBlock.exclude;
        const excludesDimensionCount = getSelectedTargetingDimensionsCountExcludes(targetingExcludes);

        return (
            <>
                {includesDimensionCount > 0 && (
                    <>
                        <IncludeConditionBlockHeader operation={operation} />
                        <ReadOnlyIncludeTargetingCollapse targetingBlock={targetingBlock} operation={operation} />
                    </>
                )}
                {includesDimensionCount > 0 && excludesDimensionCount > 0 && <Text>And... </Text>}
                {excludesDimensionCount > 0 && (
                    <>
                        <ExcludeConditionBlockHeader operation={operation} />
                        <ReadOnlyExcludeTargetingCollapse targetingBlock={targetingBlock} operation={operation} />
                    </>
                )}
            </>
        );
    };

    return (
        <>
            {targetingBlocks.map((targetingBlock, index) => (
                <Fragment key={`targeting-block-${targetingBlock.id}`}>
                    <Row>
                        <Col xs={24}>
                            {wrappedCollapse ? (
                                <TargetingBlockCollapse
                                    header={
                                        <ReadOnlyTargetingBlockHeader
                                            index={index}
                                            name={targetingBlock.name}
                                            blockCount={targetingBlocks.length}
                                            formKey={formKey}
                                        />
                                    }
                                    extra={
                                        onClickDelete && (
                                            <Tooltip title="Delete Targeting Group">
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onClickDelete(targetingBlock.id);
                                                    }}
                                                    icon={<DeleteOutlined />}
                                                />
                                            </Tooltip>
                                        )
                                    }
                                >
                                    <ReadOnlyTargetingBlock targetingBlock={targetingBlock} />
                                </TargetingBlockCollapse>
                            ) : (
                                <ReadOnlyTargetingBlock targetingBlock={targetingBlock} />
                            )}
                        </Col>
                    </Row>
                    {index !== targetingBlocks.length - 1 && (
                        <Row>
                            <Col xs={24}>
                                {onOperationChange ? (
                                    <div style={{ width: "fit-content", margin: "16px 0" }}>
                                        <OperationSelect
                                            value={operation}
                                            onChange={(operation) => onOperationChange(operation)}
                                        />
                                    </div>
                                ) : (
                                    <div style={{ margin: 16 }}>
                                        <ReadOnlyOperation operation={operation} />
                                    </div>
                                )}
                            </Col>
                        </Row>
                    )}
                </Fragment>
            ))}
        </>
    );
};
