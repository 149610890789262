import { MarketplaceInfo } from "@app/core/services";
import {
    DealObjectForm,
    DemandConfigurationForm,
} from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";
import { Form } from "antd";
import { useState } from "react";
import moment from "moment-timezone";
import { RuleObject } from "antd/es/form";
import { DEAL_FORM_FIELDS } from "../../../../../constants";

export const useEndDatePicker = () => {
    const startDate = Form.useWatch<moment.Moment | null>(["dealUnderEdit", "startTime"]);
    const endDate = Form.useWatch<moment.Moment | null>(["dealUnderEdit", "endTime"]);
    const dealType = Form.useWatch<MarketplaceInfo["deal"]["dealType"]>(["dealUnderEdit", "dealType"]);
    const isRequired = dealType?.name === "Programmatic Guaranteed";
    const [isSelectingEndDate, setIsSelectingEndDate] = useState(false);
    const { setFieldValue, validateFields } = Form.useFormInstance<DealObjectForm>();
    const { updateSyncedFields } = useSyncedFieldsProducer();

    const endDateValidator =
        (startDate: DemandConfigurationForm["startDate"], endDate: DemandConfigurationForm["endDate"]) =>
        (_: RuleObject) => {
            if (isRequired && !endDate) {
                return Promise.reject(`${DEAL_FORM_FIELDS.END_DATE.label} is Required`);
            }
            if (startDate && startDate.isAfter(endDate)) {
                return Promise.reject(
                    `${DEAL_FORM_FIELDS.END_DATE.label} can not be earlier than ${DEAL_FORM_FIELDS.START_DATE.label}`
                );
            }
            return Promise.resolve();
        };

    const rules = [{ validator: endDateValidator(startDate, endDate) }];

    const onSelectNoEndDate = () => {
        setIsSelectingEndDate((v) => !v);
        setFieldValue(["dealUnderEdit", "endTime"], null);
        validateFields(["dealUnderEdit", "endTime"], { recursive: true });
        if (endDate) {
            updateSyncedFields({ endDate: null });
        }
    };

    const onChange = (endDate: moment.Moment | null) => {
        updateSyncedFields({ endDate });
    };

    return { isSelectingEndDate, onSelectNoEndDate, rules, isRequired, onChange };
};
