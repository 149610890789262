import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useSeatAuthContext } from "@app/core/auth";
import { Seat, GetSeatAllDealsParams } from "@app/core/services/console";
import { skipToken } from "@reduxjs/toolkit/query";
import {
    clearDealHealthFilters,
    DealHealthState,
    selectDealHealthFilterCount,
    selectDealHealthFilters,
    selectDealHealthLiveStats,
    selectDealHealthPagination,
    selectDealHealthSearchFilter,
    selectDealHealthSort,
} from "@app/features/deals/DealHealthPage/deprecated/reducer";
import { LabeledValue } from "antd/lib/select";

interface UseDealHealthTableFilters {
    filterCount: number;
    handleClearFilters: () => void;
    seatAllDealHealthParams: typeof skipToken | GetSeatAllDealsParams;
    liveStats: boolean;
}

export const getFilter = (filter: LabeledValue | null) => {
    if (filter) {
        return String(filter.value);
    }
};

export const getFilterMultiSelects = (filter: LabeledValue[] | null) => {
    if (filter && filter.length > 0) {
        return filter.map((filter) => String(filter.value));
    }
};

const getSeatAllDealHealthParams = (
    context: Seat | null,
    filters: DealHealthState["filters"],
    sort: DealHealthState["sort"],
    pagination: DealHealthState["pagination"],
    searchFilter: DealHealthState["searchFilter"]
) => {
    if (!context) {
        return skipToken;
    }

    return {
        searchKeyword: searchFilter?.searchKeyword,
        maxResults: pagination?.maxResults,
        page: pagination?.page,
        orderBy: sort?.orderBy,
        isAscending: sort?.asc,
        filterDealType: getFilterMultiSelects(filters.filterDealType),
        pricingType: getFilter(filters.pricingType),
        filterStatus: getFilterMultiSelects(filters.status),
        seatId: context.id,
        filterPriority: getFilterMultiSelects(filters.filterPriorities),
        filterDsp: getFilterMultiSelects(filters.filterDsp),
    };
};

export const useDealHealthTableFilters = (): UseDealHealthTableFilters => {
    const { context } = useSeatAuthContext();
    const dispatch = useAppDispatch();
    const dealHealthFilterCount = useAppSelector(selectDealHealthFilterCount);
    const dealHealthFilters = useAppSelector(selectDealHealthFilters);
    const dealHealthSort = useAppSelector(selectDealHealthSort);
    const dealHealthPagination = useAppSelector(selectDealHealthPagination);
    const dealHealthSearchFilter = useAppSelector(selectDealHealthSearchFilter);
    const liveStats = useAppSelector(selectDealHealthLiveStats);

    const handleClearFilters = () => {
        dispatch(clearDealHealthFilters());
    };

    return {
        liveStats,
        filterCount: dealHealthFilterCount,
        handleClearFilters,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        seatAllDealHealthParams: getSeatAllDealHealthParams(
            context,
            dealHealthFilters,
            dealHealthSort,
            dealHealthPagination,
            dealHealthSearchFilter
        ),
    };
};
