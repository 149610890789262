import { FC, useState } from "react";
import { Col, Form, Input, Row, Typography } from "antd";
import { FORM_ITEMS, MAX_RUN_TIME_IN_HOURS } from "../../../constants";
import { useLiveEventsFormItemDuration } from "./useLiveEventsFormItemDuration";
import { InfoCircleFilled } from "@ant-design/icons";
import { blue6, red5 } from "@rubicon/antd-components";
import { DurationValue } from "../../InventoryLiveEventsForm";

interface DurationInputProps {
    value?: DurationValue;
    onChange?: (value: DurationValue) => void;
}

const DurationInput: React.FC<DurationInputProps> = ({ value, onChange }) => {
    const [hours, setHours] = useState<string>(value?.hours ?? "");
    const [minutes, setMinutes] = useState<string>(value?.minutes ?? "");

    const handleChange = (type: "hours" | "minutes", value: string) => {
        if (onChange) {
            if (type === "hours") {
                setHours(value);
                onChange({ hours: value, minutes });
            } else {
                setMinutes(value);
                onChange({ hours, minutes: value });
            }
        }
    };
    return (
        <Row gutter={[8, 8]}>
            <Col span={12}>
                <Input
                    value={hours}
                    onChange={(e) => handleChange("hours", e.target.value)}
                    suffix="hours"
                    placeholder="e.g. 8"
                />
            </Col>
            <Col span={12}>
                <Input
                    value={minutes}
                    onChange={(e) => handleChange("minutes", e.target.value)}
                    suffix="mins"
                    placeholder="e.g. 59"
                />
            </Col>
        </Row>
    );
};

export const LiveEventsFormItemDuration: FC = () => {
    const { rules, isDurationError } = useLiveEventsFormItemDuration();

    return (
        <>
            <Form.Item
                required
                name={FORM_ITEMS.DURATION.name}
                label={FORM_ITEMS.DURATION.label}
                dependencies={[FORM_ITEMS.START_DATE.name]}
                rules={rules}
                style={{ marginBottom: "8px" }}
            >
                <DurationInput />
            </Form.Item>
            <Row style={{ marginBottom: "8px" }}>
                <InfoCircleFilled
                    style={{
                        color: isDurationError ? red5 : blue6,
                        marginRight: 4,
                    }}
                />
                <Typography.Text type="secondary">
                    The event can&apos;t be longer than {MAX_RUN_TIME_IN_HOURS} hours
                </Typography.Text>
            </Row>
        </>
    );
};
