import { BaseOption } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { AdSourceTypeNames, DEFAULT_ENRICHMENT_COST_CURRENCY_CODE } from "@app/features/seatAdSources/constants";
import {
    EnrichmentCostSectionInnerCard,
    useAdSourceEnrichmentCost,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections";
import {
    calcFinalEc,
    calcFinalCpmWithEcAndDac,
    calcFinalizedCpm,
    convertEnrichmentCostByCurrencyRate,
    getAudienceEnrichmentCost,
    getAudiencesFromTargetingForm,
    getSegmentsFromTargetingForm,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/utils";
import { FC } from "react";
import { useCurrencyOptions } from "../../../useCurrencyOptions";
import { DemandAcquisitionCostFormItem } from "./DemandAcquisitionCostFormItem";
import { useSeatAuthContext } from "@app/core/auth";

export const AdSourceEnrichmentCostSection: FC = () => {
    const { context } = useSeatAuthContext();
    const currencyType = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.CURRENCY]);
    const { optionsByValue } = useCurrencyOptions();

    const currencyCode = String(optionsByValue[currencyType]?.label || DEFAULT_ENRICHMENT_COST_CURRENCY_CODE);
    const dealName = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.NAME]);
    const adSourceCpm =
        useAppSelector((state) => Number(state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_PRICE])) ||
        0;
    const adSourceFloorTypeId = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_FLOOR_TYPE]
    );
    const dealPriceModelId = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.PRICE_MODEL]);
    const adSourceDemandAcquisitionCost = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST]
    );
    const adSourceDemandAcquisitionCostModelId = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST_MODEL]
    );
    const dealTypeId = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);
    const dealType = { name: AdSourceTypeNames[dealTypeId], id: dealTypeId } as BaseOption;
    const adSourceLevelTargeting = useAppSelector((state) => state.targeting.targetingFormsByKey.deal.targetingBlocks);
    const dealAdditionalTargeting = useAppSelector(
        (state) => state.targeting.targetingFormsByKey.dealAdditionalTargeting.targetingBlocks
    );
    const dealRate = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.RATE]);
    const dealLevelAudiences = getAudiencesFromTargetingForm(dealAdditionalTargeting);
    const dealLevelSegments = getSegmentsFromTargetingForm(dealAdditionalTargeting);

    // TODO: `useAdSourceEnrichmentCost` was created for the Ad Source form, and is hacky to use here - note the `marketplaceInfoList: []` arg.
    // Don't use any deal level props from useAdSourceEnrichmentCost as a result, and create a better abstraction for the deals form
    const {
        adSourceConversionRate,
        adSourceEc,
        adSourceLevelAudiences,
        adSourceLevelSegments,
        isDrawerOpen,
        isRangedCost,
        setIsDrawerOpen,
        shouldUseAdSourceValues,
    } = useAdSourceEnrichmentCost({
        adSourceCurrencyCode: currencyCode,
        adSourceCpm: adSourceCpm,
        adSourceType: dealType,
        adSourceLevelTargeting,
        adSourceDemandAcquisitionCost: Number(adSourceDemandAcquisitionCost || 0),
        adSourceDemandAcquisitionCostModelId,
        adSourceFloorTypeId: 0, // Used to determine if we should use Ad Source values - passing 0 so we never do/shouldUseAdSourceValues always returns false
        marketplaceInfoList: [], // Deal Form doesn't use a marketplaceInfoList
    });

    const hasEnrichmentCostTargeting = Boolean(
        adSourceLevelAudiences.length ||
            adSourceLevelSegments.length ||
            dealLevelAudiences.length ||
            dealLevelSegments.length
    );

    const adSourceEcConverted = convertEnrichmentCostByCurrencyRate(adSourceEc, adSourceConversionRate);

    const dealEc = getAudienceEnrichmentCost(
        dealLevelAudiences,
        dealLevelSegments,
        isRangedCost,
        adSourceConversionRate
    );

    const finalEc = calcFinalEc(adSourceEcConverted, dealEc);
    const finalCpm = calcFinalizedCpm(finalEc, Number(dealRate || 0), Number(adSourceDemandAcquisitionCost || 0));

    return (
        <EnrichmentCostSectionInnerCard
            adSourceFloorType={{ id: Number(adSourceFloorTypeId) }}
            adSourceCurrency={currencyCode}
            adSourceCpm={adSourceCpm}
            adSourceEc={adSourceEc}
            adSourceType={dealType}
            adSourceDemandAcquisitionCost={adSourceDemandAcquisitionCost}
            adSourceDemandAcquisitionCostModelId={adSourceDemandAcquisitionCostModelId}
            adSourceLevelAudiences={adSourceLevelAudiences}
            adSourceLevelSegments={adSourceLevelSegments}
            dealLevelValues={[
                {
                    adSourceDemandAcquisitionCost: Number(adSourceDemandAcquisitionCost || 0),
                    adSourceEc,
                    cpm: Number(dealRate || 0),
                    currencyCode,
                    dealEc,
                    dealId: dealName, // TODO - pass actual ID if `setSelectedDeal` param is ever used
                    dealName,
                    finalCpm,
                    finalEc,
                    finalEcPlusDac: calcFinalCpmWithEcAndDac(
                        null,
                        finalEc,
                        Number(adSourceDemandAcquisitionCost || 0),
                        adSourceDemandAcquisitionCostModelId
                    ),
                    targetingAudiences: dealLevelAudiences,
                    targetingSegments: dealLevelSegments,
                    shouldUseAdSourceValues,
                    priceModelId: dealPriceModelId,
                },
            ]}
            demandAcquisitionCostField={<DemandAcquisitionCostFormItem />}
            hasEnrichmentCostTargeting={hasEnrichmentCostTargeting}
            isDemandAcquisitionCostEnabled={Boolean(context?.dacEnabled)}
            isDrawerOpen={isDrawerOpen}
            isRangedCost={isRangedCost}
            setIsDrawerOpen={setIsDrawerOpen}
            setSelectedDeal={() => setIsDrawerOpen(true)}
            selectedDealLevelAudiences={dealLevelAudiences}
            selectedDealLevelSegments={dealLevelSegments}
            selectedDealName={dealName}
            selectedDealCurrencyCode={currencyCode}
            shouldUseAdSourceValues={shouldUseAdSourceValues}
        />
    );
};
