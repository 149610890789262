import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useGetAdSourcePrioritiesQuery } from "@app/core/services/console/adSources";
import { DEAL_HEALTH_QUERY_PARAM, useDealHealthListParam } from "../useDealHealthTableFilters";

interface UseDealHealthPriorityFilter {
    handleChange: (value: number[], option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: number[];
    isLoading: boolean;
    areAllOptionsSelected: boolean;
    toggleSelectDeselectAll: () => void;
}

export const useDealHealthPriorityFilter = (): UseDealHealthPriorityFilter => {
    const { value: filterPriorities, setValue: setFiltersDealHealthType } = useDealHealthListParam(
        DEAL_HEALTH_QUERY_PARAM.FILTER_PRIORITY
    );
    const { data, isFetching } = useGetAdSourcePrioritiesQuery();
    const options = useMemo(() => (data || []).map((filter) => ({ label: filter.name, value: filter.id })), [data]);

    const handleChange = (value: number[], option: LabeledValue[]) => {
        setFiltersDealHealthType(option.map((item) => `${item.value}`));
    };

    const areAllOptionsSelected = options.length === filterPriorities?.length;

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            handleChange(filterPriorities, []);
        } else {
            handleChange(filterPriorities, options);
        }
    };

    return {
        handleChange,
        options: options,
        value: filterPriorities,
        isLoading: isFetching,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
    };
};
