import { useSeatAuthContext } from "@app/core/auth";
import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { useAdSourceTypeLimits } from "./useAdSourceTypeLimits";

const pbsNamespaceId = 9;

export const useAdSourceTypeFilter = (types) => {
    const { context } = useSeatAuthContext();

    let filteredTypes = [...types];

    if (!context?.externalAdUnitCodeNamespaces.find((elem) => elem.id === pbsNamespaceId)) {
        filteredTypes = filteredTypes.filter((type) => {
            if (type.id === AdSourceTypeIds.PRE_BID_AD_SOURCE || type.id === AdSourceTypeIds.PRE_BID_DEALS) {
                return false;
            }
            return true;
        });
    }

    filteredTypes = filteredTypes.filter((type) => {
        return type.id !== AdSourceTypeIds.DIRECT_AD_SERVING;
    });

    if (!context?.authorizedMarketplaces.length) {
        filteredTypes = filteredTypes?.filter((type) => type.id !== AdSourceTypeIds.MARKETPLACE);
    }

    const filteredTypesWithLimits = useAdSourceTypeLimits(filteredTypes);

    return filteredTypesWithLimits;
};
