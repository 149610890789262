import { FC } from "react";
import { Form, Select } from "antd";
import { FORM_ITEMS } from "../../../constants";
import { getTimeZoneLabel, useGetTimeZonesQuery } from "@app/core/services";

export const LiveEventsFormItemTimezone: FC = () => {
    const { data: timezones, isLoading: isTimezonesLoading } = useGetTimeZonesQuery();
    const timezoneOptions = timezones?.map((timezone) => ({
        label: getTimeZoneLabel(timezone),
        value: timezone.id,
    }));

    return (
        <Form.Item
            required
            name={FORM_ITEMS.TIMEZONE.name}
            label={FORM_ITEMS.TIMEZONE.label}
            rules={[{ required: true, message: "Timezone is required" }]}
        >
            <Select
                showSearch
                data-sdet="live-event-time-zone"
                options={timezoneOptions}
                optionFilterProp="label"
                loading={isTimezonesLoading}
            />
        </Form.Item>
    );
};
