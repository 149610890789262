import { FC, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { BlockedIndustriesSelect } from "@app/core/components";
import { Brand } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { BrandSafetyActions } from "../BrandSafetyActions";
import { BrandSafetyBrandForm } from "../BrandSafetyBrandForm";
import {
    setEditActiveKey,
    selectInventoryBrandSafetyEditActiveKey,
} from "@app/features/inventory/InventoryBrandSafety/reducer";
import { FORM_FIELDS } from "../../../BrandSafetyForm/constants";
import { Collapse, CollapsePanelProps, CollapseProps, Form } from "antd";
import { FORM_GRID_OPTIONS } from "../../BrandSafetyBrandPage/constants";
import { BlockedBuyerSeatListFormItem } from "../../../BrandSafetyForm/BuyerBlockSeatListFormItem";
import { useBrandBrandSafetyEditForm } from "../../../BrandSafetyForm/useBrandBrandSafetyEditForm";
import { BlockedIABCategoriesFormItem } from "../../../BrandSafetyForm/BlockedIABCategoriesFormItem";
import { BlockedAdvertiserDomainsFormItem } from "../../../BrandSafetyForm/BlockedAdvertiserDomainsFormItem";
import { BlockedAdvertiserDomainListsFormItem } from "../../../BrandSafetyForm/BlockedAdvertiserDomainListsFormItem";
import { BrandSafetyCollapsePanelKeys } from "@app/features/inventory/InventoryBrandSafety/InventoryBrandSafetyPage/BrandSafetyCollapsePanel";

type Props = {
    brand: Brand;
    onChange?: CollapseProps["onChange"];
} & CollapsePanelProps;

export const BrandSafetyBrandCollapsePanel: FC<Props> = ({ brand, onChange, ...props }) => {
    const dispatch = useDispatch();
    const editActiveKey = useAppSelector(selectInventoryBrandSafetyEditActiveKey);
    const { brandForm, resetFormsFields, initialValues, handleSubmit, isUpdating, handleDelete, isDeleting } =
        useBrandBrandSafetyEditForm(brand);

    const isOnEdit: boolean = editActiveKey === BrandSafetyCollapsePanelKeys.BRAND;

    const onEdit = (): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange?.(BrandSafetyCollapsePanelKeys.BRAND);
        dispatch(setEditActiveKey(BrandSafetyCollapsePanelKeys.BRAND));
    };

    const onCancel = useCallback((): void => {
        resetFormsFields();
        dispatch(setEditActiveKey(null));
    }, [resetFormsFields, dispatch]);

    useEffect(() => {
        return () => {
            onCancel();
        };
    }, [brand, onCancel]);

    return (
        <Collapse.Panel
            extra={
                <BrandSafetyActions
                    handleDelete={handleDelete}
                    isDeleting={isDeleting}
                    isOnEdit={isOnEdit}
                    handleEdit={onEdit}
                    handleCancel={onCancel}
                    handleSubmit={handleSubmit}
                    isUpdating={isUpdating}
                />
            }
            {...props}
        >
            {isOnEdit ? (
                <Form
                    layout="vertical"
                    {...FORM_GRID_OPTIONS["vertical"]}
                    labelAlign="left"
                    form={brandForm}
                    initialValues={initialValues}
                >
                    <BlockedAdvertiserDomainListsFormItem {...FORM_FIELDS.BLOCKED_ADVERTISER_DOMAIN_LISTS} />
                    <BlockedAdvertiserDomainsFormItem {...FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS} />
                    <BlockedBuyerSeatListFormItem {...FORM_FIELDS.BLOCKED_BUYER_SEAT_LIST} />
                    <BlockedIABCategoriesFormItem {...FORM_FIELDS.BLOCKED_IAB_CATEGORIES} />
                    <BlockedIndustriesSelect />
                </Form>
            ) : (
                <BrandSafetyBrandForm brand={brand} />
            )}
        </Collapse.Panel>
    );
};
