import { FC, useState } from "react";
import { Button, Tooltip, Typography, Flex } from "antd";
import { ArrowRightOutlined, LinkOutlined } from "@ant-design/icons";
import { SeatAdSourcesDetailsDrawerButtons } from "./SeatAdSourcesDetailsDrawerButtons";
import { useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { brandCobalt } from "@rubicon/antd-components";
import { useSeatAdSourcesPageNavigator } from "@app/features/seatAdSources/utils/index";
import { useAppSelector } from "@app/core/store";
import { selectIframed } from "@app/core/components/Main/reducer";
import { getUrlSegment } from "@app/core/utils";
import { useClipBoardButton } from "@app/core/components/ClipBoardButton/useClipBoardButton";

interface Props {
    adSourceId?: number;
    adSourceName: string;
    adSourceTypeId: number;
}

const GoToAdSourceHover = ({ adSourceName, adSourceId }: { adSourceName: string; adSourceId: number }) => {
    const { goToSeatAdSourcesDetailsPage } = useSeatAdSourcesPageNavigator();
    const { seatId } = useParams<{ seatId: string }>();
    const [isVisible, setIsVisible] = useState(false);
    const inIfram = useAppSelector(selectIframed);
    const targetPath = ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS_DRAWER(seatId, adSourceId);
    const { origin, pathname } = window.location;
    const url = new URL(`${pathname}#${targetPath}`, origin);
    const copyUrl = inIfram ? getUrlSegment(url.hash) : url.href;
    const { handleCopy } = useClipBoardButton();

    return (
        <Flex
            data-sdet="seat-ad-sources-details-drawer-nav-bar"
            onMouseOver={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
            align="baseline"
        >
            <Typography.Title
                style={{
                    maxWidth: "25rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingRight: "1rem",
                    marginTop: 0,
                }}
                level={5}
            >
                {adSourceName}
            </Typography.Title>
            {adSourceId && (
                <Button
                    type="link"
                    size="small"
                    onClick={() => goToSeatAdSourcesDetailsPage(adSourceId)}
                    style={{ marginBottom: "0.8rem", paddingLeft: 0 }}
                >
                    <Typography.Text style={{ fontSize: "0.9rem", color: brandCobalt }}>
                        Go To Ad Source <ArrowRightOutlined />{" "}
                    </Typography.Text>
                </Button>
            )}
            <Tooltip placement="bottom" title="Copy Ad Source Link">
                <LinkOutlined
                    data-sdet="seat-ad-sources-details-drawer-copy-link"
                    style={{
                        paddingTop: "0.2rem",
                        opacity: isVisible ? "1" : "0",
                        height: isVisible ? "auto" : "0",
                        transition: "height 0.3s, opacity 0.3s ease-out",
                        color: brandCobalt,
                    }}
                    onClick={() => handleCopy(copyUrl, "Link")}
                />
            </Tooltip>
        </Flex>
    );
};

export const SeatAdSourcesDetailsDrawerNavBarTitle: FC<Props> = ({ adSourceId, adSourceName, adSourceTypeId }) => {
    if (!adSourceId) {
        return null;
    }
    return (
        <Flex vertical>
            <GoToAdSourceHover adSourceId={adSourceId} adSourceName={adSourceName} />
            <SeatAdSourcesDetailsDrawerButtons adSourceId={adSourceId} adSourceTypeId={adSourceTypeId} />
        </Flex>
    );
};
