import { FC } from "react";
import { Form } from "antd";
import { LabeledValue } from "antd/lib/select";
import { LoadOnScrollSelect } from "@rubicon/antd-components";
import { useGetSeatSegmentsQuery } from "@magnite/client-streaming-platform";
import { segmentsToLabelValue } from "@app/features/targeting";
import { useDimensionRowSearch } from "../../useDimensionRowSearch";
import { getParams } from "../helpers";

interface Props {
    isValidationShown: boolean;
    onChange: (value: LabeledValue | null) => void;
    value: LabeledValue | null;
}

export const AdvancedSegmentSelect: FC<Props> = ({ isValidationShown, onChange, value }) => {
    const [search, handleChangeSearch, options, isFetching, hasMore, loadMore] = useDimensionRowSearch(
        useGetSeatSegmentsQuery,
        segmentsToLabelValue,
        getParams
    );

    const isInvalid = isValidationShown && !value;

    return (
        <Form.Item
            style={{ margin: 0 }}
            validateStatus={isInvalid ? "error" : undefined}
            help={isInvalid ? "A value is required" : undefined}
        >
            <LoadOnScrollSelect
                allowClear
                filterOption={false}
                labelInValue
                loading={isFetching}
                onBlur={() => handleChangeSearch("")}
                onChange={(v) => {
                    // Ant's onChange returns undefined on clear
                    if (!v) {
                        onChange(null);
                        return;
                    }
                    onChange(v);
                }}
                onSearch={handleChangeSearch}
                options={options}
                placeholder="Select Value"
                searchValue={search}
                showSearch
                style={{ width: "100%" }}
                value={value}
                status={isInvalid ? "error" : undefined}
                loadMore={loadMore}
                hasMore={hasMore}
            />
        </Form.Item>
    );
};
