import React, { FC } from "react";
import { cx } from "@emotion/css";
import { Collapse, Form, Tag } from "antd";
import { LabelValue } from "@magnite/client-streaming-platform";
import type { ColProps } from "antd/es/grid/col";
import {
    INHERITED_OPTIONS_SECTION_CLASSLIST,
    INHERITED_SECTION_CLASSLIST,
} from "@app/features/inventory/HierarchyForms/constants";

interface InheritedLabelsProps {
    wrapperCol?: ColProps;
    type: "seat" | "internal";
    labels: LabelValue[];
}

const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 };

export const InheritedLabels: FC<InheritedLabelsProps> = ({ labels, type, wrapperCol = COL_SIZES }) => {
    const typeLabel = type === "seat" ? "Seat" : "Global";

    if (!labels?.length) return null;

    return (
        <Collapse
            ghost
            expandIconPosition="end"
            data-sdet={`inherited-${type}-lables`}
            className={cx(INHERITED_OPTIONS_SECTION_CLASSLIST, INHERITED_SECTION_CLASSLIST)}
            items={[
                {
                    key: "0",
                    label: `View Inherited ${typeLabel} Labels`,
                    children: (
                        <Form.Item wrapperCol={wrapperCol} label={`Inherited ${typeLabel} Labels`}>
                            {labels?.map((label: LabelValue) => {
                                return (
                                    <Tag key={`${label?.label?.key}-${label?.value}`}>
                                        {label?.label?.key}: {label?.value}
                                    </Tag>
                                );
                            })}
                        </Form.Item>
                    ),
                },
            ]}
        />
    );
};
