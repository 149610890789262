import { Card, Col, Divider, Row, Collapse, Form, Tooltip } from "antd";
import { FC } from "react";
import {
    AD_SOURCE_FIELDS,
    AD_SOURCE_SECTIONS,
    AD_SOURCE_SECTIONS_NAME,
    AdSourceAdvertiserDomainModeIds,
    cardBodyStyle,
    COL_SIZES,
    GUTTER,
    mainStepSectionClassList,
} from "../../../constants";
import { DefinitionSidePanel } from "../../DefinitionSidePanel";
import {
    AdditionalAdvertiserDomainsTextArea,
    BlockedIndustriesSelect,
    ModeSelect,
    ReusableAdvertiserDomainSelect,
    IABCategoryBlockModeSelect,
    IABCategoriesSelect,
    IndustriesModeSelect,
    AdSourceIabCategoryBlockExceptionSelect,
} from "./Fields";
import { useIsBrandSafetySectionVisible } from "./isBrandSafetySectionVisible";
import { SeatAdSourcesType } from "@app/core/services";

const { useWatch } = Form;

export const BrandSafetySection: FC = () => {
    const isVisible = useIsBrandSafetySectionVisible();
    const advertiserDomainMode = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.MODE.name);
    const isOverrideInventoryBlockedDomains: boolean =
        advertiserDomainMode?.id === AdSourceAdvertiserDomainModeIds.ALLOW_OVERRIDE_INVENTORY;

    return isVisible ? (
        <Collapse
            bordered
            ghost={false}
            collapsible="header"
            className={mainStepSectionClassList}
            data-sdet={AD_SOURCE_SECTIONS.BRAND_SAFETY.toLowerCase().replace(/_/g, "-")}
            items={[
                {
                    key: "1",
                    forceRender: true,
                    label: AD_SOURCE_SECTIONS_NAME.BRAND_SAFETY,
                    extra: (
                        <DefinitionSidePanel
                            panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.BRAND_SAFETY]}
                            section={AD_SOURCE_SECTIONS.BRAND_SAFETY}
                            sectionSelector="#adSourceForm"
                        />
                    ),
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES} data-sdet="mode">
                                    <ModeSelect />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col span={16} data-sdet="reusable-advertiser-domain">
                                    <ReusableAdvertiserDomainSelect />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col span={16} data-sdet="additional-advertiser-domain">
                                    <AdditionalAdvertiserDomainsTextArea />
                                </Col>
                            </Row>
                            <Divider />
                            <Tooltip
                                placement="topLeft"
                                title={
                                    isOverrideInventoryBlockedDomains
                                        ? "IAB Category Block is ignored when you allow specified Advertisers to run"
                                        : undefined
                                }
                            >
                                <Row gutter={GUTTER}>
                                    <Col span={16} data-sdet="iab-category-blocking-mode">
                                        <IABCategoryBlockModeSelect />
                                    </Col>
                                </Row>
                                <Row gutter={GUTTER}>
                                    <Col span={16} data-sdet="iab-categories">
                                        <IABCategoriesSelect />
                                    </Col>
                                </Row>
                                <Row gutter={GUTTER}>
                                    <Col span={16} data-sdet="iab-categories-block-exception">
                                        <AdSourceIabCategoryBlockExceptionSelect />
                                    </Col>
                                </Row>
                            </Tooltip>
                            <Divider />
                            <Row gutter={GUTTER}>
                                <Col span={8}>
                                    <IndustriesModeSelect />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col span={16}>
                                    <BlockedIndustriesSelect />
                                </Col>
                            </Row>
                        </Card>
                    ),
                },
            ]}
        />
    ) : null;
};
