import { useAppSelector } from "./store";
import { Theme } from "@rubicon/antd-components";
import { PrivateLabel, useGetSeatPrivateLabelQuery } from "./services/console/privateLabels";
import { useMemo } from "react";

export const useThemeId = () => {
    const auth = useAppSelector((state) => state.auth);
    const id = auth.buyer?.context?.id || auth.seat?.context?.seat?.id || null;
    return id;
};

// TODO: Purely for demo purposes, remove these
const UI_TEST_SUPPLY_SEAT_ID = 60899;
const DEMO_NEW_CO_APAC_ID = 61444;

export interface Theme {
    token: {
        colorPrimary: string;
    };
    meta: {
        navbarBackgroundColor: string;
        navbarBackgroundHighlightColor: string;
        colorPrimaryDark: string;
        logo: string;
    };
}

export const getTheme = (data: PrivateLabel | undefined, isLoading: boolean, isError: boolean): Theme | undefined => {
    if (!data || isLoading || isError) {
        return undefined;
    }

    return {
        token: {
            colorPrimary: data.uiTheme.primaryColor,
        },
        meta: {
            // TODO: Top navbar colors
            colorPrimaryDark: data.uiTheme.primaryDarkColor,
            logo: data.uiUrls.logo,
            navbarBackgroundColor: data.uiTheme.navbarBackgroundColor,
            navbarBackgroundHighlightColor: data.uiTheme.navbarBackgroundHighlightColor,
        },
    };
};

export const useTheme = () => {
    const id = useThemeId();
    const isTest = [UI_TEST_SUPPLY_SEAT_ID, DEMO_NEW_CO_APAC_ID].includes(Number(id));
    const { data, isFetching, isError } = useGetSeatPrivateLabelQuery({ id: Number(id), test: isTest }, { skip: !id });

    const theme = useMemo(() => getTheme(data, isFetching, isError), [data, isFetching, isError]);

    return { theme };
};

export const ThemeProvider = ({ children }) => {
    const { theme } = useTheme();

    return (
        <Theme theme="ruthlessEmpress" themeConfigOverride={theme}>
            {children}
        </Theme>
    );
};
