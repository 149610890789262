import { useEffect, useState } from "react";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import { ChangeLogs } from "@app/core/services";

const defaultAllOption = { label: "All", value: "" };

const actionOptions = [
    defaultAllOption,
    { label: "Created", value: "Created" },
    { label: "Changed", value: "Changed" },
    { label: "Deleted", value: "Deleted" },
];

interface SelectOption {
    label: string;
    value: string;
}

interface UseChangelogFilters {
    form: FormInstance;
    actionOptions: SelectOption[];
    userOptions: SelectOption[];
}

export const useChangeLogFilters = (changeLogs: ChangeLogs): UseChangelogFilters => {
    const [form] = Form.useForm();
    const [userOptions, setUserOptions] = useState<{ label: string; value: string }[]>([defaultAllOption]);

    useEffect(() => {
        // Only initialize user options when we receive the first batch of data. This way, we can
        // grab a complete list of distinct emails because no other filters have been applied yet.
        // TODO - use service to lookup users to match legacy behavior
        const distinctEmails = [...new Set(changeLogs?.map((changeLog) => changeLog.user) ?? [])];
        if (userOptions.length === 1 && distinctEmails.length > 0) {
            const userEmailOptions = [
                defaultAllOption,
                distinctEmails.map((email: string) => ({ label: email, value: email })),
            ].flat(1);
            setUserOptions(userEmailOptions);
        }
    }, [changeLogs, userOptions]);

    return {
        form,
        actionOptions,
        userOptions,
    };
};
