import { useMemo, useState } from "react";
import { DEFAULT_PAGE_SIZE_DEAL_DEMAND_TABLE, PAGE_SIZE_OPTIONS } from "@app/core/components/constants";
import { AdSource } from "@app/core/services";
import { DemandTableRecord, getDataSource } from "../SeatAdSourcesForm/AdSourcesSections";
import { parseBookingPriceFromApi } from "../SeatAdSourcesForm/utils";
import moment from "moment-timezone";
import { TablePaginationConfig } from "antd";
import { AdSourceTypeIds } from "../constants";
import {
    useSearchDemandDealsTable,
    SortField,
    sortDeals,
    compareFunctionsByColumnKey,
    paginate,
} from "@app/features/seatAdSources/utils/useDemandDealsTable";

interface UseSeatAdSourcesDealCountTable {
    deals: DemandTableRecord[];
    onSearchChange: (searchString: string) => void;
    onSortChange: (_a: unknown, _b: unknown, sortField: SortField) => void;
    isSingleInstanceOnly: boolean;
    totalPaginationProps: TablePaginationConfig;
}

export const useSeatAdSourcesDealCountTable = (adSource: AdSource): UseSeatAdSourcesDealCountTable => {
    const [search, setSearch] = useState<string>("");
    const [{ max, page }, setPagination] = useState<{ page: number; max: number }>({
        page: 1,
        max: DEFAULT_PAGE_SIZE_DEAL_DEMAND_TABLE,
    });
    const [sortField, setSortField] = useState<SortField>({ columnKey: "network", order: "ascend" });

    const isSingleInstanceOnly =
        adSource.type?.id === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED ||
        adSource.type?.id === AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED;

    const dealsDataSource = useMemo(() => {
        return getDataSource(adSource.marketplaceInfoList, {
            adSourceFloorType: adSource.adSourceFloorType,
            rate: adSource.bookingPrice ? parseBookingPriceFromApi(adSource.bookingPrice) : null,
            currency: adSource.currencyType,
            startDate: moment(adSource.bookingStartDate),
            endDate: adSource.bookingEndDate ? moment(adSource.bookingEndDate) : null,
            type: adSource.type,
            timeZone: adSource.timeZone,
        });
    }, [
        adSource.adSourceFloorType,
        adSource.marketplaceInfoList,
        adSource.bookingPrice,
        adSource.currencyType,
        adSource.bookingStartDate,
        adSource.bookingEndDate,
        adSource.type,
        adSource.timeZone,
    ]);

    const searchFilteredDeals = useSearchDemandDealsTable(dealsDataSource, search);

    const sortedDeals = useMemo(() => {
        return sortDeals(searchFilteredDeals, sortField, compareFunctionsByColumnKey);
    }, [searchFilteredDeals, sortField]);

    const paginatedDeals = useMemo(() => {
        return paginate(sortedDeals, page, max);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedDeals, sortField, max, page]);

    const onSearchChange = (searchString: string) => {
        setPagination({ max, page: 1 });
        setSearch(searchString);
    };

    const onPaginationChange = (page: number, pMax: number) => {
        const updatedCurrentPage = pMax === max ? page : 1;
        setPagination({ max: pMax, page: updatedCurrentPage });
    };

    const onSortChange = (_a: unknown, _b: unknown, sortField: SortField) => {
        setSortField(sortField);
    };

    return {
        deals: paginatedDeals,
        onSearchChange,
        onSortChange,
        isSingleInstanceOnly,
        totalPaginationProps: {
            current: page,
            pageSize: max,
            total: sortedDeals.length,
            onChange: onPaginationChange,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            showSizeChanger: true,
        },
    };
};
