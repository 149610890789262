import { FC, MouseEvent, useEffect } from "react";
import { Col, Row, Space, Table, Grid } from "antd";
import DealHealthFilterSearch from "@app/features/deals/DealHealthPage/deprecated/DealHealthTable/DealHealthTableFilterSearch";
import DealHealthPagination from "@app/features/deals/DealHealthPage/deprecated/DealHealthTable/DealHealthTablePagination";
import { useDealHealthTable } from "@app/features/deals/DealHealthPage/deprecated/DealHealthTable/useDealHealthTable";
import { COLUMN_KEY } from "@app/features/deals/DealHealthPage/constants";
import { UIDeal } from "@app/core/services";
import DealStatusBadge from "@app/features/deals/DealStatusBadge";
import { useDealAdStats } from "@app/features/adStats";
import { DealListTableActions } from "@app/features/deals/shared/DealTableActions";
import DealPriority from "@app/features/deals/DealPriority";
import DealHealthTableTriesCell from "./DealHealthTableCells/DealHealthTableTriesCell";
import DealHealthTableImpressionsCell from "./DealHealthTableCells/DealHealthTableImpressionsCell";
import DealHealthTableUseRateCell from "./DealHealthTableCells/DealHealthTableUseRateCell";
import DealHealthTableNetRevenueCell from "./DealHealthTableCells/DealHealthTableNetRevenueCell";
import { DEAL_TYPE_PROGRAMMATIC_GUARANTEE, DealsHelpKeys } from "@app/features/deals/constants";
import { Dashes, HelpTooltip, PacingProgressBar, StyledLink } from "@app/core/components";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "src/features/seatAdSources/SeatAdSourcesDetailsDrawer/types";
import DealTableCpmRateCell from "@app/features/deals/shared/DealCPMRateCell/DealTableCpmRateCell";
import { ManageColumnsButton, ManageColumnsDrawer } from "@app/core/components/ManageColumns";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
    MANAGE_COLUMNS_KEY_DEAL_HEALTH_DEPRECATED,
    useManageColumnsDrawer,
} from "@app/core/components/ManageColumns/useManageColumnsDrawer";
import { useDealDetailsDrawer } from "../../../DealDetailsDrawer/useDealDetailsDrawer";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { format } from "@rubicon/utils";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_NO_COMMA } from "@app/core/components/constants";
import { DealCurrencyFilters } from "../../../DealListPage/DealCurrencyFilters";
import { brandCobalt } from "@rubicon/antd-components";
import moment from "moment-timezone";
import { useSeatAdSourcesDetailsDrawer } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/useSeatAdSourcesDetailsDrawer";
import { TableItem } from "./useDealHealthTable";
import { dealListSourceToLiveStatsAdSourcePreview } from "../../helpers";

export const getTableItems = (
    data: UIDeal,
    handleLinkClick: (e: MouseEvent<HTMLElement>, dealId: number) => void,
    openSeatAdSourcesDetailsDrawer: (adSourceId: number, secondaryDetailsView?: SecondaryDetailsView) => void,
    route: string,
    seatId: string
): TableItem[] =>
    data?.deals.map((deal) => {
        const mappedAdSources = (deal.adSources || []).map(dealListSourceToLiveStatsAdSourcePreview);
        return {
            key: deal?.id,
            [COLUMN_KEY.NAME]: (
                <StyledLink
                    href={`${route}${ROUTE_FORMATTERS.SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER(seatId, deal.id)}`}
                    onClick={(e: MouseEvent<HTMLElement>) => handleLinkClick(e, deal?.id)}
                >
                    {format.asSelf(deal?.name)}
                </StyledLink>
            ),
            [COLUMN_KEY.DEAL_PRIORITY]: (
                <DealPriority adSources={mappedAdSources} openDrawer={openSeatAdSourcesDetailsDrawer} />
            ),
            [COLUMN_KEY.CODE]: <Dashes value={deal?.code} />,
            [COLUMN_KEY.DEAL_ENTITY_TYPE]: deal?.dealType?.name,
            [COLUMN_KEY.DEAL_START_DATE]: (
                <Dashes
                    value={
                        deal?.startTime
                            ? `${moment(deal.startTime)
                                  .tz(deal.timeZone?.code || "UTC")
                                  .format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_NO_COMMA)} ${deal.timeZone?.code || "UTC"}`
                            : null
                    }
                />
            ),
            [COLUMN_KEY.PACING]:
                deal?.dealType?.id !== DEAL_TYPE_PROGRAMMATIC_GUARANTEE.id ||
                !deal.pacing ||
                deal.pacing.length < 1 ||
                !deal.adSources ||
                deal.adSources.length < 1 ? (
                    <Dashes />
                ) : (
                    <PacingProgressBar pacingData={deal.pacing[0]} />
                ),
            [COLUMN_KEY.CPM_RATE]: (
                <DealTableCpmRateCell
                    dealType={deal.dealType}
                    rate={deal.rate}
                    currencyType={deal.currencyType}
                    adSources={mappedAdSources}
                    openDrawer={openSeatAdSourcesDetailsDrawer}
                />
            ),
            [COLUMN_KEY.TRIES]: (
                <DealHealthTableTriesCell dealId={deal?.id} sourceCurrencyCode={deal.currencyType?.code} />
            ),
            [COLUMN_KEY.IMPRESSIONS]: (
                <DealHealthTableImpressionsCell dealId={deal?.id} sourceCurrencyCode={deal.currencyType?.code} />
            ),
            [COLUMN_KEY.USE_RATE]: (
                <DealHealthTableUseRateCell dealId={deal?.id} sourceCurrencyCode={deal.currencyType?.code} />
            ),
            [COLUMN_KEY.NET_REVENUE]: (
                <DealHealthTableNetRevenueCell dealId={deal?.id} sourceCurrencyCode={deal.currencyType?.code} />
            ),
            [COLUMN_KEY.DEAL_STATUS_NAME]: <DealStatusBadge status={deal.status} />,
            [COLUMN_KEY.DEAL_ACTIONS]: (
                <DealListTableActions
                    dealId={deal.id}
                    adSources={mappedAdSources}
                    dealType={deal.dealType}
                    isDealShared={
                        (deal.sharedMarketplaces && deal.sharedMarketplaces.length > 0) ||
                        (deal.sharedSeats && deal.sharedSeats?.length > 0) ||
                        false
                    }
                />
            ),
        };
    });

const getDefaultColumns = () => [
    {
        dataIndex: COLUMN_KEY.NAME,
        title: (
            <HelpTooltip
                helpKeyList={DealsHelpKeys}
                helpKey={DealsHelpKeys.DealName}
                tooltipPlacement={"topRight"}
                popover={true}
            >
                Deal Name
            </HelpTooltip>
        ),
        width: 280,
        visible: true,
        elipsis: true,
        managedColumnTitle: "Deal Name",
        selectable: false,
    },
    {
        dataIndex: COLUMN_KEY.DEAL_PRIORITY,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealPriority} popover={true}>
                Priority
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Priority",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.CODE,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealId} popover={true}>
                Deal ID
            </HelpTooltip>
        ),
        visible: false,
        managedColumnTitle: "Deal ID",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.DEAL_ENTITY_TYPE,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealType} popover={true}>
                Deal Type <InfoCircleOutlined style={{ color: brandCobalt }} />
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Deal Type",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.DEAL_START_DATE,
        title: "Start Date",
        visible: true,
        managedColumnTitle: "Start Date",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.PACING,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealDetailsPacing} popover={true}>
                Pacing
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Pacing",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.CPM_RATE,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealDetailsCPMRate} popover={true}>
                CPM Rate
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "CPM Rate",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.TRIES,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.Tries} popover={true}>
                Tries
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Tries",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.IMPRESSIONS,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.AdStatsPublisherImpressions} popover={true}>
                Impressions
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Impressions",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.USE_RATE,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.AdStatsPublisherUseRate} popover={true}>
                Use Rate
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Use Rate",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.NET_REVENUE,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.AdStatsPublisherNetRevenue} popover={true}>
                Net Revenue
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Net Revenue",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.DEAL_STATUS_NAME,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealStatus} popover={true}>
                Internal Status
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Internal Status",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.DEAL_ACTIONS,
        title: "Actions",
        // width: 140,
        visible: true,
        selectable: false,
    },
];
const DealHealthTable: FC = () => {
    const { data, isFetching, handleChange, seatId } = useDealHealthTable();
    const { sm } = Grid.useBreakpoint();
    const { setSubEntityIds } = useDealAdStats();
    const { openDealDetailsDrawer } = useDealDetailsDrawer();
    const { openSeadAdSourceDrawerDeals } = useSeatAdSourcesDetailsDrawer();
    const defaultColumns = getDefaultColumns();
    const {
        isDrawerOpen,
        handleOpenManageColumnsDrawer,
        handleCloseManageColumnsDrawer,
        allColumns,
        visibleColumns,
        onChange,
    } = useManageColumnsDrawer(MANAGE_COLUMNS_KEY_DEAL_HEALTH_DEPRECATED, defaultColumns);
    const { origin, pathname } = window.location;
    const route = `${origin}${pathname}#`;

    const handleLinkClick = (e: MouseEvent<HTMLElement>, dealId: number) => {
        openDealDetailsDrawer(dealId);
    };

    useEffect(() => {
        if (data?.deals) {
            setSubEntityIds(data.deals.map((deal) => String(deal.id)));
        }
    }, [data, setSubEntityIds]);

    return (
        <>
            <Space direction="vertical" size="middle" style={{ width: "100%" }} data-sdet="deals-list-table">
                <Row gutter={[16, 16]}>
                    <Col xs={24} lg={10}>
                        <DealHealthFilterSearch />
                    </Col>
                    <Col xs={24} sm={20} lg={10}>
                        <DealCurrencyFilters />
                    </Col>
                    <Col flex="1" style={sm ? { display: "flex", justifyContent: "flex-end" } : { marginTop: "-24px" }}>
                        <ManageColumnsButton handleOpen={handleOpenManageColumnsDrawer} />
                    </Col>
                </Row>
                <Table<TableItem>
                    size="small"
                    columns={visibleColumns}
                    showSorterTooltip={false}
                    onChange={handleChange}
                    loading={isFetching}
                    scroll={{ x: 1750 }}
                    dataSource={getTableItems(data, handleLinkClick, openSeadAdSourceDrawerDeals, route, seatId)}
                    pagination={false}
                />
                <Row justify="end">
                    <Col>
                        <DealHealthPagination totalResults={data?.totalResults || 0} />
                    </Col>
                </Row>
            </Space>
            <ManageColumnsDrawer
                visible={isDrawerOpen}
                columns={allColumns}
                title="Manage Columns for Deal Health"
                handleClose={handleCloseManageColumnsDrawer}
                onChange={onChange}
            />
        </>
    );
};

export default DealHealthTable;
