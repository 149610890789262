import { AD_SOURCE_SECTIONS, AD_SOURCE_SECTIONS_NAME, AdSourceTypeIds } from "@app/features/seatAdSources/constants";

export const getPricingSectionName = (typeId: number) => {
    if (
        typeId === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED ||
        typeId === AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED ||
        typeId === AdSourceTypeIds.FIXED_PRICE ||
        typeId === AdSourceTypeIds.LINEAR_UNRESERVED_FIXED_RATE
    ) {
        return AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.LEGACY_PRICING];
    }

    if (
        typeId === AdSourceTypeIds.AUCTION_PRICE ||
        typeId === AdSourceTypeIds.CURATOR_MARKETPLACE ||
        typeId === AdSourceTypeIds.LINEAR_AUCTION_PRICE ||
        typeId === AdSourceTypeIds.MARKETPLACE ||
        typeId === AdSourceTypeIds.OPEN_AUCTION
    ) {
        return AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.FLOOR_SETTINGS];
    }
    return AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.PRICING];
};
