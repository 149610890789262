import { NONE } from "@app/core/components/constants";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";
import { GUTTER, INNER_COL_SIZES, TWO_COL_SIZES } from "@app/features/inventory/constants";
import { Col, Form, Row, Typography } from "antd";
import { FC } from "react";
import { CHANNEL_GENERAL_LABELS, FORM_ITEM_OFFSET } from "../../../constants";
import { TextWithCopy } from "@app/features/inventory/components/TextWithCopy";
import { Link } from "react-router-dom";
import { InventoryDetailsDrawerType } from "@app/features/inventory/DetailsDrawer/reducer";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { AccountType } from "@magnite/client-streaming-platform/dist/endpoints/types";

const { Item } = Form;

interface Props {
    accountType?: AccountType | null;
    adUnitCode: string;
    adUnitId: number;
    billingCode?: string;
    brandCode: string;
    brandId: number;
    description?: string;
    isInternalOnly: boolean;
    isPublisherChannel: boolean;
    name: string;
    publisherCode: string;
    publisherId: number;
    seatId: number;
    status: string;
    supplyId: number;
}

export const General: FC<Props> = ({
    accountType,
    adUnitCode,
    adUnitId,
    billingCode,
    brandCode,
    brandId,
    description,
    isInternalOnly,
    isPublisherChannel,
    name,
    publisherCode,
    publisherId,
    seatId,
    status,
    supplyId,
}) => {
    return (
        <Row gutter={GUTTER} data-sdet="channel-general-section">
            <Col {...TWO_COL_SIZES}>
                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={CHANNEL_GENERAL_LABELS.name} className={FORM_ITEM_OFFSET}>
                            <TextWithCopy notificationEntity="Name" text={name} />
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={CHANNEL_GENERAL_LABELS.description} className={FORM_ITEM_OFFSET}>
                            <Typography.Paragraph>{description ? description : NONE}</Typography.Paragraph>
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={CHANNEL_GENERAL_LABELS.publisherCode} className={FORM_ITEM_OFFSET}>
                            <TextWithCopy notificationEntity="Publisher Code" text={publisherCode} />
                        </Item>
                    </Col>
                </Row>
                {!isPublisherChannel && (
                    <Row gutter={GUTTER}>
                        <Col {...INNER_COL_SIZES}>
                            <Item label={CHANNEL_GENERAL_LABELS.brandCode} className={FORM_ITEM_OFFSET}>
                                {brandCode}
                            </Item>
                        </Col>
                    </Row>
                )}
                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={CHANNEL_GENERAL_LABELS.adUnitCode} className={FORM_ITEM_OFFSET}>
                            <Link
                                to={
                                    ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
                                        seatId,
                                        InventoryDetailsDrawerType.AD_UNIT,
                                        publisherId,
                                        brandId,
                                        supplyId,
                                        adUnitId
                                    ) || ""
                                }
                            >
                                <TextWithCopy notificationEntity="Ad Unit Code" text={adUnitCode} />
                            </Link>
                        </Item>
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...INNER_COL_SIZES}>
                        <Item label={CHANNEL_GENERAL_LABELS.status} className={FORM_ITEM_OFFSET}>
                            {status}
                        </Item>
                    </Col>
                </Row>
            </Col>
            <Col hidden={!(isInternalOnly && isPublisherChannel)} {...TWO_COL_SIZES}>
                <InternalOnly>
                    <Item label={CHANNEL_GENERAL_LABELS.accountType} className={FORM_ITEM_OFFSET}>
                        {accountType?.name || NONE}
                    </Item>
                    <Item label={CHANNEL_GENERAL_LABELS.billingCode} className={FORM_ITEM_OFFSET}>
                        {billingCode}
                    </Item>
                </InternalOnly>
            </Col>
        </Row>
    );
};
