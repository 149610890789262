import { useAppDispatch, useAppSelector } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import { setFiltersDealHealthDsp } from "@app/features/deals/DealHealthPage/deprecated/reducer";
import { useEffect, useMemo, useRef, useState } from "react";
import { useGetGlobalNetworksQuery } from "@magnite/client-streaming-platform";

interface UseDealHealthDspFilter {
    handleChange: (option: LabeledValue[]) => void;
    isFetching: boolean;
    options: LabeledValue[];
    value: LabeledValue[] | null;
    areAllOptionsSelected: boolean;
    toggleSelectDeselectAll: () => void;
}

const MAX = 200;

export const useDspFilter = (): UseDealHealthDspFilter => {
    const ref = useRef(null);
    const [page, setPage] = useState<number>(1);

    const dispatch = useAppDispatch();
    const dsp = useAppSelector((state) => state.dealHealthDeprecated.filters.filterDsp);

    const { data, isFetching } = useGetGlobalNetworksQuery({ page, max: MAX });

    const options = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return (ref.current || []).concat((data || []).map((item) => ({ label: item.name, value: item.id })));
    }, [data]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref.current = options;
    }, [options]);

    useEffect(() => {
        if ((data || []).length === MAX) {
            setPage((page) => page + 1);
        }
    }, [data]);

    const handleChange = (option: LabeledValue[]): void => {
        dispatch(setFiltersDealHealthDsp(option));
    };

    const areAllOptionsSelected = options.length === dsp?.length;

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            handleChange([]);
        } else {
            handleChange(options);
        }
    };

    return {
        handleChange,
        isFetching,
        options,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value: dsp,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
    };
};
