import { skipToken } from "@reduxjs/toolkit/query";
import { LabeledValue } from "antd/lib/select";
import { useSeatAuthContext } from "@app/core/auth";
import { useAppSelector } from "@app/core/store";
import { GetSeatAllDealsParams } from "@app/core/services/console";
import { CurrencyConversionModes } from "@app/core/clients/console";
import { selectUserTimezone } from "@app/core/authClient/reducer";
import {
    DealHealthSearchFilter,
    DealHealthTableFilters,
    DealHealthTablePagination,
    DealHealthTableSort,
} from "../types";
import { useHistory, useLocation } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { useDealHealthTablePagination } from "../DealHealthTable/DealHealthTablePagination/useDealHealthTablePagination";
import { useQueryParamListState } from "@app/core/utils/useQueryParamState/useQueryParamListState";
import { getQueryParam, getQueryParamList } from "@app/core/utils/useQueryParamState/utils";
import { useQueryParamState } from "@app/core/utils/useQueryParamState/useQueryParamState";
import { useAllQueryParamState } from "@app/core/utils/useQueryParamState/useAllQueryParamState";

export interface DealHealthState {
    filters: DealHealthTableFilters;
    sort: DealHealthTableSort;
    pagination: DealHealthTablePagination;
    searchFilter: DealHealthSearchFilter;
    liveStats: boolean;
}

interface UseDealHealthTableFilters {
    filterCount: number;
    seatAllDealHealthParams: typeof skipToken | GetSeatAllDealsParams;
    handleClearFilters: () => void;
    setDealHealthTableSort: (sort: DealHealthTableSort) => void;
}

export const getFilter = (filter: LabeledValue | null) => {
    if (filter) {
        return String(filter.value);
    }
};

export const getFilterMultiSelects = (filter: LabeledValue[] | null) => {
    if (filter && filter.length > 0) {
        return filter.map((filter) => String(filter.value));
    }
};

export const DEAL_HEALTH_QUERY_PARAM = {
    FILTER_DEAL_TYPE: "filterDealType",
    FILTER_DSP: "filterDsp",
    FILTER_INTERNAL_STATUS: "filterStatus",
    FILTER_BUYER_STATUS: "filterBuyerStatus",
    FILTER_PRIORITY: "filterPriority",
    FILTER_SEARCH: "searchKeyword",
    DEAL_SORT_ORDER_BY: "orderBy",
    DEAL_SORT_ASC: "asc",
    DEAL_PAGINATION_PAGE: "page",
    DEAL_PAGINATION_MAX: "max",
    DEAL_PAGINATION_TOTAL: "total",
} as const;
type DealHealthQueryAllParamKeys = keyof typeof DEAL_HEALTH_QUERY_PARAM;
type DealHealthQueryAllParams = (typeof DEAL_HEALTH_QUERY_PARAM)[DealHealthQueryAllParamKeys];
type DealHealthQueryListParams =
    | "filterDealType"
    | "filterDsp"
    | "filterStatus"
    | "filterBuyerStatus"
    | "filterPriority";
type DealHealthQueryNonListParams = "searchKeyword" | "orderBy" | "asc" | "page" | "max" | "total";

const getDealHealthTableQueryParam = (queryString: string, key: DealHealthQueryAllParams) =>
    getQueryParam(queryString, key);

const getDealHealthTableQueryParamList = (queryString: string, key: DealHealthQueryListParams) =>
    getQueryParamList(queryString, key);

export const useDealHealthListParam = (param: DealHealthQueryListParams) => {
    const [value, setValue] = useQueryParamListState(param);
    return {
        value: value.map(Number),
        setValue,
    };
};

export const useDealHealthTypeParam = (param: DealHealthQueryNonListParams) => {
    const [value, setValue] = useQueryParamState(param);
    return {
        value,
        setValue,
    };
};

export const useDealHealthTypeParams = <T extends DealHealthQueryNonListParams>(params: T[]) => {
    const [values, setValues] = useAllQueryParamState(params);
    return {
        values,
        setValues,
    };
};

export const useDealHealthTableFilters = (): UseDealHealthTableFilters => {
    const { context } = useSeatAuthContext();
    const { search: queryString } = useLocation();

    const dealHealthFilters = useMemo(
        () => ({
            filterDealType: getDealHealthTableQueryParamList(queryString, DEAL_HEALTH_QUERY_PARAM.FILTER_DEAL_TYPE),
            filterDsp: getDealHealthTableQueryParamList(queryString, DEAL_HEALTH_QUERY_PARAM.FILTER_DSP),
            filterStatus: getDealHealthTableQueryParamList(queryString, DEAL_HEALTH_QUERY_PARAM.FILTER_INTERNAL_STATUS),
            filterBuyerStatus: getDealHealthTableQueryParamList(
                queryString,
                DEAL_HEALTH_QUERY_PARAM.FILTER_BUYER_STATUS
            ),
            filterPriorities: getDealHealthTableQueryParamList(queryString, DEAL_HEALTH_QUERY_PARAM.FILTER_PRIORITY),
        }),
        [queryString]
    );

    const dealHealthSort = useMemo(
        () => ({
            orderBy: getDealHealthTableQueryParam(queryString, DEAL_HEALTH_QUERY_PARAM.DEAL_SORT_ORDER_BY),
            isAscending: getDealHealthTableQueryParam(queryString, DEAL_HEALTH_QUERY_PARAM.DEAL_SORT_ASC) !== "false",
        }),
        [queryString]
    );

    const dealHealthSearchFilter = useMemo(
        () => ({
            searchKeyword: getDealHealthTableQueryParam(queryString, DEAL_HEALTH_QUERY_PARAM.FILTER_SEARCH),
        }),
        [queryString]
    );

    const dealHealthFilterCount = useMemo(() => {
        return Object.values(dealHealthFilters).filter((item) => item && Array.isArray(item) && item.length > 0).length;
    }, [dealHealthFilters]);

    const { page, maxResults } = useDealHealthTablePagination();
    const dealHealthPagination = useMemo(() => ({ page, maxResults }), [page, maxResults]);
    const userPreferredTimeZone = useAppSelector(selectUserTimezone);
    const userPreferredCurrencyId = useUserPreferredCurrencyId();

    const seatAllDealHealthParams = useMemo(
        () =>
            !context
                ? skipToken
                : {
                      seatId: context.id,
                      currencyId: userPreferredCurrencyId,
                      timeZoneCode: userPreferredTimeZone,
                      ...dealHealthFilters,
                      ...dealHealthSort,
                      ...dealHealthPagination,
                      ...dealHealthSearchFilter,
                  },
        [
            context,
            userPreferredCurrencyId,
            userPreferredTimeZone,
            dealHealthFilters,
            dealHealthSort,
            dealHealthPagination,
            dealHealthSearchFilter,
        ]
    );

    const { handleClearFilters } = useClearDealHealthTableFilters();
    const { setDealHealthTableSort } = useSetDealHealthTableSort();

    return {
        filterCount: dealHealthFilterCount,
        handleClearFilters,
        seatAllDealHealthParams,
        setDealHealthTableSort,
    };
};

const useUserPreferredCurrencyId = () => {
    const { dealPreferredCurrency, dealCurrencyConversionMode } = useAppSelector(
        (state) => state.deals.settings.values
    );
    return dealCurrencyConversionMode === CurrencyConversionModes.ORIGINATING_CURRENCY ? null : dealPreferredCurrency;
};

const useClearDealHealthTableFilters = () => {
    const history = useHistory();
    const { search: queryString, pathname } = useLocation();

    const handleClearFilters = useCallback(() => {
        const params = new URLSearchParams(queryString);
        Object.values(DEAL_HEALTH_QUERY_PARAM).forEach((key) => {
            params.delete(key);
        });
        history.replace({ pathname, search: params.toString() });
    }, [queryString, history, pathname]);

    return { handleClearFilters };
};

const useSetDealHealthTableSort = () => {
    const history = useHistory();
    const { search: queryString, pathname } = useLocation();

    const setDealHealthTableSort = useCallback(
        (sort: DealHealthTableSort) => {
            const params = new URLSearchParams(queryString);
            Object.entries({
                [DEAL_HEALTH_QUERY_PARAM.DEAL_SORT_ORDER_BY]: sort.orderBy,
                [DEAL_HEALTH_QUERY_PARAM.DEAL_SORT_ASC]: String(sort.asc),
            }).forEach(([key, value]) => {
                params.set(key, value);
            });
            history.replace({ pathname: pathname, search: params.toString() });
        },
        [queryString, history, pathname]
    );

    return { setDealHealthTableSort };
};
