import { useGetOperatorsQuery } from "@magnite/client-streaming-platform";
import { useMemo } from "react";
import { durationTargetToLabelValue } from "../../../../helpers";

export const useMinDurationTargetSelect = () => {
    const { data, isFetching } = useGetOperatorsQuery();
    const options = useMemo(() => durationTargetToLabelValue(data || []), [data]);

    return {
        isLoading: isFetching,
        options,
    };
};
