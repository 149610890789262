import { FC } from "react";
import { AdSource, SeatContentTransparencyRule } from "@app/core/services";
import { Col, Row } from "antd";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { DetailsItem } from "../DetailsItem";
import { format } from "@rubicon/utils";
import { Dashes } from "@app/core/components";
import { DealAdSourceTransparencyExtendedUserId } from "@app/features/deals/DealForm/types";
import { useUserAccess } from "@app/core/auth";

interface Props {
    adSource: AdSource;
}

const sharedOrHiddenOrRule = (allow: boolean, rule: SeatContentTransparencyRule | null) => {
    return rule ? rule.name : format.asSharedOrHidden(allow);
};

const sharedOrHiddenOrMasked = (allow: boolean, mask: boolean) => {
    return mask ? "Masked" : format.asSharedOrHidden(allow);
};

const extendedIdShared = (allow: boolean, ids: unknown[]) => {
    if (allow && !ids?.length) {
        return "Yes";
    }
    return allow || format.asYesOrNo(ids?.length);
};

const extendedIdTransparencies = (allow: boolean, ids: DealAdSourceTransparencyExtendedUserId[]) => {
    if (allow) {
        return "All";
    }
    if (!ids?.length) {
        return "None";
    }
    return ids.map((extendedIdTransparency) => (
        <li key={extendedIdTransparency.id}>
            {extendedIdTransparency.extendedId?.name}
            {extendedIdTransparency.network ? " - " + extendedIdTransparency.network.name : ""}
        </li>
    ));
};

export const SeatAdSourcesTransparencyDetails: FC<Props> = ({ adSource }) => {
    const { isOztamEnabled } = useUserAccess();

    return (
        <>
            <Row>
                <Col xs={24}>
                    <DetailsItem label={AD_SOURCE_FIELDS.SUPPLY_NAME.label}>
                        {format.asSharedOrHidden(adSource.allowSiteName)}
                    </DetailsItem>
                </Col>
            </Row>
            <Row>
                <Col xs={12} xl={8}>
                    <DetailsItem label={AD_SOURCE_FIELDS.DOMAIN_NAME.label}>
                        {format.asSharedOrHidden(adSource.allowDomainName)}
                    </DetailsItem>
                </Col>
                <Col xs={12} xl={8}>
                    <DetailsItem label={AD_SOURCE_FIELDS.DOMAIN_NAME_OVERRIDE.label}>
                        <Dashes value={adSource.domainNameOverride} />
                    </DetailsItem>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <DetailsItem label={AD_SOURCE_FIELDS.SUPPLY_PAGE.label}>
                        {format.asSharedOrHidden(adSource.allowSitePage) +
                            (adSource.domainNameOverride ? " - " + adSource.domainNameOverride : "")}
                    </DetailsItem>
                </Col>
                <Col xs={24}>
                    <DetailsItem label={AD_SOURCE_FIELDS.REFER_URL.label}>
                        {format.asSharedOrHidden(adSource.allowRef) +
                            (adSource.domainNameOverride ? " - " + adSource.domainNameOverride : "")}
                    </DetailsItem>
                </Col>
                <Col xs={24}>
                    <DetailsItem label={AD_SOURCE_FIELDS.FULL_IP_ADDRESS.label}>
                        {format.asSharedOrHidden(adSource.allowIp)}
                    </DetailsItem>
                </Col>
            </Row>
            <Row>
                <Col xs={12} xl={8}>
                    <DetailsItem label={AD_SOURCE_FIELDS.BUNDLE_ID.label}>
                        {format.asSharedOrHidden(adSource.allowBundleId)}
                    </DetailsItem>
                </Col>
                <Col xs={12} xl={8}>
                    <DetailsItem label={AD_SOURCE_FIELDS.BUNDLE_ID_OVERRIDE.label}>
                        <Dashes value={adSource.bundleIdOverride} />
                    </DetailsItem>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <DetailsItem label={AD_SOURCE_FIELDS.STORE_URL.label}>
                        {format.asSharedOrHidden(adSource.allowStoreUrl)}
                    </DetailsItem>
                </Col>
                <Col xs={24}>
                    <DetailsItem label={AD_SOURCE_FIELDS.USER_ID.label}>
                        {sharedOrHiddenOrMasked(adSource.allowUserId, adSource.maskUserId)}
                    </DetailsItem>
                </Col>
                <Col xs={24}>
                    <DetailsItem label={AD_SOURCE_FIELDS.CONTENT.label}>
                        {sharedOrHiddenOrRule(adSource.allowContent, adSource.contentTransparencyRule)}
                    </DetailsItem>
                </Col>
                <Col xs={24}>
                    {adSource.type?.id != AdSourceTypeIds.OPEN_AUCTION && (
                        <DetailsItem label={AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.label}>
                            {extendedIdTransparencies(
                                adSource.allowAllExtendedId,
                                adSource.allowedExtendedIdTransparencies
                            )}
                        </DetailsItem>
                    )}
                </Col>
                <Col xs={24}>
                    {adSource.type?.id != AdSourceTypeIds.OPEN_AUCTION && (
                        <DetailsItem label={AD_SOURCE_FIELDS.SHARE_EXTENDED_IDS.label}>
                            {extendedIdShared(adSource.allowAllExtendedId, adSource.allowedExtendedIdTransparencies)}
                        </DetailsItem>
                    )}
                </Col>
                <Col xs={24}>
                    <DetailsItem label={AD_SOURCE_FIELDS.ALLOW_AUDIENCE_LOCK.label}>
                        {format.asYesOrNo(adSource.allowAudienceLock)}
                    </DetailsItem>
                </Col>
                <Col xs={24}>
                    {isOztamEnabled && (
                        <DetailsItem label={AD_SOURCE_FIELDS.OZTAM_CO_VIEWING.label}>
                            {format.asYesOrNo(adSource.allowOztamCoviewing)}
                        </DetailsItem>
                    )}
                </Col>
            </Row>
        </>
    );
};
