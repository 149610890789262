import { useHistory, useLocation } from "react-router-dom";
import { getQueryParam } from "./utils";
import { useCallback } from "react";

type UseAllQueryParamStateReturnType<T extends string> = [
    Record<T, string>,
    (values: Partial<Record<T, string>>) => void
];

export const useAllQueryParamState = <T extends string>(params: T[]): UseAllQueryParamStateReturnType<T> => {
    const { search, pathname } = useLocation();
    const history = useHistory();

    const values = params.reduce((acc, param) => {
        return {
            ...acc,
            [param]: getQueryParam(search, param),
        };
    }, {}) as Record<T, string>;

    const setValues = useCallback(
        (vals: Partial<Record<T, string>>) => {
            const params = new URLSearchParams(search);
            Object.entries<string | undefined>(vals).forEach(([param, value]) => {
                if (value && value.length > 0) {
                    params.set(param, value);
                } else {
                    params.delete(param);
                }
            });
            history.replace({ pathname, search: params.toString() });
        },
        [search, history, pathname]
    );

    return [values, setValues];
};
