import { Col, Form, Input, Row } from "antd";
import { FC } from "react";
import { AdUnit, useGetSeatSuppliesQuery, useGetSeatAdUnitsQuery, Supply } from "@app/core/services";
import { SearchItemSeat, useSearchQuery } from "@magnite/client-streaming-platform";
import { BULK_OPERATION_FORM_ITEMS } from "../../../constants";
import { GenericLoadOnScrollField } from "../../../shared/GenericLoadOnScrollField";
import { CopyInventoryChildrenField } from "./CopyInventoryChildrenField";
import { useDestinationSeat } from "./useDestinationSeat";

export const BulkOperationCopyAdUnitForm: FC = () => {
    const form = Form.useFormInstance();
    const { seatId, destinationSeat } = useDestinationSeat(form);

    return (
        <>
            <Row>
                <Col xs={24} md={12}>
                    <Form.Item
                        {...BULK_OPERATION_FORM_ITEMS.AD_UNIT_TO_COPY}
                        rules={[{ required: true, message: "Please select an Ad Unit to Copy" }]}
                        wrapperCol={{ xs: 24 }}
                    >
                        <GenericLoadOnScrollField<AdUnit>
                            labelInValue
                            sdet="ad-unit-field"
                            useGetOptionsQuery={useGetSeatAdUnitsQuery}
                            extraParams={{ seatId: Number(seatId), channels: false }}
                            optionMapper={({
                                name,
                                id,

                                supply: {
                                    name: supplyName,
                                    brand: {
                                        name: brandName,
                                        publisher: { name: pubName, seat },
                                    },
                                },
                            }: AdUnit) => ({
                                label: `${seat.name} > ${pubName} > ${brandName} > ${supplyName} > ${name}`,
                                value: id,
                            })}
                            onChange={() => form.setFieldsValue({ recursiveCopy: false, copyScope: null })}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={{ span: 10, offset: 2 }}>
                    <CopyInventoryChildrenField />
                </Col>
            </Row>
            <Form.Item
                {...BULK_OPERATION_FORM_ITEMS.NAME_FOR_NEW_AD_UNIT}
                rules={[{ required: true, whitespace: true, message: "Please put a Name for New Ad Unit" }]}
                wrapperCol={{ xs: 24, md: 12 }}
            >
                <Input data-sdet="name-for-new-ad-unit-field" />
            </Form.Item>
            <Form.Item
                {...BULK_OPERATION_FORM_ITEMS.DESTINATION_SEAT}
                rules={[{ required: true, message: "Please select a Destination Seat Filter" }]}
                wrapperCol={{ xs: 24, md: 12 }}
            >
                <GenericLoadOnScrollField<SearchItemSeat>
                    labelInValue
                    sdet="destination-seat-filter-field"
                    useGetOptionsQuery={useSearchQuery}
                    extraParams={{ type: "seat" }}
                    optionMapper={({ name, id }: SearchItemSeat) => ({ label: name, value: id })}
                    onChange={() => form.setFieldValue(BULK_OPERATION_FORM_ITEMS.SELECT_SUPPLY_PARENT.name, undefined)}
                />
            </Form.Item>
            <Form.Item
                {...BULK_OPERATION_FORM_ITEMS.SELECT_SUPPLY_PARENT}
                rules={[{ required: true, message: "Please select a Supply Parent" }]}
                wrapperCol={{ xs: 24, md: 12 }}
            >
                <GenericLoadOnScrollField<Supply>
                    labelInValue
                    sdet="supply-parent-field"
                    useGetOptionsQuery={useGetSeatSuppliesQuery}
                    extraParams={{ seatId: destinationSeat?.value || seatId, channels: false }}
                    optionMapper={({
                        name,
                        id,
                        brand: {
                            name: brandName,
                            publisher: { name: pubName, seat },
                        },
                    }: Supply) => ({
                        label: `${seat.name} > ${pubName} > ${brandName} > ${name}`,
                        value: id,
                    })}
                />
            </Form.Item>
        </>
    );
};
