import { LabeledValue } from "antd/lib/select";
import { HasIdName } from "@app/core/services";
import {
    PlayerSize,
    OperatingSystem,
    OztamDemo,
    Operator,
    ContentMetadataTvSeries,
    UNKNOWN,
} from "@magnite/client-streaming-platform";

import {
    AdUnitsApiFramework,
    AdUnitsMime,
    Category,
    ContentCategory,
    IabCategory,
    Platform,
    SupplyType,
    TargetingMode,
} from "@app/core/services/console";
import {
    Operations,
    SUPPLY_TYPE_APPLICATION,
    SUPPLY_TYPE_SITE,
    TARGETING_MODE_PAYLOAD_MERGE,
    TARGETING_MODE_PAYLOAD_OR,
    TargetingDimensionTypes,
} from "../constants";
import {
    CustomRuleGroup,
    MaxDurationTarget,
    MinDurationTarget,
    SupplyTypesTargetsDimension,
    TargetingDimension,
    UsedDimensions,
} from "../types";
import { Typography } from "antd";

export const hasIdNameToLabelValue = (targets: HasIdName[] | null): LabeledValue[] =>
    (targets || []).map((target) => ({
        value: JSON.stringify(target),
        label: target.name,
    }));

export const formatCategoryLabel = (target: Category | IabCategory | ContentCategory, typename = "Unknown") => {
    if (target.name === UNKNOWN) {
        return (
            <span>
                {typename}: {UNKNOWN}
            </span>
        );
    }

    if ("code" in target && target.code) {
        if (target.parentName) {
            return (
                <span>
                    {target.code}: {target.parentName}: {target.name}
                </span>
            );
        }
        return (
            <strong>
                {target.code}: {target.name}
            </strong>
        );
    }

    if (target.parentName) {
        return (
            <span>
                {target.parentName}: {target.name}
            </span>
        );
    }
    return <strong>{target.name}</strong>;
};

export const formatCategorySearchValue = (target: Category | IabCategory | ContentCategory, typename = "Unknown") => {
    if (target.name === UNKNOWN) {
        return `${typename}: ${UNKNOWN}`;
    }

    if ("code" in target && target.code) {
        if (target.parentName) {
            return `${target.code}: ${target.parentName}: ${target.name}`;
        }
        return `${target.code}: ${target.name}`;
    }

    if (target.parentName) {
        return `${target.parentName}: ${target.name}`;
    }

    return `${target.name}`;
};

export const parseCategoryToApi = (target: Category | IabCategory | ContentCategory) => {
    if ("search" in target) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { search, ...rest } = target as Category | IabCategory | ContentCategory;
        return rest;
    }
    return target;
};

export const parseTvSeriesToApi = (target: ContentMetadataTvSeries) => ({
    seriesName: target.seriesName,
    season: target.season,
    episode: target.episode,
});

export const platformTargetToLabelValue = (targets: Platform[] | null): (LabeledValue & { searchLabel: string })[] =>
    (targets || []).map((target) => ({
        value: JSON.stringify(target),
        label: !target.parentId ? <Typography.Text strong>{target.name}</Typography.Text> : target.name,
        searchLabel: target.name,
    }));

export const operatingSystemToLabelValue = (targets: OperatingSystem[] | null): LabeledValue[] =>
    (targets || []).map((target) => ({
        value: JSON.stringify(target),
        label: target.name,
    }));

export const categoryToLabelValue = (
    targets: (Category | IabCategory | ContentCategory)[] | null,
    typename: string
): (LabeledValue & { search: string })[] =>
    (targets || []).map((target) => ({
        value: JSON.stringify({ id: target.id, name: target.name }),
        label: formatCategoryLabel(target, typename),
        search: formatCategorySearchValue(target, typename),
    }));

export const playerSizesToLabelValue = (targets: PlayerSize[] | null): LabeledValue[] =>
    (targets || []).map((target) => ({
        value: JSON.stringify(target),
        label: target.name,
    }));

export const apiFrameworkToLabelValue = (targets: AdUnitsApiFramework[] | null): LabeledValue[] =>
    (targets || []).map((target) => ({
        value: JSON.stringify(target),
        label: target.name,
    }));

export const supplyTypesToLabelValue = (targets: SupplyType[] | null): LabeledValue[] => {
    return (targets || []).map((target) => ({
        value: JSON.stringify(target),
        label: target.name,
    }));
};

export const mimeTypeToLabelValue = (targets: AdUnitsMime[] | null): LabeledValue[] =>
    (targets || []).map((target) => ({
        value: JSON.stringify(target),
        label: target.name,
    }));

export const durationTargetToLabelValue = (targets: Pick<Operator, "id" | "name">[] | null): LabeledValue[] =>
    (targets || []).map((target) => ({
        value: target.id,
        label: target.name,
    }));

export const bvodToLabelValue = (targets: OztamDemo[] | null): LabeledValue[] =>
    (targets || []).map((target) => ({
        value: JSON.stringify(target),
        label: target.name,
    }));

export const convertCustomRuleGroupToCustomRules = (customRule: CustomRuleGroup | null): string[] => {
    if (customRule === null) {
        return [];
    }
    return [JSON.stringify(customRule)];
};

const matchSupplyType = (
    supplyTypes: SupplyTypesTargetsDimension,
    matcher: typeof SUPPLY_TYPE_APPLICATION | typeof SUPPLY_TYPE_SITE
): boolean =>
    supplyTypes &&
    supplyTypes.values.length > 0 &&
    (JSON.parse(supplyTypes?.values[0]?.value as string) as SupplyType)?.id === matcher;

export const isEnrichmentTargetingDisabled = (usedDimensions: UsedDimensions) =>
    usedDimensions.audiences.includes || usedDimensions.segmentRules.includes || usedDimensions.segmentRules.excludes;

export const isSegmentRuleTargetingDisabled = (usedDimensions: UsedDimensions) =>
    usedDimensions.audiences.includes || (usedDimensions.segmentRules.includes && usedDimensions.segmentRules.excludes);

const IS_ADD_DISABLED_BY_TYPE = {
    [TargetingDimensionTypes.AdBreakPositionTargets]: (usedDimensions: UsedDimensions) =>
        usedDimensions.adBreakPositionTargets.includes || usedDimensions.adBreakPositionTargets.excludes,
    [TargetingDimensionTypes.Audiences]: (_: UsedDimensions, usedDimensionsArr: UsedDimensions[]) => {
        return usedDimensionsArr.some((v) => isEnrichmentTargetingDisabled(v));
    },
    [TargetingDimensionTypes.ApiFrameworks]: (usedDimensions: UsedDimensions) =>
        usedDimensions.apiFrameworks.includes || usedDimensions.apiFrameworks.excludes,
    [TargetingDimensionTypes.BundleIdTargets]: (
        usedDimensions: UsedDimensions,
        _: UsedDimensions[],
        supplyTypes: SupplyTypesTargetsDimension
    ) => {
        return (
            matchSupplyType(supplyTypes, SUPPLY_TYPE_SITE) ||
            usedDimensions.bundleIdTargets.includes ||
            usedDimensions.bundleIdTargets.excludes
        );
    },
    [TargetingDimensionTypes.Bvod]: (usedDimensions: UsedDimensions) =>
        usedDimensions.oztamDemographics.includes && usedDimensions.oztamDemographics.excludes,
    [TargetingDimensionTypes.Categories]: (usedDimensions: UsedDimensions) =>
        usedDimensions.categories.includes || usedDimensions.categories.excludes,
    [TargetingDimensionTypes.ContentCategories]: (usedDimensions: UsedDimensions) =>
        usedDimensions.contentCategories.includes || usedDimensions.contentCategories.excludes,
    [TargetingDimensionTypes.ContentChannels]: (usedDimensions: UsedDimensions) =>
        usedDimensions.contentChannels.includes || usedDimensions.contentChannels.excludes,
    [TargetingDimensionTypes.ContentLengths]: (usedDimensions: UsedDimensions) =>
        usedDimensions.contentLengths.includes || usedDimensions.contentLengths.excludes,
    [TargetingDimensionTypes.ContentNetworks]: (usedDimensions: UsedDimensions) =>
        usedDimensions.contentNetworks.includes || usedDimensions.contentNetworks.excludes,
    [TargetingDimensionTypes.ContentRatings]: (usedDimensions: UsedDimensions) =>
        usedDimensions.contentRatings.includes || usedDimensions.contentRatings.excludes,
    [TargetingDimensionTypes.ContentSeries]: (usedDimensions: UsedDimensions) =>
        usedDimensions.contentSeries.includes || usedDimensions.contentSeries.excludes,
    [TargetingDimensionTypes.Coppa]: (usedDimensions: UsedDimensions) =>
        usedDimensions.coppa.includes || usedDimensions.coppa.excludes,
    [TargetingDimensionTypes.CustomRules]: (usedDimensions: UsedDimensions) =>
        usedDimensions.customRules.includes && usedDimensions.customRules.excludes,
    [TargetingDimensionTypes.CustomTargets]: (usedDimensions: UsedDimensions) =>
        usedDimensions.customTargets.includes || usedDimensions.customTargets.excludes,
    [TargetingDimensionTypes.DayPartTargets]: (usedDimensions: UsedDimensions) =>
        usedDimensions.dayPartTargets.includes || usedDimensions.dayPartTargets.excludes,
    [TargetingDimensionTypes.Dnt]: (usedDimensions: UsedDimensions) =>
        usedDimensions.dnt.includes || usedDimensions.dnt.excludes,
    [TargetingDimensionTypes.Genres]: (usedDimensions: UsedDimensions) =>
        usedDimensions.genres.includes && usedDimensions.genres.excludes,
    [TargetingDimensionTypes.GeoTargets]: (usedDimensions: UsedDimensions) =>
        usedDimensions.geoTargets.includes && usedDimensions.geoTargets.excludes,
    [TargetingDimensionTypes.Inventory]: (usedDimensions: UsedDimensions) =>
        usedDimensions.inventory.includes && usedDimensions.inventory.excludes,
    [TargetingDimensionTypes.LabelValues]: (usedDimensions: UsedDimensions) =>
        usedDimensions.labelValues.includes || usedDimensions.labelValues.excludes,
    [TargetingDimensionTypes.LiveStream]: (usedDimensions: UsedDimensions) =>
        usedDimensions.liveStream.includes || usedDimensions.liveStream.excludes,
    [TargetingDimensionTypes.MaxDurationTarget]: (usedDimensions: UsedDimensions) =>
        usedDimensions.maxDurationTarget.includes || usedDimensions.maxDurationTarget.excludes,
    [TargetingDimensionTypes.MimeTypes]: (usedDimensions: UsedDimensions) =>
        usedDimensions.mimeTypes.includes || usedDimensions.mimeTypes.excludes,
    [TargetingDimensionTypes.MinDurationTarget]: (usedDimensions: UsedDimensions) =>
        usedDimensions.minDurationTarget.includes || usedDimensions.minDurationTarget.excludes,
    [TargetingDimensionTypes.OperatingSystems]: (usedDimensions: UsedDimensions) =>
        usedDimensions.operatingSystems.includes || usedDimensions.operatingSystems.excludes,
    [TargetingDimensionTypes.Platforms]: (usedDimensions: UsedDimensions) =>
        usedDimensions.platforms.includes || usedDimensions.platforms.excludes,
    [TargetingDimensionTypes.Pmp]: (usedDimensions: UsedDimensions) =>
        usedDimensions.pmpDealIdTargets.includes || usedDimensions.pmpDealIdTargets.excludes,
    [TargetingDimensionTypes.Producers]: (usedDimensions: UsedDimensions) =>
        usedDimensions.producers.includes || usedDimensions.producers.excludes,
    [TargetingDimensionTypes.SegmentRules]: (usedDimensions: UsedDimensions, usedDimensionsArr: UsedDimensions[]) =>
        isSegmentRuleTargetingDisabled(usedDimensions) || usedDimensionsArr.some((v) => v.audiences.includes),
    [TargetingDimensionTypes.Sizes]: (usedDimensions: UsedDimensions) =>
        usedDimensions.sizes.includes || usedDimensions.sizes.excludes,
    [TargetingDimensionTypes.SupplyDomainTargets]: (
        usedDimensions: UsedDimensions,
        _: UsedDimensions[],
        supplyTypes: SupplyTypesTargetsDimension
    ) =>
        matchSupplyType(supplyTypes, SUPPLY_TYPE_APPLICATION) ||
        usedDimensions.supplyDomainTargets.includes ||
        usedDimensions.supplyDomainTargets.excludes,
    [TargetingDimensionTypes.SupplyTypes]: (usedDimensions: UsedDimensions) =>
        usedDimensions.supplyTypes.includes || usedDimensions.supplyTypes.excludes,
    [TargetingDimensionTypes.VideoIds]: (usedDimensions: UsedDimensions) =>
        usedDimensions.videoIds.includes || usedDimensions.videoIds.excludes,
};

export const isAddDisabled = (
    type: TargetingDimensionTypes,
    usedDimensions: UsedDimensions,
    usedDimensionsArr?: UsedDimensions[],
    supplyTypes?: TargetingDimension
) => {
    return IS_ADD_DISABLED_BY_TYPE[type](usedDimensions, usedDimensionsArr, supplyTypes);
};

export const toTargetingModePayload = (operation: Operations) => {
    return operation === Operations.All ? TARGETING_MODE_PAYLOAD_MERGE : TARGETING_MODE_PAYLOAD_OR;
};

export const toTargetingModePayloadId = (operation: Operations) => toTargetingModePayload(operation).id;

export const toTargetingOperation = (targetingModeId: number): Operations => {
    return targetingModeId === TARGETING_MODE_PAYLOAD_MERGE.id ? Operations.All : Operations.Any;
};

export const targetingModePayloadToTargetingOperation = (targetingMode: TargetingMode): Operations =>
    toTargetingOperation(targetingMode.id);

const min = (a: number, b: number) => a < b;
const max = (a: number, b: number) => a > b;

export function minMaxDurationByOperation<
    T extends MinDurationTarget | MaxDurationTarget,
    F extends keyof T & ("minDuration" | "maxDuration")
>(targets: T[], operation: Operations, field: F): T | null {
    // https://magnite.atlassian.net/browse/CTVUI-5521?focusedCommentId=1450641
    if (!targets.length) {
        return null;
    }

    if (targets.length < 2 || operation === Operations.Any) {
        return targets[0];
    }

    const operator = field === "maxDuration" ? min : max;

    return targets.slice(1).reduce((acc, value) => {
        if (operator(value[field] as number, acc[field] as number)) {
            acc = value;
        }
        return acc;
    }, targets[0]);
}
