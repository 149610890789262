import { Form } from "antd";
import { useContext } from "react";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "../constants";
import { AdSourceFormsContext } from "./SeatAdSourcesForm";

export const useIsPbsDealType = () => {
    const {
        forms: { adSourceForm },
    } = useContext(AdSourceFormsContext);
    const adSourceType = Form.useWatch(AD_SOURCE_FIELDS.TYPE.name, adSourceForm);
    const adSourceTypeId = adSourceType?.id;

    return adSourceTypeId === AdSourceTypeIds.PRE_BID_DEALS;
};
