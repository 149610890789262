import { AdUnit, Brand, BrandChannel, BrandSupply } from "@app/core/services";
import { Publisher } from "@magnite/client-streaming-platform";
import { FC } from "react";
import { InventoryDetailsDrawerType } from "../../../reducer";
import {
    AdUnitSections,
    BrandSections,
    ChannelSections,
    PublisherSections,
    SupplySections,
} from "@app/features/inventory/InventoryHealthDetailsPages";

interface Props {
    drawerType: InventoryDetailsDrawerType;
    publisher?: Publisher;
    brand?: Brand;
    supply?: BrandSupply;
    adUnit?: AdUnit;
    channel?: BrandChannel;
}

export const DetailsDrawerViewSwitch: FC<Props> = ({ drawerType, publisher, brand, supply, adUnit, channel }) => {
    switch (drawerType) {
        case InventoryDetailsDrawerType.PUBLISHER:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return <PublisherSections publisher={publisher} />;
        case InventoryDetailsDrawerType.BRAND:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return <BrandSections brand={brand} />;
        case InventoryDetailsDrawerType.SUPPLY:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return <SupplySections supply={supply} />;
        case InventoryDetailsDrawerType.AD_UNIT:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return <AdUnitSections adUnit={adUnit} />;
        case InventoryDetailsDrawerType.CHANNEL:
        case InventoryDetailsDrawerType.BRAND_CHANNEL:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return <ChannelSections channel={channel} />;
        default:
            return null;
    }
};
