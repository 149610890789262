import { css } from "@emotion/css";
import { Col, Form, Row } from "antd";
import { FC } from "react";
import { FreqCapping } from "../DealForm/types";
import { FrequencyCappingTagItem } from "@app/core/components/Fields/FrequencyCappingTagItem";
import { Dashes } from "@app/core/components";
import { gray5 } from "@rubicon/antd-components";

const className = css`
    padding: 10px 14px;
    border: 1px solid ${gray5};
    border-radius: 2px;
`;

interface Props {
    enforcement?: string | null;
    frequencyCappingType?: {
        id: number;
        name: string;
    } | null;
    dealFrequencyCapping?: FreqCapping[] | null;
    transactionCurrency?: string | null;
}

export const DealDetailsDemandConfiguration: FC<Props> = ({
    enforcement,
    frequencyCappingType,
    dealFrequencyCapping,
    transactionCurrency,
}) => {
    return (
        <Row>
            <Col xs={12} xl={8}>
                <Form.Item label="Transaction Currency">
                    <Dashes value={transactionCurrency} />
                </Form.Item>
            </Col>
            <Col xs={12} xl={8}>
                <Form.Item label="Enforcement">
                    <Dashes value={enforcement} />
                </Form.Item>
            </Col>
            <Col xs={12} xl={8}>
                <Form.Item label="Deal Frequency Capping">
                    {frequencyCappingType?.name}
                    {Boolean(dealFrequencyCapping?.length) && (
                        <div className={className}>
                            {dealFrequencyCapping?.map(({ id, mode, total, timeDurationMin }) => (
                                <FrequencyCappingTagItem
                                    key={id}
                                    name={mode.name}
                                    timeDuration={timeDurationMin}
                                    total={total}
                                />
                            ))}
                        </div>
                    )}
                </Form.Item>
            </Col>
        </Row>
    );
};
