import { intl } from "@rubicon/utils";
import { MINUTES_IN, NONE } from "@app/core/components/constants";
import { AuthorizedExportStream } from "@app/core/services/console/externalDataStreams";
import { AdSourceCountLimit, AdSourceQpsLimit, Dmp, FinanceDefault, IabCategory, Seat } from "@app/core/services";
import { DefinationField } from "@app/core/components/HelpKeysDrawer";
import client from "@app/core/clients/console/client";
import { AD_SOURCE_TYPES_NEXT_UI_NAMES } from "@app/features/seatAdSources/constants";
import { Label } from "@magnite/client-streaming-platform";

export const getFinanceCostValue = (finance: FinanceDefault): string => {
    if (!finance?.costValuePercent && !finance?.costValueFixed) {
        return NONE;
    }
    if (finance?.costValueFixed) {
        return `${finance?.costValueFixed / 1000} CPM`;
    }
    return `${finance?.costValuePercent}%`;
};
export const getFinanceType = (finance: FinanceDefault): string => {
    if (finance?.adSourceType) {
        return AD_SOURCE_TYPES_NEXT_UI_NAMES[finance.adSourceType?.name];
    }
    if (finance?.lineItemType) {
        return finance.lineItemType?.name;
    }
    return NONE;
};

export const getQpsLimitLabel = (limit: AdSourceQpsLimit): string => {
    const { qpsLimit, awsRegion } = limit;
    return `${awsRegion?.name} limit ${qpsLimit}`;
};

export const getLimitLabel = (limit: AdSourceCountLimit): string => {
    const { maxAllowed, adSourceType } = limit;
    return `${adSourceType?.name} limit ${maxAllowed}`;
};
export const getUdeCpmFee = (
    udeCpmFee: number | null,
    udeCpmFeeCurrency: { id: number; code: string } | null
): string => {
    if (!udeCpmFee || !udeCpmFeeCurrency) return NONE;
    return `${intl.moneyFormatter(udeCpmFee / 1000, udeCpmFeeCurrency?.code)} (${udeCpmFeeCurrency?.code})`;
};
export const getDmpTypeLabel = (dmp: Dmp): string => {
    if (dmp.entityType === "Dmp") {
        return `[DMP] ${dmp.name}`;
    }
    return `[DMP View] ${dmp.name}`;
};
export const getAuthorizedExportStreamLabel = (stream: AuthorizedExportStream): string => {
    return `${stream.type.name} - ${stream.name}`;
};
export const getSeatFallbackFloor = (seat: Seat): string =>
    `${intl.moneyFormatter(seat.fallbackFloor / 1000, seat.floorCurrency.code)} (${seat.floorCurrency.code})`;
export const getSeatLabelValueLabel = (label: Label, value: string): string => {
    return `${label.key} : ${value}`;
};
export const getAdSourceDuration = (duration: number | null) => (duration !== null ? duration : NONE);

export const filterDefinitionsFieldsByHelpPathKeys = (definitionsFields: DefinationField[], helpPathKeys: string[]) =>
    definitionsFields.filter((field) => !helpPathKeys.includes(field.helpPath));

export const getFloorPrice = (price: number, currencyCode: string) => intl.moneyFormatter(price, currencyCode);

export const downloadTags = (seatId: string) => {
    window.location.assign(`${client.defaults.baseURL}/platform/resources/seats/${seatId}/adUnitTags`);
};

export const getDimension = (timeDurationMin: number) => {
    if (timeDurationMin < MINUTES_IN.HOUR) {
        return timeDurationMin + " minute" + (timeDurationMin === 1 ? "" : "s");
    }

    if (timeDurationMin % MINUTES_IN.MONTH === 0) {
        return timeDurationMin / MINUTES_IN.MONTH + " month" + (timeDurationMin / MINUTES_IN.MONTH === 1 ? "" : "s");
    }

    if (timeDurationMin % MINUTES_IN.WEEK === 0) {
        return timeDurationMin / MINUTES_IN.WEEK + " week" + (timeDurationMin / MINUTES_IN.WEEK === 1 ? "" : "s");
    }

    if (timeDurationMin % MINUTES_IN.DAY === 0) {
        return timeDurationMin / MINUTES_IN.DAY + " day" + (timeDurationMin / MINUTES_IN.DAY === 1 ? "" : "s");
    }

    if (timeDurationMin % MINUTES_IN.HOUR === 0) {
        return timeDurationMin / MINUTES_IN.HOUR + " hour" + (timeDurationMin / MINUTES_IN.HOUR === 1 ? "" : "s");
    }

    const hours = timeDurationMin / MINUTES_IN.HOUR;
    const fullHour = parseInt(String(hours));
    const mins = timeDurationMin - fullHour * MINUTES_IN.HOUR;

    return (
        fullHour +
        " hour" +
        (fullHour === 1 ? "" : "s") +
        (mins === 0 ? "" : " and " + mins + " minute" + (mins === 1 ? "" : "s"))
    );
};

export const getSeparationGroupLabel = (name: string, iabCategories: IabCategory[]): string => {
    if (iabCategories.length) {
        return `${name} [Type: IAB Categories]`;
    }
    return `${name} [Type: Industry]`;
};
