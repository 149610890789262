import { FC, ReactNode } from "react";
import { Form, Typography } from "antd";
import { LabelTooltipType } from "antd/lib/form/FormItemLabel";

interface ReadOnlyFieldProps {
    label: ReactNode;
    children: ReactNode;
    tooltip?: LabelTooltipType;
    required?: boolean;
    message?: string;
    dataSdet?: string;
}

export const ReadOnlyField: FC<ReadOnlyFieldProps> = ({ label, children, tooltip, required, message, dataSdet }) => {
    return (
        <Form.Item
            data-sdet={dataSdet}
            label={label}
            tooltip={tooltip}
            required={required}
            rules={[{ required, message }]}
        >
            <Typography.Text>{children}</Typography.Text>
        </Form.Item>
    );
};
