import { FC } from "react";
import { Brand, Seat } from "@app/core/services";
import { Publisher } from "@magnite/client-streaming-platform";
import { AffiliateCostValue } from "@app/features/inventory/components/FormItems";
import { REVSHARE_PERCENTAGE_COST_MODEL } from "@app/features/inventory/InventorySeat/constants";
import { useBrandFormInstance } from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormInstance";

const getExtra = (seat: Seat | null, publisher: Publisher | undefined, brand: Brand | undefined): string | null => {
    if (brand?.affiliateCostModel?.id === REVSHARE_PERCENTAGE_COST_MODEL.value) {
        return `Inherited value: ${
            brand?.affiliateCostValuePercent ?? publisher?.affiliateCostValuePercent ?? seat?.affiliateCostValuePercent
        } %`;
    }
    const affiliateCostValueFixed: number | null | undefined =
        brand?.affiliateCostValueFixed ?? publisher?.affiliateCostValueFixed ?? seat?.affiliateCostValueFixed;

    if (typeof affiliateCostValueFixed === "number") return `Inherited value: ${affiliateCostValueFixed / 1000} CPM`;

    return null;
};

export const CostValue: FC = () => {
    const { seat, publisher, brand } = useBrandFormInstance();
    const extra: string | null = getExtra(seat, publisher, brand);
    return <AffiliateCostValue extra={extra} wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }} />;
};
