import { Form } from "antd";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { SeatAdSourcesType } from "@app/core/services";

export const useIsFieldVisible = () => {
    const adSourceTypeId = Form.useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name)?.id;

    return [
        AdSourceTypeIds.PROGRAMMATIC_GUARANTEED,
        AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED,
        AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED,
        AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED,
    ].includes(adSourceTypeId);
};
