import { FC } from "react";
import { Form, Input } from "antd";
import { FORM_ITEMS } from "../../../constants";
import { formatEstImpOpportunities } from "../../../utils";

export const EstimatedImpressionOpportunities: FC = () => {
    return (
        <Form.Item name={FORM_ITEMS.EST_IMP_OPPORTUNITIES.name} label={FORM_ITEMS.EST_IMP_OPPORTUNITIES.label}>
            <Input placeholder={`e.g. ${formatEstImpOpportunities(10000000)}`} />
        </Form.Item>
    );
};
