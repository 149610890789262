import { LabeledValue } from "antd/lib/select";
import {
    InventoryCodeType,
    HierarchyFormsMode,
    NOT_SPECIFIED_OPTION,
    INVENTORY_GENERATED_CODE_TYPE,
    INVENTORY_NOT_AVAILABLE_RESELL_TYPE,
    INVENTORY_UNKNOWN_VIDEO_QUALITY_TYPE,
    MIME_TYPES,
    AD_UNITS_PROTOCOLS,
    LINEARITY_BOTH_ALLOWED,
    AD_UNIT_PRE_ROLL_TYPE,
    AD_UNITS_MULTIPLICITIES,
    DEFAULT_AD_UNIT_INTERSTITIAL,
    AD_UNIT_IN_STREAM_PLACEMENT_TYPE,
    AD_UNIT_ACTIVE_STATUS,
    DEFAULT_AD_UNIT_MIN_DURATION,
    DEFAULT_AD_UNIT_MAX_DURATION,
    NOT_ALLOWED_MAX_EXTENDED,
    UNNLIMITED_MAX_EXTENDED,
    CUSTOM_MAX_EXTENDED,
    DEFAULT_AD_UNIT_LIVE_STREAM,
    UNKNOWN_OPTION,
    OPTIMIZE_FILL_MODE,
    NONE_POD_ENFORCEMENT,
    DEFAULT_PLAYLIST_DEFINITION_MODE,
    IAB_CATEGORIES_COMPETITIVE_SEPARATION_MODE,
    DEFAULT_ALLOW_VAST_WRAPPER,
    DEFAULT_LIVE_STREAM_ACCELERATION,
    DEFAULT_UNWRAP_VAST,
    DEFAULT_ADVERTISERS_AS_ALLOW_LIST,
    DEFAULT_ENABLE_NO_AD_COOKIE_SYNC,
    DEFAULT_ADVERTISER_FREQUENCY_CAPPING,
    DEFAULT_AD_UNIT_BOXING,
    DEFAULT_WAIT_TIME,
    BREAK_RULE_PRE_ROLL,
    BREAK_RULE_MID_ROLL,
    BREAK_RULE_POST_ROLL,
    PLAYLIST_DEFINITION_MODE_OPTIONS,
    SUPPLY_TYPES,
    EXTENDED_IMP_TIME_LINEAR_DEFAULT,
    NONE_OPTION,
    OFF_WAIT_TIME,
} from "@app/features/inventory/HierarchyForms/constants";
import { getDimension, getSeatLabelValueLabel, getSeparationGroupLabel } from "@app/features/inventory/helpers";
import { CREATIVE_BLOCK_MODE_OFF_OPTION } from "@app/features/inventory/InventorySeat/constants";
import { Publisher } from "@magnite/client-streaming-platform";
import { AdUnit, Brand, BrandSupply, Seat, AdomainFreqCapping, AdBreakRules } from "@app/core/services";
import { LabelValueOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/useSeatDetailsForm";
import { LabeledValueOpotions } from "../components/Fields/FrequencyCaps/useFrequencyCaps";
import { SeparationGroupOption } from "@app/features/inventory/components/FormItems/AdUnitCompetitiveSeparationGroups/useAdUnitCompetitiveSeparationGroups";
import {
    AdBreakRuleAds,
    AdBreakRuleDuration,
    AdBreakRuleIndex,
    AdBreakRuleMidRoll,
    AdBreakRuleName,
    AdBreakRulePostRoll,
    AdBreakRulePreRoll,
} from "../components/Fields";
import { formatCategoryLabel } from "@app/features/targeting";

export const getCodeExtraMessage = (
    seat: Seat | null,
    isTremorUser: boolean,
    mode: HierarchyFormsMode,
    code: string
): string | undefined => {
    const isEditMode: boolean = mode === "edit";
    if (!seat || isEditMode) return undefined;
    if (!isEditMode && isTremorUser) return "The Actual code doesn't have a seat prefix since you're an admin";
    return `Actual Code: ${seat.code + code}`;
};
export const getCodeRequiredMark = (
    isGeneratedCodeType: boolean,
    mode: HierarchyFormsMode,
    isTremorUser: boolean,
    hasEditAccess: boolean
): boolean => {
    const isEditMode: boolean = mode === "edit";
    if (isEditMode && hasEditAccess) return true;
    return !isEditMode && isTremorUser && !isGeneratedCodeType;
};
export const getCodeAddonBefore = (
    seat: Seat | null,
    isTremorUser: boolean,
    mode: HierarchyFormsMode
): string | undefined => {
    const isEditMode: boolean = mode === "edit";
    if (!seat || isEditMode) return undefined;
    if (!isEditMode && !isTremorUser) return seat.code;
    return undefined;
};

export const parseCodeValue = (codeType: InventoryCodeType, code: string): string => {
    const isGeneratedCodeType: boolean = codeType === INVENTORY_GENERATED_CODE_TYPE.value;
    if (isGeneratedCodeType) return "";
    return code.trim();
};

export const parseBlockedAdvertiserDomains = (
    mode: HierarchyFormsMode,
    data: Publisher | Brand | BrandSupply | AdUnit | undefined
): LabeledValue[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.seatAdvertiserDomainFilterListDefs) {
        return [];
    }

    return (data?.seatAdvertiserDomainFilterListDefs).map((domain) => ({
        value: domain.id,
        label: domain.name,
    }));
};
export const parseAffiliateCostModel = (
    mode: HierarchyFormsMode,
    data: Publisher | Brand | BrandSupply | AdUnit | undefined
): LabeledValue | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.affiliateCostModel) return null;

    return {
        value: data?.affiliateCostModel.id,
        label: data?.affiliateCostModel.name,
    };
};
export const parseAffiliateCostValuePercent = (
    mode: HierarchyFormsMode,
    data: Publisher | Brand | BrandSupply | AdUnit | undefined
): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return null;
    return data?.affiliateCostValuePercent ?? null;
};

export const parseAffiliateCostValueFixed = (
    mode: HierarchyFormsMode,
    data: Publisher | Brand | BrandSupply | AdUnit | undefined
): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || typeof data?.affiliateCostValueFixed !== "number") return null;
    return data.affiliateCostValueFixed / 1000;
};

export const parseAdvertiserBlockDomains = (
    mode: HierarchyFormsMode,
    data: Publisher | Brand | BrandSupply | AdUnit | undefined
): string => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !Array.isArray(data?.advertiserBlockDomains) || !data?.advertiserBlockDomains?.length)
        return "";
    return data.advertiserBlockDomains.join("\n");
};
export const parseBuyerSeatList = (
    mode: HierarchyFormsMode,
    data: Publisher | Brand | BrandSupply | AdUnit | undefined
): LabeledValue | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.buyerSeatList) return null;
    return { value: data.buyerSeatList.id, label: data.buyerSeatList.name };
};
export const parseCreativeBlockingMode = (mode: HierarchyFormsMode, data: Publisher | Brand | undefined): number => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.creativeBlockingMode?.id) return CREATIVE_BLOCK_MODE_OFF_OPTION.value;
    return data.creativeBlockingMode.id;
};

export const parseBlockedIabCategories = (mode: HierarchyFormsMode, data: Brand | undefined): LabeledValue[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return [];
    return (data?.blockedIabCategories || []).map((category) => ({
        value: category.id,
        label: formatCategoryLabel(category),
    }));
};
export const parseAllowVastWrappers = (mode: HierarchyFormsMode, data: BrandSupply | undefined): boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return DEFAULT_ALLOW_VAST_WRAPPER;
    return !!data?.allowVastWrappers;
};
export const parseUnwrapVast = (mode: HierarchyFormsMode, data: AdUnit | undefined): boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return DEFAULT_UNWRAP_VAST;
    return !!data?.unwrapVast;
};
export const parseLsaEnabled = (mode: HierarchyFormsMode, data: AdUnit | undefined): boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return DEFAULT_LIVE_STREAM_ACCELERATION;
    return !!data?.lsaEnabled;
};
export const parseLsaWindowSizeSeconds = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.lsaWindowSizeSeconds) return null;
    return data.lsaWindowSizeSeconds;
};
export const parseIabCategories = (mode: HierarchyFormsMode, data: Brand | undefined): LabeledValue[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return [];
    return (data?.iabCategories || []).map((category) => ({
        value: category.id,
        label: formatCategoryLabel(category),
    }));
};

export const getExtraUseRate = (
    isEditMode: boolean,
    data: Publisher | Brand | BrandSupply | undefined,
    isLowerRate: boolean
): string | undefined => {
    if (isLowerRate && data?.calculatedCalculonUseRateOverride && !data.calculonUseRateOverride)
        return `Inherited value: ${data.calculatedCalculonUseRateOverride}`;

    if (data?.calculatedUpperCalculonUseRateOverride && !data.upperCalculonUseRateOverride)
        return `Inherited value: ${data.calculatedUpperCalculonUseRateOverride}`;
    return "Values should be between .001 - .999";
};

export const parsePublisherReauction = (
    mode: HierarchyFormsMode,
    data: Publisher | Brand | BrandSupply | AdUnit | undefined
): string | boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode || data?.publisherReauction === null || data?.publisherReauction === undefined)
        return NOT_SPECIFIED_OPTION.value;
    return data.publisherReauction;
};

export const parseThirdPartyPixels = (
    mode: HierarchyFormsMode,
    data: Publisher | Brand | BrandSupply | AdUnit | undefined
): LabeledValue[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.thirdPartyPixels) return [];
    return data.thirdPartyPixels.map((pixel) => ({
        value: pixel.id,
        label: pixel.name,
    }));
};
export const parseLabelValues = (
    mode: HierarchyFormsMode,
    data: Publisher | Brand | BrandSupply | AdUnit | undefined
): LabelValueOption[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.labelValues) return [];
    return data.labelValues.map(({ id, label, value }) => ({
        value: id,
        label: getSeatLabelValueLabel(label, value),
        labelValue: value,
        seatLabel: label,
    }));
};
export const parseAdUnitSeparationGroups = (
    mode: HierarchyFormsMode,
    data: AdUnit | undefined
): SeparationGroupOption[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.separationGroups) return [];
    return data.separationGroups.map(({ id, name, industries, iabCategories }) => ({
        value: id,
        label: getSeparationGroupLabel(name, iabCategories),
        industries,
        iabCategories,
    }));
};

export const parseInternalLabelValues = (
    mode: HierarchyFormsMode,
    data: Publisher | Brand | BrandSupply | AdUnit | undefined
): LabelValueOption[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.internalLabelValues) return [];
    return data.internalLabelValues.map(({ id, label, value }) => ({
        value: id,
        label: getSeatLabelValueLabel(label, value),
        labelValue: value,
        seatLabel: label,
    }));
};
export const parseDistributionGroupLabelValues = (
    mode: HierarchyFormsMode,
    data: Brand | undefined
): LabelValueOption[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.distributionGroupLabelValues) return [];
    return data.distributionGroupLabelValues.map(({ id, label, value }) => ({
        value: id,
        label: getSeatLabelValueLabel(label, value),
        labelValue: value,
        seatLabel: label,
    }));
};
export const parsePrefilterLabelValues = (
    mode: HierarchyFormsMode,
    data: BrandSupply | AdUnit | undefined
): LabelValueOption[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.prefilterLabelValues) return [];
    return data.prefilterLabelValues.map(({ id, label, value }) => ({
        value: id,
        label: getSeatLabelValueLabel(label, value),
        labelValue: value,
        seatLabel: label,
    }));
};
export const parseAppStore = (mode: HierarchyFormsMode, data: BrandSupply | undefined): LabeledValue | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.appStore) return null;
    return { value: data.appStore.id, label: data.appStore.name };
};
export const parseMaxAdsPerAdvertiser = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return null;
    return data?.maxAdsPerAdvertiser ?? null;
};

export const parseSupplySupplyDomainFilterLists = (
    mode: HierarchyFormsMode,
    data: BrandSupply | undefined
): LabeledValue[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return [];
    return (data?.supplySupplyDomainFilterLists || []).map((domain) => ({
        value: domain.id,
        label: domain.name,
    }));
};
export const parseSupplyBundleIdFilterLists = (
    mode: HierarchyFormsMode,
    data: BrandSupply | undefined
): LabeledValue[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return [];
    return (data?.supplyBundleIdFilterLists || []).map((bundle) => ({
        value: bundle.id,
        label: bundle.name,
    }));
};
export const parseResellType = (mode: HierarchyFormsMode, data: BrandSupply | undefined): LabeledValue => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.resellType) return INVENTORY_NOT_AVAILABLE_RESELL_TYPE;
    return { value: data.resellType.id, label: data.resellType.name };
};
export const parseVideoQuality = (mode: HierarchyFormsMode, data: BrandSupply | undefined): LabeledValue => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.videoQuality) return INVENTORY_UNKNOWN_VIDEO_QUALITY_TYPE;
    return { value: data.videoQuality.id, label: data.videoQuality.name };
};
export const parseImpressionWaitTime = (mode: HierarchyFormsMode, data: AdUnit | undefined): LabeledValue => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return DEFAULT_WAIT_TIME;
    if (!data?.impressionWaitTime) return OFF_WAIT_TIME;
    return { value: data.impressionWaitTime.id, label: data.impressionWaitTime.name };
};
export const parseAdomainFreqCappingType = (mode: HierarchyFormsMode, data: AdUnit | undefined): LabeledValue => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.adomainFreqCappingType) return DEFAULT_ADVERTISER_FREQUENCY_CAPPING;
    return { value: data.adomainFreqCappingType.id, label: data.adomainFreqCappingType.name };
};
export const parseInventoryPartnerDomain = (mode: HierarchyFormsMode, data: BrandSupply | undefined): string => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.inventoryPartnerDomain) return "";
    return data.inventoryPartnerDomain;
};

export const parseIfaType = (mode: HierarchyFormsMode, data: BrandSupply | undefined): LabeledValue | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.ifaType) return null;
    return { value: data.ifaType.id, label: data.ifaType.name };
};

export const parseIsCoppa = (mode: HierarchyFormsMode, data: BrandSupply | undefined): string | boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode || data?.isCoppa === null || data?.isCoppa === undefined) return NOT_SPECIFIED_OPTION.value;
    return data.isCoppa;
};
export const parseAdUnitLinearity = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | string => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.linearity?.id) return LINEARITY_BOTH_ALLOWED.value;
    return data.linearity.id;
};
export const parseAdUnitSeparationMode = (mode: HierarchyFormsMode, data: AdUnit | undefined): boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.separationGroups) return IAB_CATEGORIES_COMPETITIVE_SEPARATION_MODE;
    return !data?.separationGroups?.[0]?.industries?.length;
};
export const parseAdUnitType = (mode: HierarchyFormsMode, data: AdUnit | undefined): LabeledValue => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.type?.id) return AD_UNIT_PRE_ROLL_TYPE;
    return { value: data.type.id, label: data.type.name };
};
export const parseAdUnitSsaiType = (mode: HierarchyFormsMode, data: AdUnit | undefined): LabeledValue => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.ssaiType?.id) return NONE_OPTION;
    return { value: data.ssaiType.id, label: data.ssaiType.name };
};
export const parseAdUnitCreativeSamplesMinute = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.creativeSamplesMinute) return 6;
    return data?.creativeSamplesMinute;
};
export const parseAdUnitUnwrapVAST = (mode: HierarchyFormsMode, data: AdUnit | undefined): boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.unwrapVast) return false;
    return data?.unwrapVast;
};
export const parseAdUnitOverrideInboundFloor = (mode: HierarchyFormsMode, data: AdUnit | undefined): boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.overrideDynamicFloor) return false;
    return data?.overrideDynamicFloor;
};
export const parseAdUnitLiveStreamAcceleration = (mode: HierarchyFormsMode, data: AdUnit | undefined): boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.lsaEnabled) return false;
    return data?.lsaEnabled;
};
export const parseAdUnitUdOptOut = (mode: HierarchyFormsMode, data: AdUnit | undefined): boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.udeOptOut) return false;
    return data?.udeOptOut;
};
export const parseAdUnitAllowPlaybackMethods = (
    mode: HierarchyFormsMode,
    data: AdUnit | undefined
): LabeledValue[] | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.allowedPlaybackMethods) return [];
    return (data?.allowedPlaybackMethods || []).map((playbackMethod) => ({
        value: playbackMethod.id,
        label: playbackMethod.name,
    }));
};
export const parseAdUnitContentChannel = (mode: HierarchyFormsMode, data: AdUnit | undefined): string => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.contentChannel) return "";
    return data?.contentChannel;
};
export const parseAdUnitContentNetwork = (mode: HierarchyFormsMode, data: AdUnit | undefined): string => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.contentNetwork) return "";
    return data?.contentNetwork;
};
export const parseAdUnitVideoDeliveries = (mode: HierarchyFormsMode, data: AdUnit | undefined): LabeledValue[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.videoDeliveries) return [];
    return (data?.videoDeliveries || []).map((videoDelivery) => ({
        value: videoDelivery.id,
        label: videoDelivery.name,
    }));
};
export const parseAdUnitImpressionWaitTime = (mode: HierarchyFormsMode, data: AdUnit | undefined): LabeledValue => {
    if (data?.supply.type.id === SUPPLY_TYPES.LINEAR) {
        return EXTENDED_IMP_TIME_LINEAR_DEFAULT;
    }
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.impressionWaitTime) {
        return DEFAULT_WAIT_TIME;
    }
    return { value: data?.impressionWaitTime.id, label: data?.impressionWaitTime.name };
};
export const parseAdUnitPlacement = (mode: HierarchyFormsMode, data: AdUnit | undefined): LabeledValue => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.placement) return AD_UNIT_IN_STREAM_PLACEMENT_TYPE;
    return { value: data.placement.id, label: data.placement.name };
};
export const parseAdUnitStartDelay = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.startDelay) return null;
    return data.startDelay;
};
export const parseAdUnitMinDuration = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.minDuration) return DEFAULT_AD_UNIT_MIN_DURATION;
    return data.minDuration;
};
export const parseAdUnitMinBitrate = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.minBitrate) return null;
    return data.minBitrate;
};
export const parseAdUnitMaxPodSeconds = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.maxPodSeconds) return null;
    return data.maxPodSeconds;
};
export const parseAdUnitMaxAdsPerPod = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.maxAdsPerPod) return null;
    return data.maxAdsPerPod;
};
export const parseAdUnitMaxBitrate = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.maxBitrate) return null;
    return data.maxBitrate;
};
export const parseAdUnitMaxDuration = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.maxDuration) return DEFAULT_AD_UNIT_MAX_DURATION;
    return data.maxDuration;
};
export const parseAdUnitMaxExtended = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | string => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.maxExtended) return NOT_ALLOWED_MAX_EXTENDED.value;
    if (data.maxExtended === UNNLIMITED_MAX_EXTENDED.value) return UNNLIMITED_MAX_EXTENDED.value;
    return CUSTOM_MAX_EXTENDED.value;
};
export const parseAdUnitAdditionalTime = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.maxExtended) return null;
    if (data.maxExtended > NOT_ALLOWED_MAX_EXTENDED.value) return data?.maxExtended;
    return null;
};

export const parseAdUnitBlockedCreativeAttributes = (mode: HierarchyFormsMode, data: AdUnit | undefined) => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.blockedCreativeAttributes) return [];
    return data.blockedCreativeAttributes.map((attribute) => ({
        label: attribute.name,
        value: attribute.id,
    }));
};

export const parseAdUnitStatus = (mode: HierarchyFormsMode, data: AdUnit | undefined): number => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.status?.id) return AD_UNIT_ACTIVE_STATUS.value;
    return data.status.id;
};
export const parseAdUnitBoxing = (mode: HierarchyFormsMode, data: AdUnit | undefined): boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.isBoxingAllowed) return DEFAULT_AD_UNIT_BOXING;
    return data.isBoxingAllowed;
};
export const parseAdUnitInterstitial = (mode: HierarchyFormsMode, data: AdUnit | undefined): boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.isInterstitial) return DEFAULT_AD_UNIT_INTERSTITIAL;
    return data.isInterstitial;
};
export const parseEnableAdvertisersAsWhitelist = (mode: HierarchyFormsMode, data: Brand | undefined): boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.enableAdvertisersAsWhitelist) return DEFAULT_ADVERTISERS_AS_ALLOW_LIST;
    return data.enableAdvertisersAsWhitelist;
};
export const parseEnableNoAdCookieSync = (mode: HierarchyFormsMode, data: BrandSupply | undefined): boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return DEFAULT_ENABLE_NO_AD_COOKIE_SYNC;
    return !!data?.enableNoAdCookieSync;
};

export const parseAdUnitLiveStream = (mode: HierarchyFormsMode, data: AdUnit | undefined): boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.isLiveStream) return DEFAULT_AD_UNIT_LIVE_STREAM;
    return data.isLiveStream;
};
export const parseAdUnitSkip = (mode: HierarchyFormsMode, data: AdUnit | undefined): boolean | string => {
    const isCreateMode = mode === "create";
    if (isCreateMode || data?.skip === null || data?.skip === undefined) return UNKNOWN_OPTION.value;
    return data.skip;
};
export const parseAdUnitSkipafter = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.skipafter) return null;
    return data.skipafter;
};
export const parseAdUnitSkipmin = (mode: HierarchyFormsMode, data: AdUnit | undefined): number | null => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.skipmin) return null;
    return data.skipmin;
};
export const parseAdUnitMultiplicity = (mode: HierarchyFormsMode, data: AdUnit | undefined): number => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.multiplicity?.id) return AD_UNITS_MULTIPLICITIES.SINGLE.value;
    return data.multiplicity.id;
};
export const parseAdUnitAdPodFillMode = (mode: HierarchyFormsMode, data: AdUnit | undefined): number => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.adPodFillMode?.id) return OPTIMIZE_FILL_MODE.value;
    return data.adPodFillMode.id;
};
export const parseAdUnitPodEnforcement = (mode: HierarchyFormsMode, data: AdUnit | undefined): number => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.podEnforcement?.id) return NONE_POD_ENFORCEMENT.value;
    return data.podEnforcement.id;
};
export const parseHasPrivacyPolicy = (mode: HierarchyFormsMode, data: BrandSupply | undefined): string | boolean => {
    const isCreateMode = mode === "create";
    if (isCreateMode || data?.hasPrivacyPolicy === null || data?.hasPrivacyPolicy === undefined)
        return NOT_SPECIFIED_OPTION.value;
    return data.hasPrivacyPolicy;
};

export const parseViewabilityVendors = (mode: HierarchyFormsMode, data: BrandSupply | undefined): LabeledValue[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return [];
    return (data?.viewabilityVendors || []).map((vendor) => ({
        value: vendor.id,
        label: vendor.name,
    }));
};
export const parseKeywords = (mode: HierarchyFormsMode, data: BrandSupply | undefined): Array<string | number> => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return [];
    return data?.keywords?.length ? data.keywords : [];
};
export const parseMaskIFAForNetworks = (mode: HierarchyFormsMode, data: BrandSupply | undefined): LabeledValue[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return [];
    return (data?.maskIFAForNetworks || []).map((vendor) => ({
        value: vendor.id,
        label: vendor.name,
    }));
};
export const parseAdUnitMimes = (mode: HierarchyFormsMode, data: AdUnit | undefined): LabeledValue[] => {
    const isCreateMode = mode === "create";
    // MIME_TYPES.MP4 is Default
    if (isCreateMode || !data?.mimes) return [MIME_TYPES.MP4];
    return (data?.mimes || []).map((mime) => ({
        value: mime.id,
        label: mime.name,
    }));
};
export const parseAdUnitSupportedApis = (mode: HierarchyFormsMode, data: AdUnit | undefined): LabeledValue[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !data?.supportedApis) return [];
    return (data?.supportedApis || []).map((framework) => ({
        value: framework.id,
        label: framework.name,
    }));
};
export const parseAdUnitSupportedProtocols = (mode: HierarchyFormsMode, data: AdUnit | undefined): LabeledValue[] => {
    const isCreateMode = mode === "create";
    // AD_UNITS_PROTOCOLS.VAST_2 is Default
    if (isCreateMode || !data?.supportedProtocols) return [AD_UNITS_PROTOCOLS.VAST_2];
    return (data?.supportedProtocols || []).map((protocol) => ({
        value: protocol.id,
        label: protocol.name,
    }));
};

export const parseFreqCaps = (mode: HierarchyFormsMode, data?: AdomainFreqCapping[]): LabeledValueOpotions[] => {
    const isCreateMode = mode === "create";
    if (isCreateMode) return [];
    return (data || []).map(({ total, timeDurationMin, mode, id }) => ({
        label: `${total} ${mode.name} in ${getDimension(timeDurationMin)}`,
        value: timeDurationMin,
        id,
        mode,
        total,
        timeDurationMin,
    }));
};
export const parseFreqCapsToApi = (data: LabeledValueOpotions[] | null): AdomainFreqCapping[] | null => {
    if (!data?.length) return null;
    return data.map(({ label, value, id, ...rest }) => rest);
};
export const parseChannelPlayListDefinitionMode = (mode: HierarchyFormsMode, id?: number) => {
    const isCreateMode = mode === "create";
    if (isCreateMode || !id) {
        return DEFAULT_PLAYLIST_DEFINITION_MODE.value;
    }

    return id;
};
export const parseChannelPlayListDefinitionModeToApi = (id: number) => {
    const { value, label } = PLAYLIST_DEFINITION_MODE_OPTIONS.find(({ value }) => value === id) as {
        label: string;
        value: number;
    };
    return { id: value, name: label };
};
export const parseAdBreakRules = (mode: HierarchyFormsMode, adBreakRules?: AdBreakRules[]) => {
    const defaultValue: {
        preRoll: AdBreakRulePreRoll[];
        midRoll: AdBreakRuleMidRoll[];
        postRoll: AdBreakRulePostRoll[];
    } = {
        preRoll: [],
        midRoll: [],
        postRoll: [],
    };
    const isCreateMode = mode === "create";
    if (isCreateMode || !adBreakRules?.length) return defaultValue;

    return adBreakRules.reduce((acc, { type, adBreakLabel, maxAdsPerPod, midRollIndex, maxPodLengthSeconds }) => {
        switch (type.id) {
            case BREAK_RULE_PRE_ROLL.id:
                acc.preRoll.push({
                    [AdBreakRuleName.PRE_ROLL_NAME]: adBreakLabel,
                    [AdBreakRuleAds.PRE_ROLL_ADS]: maxAdsPerPod,
                    [AdBreakRuleDuration.PRE_ROLL_DURATION]: maxPodLengthSeconds,
                });
                break;

            case BREAK_RULE_MID_ROLL.id:
                acc.midRoll.push({
                    [AdBreakRuleName.MID_ROLL_NAME]: adBreakLabel,
                    [AdBreakRuleAds.MID_ROLL_ADS]: maxAdsPerPod,
                    [AdBreakRuleDuration.MID_ROLL_DURATION]: maxPodLengthSeconds,
                    [AdBreakRuleIndex.MID_ROLL_INDEX]: midRollIndex as number,
                });
                break;
            case BREAK_RULE_POST_ROLL.id:
                acc.postRoll.push({
                    [AdBreakRuleName.POST_ROLL_NAME]: adBreakLabel,
                    [AdBreakRuleAds.POST_ROLL_ADS]: maxAdsPerPod,
                    [AdBreakRuleDuration.POST_ROLL_DURATION]: maxPodLengthSeconds,
                });
                break;
        }
        return acc;
    }, defaultValue);
};
export const parseAdBreakRulesToApi = (
    preRoll: AdBreakRulePreRoll[],
    midRoll: AdBreakRuleMidRoll[],
    postRoll: AdBreakRulePostRoll[]
) => {
    const adBreakRules: {
        adBreakLabel: string;
        maxAdsPerPod: number;
        maxPodLengthSeconds: number;
        midRollIndex: number | null;
        type: { id: number };
    }[] = [];

    if (preRoll?.length) {
        const { preAds, preName, preDuration } = preRoll[0];
        adBreakRules.push({
            adBreakLabel: preName,
            maxAdsPerPod: preAds,
            maxPodLengthSeconds: preDuration,
            midRollIndex: null,
            type: { id: BREAK_RULE_PRE_ROLL.id },
        });
    }

    if (midRoll?.length) {
        const newMidRolles = midRoll.map(({ midAds, midName, midIndex, midDuration }) => ({
            adBreakLabel: midName,
            maxAdsPerPod: midAds,
            maxPodLengthSeconds: midDuration,
            midRollIndex: midIndex,
            type: { id: BREAK_RULE_MID_ROLL.id },
        }));
        adBreakRules.push(...newMidRolles);
    }

    if (postRoll?.length) {
        const { postAds, postName, postDuration } = postRoll[0];
        adBreakRules.push({
            adBreakLabel: postName,
            maxAdsPerPod: postAds,
            maxPodLengthSeconds: postDuration,
            midRollIndex: null,
            type: { id: BREAK_RULE_POST_ROLL.id },
        });
    }
    return adBreakRules;
};
