import { FC } from "react";
import { Col, Row } from "antd";
import { LiveEventsFormItemName } from "./LiveEventsFormItemName";
import { LiveEventsFormItemStartDate } from "./LiveEventsFormItemStartDate";
import { LiveEventsFormItemDuration } from "./LiveEventsFormItemDuration";
import { LiveEventsFormItemTimezone } from "./LiveEventsFormItemTimezone";

export const LiveEventsFormGeneralSection: FC = () => {
    return (
        <>
            <Row gutter={24}>
                <Col span={8}>
                    <LiveEventsFormItemName />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <LiveEventsFormItemStartDate />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <LiveEventsFormItemDuration />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <LiveEventsFormItemTimezone />
                </Col>
            </Row>
        </>
    );
};
