import { useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { useDealTimeZoneField } from "@app/features/deals/DealForm/DealDemandFormSections/DealTermsSection/Fields/DealTimeZoneField/useDealTimeZoneField";
import { TimeZone } from "@app/core/services";
import { useCurrencyOptions } from "@app/features/deals/DealForm/useCurrencyOptions";

interface UseAdSourceDealsListTable {
    isDealRateColumnVisible: boolean;
    rate: number | null;
    startDate: string | null;
    endDate: string | null;
    currencyCode: string;
    timeZone: TimeZone | null;
}

export const useAdSourceDealsListTable = (): UseAdSourceDealsListTable => {
    const { optionsByValue: currenciesByValue } = useCurrencyOptions();
    const { optionsByValue: timeZonesByValue } = useDealTimeZoneField("edit");

    const dealAdSourceType = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);
    const rate = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_PRICE]);
    const startDate = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.START_DATE]);
    const endDate = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.END_DATE]);
    const currency = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.CURRENCY]);
    const timeZoneId = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TIME_ZONE]);

    const timeZone = timeZonesByValue[timeZoneId] || null;
    const currencyCode = String(currenciesByValue[currency]?.label || "USD");
    const isDealRateColumnVisible = [
        AdSourceTypeIds.PROGRAMMATIC_GUARANTEED,
        AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED,
        AdSourceTypeIds.FIXED_PRICE,
        AdSourceTypeIds.AUCTION_PRICE,
    ].includes(dealAdSourceType);

    return {
        isDealRateColumnVisible,
        rate,
        startDate,
        endDate,
        currencyCode,
        timeZone,
    };
};
