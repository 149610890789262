import { getUrlSegment } from "@app/core/utils/urlSegment";
import { useLocation } from "react-router-dom";

export const useGoToEntityLink = (): string => {
    const inIframe = window.self !== window.top;
    const { pathname, search } = useLocation();
    const { origin } = window.location;

    //TODO: test on dev to make sure that url is right
    const copyUrl = new URL(`#${pathname}${search}`, origin);
    console.log(copyUrl, pathname);

    return inIframe ? getUrlSegment(copyUrl.hash) : copyUrl.href;
};
