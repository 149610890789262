import { FC } from "react";
import { Form } from "antd";
import { LoadOnScrollSelect } from "@rubicon/antd-components";
import { useAssociatedGlobalIDGSelect } from "./useAssociatedGlobalIDGSelect";

interface Props {
    name: string;
    selectName: string;
}

export const AssociatedGlobalIDGSelect: FC<Props> = ({ name, selectName }) => {
    const { options, isFetching, search, hasMore, loadMore, onBlur, onChange, onChangeSearch } =
        useAssociatedGlobalIDGSelect(name, selectName);

    return (
        <>
            <Form.Item
                data-sdet="associated-global-idg-select"
                label="Associated Global IDG"
                name={selectName}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <LoadOnScrollSelect
                    allowClear
                    labelInValue
                    filterOption={false}
                    loading={isFetching}
                    options={options}
                    hasMore={hasMore}
                    loadMore={loadMore}
                    onChange={onChange}
                    onBlur={onBlur}
                    onSearch={onChangeSearch}
                    showSearch
                    searchValue={search}
                    placeholder="Select..."
                />
            </Form.Item>
            {/* Hidden field used to store the label that is fetched from the selected associated global IDG value. */}
            <Form.Item name={name} hidden={true} />
        </>
    );
};
