import { FC } from "react";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { FixedSaveButtons } from "@app/core/components";
import { useHistory, useParams } from "react-router-dom";
import { getLabelFormInitialValues } from "../helpers";
import { Card, Form, Layout, notification, Typography } from "antd";
import {
    I_D_G_VALUE,
    CLASSIFICATIONS_LABEL_FORM_ITEMS,
    ClassificationsLabelCreateFormValues,
} from "@app/features/controls/classifications/labels/ClassificationsLabelForm/constants";
import { ClassificationsLabelFormBaseFormItems } from "../ClassificationsLabelFormBaseFormItems";
import {
    Label,
    LabelPayload,
    useCreateLabelValuesMutation,
    useCreateSeatLabelMutation,
} from "@magnite/client-streaming-platform";

export const CLASSIFICATIONS_LABEL_FORM_SDET = "classifications-label-form";

export const ClassificationsLabelCreateForm: FC = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const { seatId } = useParams<{ seatId: string }>();
    const isDistributionGroup = Form.useWatch(CLASSIFICATIONS_LABEL_FORM_ITEMS.DISTRIBUTION_GROUP.name, form);

    const [createClassificationsLabel, { isLoading: isCreateSeatLabel }] = useCreateSeatLabelMutation();
    const [createClassificationsLabelValues, { isLoading: isCreateLabelValues }] = useCreateLabelValuesMutation();

    const initialValues = getLabelFormInitialValues();

    const onFinish = async (values: ClassificationsLabelCreateFormValues): Promise<void> => {
        const { labelValues, ...rest } = values;
        const labelPayload: LabelPayload = {
            ...rest,
            seatId,
            namespace: `s:${seatId}`,
        };
        if (values.isDistributionGroup) {
            // Labels API for distribution groups required the following properties
            Object.assign(labelPayload, {
                enablePrefilter: false,
                isPublic: true,
                multiValue: false,
            });
        }

        try {
            const createdLabel: Label = await createClassificationsLabel({ seatId, body: labelPayload }).unwrap();

            if (values.isDistributionGroup) {
                // Whether the distribution group label is set to true there will be one value, and it will have a hardcoded "idg" value - the value will not be editable
                await createClassificationsLabelValues([
                    { label: { id: createdLabel.id }, value: I_D_G_VALUE },
                ]).unwrap();
            }
            notification.success({
                message: (
                    <span>
                        <Typography.Text strong>{createdLabel.key}</Typography.Text> has successfully been created
                    </span>
                ),
            });
            history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_LABEL_EDIT_PAGE(seatId, createdLabel.id));
        } catch (err) {
            notification.error({ message: err.message || err.data.errorDescription });
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initialValues}
            wrapperCol={{ xs: 24, lg: 12 }}
            data-sdet={CLASSIFICATIONS_LABEL_FORM_SDET}
        >
            <Layout.Content style={{ height: "90vh" }}>
                <Card>
                    <ClassificationsLabelFormBaseFormItems isDistributionGroup={isDistributionGroup} />
                </Card>
            </Layout.Content>
            <FixedSaveButtons
                isLoading={isCreateSeatLabel || isCreateLabelValues}
                submitLabel={isDistributionGroup ? "Submit" : "Submit & Add Values"}
                onCancel={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_TABS(seatId, "labels"))}
            />
        </Form>
    );
};
