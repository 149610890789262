import { FC } from "react";
import { Form } from "antd";
import { FORM_ITEMS } from "../../../constants";
import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { disablePastDates } from "@app/core/utils/disablePastDates";

export const LiveEventsFormItemStartDate: FC = () => {
    return (
        <Form.Item
            required
            name={FORM_ITEMS.START_DATE.name}
            label={FORM_ITEMS.START_DATE.label}
            rules={[{ required: true, message: "Start Date is required" }]}
        >
            <MomentDatePicker
                allowClear
                showTime
                style={{ width: "100%" }}
                placeholder="Select Start Date"
                data-sdet="live-event-start-date-picker"
                disabledDate={disablePastDates}
            />
        </Form.Item>
    );
};
