import { FC } from "react";
import { Form } from "antd";
import { useGetCostModelsQuery } from "@magnite/client-streaming-platform";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useIsFieldVisible } from "./useIsFieldIsVisible";

export const CostModelSelect: FC<{ currentDefaultName?: string }> = ({ currentDefaultName }) => {
    const { data: costModels = [], isLoading } = useGetCostModelsQuery();
    const isVisible = useIsFieldVisible();

    return isVisible ? (
        <Form.Item
            {...VERTICAL_LAYOUT_FIX}
            label={AD_SOURCE_FIELDS.COST_MODEL.label}
            name={AD_SOURCE_FIELDS.COST_MODEL.name}
            help={currentDefaultName ? `Default Cost Model: ${currentDefaultName}` : null}
        >
            <SelectWithValue
                placeholder="Select…"
                options={costModels}
                loading={isLoading}
                fieldAsLabel={["name"]}
                fieldAsValue={["id"]}
                data-sdet="adsource-cost-model"
            />
        </Form.Item>
    ) : null;
};
