import { SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { Form } from "antd";

const { useWatch } = Form;

export const useIsFieldVisible = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);

    const adSourceTypeId = adSourceType?.id;

    switch (adSourceTypeId) {
        case AdSourceTypeIds.PROGRAMMATIC_GUARANTEED:
        case AdSourceTypeIds.FIXED_PRICE:
        case AdSourceTypeIds.MARKETPLACE:
        case AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED:
        case AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED:
        case AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED:
        case AdSourceTypeIds.AUCTION_PRICE:
            return false;
        case AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE:
            return true;
        default:
            return false;
    }
};
