import { Affix, Button, Card, Collapse, Form, FormInstance, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { FC, useState } from "react";
import { PanelHeader } from "@app/features/deals/DealDetailsPage/BuyerDealDetailsForm";
import { LiveEventsFormGeneralSection } from "./LiveEventsFormGeneralSection";
import { LiveEventsFormEstimatesSection } from "./LiveEventsFormEstimatesSection";
import { LiveEvent, LiveEventSubmitPayload } from "@app/core/services/console/liveEvents";
import moment, { Moment } from "moment-timezone";
import { useAppSelector } from "@app/core/store";
import { selectGetTimeZonesResult, TimeZone } from "@app/core/services";
import { convertDateToTimeZone } from "@app/core/utils";
import { FORM_ITEMS } from "../constants";
import { css } from "@emotion/css";
import { calculatedEndDate } from "../utils";

const INVENTORY_LIVE_EVENTS_HEADERS_CLASS = css`
    .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
        height: 32px;
    }
`;

enum LIVE_EVENT_SECTIONS {
    GENERAL = "GENERAL",
    EVENT_ESTIMATES = "EVENT_ESTIMATES",
}

const LIVE_EVENT_SECTIONS_NAME = {
    [LIVE_EVENT_SECTIONS.GENERAL]: "General",
    [LIVE_EVENT_SECTIONS.EVENT_ESTIMATES]: "Event Estimates",
};

const LIVE_EVENT_SECTION_KEYS = {
    [LIVE_EVENT_SECTIONS.GENERAL]: "1",
    [LIVE_EVENT_SECTIONS.EVENT_ESTIMATES]: "2",
};

export interface DurationValue {
    hours: string;
    minutes: string;
}

export interface LiveEventForm {
    name: string;
    startDate: Moment;
    duration: DurationValue;
    timeZone: number;
    estimatedImpressionOpportunities: number;
    estimatedPeakConcurrentUsers: number;
    estimatedAverageConcurrentUsers: number;
    estimatedAdBreakCount: number;
    estimatedTotalAvailableAdMinutes: number;
}

const mapLiveEventToFormValues = (liveEvent: Partial<LiveEvent> | undefined): Partial<LiveEventForm> => {
    return {
        ...liveEvent,
        timeZone: liveEvent?.timeZone?.id,
        duration: mapLiveEventStartEndDatesToDuration(liveEvent),
    };
};

export const mapLiveEventStartEndDatesToDuration = (liveEvent: Partial<LiveEvent> | undefined): DurationValue => {
    const duration = moment.duration(liveEvent?.endDate?.diff(liveEvent.startDate));
    return { hours: duration?.hours().toString() || "0", minutes: duration?.minutes().toString() || "0" };
};

const mapFormValuesToLiveEventSubmitPayload = (
    values: LiveEventForm,
    timeZone: TimeZone | null | undefined
): Omit<LiveEventSubmitPayload, "seat"> => {
    const payload: Omit<LiveEventSubmitPayload, "seat"> & { duration?: DurationValue } = {
        ...values,
        startDate: convertDateToTimeZone(values.startDate.format(), timeZone) as string,
        endDate: convertDateToTimeZone(
            calculatedEndDate(values.startDate, values.duration).format(),
            timeZone
        ) as string,
        timeZone: {
            id: values.timeZone,
        },
    };

    delete payload.duration;

    return payload;
};

interface Props {
    liveEvent?: Partial<LiveEvent>;
    isSubmitting: boolean;
    handleSubmit: (liveEventForm: Omit<LiveEventSubmitPayload, "seat">) => void;
    handleCancel: () => void;
}

export const InventoryLiveEventsForm: FC<Props> = ({ liveEvent, isSubmitting, handleSubmit, handleCancel }) => {
    const [liveEventForm] = useForm();

    const [keys, setKeys] = useState<string[]>([
        LIVE_EVENT_SECTION_KEYS.GENERAL,
        LIVE_EVENT_SECTION_KEYS.EVENT_ESTIMATES,
    ]);

    const initialValues = mapLiveEventToFormValues(liveEvent);

    const timeZones = useAppSelector(selectGetTimeZonesResult);
    const selectedTimeZoneId = Form.useWatch(FORM_ITEMS.TIMEZONE.name, liveEventForm);
    const selectedTimeZone = timeZones?.data?.find((tz) => tz.id === selectedTimeZoneId);

    const handleFormSubmit = (liveEventForm: FormInstance<LiveEventForm>) => {
        liveEventForm
            .validateFields()
            .then((values) => {
                handleSubmit(mapFormValuesToLiveEventSubmitPayload(values, selectedTimeZone));
            })
            .catch((err) => {
                console.error("Form validation failed", err);
            });
    };

    return (
        <Form form={liveEventForm} initialValues={initialValues} layout="vertical">
            <Collapse
                bordered
                activeKey={keys}
                className={INVENTORY_LIVE_EVENTS_HEADERS_CLASS}
                onChange={(keys: string[]) => {
                    setKeys(keys);
                }}
                items={[
                    {
                        key: LIVE_EVENT_SECTION_KEYS.GENERAL,
                        label: <PanelHeader title={LIVE_EVENT_SECTIONS_NAME.GENERAL} />,
                        children: <LiveEventsFormGeneralSection />,
                    },
                    {
                        key: LIVE_EVENT_SECTION_KEYS.EVENT_ESTIMATES,
                        label: <PanelHeader title={LIVE_EVENT_SECTIONS_NAME.EVENT_ESTIMATES} />,
                        children: <LiveEventsFormEstimatesSection />,
                    },
                ]}
            />
            <Affix offsetBottom={46}>
                <Card styles={{ body: { padding: "0.75rem 1rem" } }} style={{ marginTop: "3rem" }}>
                    <Space>
                        <Button
                            type="primary"
                            data-sdet="confirmation-submit"
                            loading={isSubmitting}
                            onClick={() => handleFormSubmit(liveEventForm)}
                        >
                            Submit
                        </Button>
                        <Button onClick={handleCancel} disabled={isSubmitting} data-sdet="cancel">
                            Cancel
                        </Button>
                    </Space>
                </Card>
            </Affix>
        </Form>
    );
};
