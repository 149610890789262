import { useEffect, useState, FC } from "react";
import { Redirect } from "react-router-dom";
import { Loading } from "@app/core/components";
import { LOCAL_STORAGE_KEY_LOGIN_REDIRECT_PATHNAME } from "@app/core/constants";
import { ROUTES } from "@app/core/routing";
import { useTokenExchange } from "./useTokenExchange";

export const TokenExchangeRedirect: FC = () => {
    const { loading } = useTokenExchange();
    const [loginRedirectPathname] = useState<string | null>(
        localStorage.getItem(LOCAL_STORAGE_KEY_LOGIN_REDIRECT_PATHNAME)
    );

    useEffect(() => {
        if (loginRedirectPathname) {
            localStorage.removeItem(LOCAL_STORAGE_KEY_LOGIN_REDIRECT_PATHNAME);
        }
    }, [loginRedirectPathname]);

    if (loading) {
        return <Loading />;
    }

    return <Redirect to={loginRedirectPathname || ROUTES.LOGIN_FORM_CONTEXT_STEP} />;
};
