import { FC } from "react";
import { CollapsePanelProps, Typography } from "antd";
import { ExpandCollapse } from "@rubicon/antd-components";
import { MinDurationTarget } from "../types";

export interface ReadOnlyMinDurationTargetingPanelProps extends CollapsePanelProps {
    target: MinDurationTarget | null;
}

export const ReadOnlyMinDurationTargetingPanel: FC<ReadOnlyMinDurationTargetingPanelProps> = ({ target, ...rest }) => {
    if (!target) {
        return null;
    }

    return (
        <ExpandCollapse.Panel {...rest}>
            <Typography.Text>
                {target.operator?.name} {target.minDuration}
            </Typography.Text>
        </ExpandCollapse.Panel>
    );
};
