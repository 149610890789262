import { Form } from "antd";
import { useOverrideFloorCpmInputLabel } from "./useOverrideFloorCpmInputLabel";
import { useCallback } from "react";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";
import { useOverrideFloorCurrencyCodeLabel } from "./useOverrideFloorCurrencyCodeLabel";
import { SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, OverrideFloorCpmEligibleAdSourceTypeIds } from "@app/features/seatAdSources/constants";

export const useCpmInput = () => {
    const adSourceTypeId = Form.useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name)?.id;
    const isVisible = OverrideFloorCpmEligibleAdSourceTypeIds.has(adSourceTypeId);
    const fieldLabel = useOverrideFloorCpmInputLabel();
    const currencyCode = useOverrideFloorCurrencyCodeLabel();
    const { updateSyncedFields } = useSyncedFieldsProducer();

    const onBlur = useCallback(
        (value: string, isDirty: boolean) => {
            if (isDirty) {
                updateSyncedFields({ cpmRate: value });
            }
        },
        [updateSyncedFields]
    );

    return { isVisible, fieldLabel, currencyCode, onBlur };
};
