import { FC, MouseEvent } from "react";
import { Col, Grid, Row, Space, Table } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { brandCobalt } from "@rubicon/antd-components";
import { format } from "@rubicon/utils";
import { AnimatedNumberCell, Dashes, HelpTooltip, PacingProgressBar, StyledLink } from "@app/core/components";
import { DEFAULT_TIME_ZONE, YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_NO_COMMA } from "@app/core/components/constants";
import {
    MANAGE_COLUMNS_KEY_DEAL_HEALTH,
    useManageColumnsDrawer,
} from "@app/core/components/ManageColumns/useManageColumnsDrawer";
import { ManageColumnsButton, ManageColumnsDrawer } from "@app/core/components/ManageColumns";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Currency, LiveStatsDeal, TimeZone } from "@app/core/services";
import { COLUMN_KEY } from "@app/features/deals/DealHealthPage/constants";
import DealPriority from "@app/features/deals/DealPriority";
import { DEAL_TYPE_PROGRAMMATIC_GUARANTEE, DealsHelpKeys } from "@app/features/deals/constants";
import DealStatusBadge from "@app/features/deals/DealStatusBadge";
import { DealListTableActions } from "@app/features/deals/shared/DealTableActions";
import { useSeatAdSourcesDetailsDrawer } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/useSeatAdSourcesDetailsDrawer";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "src/features/seatAdSources/SeatAdSourcesDetailsDrawer/types";
import { useDealDetailsDrawer } from "../../DealDetailsDrawer/useDealDetailsDrawer";
import { DealCurrencyFilters } from "../../DealListPage/DealCurrencyFilters";
import { DealHealthTableNetRevenueCell, DealHealthTableUseRateCell } from "./DealHealthTableCells";
import DealHealthFilterSearch from "./DealHealthTableFilterSearch";
import DealHealthPagination from "./DealHealthTablePagination";
import { TableItem, useDealHealthTable } from "./useDealHealthTable";
import { DealHealthTableCpmRateCell } from "./DealHealthTableCells/DealHealthTableCpmRateCell/DealHealthTableCpmRateCell";
import { useUserAccess } from "@app/core/auth";

export const getTableItems = (
    deals: LiveStatsDeal[] = [],
    handleLinkClick: (e: MouseEvent<HTMLElement>, dealId: number) => void,
    openSeatAdSourcesDetailsDrawer: (adSourceId: number, secondaryDetailsView?: SecondaryDetailsView) => void,
    route: string,
    seatId: string,
    timeZones: TimeZone[],
    currencies: Currency[],
    hasInternalAccess: boolean
): TableItem[] =>
    deals?.map((deal) => {
        const dealType = {
            id: deal.metadata.integratedDealType.integratedDealTypeId,
            name: deal.metadata.integratedDealType.name,
        };
        const timeZone: TimeZone =
            timeZones.find((timeZone) => timeZone.id === deal.metadata.timeZoneId) || DEFAULT_TIME_ZONE;
        const currency: { id: number; code: string } = currencies.find(
            (currency) => currency.id === deal.metadata.currencyId
        ) || { id: 1, code: "USD" };

        const rowData = {
            key: deal?.metadata.dealId,
            [COLUMN_KEY.NAME]: (
                <StyledLink
                    href={`${route}${ROUTE_FORMATTERS.SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER(
                        seatId,
                        deal.metadata.dealId
                    )}`}
                    onClick={(e: MouseEvent<HTMLElement>) => handleLinkClick(e, deal?.metadata.dealId)}
                >
                    {format.asSelf(deal?.metadata.dealName)}
                </StyledLink>
            ),
            [COLUMN_KEY.DEAL_PRIORITY]: (
                <DealPriority adSources={deal.metadata.adSources} openDrawer={openSeatAdSourcesDetailsDrawer} />
            ),
            [COLUMN_KEY.CODE]: <Dashes value={deal?.metadata.dealCode} />,
            [COLUMN_KEY.DEAL_ENTITY_TYPE]: dealType.name,
            [COLUMN_KEY.NETWORK_NAME]: <Dashes value={deal?.metadata?.network?.name} />,
            [COLUMN_KEY.DEAL_START_DATE]: (
                <Dashes
                    value={
                        deal?.metadata.startTime
                            ? `${moment(deal.metadata.startTime)
                                  .tz(timeZone?.code || "UTC")
                                  .format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_NO_COMMA)} ${timeZone?.code || "UTC"}`
                            : null
                    }
                />
            ),
            [COLUMN_KEY.DEAL_END_DATE]: (
                <Dashes
                    value={
                        deal?.metadata.endTime
                            ? `${moment(deal.metadata.endTime)
                                  .tz(timeZone?.code || "UTC")
                                  .format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_NO_COMMA)} ${timeZone?.code || "UTC"}`
                            : null
                    }
                />
            ),
            [COLUMN_KEY.PACING]:
                deal?.metadata?.integratedDealType?.integratedDealTypeId !== DEAL_TYPE_PROGRAMMATIC_GUARANTEE.id ||
                !deal.metadata.dealPacing ||
                deal.metadata.dealPacing.length < 1 ||
                !deal.metadata.adSources ||
                deal.metadata.adSources.length < 1 ? (
                    <Dashes />
                ) : (
                    <PacingProgressBar pacingData={deal.metadata.dealPacing[0]} />
                ),
            [COLUMN_KEY.CPM_RATE]: (
                <DealHealthTableCpmRateCell
                    dealType={dealType}
                    rate={deal.metadata.rate}
                    currencyType={currency}
                    adSources={deal.metadata.adSources}
                    openDrawer={openSeatAdSourcesDetailsDrawer}
                />
            ),
            [COLUMN_KEY.TRIES]: <AnimatedNumberCell value={deal.stats?.[0]?.tries} />,
            [COLUMN_KEY.IMPRESSIONS]: <AnimatedNumberCell value={deal.stats?.[0]?.impressions} />,
            [COLUMN_KEY.USE_RATE]: <DealHealthTableUseRateCell useRate={deal.stats?.[0]?.useRate} />,
            [COLUMN_KEY.NET_REVENUE]: (
                <DealHealthTableNetRevenueCell netRevenue={deal?.stats?.[0]?.netRevenue} currencyCode={currency.code} />
            ),
            [COLUMN_KEY.DEAL_STATUS_NAME]: (
                <DealStatusBadge
                    status={{
                        id: deal.metadata.dealStatus.dealStatusId,
                        name: deal.metadata.dealStatus.name,
                    }}
                />
            ),
            [COLUMN_KEY.DEAL_EXTERNAL_STATUS]: <Dashes value={deal?.metadata?.externalStatus} />,
            [COLUMN_KEY.DEAL_ACTIONS]: (
                <DealListTableActions
                    dealId={deal.metadata.dealId}
                    adSources={deal.metadata.adSources}
                    dealType={dealType}
                    isDealShared={deal.metadata.isShared}
                />
            ),
        };

        if (hasInternalAccess) {
            rowData[COLUMN_KEY.DEAL_ORIGIN_NAME] = <Dashes value={deal?.metadata?.dealOrigin?.name} />;
        }

        return rowData;
    });

const getDefaultColumns = () => [
    {
        dataIndex: COLUMN_KEY.NAME,
        title: (
            <HelpTooltip
                helpKeyList={DealsHelpKeys}
                helpKey={DealsHelpKeys.DealName}
                tooltipPlacement={"topRight"}
                popover={true}
            >
                Deal Name
            </HelpTooltip>
        ),
        width: 280,
        visible: true,
        elipsis: true,
        managedColumnTitle: "Deal Name",
        selectable: false,
        sorter: true,
    },
    {
        dataIndex: COLUMN_KEY.DEAL_PRIORITY,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealPriority} popover={true}>
                Priority
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Priority",
        selectable: true,
        sorter: true,
    },
    {
        dataIndex: COLUMN_KEY.CODE,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealId} popover={true}>
                Deal ID
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Deal ID",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.DEAL_ENTITY_TYPE,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealType} popover={true}>
                Deal Type <InfoCircleOutlined style={{ color: brandCobalt }} />
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Deal Type",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.DEAL_ORIGIN_NAME,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealOrigin} popover={true}>
                Deal Origin
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Deal Origin",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.NETWORK_NAME,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealDSP} popover={true}>
                DSP
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "DSP",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.DEAL_START_DATE,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealDates} popover={true}>
                Start Date
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Start Date",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.DEAL_END_DATE,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealDates} popover={true}>
                End Date
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "End Date",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.PACING,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealDetailsPacing} popover={true}>
                Pacing
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Pacing",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.CPM_RATE,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealDetailsCPMRate} popover={true}>
                CPM Rate
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "CPM Rate",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.TRIES,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.Tries} popover={true}>
                Tries
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Tries",
        selectable: true,
        sorter: true,
    },
    {
        dataIndex: COLUMN_KEY.IMPRESSIONS,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.AdStatsPublisherImpressions} popover={true}>
                Impressions
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Impressions",
        selectable: true,
        sorter: true,
    },
    {
        dataIndex: COLUMN_KEY.USE_RATE,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.AdStatsPublisherUseRate} popover={true}>
                Use Rate
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Use Rate",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.NET_REVENUE,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.AdStatsPublisherNetRevenue} popover={true}>
                Net Revenue
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Net Revenue",
        selectable: true,
        sorter: true,
    },
    {
        dataIndex: COLUMN_KEY.DEAL_STATUS_NAME,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealStatus} popover={true}>
                Internal Status
            </HelpTooltip>
        ),
        visible: true,
        managedColumnTitle: "Internal Status",
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.DEAL_EXTERNAL_STATUS,
        title: (
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealBuyerStatus} popover={true}>
                Buyer Status
            </HelpTooltip>
        ),
        visible: true,
        selectable: true,
    },
    {
        dataIndex: COLUMN_KEY.DEAL_ACTIONS,
        title: "Actions",
        width: 140,
        visible: true,
        selectable: false,
    },
];

export const DealHealthTable: FC = () => {
    const { data, isLoading, handleChange, seatId, timeZones, currencies } = useDealHealthTable();
    const { hasInternalAccess } = useUserAccess();
    const { sm } = Grid.useBreakpoint();
    const { openDealDetailsDrawer } = useDealDetailsDrawer();
    const { openSeadAdSourceDrawerDeals } = useSeatAdSourcesDetailsDrawer();
    const defaultColumns = getDefaultColumns();
    const {
        isDrawerOpen,
        handleOpenManageColumnsDrawer,
        handleCloseManageColumnsDrawer,
        allColumns,
        visibleColumns,
        onChange,
    } = useManageColumnsDrawer(MANAGE_COLUMNS_KEY_DEAL_HEALTH, defaultColumns);
    const { origin, pathname } = window.location;
    const route = `${origin}${pathname}#`;

    const handleLinkClick = (e: MouseEvent<HTMLElement>, dealId: number) => {
        openDealDetailsDrawer(dealId);
    };

    return (
        <>
            <Space direction="vertical" size="middle" style={{ width: "100%" }} data-sdet="deals-list-table">
                <Row gutter={[16, 16]}>
                    <Col xs={24} lg={10}>
                        <DealHealthFilterSearch />
                    </Col>
                    <Col xs={24} sm={20} lg={10}>
                        <DealCurrencyFilters />
                    </Col>
                    <Col flex="1" style={sm ? { display: "flex", justifyContent: "flex-end" } : { marginTop: "-24px" }}>
                        <ManageColumnsButton handleOpen={handleOpenManageColumnsDrawer} />
                    </Col>
                </Row>
                <Table<TableItem>
                    size="small"
                    columns={visibleColumns}
                    showSorterTooltip={false}
                    onChange={handleChange}
                    loading={isLoading}
                    scroll={{ x: 2250 }}
                    dataSource={getTableItems(
                        data?.deals,
                        handleLinkClick,
                        openSeadAdSourceDrawerDeals,
                        route,
                        seatId,
                        timeZones,
                        currencies,
                        hasInternalAccess
                    )}
                    pagination={false}
                />
                <Row justify="end">
                    <Col>
                        <DealHealthPagination totalResults={data?.totalResults || 0} />
                    </Col>
                </Row>
            </Space>
            <ManageColumnsDrawer
                visible={isDrawerOpen}
                columns={allColumns}
                title="Manage Columns for Deal Health"
                handleClose={handleCloseManageColumnsDrawer}
                onChange={onChange}
            />
        </>
    );
};
