import { FC } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useHistory, useParams } from "react-router-dom";
import { InventoryLiveEventsForm } from "./InventoryLiveEventsForm";
import {
    LiveEventSubmitPayload,
    useGetLiveEventByIdQuery,
    useUpdateLiveEventMutation,
} from "@app/core/services/console/liveEvents";
import { notification } from "antd";

export const InventoryLiveEventsEditPage: FC = () => {
    const history = useHistory();
    const { seatId, eventId } = useParams<{ seatId: string; eventId: string }>();

    const { data: liveEventToEdit, isLoading } = useGetLiveEventByIdQuery(Number(eventId));
    const [updateLiveEvent, { isLoading: isSubmitting }] = useUpdateLiveEventMutation();

    if (isLoading || !liveEventToEdit) {
        return null;
    }

    const handleSubmit = (liveEventSubmitPayload: Omit<LiveEventSubmitPayload, "seat">) => {
        updateLiveEvent({
            eventId: liveEventToEdit.id,
            body: {
                ...liveEventSubmitPayload,
                id: liveEventToEdit.id,
                seat: {
                    id: Number(seatId),
                },
            },
        })
            .unwrap()
            .then(() => {
                history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_LIVE_EVENTS(seatId));
            })
            .catch((err) => {
                notification.error({
                    message:
                        err.data?.errorDescription ||
                        err.data?.errorCode ||
                        "Something went wrong when trying to edit this live event",
                });
            });
    };

    const handleCancel = () => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_LIVE_EVENTS(seatId));
    };

    return (
        <>
            <PageHeader title={`Edit ${liveEventToEdit.name}`} onBack={handleCancel} />
            <InventoryLiveEventsForm
                liveEvent={liveEventToEdit}
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
            />
        </>
    );
};
