import { SECONDS } from "./constants";
import { getLabel } from "./utils";

export const minValidator = async (
    minDuration: number | undefined,
    maxDuration: number | undefined,
    isRequired: boolean | undefined,
    min: number | undefined
): Promise<void> => {
    const minLabel = getLabel("min");
    const maxLabel = getLabel("max");

    if (isRequired && !minDuration) {
        throw new Error(`${minLabel} is required`);
    }

    if (minDuration && min && minDuration < min) {
        throw new Error(`${minLabel} can't be less than ${min} ${SECONDS}`);
    }

    if (
        typeof minDuration !== "number" ||
        isNaN(minDuration) ||
        typeof maxDuration !== "number" ||
        isNaN(maxDuration) ||
        minDuration <= maxDuration
    ) {
        return;
    }

    throw new Error(`${minLabel} can't be greater than ${maxLabel}`);
};

export const maxValidator = async (
    maxDuration: number | undefined,
    minDuration: number | undefined,
    isRequired: boolean | undefined
): Promise<void> => {
    const maxLabel = getLabel("max");
    const minLabel = getLabel("min");

    if (isRequired && !maxDuration) {
        throw new Error(`${maxLabel} is required`);
    }

    if (
        typeof minDuration !== "number" ||
        isNaN(minDuration) ||
        typeof maxDuration !== "number" ||
        isNaN(maxDuration) ||
        minDuration <= maxDuration
    ) {
        return;
    }

    throw new Error(`${maxLabel} can't be less than ${minLabel}`);
};
