import { FC, Key, useEffect, useState } from "react";
import { Button, Col, Form, Row, Space, Table, Typography, notification } from "antd";
import { useSeatAuthContext, useUserAccess } from "@app/core/auth";
import { Floor, FloorCreatePayload, useCreateSeatFloorMutation } from "@app/core/services";
import { COLUMN_KEY } from "../InventoryFloorsPage/constants";
import { SeatFloorsTableActions } from "./SeatFloorsTableActions";
import { useSeatFloorsTable } from "./useSeatFloorsTable";
import {
    getCountryValue,
    getCountryGeo,
    getFloorPriceLabel,
    isActualPrice,
    getFloorCountryLabel,
    getTargetingFromCountry,
} from "@app/features/inventory/InventoryFloors/helpers";
import { InventoryFloorHelp } from "../InventoryFloorHelp";
import { ActualPriceLabel } from "../../ActualPriceLabel";
import { EditableCell } from "./EditableCell";
import { CurrencyInput } from "@app/core/components/Fields/CurrencyInput";
import { CountryField } from "../FloorFormFields";
import { useFloorSource } from "../FloorForm/useFloorSource";
import { useSeatTree } from "../SeatTree/useSeatTree";

export interface FloorTableRecord extends Floor {
    key: string;
    editable?: boolean;
    floor: Floor;
}

export const getTableItems = (data?: Array<Floor>) =>
    data?.map((floor) => ({
        key: floor?.id,
        [COLUMN_KEY.PRICE]: floor.price / 1000,
        [COLUMN_KEY.COUNTRY]: getCountryValue(getCountryGeo(floor)),
        [COLUMN_KEY.ACTUAL_PRICE]: <ActualPriceLabel isActualPrice={isActualPrice(floor, data)} />,
        floor,
    }));

export const SeatFloorsTable: FC = () => {
    const { hasSeatWriteAccess } = useUserAccess();
    const { context } = useSeatAuthContext();
    const { expandKeys, setSelectedKeys } = useSeatTree();
    const [createSeatFloor, { isLoading: isSeatFloorSaving }] = useCreateSeatFloorMutation();
    const [mode, setMode] = useState("create");
    const [isCreating, setIsCreating] = useState(false);
    const floorSource = useFloorSource();
    const [floors, setFloors] = useState<Floor[]>([]);

    const handleAddFloor = () => {
        setMode("create");
        setIsCreating(true);
    };

    useEffect(() => {
        if (context) {
            expandKeys([`seat-${context.id}`]);
            setSelectedKeys([`seat-${context.id}`]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context]);

    const { data, isFetching, editFloor, isSaving } = useSeatFloorsTable();
    const [form] = Form.useForm();
    const [floorOnEditing, setFloorOnEditing] = useState<(Partial<FloorTableRecord> & { key: Key }) | null>(null);
    const [editingKey, setEditingKey] = useState<Key | null>(null);

    const isEditing = (record: FloorTableRecord) => record.key === editingKey;

    useEffect(() => {
        const floorList: Floor[] = [...(data || [])];
        if (isCreating) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            floorList.push({ id: 0, price: 0, targeting: [] });
            setEditingKey(0);
            form.setFieldsValue({
                [COLUMN_KEY.PRICE]: 0,
                [COLUMN_KEY.COUNTRY]: null,
            });
        }
        setFloors(floorList);
    }, [data, isCreating, form]);

    const edit = (record: Partial<FloorTableRecord> & { key: Key }) => {
        setMode("edit");
        setFloorOnEditing(record);
        form.setFieldsValue({
            [COLUMN_KEY.PRICE]: record[COLUMN_KEY.PRICE],
            [COLUMN_KEY.COUNTRY]: record[COLUMN_KEY.COUNTRY]?.value
                ? { value: record[COLUMN_KEY.COUNTRY]?.value, label: record[COLUMN_KEY.COUNTRY]?.label }
                : null,
        });
        setEditingKey(record.key);
    };

    const save = async () => {
        if (mode === "edit") {
            editFloor(floorOnEditing as FloorTableRecord, form.getFieldsValue());
        }
        if (mode === "create") {
            const newFloor = form.getFieldsValue();
            const body: FloorCreatePayload = {
                id: null,
                ...floorSource,
                price: newFloor.price * 1000,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                targeting: getTargetingFromCountry(newFloor.country),
            };
            try {
                await createSeatFloor({ seatId: Number(context?.id), body }).unwrap();
                notification.success({ message: "Floor created successfully" });
            } catch (err) {
                notification.error({ message: err.data.errorDescription });
            }
        }
        setEditingKey(null);
        setIsCreating(false);
    };

    const cancel = () => {
        setEditingKey(null);
        if (isCreating) {
            setIsCreating(false);
        }
    };

    const columns = [
        {
            dataIndex: COLUMN_KEY.PRICE,
            title: "Floor Price",
            editable: true,
            render: (_, record: FloorTableRecord) => getFloorPriceLabel(record.floor, context?.floorCurrency?.code),
            renderInputNode: () => <CurrencyInput currency={context?.floorCurrency?.code} />,
            width: "30%",
        },
        {
            dataIndex: COLUMN_KEY.COUNTRY,
            title: "Country",
            editable: true,
            render: (_, record: FloorTableRecord) => getFloorCountryLabel(record.floor),
            renderInputNode: () => <CountryField label={null} margin={0} />,
            width: "30%",
        },

        {
            dataIndex: COLUMN_KEY.ACTUAL_PRICE,
            title: "Custom Price?",
            width: "30%",
        },
        {
            dataIndex: COLUMN_KEY.ACTIONS,
            title: "Actions",
            render: (_, record: FloorTableRecord) => {
                return !isEditing(record) ? (
                    <SeatFloorsTableActions floor={record.floor} onEdit={() => edit(record)} />
                ) : (
                    <Space>
                        <Button data-sdet="" onClick={cancel} disabled={isSaving || isSeatFloorSaving}>
                            Cancel
                        </Button>
                        <Button data-sdet="" type="primary" onClick={save} loading={isSaving || isSeatFloorSaving}>
                            Save
                        </Button>
                    </Space>
                );
            },
        },
    ];

    const mapColumns = (col) => {
        if (!col.editable) {
            return col;
        }
        const newCol = {
            ...col,
            onCell: (record: FloorTableRecord) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                renderInputNode: col.renderInputNode,
                inputNodeValuePropName: col.inputNodeValuePropName,
            }),
        };

        if (col.children) {
            newCol.children = col.children.map(mapColumns);
        }

        return newCol;
    };

    const mergedColumns = columns.map(mapColumns);

    return (
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Row justify="space-between">
                <Col>
                    <Typography.Title level={5}>Seat Floors: {context?.name}</Typography.Title>
                    <Typography.Text>Pricing rules for this seat below: </Typography.Text>
                </Col>
                <Col style={{ alignSelf: "flex-end" }}>
                    <Space size="large">
                        <InventoryFloorHelp />
                        {hasSeatWriteAccess && (
                            <Button type="primary" onClick={handleAddFloor}>
                                Add Floor
                            </Button>
                        )}
                    </Space>
                </Col>
            </Row>
            <Form form={form} component={false}>
                <Table
                    size="small"
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    columns={mergedColumns}
                    showSorterTooltip={false}
                    loading={isFetching}
                    pagination={false}
                    dataSource={getTableItems(floors)}
                />
            </Form>
        </Space>
    );
};
