import { useParams } from "react-router-dom";
import { LabelValue, useGetSupplyInheritedLabelsQuery } from "@magnite/client-streaming-platform";
import { useSupplyFormInstance } from "@app/features/inventory/HierarchyForms/SupplyForm/SupplyFormInstance";

export interface UseSupplyInheritedLabels {
    isInheritedLabelsShown: boolean;
    labels: LabelValue[];
}

export interface UseSupplyInheritedLabelsProps {
    labelType: "seat" | "internal";
}

export const useSupplyInheritedLabels = ({ labelType }: UseSupplyInheritedLabelsProps): UseSupplyInheritedLabels => {
    const { supplyId } = useParams<{ supplyId: string }>();
    const { mode } = useSupplyFormInstance();
    const isEditMode = mode === "edit";

    const { data = [] } = useGetSupplyInheritedLabelsQuery(
        { supplyId: supplyId, includeMine: false, labelType: labelType },
        { skip: !isEditMode || !supplyId }
    );
    return {
        labels: data,
        isInheritedLabelsShown: isEditMode,
    };
};
