import { FC } from "react";
import { Card } from "antd";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { PUBLISHER_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { PublisherLabels } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormSections/LabelsSection/Fields";

export const LabelsInternalOnly: FC = () => {
    return (
        <Card title="Internal Only" type="inner" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
            <PublisherLabels labelType="internal" {...PUBLISHER_FORM_FIELDS.INTERNAL_LABELS} />
        </Card>
    );
};
