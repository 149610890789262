import { FC } from "react";
import { Button, Col, Dropdown, Row, Space, Tooltip } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "../../../../../types";
import { useSeatAdSourcesDetailsDrawerContent } from "../../../../../useSeatAdSourcesDetailsDrawerContent";
import {
    isAdSourceAdServingEligible,
    isAdSourceCopyEligible,
    isAdSourceEditEligible,
} from "@app/features/seatAdSources/utils";
import { useUserAccess } from "@app/core/auth";
import { useSeatAdSourcesPageNavigator } from "@app/features/seatAdSources/utils/index";

interface Props {
    adSourceId: number;
    adSourceTypeId: number;
}

export const SeatAdSourcesDetailsDrawerButtons: FC<Props> = ({ adSourceId, adSourceTypeId }) => {
    const { switchToNestedDetailsView, isReadonly } = useSeatAdSourcesDetailsDrawerContent();
    const { goToSeatAdSourcesEditPage, goToSeatAdSourcesCopyPage, isExpandAll, handleChangeCollapse } =
        useSeatAdSourcesPageNavigator();
    const { canEditSeat, isTremorUser } = useUserAccess();

    const showAdServing = isAdSourceAdServingEligible(adSourceTypeId);
    const showCopyButton = isAdSourceCopyEligible(canEditSeat, isReadonly);
    const showEditButton = isAdSourceEditEligible(canEditSeat, isReadonly);

    const MORE_MENU_ITEMS = [
        {
            label: "Change History",
            key: "detailsChangeHistory",
            onClick: () => switchToNestedDetailsView(SecondaryDetailsView.ChangeHistory),
        },
        {
            label: "Permissions",
            key: "detailsPermissions",
            onClick: () => switchToNestedDetailsView(SecondaryDetailsView.Permissions),
        },
    ];

    return (
        <Row
            data-sdet="seat-ad-sources-details-drawer-buttons"
            gutter={[0, 8]}
            justify="space-between"
            style={{ width: "100%" }}
        >
            <Col>
                <Space wrap>
                    {showEditButton && (
                        <Button type="primary" onClick={() => goToSeatAdSourcesEditPage(adSourceId)}>
                            Edit
                        </Button>
                    )}
                    {showCopyButton && <Button onClick={() => goToSeatAdSourcesCopyPage(adSourceId)}>Copy</Button>}
                    <Button onClick={() => switchToNestedDetailsView(SecondaryDetailsView.Ladle)}>Ladle</Button>
                    <Button type="link" onClick={handleChangeCollapse}>
                        {isExpandAll ? "Expand All" : "Collapse All"}
                    </Button>
                </Space>
            </Col>
            <Col>
                <Space wrap>
                    {showAdServing && (
                        <Button onClick={() => switchToNestedDetailsView(SecondaryDetailsView.AdServing)}>
                            Ad Serving
                        </Button>
                    )}
                    <Button onClick={() => switchToNestedDetailsView(SecondaryDetailsView.VastErrors)}>
                        VAST Errors
                    </Button>
                    <Button onClick={() => switchToNestedDetailsView(SecondaryDetailsView.CreativeReview)}>
                        Creative Review
                    </Button>
                    {isTremorUser && (
                        <Button onClick={() => switchToNestedDetailsView(SecondaryDetailsView.TimingStats)}>
                            Timing Stats
                        </Button>
                    )}
                    <Tooltip title="More">
                        <Dropdown
                            trigger={["click"]}
                            daga-sdet="menu-items"
                            menu={{
                                items: MORE_MENU_ITEMS,
                            }}
                        >
                            <Button data-sdet="more-action-button" icon={<MoreOutlined />} />
                        </Dropdown>
                    </Tooltip>
                </Space>
            </Col>
        </Row>
    );
};
