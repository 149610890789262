import { Form } from "antd";
import { FORM_ITEMS } from "../../../constants";
import { RuleObject } from "antd/lib/form";

export const useEstimatedPeakConcurrentViewers = () => {
    const averageConcurrentViewers = Form.useWatch([FORM_ITEMS.EST_AVG_CONCURRENT_VIEWERS.name]);
    const rule = [
        {
            message: "Peak Viewers must be greater than Average Viewers",
            validator: higherPeakViewers(averageConcurrentViewers),
        },
    ];

    return { rule };
};

const higherPeakViewers = (averageConcurrentViewers: number) => (_: RuleObject, peakConcurrentViewers: number) => {
    if (!peakConcurrentViewers) {
        return Promise.resolve();
    }
    if (averageConcurrentViewers != null && Number(peakConcurrentViewers) < Number(averageConcurrentViewers)) {
        return Promise.reject();
    }
    return Promise.resolve();
};
