import { FC, useMemo } from "react";
import { Card, CollapsePanelProps } from "antd";
import { ExpandCollapse } from "@rubicon/antd-components";
import { SegmentRuleGroupRow } from "./ReadOnlySegmentRuleGroupRow";
import { SegmentRuleGroupApi } from "../../types";
import { replaceSegmentRuleGroupValues } from "../../helpers";
import { SeatSegment } from "@magnite/client-streaming-platform";

export interface ReadOnlySegmentRulePanelProps extends CollapsePanelProps {
    targets: string[] | null;
    segments: SeatSegment[] | null;
}

export const ReadOnlySegmentRulePanel: FC<ReadOnlySegmentRulePanelProps> = ({ targets, segments, ...rest }) => {
    const groups = useMemo(() => {
        return (targets || []).map((target) => {
            const groupApi = JSON.parse(target) as SegmentRuleGroupApi;
            return replaceSegmentRuleGroupValues(groupApi, segments || []);
        });
    }, [targets, segments]);

    if (!groups.length) {
        return null;
    }

    return (
        <ExpandCollapse.Panel {...rest}>
            <Card bodyStyle={{ padding: 8 }}>
                {groups.map((group, i) => {
                    return <SegmentRuleGroupRow key={i} group={group} path={[]} />;
                })}
            </Card>
        </ExpandCollapse.Panel>
    );
};
