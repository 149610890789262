import { LabeledValue } from "antd/lib/select";
import { NA } from "@app/core/components/constants";
import { Store } from "rc-field-form/lib/interface";
import {
    DEFAULT_ALLOW_AD_SOURCE_NAME_VAST_EXTENSION,
    DEFAULT_ALLOW_VAST_EXTENSION_IAB_CATEGORY,
    DEFAULT_ALLOW_AD_SOURCE_VAST_EXTENSION,
    DEFAULT_ALLOW_VAST_EXTENSION_BID_PRICE,
    DEFAULT_ALLOW_VAST_EXTENSION_ADOMAIN,
    DEFAULT_ALLOW_VAST_EXTENSION_BRAND,
    DEFAULT_ALLOW_VAST_EXTENSION_CRID,
    DEFAULT_ALLOW_VAST_EXTENSION_DSP,
    INVENTORY_GENERATED_CODE_TYPE,
    DEFAULT_EMIT_PRICING_IN_VAST,
    INVENTORY_CUSTOM_CODE_TYPE,
    PUBLISHER_FORM_FIELDS,
    NOT_SPECIFIED_OPTION,
    HierarchyFormsMode,
} from "@app/features/inventory/HierarchyForms/constants";
import {
    parseCodeValue,
    parseLabelValues,
    parseBuyerSeatList,
    parseThirdPartyPixels,
    parsePublisherReauction,
    parseAffiliateCostModel,
    parseInternalLabelValues,
    parseCreativeBlockingMode,
    parseAdvertiserBlockDomains,
    parseAffiliateCostValueFixed,
    parseBlockedAdvertiserDomains,
    parseAffiliateCostValuePercent,
} from "@app/features/inventory/HierarchyForms/helpers";
import { Seat } from "@app/core/services";
import { Publisher, PublisherPayload } from "@magnite/client-streaming-platform";
import { PublisherForm } from "@app/features/inventory/HierarchyForms/PublisherForm/usePublisherForm";

interface ParsePublisherFormValuesToApi {
    seat: Seat | null;
    isSysAdmin: boolean;
    isTremorUser: boolean;
    isPubAcctMgr: boolean;
    values: PublisherForm;
    mode: HierarchyFormsMode;
    hasInternalAccess: boolean;
    publisher: Publisher | undefined;
}

interface GetPublisherFormInitialValues {
    mode: HierarchyFormsMode;
    publisher: Publisher | undefined;
}

export const parseBillingCode = (
    code: string | undefined | null,
    seat: Seat | null,
    publisher: Publisher | undefined,
    isTremorUser: boolean,
    mode: HierarchyFormsMode
): string => {
    if (mode === "edit" && !isTremorUser && publisher) return publisher?.billingCode;
    if (code?.trim()) return code.trim();
    return seat?.defaultBillingCode || NA;
};

const parseAccountType = (mode: HierarchyFormsMode, publisher: Publisher | undefined): LabeledValue | null => {
    const isCreateMode = mode === "create";
    if (!publisher?.accountType || isCreateMode) return null;
    return { value: publisher.accountType.id, label: publisher.accountType.name };
};

export const getPublisherFormInitialValues = ({ publisher, mode }: GetPublisherFormInitialValues): Store => {
    const isCreateMode = mode === "create";
    const isCopyMode = mode === "copy";
    return {
        // General Section
        [PUBLISHER_FORM_FIELDS.NAME.name]: isCreateMode
            ? ""
            : isCopyMode
            ? `Copy of ${publisher?.name}`
            : publisher?.name,
        [PUBLISHER_FORM_FIELDS.CODE_TYPE.name]:
            isCreateMode || isCopyMode ? INVENTORY_GENERATED_CODE_TYPE.value : INVENTORY_CUSTOM_CODE_TYPE.value,
        [PUBLISHER_FORM_FIELDS.CODE.name]: isCreateMode || isCopyMode ? "" : publisher?.code,
        [PUBLISHER_FORM_FIELDS.DESCRIPTION.name]: isCreateMode ? "" : publisher?.description,
        [PUBLISHER_FORM_FIELDS.PUBLISHER_NAME.name]: isCreateMode ? "" : publisher?.publisherNameOverride,
        [PUBLISHER_FORM_FIELDS.OVERRIDE.name]: !(isCreateMode || !publisher?.publisherNameOverride),
        [PUBLISHER_FORM_FIELDS.EXTERNAL_COMMENT.name]: isCreateMode ? "" : publisher?.externalComment,
        [PUBLISHER_FORM_FIELDS.BUSINESS_DOMAIN.name]: isCreateMode ? "" : publisher?.businessDomain,
        [PUBLISHER_FORM_FIELDS.ACCOUNT_TYPE.name]: parseAccountType(mode, publisher),
        // Affiliate Section
        [PUBLISHER_FORM_FIELDS.AFFILIATE_COST_MODEL.name]: parseAffiliateCostModel(mode, publisher),
        [PUBLISHER_FORM_FIELDS.AFFILIATE_COST_VALUE_PERCENT.name]: parseAffiliateCostValuePercent(mode, publisher),
        [PUBLISHER_FORM_FIELDS.AFFILIATE_COST_VALUE_FIXED.name]: parseAffiliateCostValueFixed(mode, publisher),
        // Brand Safety Section
        [PUBLISHER_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LISTS.name]: parseBlockedAdvertiserDomains(mode, publisher),
        [PUBLISHER_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: parseAdvertiserBlockDomains(mode, publisher),
        [PUBLISHER_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name]: parseBuyerSeatList(mode, publisher),
        [PUBLISHER_FORM_FIELDS.CREATIVE_BLOCK_MODE.name]: parseCreativeBlockingMode(mode, publisher),

        //Internal Section
        [PUBLISHER_FORM_FIELDS.BILLING_CODE.name]: isCreateMode || isCopyMode ? "" : publisher?.billingCode,
        [PUBLISHER_FORM_FIELDS.LOWER_CALCULON_USE_RATE_OVERRIDE.name]: isCreateMode
            ? null
            : publisher?.calculonUseRateOverride,
        [PUBLISHER_FORM_FIELDS.UPPER_CALCULON_USE_RATE_OVERRIDE.name]: isCreateMode
            ? null
            : publisher?.upperCalculonUseRateOverride,
        [PUBLISHER_FORM_FIELDS.PUBLISHER_RE_AUCTION.name]: parsePublisherReauction(mode, publisher),

        //Advanced Features Section
        [PUBLISHER_FORM_FIELDS.EMIT_PRICEING_IN_VAST.name]: isCreateMode
            ? DEFAULT_EMIT_PRICING_IN_VAST
            : publisher?.emitPricingInVast,
        [PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_DSP.name]: isCreateMode
            ? DEFAULT_ALLOW_VAST_EXTENSION_DSP
            : publisher?.allowVastExtensionDSP,
        [PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_CRID.name]: isCreateMode
            ? DEFAULT_ALLOW_VAST_EXTENSION_CRID
            : publisher?.allowVastExtensionCRID,
        [PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_ADOMAIN.name]: isCreateMode
            ? DEFAULT_ALLOW_VAST_EXTENSION_ADOMAIN
            : publisher?.allowVastExtensionAdomain,
        [PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_BRAND.name]: isCreateMode
            ? DEFAULT_ALLOW_VAST_EXTENSION_BRAND
            : publisher?.allowVastExtensionBrand,
        [PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_BID_PRICE.name]: isCreateMode
            ? DEFAULT_ALLOW_VAST_EXTENSION_BID_PRICE
            : publisher?.allowVastExtensionBidPrice,
        [PUBLISHER_FORM_FIELDS.ALLOW_AD_SOURCE_VAST_EXTENSION.name]: isCreateMode
            ? DEFAULT_ALLOW_AD_SOURCE_VAST_EXTENSION
            : publisher?.allowVastExtensionAdSourceType,
        [PUBLISHER_FORM_FIELDS.ALLOW_VAST_EXTENSION_IAB_CATEGORY.name]: isCreateMode
            ? DEFAULT_ALLOW_VAST_EXTENSION_IAB_CATEGORY
            : publisher?.allowVastExtensionIabCategory,
        [PUBLISHER_FORM_FIELDS.ALLOW_AD_SOURCE_NAME_VAST_EXTENSION.name]: isCreateMode
            ? DEFAULT_ALLOW_AD_SOURCE_NAME_VAST_EXTENSION
            : publisher?.allowVastExtensionAdSourceName,

        // Custom Pixels
        [PUBLISHER_FORM_FIELDS.CUSTOM_PIXELS.name]: parseThirdPartyPixels(mode, publisher),

        // Labels
        [PUBLISHER_FORM_FIELDS.LABELS.name]: parseLabelValues(mode, publisher),
        [PUBLISHER_FORM_FIELDS.INTERNAL_LABELS.name]: parseInternalLabelValues(mode, publisher),
    };
};

export const parsePublisherFormValuesToApi = ({
    mode,
    seat,
    values,
    publisher,
    isSysAdmin,
    isTremorUser,
    isPubAcctMgr,
    hasInternalAccess,
}: ParsePublisherFormValuesToApi): PublisherPayload => {
    const {
        // General Section
        name,
        code,
        description,
        publisherNameOverride,
        codeType,
        override,
        accountType,
        businessDomain,
        externalComment,
        // Affiliate Section
        affiliateCostModel,
        affiliateCostValueFixed,
        affiliateCostValuePercent,
        // Brand Safety Section
        seatAdvertiserDomainFilterListDefs,
        advertiserBlockDomains,
        buyerSeatList,
        creativeBlockingMode,
        // Internal Section
        billingCode,
        calculonUseRateOverride,
        upperCalculonUseRateOverride,
        publisherReauction,
        //Advanced Features Section
        emitPricingInVast,
        allowVastExtensionDSP,
        allowVastExtensionCRID,
        allowVastExtensionAdomain,
        allowVastExtensionBrand,
        allowVastExtensionBidPrice,
        allowVastExtensionAdSourceType,
        allowVastExtensionIabCategory,
        allowVastExtensionAdSourceName,
        // Custom Pixels
        thirdPartyPixels,
        // Labels
        labelValues,
        internalLabelValues,
    } = values;

    const payload: PublisherPayload = {
        // General Section
        name,
        code: parseCodeValue(codeType, code),
        description,
        publisherNameOverride: override ? publisherNameOverride : "",

        // Brand Safety Section
        seatAdvertiserDomainFilterListDefs: (seatAdvertiserDomainFilterListDefs || []).map((option) => ({
            id: option.value as number,
            name: option.label as string,
        })),
        advertiserBlockDomains: advertiserBlockDomains.trim() ? advertiserBlockDomains.trim().split("\n") : null,
        buyerSeatList: buyerSeatList
            ? { id: buyerSeatList.value as number, name: buyerSeatList.label as string }
            : null,

        // Custom Pixels
        thirdPartyPixels: (thirdPartyPixels || []).map((pixel) => ({
            id: pixel.value as number,
            name: pixel.label as string,
        })),

        // Labels
        labelValues: (labelValues || []).map(({ value, seatLabel, labelValue }) => ({
            id: value,
            value: labelValue,
            label: seatLabel,
        })),
    };

    if (isPubAcctMgr || isSysAdmin)
        Object.assign(payload, {
            // General Section
            accountType: accountType ? { id: accountType.value, name: accountType.label } : null,
            businessDomain,
            externalComment,
            // Advanced Features Section
            emitPricingInVast,
            allowVastExtensionDSP,
            allowVastExtensionCRID,
            allowVastExtensionAdomain,
            allowVastExtensionBrand,
            allowVastExtensionBidPrice,
            allowVastExtensionAdSourceType,
            allowVastExtensionIabCategory,
            allowVastExtensionAdSourceName,
        });

    if (isSysAdmin)
        Object.assign(payload, {
            // Brand Safety Section
            creativeBlockingMode: { id: creativeBlockingMode },
        });

    if (isTremorUser) {
        Object.assign(payload, {
            // Internal Section
            billingCode: parseBillingCode(billingCode, seat, publisher, isTremorUser, mode),
            calculonUseRateOverride,
            upperCalculonUseRateOverride,
        });
        if (isTremorUser && seat?.affiliateCostEnabled) {
            Object.assign(payload, {
                // Affiliate Section
                affiliateCostModel: affiliateCostModel
                    ? { id: affiliateCostModel.value, name: affiliateCostModel.label }
                    : null,
                affiliateCostValuePercent: affiliateCostValuePercent,
                affiliateCostValueFixed:
                    typeof affiliateCostValueFixed === "number" ? affiliateCostValueFixed * 1000 : null,
            });
        }
        if (isPubAcctMgr || isSysAdmin)
            Object.assign(payload, {
                publisherReauction: publisherReauction === NOT_SPECIFIED_OPTION.value ? null : publisherReauction,
            });
    }
    if (hasInternalAccess)
        Object.assign(payload, {
            // Labels
            internalLabelValues: (internalLabelValues || []).map(({ value, seatLabel, labelValue }) => ({
                id: value,
                value: labelValue,
                label: seatLabel,
            })),
        });

    return payload;
};
