import { FC } from "react";
import { Col, Row, Select, Typography } from "antd";
import { useAdBidStats } from "./useAdBidStats";
import { AdStatsCard } from "./AdStats/AdStatsCard";
import { BidStatsCard } from "./BidStats/BidStatsCard";

interface Props {
    adSourceId: number;
}

export const AdBidStats: FC<Props> = ({ adSourceId }) => {
    const { dealFilterOptions, dealFilterValue, handleChangeValue } = useAdBidStats(adSourceId);

    return (
        <>
            <Row style={{ marginBottom: 16 }}>
                <Col xs={24} md={16} lg={8}>
                    <Typography.Text style={{ fontWeight: "bold" }}>Deal</Typography.Text>
                    <Select
                        style={{ width: "100%" }}
                        placeholder="Deal Name"
                        options={dealFilterOptions}
                        value={dealFilterValue}
                        onChange={handleChangeValue}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} lg={8}>
                    <AdStatsCard adSourceId={adSourceId} />
                </Col>
                <Col xs={24} lg={16}>
                    <BidStatsCard adSourceId={adSourceId} />
                </Col>
            </Row>
        </>
    );
};
