import { LabeledValue } from "antd/es/select";
import { CUSTOM_OPTION, DURATION, MAX_SECONDS, MIN_SECONDS, SECONDS } from "./constants";
import { DurationType } from "./types";

const mapSecondsToSelectOptions = (optionsSeconds: ReadonlyArray<number>): LabeledValue[] => [
    ...optionsSeconds.map((seconds) => ({ label: `${seconds} ${SECONDS}`, value: seconds })),
    CUSTOM_OPTION,
];

export const getOptions = (): Record<DurationType, LabeledValue[]> => ({
    min: mapSecondsToSelectOptions(MIN_SECONDS),
    max: mapSecondsToSelectOptions(MAX_SECONDS),
});

export const getLabel = (type: DurationType): string => `${type.charAt(0).toUpperCase() + type.slice(1)} ${DURATION}`;

export const getOppositeType = (type: DurationType): DurationType => (type === "min" ? "max" : "min");

export const getInputName = (name: string): string => `${name}-input`;
export const getSelectName = (name: string): string => `${name}-select`;

export const normalizeDuration = (n: string | number | null | undefined): number | undefined => {
    const numberValue = n ? Number(n) : undefined;
    if (typeof numberValue === "number" && !isNaN(numberValue)) {
        return Math.round(numberValue);
    }
};
