import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "@app/core/components/constants";
import { DealHealthTablePagination } from "../../types";
import {
    DEAL_HEALTH_QUERY_PARAM,
    useDealHealthTypeParams,
} from "../../DealHealthTableFilters/useDealHealthTableFilters";

interface UseDealHealthTablePagination extends DealHealthTablePagination {
    pageSizeOptions: typeof PAGE_SIZE_OPTIONS;
    handleChange: (page: number, max: number) => void;
}
export const useDealHealthTablePagination = (): Omit<UseDealHealthTablePagination, "totalResults"> => {
    const {
        values: { page, max },
        setValues,
    } = useDealHealthTypeParams([
        DEAL_HEALTH_QUERY_PARAM.DEAL_PAGINATION_PAGE,
        DEAL_HEALTH_QUERY_PARAM.DEAL_PAGINATION_MAX,
    ]);

    const handleChange = (page: number, max: number): void => {
        setValues({ page: String(page), max: String(max) });
    };

    return {
        handleChange,
        maxResults: Number(max) || DEFAULT_PAGE_SIZE,
        page: Number(page) || 1,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
    };
};
