import { ROUTE_FORMATTERS } from "@app/core/routing";
import { BulkOperationSourceEntity } from "@app/core/services";
import { BulkOperationsUrlParams } from "@app/features/controls/bulkOperations/constants";
import { FC } from "react";
import { Link } from "react-router-dom";
import { selectIframed } from "../Main/reducer";
import { useAppSelector } from "@app/core/store";
import { Button } from "antd";
import { useIframeChangeApp } from "@app/core/utils";

const text = "Controls > Bulk Operations.";

export interface BulkOperationsUrlState {
    [BulkOperationsUrlParams.Type]: string | null;
    [BulkOperationsUrlParams.EntityType]: BulkOperationSourceEntity | null;
    [BulkOperationsUrlParams.EntityId]: string | null;
    withChildren?: boolean;
}

interface Props {
    seatId: string;
    id: string;
    entityType: string;
}

export const InventoryBulkOperationsLink: FC<Props> = ({ seatId, id, entityType }) => {
    const inIframe = useAppSelector(selectIframed);
    const { handleChangeApp } = useIframeChangeApp();
    const state: BulkOperationsUrlState = {
        [BulkOperationsUrlParams.Type]: "copy",
        [BulkOperationsUrlParams.EntityType]: entityType as BulkOperationSourceEntity,
        [BulkOperationsUrlParams.EntityId]: id,
        withChildren: false,
    };

    if (inIframe) {
        const data = {
            path: "bulk-operations/create",
            state,
        };

        return (
            <Button
                data-sdet="bulk-operations-button-link"
                type="link"
                style={{ padding: 0 }}
                onClick={() => handleChangeApp("controls", data)}
            >
                {text}
            </Button>
        );
    }

    return (
        <Link
            data-sdet="bulk-operations-link"
            to={{
                pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_BULK_OPERATIONS_CREATE(seatId),
                state,
            }}
        >
            {text}
        </Link>
    );
};
