import { MetricsItem } from "@rubicon/antd-components";
import { Col, Divider, Row, Typography } from "antd";

interface Props {
    item: MetricsItem;
}
const { Text } = Typography;

export const StatsItem: React.FC<Props> = ({ item }) => {
    return (
        <>
            <Row
                style={{
                    padding: "4px 6px",
                }}
                key={`${item?.title}${item?.value}`}
                justify={"space-between"}
            >
                <Col>
                    <Text
                        style={{
                            color: item?.color,
                            fontSize: "14px",
                        }}
                    >
                        {item.title}
                    </Text>
                </Col>
                <Col>
                    <Text
                        strong
                        style={{
                            color: item?.color,
                        }}
                    >
                        {item.value}
                    </Text>
                </Col>
            </Row>
            <Divider style={{ margin: 0, padding: 0 }} />
        </>
    );
};
