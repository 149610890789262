import { CreativeDetails } from "@app/core/services/diagnostics/mediaUrls";
import { Column } from "@ant-design/plots";
import { useCreativeReviewLoudnessChart } from "./useCreativeReviewLoudnessChart";

interface Props {
    loudnessSamples: CreativeDetails["loudnessSamples"];
}

export const CreativeReviewLoudnessChart = ({ loudnessSamples = [] }: Props) => {
    const { config } = useCreativeReviewLoudnessChart(loudnessSamples);
    return <Column {...config} />;
};
