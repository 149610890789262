import { FC, useState } from "react";
import { DealChartDrawerNavMenu, DealPerformanceTab } from "../../DealChartDrawer/DealChartDrawerNavMenu";
import { DealListSource } from "@app/core/services";
import { DealsHistoricalChart } from "../../DealChartDrawer/DealCharts/DealsHistoricalChart/DealsHistoricalChart";
import { Overview as DeprecatedOverview } from "./deprecated/Overview/Overview";
import { Overview } from "./Overview";

interface Props {
    dealId: number;
    adSources: DealListSource[];
    sourceCurrencyCode: string | undefined;
    isInsideDrawer: boolean;
}

export const PerformanceSection: FC<Props> = ({ adSources, dealId, sourceCurrencyCode, isInsideDrawer }) => {
    const [activeKey, setActiveKey] = useState<DealPerformanceTab>("overview");

    const activeTab = (() => {
        switch (activeKey) {
            case "overviewBeta":
                return <Overview />;
            case "overview":
                return (
                    <DeprecatedOverview
                        isInsideDrawer={isInsideDrawer}
                        dealId={dealId}
                        sourceCurrencyCode={sourceCurrencyCode}
                    />
                );
            case "historical":
                if (adSources) {
                    return <DealsHistoricalChart dealId={dealId} adSources={adSources} />;
                }
                break;
        }
        return null;
    })();

    return (
        <>
            <DealChartDrawerNavMenu activeKey={activeKey} onChange={(key) => setActiveKey(key)} />
            {activeTab}
        </>
    );
};
