import { FC } from "react";
import { Col, Form, Row } from "antd";
import { CurrencyConversionModeField } from "./CurrencyConversionModeField";
import { RevenueTypeField } from "./RevenueTypeField";
import { useUpdateSettingsOnChange } from "./useUpdateSettingsOnChange";
import { PreferredCurrencyField } from "./PreferredCurrencyField";

export const DashboardFilters: FC = () => {
    useUpdateSettingsOnChange();

    return (
        <Form colon={false} layout="horizontal">
            <Row gutter={[16, 16]} justify="start" style={{ width: "100%" }}>
                <Col xs={24} sm={24} md={10} lg={8} xl={6} xxl={4}>
                    <RevenueTypeField />
                </Col>
                <Col xs={24} sm={24} md={14} lg={11} xl={9} xxl={7}>
                    <Row wrap={false} align="bottom">
                        <Col xs={16} sm={19} md={18} lg={16} xl={16} xxl={16}>
                            <CurrencyConversionModeField />
                        </Col>
                        <Col xs={8} sm={5} md={6} lg={6} xl={5} xxl={5}>
                            <PreferredCurrencyField />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};
