import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/es/select";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { useGetViewabilityMesurementVendorQuery } from "@app/core/services/console/viewabilityMesurementVendor";
import { ViewabilityMesurementOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/useExchangeProtectionsForm";

const { useWatch } = Form;

interface UseTypeSelect {
    options: LabeledValue[];
    isFetching: boolean;
}

export const useVendorSelect = (): UseTypeSelect => {
    const { data = [], isFetching } = useGetViewabilityMesurementVendorQuery();

    const vendorsOptions: ViewabilityMesurementOption[] =
        useWatch(INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT.name) ?? [];

    const selectedTypes = vendorsOptions.map((option) => option?.value);

    const options: LabeledValue[] = useMemo(
        () =>
            data.map(({ id, name }) => ({
                value: id,
                label: name,
                disabled: selectedTypes.includes(id),
            })),
        [data, selectedTypes]
    );

    return {
        isFetching,
        options,
    };
};
