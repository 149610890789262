import { useContext } from "react";
import { useWatch } from "antd/lib/form/Form";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { useIsPbsDealType } from "@app/features/seatAdSources/SeatAdSourcesForm/useIsPbsDealType";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { useSyncedFieldsConsumer } from "@app/features/syncedFields";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";

interface UseSelectOrCreateDeal {
    isCreateNewDealVisible: boolean;
    isSelectExistingDealVisible: boolean;
    handleClickCreateNewDeal: () => void;
}

export const useSelectOrCreateDeal = (): UseSelectOrCreateDeal => {
    const { adSourceForm, dealObjectForm } = useContext(AdSourceFormsContext).forms;
    const { dealObjectMode, marketplaceInfoMode, setDealObjectMode } = useMarketplaceInfoWidget();
    const { consumeAllSyncedFields } = useSyncedFieldsConsumer();
    const adSourceTypeId = useWatch(AD_SOURCE_FIELDS.TYPE.name, adSourceForm)?.id;
    const isAdSourceGuaranteed = [
        AdSourceTypeIds.PROGRAMMATIC_GUARANTEED,
        AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED,
    ].includes(adSourceTypeId);
    const isPbsDealType = useIsPbsDealType();

    const isEligibleMode = marketplaceInfoMode.name === "create" && ["edit", "view"].includes(dealObjectMode);
    const isCreateNewDealVisible = isEligibleMode && !isPbsDealType;
    const isSelectExistingDealVisible = isEligibleMode && !isAdSourceGuaranteed;

    const handleClickCreateNewDeal = () => {
        consumeAllSyncedFields();
        setDealObjectMode("create");
        dealObjectForm.resetFields();
    };

    return {
        isCreateNewDealVisible,
        isSelectExistingDealVisible,
        handleClickCreateNewDeal,
    };
};
