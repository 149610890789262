import { FC } from "react";
import { matchPath, useLocation, useHistory, useParams } from "react-router-dom";
import { NavMenu } from "@rubicon/antd-components";
import { ItemType } from "antd/lib/menu/interface";
import { useAdStatsBetaAccess } from "@app/core/auth/useAdStatsBetaAccess";
import { ROUTE_FORMATTERS, ROUTES } from "@app/core/routing";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { selectIsBeta, setIsBeta } from "./DealHealthPage/deprecated/reducer";

enum MenuKey {
    DealHealthBeta = "dealHealthBeta",
    DealHealth = "dealHealth",
    Deals = "deals",
}

const MENU_ITEMS: Readonly<ItemType[]> = [
    {
        label: "Deal Health (BETA)",
        key: MenuKey.DealHealthBeta,
    },
    {
        label: "Deal Health",
        key: MenuKey.DealHealth,
    },
    {
        label: "Deals Management",
        key: MenuKey.Deals,
    },
];

export const DealsNavMenu: FC = () => {
    const location = useLocation();
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const dispatch = useAppDispatch();
    const isBeta = useAppSelector(selectIsBeta);
    const { hasDealHealthBetaAccess } = useAdStatsBetaAccess();
    const menuItems = MENU_ITEMS.filter((item) =>
        item?.key === MenuKey.DealHealthBeta ? hasDealHealthBetaAccess : true
    );

    const handleClick = ({ key }) => {
        switch (key) {
            case MenuKey.DealHealthBeta:
                dispatch(setIsBeta(true));
                history.push(ROUTE_FORMATTERS.SEAT_DEAL_HEALTH(seatId));
                break;
            case MenuKey.DealHealth:
                dispatch(setIsBeta(false));
                history.push(ROUTE_FORMATTERS.SEAT_DEAL_HEALTH(seatId));
                break;
            default:
                dispatch(setIsBeta(false));
                history.push(ROUTE_FORMATTERS.SEAT_DEALS(seatId));
        }
    };

    const getSelectedKey = (pathname: string): MenuKey => {
        if (matchPath(pathname, { path: ROUTES.SEAT_DEAL_HEALTH })) {
            return isBeta ? MenuKey.DealHealthBeta : MenuKey.DealHealth;
        }
        return MenuKey.Deals;
    };

    return <NavMenu selectedKey={getSelectedKey(location.pathname)} onClick={handleClick} items={menuItems} />;
};
