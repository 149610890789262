import { useEffect, useMemo, useState } from "react";

/**
 * Determines if the query is loading for the first time with the given filters
 * @param isFetching
 * @param filters
 * @returns
 */
export const useIsLoadingNewQuery = (isFetching: boolean, filters: unknown) => {
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        setFirstLoad(true);
    }, [filters]);

    useEffect(() => {
        if (!isFetching) {
            setFirstLoad(false);
        }
    }, [isFetching]);

    const isLoading = useMemo(() => firstLoad && isFetching, [firstLoad, isFetching]);

    return {
        isLoading,
    };
};
