import { MarketplaceInfo } from "@app/core/services";
import { targetingToTargetingBlock } from "@app/features/targeting";
import {
    calcFinalEc,
    EnrichmentCost,
    getAudienceEnrichmentCost,
    getAudiencesFromTargetingForm,
    getDemandFeeFromEnrichmentCost,
    getSegmentsFromTargetingForm,
} from "../AdSourcesSections/EnrichmentCostSection/utils";
import { DEFAULT_PMP_EXCLUDE_TARGETING_VALUE } from "@app/features/targeting/constants";
import { shouldInitializePmpDealIdTargeting } from "@app/core/services/console/targeting";
import { convertLocalDateToApi } from "@app/core/utils";
import { parsePriceModelFieldsToApi } from "./parsePriceModelFieldsToApi";

export const parseMarketplaceInfoListToApi = (
    adSourceTypeId: number | undefined,
    marketplaceInfoList: MarketplaceInfo[],
    adSourceTimeZoneCode: string,
    adSourceEc: EnrichmentCost,
    isRangedCost: boolean
) => {
    return marketplaceInfoList.map((marketplaceInfo) => {
        const { targeting, startDate, endDate, deal, ...rest } = marketplaceInfo;
        const targetingBlocks = targeting.map(targetingToTargetingBlock);
        const dealAudiences = getAudiencesFromTargetingForm(targetingBlocks);
        const dealSegments = getSegmentsFromTargetingForm(targetingBlocks);

        const dealEc = getAudienceEnrichmentCost(dealAudiences, dealSegments, isRangedCost, 1);
        const finalEc = calcFinalEc(adSourceEc, dealEc);
        const parsedTargeting = targeting.map((targetingItem) => {
            const { marketplace, seat, ...rest } = targetingItem;
            if (shouldInitializePmpDealIdTargeting(targetingItem.include, targetingItem.exclude) && rest.exclude) {
                return {
                    ...rest,
                    exclude: {
                        ...rest.exclude,
                        pmpDealIdTargets: DEFAULT_PMP_EXCLUDE_TARGETING_VALUE,
                    },
                };
            }
            return rest;
        });
        const demandFee = getDemandFeeFromEnrichmentCost(finalEc);
        const dealTimeZoneCode = deal.timeZone?.code;

        const marketplaceInfoPayload = {
            ...rest,
            deal: {
                ...deal,
                ...parsePriceModelFieldsToApi(adSourceTypeId),
                startTime: convertLocalDateToApi(deal.startTime, dealTimeZoneCode),
                endTime: convertLocalDateToApi(deal.endTime, dealTimeZoneCode),
            },
            startDate: convertLocalDateToApi(startDate, adSourceTimeZoneCode),
            endDate: convertLocalDateToApi(endDate, adSourceTimeZoneCode),
            targeting: parsedTargeting,
            demandFee,
        };

        if (!marketplaceInfoPayload["@class"] && deal.entityType === "AuctionPackageDeal") {
            marketplaceInfoPayload["@class"] = "com.tremorvideo.ssp.platform.model.MarketPlaceInfoAuctionPackageDeal";
        }

        return marketplaceInfoPayload;
    });
};
