import { matchPath, useHistory, useLocation, useParams } from "react-router-dom";
import { ItemType } from "antd/lib/menu/interface";
import { useAdStatsBetaAccess } from "@app/core/auth/useAdStatsBetaAccess";
import { ROUTE_FORMATTERS, ROUTES } from "@app/core/routing";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { selectIsBeta, setIsBeta } from "../SeatAdSourcesListPage/reducer";

interface UseSeatAdSourcesNavMenu {
    menuItems: ItemType[];
    selectedKey: string;
    handleClick: (arg: { key: string }) => void;
}

enum MenuKey {
    AdSourcesBeta = "adSourcesBeta",
    AdSources = "adSources",
    PacingSummary = "pacingSummary",
}

const MENU_ITEMS: Readonly<ItemType[]> = [
    {
        label: "Ad Sources (BETA)",
        key: MenuKey.AdSourcesBeta,
    },
    {
        label: "Ad Sources",
        key: MenuKey.AdSources,
    },
    {
        label: "Pacing Summary",
        key: MenuKey.PacingSummary,
    },
];

export const useSeatAdSourcesNavMenu = (): UseSeatAdSourcesNavMenu => {
    const location = useLocation();
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const dispatch = useAppDispatch();
    const { hasAdSourcesListBetaAccess } = useAdStatsBetaAccess();
    const isBeta = useAppSelector(selectIsBeta);

    const handleClick = ({ key }) => {
        switch (key) {
            case MenuKey.PacingSummary: {
                dispatch(setIsBeta(false));
                history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES_PACING(seatId));
                break;
            }
            case MenuKey.AdSourcesBeta: {
                dispatch(setIsBeta(true));
                history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES(seatId));
                break;
            }
            default: {
                dispatch(setIsBeta(false));
                history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES(seatId));
                break;
            }
        }
    };

    const selectedKey = (() => {
        const isOnPacingPage = matchPath(location.pathname, { path: ROUTES.SEAT_AD_SOURCES_PACING });
        if (isOnPacingPage) {
            return MenuKey.PacingSummary;
        }
        return isBeta ? MenuKey.AdSourcesBeta : MenuKey.AdSources;
    })();

    const menuItems = MENU_ITEMS.filter((item) =>
        item?.key === MenuKey.AdSourcesBeta ? hasAdSourcesListBetaAccess : true
    );

    return {
        menuItems,
        selectedKey,
        handleClick,
    };
};
