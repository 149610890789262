import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useHistory, useParams } from "react-router-dom";
import { useDealDetailsDrawer } from "@app/features/deals/DealDetailsDrawer/useDealDetailsDrawer";
import { useState } from "react";
import { selectIframed } from "@app/core/components/Main/reducer";
import { useAppSelector } from "@app/core/store";
import { getUrlSegment } from "@app/core/utils";

interface UseDealDetailsDrawerNavTitle {
    goDealDetailsPage: () => void;
    seatId: number;
    visibleCopy: boolean;
    handleVisibleCopy: () => void;
    handleHideCopy: () => void;
    copyUrl: string;
}

export const useDealDetailsDrawerNavTitle = (dealId: number): UseDealDetailsDrawerNavTitle => {
    const inIframe = useAppSelector(selectIframed);
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();
    const [visibleCopy, setVisibleCopy] = useState<boolean>(false);
    const { closeDealDetailsDrawer } = useDealDetailsDrawer();
    const targetPath = ROUTE_FORMATTERS.SEAT_DEALS_DEAL_DETAILS_DRAWER(seatId, dealId);
    const { origin, pathname } = window.location;
    const url = new URL(`${pathname}#${targetPath}`, origin);
    const copyUrl = inIframe ? getUrlSegment(url.hash) : url.href;

    const goDealDetailsPage = () => {
        closeDealDetailsDrawer();
        history.push(ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(seatId, dealId));
    };

    const handleVisibleCopy = () => {
        setVisibleCopy(true);
    };
    const handleHideCopy = () => {
        setVisibleCopy(false);
    };

    return {
        goDealDetailsPage,
        seatId: Number(seatId),
        handleVisibleCopy,
        handleHideCopy,
        visibleCopy,
        copyUrl,
    };
};
