import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { fixedBottomButtonsStyle } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Button, FormInstance, Space } from "antd";
import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    BULK_OPERATION_FORM_ITEMS,
    DESTINATION_ENTITY_ID_FIELD,
    DESTINATION_ENTITY_NAME_FIELD,
    SOURCE_ENTITY_ID_FIELD,
} from "../../constants";
import { BulkOperationType } from "@app/core/services";

interface Props {
    goToNext: () => void;
    goToPrev: () => void;
    form: FormInstance;
}

export const BulkOperationDetailsStepBottomButtons: FC<Props> = ({ goToPrev, goToNext, form }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();

    return (
        <div className={fixedBottomButtonsStyle}>
            <Space direction="horizontal">
                <Button data-sdet="step2-previous-button" onClick={goToPrev}>
                    <ArrowLeftOutlined />
                    Previous
                </Button>
                <Button
                    data-sdet="step2-next-button"
                    type="primary"
                    onClick={() => {
                        const { type, ...rest } = form.getFieldsValue(true);
                        const restNames = Object.keys(rest);
                        //HACK: omit all keys that doesn't related to min/max Duration
                        // due to these fields validation is specific we need this
                        const minMaxDurationNames = restNames.filter(
                            (name) =>
                                name.toLowerCase().includes("minduration") || name.toLowerCase().includes("maxduration")
                        );

                        form.validateFields(
                            type === BulkOperationType.Edit
                                ? [
                                      BULK_OPERATION_FORM_ITEMS.OPERATION_ENTITIES_EDIT_SECTION_ERROR_MESSAGE.name,
                                      ...minMaxDurationNames,
                                  ]
                                : [SOURCE_ENTITY_ID_FIELD, DESTINATION_ENTITY_NAME_FIELD, DESTINATION_ENTITY_ID_FIELD]
                        ).then(() => goToNext());
                    }}
                >
                    Next
                    <ArrowRightOutlined />
                </Button>
                <Button
                    data-sdet="step2-cancel-button"
                    onClick={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BULK_OPERATIONS(seatId))}
                >
                    Cancel
                </Button>
            </Space>
        </div>
    );
};
