import { FC } from "react";
import { CollapsePanelProps, Typography } from "antd";
import { ExpandCollapse } from "@rubicon/antd-components";
import { MaxDurationTarget } from "../types";

export interface ReadOnlyMaxDurationTargetingPanelProps extends CollapsePanelProps {
    target: MaxDurationTarget | null;
}

export const ReadOnlyMaxDurationTargetingPanel: FC<ReadOnlyMaxDurationTargetingPanelProps> = ({ target, ...rest }) => {
    if (!target) {
        return null;
    }

    return (
        <ExpandCollapse.Panel {...rest}>
            <Typography.Text>
                {target.operator?.name} {target.maxDuration}
            </Typography.Text>
        </ExpandCollapse.Panel>
    );
};
