import { FC } from "react";
import { Tabs } from "antd";
import { useAdStatsBetaAccess } from "@app/core/auth/useAdStatsBetaAccess";

interface DealChartDrawerNavMenuProps {
    activeKey: DealPerformanceTab;
    onChange: (key: DealPerformanceTab) => void;
}

export type DealPerformanceTab = "overviewBeta" | "overview" | "historical";

type DealChartDrawerTabItem = {
    label: string;
    key: DealPerformanceTab;
};

const TAB_ITEMS: Readonly<DealChartDrawerTabItem[]> = [
    { label: "Overview (BETA)", key: "overviewBeta" },
    { label: "Overview", key: "overview" },
    { label: "Historical Charts", key: "historical" },
];

export const DealChartDrawerNavMenu: FC<DealChartDrawerNavMenuProps> = ({ activeKey, onChange }) => {
    const { hasDealPerformanceOverviewBetaAccess } = useAdStatsBetaAccess();

    const accessibleTabItems = TAB_ITEMS.filter(({ key }) =>
        key === "overviewBeta" ? hasDealPerformanceOverviewBetaAccess : true
    );

    return (
        <Tabs items={accessibleTabItems} activeKey={activeKey} onChange={(key: DealPerformanceTab) => onChange(key)} />
    );
};
