import { Audience } from "@app/core/services";
import { Col, Form, Row, Typography } from "antd";
import { FC } from "react";
import { displayEnrichmentCost, EnrichmentCost } from "../utils";
import { ModifySegmentsMessage } from "./ModifySegmentsMessage";
import { SegmentsDetailsTable } from "./SegmentsDetailsTable";
import { AudiencesDetailsTable } from "../AudiencesDetailsTable";
import { SeatSegment } from "@magnite/client-streaming-platform";

interface Props {
    mode: "deal" | "adSource";
    audiences: Audience[];
    segments: SeatSegment[];
    ecValue: EnrichmentCost;
    dealName?: string;
    adSourceCurrencyCode: string;
    conversionRate: number;
}

export const EcDetailsSection: FC<Props> = ({
    mode,
    audiences,
    segments,
    ecValue,
    dealName,
    adSourceCurrencyCode,
    conversionRate,
}) => {
    return (
        <div style={{ padding: "16px" }}>
            <Row gutter={[0, 16]}>
                <Col span={24}>
                    <Form.Item
                        label={`${mode === "deal" ? "Deal" : "Ad Source"} Audience Enrichment Cost`}
                        style={{ marginBottom: 0 }}
                    >
                        <Typography.Text>{displayEnrichmentCost(ecValue, adSourceCurrencyCode)}</Typography.Text>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={`Targeted Audience Segments In ${mode === "deal" ? "Deal" : "Ad Source"}`}
                        style={{ marginBottom: 0 }}
                    >
                        {Boolean(segments.length || audiences.length) && (
                            <ModifySegmentsMessage mode={mode} type={segments.length ? "dmp" : "audience"} />
                        )}
                        {Boolean(segments.length) && (
                            <SegmentsDetailsTable
                                segments={segments}
                                currencyCode={adSourceCurrencyCode}
                                conversionRate={conversionRate}
                            />
                        )}
                        {Boolean(audiences.length) && (
                            <AudiencesDetailsTable
                                audiences={audiences}
                                currencyCode={adSourceCurrencyCode}
                                conversionRate={conversionRate}
                            />
                        )}
                        {!audiences.length && !segments.length && (
                            <Typography.Text>
                                You don&apos;t have targeted Audiences in the{" "}
                                {mode === "deal" ? dealName || "Deal" : "Ad Source"}
                            </Typography.Text>
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};
