import { FC } from "react";
import { Col, Form, InputNumber, Row, Select } from "antd";
import { SECONDS } from "./constants";
import { MinMaxDurationSelectProps } from "./types";
import { getInputName, getSelectName, normalizeDuration } from "./utils";
import { useMinMaxDurationSelect } from "./useMinMaxDurationSelect";

export const MinMaxDurationSelect: FC<MinMaxDurationSelectProps> = ({
    mode,
    required,
    min,
    onChange,
    value,
    id,
    searchV,
    opposite,
}) => {
    //INFO: id ?? mode - when id exists it means we render component in table and
    //      when id is undefined it means comopnent renders inside modal where id useless
    const inputName = getInputName(id ?? mode);
    const selectName = getSelectName(id ?? mode);
    const oppositeName = `${(id ?? mode).replace(searchV, opposite)}`;

    const { isCustom, label, selectRules, inputRules, options, handleSelectChange, handleInputBlur } =
        useMinMaxDurationSelect(inputName, selectName, mode, value, oppositeName, required, min, onChange);

    return (
        <Row>
            <Col span={isCustom ? 8 : 24}>
                <Form.Item
                    name={selectName}
                    data-sdet={`${mode}-duration-select`}
                    rules={selectRules}
                    required={required}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Select
                        options={options}
                        placeholder={`Select ${label}`}
                        allowClear
                        style={{ width: "100%" }}
                        onChange={handleSelectChange}
                    />
                </Form.Item>
            </Col>
            <Col span={16}>
                <Form.Item
                    hidden={!isCustom}
                    name={inputName}
                    data-sdet={`${mode}-duration-input`}
                    normalize={normalizeDuration}
                    rules={inputRules}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <InputNumber
                        placeholder={`Enter ${label}`}
                        addonAfter={SECONDS}
                        style={{ width: "100%" }}
                        onBlur={handleInputBlur}
                    />
                </Form.Item>
            </Col>
        </Row>
    );
};
