import { FC } from "react";
import {
    AD_SOURCE_SECTIONS,
    AD_SOURCE_SECTIONS_NAME,
    cardBodyStyle,
    COL_SIZES,
    GUTTER,
    mainStepSectionClassList,
} from "../../../constants";
import { DefinitionSidePanel } from "../../DefinitionSidePanel";
import { Card, Col, Row, Collapse } from "antd";
import {
    AssignedAdvertiserDomainInput,
    AssigneeSelect,
    AssociatedCuratorMarketplacesSelect,
    AssociatedMarketplaceSelect,
    DescriptionTextArea,
    ExtendTimeoutRadio,
    ExternalContractNumberInput,
    NameInput,
    PrioritySelect,
    SalesContactSelect,
    StatusSelect,
    TagURLTextArea,
    TypeSelect,
} from "./Fields";
import { useUserAccess } from "@app/core/auth";
import { useIsPbsDealType } from "../../useIsPbsDealType";
import { UDPrioritySelect } from "./Fields/UDPriority";
import { AdminSettings } from "./AdminSettings";

export const AdSourceTermsSection: FC = () => {
    const { isTremorUser } = useUserAccess();
    const isPbsDealType = useIsPbsDealType();
    return (
        <Collapse
            bordered
            ghost={false}
            collapsible="header"
            className={mainStepSectionClassList}
            defaultActiveKey={["1"]}
            data-sdet={AD_SOURCE_SECTIONS.AD_SOURCE_TERMS.toLowerCase().replace(/_/g, "-")}
            items={[
                {
                    key: "1",
                    forceRender: true,
                    label: AD_SOURCE_SECTIONS_NAME.AD_SOURCE_TERMS,
                    extra: (
                        <DefinitionSidePanel
                            panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.AD_SOURCE_TERMS]}
                            section={AD_SOURCE_SECTIONS.AD_SOURCE_TERMS}
                            sectionSelector="#adSourceForm"
                        />
                    ),
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES}>
                                    <TypeSelect />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES}>
                                    <NameInput />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col span={16}>
                                    <DescriptionTextArea />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES}>
                                    <PrioritySelect />
                                </Col>
                                <Col {...COL_SIZES}>
                                    <StatusSelect />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES}>
                                    <AssigneeSelect />
                                </Col>
                                <Col {...COL_SIZES}>
                                    <SalesContactSelect />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES}>
                                    <ExternalContractNumberInput />
                                </Col>
                                <Col {...COL_SIZES}>
                                    <ExtendTimeoutRadio />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES}>
                                    <UDPrioritySelect />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES}>
                                    <AssociatedMarketplaceSelect />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col sm={24} md={16}>
                                    <AssociatedCuratorMarketplacesSelect />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col span={16}>
                                    <TagURLTextArea />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col span={16}>
                                    <AssignedAdvertiserDomainInput />
                                </Col>
                            </Row>
                            {isTremorUser && !isPbsDealType ? <AdminSettings /> : null}
                        </Card>
                    ),
                },
            ]}
        />
    );
};
