import { Form, Input } from "antd";
import { FC, useEffect } from "react";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { usePublisherChannelFormInstance } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormInstance";

export const BusinessDomain: FC = () => {
    const { mode, form, seat } = usePublisherChannelFormInstance();
    const extra: string | undefined = seat?.businessDomain ? `Inherited value: ${seat.businessDomain}` : undefined;

    useEffect((): void => {
        if (mode === "create") {
            form.setFieldValue(CHANNEL_FORM_FIELDS.BUSINESS_DOMAIN.name, seat?.businessDomain);
        }
    }, [mode, seat, form]);

    return (
        <Form.Item
            extra={extra}
            name={CHANNEL_FORM_FIELDS.BUSINESS_DOMAIN.name}
            label={CHANNEL_FORM_FIELDS.BUSINESS_DOMAIN.label}
            wrapperCol={{ xs: 24, sm: 24, md: 10, lg: 9, xl: 12, xxl: 11 }}
            rules={[
                {
                    required: true,
                    whitespace: true,
                    message: CHANNEL_FORM_FIELDS.BUSINESS_DOMAIN.rulesMessage,
                },
            ]}
        >
            <Input allowClear placeholder={CHANNEL_FORM_FIELDS.BUSINESS_DOMAIN.placeholder} />
        </Form.Item>
    );
};
