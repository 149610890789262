import { Row, Col, Typography } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useSeatAuthContext } from "@app/core/auth";
import { FC } from "react";

const InventoryPermissionsToolbar: FC = () => {
    const { context } = useSeatAuthContext();

    return (
        <PageHeader ghost={false} title={`Permissions: ${context?.name}`}>
            <Row>
                <Col>
                    <Typography.Paragraph>
                        Create and manage permissions for users and groups in this seat
                    </Typography.Paragraph>
                </Col>
            </Row>
        </PageHeader>
    );
};

export default InventoryPermissionsToolbar;
