import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useUserAccess } from "@app/core/auth";

interface Props {
    isBeta?: boolean;
}

export const SeatAdSourcesListToolbar: FC<Props> = ({ isBeta }) => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { canEditSeat } = useUserAccess();

    const handleCreate = (): void => {
        history.push(ROUTE_FORMATTERS.SEAT_AD_SOURCES_CREATE(seatId));
    };

    return (
        <div data-sdet="seat-ad-sources-list-toolbar">
            <PageHeader
                ghost={false}
                title={`Ad Sources${isBeta ? " (BETA)" : ""}`}
                extra={
                    canEditSeat && (
                        <Button type="primary" onClick={handleCreate}>
                            Create
                        </Button>
                    )
                }
            />
        </div>
    );
};
