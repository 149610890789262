import { FC } from "react";
import { Tag } from "antd";
import { LabeledValue } from "antd/es/select";

interface Props {
    value?: LabeledValue[];
}

export const AllowedExtendedIdTransparencyTags: FC<Props> = ({ value = [] }) => {
    return (
        <>
            {value.map((v) => (
                <Tag color="processing" key={v.value}>
                    {v.label}
                </Tag>
            ))}
        </>
    );
};
