import { FC } from "react";
import { Badge, Button, Collapse, Space, Typography, Row, Col, Form } from "antd";
import { brandText2 } from "@rubicon/antd-components";
import { useDealHealthTableFilters } from "./useDealHealthTableFilters";
import { DealHealthTypeFilter } from "./DealHealthTypeFilter";
import { DealHealthDspFilter } from "./DealHealthDspFilter";
import { StatusFilter } from "./StatusFilter";
import { DealHealthPriorityFilter } from "@app/features/deals/DealHealthPage/deprecated/DealHealthTableFilters/DealHealthPriorityFilter";

const DealHealthTableFilters: FC = () => {
    const { filterCount, handleClearFilters } = useDealHealthTableFilters();

    return (
        <Collapse collapsible="header" defaultActiveKey="filters">
            <Collapse.Panel
                key="filters"
                header={
                    <Space>
                        <Typography.Text>Filters</Typography.Text>
                        <Badge
                            count={filterCount}
                            data-sdet="deal-filter-count"
                            style={{ backgroundColor: brandText2 }}
                        />
                    </Space>
                }
                extra={
                    <Button
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClearFilters();
                        }}
                        data-sdet="clear"
                    >
                        Clear
                    </Button>
                }
            >
                <Form layout="vertical">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                            <DealHealthTypeFilter />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                            <DealHealthDspFilter />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                            <StatusFilter />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                            <DealHealthPriorityFilter />
                        </Col>
                    </Row>
                </Form>
            </Collapse.Panel>
        </Collapse>
    );
};
export default DealHealthTableFilters;
