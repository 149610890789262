import { SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { useWatch } from "antd/lib/form/Form";

export const useIsFieldVisible = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeId = adSourceType?.id;

    switch (adSourceTypeId) {
        case null:
        case AdSourceTypeIds.TREMOR_NETWORK:
        case AdSourceTypeIds.PROGRAMMATIC_GUARANTEED:
        case AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED:
        case AdSourceTypeIds.FIXED_PRICE:
        case AdSourceTypeIds.AUCTION_PRICE:
        case AdSourceTypeIds.OPEN_AUCTION:
        case AdSourceTypeIds.LINEAR_UNRESERVED_FIXED_RATE:
        case AdSourceTypeIds.LINEAR_AUCTION_PRICE:
        case AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED:
        case AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED:
        case AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE:
        case AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED:
        case AdSourceTypeIds.FALLBACK_TAG:
        case AdSourceTypeIds.PRE_BID_AD_SOURCE:
            return true;
        default:
            return false;
    }
};
