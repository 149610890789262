import { useMemo, useState } from "react";
import { useGetPlatformsQuery } from "@magnite/client-streaming-platform";
import { platformTargetToLabelValue } from "@app/features/targeting/helpers";

export const usePlatformSelect = (value) => {
    const [search, setSearch] = useState("");
    const { data, isFetching } = useGetPlatformsQuery();
    const orderedData = useMemo(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        () => [...(data || [])].sort((a, b) => a.type.localeCompare(b.type) || a.sort - b.sort),
        [data]
    );
    const orderedDataLabelValues = useMemo(() => platformTargetToLabelValue(orderedData || []), [orderedData]);
    const originalValuesById = useMemo(
        () =>
            orderedData.reduce((acc, v) => {
                acc[v.id] = v;
                return acc;
            }, {}),
        [orderedData]
    );
    const selectedOriginalValues = useMemo(
        () => value.map((v) => originalValuesById[v.value]),
        [originalValuesById, value]
    );
    const isParent = (platform) => platform?.parentId === null;
    const hasSelectedParent = useMemo(
        () =>
            selectedOriginalValues.reduce((acc, platform) => {
                if (isParent(platform)) {
                    acc[platform.type] = true;
                }
                return acc;
            }, {}),
        [selectedOriginalValues]
    );
    const options = useMemo(
        () =>
            orderedDataLabelValues
                .map((option) => {
                    return {
                        ...option,
                        disabled:
                            hasSelectedParent[originalValuesById[option.value]?.type] &&
                            !isParent(originalValuesById[option.value]),
                    };
                })
                .filter((option) => {
                    return option.searchLabel.toLowerCase().includes(search.toLowerCase());
                }),
        [orderedDataLabelValues, hasSelectedParent, originalValuesById, search]
    );

    return {
        isLoading: isFetching,
        options,
        search,
        onSearch: setSearch,
        onBlur: () => setSearch(""),
    };
};
