import { Footer } from "@app/core/components";
import { useScrollToTopOnMount } from "@app/core/components/hooks";
import { PublisherChannelForm } from "@app/features/inventory/HierarchyForms/PublisherChannelForm";
import { PublisherChannelFormInstance } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormInstance";
import { PageLoader } from "@rubicon/antd-components";
import { Layout } from "antd";
import { useParams } from "react-router-dom";
import { PublisherChannelEditPageHeader } from "./PublisherChannelEditPageHeader";
import { useGetChannelQuery } from "@magnite/client-streaming-platform";

export const PublisherChannelEditPage = () => {
    useScrollToTopOnMount();
    const { channelId } = useParams<{ channelId: string }>();
    const { data, isFetching } = useGetChannelQuery(Number(channelId));

    if (isFetching || !data) return <PageLoader />;
    return (
        <>
            <PublisherChannelEditPageHeader channel={data} />
            <Layout.Content>
                <PublisherChannelFormInstance mode="edit" channel={data}>
                    <PublisherChannelForm />
                </PublisherChannelFormInstance>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
