import { FC, MouseEvent, ReactNode, useMemo } from "react";
import { Col, Flex, Grid, Row, Space, Table } from "antd";
import { useSeatAdSourcesListTable } from "./useSeatAdSourcesListTable";
import { LiveAdSourceListItem } from "../../data";
import { SeatAdSourcesDealCountDrawer } from "./SeatAdSourcesDealCountDrawer";
import { OpenDealCountDrawer, useSeatAdSourcesDealCountDrawer } from "./SeatAdSourcesDealCountDrawer";
import { SeatAdSourcesListTableCpmRateCell, SeatAdSourcesListTableNetRevenueCell } from "./seatAdSourcesListTableCells";
import { HelpTooltip, LoadableAnimatedNumberCell, StyledLink } from "@app/core/components";
import {
    SeatAdSourcesActionButtonBar,
    SeatAdSourcesActionButton as ActionButton,
    SeatAdSourcesMoreMenuItem as MoreMenuItem,
} from "@app/core/components/SeatAdSourcesActionButtonBar";
import { AdSourcesHealthHelpKeys, AdSourceTypeAcronyms, AdSourceTypeIds } from "../../constants";
import { PacingProgressBar } from "@app/core/components/PacingProgressBar/PacingProgressBar";
import {
    MANAGE_COLUMNS_KEY_AD_SOURCES,
    useManageColumnsDrawer,
} from "@app/core/components/ManageColumns/useManageColumnsDrawer";
import { ManageColumnsButton, ManageColumnsDrawer } from "@app/core/components/ManageColumns";
import { SeatAdSourcesFilterSearch } from "../SeatAdSourcesListFilterSearch";
import { SeatAdSourcesListViewRadio } from "../SeatAdSourcesListViewRadio";
import { useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { SeatAdSourceStatusTag } from "../../SeatAdSourceStatusTag";
import { Dashes } from "@app/core/components";
import { SeatAdSourcesCurrencyFilters } from "../SeatAdSourcesCurrencyFilters";
import { PAGE_SIZE_OPTIONS } from "@app/core/components/constants";
import { useSeatAdSourcesDetailsDrawer } from "../../SeatAdSourcesDetailsDrawer/useSeatAdSourcesDetailsDrawer";
import { formatNumber } from "@rubicon/utils";
import { useAppSelector } from "@app/core/store";
import { selectSeatAdSourcesTableViewSort } from "@app/features/seatAdSources/SeatAdSourcesListPage/reducer";
import { ScreensBreakpoint } from "@app/features/inventory/InventoryBrandSafety/TreeDrawer";

enum ColumnKey {
    DealCount = "dealCount",
    Pacing = "pacing",
    Skips = "skips",
    Tries = "tries",
    Fills = "fills",
    Impressions = "impressions",
    NetRevenue = "netRevenue",
    Time = "time",
    Status = "status",
    Actions = "actions",
}

const sortableColumnKey = {
    AdSourceName: "name",
    Priority: "priority",
    Type: "type",
    CpmRate: "cpmrate",
} as const;

const { useBreakpoint } = Grid;

const getDefaultColumns = (sortValue) => [
    {
        title: (
            <HelpTooltip
                helpKeyList={AdSourcesHealthHelpKeys}
                helpKey={AdSourcesHealthHelpKeys.AdSourceName}
                popover={true}
            >
                Ad Source Name
            </HelpTooltip>
        ),
        dataIndex: sortableColumnKey.AdSourceName,
        sorter: true,
        defaultSortOrder: sortValue.orderBy === sortableColumnKey.AdSourceName,
        width: 280,
        selectable: false,
        visible: true,
    },
    {
        title: (
            <HelpTooltip
                helpKeyList={AdSourcesHealthHelpKeys}
                helpKey={AdSourcesHealthHelpKeys.Priority}
                popover={true}
            >
                Priority
            </HelpTooltip>
        ),
        dataIndex: sortableColumnKey.Priority,
        defaultSortOrder: sortValue.orderBy === sortableColumnKey.Priority,
        sorter: true,
        selectable: true,
        visible: true,
    },
    {
        title: (
            <HelpTooltip helpKeyList={AdSourcesHealthHelpKeys} helpKey={AdSourcesHealthHelpKeys.Type} popover={true}>
                Type
            </HelpTooltip>
        ),
        dataIndex: sortableColumnKey.Type,
        sorter: true,
        defaultSortOrder: sortValue.orderBy === sortableColumnKey.Type,
        selectable: true,
        visible: true,
    },
    {
        title: (
            <HelpTooltip
                helpKeyList={AdSourcesHealthHelpKeys}
                helpKey={AdSourcesHealthHelpKeys.NumberOfDeals}
                popover={true}
            >
                # of Deals
            </HelpTooltip>
        ),
        dataIndex: ColumnKey.DealCount,
        sorter: false,
        selectable: true,
        visible: true,
    },
    {
        title: (
            <HelpTooltip helpKeyList={AdSourcesHealthHelpKeys} helpKey={AdSourcesHealthHelpKeys.Pacing} popover={true}>
                Pacing
            </HelpTooltip>
        ),
        dataIndex: ColumnKey.Pacing,
        sorter: false,
        selectable: true,
        visible: true,
    },
    {
        title: (
            <HelpTooltip helpKeyList={AdSourcesHealthHelpKeys} helpKey={AdSourcesHealthHelpKeys.CPMRate} popover={true}>
                CPM Rate
            </HelpTooltip>
        ),
        dataIndex: sortableColumnKey.CpmRate,
        sorter: false,
        selectable: true,
        visible: true,
    },
    {
        title: (
            <HelpTooltip helpKeyList={AdSourcesHealthHelpKeys} helpKey={AdSourcesHealthHelpKeys.Skips} popover={true}>
                Skips
            </HelpTooltip>
        ),
        dataIndex: ColumnKey.Skips,
        sorter: false,
        selectable: true,
        visible: true,
    },
    {
        title: (
            <HelpTooltip helpKeyList={AdSourcesHealthHelpKeys} helpKey={AdSourcesHealthHelpKeys.Tries} popover={true}>
                Tries
            </HelpTooltip>
        ),
        dataIndex: ColumnKey.Tries,
        sorter: false,
        selectable: true,
        visible: true,
    },
    {
        title: (
            <HelpTooltip helpKeyList={AdSourcesHealthHelpKeys} helpKey={AdSourcesHealthHelpKeys.Fills} popover={true}>
                Fills
            </HelpTooltip>
        ),
        dataIndex: ColumnKey.Fills,
        sorter: false,
        selectable: true,
        visible: true,
    },
    {
        title: (
            <HelpTooltip
                helpKeyList={AdSourcesHealthHelpKeys}
                helpKey={AdSourcesHealthHelpKeys.Impressions}
                popover={true}
            >
                Impressions
            </HelpTooltip>
        ),
        dataIndex: ColumnKey.Impressions,
        sorter: false,
        selectable: true,
        visible: true,
    },
    {
        title: (
            <HelpTooltip
                helpKeyList={AdSourcesHealthHelpKeys}
                helpKey={AdSourcesHealthHelpKeys.NetRevenue}
                popover={true}
            >
                Net Revenue
            </HelpTooltip>
        ),
        dataIndex: ColumnKey.NetRevenue,
        sorter: false,
        selectable: true,
        visible: true,
    },
    {
        title: (
            <HelpTooltip helpKeyList={AdSourcesHealthHelpKeys} helpKey={AdSourcesHealthHelpKeys.Time} popover={true}>
                Time(ms)
            </HelpTooltip>
        ),
        dataIndex: ColumnKey.Time,
        sorter: false,
        selectable: true,
        visible: true,
    },
    {
        title: (
            <HelpTooltip helpKeyList={AdSourcesHealthHelpKeys} helpKey={AdSourcesHealthHelpKeys.Status} popover={true}>
                Status
            </HelpTooltip>
        ),
        dataIndex: ColumnKey.Status,
        sorter: false,
        selectable: true,
        visible: true,
    },
    {
        title: "Actions",
        dataIndex: ColumnKey.Actions,
        sorter: false,
        width: 160,
        selectable: false,
        visible: true,
    },
];

export interface TableItem {
    key: number;
    name: ReactNode;
    priority: ReactNode;
    type: ReactNode;
    cpmrate: ReactNode;
    dealCount: ReactNode;
    pacing: ReactNode;
    skips: ReactNode;
    tries: ReactNode;
    fills: ReactNode;
    impressions: ReactNode;
    netRevenue: ReactNode;
    time: ReactNode;
    status: ReactNode;
    actions: ReactNode;
}

const getDisplayableTableItems = (
    liveAdSources: LiveAdSourceListItem[],
    seatId: string,
    route: string,
    handleLinkClick: (e: MouseEvent<HTMLElement>, adSourceId: number) => void,
    openDealCountDrawer: OpenDealCountDrawer
): TableItem[] =>
    liveAdSources?.map((liveAdSource) => {
        const { adSource, liveData } = liveAdSource;

        return {
            key: adSource.id,
            [sortableColumnKey.AdSourceName]: (
                <StyledLink
                    href={`${route}${ROUTE_FORMATTERS.SEAT_AD_SOURCES_DETAILS_DRAWER(seatId, adSource.id)}`}
                    onClick={(e: React.MouseEvent<HTMLElement>) => handleLinkClick(e, adSource.id)}
                >
                    {adSource.name}
                </StyledLink>
            ),
            [sortableColumnKey.Priority]: <Dashes value={adSource.priority?.name} />,
            // TODO: remove AdSourceTypeAcronyms[<id>] abbreviation fallback once API starts returning adSource.type.abbreviation
            [sortableColumnKey.Type]: (
                <Dashes value={adSource.type?.abbreviation || AdSourceTypeAcronyms[adSource.type?.id]} />
            ),
            [ColumnKey.DealCount]:
                adSource.dealCount > 0 ? (
                    <StyledLink
                        onClick={() =>
                            openDealCountDrawer(
                                liveAdSource.adSource.id,
                                liveAdSource.adSource.name,
                                liveAdSource.adSource.dealCount
                            )
                        }
                    >
                        {liveAdSource.adSource.dealCount}
                    </StyledLink>
                ) : (
                    0
                ),
            [ColumnKey.Pacing]:
                adSource.type?.id === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED ? (
                    <PacingProgressBar pacingData={adSource.adsourcePacingData} />
                ) : (
                    <Dashes />
                ),
            [sortableColumnKey.CpmRate]: (
                <SeatAdSourcesListTableCpmRateCell
                    adSource={adSource}
                    cpmRate={adSource.adSourceCpm}
                    currency={adSource.currencyType}
                />
            ),
            [ColumnKey.Skips]: <LoadableAnimatedNumberCell value={liveData?.skips} />,
            [ColumnKey.Tries]: <LoadableAnimatedNumberCell value={liveData?.tries} />,
            [ColumnKey.Fills]: <LoadableAnimatedNumberCell value={liveData?.fills} />,
            [ColumnKey.Impressions]: <LoadableAnimatedNumberCell value={liveData?.impressions} />,
            [ColumnKey.NetRevenue]: (
                <SeatAdSourcesListTableNetRevenueCell
                    netRevenue={liveData?.netRevenue}
                    currencyCode={liveData?.currencyCode}
                />
            ),
            [ColumnKey.Time]: <LoadableAnimatedNumberCell value={liveData?.time} />,
            [ColumnKey.Status]: <SeatAdSourceStatusTag synthesizedStatus={adSource.synthesizedAdSourceStatus} />,
            [ColumnKey.Actions]: (
                <SeatAdSourcesActionButtonBar
                    adSourceId={adSource.id}
                    adSourceTypeId={adSource.type?.id}
                    actionButtons={[ActionButton.Edit, ActionButton.Performance, ActionButton.CreativeReview]}
                    moreMenuItems={[
                        MoreMenuItem.Copy,
                        MoreMenuItem.AdServing,
                        MoreMenuItem.VastErrors,
                        MoreMenuItem.Ladle,
                        MoreMenuItem.TimingStats,
                        MoreMenuItem.ChangeHistory,
                    ]}
                />
            ),
        };
    });

export const SeatAdSourcesListTable: FC = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const screens: ScreensBreakpoint = useBreakpoint();
    const { md: fixedCols, lg } = screens;
    const { liveAdSources, isFetching, total, handleChange, pageSize, current, onPaginationChange } =
        useSeatAdSourcesListTable();
    const seatAdSourcesSort = useAppSelector(selectSeatAdSourcesTableViewSort);
    const { openSeatAdSourcesDetailsDrawer } = useSeatAdSourcesDetailsDrawer();
    const useDealCountDrawer = useSeatAdSourcesDealCountDrawer();
    const defaultColumns = useMemo(() => getDefaultColumns(seatAdSourcesSort), [seatAdSourcesSort]);
    const {
        isDrawerOpen,
        handleOpenManageColumnsDrawer,
        handleCloseManageColumnsDrawer,
        allColumns,
        visibleColumns,
        onChange,
    } = useManageColumnsDrawer(MANAGE_COLUMNS_KEY_AD_SOURCES, defaultColumns, fixedCols);
    const { origin, pathname } = window.location;
    const route = `${origin}${pathname}#`;

    const handleLinkClick = (e: MouseEvent<HTMLElement>, adSourceId: number) => {
        openSeatAdSourcesDetailsDrawer(adSourceId);
    };

    return (
        <>
            <Space data-sdet="seat-ad-sources-list-table" direction="vertical" size="middle" style={{ width: "100%" }}>
                <Flex gap="small" wrap="wrap">
                    <Col xs={24} lg={8}>
                        <SeatAdSourcesFilterSearch />
                    </Col>
                    <Col xs={24} lg={10}>
                        <SeatAdSourcesCurrencyFilters />
                    </Col>
                    <Col flex={1} style={{ display: "flex", justifyContent: lg ? "flex-end" : "flex-start" }}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col>
                                <SeatAdSourcesListViewRadio />
                            </Col>
                            <Col>
                                <ManageColumnsButton handleOpen={handleOpenManageColumnsDrawer} />
                            </Col>
                        </Row>
                    </Col>
                </Flex>
                <Row>
                    <Col span={24}>
                        <SeatAdSourcesDealCountDrawer useSeatAdSourcesDealCountDrawer={useDealCountDrawer} />
                        <Table<TableItem>
                            size="small"
                            columns={visibleColumns}
                            dataSource={getDisplayableTableItems(
                                liveAdSources,
                                seatId,
                                route,
                                handleLinkClick,
                                useDealCountDrawer.openDealCountDrawer
                            )}
                            loading={isFetching}
                            onChange={handleChange}
                            showSorterTooltip={false}
                            scroll={{ x: 1750 }}
                            pagination={{
                                pageSize: pageSize,
                                total,
                                pageSizeOptions: PAGE_SIZE_OPTIONS,
                                current,
                                onChange: onPaginationChange,
                                showTotal: (total) => (total ? `${formatNumber.asNumber(total)} Total` : ""),
                            }}
                        />
                    </Col>
                </Row>
            </Space>
            <ManageColumnsDrawer
                visible={isDrawerOpen}
                columns={allColumns}
                title="Manage Columns for AD Sources"
                handleClose={handleCloseManageColumnsDrawer}
                onChange={onChange}
            />
        </>
    );
};
