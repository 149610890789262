import { FC, useState } from "react";
import DOMPurify from "dompurify";
import { Button, Collapse, Drawer, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { brandCobalt } from "@rubicon/antd-components";
import { configurationCollapseClassList } from "@app/features/deals/DealForm/DefinitionSidePanels/DealObjectSidePanel/DealObjectSidePanel";
import { useHelpArticles } from "@app/core/components/hooks";
import { DealsHelpKeys } from "../../../constants";
import { DrawerCloseButton, SectionTitle } from "@app/core/components";

export const PricingDefinitionSidePanel: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { helpArticles } = useHelpArticles(DealsHelpKeys);
    const dealModelFields = helpArticles[DealsHelpKeys.DealPriceModel];
    const dealCPMFields = helpArticles[DealsHelpKeys.DealDetailsCPMRate];
    const dealImpressionFields = helpArticles[DealsHelpKeys.AdStatsPublisherImpressions];
    const dealCurrencyFields = helpArticles[DealsHelpKeys.DealCurrency];
    return (
        <>
            <Button
                onClick={() => setIsOpen(true)}
                type="link"
                style={{ color: brandCobalt, padding: 0 }}
                data-sdet="pricing"
            >
                <InfoCircleOutlined />
                Pricing
            </Button>
            <Drawer
                styles={{ body: { padding: 0 } }}
                width="40%"
                title="Pricing Definitions"
                open={isOpen}
                onClose={() => setIsOpen(false)}
                closable={false}
                extra={<DrawerCloseButton onClose={() => setIsOpen(false)} />}
            >
                <Collapse defaultActiveKey={["1", "2", "3", "4", "5"]} className={configurationCollapseClassList}>
                    <Collapse.Panel key="1" header={<SectionTitle title={dealModelFields?.title ?? "Pricing Model"} />}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(dealModelFields?.body),
                            }}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="2" header={<SectionTitle title="Floor Settings for Auction Price Deals" />}>
                        <Typography.Paragraph>
                            Pricing for Auction Price Deals are set at the Ad Source level and not at the Deal object.
                            If you want to manipulate floor rules you will need to do so in the Ad Source that’s
                            configured to the deal object.
                        </Typography.Paragraph>
                        <Typography.Text strong={true}>Override Floor (CPM)</Typography.Text>
                        <Typography.Paragraph>Reserved for guaranteed deals</Typography.Paragraph>
                        <Typography.Text strong={true}>Auction Type</Typography.Text>
                        <Typography.Paragraph>
                            In a first price auction the clearing price is the winning bid. In a second price auction
                            the clearing price is $0.01 higher than an either the second highest bid or the auction
                            floor, whichever is higher.
                        </Typography.Paragraph>
                        <Typography.Text strong={true}>Floor Type</Typography.Text>
                        <Typography.Paragraph>
                            When defining a price in an auction, you must establish a price floor, that is, the lowest
                            price that the publisher will accept in a bid. The price floor defined in an auction can
                            override or augment the price floor defined at a given inventory level, but in order for
                            that to happen, you must define the rules for that override up front in the pricing section
                            of the auction definition, using the appropriate Floor Type designation:
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Fallback - If a floor price has not been set in the inventory hierarchy the floor will be
                            set to the value in the Fallback Floor (CPM) field.
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Override - Will override the floor price that has been set in the inventory hierarchy with
                            the value set in the Override Floor (CPM) field below.
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Increase CPM - Increase the floor price set in the inventory hierarchy by the value set in
                            the Increase Floor by CPM by field below.
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Decrease CPM - Decrease the floor price set in the inventory hierarchy by the value set in
                            the Decrease Floor by CPM by field below.
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Increase Percent - Increase the floor price set in the inventory hierarchy by the percentage
                            set in the Increase Floor by % by field below.
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Decrease Percent - Decrease the floor price set in the inventory hierarchy by the percentage
                            set in the Decrease Floor by % by field below.
                        </Typography.Paragraph>
                        <Typography.Text strong={true}>Always Send Floor</Typography.Text>
                        <Typography.Paragraph>N/A</Typography.Paragraph>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key="3"
                        header={<SectionTitle title={dealCPMFields?.title ?? "Deal Object Pricing"} />}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(dealCPMFields?.body),
                            }}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel
                        key="4"
                        header={<SectionTitle title={dealImpressionFields?.title ?? "Impressions"} />}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(dealImpressionFields?.body),
                            }}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="5" header={<SectionTitle title={dealCurrencyFields?.title ?? "Currency"} />}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(dealCurrencyFields?.body),
                            }}
                        />
                    </Collapse.Panel>
                </Collapse>
            </Drawer>
        </>
    );
};
