import { FC } from "react";
import { Form, Input } from "antd";
import { FORM_ITEMS } from "../../../constants";

export const EstimatedTotalAvailableAdMinutes: FC = () => {
    return (
        <Form.Item name={FORM_ITEMS.EST_TOTAL_AVAIL_AD_MINS.name} label={FORM_ITEMS.EST_TOTAL_AVAIL_AD_MINS.label}>
            <Input placeholder={"e.g. 30"} addonAfter="mins" />
        </Form.Item>
    );
};
