import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { LiveStatsTableAdSource } from "@app/core/services";
import { useHistory, useParams } from "react-router-dom";

interface UseDealTableActions {
    canEditSeat: boolean;
    isAdSource: () => boolean;
    isLegacyDeal: boolean;
    handleCopy: () => void;
    handleEdit: () => void;
}

interface Props {
    dealId: number;
    adSources: LiveStatsTableAdSource[];
    dealType: {
        id: number;
        name: string;
    };
}

export const useDealTableActions = ({ dealId, adSources, dealType }: Props): UseDealTableActions => {
    const { canEditSeat } = useUserAccess();
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();

    const isAdSource = () => adSources?.length > 0;

    const handleCopy = () => {
        history.push(ROUTE_FORMATTERS.SEAT_DEAL_COPY(seatId, dealId));
    };

    const handleEdit = () => {
        history.push(ROUTE_FORMATTERS.SEAT_DEAL_EDIT(seatId, dealId));
    };

    const isLegacyDeal = dealType.name === "Legacy Deal";
    return {
        canEditSeat,
        isAdSource,
        isLegacyDeal,
        handleCopy,
        handleEdit,
    };
};
