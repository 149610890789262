import { NetworkBuyerSeat } from "@magnite/client-streaming-platform";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Form } from "antd";
import { FC } from "react";
import { DEAL_FORM_FIELDS } from "../../../../constants";
import { BuyerSeatsSelectLabel, DspSyncInfoBlock, useBuyerSeatsSelect } from "@app/core/components/BuyerSeatsSelect";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { SeatAvailableNetworks } from "@app/core/services";
import { DealFormMode } from "@app/features/deals/DealForm/types";

interface Props {
    isProgrammaticGuaranteedOrLegacy: boolean;
}

export const BuyerSeatsSelect: FC<Props> = ({ isProgrammaticGuaranteedOrLegacy }) => {
    const { marketplaceInfoMode } = useMarketplaceInfoWidget();
    const selectedDspNetworkId = Form.useWatch<SeatAvailableNetworks>(DEAL_FORM_FIELDS.DEMAND.name)?.id;
    const selectedBuyerSeatIds = Form.useWatch<{ buyerSeat: NetworkBuyerSeat }[]>([
        DEAL_FORM_FIELDS.BUYER_SEATS.name,
    ])?.map((buyerSeat) => {
        return buyerSeat.buyerSeat.id;
    });
    const createdWithBuyerSeats = Form.useWatch<boolean>(["deal", "createdWithBuyerSeats"]);
    const buyerSeatsSelectViewMode =
        marketplaceInfoMode.name === "closed" ? "view" : (marketplaceInfoMode.name as DealFormMode);
    const { isDisabled, isFetching, validationRules, buyerSeats, isBuyerSelected, isExternalDsp, hasValidationError } =
        useBuyerSeatsSelect(
            buyerSeatsSelectViewMode,
            selectedDspNetworkId,
            selectedBuyerSeatIds,
            createdWithBuyerSeats,
            true
        );

    const mappedbuyerSeats = buyerSeats?.map((seat) => ({ buyerSeat: seat })) || [];
    const isReadonly = marketplaceInfoMode.name === "view";

    return (
        <Form.Item
            name={DEAL_FORM_FIELDS.BUYER_SEATS.name}
            label={
                <BuyerSeatsSelectLabel
                    selectedDspNetworkId={selectedDspNetworkId}
                    formMode={buyerSeatsSelectViewMode}
                    isBuyerSelected={isBuyerSelected}
                    isProgrammaticGuaranteed={isProgrammaticGuaranteedOrLegacy}
                />
            }
            help={
                hasValidationError ? undefined : (
                    <DspSyncInfoBlock isBuyerSelected={isBuyerSelected} isExternalDsp={isExternalDsp} />
                )
            }
            rules={validationRules}
        >
            {isReadonly ? (
                <ReadonlyControl<typeof mappedbuyerSeats>
                    getLabel={(seats) =>
                        seats.map(({ buyerSeat }) => `${buyerSeat?.code} [${buyerSeat?.name}]`).join(", ")
                    }
                />
            ) : (
                <SelectWithValue
                    allowClear
                    disabled={isDisabled}
                    fieldAsLabel={({ buyerSeat }) => `${buyerSeat?.code} [${buyerSeat?.name}]`}
                    fieldAsValue={["buyerSeat", "id"]}
                    loading={isFetching}
                    mode="multiple"
                    optionFilterProp="label" // For internal LabelValue
                    options={mappedbuyerSeats}
                    placeholder="Select…"
                    showArrow
                    showSearch
                />
            )}
        </Form.Item>
    );
};
