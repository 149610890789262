import { useOverrideFloorCpmInputLabel } from "./useOverrideFloorCpmInputLabel";
import { AdSource } from "@app/core/services";
import { formatNumber } from "@rubicon/utils";

export const useCpmInput = (adSource: AdSource) => {
    const { fieldLabel, isMoney } = useOverrideFloorCpmInputLabel(adSource.adSourceFloorType.name);
    const value = isMoney
        ? formatNumber.asMoney(adSource?.bookingPrice / 1000, adSource?.currencyType.code)
        : adSource?.bookingPrice / 1000;

    return { fieldLabel, value };
};
