import { FC } from "react";
import { Form, Input } from "antd";
import { FORM_ITEMS } from "../../../constants";

export const EstimatedAdBreakCount: FC = () => {
    return (
        <Form.Item name={FORM_ITEMS.EST_AD_BREAK_COUNT.name} label={FORM_ITEMS.EST_AD_BREAK_COUNT.label}>
            <Input placeholder={"e.g. 8"} />
        </Form.Item>
    );
};
