import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useAppSelector } from "@app/core/store";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { InventoryDetailsDrawerType, selectInventoryDetailsDrawerType } from "../../reducer";
import { useInventoryDetailsDrawer } from "../../useInventoryDetailsDrawer";
import { UnitUrlIds } from "../../useInventoryDetailsDrawerUrlId";
import { selectIframed } from "@app/core/components/Main/reducer";
import { getUrlSegment } from "@app/core/utils";

interface UseDetailsDrawerUnitLayout {
    visibleCopy: boolean;
    handleVisibleCopy: () => void;
    handleHideCopy: () => void;
    gotoInventory: () => void;
    copyUrl: string;
    goBackUrl: string | undefined;
}

export const useDetailsDrawerUnitLayout = (): UseDetailsDrawerUnitLayout => {
    const history = useHistory();
    const { seatId, publisherId, brandId, adUnitId, supplyId } = useParams<UnitUrlIds>();
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    const inIfram = useAppSelector(selectIframed);
    const { closeDetailsDrawer } = useInventoryDetailsDrawer();
    const [visibleCopy, setVisibleCopy] = useState<boolean>(false);

    const { origin, pathname } = window.location;
    const pageUnit = ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
        drawerType,
        seatId,
        publisherId,
        brandId,
        supplyId,
        adUnitId
    ) as string;
    const url = new URL(`${pathname}#${pageUnit}`, origin);
    const copyUrl = inIfram ? getUrlSegment(url.hash) : url.href;
    // We only show gobackUrl for Channel Ad Units
    const goBackUrl = ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
        seatId,
        InventoryDetailsDrawerType.BRAND_CHANNEL,
        Number(publisherId),
        Number(brandId)
    );

    const gotoInventory = () => {
        closeDetailsDrawer();
        history.push(pageUnit);
    };

    const handleVisibleCopy = () => {
        setVisibleCopy(true);
    };

    const handleHideCopy = () => {
        setVisibleCopy(false);
    };

    return {
        handleVisibleCopy,
        handleHideCopy,
        gotoInventory,
        goBackUrl,
        copyUrl,
        visibleCopy,
    };
};
