import { useNotificationError } from "@app/core/components/hooks";
import { useLazySearchQuery, SearchItemUser } from "@magnite/client-streaming-platform";
import { uri } from "@rubicon/utils";
import { Form, Select } from "antd";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const AUDIT_LOG_USER_SELECT_SDET = "audit-log-user-select";
export const USER_QUERY_PARAM = "email";
export const getUserQueryParam = (queryString: string) =>
    new URLSearchParams(queryString).get(USER_QUERY_PARAM) || undefined;
const AUDIT_LOG_USER_BASE_SEARCH_PARAMS = {
    type: "user",
    page: 1,
    max: 250,
};
export const AuditLogUserSelect = () => {
    const { search: queryString } = useLocation();
    const email = getUserQueryParam(queryString);

    const history = useHistory();

    const onSelectUser = (value: string) => {
        const nextQueryString = uri.setSearchParam(queryString, "email", value);
        history.push({
            search: nextQueryString,
        });
    };
    const [triggerUserSearch, { data = [], isFetching, error }] = useLazySearchQuery();

    useNotificationError(error);

    useEffect(() => {
        triggerUserSearch(AUDIT_LOG_USER_BASE_SEARCH_PARAMS);
    }, [triggerUserSearch]);

    return (
        <Form.Item label="User">
            <Select
                allowClear
                showSearch
                data-sdet={AUDIT_LOG_USER_SELECT_SDET}
                loading={isFetching}
                onSearch={(value) =>
                    triggerUserSearch({
                        ...AUDIT_LOG_USER_BASE_SEARCH_PARAMS,
                        keyword: value,
                    })
                }
                value={email}
                options={data?.map((user) => ({
                    label: (user as SearchItemUser).emailAddress,
                    value: (user as SearchItemUser).emailAddress,
                }))}
                onSelect={onSelectUser}
                onClear={() => {
                    const nextQueryString = uri.deleteSearchParam(queryString, USER_QUERY_PARAM);
                    history.push({ search: nextQueryString });
                    triggerUserSearch(AUDIT_LOG_USER_BASE_SEARCH_PARAMS);
                }}
            />
        </Form.Item>
    );
};
