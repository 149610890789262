import { LabeledValue } from "antd/lib/select";
import { setFiltersSeatAdSourcesLabelValue } from "../../reducer";
import { useGetLabelValuesQuery } from "@magnite/client-streaming-platform";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useMemo } from "react";

interface UseSeatAdSourcesPacingLabelValueFilter {
    handleChangeValue: (value: string | number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue[] | string | null;
    isLoading: boolean;
    disabled: boolean;
}

export const useSeatAdSourcesPacingLabelValueFilter = (): UseSeatAdSourcesPacingLabelValueFilter => {
    const dispatch = useAppDispatch();
    const seatAdSourcesPacingLabelKey = useAppSelector(
        (state) => state.seatAdSourcesPacing.filters.seatAdSourcesLabelKey
    );
    const seatAdSourcesPacingLabelValue = useAppSelector(
        (state) => state.seatAdSourcesPacing.filters.seatAdSourcesLabelValue
    );
    const labelId = seatAdSourcesPacingLabelKey?.value;
    const { data, isFetching } = useGetLabelValuesQuery(Number(labelId), { skip: !labelId });
    const options = useMemo(() => (data || []).map((filter) => ({ label: filter.value, value: filter.id })), [data]);

    const handleChangeValue = (value: string | number | undefined, option: LabeledValue[]) => {
        dispatch(setFiltersSeatAdSourcesLabelValue(option));
    };
    const isDisabled = (seatAdSourcesPacingLabelKey?: LabeledValue | null): boolean =>
        seatAdSourcesPacingLabelKey === undefined || seatAdSourcesPacingLabelKey === null;

    return {
        handleChangeValue,
        options: options,
        value: seatAdSourcesPacingLabelValue,
        isLoading: isFetching,
        disabled: isDisabled(seatAdSourcesPacingLabelKey),
    };
};
