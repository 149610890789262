import { FC } from "react";
import { Drawer, Grid } from "antd";
import { css } from "@emotion/css";
import { DrawerLayout } from "@app/core/components";
import { ManageColumns, ManagedColumn } from "@app/core/components/ManageColumns/ManageColumns";

const drawerClasses = css`
    .ant-drawer-body {
        padding: 0;
    }
`;

interface ManageColumnsDrawerProps {
    visible: boolean;
    columns: ManagedColumn[];
    title?: string;
    handleClose: () => void;
    onChange: (newItems: ManagedColumn[]) => void;
}

export const ManageColumnsDrawer: FC<ManageColumnsDrawerProps> = ({
    visible,
    columns,
    title,
    handleClose,
    onChange,
}) => {
    const { xl } = Grid.useBreakpoint();
    return (
        <Drawer
            width={xl ? "25%" : "75%"}
            open={visible}
            closable={false}
            onClose={handleClose}
            className={drawerClasses}
        >
            <DrawerLayout closeHandler={handleClose} title={title || "Manage Columns"}>
                <ManageColumns columns={columns} onChange={onChange} />
            </DrawerLayout>
        </Drawer>
    );
};
