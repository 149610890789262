import { Card } from "antd";
import React, { FC } from "react";
import { LabelValue } from "@magnite/client-streaming-platform";
import { PrefilterLabel } from "@app/features/inventory/components/Fields/WaterfallPrefilteringSection/Fields";
import { InheritedPrefilterLabels } from "@app/features/inventory/components/Fields/WaterfallPrefilteringSection/InheritedPrefilterLabels/InheritedPrefilterLabels";

interface WaterfallPrefilteringSectionProps {
    inheritedPrefilterLabels?: LabelValue[];
    isInheritedPrefilterLabelsShown?: boolean;
}

export const WaterfallPrefilteringSection: FC<WaterfallPrefilteringSectionProps> = ({
    inheritedPrefilterLabels,
    isInheritedPrefilterLabelsShown,
}) => {
    return (
        <Card bordered={false}>
            <PrefilterLabel />
            {isInheritedPrefilterLabelsShown && <InheritedPrefilterLabels prefilterLabels={inheritedPrefilterLabels} />}
        </Card>
    );
};
