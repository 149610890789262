import { FC } from "react";
import { Button } from "antd";
import { Tooltip } from "antd/lib";
import { MenuOutlined } from "@ant-design/icons";

interface ManageColumnsButtonProps {
    handleOpen: () => void;
}

export const ManageColumnsButton: FC<ManageColumnsButtonProps> = ({ handleOpen }) => {
    return (
        <Tooltip title={"Manage Columns"}>
            <Button onClick={handleOpen}>
                <MenuOutlined rotate={90} />
            </Button>
        </Tooltip>
    );
};
