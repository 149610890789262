import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useGetBuyerStatusQuery } from "@app/core/services";
import { DEAL_HEALTH_QUERY_PARAM, useDealHealthListParam } from "../useDealHealthTableFilters";

interface UseDealHealthBuyerStatusFilter {
    handleChange: (value: number[], option: LabeledValue[]) => void;
    options: LabeledValue[];
    isFetching: boolean;
    value: number[];
    areAllOptionsSelected: boolean;
    toggleSelectDeselectAll: () => void;
}

export const useDealHealthBuyerStatusFilter = (): UseDealHealthBuyerStatusFilter => {
    const { data, isFetching } = useGetBuyerStatusQuery();
    const { value: buyerStatus, setValue: setFiltersDealHealthBuyerStatus } = useDealHealthListParam(
        DEAL_HEALTH_QUERY_PARAM.FILTER_BUYER_STATUS
    );

    const options = useMemo(
        () =>
            (data || []).map((status) => ({
                value: status.id,
                label: status.name,
            })),
        [data]
    );

    const handleChange = (value: number[], option: LabeledValue[]) => {
        setFiltersDealHealthBuyerStatus(option.map((item) => `${item.value}`));
    };

    const areAllOptionsSelected = options.length === buyerStatus?.length;

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            handleChange(buyerStatus, []);
        } else {
            handleChange(buyerStatus, options);
        }
    };

    return {
        options,
        handleChange,
        isFetching,
        value: buyerStatus,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
    };
};
