import { FC } from "react";
import { Form, Input } from "antd";
import { FORM_ITEMS } from "../../../constants";
import { useEstimatedPeakConcurrentViewers } from "./useEstimatedPeakConcurrentViewers";
import { formatEstPeakConcurrentViewers } from "../../../utils";

export const EstimatedPeakConcurrentViewers: FC = () => {
    const { rule } = useEstimatedPeakConcurrentViewers();

    return (
        <Form.Item
            name={FORM_ITEMS.EST_PEAK_CONCURRENT_VIEWERS.name}
            label={FORM_ITEMS.EST_PEAK_CONCURRENT_VIEWERS.label}
            rules={rule}
        >
            <Input placeholder={`e.g. ${formatEstPeakConcurrentViewers(1200000)}`} />
        </Form.Item>
    );
};
