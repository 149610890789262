import { FC } from "react";
import { Typography } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useSeatAuthContext } from "@app/core/auth";
import { InventorySeatActions } from "@app/features/inventory/InventorySeatActions";

const InventoryHealthToolbar: FC = () => {
    const { context } = useSeatAuthContext();

    return (
        <PageHeader
            ghost={false}
            title={`${context?.entityType || "Seat"} Health: ${context?.name}`}
            extra={<InventorySeatActions />}
        >
            <Typography.Text>
                Click on a publisher to see its brands or manage action using the actions on its right
            </Typography.Text>
        </PageHeader>
    );
};

export default InventoryHealthToolbar;
