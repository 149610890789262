import { SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { Form } from "antd";

const { useWatch } = Form;

export const useIsFieldVisible = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeId = adSourceType?.id;

    return adSourceTypeId !== AdSourceTypeIds.FALLBACK_TAG;
};
