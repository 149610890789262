import { FC } from "react";
import { useSeatAuthContext } from "@app/core/auth";
import { NavBar, Loading } from "@app/core/components";
import { DealsNavMenu } from "./DealsNavMenu";
import { DealsSwitch } from "./DealsSwitch";
import { useSetInitialSettings } from "./useSetInitialSettings";
import { AdSourceDetailsDrawerDealsWrapper } from "./DealDetailsPage/DealDetailsPageAdSourceConfiguration/AdSourceSections/AdSourcesDetailsDrawerDealsWrapper";
import { DealDetailsDrawerWrapper } from "./DealDetailsDrawer/DealDetailsDrawerWrapper";
import { useAppSelector } from "@app/core/store";
import { selectIframed } from "@app/core/components/Main/reducer";

export const Deals: FC = () => {
    const { context, session } = useSeatAuthContext();
    const inIframe = useAppSelector(selectIframed);

    useSetInitialSettings();

    if (!context || !session) {
        return <Loading />;
    }

    return (
        <>
            {!inIframe && <NavBar label="Deals Management" />}
            <DealsNavMenu />
            <DealsSwitch />
            <DealDetailsDrawerWrapper />
            <AdSourceDetailsDrawerDealsWrapper />
        </>
    );
};
