import { Col, Typography } from "antd";
import { AdSource } from "@app/core/services";
import { useIsFieldVisible } from "./useIsFieldVisible";
import { formatNumber } from "@rubicon/utils";

export const FixedCpmInput = ({ adSource }: { adSource: AdSource }) => {
    const isVisible = useIsFieldVisible(adSource);

    if (!isVisible) {
        return null;
    }

    return (
        <Col xs={12} xl={8}>
            <Typography.Text strong>Fixed CPM</Typography.Text>
            <Typography.Paragraph>
                {formatNumber.asMoney(adSource?.bookingPrice / 1000, adSource?.currencyType.code)}
            </Typography.Paragraph>
        </Col>
    );
};
