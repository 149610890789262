import { FC } from "react";
import { useSeatAuthContext } from "@app/core/auth";
import { Loading, NavBar } from "@app/core/components";
import { SeatAdSourcesSwitch } from "./SeatAdSourcesSwitch";
import { SeatAdSourcesNavMenu } from "@app/features/seatAdSources/SeatAdSourcesNavMenu/SeatAdSourcesNavMenu";
import { useSetInitialSettings } from "./useSetInitialSettings";
import { useAppSelector } from "@app/core/store";
import { selectIframed } from "@app/core/components/Main/reducer";

export const SeatAdSources: FC = () => {
    const { context, session } = useSeatAuthContext();
    const inIframe = useAppSelector(selectIframed);

    useSetInitialSettings();

    if (!context || !session) {
        return <Loading />;
    }

    return (
        <>
            {!inIframe && <NavBar label="Ad Sources" />}
            <SeatAdSourcesNavMenu />
            <SeatAdSourcesSwitch />
        </>
    );
};
