import { FC } from "react";
import { Form } from "antd";
import { FormItemProps } from "antd/lib/form";
import { css } from "@emotion/css";

const className = css`
    margin-bottom: 1rem;

    > .ant-form-item-row {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .ant-form-item-label {
        padding: 0;

        > label {
            height: 32px;
        }
    }
`;
export const TransparencyFormItem: FC<FormItemProps> = ({ children, ...props }) => (
    <Form.Item className={className} labelCol={{ span: 5 }} {...props}>
        {children}
    </Form.Item>
);
