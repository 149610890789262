import { Form } from "antd";
import { MarketplaceInfo, SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, DealEntityTypes, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { findDealsWithSpecificTypes } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/findDealsWithSpecificTypes";

const { useWatch } = Form;

export const useIsFieldReadOnly = () => {
    // ToDo add actual adSourceType type to useWatch generic
    const deals = useWatch<MarketplaceInfo[]>(AD_SOURCE_FIELDS.DEMAND.name);
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);

    const adSourceTypeId = adSourceType?.id;
    const demandDeals = findDealsWithSpecificTypes({
        deals,
        entityTypes: [DealEntityTypes.Demand, DealEntityTypes.Buyer],
    });
    if (
        (adSourceTypeId === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED ||
            adSourceTypeId === AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED) &&
        demandDeals.length > 0
    ) {
        return true;
    }

    return false;
};
