import { FC, useMemo } from "react";
import {
    AdSource,
    useGetSupplySeatQuery,
    useGetAdSourceCoreIndexTimeQuery,
    useGetAdSourceTargetingIndexTimeQuery,
} from "@app/core/services";
import { Col, Collapse, CollapseProps, Row, Typography, Tag, Space } from "antd";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED, NONE } from "@app/core/components/constants";
import { formatDateInUtc } from "@app/core/utils";
import {
    AdSourceTypeIds,
    CostModels,
    AD_SOURCE_TYPES_NEXT_UI_NAMES,
    AD_SOURCE_FIELDS,
} from "@app/features/seatAdSources/constants";
import { useUserAccess } from "@app/core/auth";
import { isTagURLVisible } from "../utils/isTagURLVisible";
import { isAssignedAdvertiserVisible } from "../utils/isAssignedAdvertiserVisible";
import { useParams } from "react-router-dom";
import { isExtendTimeoutValueVisible } from "../utils/isExtendTimeoutValueVisible";
import { parseCostFixedFromApi } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/parseCostFixedFromApi";
import { SeatAdSourceStatusTag } from "@app/features/seatAdSources/SeatAdSourceStatusTag";
import { format } from "@rubicon/utils";
import { brandCobalt } from "@rubicon/antd-components";
import { AdSourceTermsResponsiveLayout } from "@app/core/components/responsive/AdSourceTerms/AdSourceTerms";

interface Props {
    adSource: AdSource;
}

const { Paragraph, Text } = Typography;

const costValueText = (adSource: AdSource) => {
    let costValue = 0;
    if (adSource.costModel?.id === CostModels.FIXED_CPM) {
        costValue = parseCostFixedFromApi(adSource.costValueFixed);
    } else if (adSource.costModel?.id === CostModels.REVSHARE_PERCENTAGE) {
        costValue = adSource.costValuePercent;
    }

    return costValue;
};

const salesContactText = (adSource: AdSource) => {
    const salesContact = adSource.salesContact;
    if (salesContact && salesContact.name) {
        return salesContact.name;
    }
    if (salesContact && salesContact.emailAddress) {
        return salesContact.emailAddress;
    }
    return format.constants.DEFAULT_DASHES;
};

export const SeatAdSourcesTermsDetails: FC<Props> = ({ adSource }) => {
    const { hasInternalAccess, isTremorUser } = useUserAccess();
    const { seatId } = useParams<{ seatId: string }>();
    const { data: supplySeat } = useGetSupplySeatQuery(Number(seatId));
    const { data: adSourcesCore } = useGetAdSourceCoreIndexTimeQuery(Number(adSource.id));
    const { data: adSourcesTargeting } = useGetAdSourceTargetingIndexTimeQuery(Number(adSource.id));

    const items: CollapseProps["items"] = useMemo(
        () => [
            {
                key: "1",
                label: (
                    <Typography.Text
                        style={{
                            fontSize: "0.9rem",
                            color: brandCobalt,
                        }}
                    >
                        Admin Options
                    </Typography.Text>
                ),
                children: (
                    <>
                        <Row>
                            <Col xs={12} xl={8}>
                                <Text strong>Cost Model</Text>
                                <Paragraph>{format.asSelf(adSource.costModel?.name)}</Paragraph>
                            </Col>
                            <Col xs={12} xl={8}>
                                <Text strong>Cost</Text>
                                <Paragraph>{format.asSelf(costValueText(adSource))}</Paragraph>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} xl={8}>
                                <Text strong>Allow Autoscale</Text>
                                <Paragraph>{format.asYesOrNo(adSource.allowAutoscale)}</Paragraph>
                            </Col>
                            <Col>
                                <Text strong>Region</Text>
                                <Paragraph>{format.asSelf(adSource.region?.name)}</Paragraph>
                            </Col>
                        </Row>
                        {hasInternalAccess && adSource.type.id === AdSourceTypeIds.MARKETPLACE && (
                            <Row>
                                <Col xs={12} xl={8}>
                                    <Text strong>{AD_SOURCE_FIELDS.ASSOCIATED_GLOBAL_IDG.label}</Text>
                                    <Paragraph>
                                        {adSource.associatedIdgs?.length
                                            ? adSource.associatedIdgs
                                                  .map((idg) => format.asSelf(idg.label.key))
                                                  .join(",")
                                            : format.constants.DEFAULT_DASHES}
                                    </Paragraph>
                                </Col>
                            </Row>
                        )}
                    </>
                ),
            },
        ],
        [adSource, hasInternalAccess]
    );

    return (
        <>
            <AdSourceTermsResponsiveLayout
                name={
                    <>
                        <Text strong>Name</Text>
                        <Paragraph>{format.asSelf(adSource.name)}</Paragraph>
                    </>
                }
                status={
                    <>
                        <Text strong>Status</Text>
                        <Paragraph>
                            <SeatAdSourceStatusTag synthesizedStatus={adSource.synthesizedAdSourceStatus} />
                        </Paragraph>
                    </>
                }
                description={
                    Boolean(adSource.description?.length) && (
                        <>
                            <Text strong>Description</Text>
                            <Paragraph>{format.asSelf(adSource.description)}</Paragraph>
                        </>
                    )
                }
                leftCol={
                    <>
                        <Text strong>Type</Text>
                        <Paragraph>{format.asSelf(AD_SOURCE_TYPES_NEXT_UI_NAMES[adSource.type?.name])}</Paragraph>
                        <Text strong>Priority</Text>
                        {isExtendTimeoutValueVisible(adSource.type?.id) ? (
                            <>
                                <Paragraph>{format.asSelf(adSource.priority?.name)}</Paragraph>
                                <Text strong>Extend Timeout Value</Text>
                            </>
                        ) : null}
                        <Paragraph>{format.asYesOrNo(adSource.nurlTimeoutOverride)}</Paragraph>
                        <Text strong>Date Created (UTC)</Text>
                        <Paragraph>
                            {format.asSelf(
                                formatDateInUtc(adSource.creationDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED)
                            )}
                        </Paragraph>
                        <Text strong>External Contract Number</Text>
                        <Paragraph>{format.asSelf(adSource.externalContractId)}</Paragraph>
                        <Text strong>Sales Contact</Text>
                        <Paragraph>{salesContactText(adSource)}</Paragraph>
                        {isTagURLVisible(adSource.type?.id) ? (
                            <>
                                <Text strong>Tag URL</Text>
                                <Paragraph>{format.asSelf(adSource.tagUrl)}</Paragraph>
                            </>
                        ) : null}
                    </>
                }
                rightCol={
                    <>
                        <Text strong>Assignee</Text>
                        <Paragraph>{adSource.assignee?.name || "Unassigned"}</Paragraph>
                        {adSource.type?.id != AdSourceTypeIds.FALLBACK_TAG && (
                            <>
                                <Text strong>UD Priority</Text>
                                <Paragraph>{adSource.udePriority?.name || "Unspecified"}</Paragraph>
                            </>
                        )}
                        {adSource.type?.id === AdSourceTypeIds.MARKETPLACE && (
                            <>
                                <Text strong>Associated Marketplace</Text>
                                <Paragraph>{adSource.associatedMarketplace?.name || NONE}</Paragraph>
                            </>
                        )}
                        {adSource.type?.id === AdSourceTypeIds.CURATOR_MARKETPLACE && (
                            <>
                                <Text strong>Associated Marketplaces</Text>
                                <Paragraph>
                                    <Space>
                                        {adSource.curatorMarketplaces?.length
                                            ? adSource.curatorMarketplaces.map((marketplace) => (
                                                  <Tag key={marketplace.id}>{marketplace.name}</Tag>
                                              ))
                                            : NONE}
                                    </Space>
                                </Paragraph>
                            </>
                        )}
                        <Text strong>Last Updated (UTC)</Text>
                        <Paragraph>
                            {format.asSelf(
                                formatDateInUtc(adSource.updateDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED)
                            )}
                        </Paragraph>
                        {hasInternalAccess && (
                            <div>
                                <Text strong>Last Indexed - Supply (UTC)</Text>
                                <Paragraph>
                                    {format.asSelf(
                                        formatDateInUtc(supplySeat?.lastIndexed, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED)
                                    )}
                                </Paragraph>
                            </div>
                        )}
                        <Text strong>Last Indexed - Core (UTC)</Text>
                        <Paragraph>
                            {format.asSelf(
                                formatDateInUtc(adSourcesCore?.lastIndexed, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED)
                            )}
                        </Paragraph>
                        {hasInternalAccess && (
                            <div>
                                <Text strong>Last Indexed - Targeting (UTC)</Text>
                                <Paragraph>
                                    {format.asSelf(
                                        formatDateInUtc(
                                            adSourcesTargeting?.lastIndexed,
                                            MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                                        )
                                    )}
                                </Paragraph>
                            </div>
                        )}
                        {isAssignedAdvertiserVisible(adSource.type?.id) ? (
                            <>
                                <Text strong>Assignable advertiser domain</Text>
                                <Paragraph>{format.asSelf(adSource.defaultAdvertiserDomain)}</Paragraph>
                            </>
                        ) : null}
                    </>
                }
            />
            {isTremorUser && (
                <Row>
                    <Collapse ghost items={items} style={{ width: "100%" }} />
                </Row>
            )}
        </>
    );
};
