import { FC, useEffect, useMemo } from "react";
import { AdSource } from "@app/core/services";
import {
    AdSourceTypeIds,
    AD_SOURCE_FIELDS_BY_SECTIONS,
    AD_SOURCE_SECTIONS,
    AD_SOURCE_SECTIONS_KEYS,
    AD_SOURCE_SECTIONS_NAME,
    TransparencyEligibleAdSourceTypeIds,
} from "@app/features/seatAdSources/constants";
import { SeatAdSourcesDeliveryDetails } from "./SeatAdSourcesDetailsCollapses/SeatAdSourcesDeliveryDetails/DeliveryDetails";
import { SeatAdSourcesBrandSafetyDetails } from "./SeatAdSourcesDetailsCollapses/SeatAdSourcesBrandSafetyDetails";
import { SeatAdSourcesCustomPixelsDetails } from "./SeatAdSourcesDetailsCollapses/SeatAdSourcesCustomPixelsDetails";
import { SeatAdSourcesTransparencyDetails } from "./SeatAdSourcesDetailsCollapses/SeatAdSourcesTransparencyDetails";
import { SeatAdSourcesLabelsDetails } from "./SeatAdSourcesDetailsCollapses/SeatAdSourcesLabelsDetails";
import { SeatAdSourcesTargetingDetails } from "./SeatAdSourcesDetailsCollapses/SeatAdSourcesTargetingDetails";
import { SeatAdSourcesTermsDetails } from "./SeatAdSourcesDetailsCollapses/SeatAdSourcesTermsDetails";
import { SeatAdSourcesDemandDetails } from "./SeatAdSourcesDetailsCollapses/SeatAdSourcesDemandDetails";
import { SeatAdSourcesPricingDetails } from "./SeatAdSourcesDetailsCollapses/SeatAdSourcesPricingDetails/PricingDetails";
import { SeatAdSourcesPacingDetails } from "./SeatAdSourcesDetailsCollapses/SeatAdSourcesPacingDetails";
import { SeatAdSourcesPbsBidderConfigurationsDetails } from "./SeatAdSourcesDetailsCollapses/SeatAdSourcesPbsBidderConfigurationsDetails";
import { SeatAdSourcesEnrichmentCostDetails } from "./SeatAdSourcesDetailsCollapses/SeatAdSourcesEnrichmentCostDetails";
import { Collapse } from "antd";
import { PacingStatus } from "@app/core/components/PacingStatus";
import { CollapseProps } from "antd/lib";
import { isDemandSectionVisible } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections";
import { isBrandSafetyVisible } from "./utils/isBrandSafetyVisible";
import { css } from "@emotion/css";
import { PanelHeader } from "@app/features/deals/DealDetailsPage/BuyerDealDetailsForm";
import { DefinitionSidePanel } from "./DefinitionSidePanel";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { collapseChange, selectSecondaryCollapseKeys } from "../SeatAdSourcesDetailsDrawer/reducer";
import { SeatAdSourcesPerformance } from "./SeatAdSourcesDetailsCollapses/SeatAdSourcesPerformance/SeatAdSourcesPerformance";
import { isPacingEligible } from "../SeatAdSourcesForm/utils/pacingUtils";
import { isEnrichmentCostEligible } from "../SeatAdSourcesForm/AdSourcesSections/EnrichmentCostSection/utils";
import { SeatAdSourcesLiveEventsDetails } from "./SeatAdSourcesDetailsCollapses/SeatAdSourcesLiveEventsDetails";
import { isDevOrQaEnv } from "@app/core/conf";

const getPricingTitle = (adSourceType: string) => {
    switch (adSourceType) {
        case "Linear Invite Only Auction":
        case "Open Auction":
        case "Invite Only Auction":
        case "Marketplace":
        case "Prebid Ad Source":
            return AD_SOURCE_SECTIONS_NAME.FLOOR_SETTINGS;
        case "Linear Unreserved Fixed Rate Deal":
        case "Unreserved Fixed Rate Deal":
        case "Automated Guaranteed Deal":
        case "Conditional Automated Guaranteed Deal":
            return AD_SOURCE_SECTIONS_NAME.LEGACY_PRICING;
        case "Server-side Tag Guaranteed":
        case "Client-side Tag Guaranteed":
        case "Server-side Tag Non-Guaranteed":
        case "Fallback Tag":
        case "Server Side Dynamic Price":
            return AD_SOURCE_SECTIONS_NAME.PRICING;
        default:
            return AD_SOURCE_SECTIONS_NAME.FLOOR_SETTINGS;
    }
};

interface Props {
    adSource: AdSource;
}

export const SeatAdSourcesDetails: FC<Props> = ({ adSource }) => {
    const dispatch = useAppDispatch();
    const keys = useAppSelector(selectSecondaryCollapseKeys);
    const hasPacingStatus = adSource.type.id === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED;

    const adSourceTypeName = adSource.type.name;
    const adSourceTypeId = adSource.type.id;

    const hasPacingDetails = isPacingEligible(adSourceTypeId);
    const hasDemandDetails = isDemandSectionVisible(adSourceTypeId);
    const hasPricingDetails =
        adSourceTypeId !== AdSourceTypeIds.PRE_BID_DEALS && adSourceTypeId !== AdSourceTypeIds.FALLBACK_TAG;
    const hasPbsBidderConfigurationsDetails = adSourceTypeId === AdSourceTypeIds.PRE_BID_AD_SOURCE;
    const hasBrandSafetyDetails = isBrandSafetyVisible(adSourceTypeId);
    const hasCustomPixelsLabelsTargetingDetails = adSourceTypeId !== AdSourceTypeIds.PRE_BID_DEALS;
    const hasTransparencyDetails = TransparencyEligibleAdSourceTypeIds.has(adSourceTypeId);
    const hasEnrichmentCostDetails = isEnrichmentCostEligible(adSource.type.id);
    const hasLiveEventsDetails = adSource.type.id === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED;

    const items = useMemo<CollapseProps["items"]>(() => {
        return [
            {
                key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.PERFORMANCE],
                label: <PanelHeader title={AD_SOURCE_SECTIONS_NAME.PERFORMANCE} />,
                extra: (
                    <DefinitionSidePanel
                        panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.PERFORMANCE]}
                        sectionFields={AD_SOURCE_FIELDS_BY_SECTIONS[AD_SOURCE_SECTIONS.PERFORMANCE]}
                    />
                ),
                forceRender: true,
                children: <SeatAdSourcesPerformance adSource={adSource} />,
                "data-sdet": [AD_SOURCE_SECTIONS.PERFORMANCE.toLowerCase().replace(/_/g, "")],
            },
            ,
            /*Pacing Status only shows for Programmatic Guaranteed Deals (i.e. Automated Guaranteed Deal*/
            hasPacingStatus && {
                key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.PACING_STATUS],
                label: <PanelHeader title={AD_SOURCE_SECTIONS_NAME.PACING_STATUS} />,
                extra: (
                    <DefinitionSidePanel
                        panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.PACING_STATUS]}
                        sectionFields={AD_SOURCE_FIELDS_BY_SECTIONS[AD_SOURCE_SECTIONS.PACING_STATUS]}
                    />
                ),
                forceRender: true,
                children: <PacingStatus adSource={adSource} />,
                "data-sdet": [AD_SOURCE_SECTIONS.PACING_STATUS.toLowerCase().replace(/_/g, "")],
            },
            {
                key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.AD_SOURCE_TERMS],
                label: <PanelHeader title={AD_SOURCE_SECTIONS_NAME.AD_SOURCE_TERMS} />,
                extra: (
                    <DefinitionSidePanel
                        panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.AD_SOURCE_TERMS]}
                        sectionFields={AD_SOURCE_FIELDS_BY_SECTIONS[AD_SOURCE_SECTIONS.AD_SOURCE_TERMS]}
                    />
                ),
                forceRender: true,
                children: <SeatAdSourcesTermsDetails adSource={adSource} />,
                "data-sdet": [AD_SOURCE_SECTIONS.AD_SOURCE_TERMS.toLowerCase().replace(/_/g, "")],
            },
            {
                key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.DELIVERY],
                label: <PanelHeader title={AD_SOURCE_SECTIONS_NAME.DELIVERY} />,
                extra: (
                    <DefinitionSidePanel
                        panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.DELIVERY]}
                        sectionFields={AD_SOURCE_FIELDS_BY_SECTIONS[AD_SOURCE_SECTIONS.DELIVERY]}
                    />
                ),
                forceRender: true,
                children: <SeatAdSourcesDeliveryDetails adSource={adSource} />,
                "data-sdet": [AD_SOURCE_SECTIONS.DELIVERY.toLowerCase().replace(/_/g, "")],
            },
            hasPacingDetails && {
                key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.PACING],
                label: <PanelHeader title={AD_SOURCE_SECTIONS_NAME.PACING} />,
                extra: (
                    <DefinitionSidePanel
                        panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.PACING]}
                        sectionFields={AD_SOURCE_FIELDS_BY_SECTIONS[AD_SOURCE_SECTIONS.PACING]}
                    />
                ),
                forceRender: true,
                children: <SeatAdSourcesPacingDetails adSource={adSource} />,
                "data-sdet": [AD_SOURCE_SECTIONS.PACING.toLowerCase().replace(/_/g, "")],
            },
            hasDemandDetails && {
                key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.DEMAND],
                label: <PanelHeader title={AD_SOURCE_SECTIONS_NAME.DEMAND} />,
                extra: (
                    <DefinitionSidePanel
                        panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.DEMAND]}
                        sectionFields={AD_SOURCE_FIELDS_BY_SECTIONS[AD_SOURCE_SECTIONS.DEMAND]}
                    />
                ),
                forceRender: true,
                children: <SeatAdSourcesDemandDetails adSource={adSource} />,
                "data-sdet": [AD_SOURCE_SECTIONS.DEMAND.toLowerCase().replace(/_/g, "")],
            },
            /*Live Events only shows for Programmatic Guaranteed Deals (i.e. Automated Guaranteed Deal*/
            hasLiveEventsDetails &&
                isDevOrQaEnv && {
                    key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.LIVE_EVENTS],
                    label: <PanelHeader title={AD_SOURCE_SECTIONS_NAME.LIVE_EVENTS} />,
                    extra: (
                        <DefinitionSidePanel
                            panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.LIVE_EVENTS]}
                            sectionFields={AD_SOURCE_FIELDS_BY_SECTIONS[AD_SOURCE_SECTIONS.LIVE_EVENTS]}
                        />
                    ),
                    forceRender: true,
                    children: <SeatAdSourcesLiveEventsDetails adSource={adSource} />,
                    "data-sdet": [AD_SOURCE_SECTIONS.LIVE_EVENTS.toLowerCase().replace(/_/g, "")],
                },
            hasPricingDetails && {
                key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.PRICING],
                label: <PanelHeader title={getPricingTitle(adSourceTypeName)} />,
                extra: (
                    <DefinitionSidePanel
                        panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.PRICING]}
                        sectionFields={AD_SOURCE_FIELDS_BY_SECTIONS[AD_SOURCE_SECTIONS.PRICING]}
                    />
                ),
                forceRender: true,
                children: <SeatAdSourcesPricingDetails adSource={adSource} />,
                "data-sdet": [AD_SOURCE_SECTIONS.PRICING.toLowerCase().replace(/_/g, "")],
            },
            hasPbsBidderConfigurationsDetails && {
                key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.PBS_BIDDER_CONFIGURATIONS],
                label: <PanelHeader title={AD_SOURCE_SECTIONS_NAME.PBS_BIDDER_CONFIGURATIONS} />,
                extra: (
                    <DefinitionSidePanel
                        panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.PBS_BIDDER_CONFIGURATIONS]}
                        sectionFields={AD_SOURCE_FIELDS_BY_SECTIONS[AD_SOURCE_SECTIONS.PBS_BIDDER_CONFIGURATIONS]}
                    />
                ),
                forceRender: true,
                children: <SeatAdSourcesPbsBidderConfigurationsDetails adSource={adSource} />,
                "data-sdet": [AD_SOURCE_SECTIONS.PBS_BIDDER_CONFIGURATIONS.toLowerCase().replace(/_/g, "")],
            },
            hasBrandSafetyDetails && {
                key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.BRAND_SAFETY],
                label: <PanelHeader title={AD_SOURCE_SECTIONS_NAME.BRAND_SAFETY} />,
                extra: (
                    <DefinitionSidePanel
                        panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.BRAND_SAFETY]}
                        sectionFields={AD_SOURCE_FIELDS_BY_SECTIONS[AD_SOURCE_SECTIONS.BRAND_SAFETY]}
                    />
                ),
                forceRender: true,
                children: <SeatAdSourcesBrandSafetyDetails adSource={adSource} />,
                "data-sdet": [AD_SOURCE_SECTIONS.BRAND_SAFETY.toLowerCase().replace(/_/g, "")],
            },
            hasCustomPixelsLabelsTargetingDetails && {
                key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.CUSTOM_PIXELS],
                label: <PanelHeader title={AD_SOURCE_SECTIONS_NAME.CUSTOM_PIXELS} />,
                extra: (
                    <DefinitionSidePanel
                        panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.CUSTOM_PIXELS]}
                        sectionFields={AD_SOURCE_FIELDS_BY_SECTIONS[AD_SOURCE_SECTIONS.CUSTOM_PIXELS]}
                    />
                ),
                forceRender: true,
                children: <SeatAdSourcesCustomPixelsDetails adSource={adSource} />,
                "data-sdet": [AD_SOURCE_SECTIONS.CUSTOM_PIXELS.toLowerCase().replace(/_/g, "")],
            },
            hasTransparencyDetails && {
                key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.TRANSPARENCY],
                label: <PanelHeader title={AD_SOURCE_SECTIONS_NAME.TRANSPARENCY} />,
                extra: (
                    <DefinitionSidePanel
                        panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.TRANSPARENCY]}
                        sectionFields={AD_SOURCE_FIELDS_BY_SECTIONS[AD_SOURCE_SECTIONS.TRANSPARENCY]}
                    />
                ),
                forceRender: true,
                children: <SeatAdSourcesTransparencyDetails adSource={adSource} />,
                "data-sdet": [AD_SOURCE_SECTIONS.TRANSPARENCY.toLowerCase().replace(/_/g, "")],
            },
            hasCustomPixelsLabelsTargetingDetails && {
                key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.LABELS],
                label: <PanelHeader title={AD_SOURCE_SECTIONS_NAME.LABELS} />,
                extra: (
                    <DefinitionSidePanel
                        panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.LABELS]}
                        sectionFields={AD_SOURCE_FIELDS_BY_SECTIONS[AD_SOURCE_SECTIONS.LABELS]}
                    />
                ),
                forceRender: true,
                children: <SeatAdSourcesLabelsDetails adSource={adSource} />,
                "data-sdet": [AD_SOURCE_SECTIONS.LABELS.toLowerCase().replace(/_/g, "")],
            },
            hasCustomPixelsLabelsTargetingDetails && {
                key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.TARGETING],
                label: <PanelHeader title={AD_SOURCE_SECTIONS_NAME.TARGETING} />,
                extra: (
                    <DefinitionSidePanel
                        panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.TARGETING]}
                        sectionFields={AD_SOURCE_FIELDS_BY_SECTIONS[AD_SOURCE_SECTIONS.TARGETING]}
                    />
                ),
                forceRender: true,
                children: <SeatAdSourcesTargetingDetails adSource={adSource} />,
                "data-sdet": [AD_SOURCE_SECTIONS.TARGETING.toLowerCase().replace(/_/g, "")],
            },
            hasEnrichmentCostDetails && {
                key: AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.ENRICHMENT_COST],
                label: <PanelHeader title={AD_SOURCE_SECTIONS_NAME.ENRICHMENT_COST} />,
                forceRender: true,
                children: <SeatAdSourcesEnrichmentCostDetails adSource={adSource} />,
                "data-sdet": [AD_SOURCE_SECTIONS.ENRICHMENT_COST.toLowerCase().replace(/_/g, "")],
            },
        ].filter(Boolean) as CollapseProps["items"];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adSource]);

    useEffect(() => {
        const visibleKeys: Set<string> = new Set([
            AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.PERFORMANCE],
            AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.AD_SOURCE_TERMS],
            AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.DELIVERY],
            AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.ENRICHMENT_COST],
        ]);

        if (hasPacingStatus) {
            visibleKeys.add(AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.PACING_STATUS]);
        }

        if (hasPacingDetails) {
            visibleKeys.add(AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.PACING]);
        }

        if (hasDemandDetails) {
            visibleKeys.add(AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.DEMAND]);
        }

        if (hasPricingDetails) {
            visibleKeys.add(AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.PRICING]);
        }

        if (hasPbsBidderConfigurationsDetails) {
            visibleKeys.add(AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.PBS_BIDDER_CONFIGURATIONS]);
        }

        if (hasBrandSafetyDetails) {
            visibleKeys.add(AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.BRAND_SAFETY]);
        }

        if (hasCustomPixelsLabelsTargetingDetails) {
            visibleKeys.add(AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.CUSTOM_PIXELS]);
            visibleKeys.add(AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.LABELS]);
            visibleKeys.add(AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.TARGETING]);
        }

        if (hasTransparencyDetails) {
            visibleKeys.add(AD_SOURCE_SECTIONS_KEYS[AD_SOURCE_SECTIONS.TRANSPARENCY]);
        }

        const uniqKeys = Array.from(visibleKeys);
        dispatch(collapseChange({ keys: uniqKeys, availableKeys: uniqKeys }));

        return () => {
            dispatch(collapseChange({ keys: [], availableKeys: [] }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adSource]);
    return (
        <Collapse
            className={css`
                &.ant-collapse .ant-collapse-header {
                    align-items: center;
                }
            `}
            activeKey={keys}
            onChange={(keys: string[]) => {
                dispatch(collapseChange({ keys }));
            }}
            items={items}
            collapsible="header"
            data-sdet="seat-ad-sources-details"
        />
    );
};
