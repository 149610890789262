import { useEffect, useMemo, useRef, useState } from "react";
import { LabeledValue } from "antd/lib/select";
import { useGetGlobalNetworksQuery } from "@magnite/client-streaming-platform";
import { DEAL_HEALTH_QUERY_PARAM, useDealHealthListParam } from "../useDealHealthTableFilters";

interface UseDealHealthDspFilter {
    handleChange: (value: number[], option: LabeledValue[]) => void;
    isFetching: boolean;
    options: LabeledValue[];
    value: number[];
    areAllOptionsSelected: boolean;
    toggleSelectDeselectAll: () => void;
}

const MAX = 200;

export const useDspFilter = (): UseDealHealthDspFilter => {
    const ref = useRef(null);
    const [page, setPage] = useState<number>(1);
    const { value: dsp, setValue: setFiltersDealHealthDsp } = useDealHealthListParam(
        DEAL_HEALTH_QUERY_PARAM.FILTER_DSP
    );

    const { data, isFetching } = useGetGlobalNetworksQuery({ page, max: MAX });

    const options = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return (ref.current || []).concat((data || []).map((item) => ({ label: item.name, value: item.id })));
    }, [data]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref.current = options;
    }, [options]);

    useEffect(() => {
        if ((data || []).length === MAX) {
            setPage((page) => page + 1);
        }
    }, [data]);

    const handleChange = (value: number[], option: LabeledValue[]): void => {
        setFiltersDealHealthDsp(option.map((item) => `${item.value}`));
    };

    const areAllOptionsSelected = options.length === dsp?.length;

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            handleChange(dsp, []);
        } else {
            handleChange(dsp, options);
        }
    };

    return {
        handleChange,
        isFetching,
        options,
        value: dsp,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
    };
};
