import { FC } from "react";
import { CloudDownloadOutlined, StockOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import { LiveStatsTableAdSource } from "@app/core/services";
import { HelpTooltip } from "@app/core/components";
import { DealDetailsDrawerTitles, DealsHelpKeys } from "@app/features/deals/constants";
import { useDealDetailsDrawer } from "@app/features/deals/DealDetailsDrawer/useDealDetailsDrawer";
import { useDealTableActions } from "./useDealTableActions";

const chartNotAvailableTip = "No data because it's not configured to an Ad Source";
const chartTip = "Live & Last 24 hrs charts";

interface Props {
    dealId: number;
    adSources: LiveStatsTableAdSource[];
    dealType: {
        id: number;
        name: string;
    };
    isDealShared: boolean;
}

export const DealListTableActions: FC<Props> = ({ dealId, adSources, dealType, isDealShared }) => {
    const { canEditSeat, isAdSource, isLegacyDeal, handleCopy, handleEdit } = useDealTableActions({
        dealId,
        adSources,
        dealType,
    });
    const { openDealDetailsDrawer } = useDealDetailsDrawer();
    return (
        <Button.Group>
            {canEditSeat && (
                <>
                    <Tooltip title={isLegacyDeal ? "Legacy Deals may not be copied" : "Copy"}>
                        <Button disabled={isLegacyDeal} onClick={handleCopy} icon={<CopyOutlined />} />
                    </Tooltip>
                    {!isDealShared && (
                        <Tooltip title="Edit">
                            <Button onClick={handleEdit} icon={<EditOutlined />} />
                        </Tooltip>
                    )}
                </>
            )}
            <Tooltip title={!isAdSource() ? chartNotAvailableTip : chartTip} placement="topRight">
                <Button
                    onClick={() => openDealDetailsDrawer(dealId)}
                    icon={<StockOutlined />}
                    disabled={!isAdSource()}
                />
            </Tooltip>
            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealLadle} popover={true}>
                <Button
                    onClick={() => openDealDetailsDrawer(dealId, DealDetailsDrawerTitles.Ladle)}
                    icon={<CloudDownloadOutlined />}
                ></Button>
            </HelpTooltip>
        </Button.Group>
    );
};
