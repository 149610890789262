import { Publisher } from "@magnite/client-streaming-platform";
import { getYesNoLabel } from "@app/core/utils/labels";
import { GUTTER } from "@app/features/inventory/constants";
import { Col, Form, Row, Table } from "antd";
import { FC } from "react";
import { COL_SIZES, FORM_ITEM_OFFSET } from "../../../constants";

const { Item } = Form;

const COLUMN_KEY = {
    TYPE: "type",
    ALLOW: "allow",
};

const getValue = (value: boolean | undefined) => (value ? "Yes" : "No");

const getTableItems = (publisher: Publisher) => [
    {
        key: 0,
        [COLUMN_KEY.TYPE]: "DSP VAST Extension",
        [COLUMN_KEY.ALLOW]: getValue(publisher.allowVastExtensionDSP),
    },
    {
        key: 1,
        [COLUMN_KEY.TYPE]: "CRID VAST Extension",
        [COLUMN_KEY.ALLOW]: getValue(publisher.allowVastExtensionCRID),
    },
    {
        key: 2,
        [COLUMN_KEY.TYPE]: "Adomain VAST Extension",
        [COLUMN_KEY.ALLOW]: getValue(publisher.allowVastExtensionAdomain),
    },
    {
        key: 3,
        [COLUMN_KEY.TYPE]: "Brand VAST Extension",
        [COLUMN_KEY.ALLOW]: getValue(publisher.allowVastExtensionBrand),
    },
    {
        key: 4,
        [COLUMN_KEY.TYPE]: "Bid Price VAST Extension",
        [COLUMN_KEY.ALLOW]: getValue(publisher.allowVastExtensionBidPrice),
    },
    {
        key: 5,
        [COLUMN_KEY.TYPE]: "Ad Source Type VAST Extension",
        [COLUMN_KEY.ALLOW]: getValue(publisher.allowVastExtensionAdSourceType),
    },
    {
        key: 6,
        [COLUMN_KEY.TYPE]: "IAB Category VAST Extension",
        [COLUMN_KEY.ALLOW]: getValue(publisher.allowVastExtensionIabCategory),
    },
    {
        key: 7,
        [COLUMN_KEY.TYPE]: "Ad Source Name VAST Extension",
        [COLUMN_KEY.ALLOW]: getValue(publisher.allowVastExtensionAdSourceName),
    },
];

const columns = [
    {
        title: "Type",
        dataIndex: COLUMN_KEY.TYPE,
    },
    {
        title: "Allow",
        dataIndex: COLUMN_KEY.ALLOW,
    },
];

interface Props {
    publisher: Publisher;
}

export const AdvancedFeatures: FC<Props> = ({ publisher }) => {
    return (
        <div data-sdet="inventory-advanced-features">
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label="Emit Pricing in VAST" className={FORM_ITEM_OFFSET}>
                        {getYesNoLabel(Boolean(publisher.emitPricingInVast))}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={8}>
                    <Item label="VAST Extension" className={FORM_ITEM_OFFSET}>
                        <Table pagination={false} columns={columns} dataSource={getTableItems(publisher)} />
                    </Item>
                </Col>
            </Row>
        </div>
    );
};
