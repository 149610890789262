import { FC } from "react";
import { HelpTooltip, SelectAllDropdownRender } from "@app/core/components";
import { FilterSelect } from "@app/core/components/FilterSelect";
import { DealsHelpKeys } from "@app/features/deals/constants";
import { useDealHealthPriorityFilter } from "./useDealHealthPriorityFilter";

export const DealHealthPriorityFilter: FC = () => {
    const { handleChange, options, value, isLoading, areAllOptionsSelected, toggleSelectDeselectAll } =
        useDealHealthPriorityFilter();

    return (
        <FilterSelect
            label={
                <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealDetailsPriority} popover={true}>
                    Priority
                </HelpTooltip>
            }
            onChange={handleChange}
            isLoading={isLoading}
            options={options}
            value={value}
            dataSdet="deal-health-priority-type-filter"
            mode="multiple"
            placeholder="All"
            dropdownRender={(menu) => (
                <SelectAllDropdownRender
                    menu={menu}
                    onClick={toggleSelectDeselectAll}
                    areAllOptionsSelected={areAllOptionsSelected}
                />
            )}
        />
    );
};
