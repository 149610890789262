import { SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { useWatch } from "antd/lib/form/Form";

export const useAutoscaleIsVisible = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeId = adSourceType?.id;

    switch (adSourceTypeId) {
        case AdSourceTypeIds.MARKETPLACE:
            return false;
        default:
            return true;
    }
};
