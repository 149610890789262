import { Form, FormInstance } from "antd";
import { useParams } from "react-router-dom";
import { createContext, FC, useContext } from "react";
import { Seat, useGetSeatSelfQuery } from "@app/core/services";
import { Publisher } from "@magnite/client-streaming-platform";
import { HierarchyFormsMode } from "@app/features/inventory/HierarchyForms/constants";
import { PublisherForm } from "@app/features/inventory/HierarchyForms/PublisherForm/usePublisherForm";

export interface PublisherFormInstanceProps {
    publisher?: Publisher;
    mode: HierarchyFormsMode;
}

type PublisherFormInstanceContextType = PublisherFormInstanceProps & {
    seatId: string;
    seat: Seat | null;
    isLoading: boolean;
    useWatch: typeof Form.useWatch;
    form: FormInstance<PublisherForm>;
};

const PublisherFormInstanceContext = createContext<PublisherFormInstanceContextType | null>(null);

export const PublisherFormInstance: FC<PublisherFormInstanceProps> = ({ children, ...rest }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data: seatSelf, isFetching } = useGetSeatSelfQuery(seatId);
    const [form] = Form.useForm<PublisherForm>();

    return (
        <PublisherFormInstanceContext.Provider
            value={{
                ...rest,
                seat: seatSelf?.seat || null,
                isLoading: isFetching,
                useWatch: Form.useWatch,
                seatId,
                form,
            }}
        >
            {children}
        </PublisherFormInstanceContext.Provider>
    );
};
export const usePublisherFormInstance = (): PublisherFormInstanceContextType => {
    const context: PublisherFormInstanceContextType | null = useContext(PublisherFormInstanceContext);
    if (!context) throw new Error("usePublisherFormInstance must be used within a PublisherFormInstance");
    return context;
};
