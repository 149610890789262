import { FC, useContext } from "react";
import { Card, Col, Row, Collapse } from "antd";
import { useWatch } from "antd/lib/form/Form";
import moment from "moment-timezone";
import { useUserAccess } from "@app/core/auth";
import { SeatAdSourcesType } from "@app/core/services";
import {
    AD_SOURCE_FIELDS,
    AD_SOURCE_SECTIONS,
    AD_SOURCE_SECTIONS_NAME,
    cardBodyStyle,
    GUTTER,
    mainStepSectionClassList,
} from "../../../constants";
import { DefinitionSidePanel } from "../../DefinitionSidePanel";
import {
    CustomPacingCharts,
    DailyImpressionEnabledRadio,
    DailyImpressionInput,
    DeliveryPercentageInput,
    FallbackOpportunity,
    PacingDeliveryScheduleRadio,
    PacingPeriodSelect,
    PacingRedistributionRadio,
    PacingTypeSelect,
    TargetDatePicker,
} from "./Fields";
import {
    isPacingDeliveryScheduleEligible,
    isPacingDeliveryScheduleEnabled,
    isPacingEligible,
} from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { PacingDeliverySchedule } from "./PacingDeliverySchedule";
import { AdSourceFormsContext } from "../../SeatAdSourcesForm";

export const PacingSection: FC = () => {
    const {
        forms: { adSourceFormMode },
    } = useContext(AdSourceFormsContext);
    const {
        seatAccessFlags: { hasPacingDeliverySchedulesAccess },
    } = useUserAccess();

    const adSourceTypeId = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name)?.id;
    const adSourcePacingTypeId = useWatch<{ id: number; name: string }>(AD_SOURCE_FIELDS.PACING_TYPE.name)?.id;
    const isDeliveryScheduleEnabled = useWatch<boolean>(AD_SOURCE_FIELDS.PACING_DELIVERY_SCHEDULE_ENABLED.name);
    const adSourceTotalImpressions = useWatch<number>(AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name);
    const adSourceStartDate = useWatch<moment.Moment | null>(AD_SOURCE_FIELDS.START_DATE.name);
    const adSourceEndDate = useWatch<moment.Moment | null>(AD_SOURCE_FIELDS.END_DATE.name);

    if (!isPacingEligible(adSourceTypeId)) {
        return null;
    }

    return (
        <Collapse
            bordered
            ghost={false}
            collapsible="header"
            className={mainStepSectionClassList}
            defaultActiveKey={["1"]}
            data-sdet={AD_SOURCE_SECTIONS.PACING.toLowerCase().replace(/_/g, "-")}
            items={[
                {
                    key: "1",
                    forceRender: true,
                    label: AD_SOURCE_SECTIONS_NAME.PACING,
                    extra: (
                        <DefinitionSidePanel
                            panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.PACING]}
                            section={AD_SOURCE_SECTIONS.PACING}
                            sectionSelector="#adSourceForm"
                        />
                    ),
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            <Row gutter={GUTTER}>
                                <Col span={8}>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <PacingTypeSelect />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <PacingPeriodSelect />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <PacingRedistributionRadio />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <DailyImpressionEnabledRadio />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <DailyImpressionInput />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <FallbackOpportunity />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <TargetDatePicker />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col span={24}>
                                            <DeliveryPercentageInput />
                                        </Col>
                                    </Row>
                                    {isPacingDeliveryScheduleEligible(
                                        adSourceTypeId,
                                        adSourcePacingTypeId,
                                        hasPacingDeliverySchedulesAccess
                                    ) && (
                                        <Row gutter={GUTTER}>
                                            <Col span={24}>
                                                <PacingDeliveryScheduleRadio
                                                    name={AD_SOURCE_FIELDS.PACING_DELIVERY_SCHEDULE_ENABLED.name}
                                                    deliveryScheduleName={
                                                        AD_SOURCE_FIELDS.PACING_DELIVERY_SCHEDULES.name
                                                    }
                                                    startDate={adSourceStartDate}
                                                    endDate={adSourceEndDate}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                                <Col span={16}>
                                    <Row gutter={GUTTER}>
                                        <CustomPacingCharts />
                                    </Row>
                                </Col>
                            </Row>
                            {isPacingDeliveryScheduleEnabled(
                                adSourceTypeId,
                                adSourcePacingTypeId,
                                isDeliveryScheduleEnabled,
                                hasPacingDeliverySchedulesAccess
                            ) && (
                                <Row gutter={GUTTER}>
                                    <Col span={24}>
                                        <PacingDeliverySchedule
                                            name={AD_SOURCE_FIELDS.PACING_DELIVERY_SCHEDULES.name}
                                            pacingTypeId={adSourcePacingTypeId}
                                            startDate={adSourceStartDate}
                                            endDate={adSourceEndDate}
                                            totalImpressions={adSourceTotalImpressions}
                                            formMode={adSourceFormMode}
                                            vertical
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Card>
                    ),
                },
            ]}
        />
    );
};
