import { FC } from "react";
import { VendorSelect } from "./VendorSelect";
import { PlusOutlined } from "@ant-design/icons";
import { TagRender } from "@app/core/components";
import { Button, Col, Form, Row, Select, Typography } from "antd";
import { LimitInput } from "@app/features/inventory/components/Fields";
import { useViewabilityMesurementField } from "./useViewabilityMesurementField";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

export const ViewabilityMesurementField: FC = () => {
    const {
        sampleRate,
        hidden,
        vendor,
        validator,
        onChange,
        handleChangeVendor,
        handleChangeSampleRate,
        viewabilityMesurementOptions,
        handleAddTypeViewabilityMesurement,
    } = useViewabilityMesurementField();

    return (
        <Form.Item label={INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT.label}>
            <Row style={{ width: "100%", marginBottom: "0.3rem" }} gutter={[4, 4]} wrap>
                <Col xs={24} sm={13} md={10} lg={8} xl={7} xxl={5}>
                    <VendorSelect value={vendor} onChange={handleChangeVendor} />
                </Col>
                <Col>
                    <Typography.Text strong style={{ lineHeight: "32px" }}>
                        :
                    </Typography.Text>
                </Col>
                <Col xs={24} sm={5} md={5} lg={4} xl={4} xxl={3}>
                    <Form.Item
                        name={INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT_SAMPLE_RATE.name}
                        rules={[
                            {
                                validator,
                            },
                        ]}
                        style={{ marginBottom: 0 }}
                    >
                        <LimitInput defaultValue={0} value={sampleRate} onChange={handleChangeSampleRate} toFixed={3} />
                    </Form.Item>
                </Col>
                <Col>
                    <Button
                        disabled={!vendor}
                        onClick={handleAddTypeViewabilityMesurement}
                        style={{ paddingLeft: "0.2rem" }}
                        icon={<PlusOutlined />}
                        type="link"
                        block
                    >
                        Add
                    </Button>
                </Col>
            </Row>
            <Form.Item
                hidden={hidden}
                style={{ marginBottom: 0 }}
                name={INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT.name}
                wrapperCol={{ xs: 24, sm: 24, md: 19, lg: 15, xl: 13, xxl: 10 }}
            >
                <Select
                    allowClear
                    labelInValue
                    open={false}
                    mode="multiple"
                    showSearch={false}
                    onChange={onChange}
                    tagRender={TagRender}
                    options={viewabilityMesurementOptions}
                />
            </Form.Item>
        </Form.Item>
    );
};
