import { useEffect, useMemo, useState } from "react";
import { Form } from "antd";
import { Rule } from "antd/lib/form";
import { LabeledValue } from "antd/es/select";
import debounce from "lodash.debounce";
import { useGetIndustriesGlobalQuery } from "@app/core/services";
import { AdSourceAdvertiserDomainModeIds, IndustriesModeId } from "@app/features/seatAdSources/constants";
import { AdSourcesForm } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { FILTER_INPUT_DEBOUNCE_TIME } from "../constants";
import { useLoadOnScroll } from "../LoadOnScrollSelect";
import { BLOCKED_INDUSTRIES_EXTRA, BLOCKED_INDUSTRIES_LABEL } from "./constants";

const MAX_PAGE_RESULTS = 200;
const MAX_PAGE_RESULTS_WITH_SEARCH = MAX_PAGE_RESULTS * 10;

interface UseBlockedIndustriesSelect {
    options: LabeledValue[];
    isFetching: boolean;
    isSearchCurrent: boolean;
    search: string;
    extra: string | undefined;
    hasMore: boolean;
    value: LabeledValue[] | null;
    label: string;
    placeholder: string;
    disabled: boolean;
    required: boolean;
    rules: Rule[] | undefined;
    loadMore: () => void;
    onChange: (_, selectedOptions: LabeledValue[]) => void;
    onChangeSelectAll: (selectedOptions: LabeledValue[]) => void;
    onChangeSearch: (search: string) => void;
    onBlur: () => void;
    getPrimaryKey: (globalIndustry: LabeledValue) => number;
}

export const useBlockedIndustriesSelect = (
    name: string,
    advertiserDomainModeId?: number | null | undefined,
    industriesModeId?: number | null | undefined,
    propValue?: LabeledValue[] | null,
    propLabel?: string,
    propPlaceholder?: string,
    propDisabled?: boolean,
    propRequired?: boolean,
    propOnChange?: (globalIndustries: LabeledValue[]) => void
): UseBlockedIndustriesSelect => {
    const form = Form.useFormInstance();
    const formValue = Form.useWatch<AdSourcesForm["blockedIndustries"]>(name) || [];
    const [pageByKeyword, setPageByKeyword] = useState({
        "": 1,
    });
    const [keyword, setKeyword] = useState("");

    const debouncedKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const [search, setSearch] = useState("");

    const isSearchCurrent = keyword === search;

    const max = search ? MAX_PAGE_RESULTS_WITH_SEARCH : MAX_PAGE_RESULTS;

    const { data, isFetching, originalArgs } = useGetIndustriesGlobalQuery({
        keyword,
        max,
        page: pageByKeyword[keyword] || 1,
    });

    const {
        options: rawOptions,
        hasMore,
        loadMore,
    } = useLoadOnScroll(
        data,
        isFetching,
        originalArgs?.keyword || "",
        originalArgs?.page || 1,
        (data?.length ?? 0) >= max,
        () =>
            setPageByKeyword((prev) => {
                const currentPage = prev[keyword] || 1;
                return {
                    ...prev,
                    [keyword]: currentPage + 1,
                };
            })
    );

    const value = propValue || formValue;
    const label = propLabel || BLOCKED_INDUSTRIES_LABEL;
    const placeholder = propPlaceholder || `Select ${label}`;
    const disabled =
        advertiserDomainModeId === AdSourceAdvertiserDomainModeIds.ALLOW_OVERRIDE_INVENTORY || Boolean(propDisabled);
    const required = Boolean(propRequired);
    const rules = [{ required: required && !disabled, message: `${label} is required` }];

    const extra =
        industriesModeId === IndustriesModeId.BLOCK_OVERRIDE_INVENTORY && !value?.length
            ? BLOCKED_INDUSTRIES_EXTRA
            : undefined;

    const options = useMemo<LabeledValue[]>(
        () =>
            [...(rawOptions || [])]
                .filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
                .map(({ id, name }) => ({
                    label: name,
                    value: id,
                }))
                .sort((a, b) => a.label.localeCompare(b.label, "en", { sensitivity: "base" })),
        [rawOptions, search]
    );

    const _onChange = (selectedOptions: LabeledValue[]) => {
        form.setFieldValue(name, selectedOptions);
        form.validateFields([name]);
        if (propOnChange) {
            propOnChange(selectedOptions);
        }
    };

    const onChange = (_, selectedOptions: LabeledValue[]) => _onChange(selectedOptions);

    const onChangeSelectAll = (selectedOptions: LabeledValue[]) => _onChange(selectedOptions);

    const onChangeSearch = (search: string) => {
        setSearch(search);
        debouncedKeyword(search);
    };

    const onBlur = () => onChangeSearch("");

    const getPrimaryKey = ({ value }: LabeledValue) => Number(value);

    useEffect(() => () => debouncedKeyword.cancel(), [debouncedKeyword]);

    return {
        options,
        isFetching,
        disabled,
        isSearchCurrent,
        value,
        label,
        placeholder,
        required,
        rules,
        search,
        extra,
        hasMore,
        loadMore,
        onChange,
        onChangeSelectAll,
        onChangeSearch,
        onBlur,
        getPrimaryKey,
    };
};
