import { useEffect, useMemo, useState } from "react";
import { Form } from "antd";
import { LabeledValue } from "antd/es/select";
import debounce from "lodash.debounce";
import { useGetGlobalDistributionGroupLabelsQuery, useGetLabelValuesQuery } from "@magnite/client-streaming-platform";
import { useLoadOnScroll } from "@app/core/components";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";

const MAX_PAGE_RESULTS = 200;

interface UseAssociatedGlobalIDGSelect {
    options: LabeledValue[];
    isFetching: boolean;
    search: string;
    hasMore: boolean;
    loadMore: () => void;
    onChange: (_: unknown, selectedOptions: LabeledValue | undefined) => void;
    onChangeSearch: (search: string) => void;
    onBlur: () => void;
}

export const useAssociatedGlobalIDGSelect = (name: string, selectName: string): UseAssociatedGlobalIDGSelect => {
    const form = Form.useFormInstance();
    const selectValue = Form.useWatch<LabeledValue | undefined>(selectName);
    const selectedId = Number(selectValue?.value);
    const { data: labelValueData } = useGetLabelValuesQuery(selectedId, { skip: !selectedId });
    const [pageByKeyword, setPageByKeyword] = useState({
        "": 1,
    });
    const [keyword, setKeyword] = useState("");
    const debouncedKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );
    const [search, setSearch] = useState("");
    const { data, isFetching, originalArgs } = useGetGlobalDistributionGroupLabelsQuery({
        keyword,
        max: MAX_PAGE_RESULTS,
        page: pageByKeyword[keyword] || 1,
    });
    const {
        options: rawOptions,
        hasMore,
        loadMore,
    } = useLoadOnScroll(
        data,
        isFetching,
        originalArgs?.keyword || "",
        originalArgs?.page || 1,
        (data?.length ?? 0) >= MAX_PAGE_RESULTS,
        () =>
            setPageByKeyword((prev) => {
                const currentPage = prev[keyword] || 1;
                return {
                    ...prev,
                    [keyword]: currentPage + 1,
                };
            })
    );
    const options = useMemo<LabeledValue[]>(
        () =>
            [...(rawOptions || [])].map(({ id, key }) => ({
                label: key,
                value: id,
            })),
        [rawOptions]
    );

    const onChange = (_: unknown, option: LabeledValue | undefined) => {
        form.setFieldValue(selectName, option);
        if (!option) {
            form.setFieldValue(name, option);
        }
    };

    const onChangeSearch = (search: string) => {
        setSearch(search);
        debouncedKeyword(search);
    };

    const onBlur = () => onChangeSearch("");

    useEffect(() => {
        if (labelValueData) {
            // we use the selected associated global IDG to fetch the label value data that is ultimately used as the value on the ad source payload
            form.setFieldValue(name, labelValueData);
        }
    }, [labelValueData, form, name]);

    useEffect(() => () => debouncedKeyword.cancel(), [debouncedKeyword]);

    return {
        options,
        isFetching,
        search,
        hasMore,
        loadMore,
        onChange,
        onChangeSearch,
        onBlur,
    };
};
