import { Form, Input, Radio, Row, Col, Typography } from "antd";
import {
    CLASSIFICATIONS_LABEL_DISTRIBUTION_GROUP_RADIO_GROUP_SDET,
    CLASSIFICATIONS_LABEL_FORM_ITEMS,
    CLASSIFICATIONS_LABEL_KEY_INPUT_SDET,
    CLASSIFICATIONS_LABEL_LABEL_VISIBILITY_RADIO_GROUP_SDET,
    CLASSIFICATIONS_LABEL_MULTI_INSTANCE_RADIO_GROUP_SDET,
    CLASSIFICATIONS_LABEL_PREFILTER_RADIO_GROUP_SDET,
} from "./constants";
import { FC } from "react";
import { Label } from "@magnite/client-streaming-platform";
interface Props {
    label?: Label;
    isDistributionGroup: boolean;
}

export const ClassificationsLabelFormBaseFormItems: FC<Props> = ({ label, isDistributionGroup }) => {
    const isEditMode = Boolean(label);

    return (
        <>
            <Form.Item
                required={!isEditMode}
                label={CLASSIFICATIONS_LABEL_FORM_ITEMS.KEY.label}
                name={CLASSIFICATIONS_LABEL_FORM_ITEMS.KEY.name}
                rules={CLASSIFICATIONS_LABEL_FORM_ITEMS.KEY.rules}
            >
                {isEditMode ? (
                    <Typography.Text>{label?.key}</Typography.Text>
                ) : (
                    <Input data-sdet={CLASSIFICATIONS_LABEL_KEY_INPUT_SDET} placeholder="Enter Label Key" />
                )}
            </Form.Item>
            <Row gutter={16}>
                <Col xs={24} sm={12} lg={12} xl={6} xxl={5}>
                    <Form.Item {...CLASSIFICATIONS_LABEL_FORM_ITEMS.DISTRIBUTION_GROUP}>
                        {isEditMode ? (
                            <Typography.Text>{label?.isDistributionGroup ? "Yes" : "No"}</Typography.Text>
                        ) : (
                            <Radio.Group
                                data-sdet={CLASSIFICATIONS_LABEL_DISTRIBUTION_GROUP_RADIO_GROUP_SDET}
                                disabled={isEditMode}
                            >
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                </Col>
                {!isDistributionGroup && (
                    <Col xs={24} sm={12} lg={12} xl={6} xxl={5}>
                        <Form.Item
                            label={CLASSIFICATIONS_LABEL_FORM_ITEMS.MULTI_INSTANCE.label}
                            name={CLASSIFICATIONS_LABEL_FORM_ITEMS.MULTI_INSTANCE.name}
                        >
                            <Radio.Group data-sdet={CLASSIFICATIONS_LABEL_MULTI_INSTANCE_RADIO_GROUP_SDET}>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                )}
            </Row>
            {!isDistributionGroup && (
                <Row gutter={16}>
                    <Col xs={24} sm={12} lg={12} xl={6} xxl={5}>
                        <Form.Item
                            label={CLASSIFICATIONS_LABEL_FORM_ITEMS.PREFILTER.label}
                            name={CLASSIFICATIONS_LABEL_FORM_ITEMS.PREFILTER.name}
                        >
                            <Radio.Group data-sdet={CLASSIFICATIONS_LABEL_PREFILTER_RADIO_GROUP_SDET}>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} lg={12} xl={6} xxl={5}>
                        <Form.Item
                            label={CLASSIFICATIONS_LABEL_FORM_ITEMS.LABEL_VISIBILITY.label}
                            name={CLASSIFICATIONS_LABEL_FORM_ITEMS.LABEL_VISIBILITY.name}
                        >
                            <Radio.Group data-sdet={CLASSIFICATIONS_LABEL_LABEL_VISIBILITY_RADIO_GROUP_SDET}>
                                <Radio value={true}>Public</Radio>
                                <Radio value={false}>Internal</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </>
    );
};
