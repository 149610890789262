import { FC } from "react";
import { AnimatedNumberCell } from "@app/core/components";

interface DealHealthTableNetRevenueCellProps {
    currencyCode: string | undefined;
    netRevenue: number | undefined;
}

export const DealHealthTableNetRevenueCell: FC<DealHealthTableNetRevenueCellProps> = ({ currencyCode, netRevenue }) => (
    <AnimatedNumberCell value={netRevenue} currencyCode={currencyCode} isMoney={true} />
);
