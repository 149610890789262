import { useMemo } from "react";
import { bvodToLabelValue } from "../../../../helpers";
import { useGetOztamDemoQuery } from "@magnite/client-streaming-platform";

export const useBvodSelect = () => {
    const { data, isFetching } = useGetOztamDemoQuery();
    const options = useMemo(() => bvodToLabelValue(data || []), [data]);

    return {
        isLoading: isFetching,
        options,
    };
};
