import { useGetEnforcementsQuery } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Form } from "antd";
import { useContext } from "react";

export const useEnforcementSelect = () => {
    const { marketplaceInfoMode, dealObjectMode } = useMarketplaceInfoWidget();
    const { forms } = useContext(AdSourceFormsContext);
    const adSourceType = Form.useWatch(AD_SOURCE_FIELDS.TYPE.name, forms.adSourceForm);
    const adSourceTypeId = adSourceType?.id;
    const isVisible =
        adSourceTypeId === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED ||
        adSourceTypeId === AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED ||
        adSourceTypeId === AdSourceTypeIds.FIXED_PRICE;
    const isReadonly = marketplaceInfoMode.name === "view";
    const isDisabled = dealObjectMode === "edit";
    const { data: enforcements = [], isFetching } = useGetEnforcementsQuery(undefined, {
        skip: isReadonly || isDisabled || !isVisible,
    });
    return {
        isLoading: isFetching,
        enforcements,
        isReadonly,
        isVisible,
        isDisabled,
    };
};
