import moment from "moment-timezone";
import { format } from "@rubicon/utils";
import { MONTH_DAY_YEAR_DASHED } from "@app/core/components/constants";
import { AdSource, PacingDeliverySchedule } from "@app/core/services";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { convertLocalDateToApi, parseDateStringFromApi } from "@app/core/utils";
import {
    AdSourceTypeIds,
    GuaranteedTagAdSourceTypeIds,
    NONE_PACING_TYPE_HELP_TEXT,
    PacingDailyImpressionCapEnabledEligibleAdSourceTypeIds,
    PacingEligibleAdSourceTypeIds,
    PacingFallbackOpportunityEstimateEligibleAdSourceTypeIds,
    PacingPeriodEligibleAdSourceTypeIds,
    PacingPeriods,
    PacingTypeEligibleAdSourceTypeIds,
    PacingTypes,
} from "../../constants";
import { AdSourceFormMode, AdSourcesForm } from "..";
import { FormPacingDeliverySchedule } from "../AdSourcesSections";

type _Boolean = boolean | null | undefined;
type _Number = number | null | undefined;

interface ApiPacingFields {
    pacingType: { id: number } | null;
    pacingPeriod: { id: number } | null;
    redistributePacing: boolean | null;
    dailyCapVolume: number | null;
    dailyRequestOpportunityFallback: number | null;
    customCurveStartDate: string | null;
    customCurveImps: number | null;
    pacingDeliveryScheduleEnabled: boolean;
    pacingDeliverySchedules: PacingDeliverySchedule[];
}

export const parsePacingToApi = (
    adSourceTypeId: _Number,
    pacingTypeId: _Number,
    redistributePacing: _Boolean,
    dailyCapVolume: _Number,
    dailyRequestOpportunityFallback: _Number,
    customCurveStartDate: moment.Moment | null,
    customCurveImps: _Number,
    showFallbackOpportunity: _Boolean,
    pacingDeliveryScheduleEnabled: _Boolean,
    pacingDeliverySchedules: FormPacingDeliverySchedule[],
    adSourceTimeZoneCode: string,
    isDailyImpressionCapEnabled: _Boolean,
    hasPacingDeliverySchedulesAccess: boolean
): ApiPacingFields => {
    if (!isPacingEligible(adSourceTypeId)) {
        return {
            pacingType: null,
            pacingPeriod: null,
            redistributePacing: null,
            dailyCapVolume: null,
            dailyRequestOpportunityFallback: null,
            customCurveStartDate: null,
            customCurveImps: null,
            pacingDeliveryScheduleEnabled: false,
            pacingDeliverySchedules: [],
        };
    }

    const shouldDefaultGuaranteedTag = isAdSourceGuaranteedTag(adSourceTypeId) && !pacingTypeId && !pacingTypeId;
    const isDeliveryScheduleEnabled = isPacingDeliveryScheduleEnabled(
        adSourceTypeId,
        pacingTypeId,
        Boolean(pacingDeliveryScheduleEnabled),
        hasPacingDeliverySchedulesAccess
    );

    return {
        pacingType: shouldDefaultGuaranteedTag
            ? { id: PacingTypes.ASAP }
            : pacingTypeId && isPacingTypeEligible(adSourceTypeId)
            ? { id: pacingTypeId }
            : null,
        pacingPeriod:
            isPacingTypeNone(pacingTypeId) || shouldDefaultGuaranteedTag ? null : { id: PacingPeriods.FLIGHT },
        redistributePacing:
            isPacingRedistributionEligible(adSourceTypeId, pacingTypeId) && !shouldDefaultGuaranteedTag
                ? Boolean(redistributePacing)
                : null,
        dailyCapVolume: isDailyImpressionCapEligible(adSourceTypeId, pacingTypeId, isDailyImpressionCapEnabled)
            ? dailyCapVolume || null
            : null,
        dailyRequestOpportunityFallback: isFallbackOpportunityEstimateEligible(
            adSourceTypeId,
            pacingTypeId,
            showFallbackOpportunity
        )
            ? dailyRequestOpportunityFallback || null
            : null,
        customCurveStartDate: isTargetDateEligible(adSourceTypeId, pacingTypeId)
            ? customCurveStartDate?.toISOString() || null
            : null,
        customCurveImps: isDeliveryPercentageEligible(adSourceTypeId, pacingTypeId) ? customCurveImps || 0 : null,
        pacingDeliveryScheduleEnabled: isDeliveryScheduleEnabled,
        pacingDeliverySchedules: isDeliveryScheduleEnabled
            ? (pacingDeliverySchedules ?? []).map(({ dates: [startDate, endDate], impressionGoal, dailyCap }) => ({
                  startDate: convertLocalDateToApi(startDate, adSourceTimeZoneCode) as string,
                  endDate: convertLocalDateToApi(endDate, adSourceTimeZoneCode) as string,
                  impressionGoal: impressionGoal ?? null,
                  dailyCap:
                      isPacingDeliveryScheduleDailyImpressionCapEligible(
                          adSourceTypeId,
                          pacingTypeId,
                          isDeliveryScheduleEnabled
                      ) && dailyCap
                          ? dailyCap
                          : null,
              }))
            : [],
    };
};

interface FormPacingFields
    extends Omit<
        Pick<
            AdSourcesForm,
            | "pacingType"
            | "pacingPeriod"
            | "redistributePacing"
            | "dailyCapEnabled"
            | "dailyCapVolume"
            | "dailyRequestOpportunityFallback"
            | "customCurveStartDate"
            | "customCurveImps"
            | "pacingDeliveryScheduleEnabled"
            | "pacingDeliverySchedules"
        >,
        "customCurveStartDate"
    > {
    customCurveStartDate: moment.Moment | null;
    dailyCapEnabled: boolean;
}

export const parsePacingFromApi = (adSource: AdSource | undefined): FormPacingFields => ({
    pacingType: adSource?.pacingType || null,
    pacingPeriod: adSource?.pacingPeriod || null,
    redistributePacing: Boolean(adSource?.redistributePacing),
    dailyCapVolume: adSource?.dailyCapVolume || null,
    dailyRequestOpportunityFallback: adSource?.dailyRequestOpportunityFallback || null,
    customCurveStartDate: adSource?.customCurveStartDate ? moment(adSource?.customCurveStartDate) : null,
    customCurveImps: adSource?.customCurveImps || null,
    dailyCapEnabled: getIsDailyImpressionCapEnabled(adSource?.dailyCapVolume),
    pacingDeliveryScheduleEnabled: Boolean(adSource?.pacingDeliveryScheduleEnabled),
    pacingDeliverySchedules: (adSource?.pacingDeliverySchedules ?? [])
        .map(({ startDate, endDate, impressionGoal, dailyCap }) => ({
            dates: [
                parseDateStringFromApi(startDate, adSource?.timeZone?.code),
                parseDateStringFromApi(endDate, adSource?.timeZone?.code),
            ],
            impressionGoal: impressionGoal ? impressionGoal : null,
            dailyCap: dailyCap ? dailyCap : null,
        }))
        .sort(({ dates: [startDateA] }, { dates: [startDateB] }) =>
            startDateA && startDateB ? startDateA.diff(startDateB) : 0
        ),
});

export const getIsDailyImpressionCapEnabled = (dailyImpressionCap: _Number): boolean => Boolean(dailyImpressionCap);

export const isPacingTypeNone = (pacingTypeId: _Number): boolean => pacingTypeId === PacingTypes.NONE;

export const isPacingTypeCustom = (pacingTypeId: _Number): boolean => pacingTypeId === PacingTypes.CUSTOM;

export const isPacingTypeAsap = (pacingTypeId: _Number): boolean => pacingTypeId === PacingTypes.ASAP;

export const isPacingTypeEvenly = (pacingTypeId: _Number): boolean => pacingTypeId === PacingTypes.EVENLY;

export const isPacingEligible = (adSourceTypeId: _Number): boolean => {
    return PacingEligibleAdSourceTypeIds.has(Number(adSourceTypeId));
};

export const isPacingTypeEligible = (adSourceTypeId: _Number): boolean =>
    isPacingEligible(adSourceTypeId) && PacingTypeEligibleAdSourceTypeIds.has(Number(adSourceTypeId));

export const isPacingRedistributionEligible = (adSourceTypeId: _Number, pacingTypeId: _Number): boolean =>
    isPacingTypeEligible(adSourceTypeId) && pacingTypeId === PacingTypes.EVENLY;

export const isDailyImpressionCapEnabledEligible = (adSourceTypeId: _Number, pacingTypeId: _Number): boolean =>
    (isPacingEligible(adSourceTypeId) &&
        PacingDailyImpressionCapEnabledEligibleAdSourceTypeIds.has(Number(adSourceTypeId))) ||
    isPacingTypeAsap(pacingTypeId);

export const isDailyImpressionCapEligible = (
    adSourceTypeId: _Number,
    pacingTypeId: _Number,
    isDailyImpressionCapEnabled: _Boolean
): boolean =>
    isPacingEligible(adSourceTypeId) &&
    isDailyImpressionCapEnabledEligible(adSourceTypeId, pacingTypeId) &&
    Boolean(isDailyImpressionCapEnabled);

export const isPacingDeliveryScheduleEligible = (
    adSourceTypeId: _Number,
    pacingTypeId: _Number,
    hasPacingDeliverySchedulesAccess: boolean
): boolean =>
    hasPacingDeliverySchedulesAccess &&
    adSourceTypeId === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED &&
    [PacingTypes.ASAP, PacingTypes.EVENLY].includes(Number(pacingTypeId));

export const isPacingDeliveryScheduleEnabled = (
    adSourceTypeId: _Number,
    pacingTypeId: _Number,
    isPacingDeliveryScheduleEnabled: boolean | undefined,
    hasPacingDeliverySchedulesAccess: boolean
): boolean =>
    hasPacingDeliverySchedulesAccess &&
    isPacingDeliveryScheduleEligible(adSourceTypeId, pacingTypeId, hasPacingDeliverySchedulesAccess) &&
    Boolean(isPacingDeliveryScheduleEnabled);

export const isPacingDeliveryScheduleDailyImpressionCapEligible = (
    adSourceTypeId: _Number,
    pacingTypeId: _Number,
    isPacingDeliveryScheduleEnabled: boolean | undefined
): boolean =>
    adSourceTypeId === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED &&
    pacingTypeId === PacingTypes.ASAP &&
    Boolean(isPacingDeliveryScheduleEnabled);

export const isFallbackOpportunityEstimateEligible = (
    adSourceTypeId: _Number,
    pacingTypeId: _Number,
    showFallbackOpportunity: _Boolean
): boolean =>
    isPacingEligible(adSourceTypeId) &&
    PacingFallbackOpportunityEstimateEligibleAdSourceTypeIds.has(Number(adSourceTypeId)) &&
    Boolean(pacingTypeId) &&
    !isPacingTypeNone(pacingTypeId) &&
    Boolean(showFallbackOpportunity);

export const isPacingPeriodEligible = (adSourceTypeId: _Number, pacingTypeId: _Number): boolean =>
    isPacingEligible(adSourceTypeId) &&
    PacingPeriodEligibleAdSourceTypeIds.has(Number(adSourceTypeId)) &&
    Boolean(pacingTypeId) &&
    !isPacingTypeNone(pacingTypeId);

export const isTargetDateEligible = (adSourceTypeId: _Number, pacingTypeId: _Number): boolean =>
    isPacingTypeEligible(adSourceTypeId) && isPacingTypeCustom(pacingTypeId);

export const isDeliveryPercentageEligible = (adSourceTypeId: _Number, pacingTypeId: _Number): boolean =>
    isPacingTypeEligible(adSourceTypeId) && isPacingTypeCustom(pacingTypeId);

export const getPacingTypeHelpText = (pacingTypeId: _Number): string | undefined =>
    isPacingTypeNone(pacingTypeId) ? NONE_PACING_TYPE_HELP_TEXT : undefined;

export const isPacingTypeReadonly = (
    formMode: AdSourceFormMode | DealFormMode,
    pacingTypeId: _Number,
    hasChanged: boolean
): boolean => formMode === "edit" && isPacingTypeNone(pacingTypeId) && !hasChanged;

export const getPacingPeriodText = (pacingTypeId: _Number): string =>
    isPacingTypeNone(pacingTypeId) ? format.constants.DEFAULT_DASHES : "Flight";

export const formatTargetDateAndDeliveryPercentage = (
    customCurveImps: _Number,
    bookingVolume: _Number,
    customCurveStartDate: moment.Moment | string | null
): string =>
    `${customCurveImps || 0} (${
        customCurveImps && bookingVolume ? Math.round((customCurveImps / bookingVolume) * 100) || 0 : 0
    }%) by EOD of ${moment(customCurveStartDate).format(MONTH_DAY_YEAR_DASHED)}`;

export const isAdSourceGuaranteedTag = (adSourceTypeId: _Number): boolean =>
    GuaranteedTagAdSourceTypeIds.has(Number(adSourceTypeId));
