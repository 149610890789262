import { MarketplaceInfo, SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Form } from "antd";
import { useContext, useEffect } from "react";
import { DEAL_TYPES } from "./constants";

export const useDealTypeSelect = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const { forms } = useContext(AdSourceFormsContext);
    const adSourceType = Form.useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name, forms.adSourceForm);
    const adSourceTypeId = adSourceType?.id;

    const dealObjectForm = Form.useFormInstance();
    const entityType = Form.useWatch<MarketplaceInfo["deal"]["entityType"]>(["dealUnderEdit", "entityType"]);
    const isLegacy = entityType === "Deal";
    const isAuctionPrice =
        adSourceTypeId === AdSourceTypeIds.AUCTION_PRICE ||
        adSourceTypeId === AdSourceTypeIds.OPEN_AUCTION ||
        adSourceTypeId === AdSourceTypeIds.LINEAR_AUCTION_PRICE;
    const isPG =
        adSourceTypeId === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED ||
        adSourceTypeId === AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED;
    const isFixedPrice =
        adSourceTypeId === AdSourceTypeIds.FIXED_PRICE || adSourceTypeId === AdSourceTypeIds.LINEAR_AUCTION_PRICE;

    const isReadonly =
        dealObjectMode === "view" || dealObjectMode === "edit" || isLegacy || isAuctionPrice || isFixedPrice || isPG;

    useEffect(() => {
        if (dealObjectMode === "create") {
            if (isLegacy) {
                dealObjectForm.setFieldValue(["dealUnderEdit", "dealType"], DEAL_TYPES.DEAL_TYPE_LEGACY);
            } else if (isAuctionPrice) {
                dealObjectForm.setFieldValue(["dealUnderEdit", "dealType"], DEAL_TYPES.DEAL_TYPE_AUCTION_PRICE);
            } else if (isFixedPrice) {
                dealObjectForm.setFieldValue(["dealUnderEdit", "dealType"], DEAL_TYPES.DEAL_TYPE_FIXED_PRICE);
            } else {
                dealObjectForm.setFieldValue(["dealUnderEdit", "dealType"], DEAL_TYPES.DEAL_TYPE_PG);
            }
        }
    }, [entityType, dealObjectMode, isLegacy, isAuctionPrice, isFixedPrice, dealObjectForm]);

    return { isReadonly };
};
