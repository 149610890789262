import {
    BaseOption,
    Targeting,
    MarketplaceInfo,
    useGetCurrenciesConversionsByCodeQuery,
    Audience,
} from "@app/core/services";
import { TargetingStub, TargetingBlock, targetingToTargetingBlock } from "@app/features/targeting";
import { useState } from "react";
import {
    EnrichmentCost,
    calcFinalEc,
    calcFinalizedCpm,
    calcFinalCpmWithEcAndDac,
    convertEnrichmentCostByCurrencyRate,
    getAudienceEnrichmentCost,
    getAudiencesFromTargetingForm,
    getIsRangedCost,
    getSegmentsFromTargetingForm,
    getShouldUseAdSourceValues,
} from "./utils";
import { getCurrencyConversionRate } from "@app/features/syncedFields";
import {
    DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
    DemandAcquisitionCostModelId,
} from "@app/features/seatAdSources/constants";
import { SeatSegment } from "@magnite/client-streaming-platform";

export interface DealLevelValue {
    adSourceDemandAcquisitionCost: number;
    adSourceEc: EnrichmentCost;
    cpm: number;
    currencyCode: string;
    dealEc: EnrichmentCost;
    dealId: string;
    dealName: string;
    finalCpm: EnrichmentCost;
    finalEc: EnrichmentCost;
    finalEcPlusDac: EnrichmentCost;
    shouldUseAdSourceValues: boolean;
    targetingAudiences: Audience[];
    targetingSegments: SeatSegment[];
    priceModelId: number | undefined;
}

export const useAdSourceEnrichmentCost = ({
    adSourceDemandAcquisitionCost,
    adSourceDemandAcquisitionCostModelId,
    adSourceCpm,
    adSourceCurrencyCode,
    adSourceFloorTypeId,
    adSourceLevelTargeting,
    adSourceType,
    marketplaceInfoList,
}: {
    adSourceDemandAcquisitionCost: number | null;
    adSourceDemandAcquisitionCostModelId: number | null;
    adSourceCpm: number | null;
    adSourceCurrencyCode: string;
    adSourceFloorTypeId: number;
    adSourceLevelTargeting: (Targeting | TargetingStub | TargetingBlock)[];
    adSourceType: BaseOption;
    marketplaceInfoList: MarketplaceInfo[];
}) => {
    const [selectedMarketplace, setSelectedMarketplace] = useState<MarketplaceInfo | null>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { data: conversions } = useGetCurrenciesConversionsByCodeQuery("USD");

    const shouldUseAdSourceValues = getShouldUseAdSourceValues(adSourceType?.id, adSourceFloorTypeId);
    const adSourceConversionRate = getCurrencyConversionRate(
        DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
        adSourceCurrencyCode,
        conversions
    );

    const adSourceLevelSegments = getSegmentsFromTargetingForm(adSourceLevelTargeting);
    const adSourceLevelAudiences = getAudiencesFromTargetingForm(adSourceLevelTargeting);

    const isRangedCost = getIsRangedCost(adSourceType);

    const adSourceEcUnconverted = getAudienceEnrichmentCost(
        adSourceLevelAudiences,
        adSourceLevelSegments,
        isRangedCost,
        1
    );
    const adSourceCpmNum = Number(adSourceCpm || 0);

    const dealLevelValues = marketplaceInfoList?.map<DealLevelValue>((marketplace) => {
        const targetingBlocks = marketplace.targeting.map(targetingToTargetingBlock);
        const targetingAudiences = getAudiencesFromTargetingForm(targetingBlocks);
        const targetingSegments = getSegmentsFromTargetingForm(targetingBlocks);

        const dealCurrencyCode = marketplace.deal.currencyType?.code || DEFAULT_ENRICHMENT_COST_CURRENCY_CODE;
        const dealConversionRate = getCurrencyConversionRate(
            DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
            dealCurrencyCode,
            conversions
        );
        const conversionRate = shouldUseAdSourceValues ? adSourceConversionRate : dealConversionRate;
        const currencyCode = shouldUseAdSourceValues ? adSourceCurrencyCode : dealCurrencyCode;
        const dealRateNum = Number(marketplace.deal.rate || 0);
        const cpm = shouldUseAdSourceValues ? adSourceCpmNum : dealRateNum;

        const adSourceDemandAcquisitionCostNum = Number(adSourceDemandAcquisitionCost || 0);
        const adSourceDemandAcquisitionCostConverted =
            adSourceDemandAcquisitionCostModelId === DemandAcquisitionCostModelId.PERCENTAGE
                ? adSourceDemandAcquisitionCostNum
                : adSourceDemandAcquisitionCostNum * conversionRate;
        const adSourceEcConverted = convertEnrichmentCostByCurrencyRate(adSourceEcUnconverted, conversionRate);
        const dealEcConverted = getAudienceEnrichmentCost(
            targetingAudiences,
            targetingSegments,
            isRangedCost,
            conversionRate
        );

        const finalEc = calcFinalEc(adSourceEcConverted, dealEcConverted);
        const finalEcPlusDac = calcFinalCpmWithEcAndDac(
            null,
            finalEc,
            adSourceDemandAcquisitionCostConverted,
            adSourceDemandAcquisitionCostModelId
        );
        const finalCpm = calcFinalizedCpm(finalEc, cpm, adSourceDemandAcquisitionCostConverted);
        const priceModelId = marketplace.deal.priceModel?.id;

        return {
            adSourceDemandAcquisitionCost: adSourceDemandAcquisitionCostConverted,
            adSourceEc: adSourceEcConverted,
            cpm,
            currencyCode,
            dealEc: dealEcConverted,
            dealId: String(marketplace.deal.id),
            dealName: marketplace.deal.name || "",
            finalCpm,
            finalEc,
            finalEcPlusDac,
            shouldUseAdSourceValues,
            targetingAudiences,
            targetingSegments,
            priceModelId,
        };
    });

    const hasAdSourceLevelEnrichmentCostTargeting = Boolean(
        adSourceLevelAudiences.length || adSourceLevelSegments.length
    );

    const hasDealsLevelEnrichmentCostTargeting = marketplaceInfoList?.some(
        (marketplace) =>
            getSegmentsFromTargetingForm(marketplace.targeting.map(targetingToTargetingBlock)).length ||
            getAudiencesFromTargetingForm(marketplace.targeting.map(targetingToTargetingBlock)).length
    );

    const hasEnrichmentCostTargeting = hasAdSourceLevelEnrichmentCostTargeting || hasDealsLevelEnrichmentCostTargeting;

    return {
        adSourceConversionRate,
        adSourceEc: adSourceEcUnconverted,
        adSourceLevelAudiences,
        adSourceLevelSegments,
        dealLevelValues,
        hasAdSourceLevelEnrichmentCostTargeting,
        hasDealsLevelEnrichmentCostTargeting,
        hasEnrichmentCostTargeting,
        isDrawerOpen,
        isRangedCost,
        selectedMarketplace,
        setIsDrawerOpen,
        setSelectedMarketplace,
        shouldUseAdSourceValues,
    };
};
