import { FC } from "react";
import { Pagination } from "antd";
import { formatNumber } from "@rubicon/utils";
import { useDealHealthTablePagination } from "./useDealHealthTablePagination";

interface Props {
    totalResults: number;
}

const DealHealthPagination: FC<Props> = ({ totalResults }) => {
    const { page, maxResults, pageSizeOptions, handleChange } = useDealHealthTablePagination();

    return (
        <Pagination
            current={page}
            onChange={handleChange}
            pageSize={maxResults}
            pageSizeOptions={pageSizeOptions}
            showTotal={(total) => (total ? `${formatNumber.asNumber(total)} Total` : "")}
            showSizeChanger
            total={totalResults}
        />
    );
};

export default DealHealthPagination;
