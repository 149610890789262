import { AdSource } from "@app/core/services";
import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";

export const useIsFieldVisible = (adSource: AdSource) => {
    const adSourceTypeId = adSource.type.id;

    switch (adSourceTypeId) {
        case AdSourceTypeIds.AUCTION_PRICE:
        case AdSourceTypeIds.CURATOR_MARKETPLACE:
        case AdSourceTypeIds.LINEAR_AUCTION_PRICE:
        case AdSourceTypeIds.MARKETPLACE:
        case AdSourceTypeIds.OPEN_AUCTION:
        case AdSourceTypeIds.PRE_BID_AD_SOURCE:
            return true;
        default:
            return false;
    }
};
