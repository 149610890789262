import { Loading, SectionTitle } from "@app/core/components";
import { BrandSupply, useGetSeatSelfQuery } from "@app/core/services";
import { SnapshotsEntityTypes } from "@app/core/services/adStats/statsSnapshots";
import { EntityTypes } from "@app/features/adStats";
import { Card, Collapse, Form } from "antd";
import { FC } from "react";
import { classNameCardBody, SUPPLY_DETAILS_SECTIONS_NAME, SECTIONS_NAMES, APPCTVID } from "../constants";
import { HelpKeysButton } from "../HelpKeysButton";
import { BrandSafety, Labels, Pixels, WaterfallPrefiltering, Performance, Affiliate } from "../SharedSections";
import { AppDetails, ExchangeSafetySection, General, InternalSection, SupplyDetails } from "./Sections";
import { FloorsTable } from "@app/features/inventory/components/FloorsTable";
import { useUserAccess } from "@app/core/auth";
import { SupplyTrafficAlertBanner } from "@app/features/inventory/components/SupplyTrafficAlertBanner";
import { SiteDetailsSection } from "@app/features/inventory/InventoryHealthDetailsPages/SupplySections/Sections/SiteDetailsSection";
import { SUPPLY_LINEAR_TYPE, SUPPLY_SITE_TYPE } from "@app/features/inventory/HierarchyForms/constants";
import { useParams } from "react-router-dom";

interface Props {
    supply: BrandSupply;
}

export const SupplySections: FC<Props> = ({ supply }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { isSysAdmin, isPubAcctMgr, isTremorUser } = useUserAccess();
    const { data: seatSelfData } = useGetSeatSelfQuery(seatId);

    const isInternalOnly = isSysAdmin || isPubAcctMgr;
    const isLinearType: boolean = supply?.type?.id === SUPPLY_LINEAR_TYPE.value;
    const isSiteType: boolean = supply?.type?.id === SUPPLY_SITE_TYPE.value;
    const isSiteDetailsSectionShown: boolean = (isSiteType || isLinearType) && !supply?.isDynamic;
    const isAffiliateSectionShown: boolean = isTremorUser && !!seatSelfData?.seat?.affiliateCostEnabled;

    if (!supply) {
        return <Loading />;
    }

    return (
        <>
            <SupplyTrafficAlertBanner sourceSelf="Supply" />
            <Form layout="vertical" style={{ width: "100%" }} data-sdet="supply-sections">
                <Collapse
                    defaultActiveKey={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
                    bordered
                    ghost={false}
                    collapsible="header"
                >
                    <Collapse.Panel
                        key={1}
                        header={<SectionTitle title={SUPPLY_DETAILS_SECTIONS_NAME().performance.label} />}
                        extra={
                            <HelpKeysButton
                                label={SUPPLY_DETAILS_SECTIONS_NAME().performance.label}
                                sectionKey={SECTIONS_NAMES.PERFORMANCE}
                            />
                        }
                    >
                        <Performance
                            id={supply?.id}
                            adStatsEntity={EntityTypes.Supply}
                            snapShotEntity={SnapshotsEntityTypes.Supply}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={2}
                        header={<SectionTitle title={SUPPLY_DETAILS_SECTIONS_NAME().general.label} />}
                        extra={
                            <HelpKeysButton
                                label={SUPPLY_DETAILS_SECTIONS_NAME().general.label}
                                sectionKey={SECTIONS_NAMES.GENERAL}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <General supply={supply} />
                        </Card>
                    </Collapse.Panel>
                    {isAffiliateSectionShown && (
                        <Collapse.Panel
                            key={3}
                            header={<SectionTitle title={SUPPLY_DETAILS_SECTIONS_NAME().affiliate.label} />}
                            extra={
                                <HelpKeysButton
                                    label={SUPPLY_DETAILS_SECTIONS_NAME().affiliate.helpLabel}
                                    sectionKey={SECTIONS_NAMES.AFFILIATE}
                                />
                            }
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <Affiliate unit={supply} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    {isSiteDetailsSectionShown && (
                        <Collapse.Panel
                            key={4}
                            header={<SectionTitle title={SUPPLY_DETAILS_SECTIONS_NAME().siteDetails.label} />}
                            extra={
                                <HelpKeysButton
                                    label={SUPPLY_DETAILS_SECTIONS_NAME().siteDetails.label}
                                    sectionKey={SECTIONS_NAMES.SITE_DETAILS}
                                />
                            }
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <SiteDetailsSection supply={supply} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    {supply?.type?.id === APPCTVID && !supply?.isDynamic && (
                        <Collapse.Panel
                            key={3}
                            header={<SectionTitle title={SUPPLY_DETAILS_SECTIONS_NAME().appDetails.label} />}
                            extra={
                                <HelpKeysButton
                                    label={SUPPLY_DETAILS_SECTIONS_NAME().appDetails.label}
                                    sectionKey={SECTIONS_NAMES.APP_DETAILS}
                                />
                            }
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <AppDetails supply={supply} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    <Collapse.Panel
                        key={4}
                        header={<SectionTitle title={SUPPLY_DETAILS_SECTIONS_NAME().brandSafety.label} />}
                        extra={
                            <HelpKeysButton
                                label={SUPPLY_DETAILS_SECTIONS_NAME().brandSafety.label}
                                sectionKey={SECTIONS_NAMES.BRAND_SAFETY}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <BrandSafety unit={supply} />
                        </Card>
                    </Collapse.Panel>
                    {isInternalOnly && (
                        <Collapse.Panel
                            key={5}
                            header={<SectionTitle title={SUPPLY_DETAILS_SECTIONS_NAME().exchangeSafety.label} />}
                            extra={
                                <HelpKeysButton label="Exchange Safety" sectionKey={SECTIONS_NAMES.EXCHANGE_SAFETY} />
                            }
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <ExchangeSafetySection supply={supply} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    {isTremorUser && (
                        <Collapse.Panel
                            key={6}
                            header={<SectionTitle title={SUPPLY_DETAILS_SECTIONS_NAME().internal.label} />}
                            extra={<HelpKeysButton label="Internal" sectionKey={SECTIONS_NAMES.INTERNAL} />}
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <InternalSection supply={supply} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    <Collapse.Panel
                        key={7}
                        header={<SectionTitle title={SUPPLY_DETAILS_SECTIONS_NAME().supplyDetails.label} />}
                        extra={
                            <HelpKeysButton
                                label={SUPPLY_DETAILS_SECTIONS_NAME(isInternalOnly).supplyDetails.label}
                                sectionKey={SECTIONS_NAMES.SUPPLY_DETAILS}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <SupplyDetails supply={supply} isInternalOnly={isInternalOnly} />
                        </Card>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={8}
                        header={<SectionTitle title={SUPPLY_DETAILS_SECTIONS_NAME().pixels.label} />}
                        extra={
                            <HelpKeysButton
                                label={SUPPLY_DETAILS_SECTIONS_NAME().pixels.label}
                                sectionKey={SECTIONS_NAMES.PIXELS}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <Pixels id={supply?.id} thirdPartyPixels={supply?.thirdPartyPixels} />
                        </Card>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={9}
                        header={<SectionTitle title={SUPPLY_DETAILS_SECTIONS_NAME().labels.label} />}
                        extra={
                            <HelpKeysButton
                                label={SUPPLY_DETAILS_SECTIONS_NAME(isInternalOnly).labels.label}
                                sectionKey={SECTIONS_NAMES.LABELS}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <Labels
                                id={supply?.id}
                                labels={supply?.labelValues}
                                isInternalOnly={isInternalOnly}
                                internalLabelValues={supply?.internalLabelValues}
                            />
                        </Card>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={10}
                        header={<SectionTitle title={SUPPLY_DETAILS_SECTIONS_NAME().waterfallPrefiltering.label} />}
                        extra={
                            <HelpKeysButton
                                label={SUPPLY_DETAILS_SECTIONS_NAME().waterfallPrefiltering.label}
                                sectionKey={SECTIONS_NAMES.WATERFALL_PREFILTERING}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <WaterfallPrefiltering unit={supply} />
                        </Card>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={11}
                        header={<SectionTitle title={SUPPLY_DETAILS_SECTIONS_NAME().floors.label} />}
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <FloorsTable readOnly id={supply?.id} sourceSelf="Supply" hiddenColumns={["actualPrice"]} />
                        </Card>
                    </Collapse.Panel>
                </Collapse>
            </Form>
        </>
    );
};
