import { Dashes } from "@app/core/components";
import { SeatContentTransparencyRule } from "@app/core/services";
import { DealAdSourceTransparencyExtendedUserId } from "@app/features/deals/DealForm/types";
import { Col, Form, Row, Tag } from "antd";
import { FC } from "react";
import { format } from "@rubicon/utils";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { useUserAccess } from "@app/core/auth";

const getSpecificText = (value: boolean, specific: DealAdSourceTransparencyExtendedUserId[]) =>
    specific?.length ? "Specific" : value ? "All" : "None";
const getOverrideShareHiddenText = (value: boolean, specific: string | number | null) =>
    specific ? "Override" : format.asSharedOrHidden(value);
const getMaskedSharedHiddenText = (value: boolean, specific: boolean) =>
    specific ? "Masked" : format.asSharedOrHidden(value);

interface Props {
    allowSiteName: boolean;
    allowDomainName: boolean;
    allowSitePage: boolean;
    allowRef: boolean;
    allowBundleId: boolean;
    allowIp: boolean;
    allowStoreUrl: boolean;
    allowUserId: boolean;
    maskUserId: boolean;
    allowContent: boolean;
    allowAllExtendedId: boolean;
    domainNameOverride: string | null;
    bundleIdOverride: string | null;
    allowedExtendedIdTransparencies: DealAdSourceTransparencyExtendedUserId[] | [];
    contentTransparencyRule: SeatContentTransparencyRule | null;
    allowAudienceLock: boolean | null;
    allowOztamCoviewing: boolean | null;
}

export const AdSourceTransparency: FC<Props> = ({
    allowSitePage,
    allowDomainName,
    allowSiteName,
    allowRef,
    allowBundleId,
    allowIp,
    allowUserId,
    maskUserId,
    allowStoreUrl,
    allowContent,
    allowAllExtendedId,
    domainNameOverride,
    bundleIdOverride,
    allowedExtendedIdTransparencies,
    contentTransparencyRule,
    allowAudienceLock,
    allowOztamCoviewing,
}) => {
    const { isOztamEnabled } = useUserAccess();
    return (
        <>
            <Row>
                <Col span={24}>
                    <Form.Item label={AD_SOURCE_FIELDS.SUPPLY_NAME.label}>
                        {format.asSharedOrHidden(allowSiteName)}
                    </Form.Item>
                </Col>
                <Col xs={12} xl={8}>
                    <Form.Item label={AD_SOURCE_FIELDS.DOMAIN_NAME.label}>
                        {getOverrideShareHiddenText(allowDomainName, domainNameOverride)}
                    </Form.Item>
                </Col>
                <Col xs={12} xl={8}>
                    <Form.Item label={AD_SOURCE_FIELDS.DOMAIN_NAME_OVERRIDE.label}>
                        <Dashes value={domainNameOverride} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label={AD_SOURCE_FIELDS.SUPPLY_PAGE.label}>
                        {format.asSharedOrHidden(allowSitePage)}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={AD_SOURCE_FIELDS.REFER_URL.label}>{format.asSharedOrHidden(allowRef)}</Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label={AD_SOURCE_FIELDS.FULL_IP_ADDRESS.label}>
                        {format.asSharedOrHidden(allowIp)}
                    </Form.Item>
                </Col>
                <Col xs={12} xl={8}>
                    <Form.Item label={AD_SOURCE_FIELDS.BUNDLE_ID.label}>
                        {getOverrideShareHiddenText(allowBundleId, bundleIdOverride)}
                    </Form.Item>
                </Col>
                <Col xs={12} xl={8}>
                    <Form.Item label={AD_SOURCE_FIELDS.BUNDLE_ID_OVERRIDE.label}>
                        <Dashes value={bundleIdOverride} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label={AD_SOURCE_FIELDS.STORE_URL.label}>
                        {format.asSharedOrHidden(allowStoreUrl)}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={AD_SOURCE_FIELDS.USER_ID.label}>
                        {getMaskedSharedHiddenText(allowUserId, maskUserId)}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={AD_SOURCE_FIELDS.CONTENT.label}>
                        {contentTransparencyRule?.name || format.asSharedOrHidden(allowContent)}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={12} xl={8}>
                    <Form.Item label={AD_SOURCE_FIELDS.SHARE_EXTENDED_IDS.label}>
                        {allowedExtendedIdTransparencies?.length
                            ? allowedExtendedIdTransparencies.map(
                                  ({ extendedId, network }: DealAdSourceTransparencyExtendedUserId) => (
                                      <Tag key={`${extendedId.id}${network?.id}`}>
                                          {extendedId.name} {network?.name && `- ${network.name}`}
                                      </Tag>
                                  )
                              )
                            : "None"}
                    </Form.Item>
                </Col>
                <Col xs={12} xl={8}>
                    <Form.Item label={AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.label}>
                        {getSpecificText(allowAllExtendedId, allowedExtendedIdTransparencies)}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={AD_SOURCE_FIELDS.ALLOW_AUDIENCE_LOCK.label}>
                        {format.asYesOrNo(allowAudienceLock)}
                    </Form.Item>
                </Col>
                {isOztamEnabled && (
                    <Col span={24}>
                        <Form.Item label={AD_SOURCE_FIELDS.OZTAM_CO_VIEWING.label}>
                            {format.asYesOrNo(allowOztamCoviewing)}
                        </Form.Item>
                    </Col>
                )}
            </Row>
        </>
    );
};
