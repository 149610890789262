import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@app/core/store";
import { DEFAULT_PAGE_SIZE } from "@app/core/components/constants";
import { InventoryLiveEventsOrderByColumns } from "@app/core/services/console/liveEvents";

interface InventoryLiveEventsState {
    params: {
        page: number;
        max: number;
        keyword?: string;
        orderBy?: InventoryLiveEventsOrderByColumns;
        isAscending?: boolean;
    };
}

const initialState: InventoryLiveEventsState = {
    params: {
        page: 1,
        max: DEFAULT_PAGE_SIZE,
    },
};

const inventoryLiveEventsSlice = createSlice({
    name: "inventoryLiveEvents",
    initialState,
    reducers: {
        setInventoryLiveEventsKeyword: (
            state: InventoryLiveEventsState,
            action: PayloadAction<{ keyword: string }>
        ) => {
            state.params.keyword = action.payload.keyword;
        },
        setInventoryLiveEventsSort: (
            state: InventoryLiveEventsState,
            action: PayloadAction<{ orderBy: InventoryLiveEventsOrderByColumns; isAscending?: boolean }>
        ) => {
            state.params.orderBy = action.payload.orderBy;
            state.params.isAscending = action.payload.isAscending;
        },
        setInventoryLiveEventsPagination: (
            state: InventoryLiveEventsState,
            action: PayloadAction<{ page: number; max: number }>
        ) => {
            state.params.page = action.payload.page;
            state.params.max = action.payload.max;
        },
    },
});

export const selectInventoryLiveEventsKeyword = (state: RootState) => state.inventoryLiveEvents.params.keyword;
export const selectInventoryLiveEventsOrderBy = (state: RootState) => state.inventoryLiveEvents.params.orderBy;
export const selectInventoryLiveEventsIsAscending = (state: RootState) => state.inventoryLiveEvents.params.isAscending;
export const selectInventoryLiveEventsPage = (state: RootState) => state.inventoryLiveEvents.params.page;
export const selectInventoryLiveEventsMax = (state: RootState) => state.inventoryLiveEvents.params.max;

export const { setInventoryLiveEventsKeyword, setInventoryLiveEventsSort, setInventoryLiveEventsPagination } =
    inventoryLiveEventsSlice.actions;

export const inventoryLiveEventsReducer = inventoryLiveEventsSlice.reducer;
