import { Store } from "rc-field-form/lib/interface";
import {
    parseIfaType,
    parseIsCoppa,
    parseAppStore,
    parseCodeValue,
    parseKeywords,
    parseResellType,
    parseLabelValues,
    parseVideoQuality,
    parseBuyerSeatList,
    parseHasPrivacyPolicy,
    parseThirdPartyPixels,
    parseAllowVastWrappers,
    parsePublisherReauction,
    parseViewabilityVendors,
    parseMaskIFAForNetworks,
    parseInternalLabelValues,
    parseAffiliateCostModel,
    parseEnableNoAdCookieSync,
    parsePrefilterLabelValues,
    parseAdvertiserBlockDomains,
    parseInventoryPartnerDomain,
    parseAffiliateCostValueFixed,
    parseBlockedAdvertiserDomains,
    parseAffiliateCostValuePercent,
    parseSupplyBundleIdFilterLists,
    parseSupplySupplyDomainFilterLists,
} from "@app/features/inventory/HierarchyForms/helpers";
import {
    IFA_SPECIFY_MODE,
    SUPPLY_SITE_TYPE,
    HierarchyFormsMode,
    SUPPLY_FORM_FIELDS,
    SUPPLY_DYNAMIC_MODE,
    NOT_SPECIFIED_OPTION,
    NOT_SPECIFIED_PAID_TYPE,
    INVENTORY_CUSTOM_CODE_TYPE,
    INVENTORY_GENERATED_CODE_TYPE,
    IFA_AUTOMATICALLY_DETECT_MODE,
    DEFAULT_BUNDLE_ID_IS_OVERRIDE,
    INVENTORY_INDIRECT_SUPPLY_SOURCE_RELATIONSHIP,
} from "@app/features/inventory/HierarchyForms/constants";
import { BrandSupply, BrandSupplyPayload, Seat } from "@app/core/services";
import { SupplyForm } from "@app/features/inventory/HierarchyForms/SupplyForm/useSupplyForm";

interface ParseSupplyFormValuesToApi {
    seat: Seat | null;
    brandId: number;
    values: SupplyForm;
    isSysAdmin: boolean;
    isPubAcctMgr: boolean;
    isTremorUser: boolean;
    mode: HierarchyFormsMode;
    hasInternalAccess: boolean;
}

interface GetSupplyFormInitialValues {
    supply: BrandSupply | undefined;
    mode: HierarchyFormsMode;
}

export const getSupplyFormInitialValues = ({ supply, mode }: GetSupplyFormInitialValues): Store => {
    const isCreateMode = mode === "create";
    const isCopyMode = mode === "copy";
    return {
        // General Section
        [SUPPLY_FORM_FIELDS.NAME.name]: isCreateMode ? "" : isCopyMode ? `Copy of ${supply?.name}` : supply?.name,
        [SUPPLY_FORM_FIELDS.CODE_TYPE.name]:
            isCreateMode || isCopyMode ? INVENTORY_GENERATED_CODE_TYPE.value : INVENTORY_CUSTOM_CODE_TYPE.value,
        [SUPPLY_FORM_FIELDS.CODE.name]: isCreateMode || isCopyMode ? "" : supply?.code,
        [SUPPLY_FORM_FIELDS.DESCRIPTION.name]: isCreateMode ? "" : supply?.description,
        [SUPPLY_FORM_FIELDS.TYPE.name]: isCreateMode ? SUPPLY_SITE_TYPE.value : supply?.type?.id,
        [SUPPLY_FORM_FIELDS.MODE.name]: isCreateMode ? SUPPLY_DYNAMIC_MODE : supply?.isDynamic,

        // Affiliate Section
        [SUPPLY_FORM_FIELDS.AFFILIATE_COST_MODEL.name]: parseAffiliateCostModel(mode, supply),
        [SUPPLY_FORM_FIELDS.AFFILIATE_COST_VALUE_PERCENT.name]: parseAffiliateCostValuePercent(mode, supply),
        [SUPPLY_FORM_FIELDS.AFFILIATE_COST_VALUE_FIXED.name]: parseAffiliateCostValueFixed(mode, supply),

        // Brand Safety Section
        [SUPPLY_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LISTS.name]: parseBlockedAdvertiserDomains(mode, supply),
        [SUPPLY_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: parseAdvertiserBlockDomains(mode, supply),
        [SUPPLY_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name]: parseBuyerSeatList(mode, supply),

        // Site Details Section
        [SUPPLY_FORM_FIELDS.EXTERNAL_NAME.name]: isCreateMode ? "" : supply?.supplyNameOverride,
        [SUPPLY_FORM_FIELDS.OVERRIDE.name]: !(isCreateMode || !supply?.supplyNameOverride),
        [SUPPLY_FORM_FIELDS.DOMAIN.name]: isCreateMode ? "" : supply?.domain,
        [SUPPLY_FORM_FIELDS.INVENTORY_PARTNER_DOMAIN.name]: parseInventoryPartnerDomain(mode, supply),

        // App Details Section
        [SUPPLY_FORM_FIELDS.APP_STORE.name]: parseAppStore(mode, supply),
        [SUPPLY_FORM_FIELDS.BUNDLE_ID.name]: isCreateMode ? "" : supply?.bundleId,
        [SUPPLY_FORM_FIELDS.BUNDLE_ID_IS_OVERRIDE.name]: isCreateMode
            ? DEFAULT_BUNDLE_ID_IS_OVERRIDE
            : supply?.bundleIdIsOverride,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [SUPPLY_FORM_FIELDS.APP_NAME.name]: isCreateMode ? "" : supply?.supplyNameOverride,
        [SUPPLY_FORM_FIELDS.STORE_URL.name]: isCreateMode ? "" : supply?.storeUrl,
        [SUPPLY_FORM_FIELDS.PAID_TYPE.name]:
            isCreateMode || !supply?.paidType?.id ? NOT_SPECIFIED_PAID_TYPE.value : supply?.paidType?.id,

        // Exchange Safety Section
        [SUPPLY_FORM_FIELDS.SUPPLY_DOMAIN_LISTS.name]: parseSupplySupplyDomainFilterLists(mode, supply),
        [SUPPLY_FORM_FIELDS.APP_BUNDLE_ID_LISTS.name]: parseSupplyBundleIdFilterLists(mode, supply),

        //Internal Section
        [SUPPLY_FORM_FIELDS.RESELL_TYPE.name]: parseResellType(mode, supply),
        [SUPPLY_FORM_FIELDS.ENABLE_NO_AD_COOKIE_SYNC.name]: parseEnableNoAdCookieSync(mode, supply),
        [SUPPLY_FORM_FIELDS.LOWER_CALCULON_USE_RATE_OVERRIDE.name]: isCreateMode
            ? null
            : supply?.calculonUseRateOverride,
        [SUPPLY_FORM_FIELDS.UPPER_CALCULON_USE_RATE_OVERRIDE.name]: isCreateMode
            ? null
            : supply?.upperCalculonUseRateOverride,
        [SUPPLY_FORM_FIELDS.PUBLISHER_RE_AUCTION.name]: parsePublisherReauction(mode, supply),

        // Supply Details Section
        [SUPPLY_FORM_FIELDS.VIDEO_QUALITY.name]: parseVideoQuality(mode, supply),
        [SUPPLY_FORM_FIELDS.SOURCE_RELATIONSHIP.name]:
            isCreateMode || !supply?.sourceRelationship?.id
                ? INVENTORY_INDIRECT_SUPPLY_SOURCE_RELATIONSHIP.value
                : supply?.sourceRelationship?.id,
        [SUPPLY_FORM_FIELDS.IFA_MODE.name]:
            isCreateMode || !supply?.ifaType ? IFA_AUTOMATICALLY_DETECT_MODE : IFA_SPECIFY_MODE,
        [SUPPLY_FORM_FIELDS.IFA_TYPE.name]: parseIfaType(mode, supply),
        [SUPPLY_FORM_FIELDS.ALLOW_VAST_WRAPPERS.name]: parseAllowVastWrappers(mode, supply),
        [SUPPLY_FORM_FIELDS.COPPA.name]: parseIsCoppa(mode, supply),
        [SUPPLY_FORM_FIELDS.PRIVACY_POLICY.name]: parseHasPrivacyPolicy(mode, supply),
        [SUPPLY_FORM_FIELDS.VIEWABILITY_VENDORS.name]: parseViewabilityVendors(mode, supply),
        [SUPPLY_FORM_FIELDS.KEYWORDS.name]: parseKeywords(mode, supply),
        [SUPPLY_FORM_FIELDS.MASK_IFA_FOR_DEMANDS.name]: parseMaskIFAForNetworks(mode, supply),

        // Custom Pixels
        [SUPPLY_FORM_FIELDS.CUSTOM_PIXELS.name]: parseThirdPartyPixels(mode, supply),

        // Labels
        [SUPPLY_FORM_FIELDS.LABELS.name]: parseLabelValues(mode, supply),
        [SUPPLY_FORM_FIELDS.INTERNAL_LABELS.name]: parseInternalLabelValues(mode, supply),

        // Waterfall Prefiltering
        [SUPPLY_FORM_FIELDS.PREFILTER_LABEL_VALUES.name]: parsePrefilterLabelValues(mode, supply),
    };
};

export const parseSupplyFormValuesToApi = ({
    seat,
    values,
    brandId,
    isSysAdmin,
    isPubAcctMgr,
    isTremorUser,
    hasInternalAccess,
}: ParseSupplyFormValuesToApi): BrandSupplyPayload => {
    const {
        // General Section
        name,
        code,
        type,
        codeType,
        isDynamic,
        description,
        // Affiliate Section
        affiliateCostModel,
        affiliateCostValueFixed,
        affiliateCostValuePercent,
        // Brand Safety Section
        seatAdvertiserDomainFilterListDefs,
        advertiserBlockDomains,
        buyerSeatList,
        // Site Details Section
        supplyNameOverride,
        domain,
        inventoryPartnerDomain,
        // App Details Section
        appStore,
        bundleId,
        bundleIdIsOverride,
        storeUrl,
        paidType,
        // Exchange Safety Section
        supplySupplyDomainFilterLists,
        supplyBundleIdFilterLists,
        // Internal Section
        resellType,
        enableNoAdCookieSync,
        calculonUseRateOverride,
        upperCalculonUseRateOverride,
        publisherReauction,
        // Supply Details Section
        videoQuality,
        sourceRelationship,
        ifaMode,
        ifaType,
        allowVastWrappers,
        isCoppa,
        hasPrivacyPolicy,
        viewabilityVendors,
        keywords,
        maskIFAForNetworks,
        // Custom Pixels
        thirdPartyPixels,
        // Labels
        labelValues,
        internalLabelValues,
        // Waterfall Prefiltering
        prefilterLabelValues,
    } = values;

    const payload: BrandSupplyPayload = {
        brand: { id: brandId },
        // General Section
        name,
        code: parseCodeValue(codeType, code),
        description,
        type: { id: type },
        isDynamic,
        // Brand Safety Section
        seatAdvertiserDomainFilterListDefs: (seatAdvertiserDomainFilterListDefs || []).map((option) => ({
            id: option.value as number,
            name: option.label as string,
        })),
        advertiserBlockDomains: advertiserBlockDomains.trim() ? advertiserBlockDomains.trim().split("\n") : null,
        buyerSeatList: buyerSeatList
            ? { id: buyerSeatList.value as number, name: buyerSeatList.label as string }
            : null,
        // Site Details Section
        supplyNameOverride,
        domain: domain?.trim() ? domain?.trim() : null,
        inventoryPartnerDomain: inventoryPartnerDomain?.trim() ? inventoryPartnerDomain?.trim() : null,
        // App Details Section
        appStore: appStore ? { id: appStore.value as number, name: appStore.label as string } : null,
        bundleId,
        bundleIdIsOverride,
        storeUrl,
        paidType: paidType ? { id: paidType } : null,
        // Supply Details Section
        videoQuality: videoQuality ? { id: videoQuality.value as number, name: videoQuality.label as string } : null,
        sourceRelationship: { id: sourceRelationship },
        ifaType: !ifaMode && ifaType ? { id: ifaType.value as number, name: ifaType.label as string } : null,
        allowVastWrappers,
        isCoppa: isCoppa === NOT_SPECIFIED_OPTION.value ? null : (isCoppa as boolean),
        hasPrivacyPolicy: hasPrivacyPolicy === NOT_SPECIFIED_OPTION.value ? null : (hasPrivacyPolicy as boolean),
        keywords,
        maskIFAForNetworks: (maskIFAForNetworks || []).map((option) => ({
            id: option.value as number,
            name: option.label as string,
        })),
        // Custom Pixels
        thirdPartyPixels: (thirdPartyPixels || []).map((pixel) => ({
            id: pixel.value as number,
            name: pixel.label as string,
        })),
        // Labels
        labelValues: (labelValues || []).map(({ value, seatLabel, labelValue }) => ({
            id: value,
            value: labelValue,
            label: seatLabel,
        })),
        // Waterfall Prefiltering
        prefilterLabelValues: (prefilterLabelValues || []).map(({ value, seatLabel, labelValue }) => ({
            id: value,
            value: labelValue,
            label: seatLabel,
        })),
    };
    if (isSysAdmin || isPubAcctMgr) {
        Object.assign(payload, {
            // Exchange Safety Section
            supplySupplyDomainFilterLists: (supplySupplyDomainFilterLists || []).map((option) => ({
                id: option.value as number,
                name: option.label as string,
            })),
            supplyBundleIdFilterLists: (supplyBundleIdFilterLists || []).map((option) => ({
                id: option.value as number,
                name: option.label as string,
            })),
        });
    }
    if (isTremorUser && seat?.affiliateCostEnabled) {
        Object.assign(payload, {
            // Affiliate Section
            affiliateCostModel: affiliateCostModel
                ? { id: affiliateCostModel.value, name: affiliateCostModel.label }
                : null,
            affiliateCostValuePercent: affiliateCostValuePercent,
            affiliateCostValueFixed:
                typeof affiliateCostValueFixed === "number" ? affiliateCostValueFixed * 1000 : null,
        });
    }
    if (isSysAdmin) {
        Object.assign(payload, {
            // Supply Details Section
            viewabilityVendors: (viewabilityVendors || []).map((option) => ({
                id: option.value as number,
                name: option.label as string,
            })),
        });
    }
    if (isTremorUser) {
        Object.assign(payload, {
            // Internal Section
            resellType: resellType ? { id: resellType.value as number, name: resellType.label as string } : null,
            enableNoAdCookieSync,
            calculonUseRateOverride,
            upperCalculonUseRateOverride,
        });
        if (isPubAcctMgr || isSysAdmin)
            Object.assign(payload, {
                publisherReauction: publisherReauction === NOT_SPECIFIED_OPTION.value ? null : publisherReauction,
            });
    }
    if (hasInternalAccess)
        Object.assign(payload, {
            // Labels
            internalLabelValues: (internalLabelValues || []).map(({ value, seatLabel, labelValue }) => ({
                id: value,
                value: labelValue,
                label: seatLabel,
            })),
        });
    return payload;
};
