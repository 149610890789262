import { AdvancedFloor, Floor, Targeting } from "@app/core/services";
import { LabeledValue } from "antd/lib/select";
import { intl } from "@rubicon/utils";
import { NONE } from "@app/core/components/constants";
import { GeoTarget } from "@magnite/client-streaming-platform";

export type SourceSelf = "Seat" | "Publisher" | "Brand" | "Supply" | "Ad Unit";

export const getFloorPriceLabel = (floor: Floor | AdvancedFloor, currencyCode: string = "USD") =>
    intl.moneyFormatter(floor.price / 1000, currencyCode);

export const getFloorSource = (floor: Floor) => {
    if (floor.seat) {
        return "Seat";
    }
    if (floor.publisher) {
        return floor.publisher.entityType as "Publisher" | "Channel";
    }
    if (floor.brand) {
        return floor.brand.entityType as "Brand" | "Brand Channel";
    }
    if (floor.supply) {
        return "Supply";
    }
    if (floor.adUnit) {
        return "Ad Unit";
    }

    return NONE;
};

export const getFloorSourceLabel = (floor: Floor | null, sourceSelf: SourceSelf) => {
    if (!floor) {
        return `${sourceSelf} (Self)`;
    }

    const source = getFloorSource(floor);
    const isSelfChannel =
        source === "Channel" &&
        (sourceSelf === "Publisher" || sourceSelf === "Brand" || sourceSelf === "Supply" || sourceSelf === "Ad Unit");
    const isSelf = source === sourceSelf || isSelfChannel;

    if (isSelf) {
        return `${source} (Self)`;
    }
    return source;
};

export const getFloorInheritedFrom = (floor: Floor): { id: number; name: string } | null => {
    if (floor.seat) {
        return floor.seat;
    }
    if (floor.publisher) {
        return floor.publisher;
    }
    if (floor.brand) {
        return floor.brand;
    }
    if (floor.supply) {
        return floor.supply;
    }
    return null;
};

export const getFloorInheritedFromName = (floor: Floor): string => {
    if (!floor) return NONE;
    if (floor.seat) {
        return floor.seat.name;
    }
    if (floor.publisher) {
        return floor.publisher.name;
    }
    if (floor.brand) {
        return floor.brand.name;
    }
    if (floor.supply) {
        return floor.supply.name;
    }
    if (floor.adUnit) {
        return floor.adUnit.name;
    }
    return NONE;
};

const getFloorPriority = (floor: Floor) => {
    if (floor.adUnit) {
        return 4;
    }
    if (floor.supply) {
        return 3;
    }
    if (floor.brand) {
        return 2;
    }
    if (floor.publisher) {
        return 1;
    }
    if (floor.seat) {
        return 0;
    }
    return -1;
};

export const compareFloorsByPriority = (a: Floor, b: Floor) => getFloorPriority(a) - getFloorPriority(b);

export const getCountryGeo = (floor: Floor | null | undefined) => {
    if (!floor || !floor.targeting.length) {
        return null;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return floor.targeting[0].include.geoTargets[0];
};

export const getFloorCountryLabel = (floor?: Floor | null) => {
    const geo = getCountryGeo(floor);

    if (!geo) {
        return "All Geos";
    }

    return geo.geo.countryName;
};

export const getCountryValue = (geo?: GeoTarget | null): LabeledValue | null => {
    if (!geo) {
        return null;
    }
    return { value: geo.geo.id, label: geo.geo.countryName };
};

export const getTargetingFromCountry = (country: LabeledValue | null) => {
    if (!country) {
        return null;
    }

    return [
        {
            include: {
                geoTargets: [
                    {
                        geo: {
                            id: Number(country.value),
                        },
                        type: {
                            id: 1,
                            name: "Country",
                        },
                    },
                ],
            },
        },
    ] as unknown as Targeting[]; // TODO: Safe, but we should probably update the payload type
};

const ALL_GEOS = 0;
export const isActualPrice = (floor: Floor, floors: Floor[]) => {
    const highestFloorByGeo = floors.reduce((acc, floor) => {
        const geo = getCountryGeo(floor);
        if (!geo) {
            if (!acc[ALL_GEOS]) {
                acc[ALL_GEOS] = floor;
            } else {
                acc[ALL_GEOS] = floor.price > acc[ALL_GEOS].price ? floor : acc[ALL_GEOS];
            }
            return acc;
        }
        if (!acc[geo.geo.id]) {
            acc[geo.geo.id] = floor;
        } else {
            acc[geo.geo.id] = floor.price > acc[geo.geo.id].price ? floor : acc[geo.geo.id];
        }
        return acc;
    }, {});

    const geo = getCountryGeo(floor);
    return highestFloorByGeo[geo?.geo.id || ALL_GEOS]?.id === floor.id;
};
