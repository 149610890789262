import { ChangeEvent, FC, useEffect, useState } from "react";
import { Input } from "antd";
import { currency as currencyUtils, formatNumber } from "@rubicon/utils";

interface Props {
    currency?: string;
    value?: number;
    onChange?: (value: number) => void;
}

const TO_FIXED = 2;

export const getAddOnContent = (currency: string, currencyWithSymbol = true) => {
    const info = currencyUtils.getCurrencyInfo(currency);
    return currencyWithSymbol ? [info.symbol] : [info.unit];
};

export const getValidValue = (value: string | number | undefined): number => {
    const number = Number(parseFloat(value as string).toFixed(TO_FIXED));
    const isInvalidNumber = Number.isNaN(number);

    if (isInvalidNumber) {
        return 0;
    }

    return number;
};

export const CurrencyInput: FC<Props> = ({ currency = "AUD", value, onChange, ...rest }) => {
    const [stateValue, setStateValue] = useState(String(value));
    const [isFocused, setIsFocused] = useState(false);
    const [addOnContent] = getAddOnContent(currency, isFocused);

    const handleFocus = () => {
        setIsFocused(true);
    };

    useEffect(() => {
        setStateValue(String(value));
    }, [value]);

    const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
        const validValue = getValidValue(e.target.value);
        setIsFocused(false);
        setStateValue(String(validValue));
        onChange?.(validValue);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setStateValue(e.target.value);
    };

    return (
        <Input
            {...rest}
            prefix={addOnContent}
            style={{ width: "100%" }}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            value={isFocused ? stateValue : formatNumber.asMoney(value, currency)}
        />
    );
};
