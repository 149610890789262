import styled from "@emotion/styled";
import { Button, Space, Typography } from "antd";
import { useAddLabel } from "./useAddLabel";
import { PlusOutlined } from "@ant-design/icons";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Label } from "@magnite/client-streaming-platform";
import { FilterFilled } from "@ant-design/icons";

const StyledSpace = styled(Space)`
    width: 100%;
    .ant-space-item:nth-of-type(odd) {
        width: 100%;
    }
`;

export const AddLabelRow = () => {
    const {
        selectedLabelValue,
        selectedLabel,
        setSelectedLabel,
        setSelectedLabelValue,
        isLabelValuesLoading,
        isLabelsOptionsLoading,
        labels,
        labelValues,
        handleAddLabel,
    } = useAddLabel();
    return (
        <StyledSpace direction="horizontal" style={{ marginBottom: 8 }}>
            <SelectWithValue<Label>
                loading={isLabelsOptionsLoading}
                options={labels}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange={setSelectedLabel}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value={selectedLabel}
                fieldAsLabel={(label) => (
                    <>
                        {label.enablePrefilter && <FilterFilled />} {label.key}
                    </>
                )}
                fieldAsValue={["id"]}
                placeholder="Select Label..."
            />
            <Typography.Text strong>:</Typography.Text>
            <SelectWithValue
                loading={isLabelValuesLoading}
                options={labelValues}
                disabled={!selectedLabel}
                onChange={setSelectedLabelValue}
                value={selectedLabelValue}
                fieldAsLabel={["value"]}
                fieldAsValue={["id"]}
                placeholder="Select Value..."
            />
            <Button
                type="link"
                onClick={handleAddLabel}
                block
                icon={<PlusOutlined />}
                disabled={!selectedLabelValue}
                style={{ paddingLeft: 0 }}
            >
                Add Label
            </Button>
        </StyledSpace>
    );
};
