import { useHistory, useParams } from "react-router-dom";
import { useNotificationError } from "@app/core/components/hooks";
import { Loading } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ClassificationsLabelDefinitionsButtonAndDrawer } from "./ClassificationsLabelsTab/ClassificationsLabelDefinitions";
import { useGetSeatLabelDetailsQuery } from "@magnite/client-streaming-platform";
import { ClassificationsLabelEditForm } from "./ClassificationsLabelForm/ClassificationsLabelEditForm/ClassificationsLabelEditForm";
import { PageHeader } from "@ant-design/pro-layout";

export const ClassificationsLabelEditPage = () => {
    const { seatId, labelId } = useParams<{ seatId: string; labelId: string }>();

    const { data: label, isLoading, error } = useGetSeatLabelDetailsQuery(Number(labelId));

    useNotificationError(error);

    const history = useHistory();
    if (isLoading) return <Loading />;

    if (!label) return null;

    return (
        <>
            <PageHeader
                onBack={() =>
                    history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_LABEL_DETAILS_PAGE(seatId, labelId))
                }
                title={label.key}
                extra={<ClassificationsLabelDefinitionsButtonAndDrawer />}
            />
            <ClassificationsLabelEditForm label={label} />
        </>
    );
};
