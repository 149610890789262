import { message } from "antd";
import { useUpdateUserPreferencesMutation, UserPreference, useLazyGetUserPreferencesQuery } from "@app/core/services";
import { useSeatAuthContext } from "@app/core/auth";

export const useUpdateSettings = () => {
    const { session } = useSeatAuthContext();
    const [getCurrentSettings] = useLazyGetUserPreferencesQuery();
    const [updateUserPreferences] = useUpdateUserPreferencesMutation();

    const handleUpdateUserPreferences = async (newSettings: Partial<Record<UserPreference["key"], string>>) => {
        if (!session) {
            return;
        }

        const settings = await getCurrentSettings(session.user.id).unwrap();

        const formattedCurrentSettings = settings.map((setting) => ({ key: setting.key, value: setting.value }));

        for (const [key, value] of Object.entries(newSettings)) {
            const setting = formattedCurrentSettings.find((s) => s.key === key);
            if (setting) {
                setting.value = value;
            } else {
                formattedCurrentSettings.push({ key: key as UserPreference["key"], value });
            }
        }

        try {
            await updateUserPreferences({ id: session.user.id, body: formattedCurrentSettings });
        } catch (err) {
            message.error("Failed to Update Dashboard Settings");
        }
    };

    return handleUpdateUserPreferences;
};
