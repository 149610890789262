import { useMemo } from "react";
import { WaterfallPacing, useGetWaterfallPacingQuery, useGetSeatAdSourcesQuery } from "@app/core/services/console";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { selectSeatAdSourcesPacingPagination, setPagination } from "../reducer";
import { useSeatAdSourcesPacingTableFilters } from "../SeatAdSourcesPacingTableFilters/useSeatAdSourcesPacingTableFilters";
import { PacingTypes } from "../../constants";

interface UseSeatAdSourcesPacingTable {
    data: WaterfallPacing[];
    isFetching: boolean;
    pageSize: number;
    current: number;
    total: number;
    onPaginationChange: (page: number, max: number) => void;
}

export const useSeatAdSourcesPacingTable = (): UseSeatAdSourcesPacingTable => {
    const dispatch = useAppDispatch();
    const { seatAllAdSourcesParams, seatWaterfallPacingParams } = useSeatAdSourcesPacingTableFilters();
    const { data: pacingData, isFetching: pacingIsFetching } = useGetWaterfallPacingQuery(seatWaterfallPacingParams);
    const { data: adSourcesData, isFetching: adSourcesIsFetching } = useGetSeatAdSourcesQuery(seatAllAdSourcesParams);
    const labelKeyFilter = useAppSelector((state) => state.seatAdSourcesPacing.filters.seatAdSourcesLabelKey);
    const statusFilter = useAppSelector((state) => state.seatAdSourcesPacing.filters.seatAdSourcesStatus);
    const pagination = useAppSelector(selectSeatAdSourcesPacingPagination);

    const adSourceIdsSet = useMemo(
        () => (adSourcesData ? new Set(adSourcesData.adSources.map(({ id }) => id)) : null),
        [adSourcesData]
    );

    const isLabelFilterEnabled = Boolean(labelKeyFilter);
    const isStatusFilterEnabled = Boolean(statusFilter?.length);

    const filteredData = useMemo(
        () =>
            (pacingData?.results && adSourcesData && !adSourcesIsFetching ? pacingData.results : []).filter(
                ({ adSourceId, pacingType, pacingProgress }) =>
                    pacingType !== PacingTypes.NONE &&
                    (!isLabelFilterEnabled || adSourceIdsSet?.has(adSourceId)) &&
                    (!isStatusFilterEnabled ||
                        statusFilter?.some((status) => status.value === pacingProgress?.pacingStatus?.id))
            ),
        [
            pacingData,
            adSourcesData,
            adSourcesIsFetching,
            isLabelFilterEnabled,
            adSourceIdsSet,
            isStatusFilterEnabled,
            statusFilter,
        ]
    );

    const onPaginationChange = (page: number, max: number) => {
        dispatch(setPagination({ page, max }));
    };

    return {
        data: filteredData,
        isFetching: pacingIsFetching || adSourcesIsFetching,
        total: pacingData?.totalResults ?? 0,
        current: pagination.page,
        pageSize: pagination.max,
        onPaginationChange,
    };
};
