import { FC } from "react";
import { LiveStatsTableAdSource } from "@app/core/services";
import { Popover, Col, Row, Typography } from "antd";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/types";
import { Dashes } from "@app/core/components";
import { brandCobalt } from "@rubicon/antd-components";

type Props = {
    adSources: LiveStatsTableAdSource[];
    openDrawer: (adSourceId: number, secondaryDetailsView?: SecondaryDetailsView) => void;
};

type PopoverProps = {
    adSources: LiveStatsTableAdSource[];
    handleOpenAdSourceDrawer: (number) => void;
};

const PopoverContent: FC<PopoverProps> = ({ adSources, handleOpenAdSourceDrawer }) => {
    return (
        <>
            <Typography.Paragraph>
                The deal is configured to multiple ad sources with <br />
                different priorities <br />
            </Typography.Paragraph>
            <Row>
                <Col span={20}>Name</Col>
                <Col span={4}>Priority</Col>
            </Row>
            {adSources.map((asp) => (
                <Row key={asp.adSourceId}>
                    <Col span={20}>
                        <a
                            style={{ textTransform: "capitalize", color: brandCobalt }}
                            onClick={() => {
                                handleOpenAdSourceDrawer(asp.adSourceId);
                            }}
                        >
                            {asp.name}
                        </a>
                    </Col>

                    <Col span={4}>{asp.priority}</Col>
                </Row>
            ))}
        </>
    );
};

const DealPriority: FC<Props> = ({ adSources, openDrawer }) => {
    const adSourcePrioritySort: LiveStatsTableAdSource[] = [...(adSources || [])].sort(
        (a, b) => parseInt(a.priority) - parseInt(b.priority)
    );

    if (!adSources) {
        return <Dashes />;
    }

    return adSources.length > 1 ? (
        <>
            <Popover
                title="Multiple Priorities"
                content={<PopoverContent adSources={adSourcePrioritySort} handleOpenAdSourceDrawer={openDrawer} />}
            >
                <span style={{ color: brandCobalt }}>{adSourcePrioritySort[0].priority}, more</span>
            </Popover>
        </>
    ) : (
        <span>{adSources[0]?.priority}</span>
    );
};

export default DealPriority;
