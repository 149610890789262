import { FC } from "react";
import { Col, Row, Typography } from "antd";
import { LiveStatsTableAdSource } from "@app/core/services";
import { getCPMRateLabel } from "@app/features/deals/helpers";
import { DEAL_TYPE_AUCTION_PRICE_DEAL } from "@app/features/deals/constants";
import { Dashes } from "@app/core/components";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/types";
import { getDisplayValue } from "@app/features/deals/DealFloorPrices/DealFloorPrices";
import { DealFloorPrices } from "./DealFloorPrices";

interface DealHealthTableCpmRateCellProps {
    dealType: {
        id: number;
        name: string;
    };
    rate: number;
    currencyType: {
        id: number;
        code: string;
    };
    adSources: LiveStatsTableAdSource[];
    openDrawer: (adSourceId: number, secondaryDetailsView?: SecondaryDetailsView) => void;
}

export const DealHealthTableCpmRateCell: FC<DealHealthTableCpmRateCellProps> = ({
    dealType,
    rate,
    currencyType,
    adSources,
    openDrawer,
}) => {
    const auctionPriceDeal: boolean = dealType?.id == DEAL_TYPE_AUCTION_PRICE_DEAL.id;

    if (!rate) {
        return <Dashes />;
    }

    return (
        <Row>
            <Col span={24}>
                <Typography.Text>
                    {auctionPriceDeal ? (
                        <DealFloorPrices
                            dealAdSources={adSources}
                            currencyType={currencyType}
                            openDrawer={openDrawer}
                        />
                    ) : (
                        <span style={{ color: "#1890FF" }}>{getDisplayValue(rate, currencyType.code)}</span>
                    )}
                </Typography.Text>
            </Col>
            <Col span={24}>
                {rate ? <Typography.Text type="secondary">{getCPMRateLabel(dealType)}</Typography.Text> : null}
            </Col>
        </Row>
    );
};
