import { FC } from "react";
import { Flex, Form } from "antd";
import { Labels } from "@app/features/inventory/components/Fields/Labels";
import { InheritedLabels } from "@app/features/inventory/components/InheritedLabels";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { useAdUnitInheritedLabels } from "@app/features/inventory/HierarchyForms/AdUnitForm/LabelsSection/Fields/useAdUnitInheritedLabels";

interface AdUnitLabelsProps {
    labelType: "seat" | "internal";
    name: typeof AD_UNIT_FORM_FIELDS.LABELS.name | typeof AD_UNIT_FORM_FIELDS.INTERNAL_LABELS.name;
    label: typeof AD_UNIT_FORM_FIELDS.LABELS.label | typeof AD_UNIT_FORM_FIELDS.INTERNAL_LABELS.label;
}

export const AdUnitLabels: FC<AdUnitLabelsProps> = ({ name, label, labelType }) => {
    const { isInheritedLabelsShown, labels } = useAdUnitInheritedLabels({ labelType });
    return (
        <Flex vertical>
            <Form.Item label={label} wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 }}>
                <Labels name={name} />
            </Form.Item>
            {isInheritedLabelsShown && <InheritedLabels type={labelType} labels={labels} />}
        </Flex>
    );
};
