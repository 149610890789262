import { FC, Key, useCallback, useEffect, useState } from "react";
import { Button, Form, Space, Table, notification } from "antd";
import { EditableCell } from "../SeatFloorsTable/EditableCell";
import {
    Floor,
    FloorCreatePayload,
    FloorUpdatePayload,
    useCreateAdUnitFloorMutation,
    useCreateBrandFloorMutation,
    useCreatePublisherFloorMutation,
    useCreateSupplyFloorMutation,
    useUpdateFloorMutation,
} from "@app/core/services";
import { COLUMN_KEY } from "../InventoryFloorsPage/constants";
import { CurrencyInput } from "@app/core/components/Fields/CurrencyInput";
import {
    getCountryGeo,
    getCountryValue,
    getFloorCountryLabel,
    getFloorPriceLabel,
    getFloorSource,
    getFloorSourceLabel,
    getTargetingFromCountry,
    isActualPrice,
    SourceSelf,
} from "../helpers";
import { ActualPriceLabel } from "../../ActualPriceLabel";
import { CountryField } from "../FloorFormFields";
import { SeatFloorsTableActions } from "../SeatFloorsTable/SeatFloorsTableActions";
import { format } from "@rubicon/utils";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useFloorSource } from "../FloorForm/useFloorSource";
import { StyledReactLink } from "@app/core/components";
import { useSeatAuthContext } from "@app/core/auth";

export interface FloorTableRecord extends Floor {
    key: string;
    editable?: boolean;
    floor: Floor;
}

interface Props {
    data: Floor[];
    isFetching: boolean;
    isCreating: boolean;
    onCancelCreating: () => void;
    sourceSelf: SourceSelf;
}

const getFloorInheritedFromLink = (floor: Floor, sourceSelf: "Seat" | "Publisher" | "Brand" | "Supply" | "Ad Unit") => {
    const floorSource = getFloorSource(floor);

    if (floorSource === sourceSelf) {
        return format.constants.DEFAULT_DASHES;
    }

    if (floorSource === "Seat") {
        return (
            <StyledReactLink
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                to={ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_HIERARCHICAL(floor.seat?.id)}
            >
                {floor.seat?.name}
            </StyledReactLink>
        );
    }

    if (floorSource === "Publisher" || floorSource === "Channel") {
        return (
            <StyledReactLink
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                to={ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_PUBLISHER(floor.publisher?.seat.id, floor.publisher?.id)}
            >
                {floor.publisher?.name}
            </StyledReactLink>
        );
    }

    if (floorSource === "Brand" || floorSource === "Brand Channel") {
        return (
            <StyledReactLink
                to={ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_PUBLISHER_BRAND(
                    floor.brand?.publisher?.seat.id as number,
                    floor.brand?.id as number
                )}
            >
                {floor.brand?.name}
            </StyledReactLink>
        );
    }

    if (floorSource === "Supply") {
        return (
            <StyledReactLink
                to={ROUTE_FORMATTERS.SEAT_INVENTORY_SUPPLY_FLOORS(
                    floor.supply?.brand?.publisher?.seat.id as number,
                    floor.supply?.id as number
                )}
            >
                {floor.supply?.name}
            </StyledReactLink>
        );
    }

    if (floorSource === "Ad Unit") {
        return floor.adUnit?.name;
    }

    return format.constants.DEFAULT_DASHES;
};

export const getTableItems = (sourceSelf, data?: Array<Floor>) =>
    data?.map((floor) => ({
        key: floor?.id,
        [COLUMN_KEY.PRICE]: floor.price / 1000,
        [COLUMN_KEY.COUNTRY]: getCountryValue(getCountryGeo(floor)),
        [COLUMN_KEY.SOURCE]: getFloorSourceLabel(floor, sourceSelf),
        [COLUMN_KEY.INHERTIED_FROM]: getFloorInheritedFromLink(floor, sourceSelf),
        [COLUMN_KEY.ACTUAL_PRICE]: <ActualPriceLabel isActualPrice={isActualPrice(floor, data)} />,
        floor,
    }));

export const FloorsTable: FC<Props> = ({ data = [], isFetching, isCreating = false, onCancelCreating, sourceSelf }) => {
    const { context } = useSeatAuthContext();
    const [form] = Form.useForm();
    const [floorOnEditing, setFloorOnEditing] = useState<(Partial<FloorTableRecord> & { key: Key }) | null>(null);
    const [editingKey, setEditingKey] = useState<Key | null>(null);
    const [updateFloor, { isLoading: isSaving }] = useUpdateFloorMutation();
    const [mode, setMode] = useState("create");
    const floorSource = useFloorSource();
    const [floors, setFloors] = useState<Floor[]>([]);
    const [createAdUnitFloor, { isLoading: isAdUnitFloorSaving }] = useCreateAdUnitFloorMutation();
    const [createSupplyFloor, { isLoading: isSupplyFloorSaving }] = useCreateSupplyFloorMutation();
    const [createBrandFloor, { isLoading: isBrandFloorSaving }] = useCreateBrandFloorMutation();
    const [createPublisherFloor, { isLoading: isPublisherFloorSaving }] = useCreatePublisherFloorMutation();

    const cancel = useCallback(() => {
        setEditingKey(null);
        if (isCreating) {
            onCancelCreating();
        }
    }, [isCreating, onCancelCreating]);

    useEffect(() => {
        const floorList: Floor[] = [...data];
        if (isCreating) {
            setMode("create");
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            floorList.push({ id: 0, price: 0, targeting: [] });
            setEditingKey(0);
            form.setFieldsValue({
                [COLUMN_KEY.PRICE]: 0,
                [COLUMN_KEY.COUNTRY]: null,
            });
        }
        setFloors(floorList);

        return () => {
            cancel();
        };
    }, [data, isCreating, form, cancel]);

    const isEditing = (record: FloorTableRecord) => record.key === editingKey;
    const isFloorCreateLoading =
        isAdUnitFloorSaving || isSupplyFloorSaving || isBrandFloorSaving || isPublisherFloorSaving;

    const editFloor = async (originalFloor: FloorTableRecord, newFloor) => {
        const body: FloorUpdatePayload = {
            ...originalFloor.floor,
            price: newFloor.price * 1000,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            targeting: getTargetingFromCountry(newFloor.country),
        };
        try {
            await updateFloor({ id: originalFloor.floor.id, body }).unwrap();
            notification.success({ message: "Floor updated successfully" });
        } catch (error) {
            notification.error({ message: `Operation has failed ${error.data.errorDescription}` });
        }
    };

    const edit = (record: Partial<FloorTableRecord> & { key: Key }) => {
        setMode("edit");
        setFloorOnEditing(record);
        form.setFieldsValue({
            [COLUMN_KEY.PRICE]: record[COLUMN_KEY.PRICE],
            [COLUMN_KEY.COUNTRY]: record[COLUMN_KEY.COUNTRY]?.value
                ? { value: record[COLUMN_KEY.COUNTRY]?.value }
                : null,
        });
        setEditingKey(record.key);
    };

    const save = async () => {
        if (mode === "edit") {
            await editFloor(floorOnEditing as FloorTableRecord, form.getFieldsValue());
        }
        if (mode === "create") {
            const newFloor = form.getFieldsValue();
            const body: FloorCreatePayload = {
                ...floorSource,
                price: newFloor.price * 1000,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                targeting: getTargetingFromCountry(newFloor.country),
            };
            try {
                if (floorSource.adUnit) {
                    await createAdUnitFloor({ adUnitId: floorSource.adUnit.id, body }).unwrap();
                } else if (floorSource.supply) {
                    await createSupplyFloor({ supplyId: floorSource.supply.id, body }).unwrap();
                } else if (floorSource.brand) {
                    await createBrandFloor({ brandId: floorSource.brand.id, body }).unwrap();
                } else if (floorSource.publisher) {
                    await createPublisherFloor({ publisherId: floorSource.publisher.id, body }).unwrap();
                }
                notification.success({ message: "Floor created successfully" });
            } catch (err) {
                notification.error({ message: err.data.errorDescription });
            }

            form.resetFields();
            onCancelCreating();
        }
        setEditingKey(null);
    };

    const columns = [
        {
            dataIndex: COLUMN_KEY.PRICE,
            title: "Floor Price",
            editable: true,
            render: (_, record: FloorTableRecord) => getFloorPriceLabel(record.floor, context?.floorCurrency?.code),
            renderInputNode: () => <CurrencyInput currency={context?.floorCurrency?.code} />,
        },
        {
            dataIndex: COLUMN_KEY.COUNTRY,
            title: "Country",
            editable: true,
            render: (_, record: FloorTableRecord) => getFloorCountryLabel(record.floor),
            renderInputNode: () => <CountryField label={null} margin={0} />,
        },
        {
            dataIndex: COLUMN_KEY.SOURCE,
            title: "Source",
        },
        {
            dataIndex: COLUMN_KEY.INHERTIED_FROM,
            title: "Inherited From",
        },

        {
            dataIndex: COLUMN_KEY.ACTUAL_PRICE,
            title: "Custom Price?",
        },
        {
            dataIndex: COLUMN_KEY.ACTIONS,
            title: "Actions",
            render: (_, record: FloorTableRecord) => {
                return !isEditing(record) ? (
                    <SeatFloorsTableActions floor={record.floor} onEdit={() => edit(record)} />
                ) : (
                    <Space>
                        <Button data-sdet="" onClick={cancel} disabled={isSaving || isFloorCreateLoading}>
                            Cancel
                        </Button>
                        <Button data-sdet="" type="primary" onClick={save} loading={isSaving || isFloorCreateLoading}>
                            Save
                        </Button>
                    </Space>
                );
            },
        },
    ];

    const mapColumns = (col) => {
        if (!col.editable) {
            return col;
        }
        const newCol = {
            ...col,
            onCell: (record: FloorTableRecord) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                renderInputNode: col.renderInputNode,
                inputNodeValuePropName: col.inputNodeValuePropName,
            }),
        };

        if (col.children) {
            newCol.children = col.children.map(mapColumns);
        }

        return newCol;
    };

    const mergedColumns = columns.map(mapColumns);

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                columns={mergedColumns}
                showSorterTooltip={false}
                loading={isFetching}
                pagination={false}
                dataSource={getTableItems(sourceSelf, floors)}
            />
        </Form>
    );
};
