import { useAppDispatch, useAppSelector } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import { setFiltersSeatAdSourcesRegion } from "../../reducer";
import { useGetRegionsQuery } from "@magnite/client-streaming-platform";
import { useMemo } from "react";

interface UseSeatAdSourcesRegionFilter {
    handleChangeValue: (value: string | number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue[] | string | null;
    isLoading: boolean;
}

export const useSeatAdSourcesRegionFilter = (): UseSeatAdSourcesRegionFilter => {
    const dispatch = useAppDispatch();
    const seatAdSourcesRegion = useAppSelector((state) => state.seatAdSourcesList.filters.seatAdSourcesRegion);
    const { data, isFetching } = useGetRegionsQuery();
    const options = useMemo(() => (data || []).map((filter) => ({ label: filter.name, value: filter.id })), [data]);

    const handleChangeValue = (value: string | number | undefined, option: LabeledValue[]) => {
        dispatch(setFiltersSeatAdSourcesRegion(option));
    };

    return {
        handleChangeValue,
        options: options,
        value: seatAdSourcesRegion,
        isLoading: isFetching,
    };
};
