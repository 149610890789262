import { FC } from "react";
import { Tag } from "antd";

type Props = {
    status: {
        id: number;
        name: string;
    };
};
export const DEAL_STATUS_ID = {
    ACTIVE: 1,
    PAUSED: 2,
    UNDER_PERFORMING: 3,
    AUTO_PAUSED: 4,
};

const getDealStatusBadgeColor = (id: number): string | undefined => {
    switch (id) {
        case DEAL_STATUS_ID.ACTIVE:
            return "success";
        case DEAL_STATUS_ID.PAUSED:
            return "default";
        case DEAL_STATUS_ID.UNDER_PERFORMING:
        case DEAL_STATUS_ID.AUTO_PAUSED:
            return "warning";
    }
};

const DealStatusBadge: FC<Props> = ({ status }) => {
    return <Tag color={getDealStatusBadgeColor(status?.id)}>{status?.name}</Tag>;
};

export default DealStatusBadge;
