import { TargetingCreateIncludeExclude } from "@app/core/services";
import { isNull } from "lodash";
import { TargetingDimensionTypes } from "../constants";
import { SeatSegment } from "@magnite/client-streaming-platform";

const validateDaypartingAndTimeZone = (
    key: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    hasDaypartTargets: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
    //INFO:1 case for DayPartTargets
    if (key === TargetingDimensionTypes.DayPartTargets) {
        if (value.length) {
            return value;
        }
    }
    //INFO:2 case for DayPartTargets but for timeZoneMode
    //if no dayPartTargets we should omit timeZoneMode as well
    if (key === TargetingDimensionTypes.TimeZoneMode) {
        if (hasDaypartTargets) {
            return value;
        }
    }
    return null;
};

function filterIncludeExcludeValues(
    keys: string[],
    obj: Record<string, unknown>,
    handleDefault: (acc: TargetingCreateIncludeExclude, key: string, value: unknown) => void
): TargetingCreateIncludeExclude {
    //INFO: All base cases for filtering should be here for include and exclude
    // edge cases should be handled in `handleDefault` function
    return keys.reduce((acc, key) => {
        const value = obj[key];

        // INFO: case for DayPartTargets and TimeZoneMode
        if (key === TargetingDimensionTypes.DayPartTargets || key === TargetingDimensionTypes.TimeZoneMode) {
            const validValue = validateDaypartingAndTimeZone(
                key,
                value,
                acc.hasOwnProperty(TargetingDimensionTypes.DayPartTargets)
            );

            if (!isNull(validValue)) {
                acc[key] = validValue;
            }
            return acc;
        }

        //INFO: case for DMP simple, Segments - OR for targetedSegments!!!
        if (key === TargetingDimensionTypes.Segments || key === "targetedSegments") {
            if ((value as SeatSegment[]).length) {
                acc[key] = value as SeatSegment[];
            }
            return acc;
        }

        //INFO: case for DMP SegmentRules ONLY advanced
        if (key === TargetingDimensionTypes.SegmentRules) {
            const parsedValue: { rules: Array<{ value: string | null }> } = JSON.parse(value as string);

            if (parsedValue?.rules?.some((rule) => !isNull(rule.value))) {
                acc[key] = value as string[] | null | undefined;
            }
            return acc;
        }

        // Delegate to the passed-in default handler
        handleDefault(acc, key, value);
        return acc;
    }, {} as TargetingCreateIncludeExclude);
}

export const filterInclude = (
    includeKeys: string[],
    include: Record<string, unknown>
): TargetingCreateIncludeExclude => {
    return filterIncludeExcludeValues(includeKeys, include, (acc, key, value) => {
        // For include: simply assign the value if it's not filtered out above
        acc[key] = value;
    });
};

export const filterExclude = (
    excludeKeys: string[],
    exclude: Record<string, unknown>
): TargetingCreateIncludeExclude => {
    return filterIncludeExcludeValues(excludeKeys, exclude, (acc, key, value) => {
        // For exclude: simply assign the value if it's not filtered out above
        acc[key] = value;
    });
};
export enum TargetingIncludeExclude {
    Include = "include",
    Exclude = "exclude",
}
export const filterIncludeExclude = (
    type: TargetingIncludeExclude,
    keys: string[],
    acc: Record<string, unknown>
): TargetingCreateIncludeExclude => {
    return type === TargetingIncludeExclude.Include ? filterInclude(keys, acc) : filterExclude(keys, acc);
};
