import { Col, Grid, Row } from "antd";

export const AdSourceTermsResponsiveLayout = ({ name, status, description, leftCol, rightCol }) => {
    const { xl } = Grid.useBreakpoint();

    if (!xl) {
        return (
            <Row gutter={16}>
                <Col span={12}>{name}</Col>
                <Col span={12}>{status}</Col>
                <Col span={24}>{description}</Col>
                <Col span={12}>{leftCol}</Col>
                <Col span={12}>{rightCol}</Col>
            </Row>
        );
    }

    return (
        <Row gutter={16}>
            <Col span={8}>
                {name}
                {leftCol}
            </Col>
            <Col span={8}>
                {status}
                {rightCol}
            </Col>
            <Col span={8}>{description}</Col>
        </Row>
    );
};
