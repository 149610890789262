import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useGetDealStatusQuery } from "@app/core/services";
import { DEAL_HEALTH_QUERY_PARAM, useDealHealthListParam } from "../useDealHealthTableFilters";

interface UseStatusFilter {
    handleChange: (value: number[], option: LabeledValue[]) => void;
    options: LabeledValue[];
    isFetching: boolean;
    value: number[];
    areAllOptionsSelected: boolean;
    toggleSelectDeselectAll: () => void;
}

export const useInternalStatusFilter = (): UseStatusFilter => {
    const { data, isFetching } = useGetDealStatusQuery();
    const { value: status, setValue: setFiltersDealHealthStatus } = useDealHealthListParam(
        DEAL_HEALTH_QUERY_PARAM.FILTER_INTERNAL_STATUS
    );

    const options = useMemo(
        () =>
            (data || []).map((status) => ({
                value: status.id,
                label: status.name,
            })),
        [data]
    );

    const handleChange = (value: number[], option: LabeledValue[]) => {
        setFiltersDealHealthStatus(option.map((item) => `${item.value}`));
    };

    const areAllOptionsSelected = options.length === status?.length;

    const toggleSelectDeselectAll = () => {
        if (areAllOptionsSelected) {
            handleChange(status, []);
        } else {
            handleChange(status, options);
        }
    };

    return {
        options,
        handleChange,
        isFetching,
        value: status,
        areAllOptionsSelected,
        toggleSelectDeselectAll,
    };
};
