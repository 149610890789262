import { Form } from "antd";
import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useGetAdSourcesTypeQuery } from "@app/core/services";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { AdSourceCountLimitOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/useExchangeProtectionsForm";

const { useWatch } = Form;

interface UseTypeSelect {
    options: LabeledValue[];
    isFetching: boolean;
}

export const useTypeSelect = (): UseTypeSelect => {
    const { data = [], isFetching } = useGetAdSourcesTypeQuery();
    const adSourceCountLimitsOptions: AdSourceCountLimitOption[] =
        useWatch(INVENTORY_FORM_FIELDS.COUNT_LIMITS.name) ?? [];
    const selectedTypes: number[] = adSourceCountLimitsOptions.map(
        (option) => option?.adSourceCountLimit?.adSourceType?.id
    );
    const options: LabeledValue[] = useMemo(
        () =>
            (data || []).map((type) => ({
                value: type.id,
                label: type.name,
                disabled: selectedTypes.includes(type.id),
            })),
        [data, selectedTypes]
    );
    return {
        isFetching,
        options,
    };
};
