import { useGetSeatFinanceDefaultsQuery } from "@app/core/services";
import {
    DefaultCostModel,
    getDefaultCostModel,
} from "@app/features/seatAdSources/SeatAdSourcesForm/utils/getDefaultCostModel";
import { GUTTER, COL_SIZES } from "@app/features/deals/constants";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { Col, Collapse, Form, Row } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AdSourceFormsContext, AdSourcesForm } from "../../SeatAdSourcesForm";
import { CostModelSelect, CostInput, AllowAutoscaleRadio, RegionSelect, AssociatedGlobalIDGSelect } from "./Fields";
import { classLilstOptionsSection } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections";
import { adminInternalSectionClassList } from "@app/features/deals/DealForm";
import { cx } from "@emotion/css";
import { useUserAccess } from "@app/core/auth";

export const AdminSettings = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const adSourceTypeId = Form.useWatch<AdSourcesForm["type"]>("type")?.id;
    const costModelId = Form.useWatch<AdSourcesForm["costModel"]>("costModel")?.id;
    const { data: financeDefaults } = useGetSeatFinanceDefaultsQuery(Number(seatId));
    const {
        forms: {
            adSourceFormMode,
            adSourceForm: { setFieldValue },
        },
    } = useContext(AdSourceFormsContext);
    const { hasInternalAccess } = useUserAccess();
    const [currentDefault, setCurrentDefault] = useState<DefaultCostModel | null>(null);
    const defaultingEventRef = useRef<{
        isInitialized: boolean;
        adSourceTypeId: number | undefined;
        costModelId: number | undefined;
    }>({
        isInitialized: false,
        adSourceTypeId: undefined,
        costModelId: undefined,
    });

    useEffect(() => {
        if (adSourceFormMode !== "create" || !financeDefaults) {
            return;
        }

        const financeDefault = getDefaultCostModel(adSourceTypeId, financeDefaults);
        if (!financeDefault) {
            return;
        }

        const isInitialization = !defaultingEventRef.current.isInitialized;
        const isAdSourceTypeChange = adSourceTypeId !== defaultingEventRef.current.adSourceTypeId;
        const isCostModelChange = costModelId !== defaultingEventRef.current.costModelId;

        if (isInitialization || isAdSourceTypeChange) {
            setFieldValue(AD_SOURCE_FIELDS.COST_MODEL.name, financeDefault.costModel);
            setFieldValue(AD_SOURCE_FIELDS.COST_PERCENT.name, financeDefault.costValuePercent);
            setFieldValue(
                AD_SOURCE_FIELDS.COST_FIXED.name,
                financeDefault.costValueFixed ? financeDefault.costValueFixed / 1000 : 0
            );
        } else if (isCostModelChange) {
            setFieldValue(AD_SOURCE_FIELDS.COST_PERCENT.name, financeDefault.costValuePercent);
            setFieldValue(
                AD_SOURCE_FIELDS.COST_FIXED.name,
                financeDefault.costValueFixed ? financeDefault.costValueFixed / 1000 : 0
            );
        }

        setCurrentDefault(financeDefault);
        defaultingEventRef.current = { isInitialized: true, adSourceTypeId, costModelId };
    }, [adSourceFormMode, financeDefaults, adSourceTypeId, costModelId, setFieldValue]);

    return (
        <Collapse
            expandIconPosition="end"
            className={cx(classLilstOptionsSection, adminInternalSectionClassList)}
            ghost
        >
            <Collapse.Panel
                key="admin"
                header={<span style={{ fontSize: "14px", color: "#3042F9", fontWeight: "400" }}>Admin Options</span>}
                forceRender
            >
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <CostModelSelect currentDefaultName={currentDefault?.costModel.name} />
                    </Col>
                    <Col {...COL_SIZES}>
                        <CostInput
                            currentDefaultId={currentDefault?.costModel.id}
                            currentDefaultValue={currentDefault?.costValueFixed || currentDefault?.costValuePercent}
                        />
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <AllowAutoscaleRadio />
                    </Col>
                    <Col {...COL_SIZES}>
                        <RegionSelect />
                    </Col>
                </Row>
                {hasInternalAccess && adSourceTypeId === AdSourceTypeIds.MARKETPLACE && (
                    <Row gutter={GUTTER}>
                        <Col {...COL_SIZES}>
                            <AssociatedGlobalIDGSelect
                                name={AD_SOURCE_FIELDS.ASSOCIATED_GLOBAL_IDG.name}
                                selectName={AD_SOURCE_FIELDS.ASSOCIATED_GLOBAL_IDG.selectName}
                            />
                        </Col>
                    </Row>
                )}
            </Collapse.Panel>
        </Collapse>
    );
};
