import { MetricsCard } from "@rubicon/antd-components";
import { Space, Typography } from "antd";
import { FC } from "react";
import { useDealCurrencyConversion } from "../../../useDealCurrencyConversion";
import { RevenueTypes } from "@app/core/clients/console";
import {
    AdStat,
    getFills,
    getImpressions,
    getOtimesForThrottleUnit,
    getTotalRevenue,
    getTries,
} from "@app/features/adStats";
import { selectAdStatDealById } from "@app/features/adStats/reducer";
import { useThrottle } from "@app/core/utils";
import { useAppSelector } from "@app/core/store";
import { formatNumber } from "@rubicon/utils";

interface DealsChartTotalsProps {
    dealId: number;
    sourceCurrencyCode: string | undefined;
    handleExtend: () => void;
    isExtended: boolean;
}

export const DealsPerformanceMetrics: FC<DealsChartTotalsProps> = ({
    dealId,
    sourceCurrencyCode,
    handleExtend,
    isExtended,
}) => {
    const { currencyConversions, currencyConversionMode, targetCurrencyCode, preferredCurrency } =
        useDealCurrencyConversion(sourceCurrencyCode);
    const revenueType = RevenueTypes.NET_REVENUE;

    const { stats, otimes } = useAppSelector((state) => {
        const adStat: AdStat | null = selectAdStatDealById(state, dealId);
        return adStat
            ? {
                  stats: {
                      tries: getTries(adStat),
                      fills: getFills(adStat, preferredCurrency, currencyConversionMode),
                      impressions: getImpressions(adStat, preferredCurrency, currencyConversionMode),
                      netRev: getTotalRevenue(
                          adStat,
                          preferredCurrency,
                          currencyConversions,
                          currencyConversionMode,
                          revenueType
                      ),
                  },
                  otimes: getOtimesForThrottleUnit(adStat),
              }
            : {
                  stats: undefined,
                  otimes: undefined,
              };
    });

    const throttledStats = useThrottle(stats, {
        forceUpdateDependencies: [
            otimes,
            sourceCurrencyCode,
            preferredCurrency,
            currencyConversionMode,
            targetCurrencyCode,
            revenueType,
        ],
    });

    const items = throttledStats
        ? [
              {
                  title: "Tries",
                  value: formatNumber.asWhole(throttledStats.tries),
              },
              {
                  title: "Fills",
                  value: formatNumber.asWhole(throttledStats.fills),
              },
              {
                  title: "Impressions",
                  value: formatNumber.asWhole(throttledStats.impressions),
              },
              {
                  title: "Net Revenue",
                  value: formatNumber.asMoney(throttledStats.netRev, targetCurrencyCode),
              },
          ]
        : [];

    return (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Typography.Text strong>Total Live Stats</Typography.Text>
            <MetricsCard
                items={items}
                handleExtend={handleExtend}
                isExtended={isExtended}
                isLoading={!throttledStats}
            />
        </Space>
    );
};
