import { MarketplaceInfo } from "@app/core/services";
import { AdSourceTypeIds, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { Button, Form, Popover, Space, TableColumnsType, Tag } from "antd";
import { FC } from "react";
import { DemandTableControls } from "../DemandTableControls";
import { DemandTableRecord } from "./getDataSource";
import { StyledLink } from "@app/core/components";

export const FrequencyCappingPopover: FC<{
    freqCapping: string;
    dealFreqCappings: MarketplaceInfo["dealFreqCappings"];
}> = ({ freqCapping, dealFreqCappings }) => {
    return (
        <Popover
            content={
                <>
                    <Form.Item
                        colon={false}
                        {...VERTICAL_LAYOUT_FIX}
                        style={{ marginBottom: 0 }}
                        label="Frequency Capping Mode"
                    >
                        {freqCapping}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        {...VERTICAL_LAYOUT_FIX}
                        style={{ marginBottom: 0 }}
                        label="Frequency Capping"
                    >
                        <Space direction="vertical">
                            {dealFreqCappings?.map(
                                (caps) => `${caps.total} ${caps.mode.name} in ${caps.timeDurationMin} minutes`
                            )}
                        </Space>
                    </Form.Item>
                </>
            }
        >
            <Button style={{ padding: 0 }} type="link">
                ON
            </Button>
        </Popover>
    );
};

export type ColumnKey =
    | "name"
    | "network"
    | "buyerSeat"
    | "rate"
    | "currency"
    | "startDate"
    | "endDate"
    | "freqCapping"
    | "targeting"
    | "internalStatus"
    | "buyerStatus"
    | "actions";

export const getTableColumns = ({
    adSourceTypeId,
    deleteByIndex,
    setEditMode,
    setViewMode,
}: {
    adSourceTypeId: number | undefined;
    deleteByIndex?: (index: number) => void;
    setEditMode?: (index: number) => void;
    setViewMode: (index: number) => void;
}): TableColumnsType<DemandTableRecord> => {
    const columns: TableColumnsType<DemandTableRecord> = [
        {
            title: "Deal Name",
            dataIndex: "name",
            key: "name",
            fixed: "left",
            width: 200,
            render: (value, row) => <StyledLink onClick={() => setViewMode(row.key)}>{value}</StyledLink>,
            sorter: true,
        },
        {
            title: "DSP",
            dataIndex: "network",
            key: "network",
            width: 300,
            defaultSortOrder: "ascend" as const,
            sorter: true,
        },
        {
            title: "Buyer Seat",
            dataIndex: "buyerSeat",
            key: "buyerSeat",
            width: 300,
            sorter: true,
        },
        {
            title: "Start Date",
            dataIndex: "startDate",
            key: "startDate",
            width: 200,
            sorter: true,
        },
        {
            title: "End Date",
            dataIndex: "endDate",
            key: "endDate",
            width: 200,
            sorter: true,
        },
        {
            title: "Time Zone",
            dataIndex: "timeZone",
            key: "timeZone",
            width: 150,
        },
        {
            title: "Freq Capping",
            dataIndex: "freqCapping",
            key: "freqCapping",
            width: 150,
            render: (capping, record) => {
                return capping === "Off" ? (
                    "OFF"
                ) : (
                    <FrequencyCappingPopover freqCapping={capping} dealFreqCappings={record.dealFreqCappings} />
                );
            },
            sorter: true,
        },
        {
            title: "Has Targeting",
            dataIndex: "targeting",
            key: "targeting",
            width: 150,
            sorter: true,
        },
        {
            title: "Deal CPM",
            dataIndex: "rate",
            key: "rate",
            width: 150,
            sorter: true,
        },
        {
            title: "Currency",
            dataIndex: "currency",
            key: "currency",
            width: 150,
        },
        {
            title: "Internal Status",
            dataIndex: "internalStatus",
            key: "internalStatus",
            width: 150,
            render: (status, record) => {
                return record?.deleted ? <Tag color="red">Deleted</Tag> : <Tag color="green">{status}</Tag>;
            },
            sorter: true,
        },
        {
            title: "Buyer Status",
            dataIndex: "buyerStatus",
            key: "buyerStatus",
            width: 200,
            sorter: true,
        },
        {
            title: "Actions",
            key: "actions",
            width: 100,
            fixed: "right",
            render: (_, row) => (
                <DemandTableControls
                    handleDelete={() => {
                        if (deleteByIndex) {
                            deleteByIndex(row.key);
                        }
                    }}
                    handleEdit={() => {
                        if (setEditMode) {
                            setEditMode(row.key);
                        }
                    }}
                />
            ),
            dataIndex: "",
        },
    ].filter((column) => {
        if (column.key === "name") {
            return true;
        }
        if (column.key === "rate") {
            return (
                adSourceTypeId &&
                [
                    AdSourceTypeIds.PROGRAMMATIC_GUARANTEED,
                    AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED,
                    AdSourceTypeIds.FIXED_PRICE,
                    AdSourceTypeIds.AUCTION_PRICE,
                ].includes(adSourceTypeId)
            );
        }
        if (column.key === "actions") {
            return deleteByIndex && setEditMode;
        }
        return adSourceTypeId !== AdSourceTypeIds.PRE_BID_DEALS;
    }) as TableColumnsType<DemandTableRecord>;

    return columns;
};
