export const MIN_RUN_TIME_IN_HOURS = 1;
export const MAX_RUN_TIME_IN_HOURS = 8;

export const FORM_ITEMS = {
    NAME: {
        name: "name",
        label: "Name",
    },
    START_DATE: {
        name: "startDate",
        label: "Start Date",
    },
    DURATION: {
        name: "duration",
        label: "Duration",
    },
    TIMEZONE: {
        name: "timeZone",
        label: "Timezone",
    },
    EST_IMP_OPPORTUNITIES: {
        name: "estimatedImpressionOpportunities",
        label: "Estimated Impressions Opportunities",
    },
    EST_AVG_CONCURRENT_VIEWERS: {
        name: "estimatedAverageConcurrentUsers",
        label: "Estimated Average Concurrent Viewers",
    },
    EST_PEAK_CONCURRENT_VIEWERS: {
        name: "estimatedPeakConcurrentUsers",
        label: "Estimated Peak Concurrent Viewers",
    },
    EST_AD_BREAK_COUNT: {
        name: "estimatedAdBreakCount",
        label: "Estimated Ad Break Count",
    },
    EST_TOTAL_AVAIL_AD_MINS: {
        name: "estimatedTotalAvailableAdMinutes",
        label: "Estimated Total Available Ad Minutes",
    },
} as const;
