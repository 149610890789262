import { Loading } from "@app/core/components";
import { useNotificationError } from "@app/core/components/hooks";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Card, Layout } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { ClassificationsLabelDetailsActions } from "./ClassificationsLabelsTab/ClassificationsLabelDetailsActions";
import { ClassificationsLabelDetailsContent } from "./ClassificationsLabelsTab/ClassificationsLabelDetailsContent";
import { ClassificationsLabelDefinitionsButtonAndDrawer } from "./ClassificationsLabelsTab/ClassificationsLabelDefinitions";
import { useGetLabelValuesQuery, useGetSeatLabelDetailsQuery } from "@magnite/client-streaming-platform";
import { PageHeader } from "@ant-design/pro-layout";

export const ClassificationsLabelDetailsPage = () => {
    const { seatId, labelId } = useParams<{ seatId: string; labelId: string }>();
    const { data: label, isLoading: isLoadingLabel, error: labelError } = useGetSeatLabelDetailsQuery(Number(labelId));
    const {
        data: labelValues,
        isLoading: isLoadingLabelValues,
        error: labelValuesError,
    } = useGetLabelValuesQuery(Number(labelId));
    useNotificationError(labelError || labelValuesError);

    const history = useHistory();
    const { canEditSeat } = useUserAccess();

    if (isLoadingLabel || isLoadingLabelValues) return <Loading />;

    if (!label || !labelValues) return null;

    return (
        <>
            <PageHeader
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_TABS(seatId, "labels"))}
                title={`Label: ${label.key}`}
                extra={<ClassificationsLabelDefinitionsButtonAndDrawer />}
            >
                {canEditSeat && <ClassificationsLabelDetailsActions label={label} />}
            </PageHeader>
            <Layout.Content>
                <Card>
                    <ClassificationsLabelDetailsContent label={label} labelValues={labelValues} />
                </Card>
            </Layout.Content>
        </>
    );
};
