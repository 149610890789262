import { FC } from "react";
import { TableSearchInput } from "@app/core/components/TableSearchInput/TableSearchInput";
import { useDealHealthTableFilterSearch } from "./useDealHealthTableFilterSearch";

const DealHealthFilterSearch: FC = () => {
    const tableSearchInputProps = useDealHealthTableFilterSearch();
    return <TableSearchInput {...tableSearchInputProps} />;
};

export default DealHealthFilterSearch;
