import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import thunk from "redux-thunk";
import { appsApi, consoleApi, diagnosticsApi, timingStatsApi, adSourceStatsApi } from "@app/core/services";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import uiReducer from "@app/core/components/Main/reducer";
import adStatsReducer from "@app/features/adStats/reducer";
import adStatsAdSourceReducer from "@app/features/adStats/adSource/reducer";
import adStatsAdSourceAdServingReducer from "@app/features/adStats/adSourceAdServing/reducer";
import authReducer from "@app/core/auth/reducer";
import customPixelsReducer from "@app/features/controls/customPixels/CustomPixelsPage/reducer";
import authClientReducer from "@app/core/authClient/reducer";
import dashboardReducer from "@app/features/dashboard/reducer";
import dealHealthDeprecatedReducer from "@app/features/deals/DealHealthPage/deprecated/reducer";
import dealsReducer from "@app/features/deals/DealListPage/reducer";
import dealFormReducer from "@app/features/deals/DealForm/reducer";
import dealDetailsDrawerReducer from "@app/features/deals/DealDetailsDrawer/reducer";
import historicalChartReducer from "@app/core/components/charts/HistoricalChart/reducer";
import inventoryHealthReducer from "@app/features/inventory/InventoryHealth/InventoryHealthPage/reducer";
import inventoryDetailsDrawerReducer from "@app/features/inventory/DetailsDrawer/reducer";
import inventoryBrandSafetyPublishersTableReducer from "@app/features/inventory/InventoryBrandSafety/InventoryBrandSafetyPage/HierarchicalPage/PublishersTable/reducer";
import inventoryBrandSafetyReducer from "@app/features/inventory/InventoryBrandSafety/reducer";
import inventoryBrandSafetyBrandReducer from "@app/features/inventory/InventoryBrandSafety/InventoryBrandSafetyPage/BrandSafetyBrandPage/reducer";
import inventoryPermissionsReducer from "@app/features/inventory/InventoryPermissions/reducer";
import targetingReducer from "@app/features/targeting/reducer";
import { manageColumnsReducer } from "@app/features/inventory/ManageColumns/reducer";
import { seatAdSourcesListReducer } from "@app/features/seatAdSources/SeatAdSourcesListPage/reducer";
import { seatAdSourcesPacingReducer } from "@app/features/seatAdSources/SeatAdSourcesPacingPage/reducer";
import { syncedFieldsReducer } from "@app/features/syncedFields/reducer";
import { consoleTagParamsReducer } from "@app/features/controls/tagParams/reducer";
import seatAdSourcesReducer from "@app/features/seatAdSources/reducer";
import { adStatsApi } from "./services/adStats/adStats";
import { inventoryFloorAdvancedReducer } from "@app/features/inventory/InventoryFloors/InventoryFloorAdvancedPage/reducer";
import { recycleBinReducer } from "@app/features/controls/recycleBin/reducer";
import bulkOperationsSlice from "@app/features/controls/bulkOperations/reducer";
import { adSourcesDetailsDrawerReducer } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/reducer";
import { seatAdSourcesPerformanceAdStatsReducer } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/SeatAdSourcesDetailsDrawerContent/SeatAdSourcesDetailsDrawerView/secondaryViews/SeatAdSourcesDetailsPerformance/performanceViews/SeatAdSourcesPerformanceAdStats/reducer";
import { seatAdSourcesPerformanceBidderStatsReducer } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/SeatAdSourcesDetailsDrawerContent/SeatAdSourcesDetailsDrawerView/secondaryViews/SeatAdSourcesDetailsPerformance/performanceViews/SeatAdSourcesPerformanceBidderStats/reducer";
import { seatAdSourcesPerformanceAdBidStatsReducer } from "@app/features/seatAdSources/SeatAdSourcesDetails/SeatAdSourcesDetailsCollapses/SeatAdSourcesPerformance/AdBidStats/reducer";
import { inventoryLiveEventsReducer } from "@app/features/inventory/InventoryLiveEvents/reducer";
import { platformApi, createPlatformApiConfig } from "@magnite/client-streaming-platform";
import conf from "@app/core/conf";

const platformApiConfig = createPlatformApiConfig({ urlRoot: conf.mctvPlatformApiRoot });

export const reducers = combineReducers({
    [platformApi.reducerPath]: platformApi.reducer,
    [platformApiConfig.name]: platformApiConfig.reducer,
    [adSourceStatsApi.reducerPath]: adSourceStatsApi.reducer,
    [appsApi.reducerPath]: appsApi.reducer,
    [consoleApi.reducerPath]: consoleApi.reducer,
    [diagnosticsApi.reducerPath]: diagnosticsApi.reducer,
    [timingStatsApi.reducerPath]: timingStatsApi.reducer,
    [adStatsApi.reducerPath]: adStatsApi.reducer,
    [bulkOperationsSlice.name]: bulkOperationsSlice.reducer,
    ui: uiReducer,
    adStats: adStatsReducer,
    adStatsAdSource: adStatsAdSourceReducer,
    adStatsAdSourceAdServing: adStatsAdSourceAdServingReducer,
    auth: authReducer,
    authClient: authClientReducer,
    customPixels: customPixelsReducer,
    consoleTagParams: consoleTagParamsReducer,
    dashboard: dashboardReducer,
    dealForm: dealFormReducer,
    dealDetailsDrawer: dealDetailsDrawerReducer,
    dealHealthDeprecated: dealHealthDeprecatedReducer,
    deals: dealsReducer,
    inventoryBrandSafety: inventoryBrandSafetyReducer,
    inventoryBrandSafetyBrand: inventoryBrandSafetyBrandReducer,
    inventoryBrandSafetyPublishersTableReducer: inventoryBrandSafetyPublishersTableReducer,
    inventoryFloorAdvanced: inventoryFloorAdvancedReducer,
    inventoryHealth: inventoryHealthReducer,
    inventoryDetailsDrawer: inventoryDetailsDrawerReducer,
    inventoryLiveEvents: inventoryLiveEventsReducer,
    inventoryPermissions: inventoryPermissionsReducer,
    manageColumns: manageColumnsReducer,
    historicalChart: historicalChartReducer,
    recycleBin: recycleBinReducer,
    seatAdSources: seatAdSourcesReducer,
    adSourcesDetailsDrawer: adSourcesDetailsDrawerReducer,
    seatAdSourcesDetailsDrawer: adSourcesDetailsDrawerReducer,
    seatAdSourcesList: seatAdSourcesListReducer,
    seatAdSourcesPerformanceAdStats: seatAdSourcesPerformanceAdStatsReducer,
    seatAdSourcesPerformanceBidderStats: seatAdSourcesPerformanceBidderStatsReducer,
    seatAdSourcesPerformanceAdBidStats: seatAdSourcesPerformanceAdBidStatsReducer,
    seatAdSourcesPacing: seatAdSourcesPacingReducer,
    syncedFields: syncedFieldsReducer,
    targeting: targetingReducer,
});

const RESET_APP_ACTION = "RESET_APP";

export const resetAppAction = () => ({ type: RESET_APP_ACTION });

export const rootReducer = (state, action) => {
    if (action.type === RESET_APP_ACTION) {
        const { apps, auth, authClient, console } = state;
        state = { apps, auth, authClient, console };
    }

    return reducers(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: [
        thunk,
        platformApi.middleware,
        adSourceStatsApi.middleware,
        adStatsApi.middleware,
        appsApi.middleware,
        consoleApi.middleware,
        diagnosticsApi.middleware,
        timingStatsApi.middleware,
    ],
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
