import { FC } from "react";
import { Row, Col, Typography } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GoToEntityLink } from "@app/core/components";
import { HelpKeysButton } from "@app/core/components/HelpKeysDrawer";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { PriceOverrideConfig } from "@app/core/services";
import { uri } from "@rubicon/utils";
import { PriceOverrideDetailsActions } from "../../PriceOverrideDetailsActions";
import { getUrlSegment } from "@app/core/utils/urlSegment";

interface Props {
    priceOverride?: PriceOverrideConfig;
}

export const PRICE_OVERRIDE_DETAILS_DRAWER_TITLE_SDET = "price-override-details-drawer-title";
export const PRICE_OVERRIDE_DETAILS_DRAWER_TITLE_NAME_SDET = "price-override-details-drawer-title-name";

export const PriceOverridesDrawerTitle: FC<Props> = ({ priceOverride }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const inIframe = window.self !== window.top;
    const detailPagePathname = ROUTE_FORMATTERS.SEAT_CONTROLS_PRICE_OVERRIDE_DETAILS_PAGE(
        seatId,
        priceOverride?.id as number
    );
    const { origin, pathname } = window.location;
    const url = new URL(`${pathname}#${detailPagePathname}`, origin);
    const copyUrl = inIframe ? getUrlSegment(url.hash) : url.href;
    const { search: queryString } = useLocation();
    const history = useHistory();

    return priceOverride ? (
        <>
            <Row data-sdet={PRICE_OVERRIDE_DETAILS_DRAWER_TITLE_SDET} gutter={[0, 16]} style={{ marginRight: "1rem" }}>
                <Col span={24}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Row gutter={[8, 4]} align="middle">
                                <Col>
                                    <Typography.Title
                                        data-sdet={PRICE_OVERRIDE_DETAILS_DRAWER_TITLE_NAME_SDET}
                                        ellipsis={{
                                            tooltip: {
                                                title: `Price Override: ${priceOverride.name}`,
                                                placement: "bottom",
                                            },
                                        }}
                                        level={5}
                                        style={{
                                            margin: 0,
                                            maxWidth: "18rem",
                                        }}
                                    >
                                        Label: {priceOverride.name}
                                    </Typography.Title>
                                </Col>
                                <Col>
                                    <GoToEntityLink
                                        entity="Price Override"
                                        linkTo={ROUTE_FORMATTERS.SEAT_CONTROLS_PRICE_OVERRIDE_DETAILS_PAGE(
                                            seatId,
                                            priceOverride.id
                                        )}
                                        copyUrl={copyUrl}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <HelpKeysButton
                                onClick={() => {
                                    const nextQueryString = uri.setSearchParam(queryString, "drawer", "definitions");
                                    history.push({ search: nextQueryString });
                                }}
                                buttonName="Price Overrides"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <PriceOverrideDetailsActions isDrawer priceOverride={priceOverride} />
                </Col>
            </Row>
        </>
    ) : null;
};
