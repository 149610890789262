import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import { setFiltersSeatAdSourcesType } from "../../reducer";
import { useGetSeatAdSourcesTypeQuery } from "@app/core/services/console/adSources";
import { useMemo } from "react";
import { AdSourceTypeNames, AdSourceTypeIds } from "../../../constants";

const programmaticOptionsMapping = [
    AdSourceTypeIds.PROGRAMMATIC_GUARANTEED,
    AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED,
    AdSourceTypeIds.FIXED_PRICE,
    AdSourceTypeIds.AUCTION_PRICE,
    AdSourceTypeIds.OPEN_AUCTION,
    AdSourceTypeIds.MARKETPLACE,
    AdSourceTypeIds.CURATOR_MARKETPLACE,
];
const tagBasedOptionsMapping = [
    AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED,
    AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED,
    AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE,
    AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED,
    AdSourceTypeIds.FALLBACK_TAG,
];

interface UseSeatAdSourcesTypeFilter {
    handleChangeValue: (value: LabeledValue[] | undefined, option: LabeledValue[]) => void;
    programmaticOptions: LabeledValue[];
    tagBasedOptions: LabeledValue[];
    value: LabeledValue[] | null;
    isLoading: boolean;
}

export const useSeatAdSourcesTypeFilter = (): UseSeatAdSourcesTypeFilter => {
    const dispatch = useAppDispatch();
    const { seatId } = useParams<{ seatId: string }>();
    const seatAdSourcesType = useAppSelector((state) => state.seatAdSourcesList.filters.seatAdSourcesType);
    const { data, isFetching } = useGetSeatAdSourcesTypeQuery(Number(seatId));

    const programmaticOptions = useMemo(
        () =>
            (data || [])
                .filter((filter) => programmaticOptionsMapping.includes(filter.id))
                .map((filter) => ({ label: filter.name, value: filter.id })),
        [data]
    );
    const tagBasedOptions = useMemo(
        () =>
            (data || [])
                .filter((filter) => tagBasedOptionsMapping.includes(filter.id))
                .map((filter) => ({ label: filter.name, value: filter.id })),
        [data]
    );

    const adSourceMapping = (data: LabeledValue[]): LabeledValue[] =>
        data.map((filter) => ({
            ...filter,
            label: AdSourceTypeNames[filter.value],
        }));

    const handleChangeValue = (value: LabeledValue[] | undefined, option: LabeledValue[]) => {
        dispatch(setFiltersSeatAdSourcesType(option));
    };

    return {
        handleChangeValue,
        programmaticOptions: adSourceMapping(programmaticOptions),
        tagBasedOptions: adSourceMapping(tagBasedOptions),
        value: seatAdSourcesType,
        isLoading: isFetching,
    };
};
