import { FC } from "react";
import { Layout } from "antd";
import { InventoryLiveEventsToolbar } from "./InventoryLiveEventsToolbar";
import { InventoryLiveEventsTable } from "./InventoryLiveEventsTable";

export const InventoryLiveEventsTablePage: FC = () => {
    return (
        <>
            <InventoryLiveEventsToolbar />
            <Layout.Content>
                <InventoryLiveEventsTable />
            </Layout.Content>
        </>
    );
};
