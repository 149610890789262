import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ErrorResponse } from "@app/core/services";
import { Modal, Typography, notification } from "antd";
import { FC, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { removeLabelDrawerParamsFromSearch } from "../ClassificationsLabelsTabDrawer";
import { Label, useDeleteSeatLabelMutation } from "@magnite/client-streaming-platform";

export const CLASSIFICATIONS_LABEL_DELETE_MODAL_SDET = "classifications-label-delete-modal";
export const CLASSIFICATIONS_LABEL_DELETE_MODAL_PROPS = {
    destroyOnClose: true,
    "data-sdet": CLASSIFICATIONS_LABEL_DELETE_MODAL_SDET,
    title: "Confirmation",
    okText: "Delete",
};
const initialState = {
    isOpen: false,
    label: null,
};

export const useClassificationsLabelDeleteModal = (isDrawer = false) => {
    const [state, setState] = useState<{ isOpen: boolean; label: Label | null }>(initialState);
    const { search: queryString } = useLocation();
    const [deleteClassificationsLabel, { isLoading }] = useDeleteSeatLabelMutation();
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const handleOpen = (nextLabel: Label) => setState({ isOpen: true, label: nextLabel });

    const onCancel = () => setState(initialState);

    const onOk = () => {
        state.label &&
            deleteClassificationsLabel(state.label.id)
                .unwrap()
                .then(() => {
                    notification.success({
                        message: (
                            <>
                                <Typography.Text strong>{state.label?.key}</Typography.Text> has successfully been
                                deleted
                            </>
                        ),
                    });
                    onCancel();
                    const nextQueryString = isDrawer ? removeLabelDrawerParamsFromSearch(queryString) : queryString;
                    history.replace({
                        pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_TABS(seatId, "labels"),
                        search: nextQueryString,
                    });
                })
                .catch((err) =>
                    notification.error({
                        message:
                            (err as ErrorResponse).data?.errorDescription ||
                            (err as ErrorResponse)?.data?.errors?.[0]?.message ||
                            "Something wrong has occurred, please contact your account manager",
                    })
                );
    };
    return { isOpen: state.isOpen, handleOpen, onCancel, onOk, isLoading, label: state.label };
};

interface Props {
    isOpen: boolean;
    onCancel: () => void;
    onOk: () => void;
    isLoading: boolean;
    label: Label | null;
}

export const ClassificationsLabelDeleteModal: FC<Props> = ({ onOk, isOpen, onCancel, isLoading, label }) => {
    return (
        <Modal
            {...CLASSIFICATIONS_LABEL_DELETE_MODAL_PROPS}
            open={isOpen}
            onCancel={onCancel}
            onOk={onOk}
            okButtonProps={{ danger: true, loading: isLoading }}
        >
            Are you sure you want to delete <Typography.Text strong>{label?.key}</Typography.Text>?
        </Modal>
    );
};
