import { Typography, notification } from "antd";
import {
    ADDED_VALUES_FORM_NAME,
    CLASSIFICATION_LABEL_FORM_CREATE_INITIAL_VALUES,
    ClassificationsLabelEditFormValues,
} from "./constants";

import { Label, LabelValuePayload } from "@magnite/client-streaming-platform";

export const getLabelFormInitialValues = (label?: Label) => {
    if (!label) return CLASSIFICATION_LABEL_FORM_CREATE_INITIAL_VALUES;

    const { key, isDistributionGroup, multiValue, enablePrefilter, isPublic } = label;

    return {
        key,
        isDistributionGroup,
        multiValue,
        enablePrefilter,
        isPublic,
        [ADDED_VALUES_FORM_NAME]: [],
    };
};

export const getLabelValuesPayload = (labelValues: string | undefined, labelId: number) =>
    labelValues
        ?.trim()
        .split("\n")
        .reduce((acc, labelValue) => {
            labelValue.trim() && acc.push({ label: { id: labelId }, value: labelValue.trim() });
            return acc;
        }, [] as LabelValuePayload[]) || [];

export const onFinishUpdateLabel = async (
    labelId: number,
    values: ClassificationsLabelEditFormValues,
    seatId: string | number,
    updateClassificationsLabel
) => {
    const { labelValues, deleteLabelValues, ...rest } = values;
    const labelPayload = {
        ...rest,
        id: labelId,
        seatId,
        namespace: `s:${seatId}`,
    };
    try {
        const updatedLabel = await updateClassificationsLabel({ labelId, body: labelPayload }).unwrap();
        notification.success({
            message: (
                <span>
                    <Typography.Text strong>{updatedLabel.key}</Typography.Text> has successfully been updated
                </span>
            ),
        });
    } catch (err) {
        notification.error({ message: err.message || err.data.errorDescription });
    }
};
