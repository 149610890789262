import { FC, MouseEvent, useEffect, useMemo } from "react";
import { Space, Table, Row, Col } from "antd";
import { COLUMN_KEY } from "@app/features/deals/DealListPage/constants";
import DealPagination from "@app/features/deals/DealListPage/DealListTable/DealListTablePagination/DealListTablePagination";
import { useDealListTable } from "@app/features/deals/DealListPage/DealListTable/useDealListTable";
import { DealListTableActions } from "@app/features/deals/shared/DealTableActions";
import DealStatusBadge from "@app/features/deals/DealStatusBadge";
import DealFilterSearch from "./DealListTableFilterSearch";
import { useUserAccess } from "@app/core/auth/useUserAccess";
import DealPriority from "@app/features/deals/DealPriority";
import { UIDeal } from "@app/core/services";
import { SeatAdSourcesDetailsDrawerSecondaryView as SecondaryDetailsView } from "src/features/seatAdSources/SeatAdSourcesDetailsDrawer/types";
import DealTableCpmRateCell from "@app/features/deals/shared/DealCPMRateCell/DealTableCpmRateCell";
import { ManageColumnsButton, ManageColumnsDrawer } from "@app/core/components/ManageColumns";
import {
    MANAGE_COLUMNS_KEY_DEAL_MANAGEMENT,
    useManageColumnsDrawer,
} from "@app/core/components/ManageColumns/useManageColumnsDrawer";
import { Dashes, HelpTooltip, StyledLink } from "@app/core/components";
import { DealsHelpKeys } from "@app/features/deals/constants";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useDealAdStats } from "@app/features/adStats";
import { useDealDetailsDrawer } from "../../DealDetailsDrawer/useDealDetailsDrawer";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { format } from "@rubicon/utils";
import { brandCobalt } from "@rubicon/antd-components";
import moment from "moment-timezone";
import { useSeatAdSourcesDetailsDrawer } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/useSeatAdSourcesDetailsDrawer";
import { TableItem } from "./useDealListTable";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_NO_COMMA } from "@app/core/components/constants";
import { dealListSourceToLiveStatsAdSourcePreview } from "../../DealHealthPage/helpers";

export const getTableItems = (
    data: UIDeal,
    seatId: string,
    route: string,
    handleLinkClick: (e: MouseEvent<HTMLElement>, dealId: number) => void,
    openSeatAdSourcesDetailsDrawer: (adSourceId: number, secondaryDetailsView?: SecondaryDetailsView) => void,
    hasInternalAccess: boolean
): TableItem[] =>
    data?.deals.map((deal) => {
        const mappedAdSources = (deal.adSources || []).map(dealListSourceToLiveStatsAdSourcePreview);
        const rowData = {
            key: deal?.id,
            [COLUMN_KEY.NAME]: (
                <StyledLink
                    href={`${route}${ROUTE_FORMATTERS.SEAT_DEALS_DEAL_DETAILS_DRAWER(seatId, deal.id)}`}
                    onClick={(e: MouseEvent<HTMLElement>) => handleLinkClick(e, deal?.id)}
                >
                    {format.asSelf(deal?.name)}
                </StyledLink>
            ),
            [COLUMN_KEY.DEAL_PRIORITY]: (
                <DealPriority adSources={mappedAdSources} openDrawer={openSeatAdSourcesDetailsDrawer} />
            ),
            [COLUMN_KEY.CODE]: <Dashes value={deal?.code} />,
            [COLUMN_KEY.NETWORK_NAME]: <Dashes value={deal?.network?.name} />,
            [COLUMN_KEY.DEAL_ENTITY_TYPE]: deal?.dealType?.name,
            [COLUMN_KEY.END_DATE]: (
                <Dashes
                    value={
                        deal?.endTime
                            ? `${moment(deal?.endTime)
                                  .tz(deal.timeZone?.code || "UTC")
                                  .format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_NO_COMMA)} ${deal.timeZone?.code || "UTC"}`
                            : null
                    }
                />
            ),
            [COLUMN_KEY.CPM_RATE]: (
                <DealTableCpmRateCell
                    dealType={deal.dealType}
                    rate={deal.rate}
                    currencyType={deal.currencyType}
                    adSources={mappedAdSources}
                    openDrawer={openSeatAdSourcesDetailsDrawer}
                />
            ),
            [COLUMN_KEY.DEAL_STATUS_NAME]: <DealStatusBadge status={deal.status} />,
            [COLUMN_KEY.DEAL_EXTERNAL_STATUS]: <Dashes value={deal?.externalStatus} />,
            [COLUMN_KEY.DEAL_ACTIONS]: (
                <DealListTableActions
                    dealId={deal.id}
                    adSources={mappedAdSources}
                    dealType={deal.dealType}
                    isDealShared={
                        (deal.sharedMarketplaces && deal.sharedMarketplaces.length > 0) ||
                        (deal.sharedSeats && deal.sharedSeats?.length > 0) ||
                        false
                    }
                />
            ),
        };

        if (hasInternalAccess) {
            rowData[COLUMN_KEY.DEAL_ORIGIN_NAME] = <Dashes value={deal?.dealOrigin?.name} />;
        }

        return rowData;
    });

const getDefaultColumns = (hasInternalAccess: boolean) =>
    [
        {
            dataIndex: COLUMN_KEY.NAME,
            title: (
                <HelpTooltip
                    helpKeyList={DealsHelpKeys}
                    helpKey={DealsHelpKeys.DealName}
                    tooltipPlacement={"topRight"}
                    popover={true}
                >
                    Deal Name
                </HelpTooltip>
            ),
            fixed: "left" as const,
            width: 280,
            visible: true,
            managedColumnTitle: "Deal Name",
            selectable: false,
        },
        {
            dataIndex: COLUMN_KEY.DEAL_PRIORITY,
            title: (
                <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealPriority} popover={true}>
                    Priority
                </HelpTooltip>
            ),
            visible: true,
            managedColumnTitle: "Priority",
            selectable: true,
        },
        {
            dataIndex: COLUMN_KEY.CODE,
            title: (
                <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealId} popover={true}>
                    Deal ID
                </HelpTooltip>
            ),
            visible: true,
            managedColumnTitle: "Deal ID",
            selectable: true,
        },
        {
            dataIndex: COLUMN_KEY.NETWORK_NAME,
            title: (
                <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealDSP} popover={true}>
                    DSP
                </HelpTooltip>
            ),
            visible: true,
            managedColumnTitle: "DSP",
            selectable: true,
        },
        {
            dataIndex: COLUMN_KEY.DEAL_ENTITY_TYPE,
            title: (
                <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealType} popover={true}>
                    Deal Type <InfoCircleOutlined style={{ color: brandCobalt }} />
                </HelpTooltip>
            ),
            visible: true,
            managedColumnTitle: "Deal Type",
            selectable: true,
        },
        {
            dataIndex: COLUMN_KEY.DEAL_ORIGIN_NAME,
            title: (
                <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealOrigin} popover={true}>
                    Deal Origin
                </HelpTooltip>
            ),
            visible: true,
            managedColumnTitle: "Deal Origin",
            selectable: true,
        },
        {
            dataIndex: COLUMN_KEY.END_DATE,
            title: (
                <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealDates} popover={true}>
                    End Date
                </HelpTooltip>
            ),
            visible: true,
            managedColumnTitle: "End Date",
            selectable: true,
        },
        {
            dataIndex: COLUMN_KEY.CPM_RATE,
            title: (
                <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealDetailsCPMRate} popover={true}>
                    CPM Rate
                </HelpTooltip>
            ),
            visible: true,
            managedColumnTitle: "CPM Rate",
            selectable: true,
        },
        {
            dataIndex: COLUMN_KEY.DEAL_STATUS_NAME,
            title: (
                <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealDetailsStatus} popover={true}>
                    Internal Status
                </HelpTooltip>
            ),
            visible: true,
            selectable: true,
        },
        {
            dataIndex: COLUMN_KEY.DEAL_EXTERNAL_STATUS,
            title: (
                <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealBuyerStatus} popover={true}>
                    Buyer Status
                </HelpTooltip>
            ),
            visible: true,
            selectable: true,
        },
        {
            dataIndex: COLUMN_KEY.DEAL_ACTIONS,
            title: "Actions",
            fixed: "right" as const,
            width: 140,
            visible: true,
            selectable: false,
        },
    ].filter((column) => column.dataIndex !== COLUMN_KEY.DEAL_ORIGIN_NAME || hasInternalAccess);

const DealListTable: FC = () => {
    const { data, isFetching, seatId, handleChange } = useDealListTable();
    const { setSubEntityIds } = useDealAdStats();
    const { hasInternalAccess } = useUserAccess();
    const { openDealDetailsDrawer } = useDealDetailsDrawer();
    const { openSeadAdSourceDrawerDeals } = useSeatAdSourcesDetailsDrawer();
    const defaultColumns = useMemo(() => getDefaultColumns(hasInternalAccess), [hasInternalAccess]);
    const {
        isDrawerOpen,
        handleOpenManageColumnsDrawer,
        handleCloseManageColumnsDrawer,
        allColumns,
        visibleColumns,
        onChange,
    } = useManageColumnsDrawer(MANAGE_COLUMNS_KEY_DEAL_MANAGEMENT, defaultColumns);
    const { pathname, origin } = window.location;
    const route = `${origin}${pathname}#`;

    const handleLinkClick = (e: MouseEvent<HTMLElement>, dealId: number) => {
        e.preventDefault();
        openDealDetailsDrawer(dealId);
    };

    useEffect(() => {
        if (data?.deals) {
            setSubEntityIds(data.deals.map((deal) => String(deal.id)));
        }
    }, [data, setSubEntityIds]);

    return (
        <>
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                <Row justify="space-between" gutter={[10, 10]}>
                    <Col xs={24} sm={24} md={16} lg={14}>
                        <Row gutter={[10, 10]}>
                            <Col xs={24} sm={16} md={18} lg={16} xl={12} xxl={9}>
                                <DealFilterSearch />
                            </Col>
                        </Row>
                    </Col>
                    <Row gutter={[10, 10]}>
                        <Col>
                            <ManageColumnsButton handleOpen={handleOpenManageColumnsDrawer} />
                        </Col>
                    </Row>
                </Row>
                <Table
                    size="small"
                    columns={visibleColumns}
                    showSorterTooltip={false}
                    onChange={handleChange}
                    loading={isFetching}
                    scroll={{ x: 1750 }}
                    dataSource={getTableItems(
                        data,
                        seatId,
                        route,
                        handleLinkClick,
                        openSeadAdSourceDrawerDeals,
                        hasInternalAccess
                    )}
                    pagination={false}
                />
                <Row justify="end">
                    <Col>
                        <DealPagination totalResults={data?.totalResults || 0} />
                    </Col>
                </Row>
            </Space>
            <ManageColumnsDrawer
                visible={isDrawerOpen}
                columns={allColumns.filter((c) => hasInternalAccess || c.dataIndex != COLUMN_KEY.DEAL_ORIGIN_NAME)}
                title="Manage Columns for Deals Management"
                handleClose={handleCloseManageColumnsDrawer}
                onChange={onChange}
            />
        </>
    );
};

export default DealListTable;
