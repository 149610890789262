import { ChangeEvent, useState, useMemo, useCallback } from "react";
import debounce from "lodash.debounce";
import { TableSearchInputProps } from "@app/core/components";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import {
    DEAL_HEALTH_QUERY_PARAM,
    useDealHealthTypeParam,
} from "../../DealHealthTableFilters/useDealHealthTableFilters";

export const useDealHealthTableFilterSearch = (): TableSearchInputProps => {
    const { value: searchKeyword, setValue: setSearchDealHealthFilter } = useDealHealthTypeParam(
        DEAL_HEALTH_QUERY_PARAM.FILTER_SEARCH
    );
    const [value, setValue] = useState(searchKeyword);

    const handleSearch = useCallback(
        (v: string) => {
            setSearchDealHealthFilter(v.trim());
        },
        [setSearchDealHealthFilter]
    );

    const debouncedHandleSearch = useMemo(
        () => debounce((v: string) => handleSearch(v), FILTER_INPUT_DEBOUNCE_TIME),
        [handleSearch]
    );

    const onReset = (): void => {
        setValue("");
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue(e?.target?.value);
        debouncedHandleSearch(e?.target?.value);
    };

    return {
        dataSdet: "deal-health-table-search-filter",
        entityType: "Deal",
        onChange,
        onReset,
        style: { width: "100%" },
        value,
    };
};
