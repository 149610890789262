import { FC } from "react";
import { AnimatedNumberCell, Dashes } from "@app/core/components";

interface DealHealthTableUseRateCellProps {
    useRate: number | undefined;
}

export const DealHealthTableUseRateCell: FC<DealHealthTableUseRateCellProps> = ({ useRate }) => {
    if (!useRate) {
        return <Dashes />;
    }

    return <AnimatedNumberCell value={useRate} isPercent />;
};
