import { NONE } from "@app/core/components/constants";
import { AdUnit, BrandSupply } from "@app/core/services";
import { GUTTER } from "@app/features/inventory/constants";
import { Col, Form, Row, Tag } from "antd";
import { FC } from "react";
import { FORM_ITEM_OFFSET, SUPPLY_WTAERFALL_DETAILS_LABELS } from "../../constants";
import { LabelValue } from "@magnite/client-streaming-platform";

const { Item } = Form;

interface Props {
    unit: BrandSupply | AdUnit;
    inheritedSeatPrefilterLabels?: LabelValue[];
}

export const WaterfallPrefiltering: FC<Props> = ({ unit, inheritedSeatPrefilterLabels }) => {
    return (
        <div data-sdet="unit-waterfall-prefiltering">
            <Row gutter={GUTTER}>
                <Col>
                    <Item label={SUPPLY_WTAERFALL_DETAILS_LABELS.prefiltering} className={FORM_ITEM_OFFSET}>
                        {unit?.prefilterLabelValues?.length
                            ? unit.prefilterLabelValues.map((prefilter) => (
                                  <Tag key={prefilter.id}>{`${prefilter.label.key}: ${prefilter.value}`}</Tag>
                              ))
                            : NONE}
                    </Item>
                </Col>
            </Row>
            {!!inheritedSeatPrefilterLabels?.length && (
                <Row>
                    <Col>
                        <Item
                            label={SUPPLY_WTAERFALL_DETAILS_LABELS.inheritedSeatPrefilterLabels}
                            className={FORM_ITEM_OFFSET}
                        >
                            {inheritedSeatPrefilterLabels.map((prefilter) => (
                                <Tag key={prefilter.id}>{`${prefilter.label.key}: ${prefilter.value}`}</Tag>
                            ))}
                        </Item>
                    </Col>
                </Row>
            )}
        </div>
    );
};
