import { FC, Key, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    DerivedBuyerDeal,
    removeBuyerDealTouchedRecordInfo,
    selectBuyerDealSelectedRows,
    selectBuyerDealTouchedRecordsInfo,
    setBuyerDealFieldBulkChange,
    setBuyerDealFieldChange,
} from "../../../reducer";
import useTableLocalSearch from "@app/core/components/hooks/useTableLocalSearch";
import { BuyerDeal } from "@app/core/services";
import { Form, Space, Table, TableColumnsType } from "antd";
import { BulkOperationEntitiesEditTableTools } from "./BulkOperationEntitiesEditTableTools";
import { BulkOperationBulkEditButtonModal } from "../BulkOperationBulkEditModal/BulkOperationBulkEditButtonModal";
import { tableCellFormItemStyle } from "@app/features/controls/constants";
import { BUYER_DEMAND_DEAL_FORM_ITEM_COMMON_CONFIG, TO_OPTION_MAPPER } from "../../../constants";
import { BUYER_DEALS_COLUMNS_BASE } from "../../BulkOperationSetupStep/BulkOperationEntitySelectSection/FindOperationEntitiesTab/OperationEntitiesTable";
import { getColumnsConfig } from "../../../helpers";
import moment from "moment-timezone";
import { useBulkOperationEntitiesEditTableRevert } from "./useBulkOperationEntitiesEditTableRevert";

const readonlyColumns: TableColumnsType = BUYER_DEALS_COLUMNS_BASE.slice(1);

const FORM_ITEM_NAME = "buyerDealUpdates";

export const getDerivedBuyerDealFromApi = (buyerDeal: BuyerDeal): DerivedBuyerDeal => {
    const { currencyType, startTime, endTime, timeZone, sharedSeats, sharedMarketplaces } = buyerDeal;

    return {
        ...buyerDeal,
        timeZone: TO_OPTION_MAPPER.timeZone(timeZone),
        currencyType: TO_OPTION_MAPPER.currencyType(currencyType),
        sharedSeats: sharedSeats?.map(TO_OPTION_MAPPER.sharedSeats),
        sharedMarketplaces: sharedMarketplaces?.map(TO_OPTION_MAPPER.sharedMarketplaces),
        startTime: moment(startTime).tz(timeZone.code),
        endTime: endTime ? moment(endTime).tz(timeZone.code) : null,
    };
};

export const BulkOperationBuyerDealEntitiesEditTable: FC = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const touchedRecordsInfo = useSelector(selectBuyerDealTouchedRecordsInfo);
    const buyerDeals = useSelector(selectBuyerDealSelectedRows);
    const derivedBuyerDeals = useMemo(() => buyerDeals.map(getDerivedBuyerDealFromApi), [buyerDeals]);

    const {
        dataSource: filteredItems,
        handleChange,
        value: searchTerm,
    } = useTableLocalSearch<DerivedBuyerDeal>([(buyerDeal) => buyerDeal.externalName || ""], derivedBuyerDeals);
    const { handleValueChange, handleRevert, handleRevertAll, hasChangedField } =
        useBulkOperationEntitiesEditTableRevert<DerivedBuyerDeal>(
            FORM_ITEM_NAME,
            derivedBuyerDeals,
            touchedRecordsInfo,
            setBuyerDealFieldChange,
            removeBuyerDealTouchedRecordInfo
        );

    const [{ selectedRowKeys, selectedRows }, setSelectedRowsInfo] = useState<{
        selectedRowKeys: Key[];
        selectedRows: DerivedBuyerDeal[];
    }>({ selectedRowKeys: [], selectedRows: [] });

    const form = Form.useFormInstance();

    // useMemo to reduce unnecessary useEffect execution
    const { fieldsToEdit, buyerDealUpdates } = useMemo(() => form.getFieldsValue(true), [form]);

    // To make sure form values only get initialized on landing, and do not get reset when doing table search
    useEffect(() => {
        const tableFieldsValue = derivedBuyerDeals.reduce((acc, derivedBuyerDeal) => {
            // To make sure when going to other step and (or) change selected rows, the other selected rows prev changed values do not get reset
            const prevItemValue = buyerDealUpdates?.[derivedBuyerDeal.id];
            acc[derivedBuyerDeal.id] = prevItemValue || derivedBuyerDeal;

            return acc;
        }, {});

        form.setFieldValue("buyerDealUpdates", tableFieldsValue);
    }, [derivedBuyerDeals, buyerDealUpdates, form]);

    const editableFields = ["externalName", ...(fieldsToEdit || [])];

    return (
        <>
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <BulkOperationEntitiesEditTableTools
                    bulkEditCount={selectedRows.length}
                    entityLabel="Buyer Deal"
                    entitiesCount={buyerDeals.length}
                    handleSearch={handleChange}
                    handleRevertAll={handleRevertAll}
                    isDisabledRevertAll={!hasChangedField}
                    searchTerm={searchTerm}
                >
                    <BulkOperationBulkEditButtonModal<DerivedBuyerDeal>
                        editableFields={editableFields}
                        entityLabel="Buyer Deal"
                        formItemConfig={BUYER_DEMAND_DEAL_FORM_ITEM_COMMON_CONFIG}
                        formItemName={FORM_ITEM_NAME}
                        seatId={Number(seatId)}
                        selectedRows={selectedRows}
                        setBulkChange={setBuyerDealFieldBulkChange}
                    />
                </BulkOperationEntitiesEditTableTools>
                <Table
                    size="small"
                    className={tableCellFormItemStyle}
                    dataSource={filteredItems}
                    columns={getColumnsConfig<DerivedBuyerDeal>(
                        Number(seatId),
                        editableFields,
                        BUYER_DEMAND_DEAL_FORM_ITEM_COMMON_CONFIG,
                        readonlyColumns,
                        FORM_ITEM_NAME,
                        handleValueChange,
                        touchedRecordsInfo,
                        handleRevert
                    )}
                    rowKey="id"
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (selectedRowKeys, selectedRows: DerivedBuyerDeal[]) =>
                            setSelectedRowsInfo({ selectedRowKeys, selectedRows }),
                    }}
                    showSorterTooltip={false}
                    scroll={{ x: "max-content" }}
                    pagination={false}
                />
            </Space>
        </>
    );
};
