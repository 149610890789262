import { FC, useState } from "react";
import { css } from "@emotion/css";
import { ResizableDrawer } from "@rubicon/antd-components";
import { Button, Col, Row, Space, Tooltip, Typography } from "antd";
import { useMarketplaceInfoWidget } from "../useMarketplaceInfoWidget";
import { DealFormContent, useDealObjectForm } from "./DealFormContent";
import { Footer } from "./Footer";
import { ArrowRightOutlined, ArrowLeftOutlined, LinkOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useAppSelector } from "@app/core/store";
import { selectIframed } from "@app/core/components/Main/reducer";
import { useClipBoardButton } from "@app/core/components/ClipBoardButton/useClipBoardButton";
import { getUrlSegment } from "@app/core/utils";

const drawerStyles = css`
    .ant-drawer-body {
        padding: 0;
    }

    .full-width {
        .ant-space,
        .ant-space-item {
            width: 100%;
        }
    }

    .ant-collapse {
        &:first-child {
            border-top: 1px solid #d9d9d9;
        }
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 0 16px;
        line-height: 42px;

        .ant-collapse-header-text {
            font-size: 20px;
            font-weight: 500;

            .ant-typography {
                line-height: 42px;
            }
        }

        .ant-collapse-arrow {
            font-size: 16px;
            vertical-align: -3px;
        }
    }

    .ant-drawer-close {
        padding: 0;
        margin: 0;
    }
`;

interface DealDrawerTitleProps {
    dealId?: number;
    dealName?: string;
    adSourceName?: string;
    readonly: boolean;
    marketplaceInfoMode: { name: string; selectedId?: number };
    setEditMode: (index: number) => void;
    deleteMarketplaceInfo: () => void;
    close: () => void;
}

export const DealDrawerTitle: FC<DealDrawerTitleProps> = ({
    dealId,
    dealName,
    adSourceName,
    readonly,
    marketplaceInfoMode,
    setEditMode,
    deleteMarketplaceInfo,
    close,
}) => {
    const { seatId } = useParams<{ seatId: string }>();
    const [isVisible, setIsVisible] = useState(false);
    const inIframe = useAppSelector(selectIframed);
    const { handleCopy } = useClipBoardButton();

    return (
        <Space
            onMouseOver={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
            data-sdet="deal-drawer-drawer-nav-bar"
            direction="vertical"
        >
            <Row align="middle">
                <Col>
                    <Button
                        type="text"
                        onClick={close}
                        size="large"
                        style={{ padding: 0, paddingRight: "6px" }}
                        data-sdet="deal-drawer-close-button"
                    >
                        <ArrowLeftOutlined />
                        {adSourceName || "--"}
                    </Button>
                </Col>
                <Col style={{ marginBottom: 0 }}>
                    <Typography.Text style={{ marginRight: "16px" }}>/ {dealName}</Typography.Text>
                    {dealId && (
                        <Link to={ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(seatId, dealId)}>
                            Go to Deal <ArrowRightOutlined />
                        </Link>
                    )}
                    {dealId && (
                        <span
                            style={{
                                opacity: isVisible ? "1" : "0",
                                height: isVisible ? "auto" : "0",
                                transition: "height 0.3s, opacity 0.3s ease-out",
                                marginLeft: "8px",
                            }}
                        >
                            <Tooltip title="Copy Deals Link" placement="bottom">
                                <LinkOutlined
                                    data-sdet="deal-drawer-copy-link"
                                    onClick={() => {
                                        const targetPath = ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(seatId, dealId);
                                        const { origin, pathname } = window.location;
                                        const url = new URL(`${pathname}#${targetPath}`, origin);
                                        const copyUrl = inIframe ? getUrlSegment(url.hash) : url.href;
                                        handleCopy(copyUrl, "Link");
                                    }}
                                />
                            </Tooltip>
                        </span>
                    )}
                </Col>
            </Row>
            <Row>
                {!readonly && marketplaceInfoMode.name === "view" && (
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                if (typeof marketplaceInfoMode.selectedId === "number") {
                                    setEditMode(marketplaceInfoMode.selectedId);
                                }
                            }}
                        >
                            Edit
                        </Button>
                        <Button danger onClick={deleteMarketplaceInfo}>
                            Delete
                        </Button>
                    </Space>
                )}
            </Row>
        </Space>
    );
};

export const DealDrawer: FC<{ readonly?: boolean; adSourceName: string }> = ({ readonly = false, adSourceName }) => {
    const { formTitle } = useDealObjectForm();
    const { close, marketplaceInfoMode, setEditMode, deleteMarketplaceInfo, getSelectedMarketplaceInfo } =
        useMarketplaceInfoWidget();

    const isOpen = marketplaceInfoMode.name !== "closed";

    const getDealData = () => {
        if (marketplaceInfoMode.name !== "view") {
            return null;
        }

        const dealInfo = getSelectedMarketplaceInfo(marketplaceInfoMode?.selectedId);
        return dealInfo;
    };

    const getDealName = () => {
        const dealData = getDealData();
        return dealData?.deal?.externalName || dealData?.deal?.name || formTitle || "--";
    };

    return (
        <ResizableDrawer
            open={isOpen}
            placement="right"
            width={1024}
            onClose={close}
            closable={false}
            footer={!(marketplaceInfoMode.name === "view") && <Footer />}
            title={
                <Space>
                    <DealDrawerTitle
                        dealId={getDealData()?.deal.id}
                        dealName={getDealName()}
                        adSourceName={adSourceName}
                        readonly={readonly}
                        marketplaceInfoMode={marketplaceInfoMode}
                        setEditMode={setEditMode}
                        deleteMarketplaceInfo={deleteMarketplaceInfo}
                        close={close}
                    />
                </Space>
            }
            styles={{ footer: { display: "flex", justifyContent: "end" } }}
            className={drawerStyles}
        >
            {isOpen && <DealFormContent readonly={readonly} />}
        </ResizableDrawer>
    );
};
