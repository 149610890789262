import { useParams } from "react-router-dom";
import {
    useLazyGetBrandsSupplyQuery,
    useLazyGetPublisherBrandsQuery,
    useLazyGetSupplyAdUnitsQuery,
} from "@app/core/services";
import { useGetSeatPublishersQuery } from "@magnite/client-streaming-platform";
import { useInventoryHealthInventoryTableFilters } from "@app/features/inventory/InventoryHealth/InventoryHealthPage/InventoryHealthInventoryTable/useInventoryHealthInventoryTableFilters";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EntityTypes, useInventoryHealthStats } from "@app/features/adStats";
import { useSeatAuthContext } from "@app/core/auth";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    setSelectedRowKeyIsLoading,
    setSelectedRowKey,
    setSelectedRowAndExpandedRowKeys,
    setExpandedRowKeys,
    selectInventoryHealthSelectedRow,
    selectInventoryHealthExpandedRowKeys,
} from "../../reducer";
import {
    loadInitialPublishersBrands,
    updateEntityChildren,
    updateBrandChildren,
    updateSupplyChildren,
    updateEntityChildrenByPath,
    updatePublisherChildren,
} from "./helpers";
import { TreeData, InventoryAdStats, TableDatum } from "./types";
import { getKey, isBrandOrBrandChannel, isPublisherOrPublisherChannel, isSeat, isSupply } from "./utils";

interface UseInventoryHealthInventoryTable {
    adStats: InventoryAdStats;
    data: TreeData | undefined;
    expandedKeys: string[];
    isLoadingInitialData: boolean;
    loadedEntities: Set<string>;
    loadingEntities: Set<string>;
    onCollapse: (entity: TableDatum) => void;
    onExpand: (entity: TableDatum) => void;
    shouldVirtualize: boolean;
    selectedRow: {
        key: string | null;
        shouldScrollTo: boolean;
        entity: TableDatum | null;
        isLoading: boolean;
    };
}

export const useInventoryHealthInventoryTable = (): UseInventoryHealthInventoryTable => {
    const { seatId } = useParams<{ seatId: string }>();
    const { context } = useSeatAuthContext();
    const { seatAllInventoryHealthParams } = useInventoryHealthInventoryTableFilters();
    const { data: publishers, isLoading: isLoadingPublishers } =
        useGetSeatPublishersQuery(seatAllInventoryHealthParams);
    const [getPublisherBrands] = useLazyGetPublisherBrandsQuery();
    const [getBrandSupply] = useLazyGetBrandsSupplyQuery();
    const [getSupplyAdUnits] = useLazyGetSupplyAdUnitsQuery();
    const [data, setData] = useState<TreeData>([]);
    const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);
    const selectedRow = useAppSelector(selectInventoryHealthSelectedRow);
    const expandedRowKeys = useAppSelector(selectInventoryHealthExpandedRowKeys);
    const dispatch = useAppDispatch();

    const [loadingEntities, setLoadingEntities] = useState(new Set<string>());
    const [loadedEntities, setLoadedEntities] = useState(new Set<string>());
    const [initialDataCount, setInitialDataCount] = useState(new Set<string>());

    const {
        setEntityIds: setSeatEntityIds,
        adStatsById: seatAdStatsById,
        isConnected: isSeatAdStatsConnected,
    } = useInventoryHealthStats(EntityTypes.Seat);
    const {
        setEntityIds: setPublisherEntityIds,
        adStatsById: publisherAdStatsById,
        isConnected: isPublisherAdStatsConnected,
    } = useInventoryHealthStats(EntityTypes.Publisher);
    const {
        setEntityIds: setBrandEntityIds,
        adStatsById: brandAdStatsById,
        isConnected: isBrandAdStatsConnected,
    } = useInventoryHealthStats(EntityTypes.Brand);
    const {
        setEntityIds: setSupplyEntityIds,
        adStatsById: supplyAdStatsById,
        isConnected: isSupplyAdStatsConnected,
    } = useInventoryHealthStats(EntityTypes.Supply);
    const {
        setEntityIds: setAdUnitEntityIds,
        adStatsById: adUnitAdStatsById,
        isConnected: isAdUnitAdStatsConnected,
    } = useInventoryHealthStats(EntityTypes.AdUnit);

    const loadChildren = useCallback(
        (entity: TableDatum) => {
            if (isSeat(entity)) {
                dispatch(
                    setExpandedRowKeys({
                        seatId: `Seat-${entity.id}`,
                        publisherId: null,
                        brandId: null,
                        supplyId: null,
                    })
                );
                if (entity.children?.length) {
                    setPublisherEntityIds(entity.children.map((publisher) => publisher.id));
                    return;
                }
            }
            if (isPublisherOrPublisherChannel(entity)) {
                dispatch(
                    setExpandedRowKeys({
                        seatId: `Seat-${entity.seat.id}`,
                        publisherId: `Publisher-${entity.id}`,
                        brandId: null,
                        supplyId: null,
                    })
                );

                if (entity.children?.length) {
                    setBrandEntityIds(entity.children.map((brand) => brand.id));
                    return;
                }
            }
            if (isBrandOrBrandChannel(entity)) {
                dispatch(
                    setExpandedRowKeys({
                        seatId: `Seat-${entity.publisher.seat.id}`,
                        publisherId: `Publisher-${entity.publisher.id}`,
                        brandId: `Brand-${entity.id}`,
                        supplyId: null,
                    })
                );
                return updateEntityChildren(
                    entity,
                    getBrandSupply,
                    { brandId: entity.id },
                    updateBrandChildren,
                    loadedEntities,
                    setLoadedEntities,
                    loadingEntities,
                    setLoadingEntities,
                    setSupplyEntityIds,
                    setData
                );
            }
            if (isSupply(entity)) {
                dispatch(
                    setExpandedRowKeys({
                        seatId: `Seat-${entity.brand.publisher.seat.id}`,
                        publisherId: `Publisher-${entity.brand.publisher.id}`,
                        brandId: `Brand-${entity.brand.id}`,
                        supplyId: `Supply-${entity.id}`,
                    })
                );
                return updateEntityChildren(
                    entity,
                    getSupplyAdUnits,
                    entity.id,
                    updateSupplyChildren,
                    loadedEntities,
                    setLoadedEntities,
                    loadingEntities,
                    setLoadingEntities,
                    setAdUnitEntityIds,
                    setData
                );
            }
        },
        [
            dispatch,
            getBrandSupply,
            getSupplyAdUnits,
            loadedEntities,
            loadingEntities,
            setAdUnitEntityIds,
            setBrandEntityIds,
            setData,
            setPublisherEntityIds,
            setSupplyEntityIds,
        ]
    );

    const onCollapse = useCallback(
        (entity: TableDatum) => {
            if (isSeat(entity)) {
                dispatch(
                    setSelectedRowAndExpandedRowKeys({
                        selectedRow: { key: getKey(entity), entity, shouldScrollTo: false, isLoading: false },
                        expandedRowKeys: { seatId: null, publisherId: null, brandId: null, supplyId: null },
                    })
                );
            } else if (isPublisherOrPublisherChannel(entity)) {
                dispatch(
                    setSelectedRowAndExpandedRowKeys({
                        selectedRow: { key: getKey(entity), entity, shouldScrollTo: false, isLoading: false },
                        expandedRowKeys: { ...expandedRowKeys, publisherId: null, brandId: null, supplyId: null },
                    })
                );
            } else if (isBrandOrBrandChannel(entity)) {
                dispatch(
                    setSelectedRowAndExpandedRowKeys({
                        selectedRow: { key: getKey(entity), entity, shouldScrollTo: false, isLoading: false },
                        expandedRowKeys: { ...expandedRowKeys, brandId: null, supplyId: null },
                    })
                );
            } else if (isSupply(entity)) {
                dispatch(
                    setSelectedRowAndExpandedRowKeys({
                        selectedRow: { key: getKey(entity), entity, shouldScrollTo: false, isLoading: false },
                        expandedRowKeys: { ...expandedRowKeys, supplyId: null },
                    })
                );
            }
        },
        [dispatch, expandedRowKeys]
    );

    const onExpand = useCallback(
        (entity: TableDatum) => {
            dispatch(setSelectedRowKey({ key: getKey(entity), entity, shouldScrollTo: false, isLoading: false }));
            loadChildren(entity);
        },
        [dispatch, loadChildren]
    );

    useEffect(() => {
        if (context && publishers) {
            dispatch(
                setSelectedRowKey({
                    key: `Seat-${context.id}`,
                    entity: context,
                    shouldScrollTo: false,
                    isLoading: false,
                })
            );
            setSeatEntityIds([Number(seatId)]);
            dispatch(
                setExpandedRowKeys({
                    seatId: `Seat-${context.id}`,
                    publisherId: null,
                    brandId: null,
                    supplyId: null,
                })
            );
            setPublisherEntityIds(publishers.map((publisher) => publisher.id));
            loadInitialPublishersBrands(
                context,
                publishers,
                getPublisherBrands,
                setData,
                setIsLoadingInitialData,
                setInitialDataCount
            );
        }
    }, [
        context,
        dispatch,
        getBrandSupply,
        getPublisherBrands,
        getSupplyAdUnits,
        publishers,
        seatId,
        setPublisherEntityIds,
        setSeatEntityIds,
    ]);

    useEffect(() => {
        if (!selectedRow.isLoading) {
            return;
        }
        updateEntityChildrenByPath(
            selectedRow.entity,
            {
                Publisher: (id) => getPublisherBrands({ publisherId: id }).unwrap(),
                Brand: (id) => getBrandSupply({ brandId: id }).unwrap(),
                Supply: (id) => getSupplyAdUnits(id).unwrap(),
            },
            {
                Publisher: setBrandEntityIds,
                Brand: setSupplyEntityIds,
                Supply: setAdUnitEntityIds,
            },
            {
                Publisher: updatePublisherChildren,
                Brand: updateBrandChildren,
                Supply: updateSupplyChildren,
            },
            setLoadingEntities,
            setLoadedEntities,
            (isLoading) => dispatch(setSelectedRowKeyIsLoading(isLoading)),
            setData
        );
    }, [
        dispatch,
        getBrandSupply,
        getPublisherBrands,
        getSupplyAdUnits,
        selectedRow.entity,
        selectedRow.isLoading,
        setAdUnitEntityIds,
        setBrandEntityIds,
        setSupplyEntityIds,
    ]);

    const adStats = useMemo(() => {
        return {
            seat: {
                byId: seatAdStatsById,
                isConnected: isSeatAdStatsConnected,
            },
            publisher: {
                byId: publisherAdStatsById,
                isConnected: isPublisherAdStatsConnected,
            },
            brand: {
                byId: brandAdStatsById,
                isConnected: isBrandAdStatsConnected,
            },
            supply: {
                byId: supplyAdStatsById,
                isConnected: isSupplyAdStatsConnected,
            },
            adUnit: {
                byId: adUnitAdStatsById,
                isConnected: isAdUnitAdStatsConnected,
            },
        };
    }, [
        adUnitAdStatsById,
        brandAdStatsById,
        publisherAdStatsById,
        seatAdStatsById,
        supplyAdStatsById,
        isAdUnitAdStatsConnected,
        isBrandAdStatsConnected,
        isPublisherAdStatsConnected,
        isSeatAdStatsConnected,
        isSupplyAdStatsConnected,
    ]);

    const expandedKeys = useMemo(() => {
        return Object.values(expandedRowKeys).filter(Boolean) as string[];
    }, [expandedRowKeys]);

    return {
        adStats,
        data,
        expandedKeys,
        isLoadingInitialData: isLoadingPublishers || isLoadingInitialData,
        loadedEntities,
        loadingEntities,
        onCollapse,
        onExpand,
        selectedRow,
        shouldVirtualize: initialDataCount.size > 100,
    };
};
