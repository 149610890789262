import { useMemo, useState } from "react";
import { useGetPlayerSizesQuery } from "@magnite/client-streaming-platform";
import { playerSizesToLabelValue } from "@app/features/targeting/helpers";

export const useSizesSelect = () => {
    const [search, setSearch] = useState("");
    const { data, isFetching } = useGetPlayerSizesQuery();
    const options = useMemo(
        () =>
            playerSizesToLabelValue(data || []).filter((option) =>
                (option.label as string).toLowerCase().includes(search.toLowerCase())
            ),
        [data, search]
    );

    return {
        isLoading: isFetching,
        onBlur: () => setSearch(""),
        onSearch: setSearch,
        options,
        search,
    };
};
