import { AdSource } from "@app/core/services";
import { Col, Typography } from "antd";
import { useIsFieldVisible } from "./useIsFieldVisible";

const { Text, Paragraph } = Typography;

export const VastDynamicPricingMultiplierInput = ({ adSource }: { adSource: AdSource }) => {
    const isVisible = useIsFieldVisible(adSource);
    if (!isVisible) {
        return null;
    }
    return (
        <Col xs={12} xl={8}>
            <Text strong>VAST Dynamic Pricing Multiplier</Text>
            <Paragraph>{adSource?.vastDynamicPricingMultiplier || "--"}</Paragraph>
        </Col>
    );
};
