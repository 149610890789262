import { FC } from "react";
import { Col, Form, InputNumber, Row, Space } from "antd";
import { css } from "@emotion/css";
import { CurrencyInput } from "@app/core/components";
import {
    AD_SOURCE_FIELDS,
    DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
    DemandAcquisitionCostModelId,
    VERTICAL_LAYOUT_FIX,
} from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "../../components";
import {
    getFixedDemandAcquisitionCostFormValue,
    getPercentDemandAcquisitionCostFormValue,
    getDemandAcquisitionCostHelpText,
    getDemandAcquisitionCostLabel,
} from "./utils";
import { useDemandAcquisitionCostUtils } from "./useDemandAcquisitionCostUtils";
import { useGetEnrichmentCostModelsQuery } from "@app/core/services";

const dacLabelCss = css`
    .ant-form-item {
        margin-bottom: 8px;
    }
    .ant-form-item-control-input {
        display: none;
    }
`;

export const DemandAcquisitionCostField: FC = () => {
    const form = Form.useFormInstance();
    const { isDemandAcquisitionEnabled, isDemandAcquisitionReadonly } = useDemandAcquisitionCostUtils();
    const { data: enrichmentCostModels } = useGetEnrichmentCostModelsQuery();

    const dac = Form.useWatch<string | null>(AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name);
    const dacModelId = Form.useWatch(AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST_MODEL.name)?.id;

    if (!isDemandAcquisitionEnabled) {
        return null;
    }

    const dacLabel = getDemandAcquisitionCostLabel(dacModelId);
    const dacHelpText = getDemandAcquisitionCostHelpText(dac, dacModelId);
    const isDacModelPercent = dacModelId === DemandAcquisitionCostModelId.PERCENTAGE;

    return (
        <div data-sdet="demand-acquisition-cost-fields">
            <Row>
                <Col className={dacLabelCss}>
                    <Form.Item label={AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.label} colon={false} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Space.Compact>
                        <Form.Item name={AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST_MODEL.name} {...VERTICAL_LAYOUT_FIX}>
                            <SelectWithValue
                                style={{
                                    width: "120px",
                                }}
                                data-sdet="ad-source-demand-acquisition-cost-model"
                                disabled={isDemandAcquisitionReadonly}
                                options={enrichmentCostModels}
                                placeholder="Select..."
                                fieldAsLabel={["name"]}
                                fieldAsValue={["id"]}
                            />
                        </Form.Item>
                        {isDacModelPercent ? (
                            <Form.Item
                                name={AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name}
                                data-sdet="ad-source-demand-acquisition-cost-field"
                                help={dacHelpText}
                                {...VERTICAL_LAYOUT_FIX}
                            >
                                <InputNumber
                                    style={{
                                        width: "280px",
                                    }}
                                    data-sdet="ad-source-demand-acquisition-cost-input"
                                    placeholder={`Enter ${dacLabel}`}
                                    precision={0}
                                    suffix="%"
                                    disabled={isDemandAcquisitionReadonly}
                                    onBlur={(blurEvent) => {
                                        const boundedDacValue = getPercentDemandAcquisitionCostFormValue(
                                            blurEvent.target.value
                                        );
                                        if (dac !== boundedDacValue) {
                                            form.setFieldValue(
                                                AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name,
                                                boundedDacValue
                                            );
                                        }
                                    }}
                                />
                            </Form.Item>
                        ) : (
                            <CurrencyInput
                                style={{
                                    width: "320px",
                                }}
                                name={AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name}
                                dataSdetPrefix="ad-source-demand-acquisition-cost"
                                label={dacLabel}
                                hideLabel
                                disabled={isDemandAcquisitionReadonly}
                                currencyCode={DEFAULT_ENRICHMENT_COST_CURRENCY_CODE}
                                help={dacHelpText}
                                onBlur={(blurValue) => {
                                    const boundedDacValue = getFixedDemandAcquisitionCostFormValue(blurValue);
                                    if (dac !== boundedDacValue) {
                                        form.setFieldValue(
                                            AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name,
                                            boundedDacValue
                                        );
                                    }
                                }}
                            />
                        )}
                    </Space.Compact>
                </Col>
            </Row>
        </div>
    );
};
