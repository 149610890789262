import { FC } from "react";
import { Form, Row, Col } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { NavBar } from "@app/core/components";
import PublisherFilter from "./PublisherFilter";
import DemandTypeFilter from "./DemandTypeFilter";
import { css } from "@emotion/css";

const COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 9 };

const pageHeaderClassName = css`
    .ant-page-header-heading {
        flex-wrap: nowrap;
        @media (max-width: 992px) {
            //Equal to ANTD lg breakpoint (992px)
            flex-wrap: wrap;
        }

        .ant-page-header-heading-extra {
            width: 55%;
            @media (max-width: 992px) {
                // Equal to ANTD lg breakpoint (992px)
                width: 100%;
            }
        }

        .ant-space {
            width: 100%;
        }
    }

    .ant-space-item {
        width: 100%;
    }
`;

interface HeaderProps {
    seatName: string;
}

const Header: FC<HeaderProps> = ({ seatName }) => {
    return (
        <>
            <NavBar label="Dashboard" />
            <PageHeader
                ghost={false}
                className={pageHeaderClassName}
                title={`Dashboard: ${seatName}`}
                extra={
                    <Form colon={false} layout="horizontal">
                        <Row gutter={[16, 16]} style={{ width: "100%" }} justify="end">
                            <Col {...COL_SIZES}>
                                <Form.Item style={{ marginBottom: 0 }} label="Publisher">
                                    <PublisherFilter style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col {...COL_SIZES}>
                                <Form.Item style={{ marginBottom: 0 }} label="Demand Type">
                                    <DemandTypeFilter style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                }
            />
        </>
    );
};

export default Header;
