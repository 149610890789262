import { Button, Col, Input, Row, Table, TableColumnsType } from "antd";
import { FC } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { useCalculonStats } from "./useCalculonStats";
import { intl } from "@rubicon/utils";
import { CalculonBannedLocalAdUnit } from "@magnite/client-streaming-platform";
import { ColumnProps } from "antd/es/table";

const DEMAND = "demand";
const ADVERTISER = "advertiser";
const REQUEST_TYPE = "requestType";
const PLATFORM = "platform";
const EST_FILLS = "estFills";
const EST_IMPS = "estImps";
const USE_RATE = "useRate";

export const COLUMN_KEY = {
    DEMAND,
    ADVERTISER,
    REQUEST_TYPE,
    PLATFORM,
    EST_FILLS,
    EST_IMPS,
    USE_RATE,
};

const columns: TableColumnsType<ColumnProps<CalculonBannedLocalAdUnit>> = [
    {
        dataIndex: COLUMN_KEY.DEMAND,
        title: "Demand",
        sorter: true,
    },
    {
        dataIndex: COLUMN_KEY.ADVERTISER,
        title: "Advertiser",
        sorter: true,
        defaultSortOrder: "ascend",
    },
    {
        dataIndex: COLUMN_KEY.REQUEST_TYPE,
        title: "Request Type",
        sorter: true,
    },
    {
        dataIndex: COLUMN_KEY.PLATFORM,
        title: "Platform",
        sorter: true,
    },
    {
        dataIndex: COLUMN_KEY.EST_FILLS,
        title: "est. Fills (last hour)",
        sorter: true,
        render: (v) => intl.numberFormatter(v),
    },
    {
        dataIndex: COLUMN_KEY.EST_IMPS,
        title: "est. Imps (last hour)",
        sorter: true,
        render: (v) => intl.numberFormatter(v),
    },
    {
        dataIndex: COLUMN_KEY.USE_RATE,
        title: "Use Rate (last hour)",
        sorter: true,
        render: (v) => (v ? `${intl.numberFormatter(v)}%` : "-"),
    },
];

const searchIconCss = css`
    color: rgba(0, 0, 0, 0.25);
    height: 12px;
`;

interface Props {
    id: string | number;
}
export const CalculonStatas: FC<Props> = ({ id }) => {
    const { refetch, tableItems, isLoading, handleChange, handleSearch } = useCalculonStats(Number(id));
    return (
        <Row justify="space-between" gutter={[24, 24]}>
            <Col xs={24} sm={14} md={10} lg={8} xl={5}>
                <Input
                    allowClear
                    onChange={handleSearch}
                    style={{ width: "100%" }}
                    placeholder="Search"
                    suffix={<SearchOutlined className={searchIconCss} />}
                />
            </Col>
            <Col>
                <Button onClick={refetch}>Refresh</Button>
            </Col>
            <Col span={24}>
                <Table<ColumnProps<CalculonBannedLocalAdUnit>>
                    loading={isLoading}
                    columns={columns}
                    dataSource={tableItems}
                    size="small"
                    showSorterTooltip={false}
                    pagination={false}
                    onChange={handleChange}
                    sortDirections={["ascend", "descend", "ascend"]}
                />
            </Col>
        </Row>
    );
};
