import { LabeledValue } from "antd/lib/select";
import { DEAL_TYPE_OPTIONS } from "@app/features/deals/constants";
import { DEAL_HEALTH_QUERY_PARAM, useDealHealthListParam } from "../useDealHealthTableFilters";

interface UseDealHealthTypeFilter {
    handleChange: (value: number[], option: LabeledValue[]) => void;
    options: LabeledValue[];
    value: number[];
    legacySelected: boolean;
}

const LEGACY_DEAL_TYPE = 4;

export const useDealHealthTypeFilter = (): UseDealHealthTypeFilter => {
    const { value: filterDealType, setValue: setFiltersDealHealthType } = useDealHealthListParam(
        DEAL_HEALTH_QUERY_PARAM.FILTER_DEAL_TYPE
    );
    const { value: filterDsp } = useDealHealthListParam(DEAL_HEALTH_QUERY_PARAM.FILTER_DSP);
    const legacySelected = filterDealType?.includes(LEGACY_DEAL_TYPE);

    const DEAL_TYPE_OPTIONS_LABEL_VALUE = DEAL_TYPE_OPTIONS.map((option) => ({
        value: option.id,
        label: option.name,
        disabled: filterDsp.length > 0 && option.id === LEGACY_DEAL_TYPE,
    }));

    const handleChange = (value: number[], option: LabeledValue[]) => {
        setFiltersDealHealthType(option.map((item) => `${item.value}`));
    };

    return {
        options: DEAL_TYPE_OPTIONS_LABEL_VALUE,
        handleChange,
        value: filterDealType,
        legacySelected,
    };
};
