import { useState } from "react";
import { setInvalidBulkValues, setInvalidBulkValuesEmpty, trimBulkUploadValues } from "@app/features/targeting";
import { GeoTarget, useValidatePostalCodesMutation } from "@magnite/client-streaming-platform";

const getUniqueId = (value: GeoTarget) => `${value.geo.id}-${value.postalCode || ""}`;

export const useBulkPostalCodeDrawerButton = (onChange: (value: GeoTarget[]) => void, values: GeoTarget[]) => {
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState("");
    const [invalidValues, setInvalidValues] = useState<string[]>([]);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [isLoadingAdd, setIsLoadingAdd] = useState(false);
    const [isLoadingReplace, setIsLoadingReplace] = useState(false);
    const [countryCode, setCountryCode] = useState("US");

    const [validatePostalCodes] = useValidatePostalCodesMutation();

    const handleClose = () => {
        setInvalidValues([]);
        setValue("");
        setIsOpen(false);
    };
    const handleOpen = () => setIsOpen(true);
    const handleChangeValue = (v) => setValue(v);

    const resetInvalidValues = () => {
        setInvalidValues([]);
    };

    const handleReplace = async () => {
        resetInvalidValues();
        setIsLoadingReplace(true);
        const allBulkValues = trimBulkUploadValues(value);
        const noValues = setInvalidBulkValuesEmpty(allBulkValues, setIsEmpty);

        if (noValues) {
            setIsLoadingReplace(false);
            return;
        }

        let postalCodes;

        try {
            postalCodes = await validatePostalCodes({ body: allBulkValues, countryCode }).unwrap();
        } catch (_) {
            setIsLoadingReplace(false);
        }

        const validPostCodes = new Set(postalCodes?.map((pc) => pc.postalCode?.toLowerCase()));

        setInvalidBulkValues(
            allBulkValues,
            validPostCodes,
            setInvalidValues,
            setValue,
            setIsOpen,
            postalCodes,
            onChange
        );
        setIsLoadingReplace(false);
    };

    const handleAdd = async () => {
        resetInvalidValues();
        setIsLoadingAdd(true);
        const allBulkValues = trimBulkUploadValues(value);

        const isEmpty = setInvalidBulkValuesEmpty(allBulkValues, setIsEmpty);
        if (isEmpty) {
            setIsLoadingAdd(false);
            return;
        }

        let postalCodes;

        try {
            postalCodes = await validatePostalCodes({ body: allBulkValues, countryCode }).unwrap();
        } catch (_) {
            setIsLoadingAdd(false);
        }

        const existingValuesByValue = values.reduce((byValue, value) => {
            const id = getUniqueId(value);
            byValue[id] = value;
            return byValue;
        }, {});
        const combinedValueByValue = postalCodes?.reduce((byValue, value) => {
            const id = getUniqueId(value);
            byValue[id] = value;
            return byValue;
        }, existingValuesByValue);

        const combinedValues = Object.values<GeoTarget>(combinedValueByValue);
        const validPostCodes = new Set(postalCodes?.map((pc) => pc.postalCode?.toLowerCase()));

        setInvalidBulkValues(
            allBulkValues,
            validPostCodes,
            setInvalidValues,
            setValue,
            setIsOpen,
            combinedValues,
            onChange
        );
        setIsLoadingAdd(false);
    };

    const handleChangeCountryCode = (countryCode: string) => {
        setCountryCode(countryCode);
    };

    return {
        countryCode,
        handleAdd,
        handleChangeCountryCode,
        handleChangeValue,
        handleClose,
        handleOpen,
        handleReplace,
        isLoadingAdd,
        isLoadingReplace,
        isOpen,
        value,
        invalidValues,
        isEmpty,
    };
};
