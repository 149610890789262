import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useGetNetworksQuery } from "@magnite/client-streaming-platform";
interface UseMaskIFAForDemands {
    isFetching: boolean;
    options: LabeledValue[];
}

export const useMaskIFAForDemands = (): UseMaskIFAForDemands => {
    const { data, isFetching } = useGetNetworksQuery();
    const options = useMemo(() => (data || []).map(({ id, name }) => ({ value: id, label: name })), [data]);
    return {
        options,
        isFetching,
    };
};
