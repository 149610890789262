import { Layout } from "antd";
import { FC } from "react";
import { Footer } from "@app/core/components";
import { useParams } from "react-router-dom";
import { PageLoader } from "@rubicon/antd-components";
import { useGetPublisherByIdQuery } from "@magnite/client-streaming-platform";
import { PublisherForm } from "@app/features/inventory/HierarchyForms/PublisherForm";
import { PublisherCopyPageHeader } from "@app/features/inventory/HierarchyFormsPages/PublisherPages/PublisherCopyPage/PublisherCopyPageHeader";
import { PublisherFormInstance } from "@app/features/inventory/HierarchyForms/PublisherForm/PublisherFormInstance";
import { useScrollToTopOnMount } from "@app/core/components/hooks";
export const PublisherCopyPage: FC = () => {
    useScrollToTopOnMount();
    const { publisherId } = useParams<{ publisherId: string }>();
    const { data, isFetching } = useGetPublisherByIdQuery(Number(publisherId));

    if (isFetching || !data) return <PageLoader />;

    return (
        <>
            <PublisherCopyPageHeader />
            <Layout.Content>
                <PublisherFormInstance mode="copy" publisher={data}>
                    <PublisherForm />
                </PublisherFormInstance>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
