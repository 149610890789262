import { FC } from "react";
import { Row, Col, Typography, Button, Space } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { GoToEntityLink } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { SeatContactPayload } from "../types";
import { SeatContactsDeleteModal } from "../SeatContactsDeleteModal/SeatContactsDeleteModal";
import { getUrlSegment } from "@app/core/utils/urlSegment";

interface Props {
    seatContact?: SeatContactPayload;
}

export const SEAT_CONTACT_DETAILS_DRAWER_TITLE_SDET = "seat-contact-details-drawer-title";
export const SEAT_CONTACT_DETAILS_DRAWER_TITLE_NAME_SDET = "seat-contact-details-drawer-title-name";
export const SEAT_CONTACT_DETAILS_ACTIONS_SDET = "seat-contact-details-action";
export const SEAT_CONTACT_DETAILS_ACTIONS_EDIT_BUTTON_SDET = "seat-contact-details-action-edit-btn";

export const SeatContactDrawerTitle: FC<Props> = ({ seatContact }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const inIframe = window.self !== window.top;
    const detailPagePathname = ROUTE_FORMATTERS.SEAT_CONTROLS_SEAT_CONTACT_DETAILS_PAGE(
        seatId,
        seatContact?.id as number
    );
    const { origin, pathname } = window.location;
    const url = new URL(`${pathname}#${detailPagePathname}`, origin);
    const copyUrl = inIframe ? getUrlSegment(url.hash) : url.href;
    const history = useHistory();
    const { canEditSeat } = useUserAccess();

    return seatContact ? (
        <Row data-sdet={SEAT_CONTACT_DETAILS_DRAWER_TITLE_SDET} gutter={[0, 16]} style={{ marginRight: "1rem" }}>
            <Col span={24}>
                <Row justify="space-between" align="middle">
                    <Col>
                        <Row gutter={[8, 4]} align="middle">
                            <Col>
                                <Typography.Title
                                    data-sdet={SEAT_CONTACT_DETAILS_DRAWER_TITLE_NAME_SDET}
                                    ellipsis={{
                                        tooltip: {
                                            title: `Seat Contact: ${seatContact.name}`,
                                            placement: "bottom",
                                        },
                                    }}
                                    level={5}
                                    style={{
                                        margin: 0,
                                        maxWidth: "18rem",
                                    }}
                                >
                                    {seatContact.name}
                                </Typography.Title>
                            </Col>
                            <Col>
                                <GoToEntityLink
                                    entity="Seat Contact"
                                    linkTo={ROUTE_FORMATTERS.SEAT_CONTROLS_SEAT_CONTACT_DETAILS_PAGE(
                                        seatId,
                                        seatContact.id
                                    )}
                                    copyUrl={copyUrl}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            {canEditSeat && (
                <Col span={24}>
                    <Row data-sdet={SEAT_CONTACT_DETAILS_ACTIONS_SDET}>
                        <Col>
                            <Space>
                                <Button
                                    onClick={() =>
                                        seatContact &&
                                        history.push(
                                            ROUTE_FORMATTERS.SEAT_CONTROLS_SEAT_CONTACTS_EDIT(seatId, seatContact.id)
                                        )
                                    }
                                    data-sdet={SEAT_CONTACT_DETAILS_ACTIONS_EDIT_BUTTON_SDET}
                                    type="primary"
                                >
                                    Edit
                                </Button>
                                <SeatContactsDeleteModal contact={seatContact} />
                            </Space>
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    ) : null;
};
