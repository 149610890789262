import { PacingType, SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, PacingTypes, AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { Form } from "antd";
import moment from "moment-timezone";

const { useWatch } = Form;

export const useIsChartsVisible = () => {
    const adSourceType = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const pacingType = useWatch<PacingType>(AD_SOURCE_FIELDS.PACING_TYPE.name);
    const totalImpressions = useWatch<number>(AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name) || 0;
    const deliveryImps = useWatch<number>(AD_SOURCE_FIELDS.DELIVERY_PERCENTAGE.name) || 0;
    const startDate = useWatch<moment.Moment>(AD_SOURCE_FIELDS.START_DATE.name);
    const endDate = useWatch<moment.Moment>(AD_SOURCE_FIELDS.END_DATE.name);
    const targetDate = useWatch<moment.Moment>(AD_SOURCE_FIELDS.TARGET_DATE.name);

    const adSourceTypeId = adSourceType?.id;
    const isPacingTypeCustom = pacingType?.id === PacingTypes.CUSTOM;
    switch (adSourceTypeId) {
        case AdSourceTypeIds.PROGRAMMATIC_GUARANTEED:
        case AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED:
        case AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED:
            return (
                isPacingTypeCustom &&
                totalImpressions &&
                deliveryImps &&
                startDate &&
                endDate &&
                targetDate &&
                targetDate.isAfter(startDate) &&
                endDate.isAfter(targetDate)
            );

        default:
            return false;
    }
};
