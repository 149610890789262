import { LabeledValue } from "antd/lib/select";
import { setFiltersSeatAdSourcesLabelValue } from "../../reducer";
import { useGetLabelValuesQuery } from "@magnite/client-streaming-platform";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useMemo } from "react";

interface UseSeatAdSourcesLabelValueFilter {
    handleChangeValue: (value: string | number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue[] | string | null;
    isLoading: boolean;
    disabled: boolean;
}

export const useSeatAdSourcesLabelValueFilter = (): UseSeatAdSourcesLabelValueFilter => {
    const dispatch = useAppDispatch();
    const seatAdSourcesLabelKey = useAppSelector((state) => state.seatAdSourcesList.filters.seatAdSourcesLabelKey);
    const seatAdSourcesLabelValue = useAppSelector((state) => state.seatAdSourcesList.filters.seatAdSourcesLabelValue);
    const labelId = seatAdSourcesLabelKey?.map((label) => label.value);
    const { data, isFetching } = useGetLabelValuesQuery(Number(labelId));
    const options = useMemo(() => (data || []).map((filter) => ({ label: filter.value, value: filter.id })), [data]);

    const handleChangeValue = (value: string | number | undefined, option: LabeledValue[]) => {
        dispatch(setFiltersSeatAdSourcesLabelValue(option));
    };
    const isDisabled = (seatAdSourcesLabelKey?: LabeledValue[] | null): boolean =>
        seatAdSourcesLabelKey === undefined || seatAdSourcesLabelKey === null || seatAdSourcesLabelKey.length === 0;

    return {
        handleChangeValue,
        options: options,
        value: seatAdSourcesLabelValue,
        isLoading: isFetching,
        disabled: isDisabled(seatAdSourcesLabelKey),
    };
};
