import { Form, InputNumber } from "antd";
import { ColProps } from "antd/lib/grid/col";
import { InputNumberProps } from "antd/lib/input-number";
import { FormItemProps } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import { StoreValue } from "rc-field-form/lib/interface";
import { FC } from "react";

export interface CalculonUseRateOverrideProps extends InputNumberProps {
    name: string;
    label: string;
    extra?: string;
    labelCol?: ColProps;
    rulesMessage: { [key: string]: string };
    wrapperCol?: ColProps;
    formItemProps?: FormItemProps;
    type: "min" | "max";
}

export const CalculonUseRateOverride: FC<CalculonUseRateOverrideProps> = ({
    name,
    label,
    labelCol,
    wrapperCol,
    extra,
    rulesMessage,
    formItemProps,
    type,
    ...rest
}) => {
    return (
        <Form.Item
            name={name}
            label={label}
            extra={extra}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            rules={[
                ({ getFieldValue }) => ({
                    validator(_, value: StoreValue) {
                        if (value === 0) return Promise.reject(new Error(rulesMessage.range));

                        const dependencyName = formItemProps?.dependencies?.[0];
                        const dependencyValue = getFieldValue(dependencyName as NamePath);

                        if (type === "min" && dependencyValue != null && value >= dependencyValue) {
                            return Promise.reject(new Error(rulesMessage.less));
                        }
                        if (type === "max" && value != null && dependencyValue >= value) {
                            return Promise.reject(new Error(rulesMessage.greater));
                        }
                        if ((value >= 0.001 && value <= 0.999) || !value) return Promise.resolve();
                        return Promise.reject(new Error(rulesMessage.range));
                    },
                }),
            ]}
            {...formItemProps}
        >
            <InputNumber step={0.001} style={{ width: "100%" }} {...rest} />
        </Form.Item>
    );
};
