import { FC } from "react";
import { Card, Col, Flex, Form, Row, Skeleton, Typography } from "antd";
import { css } from "@emotion/css";
import { Metric } from "@rubicon/antd-components";
import { ChartMetricsFilters } from "../ChartMetricsFilters";
import { TimeFrameFilter } from "../TimeFrameFilter";
import { ViewFilter } from "../ViewFilter";
import { PerformanceQuickViewChart } from "../PerformanceQuickViewChart";
import { useReportBySeatId } from "./useReportsBySeatId";
import { useCurrencyConversion } from "../useCurrencyConversion";
import { useAppSelector } from "@app/core/store";
import { getDateLabel } from "../helpers";
import { formatNumber } from "@rubicon/utils";
import { useAuthClient } from "@app/core/authClient";
import { RevenueTypes } from "@app/core/clients/console";
import { useSeatAuthContext } from "@app/core/auth";
import { selectDashboardRevenueType, selectDashboardTimeFrameFilter } from "../reducer";
import { DASHBOARD_METRIC_COL_SIZES } from "@app/features/dashboard/LiveStatsMetrics";

const getPercentageDifferenceSuffix = (percentageDifference) => {
    if (isNaN(percentageDifference)) {
        return {};
    }

    return {
        suffixColor: percentageDifference < 0 ? ("red" as const) : ("green" as const),
        suffix: `${percentageDifference > 0 ? "+" : ""}${percentageDifference}%`,
    };
};

export const PerformanceQuickViewMetrics: FC = () => {
    const { context } = useSeatAuthContext();
    const { session } = useAuthClient();
    const { currencyConfig } = useCurrencyConversion();
    const revenueType = useAppSelector(selectDashboardRevenueType);
    const period = useAppSelector(selectDashboardTimeFrameFilter);
    const timeZone = session?.user?.userPreferenceMap.timezone as string;

    const {
        performanceMetrics: {
            acceptRateThisPeriod,
            acceptRateLastPeriod,
            acceptRatePercentageDifference,
            revenueThisPeriod,
            revenueLastPeriod,
            revenuePercentageDifference,
            revenueCPMThisPeriod,
            revenueCPMLastPeriod,
            revenueCPMPercentageDifference,
            requestsThisPeriod,
            requestsLastPeriod,
            requestsPercentageDifference,
            opportunitiesThisPeriod,
            opportunitiesLastPeriod,
            opportunitiesPercentageDifference,
            fillsThisPeriod,
            fillsLastPeriod,
            fillsPercentageDifference,
            fillRateThisPeriod,
            fillRateLastPeriod,
            fillRatePercentageDifference,
            impressionsThisPeriod,
            impressionsLastPeriod,
            impressionsPercentageDifference,
            useRateThisPeriod,
            useRateLastPeriod,
            useRatePercentageDifference,
        },
        isLoading,
        metricOne,
        metricTwo,
        metricOneType,
        metricTwoType,
    } = useReportBySeatId();

    const fourthStatsCell = context?.adPodEnabled
        ? {
              title: "Opportunities",
              value: opportunitiesThisPeriod,
              precision: 0,
              loading: isLoading,
              description: (
                  <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>
                      {formatNumber.asNumber(opportunitiesLastPeriod)}
                  </Skeleton>
              ),
              ...getPercentageDifferenceSuffix(opportunitiesPercentageDifference),
          }
        : {
              title: "Accept Rate",
              value: `${acceptRateThisPeriod}%`,
              precision: 0,
              loading: isLoading,
              description: (
                  <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>
                      {formatNumber.asNumber(acceptRateLastPeriod)}%
                  </Skeleton>
              ),
              ...getPercentageDifferenceSuffix(acceptRatePercentageDifference),
          };

    const metrics = [
        {
            title: `est. ${revenueType === RevenueTypes.NET_REVENUE ? "Net" : "Gross"} Rev`,
            prefix: currencyConfig?.symbol || "",
            value: revenueThisPeriod,
            loading: isLoading,
            description: (
                <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>
                    {formatNumber.asMoney(revenueLastPeriod, currencyConfig?.code)}
                </Skeleton>
            ),
            ...getPercentageDifferenceSuffix(revenuePercentageDifference),
        },
        {
            title: `est. ${revenueType === RevenueTypes.NET_REVENUE ? "Net" : "Gross"} eCPM`,
            prefix: currencyConfig?.symbol || "",
            value: revenueCPMThisPeriod,
            loading: isLoading,
            description: (
                <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>{`${
                    currencyConfig?.symbol || ""
                }${revenueCPMLastPeriod}`}</Skeleton>
            ),
            ...getPercentageDifferenceSuffix(revenueCPMPercentageDifference),
        },
        {
            title: context?.adPodEnabled ? "SSP Requests" : "Requests",
            value: requestsThisPeriod,
            precision: 0,
            loading: isLoading,
            description: (
                <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>
                    {formatNumber.asNumber(requestsLastPeriod)}
                </Skeleton>
            ),
            ...getPercentageDifferenceSuffix(requestsPercentageDifference),
        },
        fourthStatsCell,
        {
            title: "Fills",
            value: fillsThisPeriod,
            precision: 0,
            loading: isLoading,
            description: (
                <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>
                    {formatNumber.asNumber(fillsLastPeriod)}
                </Skeleton>
            ),
            ...getPercentageDifferenceSuffix(fillsPercentageDifference),
        },
        {
            title: "Fill Rate",
            value: `${fillRateThisPeriod}%`,
            precision: 0,
            loading: isLoading,
            description: (
                <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>{`${fillRateLastPeriod}%`}</Skeleton>
            ),
            ...getPercentageDifferenceSuffix(fillRatePercentageDifference),
        },
        {
            title: "Impressions",
            value: impressionsThisPeriod,
            precision: 0,
            loading: isLoading,
            description: (
                <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>
                    {formatNumber.asNumber(impressionsLastPeriod)}
                </Skeleton>
            ),
            ...getPercentageDifferenceSuffix(impressionsPercentageDifference),
        },
        {
            title: "Use Rate",
            value: `${useRateThisPeriod}%`,
            precision: 0,
            loading: isLoading,
            description: (
                <Skeleton loading={isLoading} paragraph={{ rows: 1 }} round={true}>{`${useRateLastPeriod}%`}</Skeleton>
            ),
            ...getPercentageDifferenceSuffix(useRatePercentageDifference),
        },
    ];

    return (
        <Card
            bodyStyle={{ padding: 0 }}
            className={css`
                border: 1px solid #d9d9d9;
                background-color: #fafafa;

                .ant-card-head {
                    padding: 10px 15px;

                    .ant-card-head-wrapper {
                        flex-wrap: wrap;

                        .ant-card-head-title {
                            //padding: 15px 0;
                            flex-basis: 100%;
                        }

                        .ant-card-extra {
                            padding-top: 10px;
                            margin-left: 0;
                            width: 100%;
                        }
                    }
                }
            `}
            bordered={true}
            title={
                <Flex gap={10} align="center" wrap={true} justify="space-between">
                    <Typography.Title level={5} style={{ margin: "0", fontSize: "14px" }}>
                        Performance Quick View
                    </Typography.Title>
                    <Typography.Text data-sdet="dateRange" style={{ fontWeight: "normal", whiteSpace: "normal" }}>
                        {getDateLabel(period, timeZone)}
                    </Typography.Text>
                </Flex>
            }
            extra={[
                <Form key="form" colon={false} layout="horizontal">
                    <Row gutter={[16, 16]} align="middle">
                        {context?.adPodEnabled && (
                            <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={5}>
                                <Form.Item style={{ marginBottom: 0 }} label="View">
                                    <ViewFilter style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        )}
                        <Col xs={24} sm={24} md={12} lg={10} xl={8} xxl={5}>
                            <Form.Item style={{ marginBottom: 0 }} label="Time Frame">
                                <TimeFrameFilter style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>,
            ]}
        >
            <Row gutter={[10, 10]} wrap={true} style={{ margin: "10px" }}>
                {metrics.map((m, i) => (
                    <Col key={i} {...DASHBOARD_METRIC_COL_SIZES}>
                        <Metric {...m} />
                    </Col>
                ))}
            </Row>
            <Card
                className={css`
                    border-top: 1px solid #d9d9d9;

                    .ant-card-head {
                        border-bottom: 0;
                    }
                `}
                bordered={false}
                title={<ChartMetricsFilters />}
            >
                <PerformanceQuickViewChart
                    metricOne={metricOne}
                    metricTwo={metricTwo}
                    metricOneType={String(metricOneType)}
                    metricTwoType={String(metricTwoType)}
                    isLoading={isLoading}
                />
            </Card>
        </Card>
    );
};
